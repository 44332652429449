import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

import notFound from '../../../assets/icons/notFound.svg';
import {Button} from '../../shared/components/button';
import {colors} from '../../shared/styles/theme';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-root': {
      width: 240,
      marginTop: 10,
    },
  },
});

const titleText = "Page doesn't exist.";

export const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handlePress = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Content className={classes.root}>
      <LogoImg src={notFound} alt="notFound" />
      <Status>404</Status>
      <Title>{titleText}</Title>
      <Button onClick={handlePress}>Back to last step</Button>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: ${colors.grey801};
`;

const Title = styled.h4`
  font-size: 22px;
  text-align: center;
  width: 640px;
  font-weight: 400;
  font-style: italic;
  margin-top: -10px;
`;

const LogoImg = styled.img`
  margin-top: 225px;
`;

const Status = styled.h3`
  font-size: 40px;
  color: ${colors.primary};
  position: relative;
  margin: 0;
  top: -70px;
  left: 110px;
`;
