import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {AddButton} from '../../../shared/components/button';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {Subheader} from '../../../shared/components/subheader';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {isUserIssuerAdminOrOwner} from '../../../shared/utils/user';
import useClearOutInvitation from '../../relyingPartyInvitation/hooks/useClearOutInvitation';
import {getRelyingPartiesTableConfig} from '../constants/relyingPartiesTableSchema';
import {useRelyingParties} from '../hooks/useRelyingParties';

export const RelyingPartiesPage = ({crumbs, title}) => {
  const {relyingParties, isLoading} = useRelyingParties();
  const {roles} = useRecoilValue(currentUserState);

  const history = useHistory();
  const {clearOutInvitation} = useClearOutInvitation();

  const iconMethodMap = useMemo(
    () => ({
      handleDetail: item => history.push(`/relying-parties/${item.id}/profile`),
    }),
    [history]
  );
  const onClick = useCallback(() => {
    clearOutInvitation();
    history.push('/relying-parties/invitation');
  }, [clearOutInvitation, history]);

  const columns = useMemo(
    () => getRelyingPartiesTableConfig(iconMethodMap),
    [iconMethodMap]
  );

  const table = useReactTable({
    data: relyingParties,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <Subheader title="Relying parties">
        {isUserIssuerAdminOrOwner(roles) && (
          <AddButton onClick={onClick} text="Invite relying party" />
        )}
      </Subheader>
      {isLoading && <LoadingBar loading={isLoading} />}
      <RelyingPartyTableWrapper>
        {relyingParties && relyingParties.length === 0 && (
          <NoDataPlaceholder noDataText="No relying parties found" />
        )}
        {relyingParties && relyingParties.length > 0 && (
          <StyledTable table={table} isHeaderSticky />
        )}
      </RelyingPartyTableWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RelyingPartyTableWrapper = styled.div`
  margin-top: 40px;
  padding: 0 60px 60px 40px;
  width: 100%;
  box-sizing: border-box;
  min-height: 120px;
  overflow-y: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
`;

RelyingPartiesPage.propTypes = {
  title: PropTypes.string,
  crumbs: PropTypes.arrayOf(PropTypes.any),
};
