import React from 'react';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';

import {ViewerScreenError} from './viewerScreenError';
import {ViewerScreenLoading} from './viewerScreenLoading';
import {ViewerScreenMessage} from './viewerScreenMessage';
import {ViewScreenDICOM} from './viewScreenDICOM';

type Props = {
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewType: string;
  filePreviewData: string;
};

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
};

export const FileViewer: React.FC<Props> = ({
  filePreviewIsLoading,
  filePreviewHasError,
  filePreviewType,
  filePreviewData,
}) => {
  if (filePreviewIsLoading) {
    return (
      <ViewerScreenLoading
        data-testid="file-viewer-loading-state"
        message="File preview is loading"
      />
    );
  }

  if (filePreviewHasError) {
    return (
      <ViewerScreenError
        data-testid="file-viewer-error-state"
        title="File preview error"
        description="Please try to select another file and return back to this one"
      />
    );
  }

  switch (filePreviewType) {
    case 'CCDA':
      return (
        <PreviewWrapper data-testid="file-viewer-ccda">
          <iframe
            title="CCDA preview"
            style={{
              ...iframeStyles,
              pointerEvents: 'none',
            }}
            src={`data:text/html;charset=utf-8,${escape(filePreviewData)}`}
          />
        </PreviewWrapper>
      );
    case 'DICOM':
      return (
        <PreviewWrapper data-testid="file-viewer-dicom">
          <ViewScreenDICOM src={filePreviewData} />
        </PreviewWrapper>
      );
    case 'PDF':
      return (
        <PreviewWrapper data-testid="file-viewer-pdf">
          <iframe
            title="PDF preview"
            style={iframeStyles}
            src={filePreviewData}
          />
        </PreviewWrapper>
      );
    case 'JPEG':
    case 'PNG':
      return (
        <PreviewWrapper data-testid="file-viewer-img">
          <iframe
            title="Image preview"
            src={filePreviewData}
            style={iframeStyles}
          />
        </PreviewWrapper>
      );
    default:
      return (
        <ViewerScreenMessage
          dataTestId="file-viewer-no-preview"
          title="Preview is not supported for this file type"
        />
      );
  }
};

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  background-color: ${colors.grey1000};
  padding: 8px;
`;
