import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function CopyIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15" width="15" height="15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1H6a1 1 0 0 0-1 1v2h3a2 2 0 0 1 2 2v3h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Zm-2 9v2a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h2V2c0-1.1.9-2 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2ZM1 6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6Z"
      />
    </SvgIcon>
  );
}
