import React, {useCallback, useState} from 'react';
import {FormControl, Grid} from '@material-ui/core';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {mutate} from 'swr';
import * as yup from 'yup';

import {Button, CancelButton} from '../../../shared/components/button';
import {
  INPUT_WIDTH_SIZES,
  INPUT_WIDTH_TYPES,
  InputField,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {Subheader} from '../../../shared/components/subheader';
import {domainPattern} from '../../../shared/constants/patterns';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {checkDuplicates} from '../../../shared/utils/checkDuplicates';
import {fetcher} from '../../../shared/utils/fetcher';
import {checkEditFormButtonDisability} from '../../../shared/utils/formikUtils';
import trimValues from '../../../shared/utils/trimValues';
import {urls} from '../../../shared/utils/urls';

import {OwnerInformationField} from './ownerInformationField';

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(100, 'Must be 100 characters or less')
    .required('Relying party name is required'),
  domain: yup.string().when('providerType', {
    is: providerType => providerType === PROVIDER_TYPES.ORGANIZATION,
    then: yup
      .string()
      .trim()
      .max(200, 'Must be 200 characters or less')
      .matches(domainPattern, 'Please enter a valid domain')
      .required('Domain is required'),
  }),
});

export const ProfileEditForm = ({
  editMode,
  onEditModeChanged,
  data,
  profileUrl,
}) => {
  const {relying_party: profile, relying_party_owner: ownerData} = data;
  const isCurrentUserNonIndividual =
    profile?.provider_type !== PROVIDER_TYPES.INDIVIDUAL;

  const [currentProfile, setCurrentProfile] = useState(profile);

  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling('Unable to save changes.');

  const handleSubmit = useCallback(
    async (values, {setFieldError}) => {
      const result = await checkDuplicates(
        values.name,
        urls.checkRelyingPartyName
      );
      if (result && trimValues(values.name) !== profile.name) {
        setFieldError('name', 'This name has already been registered.');
      } else {
        try {
          if (currentProfile) {
            const responseData = await fetcher(profileUrl, 'PUT', {
              relying_party_name: trimValues(values.name),
              relying_party_domain: trimValues(values.domain),
            });
            await mutate(`${profileUrl}/profile`, responseData);
            setCurrentProfile(profile);
          }
          showMessage('Changes saved successfully.', 'success');
          onEditModeChanged(false);
        } catch (error) {
          await handleError(error);
        }
      }
    },
    [
      currentProfile,
      handleError,
      onEditModeChanged,
      profile,
      profileUrl,
      showMessage,
    ]
  );

  const handleCancel = useCallback(() => {
    setCurrentProfile(profile);
    onEditModeChanged(false);
  }, [onEditModeChanged, profile]);

  return (
    <Formik
      initialValues={{
        npi: profile.npi,
        name: profile.name,
        domain: profile.domain,
        providerType: profile.provider_type,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {formParams => (
        <Form>
          <Subheader title={profile.name}>
            <Button
              testID="saveButton"
              type="submit"
              position="section"
              disabled={checkEditFormButtonDisability(formParams)}
            >
              Save
            </Button>
            <CancelButton position="section" onClick={handleCancel} />
          </Subheader>
          <ContentWrapper>
            <Grid container>
              <Grid item xs={9}>
                <TitleText>Profile</TitleText>
                <InputContainer>
                  <FormControl disabled variant="standard">
                    <InputLabel htmlFor="npi">NPI</InputLabel>
                    <InputField
                      id="npi"
                      width="small"
                      value={formParams.values.npi}
                      onChange={formParams.handleChange}
                    />
                    <InputHelperText>{formParams.errors.npi}</InputHelperText>
                  </FormControl>
                  <FormControl
                    disabled
                    variant="standard"
                    error={!!formParams.errors.name}
                  >
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <InputField
                      id="name"
                      width="small"
                      value={formParams.values.name}
                      onChange={formParams.handleChange}
                    />
                    <InputHelperText>{formParams.errors.name}</InputHelperText>
                  </FormControl>
                  {isCurrentUserNonIndividual ? (
                    <FormControl
                      variant="standard"
                      error={!!formParams.errors.domain}
                    >
                      <InputLabel htmlFor="domain">Domain</InputLabel>
                      <InputField
                        id="domain"
                        width="small"
                        value={formParams.values.domain}
                        onChange={formParams.handleChange}
                      />
                      <InputHelperText>
                        {formParams.errors.domain}
                      </InputHelperText>
                    </FormControl>
                  ) : null}
                </InputContainer>
                <OwnerInformationFieldWrapper>
                  <OwnerInformationField
                    ownerInfo={ownerData}
                    editMode={editMode}
                  />
                </OwnerInformationFieldWrapper>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

ProfileEditForm.propTypes = {
  editMode: PropTypes.bool,
  onEditModeChanged: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  profileUrl: PropTypes.string,
};

const ContentWrapper = styled.div`
  margin: 24px 60px 0 40px;
  padding-bottom: 14px;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    ${INPUT_WIDTH_SIZES[INPUT_WIDTH_TYPES.SMALL]}
  );
  grid-column-gap: 20px;
  grid-row-gap: 2px;
`;

const TitleText = styled.h6`
  font-weight: 700;
  margin: 0;
  line-height: 24px;
`;

const OwnerInformationFieldWrapper = styled.div`
  margin-top: 37px;
`;
