import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {ConfirmModal} from '../../../shared/components/confirmModal';
import {ExitBar} from '../../../shared/components/exitBar';
import {StepSidebar} from '../../../shared/components/stepBar';
import {useLocationAddresses} from '../../../shared/hooks/useLocationAddresses';
import useSearchNPI from '../../../shared/hooks/useSearchNPI';
import {StepConfirmInvitation} from '../components/stepConfirmInvitation';
import {StepSetRelyingParty} from '../components/stepSetRelyingParty';
import {STEPS, STEPS_TITLE} from '../constants/stepper';
import useClearOutInvitation from '../hooks/useClearOutInvitation';
import {stepState} from '../store';

const invitationSteps = [
  STEPS_TITLE.SET_NPI.TITLE,
  STEPS_TITLE.CONFIRM_INVITATION.TITLE,
];

export default function RelyingPartyInvitation() {
  const history = useHistory();

  const [curStep] = useRecoilState(stepState);
  const [open, setOpen] = useState(false);
  const {clearOutInvitation} = useClearOutInvitation();

  const ownerNpi = useSearchNPI();
  const organizationNpi = useSearchNPI();
  const locationAddresses = useLocationAddresses('false');
  const onClickOpen = useCallback(() => setOpen(true), []);
  const onClickClose = useCallback(() => setOpen(false), []);
  const onConfirm = useCallback(() => {
    clearOutInvitation();
    setOpen(false);
    history.push('/relying-parties');
  }, [clearOutInvitation, history]);

  return (
    <Container>
      <ExitBar onClick={onClickOpen} title="Invite relying party" />
      <LayoutColumn>
        <StepSidebar steps={invitationSteps} activeStep={curStep} />
        <Content>
          {curStep === STEPS.FIRST && (
            <StepSetRelyingParty
              ownerNpi={ownerNpi}
              organizationNpi={organizationNpi}
              locationAddresses={locationAddresses}
            />
          )}
          {curStep === STEPS.SECOND && <StepConfirmInvitation />}
        </Content>
      </LayoutColumn>
      <ConfirmModal isOpen={open} onClose={onClickClose} onConfirm={onConfirm}>
        Are you sure you want to return to the relying party list?
      </ConfirmModal>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LayoutColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

const Content = styled.div`
  padding: 18px 24px;
`;
