import React from 'react';
import {CircularProgress} from '@material-ui/core';
import styled from 'styled-components';

import {ViewFileWrapper} from './viewerWrapper';

type Props = {
  message: string;
};

export const ViewerScreenLoading: React.FC<Props> = ({message}) => (
  <ViewFileWrapper>
    <CircularProgress color="primary" size={60} thickness={3} />
    <LoadingDescriptionWrapper>{message}</LoadingDescriptionWrapper>
  </ViewFileWrapper>
);

const LoadingDescriptionWrapper = styled.span`
  margin-top: 24px;
`;
