const COLUMN_KEY_FOR_SEARCHABLE_COLUMNS = 'searchable';

const getTableColumnAccessorsByValue = (tableColumns, key) =>
  tableColumns.reduce((acc, column) => {
    if (column[key]) {
      acc.push(column.id);
    }

    return acc;
  }, []);

export const getSearchedTableData =
  (tableColumns, searchValue) => tableData => {
    if (searchValue.length === 0) {
      return tableData;
    }

    const searchableColumnKeys = getTableColumnAccessorsByValue(
      tableColumns,
      COLUMN_KEY_FOR_SEARCHABLE_COLUMNS
    );

    return tableData.filter(item =>
      searchableColumnKeys.some(searchableColumnKey =>
        item[searchableColumnKey]
          .toUpperCase()
          .includes(searchValue.toUpperCase())
      )
    );
  };
