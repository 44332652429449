import {atom} from 'recoil';

import {PatientDataForRequestType} from '../constants/types';

type StateType = {
  isOpenModalWindow: boolean;
  data: PatientDataForRequestType | null;
};

export const patientDataForRequestState = atom<StateType>({
  key: 'patientDataForRequest',
  default: {
    isOpenModalWindow: false,
    data: null,
  },
});
