type TabsObject = {
  [key: string]: {
    title: string;
    tabIndex: number;
    path: string;
    isHidden: boolean;
  };
};
export type PossibleTab = {
  key: string;
  title: string;
  path: string;
  isHidden: boolean;
};

export const getTabs = (tabs: PossibleTab[]): TabsObject =>
  tabs.reduce<TabsObject>((acc, tab) => {
    if (!tab.isHidden) {
      acc[tab.key] = {
        ...tab,
        tabIndex: Object.keys(acc).length,
      };
    }

    return acc;
  }, {});
