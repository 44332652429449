/* eslint-disable indent */
import {v4 as uuid} from 'uuid';

import {LozengeAppearances} from '../lozenge';
import {
  Allergies,
  AllergyReactions,
  FetchedAllergies,
} from '../types/allergyTypes';

import {formatDate} from './mappersUtils';

const allergyCriticallyColors: {[key: string]: LozengeAppearances} = {
  'high risk': 'error',
  'low risk': 'warning',
};

const allergySeverityColors: {[key: string]: LozengeAppearances} = {
  mild: 'default',
  severe: 'error',
  moderate: 'warning',
};

export const getAllergyCriticallyColor = (
  criticality?: FetchedAllergies['criticality']
): LozengeAppearances =>
  criticality ? allergyCriticallyColors[criticality?.toLowerCase()] : 'default';

export const getAllergySeverityColors = (
  severity?: AllergyReactions['severity']
): LozengeAppearances =>
  severity ? allergySeverityColors[severity?.toLowerCase()] : 'default';

export const getAllergies = (allergies?: FetchedAllergies[]): Allergies[] => {
  if (!allergies) {
    return [];
  }

  return allergies.map(
    ({
      recorded_date,
      last_occurrence,
      last_updated_at,
      reaction,
      ...allergy
    }) => ({
      ...allergy,
      id: uuid(),
      reaction:
        reaction && Array.isArray(reaction)
          ? reaction.map(item => ({
              ...item,
              id: uuid(),
              onset: formatDate(item?.onset),
            }))
          : [],
      recorded_date: formatDate(recorded_date),
      last_occurrence: formatDate(last_occurrence),
      last_updated_at: formatDate(last_updated_at),
    })
  );
};
