import {getRemappedMedicalRecordNumbers} from '../../../shared/utils/getRemappedMedicalRecordNumbers';
import {
  FormattedProviderInboxEventsType,
  ProviderInboxEventsType,
} from '../constatnts/types';
import {
  getEventDescription,
  getPatientFullNameWithComment,
} from '../constatnts/wordings';

export const formatInboxEvents = (
  events: ProviderInboxEventsType[]
): FormattedProviderInboxEventsType[] =>
  events.map((event: ProviderInboxEventsType) => ({
    id: event.id,
    status: event.status,
    eventCategory: event.event_category,
    eventCategoryDescription: getEventDescription(event),
    consumerUuid: event.consumer_uuid ?? '',
    patient: getPatientFullNameWithComment(event),
    patientFirstName: event.patient_first_name,
    patientLastName: event.patient_last_name,
    patientDateOfBirth: event.patient_date_of_birth,
    eventEntityType: event.event_entity_type,
    eventEntityId: event.event_entity_id,
    eventEntityRelyingPartyId: event.event_entity_relying_party_id,
    eventEntityClientId: event.event_entity_client_id,
    completedResolution: event.completed_resolution,
    completedByClientId: event.completed_by_client_id,
    completedByClientFirstName: event.completed_by_client_first_name,
    completedByClientLastName: event.completed_by_client_last_name,
    completedAt: event.completed_at,
    createdAt: event.created_at,
    medicalRecordNumbers: getRemappedMedicalRecordNumbers(
      event.mrn_ehr_responses
    ),
  }));
