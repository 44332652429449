import {useCallback, useEffect, useRef, useState} from 'react';
import {isFunction} from 'lodash';

import {eventNames} from '../../shared/constants/analytics';
import {trackEvent} from '../../shared/utils/trackEvent';

const observerConfig = {
  childList: true,
};

const iframeObserverConfig = {
  attributes: true,
  attributeOldValue: true,
  attributeFilter: ['style'],
};

export const useHideRecaptcha = setLoading => {
  const reCaptchaObserver = useRef();
  const [recaptchaVisible, setRecaptchaVisible] = useState(true);

  const DOMChangeCallbackFunction = useCallback(
    mutationRecords => {
      mutationRecords.forEach(mutationRecord => {
        if (mutationRecord.addedNodes.length) {
          try {
            const reCaptchaParentContainer = mutationRecord.addedNodes[0];
            const reCaptchaIframeContainer =
              reCaptchaParentContainer.querySelector(
                'iframe[src^="https://www.google.com/recaptcha"]'
              )?.parentNode.parentNode;

            if (!reCaptchaIframeContainer) return;
            const iframeCallback = changes => {
              changes.forEach(() => {
                const isRecaptchaHidden =
                  reCaptchaIframeContainer?.style.visibility === 'hidden';

                setRecaptchaVisible(!isRecaptchaHidden);

                if (isRecaptchaHidden && isFunction(setLoading)) {
                  setLoading(false);
                }
              });
            };

            if (recaptchaVisible && isFunction(setLoading)) {
              setLoading(false);
            }
            reCaptchaObserver.current = new MutationObserver(iframeCallback);
            reCaptchaObserver.current.observe(
              reCaptchaIframeContainer,
              iframeObserverConfig
            );
          } catch (error) {
            trackEvent(eventNames.failedRecaptcha, error);
            setRecaptchaVisible(false);
            if (isFunction(setLoading)) {
              setLoading(false);
            }
          }
        }
      });
    },
    [recaptchaVisible, setLoading]
  );

  useEffect(() => {
    const DOMObserver = new MutationObserver(DOMChangeCallbackFunction);

    DOMObserver.observe(document.body, observerConfig);

    return () => {
      if (reCaptchaObserver?.current) {
        reCaptchaObserver?.current.disconnect();
      }
      DOMObserver?.disconnect();
    };
  }, [DOMChangeCallbackFunction, reCaptchaObserver]);

  return recaptchaVisible;
};
