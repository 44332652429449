import {useCallback, useEffect, useRef} from 'react';
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {eventNames} from 'process';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {queryClient} from '../../../queryClient';
import {useSetStateIssuerLogo} from '../../main/issuerSettings/hooks/useSetStateIssuerLogo';
import {currentUserState} from '../../shared/atoms/authAtom';
import {fetcher} from '../../shared/utils/fetcher';
import {trackEvent} from '../../shared/utils/trackEvent';
import {urls} from '../../shared/utils/urls';
import {MFA_HINT_NAME} from '../constants/constants';
import {
  extractUserFromSelfRequest,
  extractUserFromUserCredentials,
} from '../utils/formatUserData';

const getPhoneNumber = async () => {
  const user = await fetcher(urls.self);
  return user.phone_number;
};

export const useAuth = () => {
  const user = useRecoilValue(currentUserState);
  const setUser = useSetRecoilState(currentUserState);
  const setStateIssuerLogo = useSetStateIssuerLogo();

  const verificationIdRef = useRef(null);
  const phoneAuthProviderRef = useRef(null);

  useEffect(() => {
    phoneAuthProviderRef.current = new PhoneAuthProvider(getAuth());
  }, []);

  const setCurrentUserAndIssuerLogo = useCallback(async () => {
    if (!user) {
      const data = await fetcher(urls.self);

      setUser({...extractUserFromSelfRequest(data)});

      await setStateIssuerLogo();
    }
  }, [setStateIssuerLogo, setUser, user]);

  const loadFirebaseUser = useCallback(
    async curUser => {
      if (curUser) {
        const multiFactorUser = multiFactor(curUser);
        const userCredentials = await curUser.getIdTokenResult();
        await setCurrentUserAndIssuerLogo();

        return {
          ...extractUserFromUserCredentials(userCredentials.claims),
          mfaEnabled: multiFactorUser?.enrolledFactors?.length > 0,
        };
      }
      return null;
    },
    [setCurrentUserAndIssuerLogo]
  );

  const signIn = useCallback(
    async (email, password) => {
      const credentials = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      );
      return loadFirebaseUser(credentials.user);
    },
    [loadFirebaseUser]
  );

  const localSignInWithCustomToken = useCallback(
    async customToken => {
      const credentials = await signInWithCustomToken(getAuth(), customToken);
      return loadFirebaseUser(credentials.user);
    },
    [loadFirebaseUser]
  );

  const initializeRecaptcha = useCallback(
    containerId =>
      new RecaptchaVerifier(getAuth(), containerId, {
        size: 'invisible',
      }),
    []
  );

  const startMfaForSignIn = useCallback(
    async (resolver, verifier) => {
      const multiFactorHint = resolver.hints.find(
        hint => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID
      );

      verificationIdRef.current =
        await phoneAuthProviderRef.current.verifyPhoneNumber(
          {multiFactorHint, session: resolver.session},
          verifier
        );
      try {
        await verifier.verify();
      } catch (err) {
        trackEvent(eventNames.failedRecaptcha, err);
      }
      return multiFactorHint.phoneNumber;
    },
    [phoneAuthProviderRef]
  );

  const startMfaForSignUp = useCallback(
    async verifier => {
      const auth = getAuth();
      if (auth.currentUser) {
        const multiFactorSession = await multiFactor(
          auth.currentUser
        ).getSession();
        const unformattedPhoneNumber = await getPhoneNumber();
        verificationIdRef.current =
          await phoneAuthProviderRef.current.verifyPhoneNumber(
            {
              phoneNumber: `+1${unformattedPhoneNumber}`,
              session: multiFactorSession,
            },
            verifier
          );
      }
    },
    [phoneAuthProviderRef]
  );

  const verifyCode = useCallback(
    async verificationCode => {
      const credential = PhoneAuthProvider.credential(
        verificationIdRef.current,
        verificationCode
      );
      return PhoneMultiFactorGenerator.assertion(credential);
    },
    [verificationIdRef]
  );

  const updateUser = useCallback(async () => {
    const {currentUser} = getAuth();
    return loadFirebaseUser(currentUser);
  }, [loadFirebaseUser]);

  const finishMfaForSignUp = useCallback(
    async verificationCode => {
      const verificationResult = await verifyCode(verificationCode);
      const multiFactorUser = multiFactor(getAuth().currentUser);
      await multiFactorUser.enroll(verificationResult, MFA_HINT_NAME);
      return updateUser();
    },
    [updateUser, verifyCode]
  );

  const finishMfaForSignIn = useCallback(
    async (verificationCode, resolver) => {
      const verificationResult = await verifyCode(verificationCode);
      const credentials = await resolver.resolveSignIn(verificationResult);
      await loadFirebaseUser(credentials.user);
    },
    [loadFirebaseUser, verifyCode]
  );

  const localSignOut = useCallback(async () => {
    await signOut(getAuth());
    queryClient.clear();
    setUser(null);
  }, [setUser]);

  return {
    signIn,
    signInWithCustomToken: localSignInWithCustomToken,
    loadFirebaseUser,
    initializeRecaptcha,
    startMfaForSignIn,
    startMfaForSignUp,
    finishMfaForSignUp,
    finishMfaForSignIn,
    signOut: localSignOut,
  };
};
