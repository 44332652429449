import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const StarFilledIcon: React.FC<any> = props => (
  <SvgIcon {...props} fill="none" width={20} height={20} viewBox="0 0 20 20">
    <path
      fill="#FFE240"
      stroke="#F8B835"
      d="m11.995 7.177.117.29.312.021 4.938.347a.15.15 0 0 1 .086.265l-3.792 3.183-.239.201.076.303 1.196 4.804a.15.15 0 0 1-.225.163l-4.2-2.622-.264-.165-.265.165-4.199 2.622a.15.15 0 0 1-.225-.163l1.196-4.804.076-.303-.24-.2L2.554 8.1a.15.15 0 0 1 .085-.265l4.939-.347.311-.021.117-.29 1.856-4.59a.15.15 0 0 1 .278 0l1.856 4.59Z"
    />
  </SvgIcon>
);
