import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const ViewRecordsIcon: FC<any> = props => (
  <SvgIcon {...props} viewBox="0 0 20 18" width="20" height="18">
    <path
      d="M1.8 15.5C1.3 15.5 0.875 15.325 0.525 14.975C0.175 14.625 0 14.2 0 13.7V2.7C0 2.18333 0.175 1.75 0.525 1.4C0.875 1.05 1.3 0.875 1.8 0.875H7.875L9.5 2.5H17.2C17.7 2.5 18.125 2.675 18.475 3.025C18.825 3.375 19 3.8 19 4.3V7.5C19 7.77614 18.7761 8 18.5 8H18C17.7239 8 17.5 7.77614 17.5 7.5V4.3C17.5 4.21667 17.471 4.14567 17.413 4.087C17.3543 4.029 17.2833 4 17.2 4H8.875L7.275 2.375H1.8C1.71667 2.375 1.646 2.40433 1.588 2.463C1.52933 2.521 1.5 2.6 1.5 2.7V13.7C1.5 13.7833 1.52933 13.8543 1.588 13.913C1.646 13.971 1.71667 14 1.8 14H4C4.27614 14 4.5 14.2239 4.5 14.5V15C4.5 15.2761 4.27614 15.5 4 15.5H1.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6824 13.25C14.6824 14.3546 13.787 15.25 12.6824 15.25C11.5779 15.25 10.6824 14.3546 10.6824 13.25C10.6824 12.1454 11.5779 11.25 12.6824 11.25C13.787 11.25 14.6824 12.1454 14.6824 13.25ZM13.1824 13.25C13.1824 13.5261 12.9586 13.75 12.6824 13.75C12.4063 13.75 12.1824 13.5261 12.1824 13.25C12.1824 12.9739 12.4063 12.75 12.6824 12.75C12.9586 12.75 13.1824 12.9739 13.1824 13.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9715 12.2275C19.1769 12.5561 19.3461 12.8971 19.5049 13.25C19.3459 13.6028 19.1769 13.9439 18.9715 14.2725C18.7159 14.6815 18.3277 15.2266 17.7944 15.7736C16.7266 16.8687 15.0448 18 12.6824 18C10.3201 18 8.63821 16.8687 7.57042 15.7736C7.03716 15.2266 6.64894 14.6815 6.3933 14.2725C6.18793 13.9439 6.01879 13.6029 5.85999 13.25C6.01879 12.8971 6.18793 12.5561 6.3933 12.2275C6.64894 11.8185 7.03716 11.2734 7.57042 10.7264C8.63821 9.63126 10.3201 8.5 12.6824 8.5C15.0448 8.5 16.7266 9.63126 17.7944 10.7264C18.3277 11.2734 18.7159 11.8185 18.9715 12.2275ZM7.6653 13.0225C7.61296 13.1062 7.56759 13.1827 7.5291 13.25C7.56759 13.3173 7.61296 13.3938 7.6653 13.4775C7.8784 13.8185 8.20269 14.2734 8.64442 14.7264C9.52664 15.6313 10.8448 16.5 12.6824 16.5C14.5201 16.5 15.8382 15.6313 16.7204 14.7264C17.1622 14.2734 17.4864 13.8185 17.6996 13.4775C17.7519 13.3938 17.7973 13.3173 17.8357 13.25C17.7973 13.1827 17.7519 13.1062 17.6996 13.0225C17.4864 12.6815 17.1622 12.2266 16.7204 11.7736C15.8382 10.8687 14.5201 10 12.6824 10C10.8448 10 9.52664 10.8687 8.64442 11.7736C8.20269 12.2266 7.8784 12.6815 7.6653 13.0225Z"
      fill="currentColor"
    />
  </SvgIcon>
);
