import React, {FC, MouseEventHandler, useCallback} from 'react';
import {CircularProgress, FormLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form} from 'formik';
import styled from 'styled-components';

import {Button, CancelButton} from '../../../shared/components/button';
import {InputRadioButton} from '../../../shared/components/form';
import {colors} from '../../../shared/styles/theme';
import {EHR} from '../../portalPatients/hooks/useEHRs';
import {
  RequestRecordsFormValuesTypes,
  WordingsRequestRecordsType,
} from '../constatnts/types';

import {EhrAndMrnForm} from './ehrAndMrnForm';

type FormikNeededParams = {
  resetForm: () => void;
  setFieldValue: (arg1: string, arg2: string) => void;
  isValidating: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  dirty: boolean;
};

type PropsType = {
  formik: FormikNeededParams;
  values: RequestRecordsFormValuesTypes;
  EHRs: EHR[];
  EHRsIsLoading: boolean;
  EHRsHasError: string | null;
  redirectToInbox: MouseEventHandler<HTMLButtonElement> | undefined;
  addMRNisLoading: boolean;
  wordings: WordingsRequestRecordsType;
  requestedRecordsType: string;
};

const useRadioButtonWithDescriptionStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',

    '& .MuiFormLabel-root': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      color: colors.black,
      cursor: 'pointer',
    },

    '& .radio-button-description': {
      fontStyle: 'italic',
      fontSize: '14px',
      color: colors.grey801,
      marginTop: 0,
    },
  },
}));

const PROGRESS_SIZE = 22;

export const RequestRecordsForm: FC<PropsType> = ({
  formik,
  values,
  EHRs,
  EHRsIsLoading,
  EHRsHasError,
  redirectToInbox,
  addMRNisLoading,
  wordings,
  requestedRecordsType,
}) => {
  const onChange = useCallback(() => {
    formik.resetForm();
    formik.setFieldValue('isRequestHaveMedicalRecords', 'have');
  }, [formik]);
  const radioButtonStyles = useRadioButtonWithDescriptionStyles();

  const isShowHaveMedicalRecordsMrnForm = ({
    isRequestHaveMedicalRecords,
    areMedicalRecordNumbersExist,
  }: RequestRecordsFormValuesTypes) =>
    isRequestHaveMedicalRecords === 'have' && !areMedicalRecordNumbersExist;

  const isShowDoNotHaveMedicalRecordsMrnForm = ({
    isRequestHaveMedicalRecords,
    areMedicalRecordNumbersExist,
  }: RequestRecordsFormValuesTypes) =>
    isRequestHaveMedicalRecords === 'do-not-have' &&
    !areMedicalRecordNumbersExist;

  return (
    <Form>
      <div className={radioButtonStyles.root}>
        <div>
          <InputRadioButton
            name="isRequestHaveMedicalRecords"
            id="have-medical-records"
            value="have"
            inputProps={{
              'data-testid': 'have-medical-records-radio-btn',
            }}
            checked={values.isRequestHaveMedicalRecords === 'have'}
            onChange={onChange}
          />
        </div>
        <div>
          <FormLabel htmlFor="have-medical-records">
            {wordings[requestedRecordsType].isRecordsCheckBoxTitle}
          </FormLabel>
        </div>
        <div />
        <div>
          <p className="radio-button-description">
            {wordings[requestedRecordsType].isRecordsCheckBoxDescription}
          </p>

          {isShowHaveMedicalRecordsMrnForm(values) ? (
            <EhrAndMrnForm
              ehrFiledName="haveMedicalRecordsEhrId"
              mrnFiledName="haveMedicalRecordsMrn"
              EHRs={EHRs}
              EHRsIsLoading={EHRsIsLoading}
              EHRsHasError={EHRsHasError}
            />
          ) : null}
        </div>
      </div>

      <div className={radioButtonStyles.root}>
        <div>
          <InputRadioButton
            name="isRequestHaveMedicalRecords"
            id="do-not-have-medical-records"
            value="do-not-have"
            inputProps={{
              'data-testid': 'do-not-have-medical-records-radio-btn',
            }}
            checked={values.isRequestHaveMedicalRecords === 'do-not-have'}
            onChange={() => {
              formik.resetForm();
              formik.setFieldValue(
                'isRequestHaveMedicalRecords',
                'do-not-have'
              );
            }}
          />
        </div>
        <div>
          <FormLabel htmlFor="do-not-have-medical-records">
            {wordings[requestedRecordsType].noRecordsCheckBoxTitle}
          </FormLabel>
        </div>
        <div />
        <div>
          <p className="radio-button-description">
            {wordings[requestedRecordsType].noRecordsCheckBoxDescription}
          </p>

          {isShowDoNotHaveMedicalRecordsMrnForm(values) ? (
            <EhrAndMrnForm
              ehrFiledName="doNotHaveMedicalRecordsEhrId"
              mrnFiledName="doNotHaveMedicalRecordsMrn"
              EHRs={EHRs}
              EHRsIsLoading={EHRsIsLoading}
              EHRsHasError={EHRsHasError}
            />
          ) : null}
        </div>
      </div>

      <FooterWrapper>
        <CancelButton onClick={redirectToInbox} />
        <Button
          type="submit"
          testID="confirm-button"
          disabled={formik.isSubmitting || !formik.isValid || addMRNisLoading}
        >
          {addMRNisLoading ? (
            <CircularProgress size={PROGRESS_SIZE} />
          ) : (
            'Confirm'
          )}
        </Button>
      </FooterWrapper>
    </Form>
  );
};

const FooterWrapper = styled.div`
  margin-top: 40px;
`;
