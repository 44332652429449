import React, {useCallback, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../../shared/atoms/authAtom';
import {isOpenAccessDeniedModalState} from '../../../../shared/atoms/isOpenAccessDeniedModalState';
import {InfoDialog} from '../../../../shared/components/infoDialog';
import {ActionTableButton} from '../../../../shared/components/table/actionTableButton';
import {ROLES} from '../../../../shared/constants/roles';
import {USER_TYPES} from '../../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../../shared/hooks/useCustomizedSnackbar';
import {ActionIcon} from '../../../../shared/icons/actionIcon';
import {DisconnectIcon} from '../../../../shared/icons/disconnectIcon';
import {SendIcon} from '../../../../shared/icons/sendIcon';
import {ViewRecordsIcon} from '../../../../shared/icons/viewRecordsIcon';
import {
  fetchConnectPatient,
  fetchShareMedicalRecordsWithProvider,
} from '../../../../shared/utils/fetchers';
import {isRoleMatched} from '../../../../shared/utils/user';
import {isPatientConnectingState} from '../../atoms/isPatientConnectingState';
import {isSharingMedicalRecordsWithProviderState} from '../../atoms/isSharingMedicalRecordsWithProviderState';
import {selectedPatientState} from '../../atoms/selectedPatientState';
import {
  CONNECTION_STATUSES,
  MEDICAL_RECORDS_INITIATION_TYPES,
} from '../../constants/patientStatuses';
import {useConfirmDisconnectPatient} from '../../hooks/useConfirmDisconnectPatient';
import {useConfirmViewRecords} from '../../hooks/useConfirmViewRecords';
import {useShareMedicalRecordsWithProviderPermissions} from '../../hooks/useShareMedicalRecordsWithProviderPermissions';
import {useToggleDrawer} from '../../hooks/useToggleDrawer';

type Props = {
  connectionStatus: CONNECTION_STATUSES;
  connectionId: number;
  consumerUuid: string;
  relyingPartyId: number;
  firstName: string;
  name: string;
  isUpdateRequested: boolean;
  isSharingMedicalRecords: boolean;
  updatePatientsList: () => Promise<void>;
};

export const PatientDataRequestActionsButtons = ({
  connectionStatus,
  connectionId,
  consumerUuid,
  relyingPartyId,
  isUpdateRequested,
  isSharingMedicalRecords,
  updatePatientsList,
  firstName,
  name,
}: Props): JSX.Element => {
  const [toggleDrawer] = useToggleDrawer();
  const showMessage = useCustomizedSnackbar();
  const [
    isOpenPendingConnectionRequestModalWindow,
    setIsOpenPendingConnectionRequestModalWindow,
  ] = useState<boolean>(false);
  const [
    isOpenPendingShareRequestModalWindow,
    setIsOpenPendingShareRequestModalWindow,
  ] = useState<boolean>(false);
  const setIsOpenAccessDeniedModalState = useSetRecoilState(
    isOpenAccessDeniedModalState
  );
  const [connectPatientIsLoading, setConnectPatientIsLoading] = useRecoilState(
    isPatientConnectingState
  );
  const [
    shareMedicalRecordsWithProviderIsLoading,
    setShareMedicalRecordsWithProviderIsLoading,
  ] = useRecoilState(isSharingMedicalRecordsWithProviderState);
  const currentUser = useRecoilValue(currentUserState);
  const isPatientConnected = connectionStatus === CONNECTION_STATUSES.CONNECTED;
  const confirmOpenRecords = useConfirmViewRecords(
    isPatientConnected,
    isSharingMedicalRecords
  );
  const confirmDisconnect = useConfirmDisconnectPatient(
    isPatientConnected,
    isSharingMedicalRecords,
    connectionId
  );
  const setSelectedPatient = useSetRecoilState(selectedPatientState);
  const canShareMedicalRecordsWithProvider =
    useShareMedicalRecordsWithProviderPermissions();

  const isPhysicianHasRightRolesForOpenDrawer =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ]) && USER_TYPES.PHYSICIAN === currentUser?.type;

  const isNonPhysicianHasRightRolesForOpenDrawer =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ]) && USER_TYPES.NON_PHYSICIAN === currentUser?.type;

  const connectPatient = useCallback(async () => {
    setConnectPatientIsLoading(true);

    try {
      await fetchConnectPatient({
        relying_party_id: relyingPartyId,
        client_id: currentUser?.id,
        consumer_uuid: consumerUuid,
        patient_first_name: firstName,
      });
      showMessage('Connection request sent successfully.', 'success');
      await updatePatientsList();
    } catch (error) {
      showMessage('Connection request is failed. Please try again.', 'error');
    } finally {
      setConnectPatientIsLoading(false);
    }
  }, [
    consumerUuid,
    currentUser?.id,
    firstName,
    relyingPartyId,
    setConnectPatientIsLoading,
    showMessage,
    updatePatientsList,
  ]);

  const shareMedicalRecordsWithProvider = useCallback(async () => {
    if (canShareMedicalRecordsWithProvider) {
      setShareMedicalRecordsWithProviderIsLoading(true);

      try {
        await fetchShareMedicalRecordsWithProvider({
          relying_party_id: relyingPartyId,
          client_id: currentUser?.id,
          consumer_uuid: consumerUuid,
          connection_id: connectionId,
          type: MEDICAL_RECORDS_INITIATION_TYPES.CHANGE_CONNECTION,
        });
        showMessage('Medical records request sent successfully.', 'success');
        await updatePatientsList();
      } catch (error) {
        showMessage(
          'Medical records request is failed. Please try again.',
          'error'
        );
      } finally {
        setShareMedicalRecordsWithProviderIsLoading(false);
      }
    } else {
      setIsOpenAccessDeniedModalState(true);
    }
  }, [
    canShareMedicalRecordsWithProvider,
    connectionId,
    consumerUuid,
    currentUser?.id,
    relyingPartyId,
    setIsOpenAccessDeniedModalState,
    setShareMedicalRecordsWithProviderIsLoading,
    showMessage,
    updatePatientsList,
  ]);

  const IsNotAllowShareMedicalRecordsWithProvider =
    connectPatientIsLoading ||
    shareMedicalRecordsWithProviderIsLoading ||
    isSharingMedicalRecords ||
    isUpdateRequested;

  const handleGetViewMedicalRecordsActionButton = useCallback(() => {
    confirmOpenRecords(consumerUuid, name);
    setSelectedPatient({
      patientName: name,
      patientUuid: consumerUuid,
    });
  }, [confirmOpenRecords, consumerUuid, name, setSelectedPatient]);

  const onClick = useCallback(
    () => setIsOpenPendingConnectionRequestModalWindow(true),
    []
  );

  const onClickConnected = useCallback(
    () =>
      IsNotAllowShareMedicalRecordsWithProvider
        ? setIsOpenPendingShareRequestModalWindow(true)
        : shareMedicalRecordsWithProvider(),
    [IsNotAllowShareMedicalRecordsWithProvider, shareMedicalRecordsWithProvider]
  );
  const onClickDefault = useCallback(
    () =>
      connectPatientIsLoading
        ? setIsOpenPendingConnectionRequestModalWindow(true)
        : connectPatient(),
    [connectPatient, connectPatientIsLoading]
  );
  const onCloseDialogShare = useCallback(() => {
    setIsOpenPendingShareRequestModalWindow(false);
  }, []);

  const onCloseDialogConnection = useCallback(() => {
    setIsOpenPendingConnectionRequestModalWindow(false);
  }, []);
  const getConnectionActionButton = () => {
    switch (connectionStatus) {
      case CONNECTION_STATUSES.PENDING:
        return (
          <ActionTableButton
            onClick={onClick}
            testId={`pending-request-btn-${consumerUuid}`}
            title="Request Connection"
          >
            <SendIcon />
          </ActionTableButton>
        );
      case CONNECTION_STATUSES.CONNECTED:
        return !isSharingMedicalRecords ? (
          <ActionTableButton
            testId={`request-records-btn-${consumerUuid}`}
            onClick={onClickConnected}
            title="Request Record Sharing"
          >
            <SendIcon />
          </ActionTableButton>
        ) : null;
      default:
        return (
          <ActionTableButton
            testId={`connect-patient-btn-${consumerUuid}`}
            onClick={onClickDefault}
            title="Request Connection"
          >
            <SendIcon />
          </ActionTableButton>
        );
    }
  };

  const handleGetDetailsActionButton = useCallback(
    () => toggleDrawer(consumerUuid, name),
    [consumerUuid, name, toggleDrawer]
  );

  const handleDisconnect = useCallback(
    () => confirmDisconnect(consumerUuid, name),
    [confirmDisconnect, consumerUuid, name]
  );

  const getDetailsActionButton = () => {
    const isAllowedOpenDrawer =
      isSharingMedicalRecords &&
      isPatientConnected &&
      (isNonPhysicianHasRightRolesForOpenDrawer ||
        isPhysicianHasRightRolesForOpenDrawer);

    return isAllowedOpenDrawer ? (
      <ActionTableButton
        onClick={handleGetDetailsActionButton}
        title="Patient Details"
        testId={`patient-details-btn-${consumerUuid}`}
      >
        <ActionIcon />
      </ActionTableButton>
    ) : null;
  };

  const getDisconnectActionButton = () => (
    <ActionTableButton
      onClick={handleDisconnect}
      title="Disconnect Patient"
      testId={`patient-disconnect-btn-${consumerUuid}`}
    >
      <DisconnectIcon />
    </ActionTableButton>
  );

  const getViewMedicalRecordsActionButton = () =>
    isSharingMedicalRecords && isPatientConnected ? (
      <ActionTableButton
        onClick={handleGetViewMedicalRecordsActionButton}
        title="View Medical Record"
        testId={`view-medical-record-btn-${consumerUuid}`}
      >
        <ViewRecordsIcon />
      </ActionTableButton>
    ) : null;

  return (
    <>
      {getDetailsActionButton()}
      {getViewMedicalRecordsActionButton()}
      {getConnectionActionButton()}
      {isPatientConnected && getDisconnectActionButton()}

      <InfoDialog
        title="Connection request"
        content="Connection request has already been sent to the patient."
        isOpen={isOpenPendingConnectionRequestModalWindow}
        onClose={onCloseDialogConnection}
        buttonTitle="Ok"
      />
      <InfoDialog
        title="Record sharing request"
        content="Record sharing request has already been sent to the patient."
        isOpen={isOpenPendingShareRequestModalWindow}
        onClose={onCloseDialogShare}
        buttonTitle="Ok"
      />
    </>
  );
};
