import {createTheme, ThemeOptions} from '@material-ui/core/styles';
import type {} from '@material-ui/lab/themeAugmentation';

export const colors = {
  primary: '#244782',
  logo: '#0054A6',
  lightPrimary: '#597AB3',
  blue: '#5B8FF4',
  darkBlue: '#183058',
  lightBlue: '#82A9F7',
  darkPrimary: '#163466',
  green: '#268E6C',
  green50: '#F0F7F5',
  green100: '#DCECE7',
  greenLight: '#15CC5B',
  red: '#EB5757',
  red12: '#EB575712',
  red26: '#EB57571A',
  red50: '#FEF3F3',
  red100: '#FDE8E8',
  greenText: '#219653',
  black: '#323232',
  black100: '#000',
  backgroundPrimary: '#C6C6C6',
  backgroundLightPrimary: '#E9EDF3',
  statusBlue: '#026AE0',
  statusGreen: '#268E6C',
  white: '#ffffff',
  grey25: '#f9f9f9',
  grey40: '#F7F7F7',
  grey50: '#F2F2F2',
  grey70: '#F4F4F4',
  grey100: '#e3e3e3',
  grey700: '#F6F6F6',
  grey800: '#E6E6E6',
  grey200: '#CCCCCC',
  grey250: '#D5D9E2',
  grey300: '#A5a5a5',
  grey400: '#707070',
  grey500: '#898989',
  grey600: '#323232',
  grey801: '#7A7A7A',
  grey900: '#C8C8C8',
  grey1000: '#FAFAFA',
  grey1100: '#C5C5C5',
  grey1200: '#f8f8f8',
  newGrey400: '#C0C1C1', // TODO: Remove this color after updating MUI v.4 to v.5
};

export const tableColors = {
  tableRow: colors.white,
  tableRowHover: '#EDF0F5',
  tableRowSelected: '#E5E9F0',
  tableRowSelectedHover: '#DCE2EB',
};

export const ANCHOR = {
  anchorReference: 'anchorEl',
  elevation: 0,
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  style: {
    left: 0,
    top: 10,
  },
  styleChatMenu: {
    left: 0,
    margin: '-16px 80px 70px 0px',
  },
  styleChatMenuItem: {
    padding: '8px 58px 8px 16px',
  },
};

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: colors.lightPrimary,
      main: colors.primary,
      dark: colors.darkPrimary,
      contrastText: colors.white,
    },
    error: {
      light: colors.red,
      main: colors.red,
      dark: colors.red,
      contrastText: colors.white,
    },
    text: {
      primary: colors.black,
      secondary: colors.black,
      disabled: colors.grey900,
      hint: colors.primary,
    },
    grey: {
      50: colors.grey50,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.newGrey400,
      500: colors.grey500,
      600: colors.grey600,
      800: colors.grey801,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    background: {
      default: '#FFFFFF',
      // paper: '#F5F6F6',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 14,
  },
  overrides: {
    MuiInputLabel: {
      formControl: {
        position: 'relative',
        transform: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '12px',
        lineHeight: '24px',
        textAlign: 'left',
        color: colors.grey801,
        '&.Mui-disabled': {
          color: colors.grey801,
        },
      },
      asterisk: {
        color: colors.red,
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        fontStyle: 'italic',
        color: colors.grey500,
        '&:empty::before': {
          content: '""',
          display: 'inline-block',
        },
        '&.Mui-disabled': {
          color: colors.grey900,
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputBase: {
      root: {
        alignItems: 'stretch',
      },
    },
    MuiInputAdornment: {
      root: {
        height: 'auto',
        maxHeight: 'auto',
      },
    },
    MuiSelect: {
      root: {
        color: colors.black,
        display: 'flex',
        alignItems: 'center',
      },
      icon: {
        color: colors.black,
      },
      selectMenu: {
        minHeight: '34px',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiMenu: {
      paper: {
        border: `1px solid ${colors.grey200}`,
        boxSizing: 'border-box',
        // backgroundColor: 'red',
      },
      list: {
        padding: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          top: 0,
        },
        '& legend': {
          display: 'none',
        },
        '&.MuiInputBase-adornedEnd': {
          padding: 0,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.grey1000,
          '& input': {
            backgroundColor: 'transparent',
            color: colors.black,
            '&::placeholder': {
              color: colors.backgroundPrimary,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.grey1000,
          },
        },
      },
      input: {
        minHeight: '34px',
        padding: '5px 16px',
      },
    },
    MuiChip: {
      root: {
        color: colors.black,
        backgroundColor: colors.grey40,
      },
      deletable: {
        '&:hover': {
          backgroundColor: colors.grey70,
        },
        '&:focus': {
          backgroundColor: colors.grey70,
        },
      },
      clickable: {
        '&:hover': {
          backgroundColor: colors.grey70,
        },
        '&:focus': {
          backgroundColor: colors.grey70,
        },
      },
    },
    MuiTableContainer: {
      root: {
        border: `1px solid ${colors.grey800}`,
        boxSizing: 'border-box',
      },
    },
    MuiTableRow: {
      root: {
        '&.highlight td.MuiTableCell-root': {
          backgroundColor: tableColors.tableRowSelected,
        },
        '&.Mui-selected td.MuiTableCell-root': {
          backgroundColor: tableColors.tableRowSelected,
        },
        '&.Mui-selected.MuiTableRow-hover:hover td.MuiTableCell-root': {
          backgroundColor: tableColors.tableRowSelectedHover,
        },
        '&.MuiTableRow-hover:hover td.MuiTableCell-root': {
          backgroundColor: tableColors.tableRowHover,
        },
        '&:last-of-type td': {
          border: 'none',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '10px 30px',
        backgroundColor: tableColors.tableRow,
        fontSize: '1rem',
        '&.stickyLeft': {
          position: 'sticky',
          left: 0,
          borderRight: `1px solid ${colors.grey800}`,
          boxShadow: '3px 0px 8px rgba(176, 176, 176, 0.25)',
          clipPath: 'inset(0px -16px 0px 0px)',
          zIndex: 10,
        },
        '&.stickyRight': {
          position: 'sticky',
          right: 0,
          borderLeft: `1px solid ${colors.grey800}`,
          boxShadow: '-3px 0px 8px rgba(176, 176, 176, 0.25)',
          clipPath: 'inset(0px 0px 0px -16px)',
          zIndex: 10,
        },
      },
      head: {
        fontWeight: 700,
        padding: '8px 30px',
        backgroundColor: colors.grey700,
        '&.stickyRight': {
          backgroundColor: colors.grey700,
          zIndex: 15,
        },
        '&.stickyLeft': {
          backgroundColor: colors.grey700,
          zIndex: 15,
        },
      },
      stickyHeader: {
        top: 0,
        left: 'auto',
        zIndex: 11,
        backgroundColor: colors.grey700,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minHeight: 'auto',
        marginRight: '4px',
        fontSize: '1rem',
        padding: '8px 28px',
        borderRadius: '4px 4px 0 0',
        backgroundColor: colors.grey700,
        fontWeight: 'bold',
        '&:hover': {
          color: colors.grey400,
          opacity: 1,
        },
        '&:focus': {
          color: colors.white,
          backgroundColor: colors.primary,
        },
        '&$selected': {
          color: colors.white,
          backgroundColor: colors.primary,
        },
        '&$disabled': {
          color: colors.grey900,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.backgroundPrimary,
      },
      indeterminate: {
        '&.MuiCheckbox-colorPrimary': {
          color: colors.primary,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&': {
          color: colors.backgroundPrimary,
        },
        '&.Mui-checked': {
          color: colors.primary,
        },
        color: colors.backgroundPrimary,
      },
    },
    MuiAutocomplete: {
      root: {
        '& .MuiInputBase-root[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]':
          {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input':
          {
            padding: '5px 16px',
          },
      },
      paper: {
        margin: 0,
      },
      listbox: {
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiPagination: {
      root: {
        '& .MuiPagination-ul li:last-of-type button': {
          marginRight: 0,
        },
        '& .MuiPagination-ul li:first-of-type button': {
          marginLeft: 0,
        },
      },
    },
    MuiPaginationItem: {
      page: {
        color: colors.primary,
        '&.Mui-selected': {
          borderColor: colors.primary,
          backgroundColor: `${colors.primary}10`,
        },
        '&.Mui-disabled': {
          color: 'unset',
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
