import React, {useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {Form, Formik} from 'formik';
import {useRecoilState} from 'recoil';

import {currentTenantIdState} from '../../shared/atoms/authAtom';
import {Button} from '../../shared/components/button';
import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {SetPassword} from '../components/setPassword';
import {SignPageTemplate} from '../components/signPageTemplate';
import {errorMessage, firebaseError} from '../constants/constants';
import {passwordValidator} from '../utils/passwordValidator';

const EXPIRED_CODE = 'auth/expired-action-code';
const INVALID_CODE = 'auth/invalid-action-code';

const useQuery = () => new URLSearchParams(useLocation().search);
const handleFirebaseError = (error, showMessage) => {
  if (error.code === INVALID_CODE) {
    showMessage('Incorrect link, please try again.', 'error');
  } else if (error.code === EXPIRED_CODE) {
    showMessage('The link has expired, please request a new link.', 'error');
  } else if (error.code === firebaseError.INTERNET_ERROR) {
    showMessage(errorMessage.NETWORK_ERROR, 'error');
  } else {
    showMessage(error.message, 'error');
  }
};

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiButton-root': {
      width: 256,
      height: 32,
      fontSize: 12,
      marginTop: 30,
    },
  },
}));

export const ChangePasswordPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const showMessage = useCustomizedSnackbar();
  const [tenantId, setTenantId] = useRecoilState(currentTenantIdState);
  getAuth().tenantId = query.get('tenantId');
  setTenantId(query.get('tenantId'));
  useEffect(() => {
    verifyPasswordResetCode(getAuth(), query.get('oobCode')).catch(error => {
      handleFirebaseError(error, showMessage);
    });
  }, [query, showMessage]);

  const handleSubmit = useCallback(
    async values => {
      try {
        await confirmPasswordReset(
          getAuth(),
          query.get('oobCode'),
          values.password
        );
        showMessage('Password reset successfully.', 'success');
        history.push(`/${tenantId}/sign-in`);
      } catch (error) {
        handleFirebaseError(error, showMessage);
      }
    },
    [history, tenantId, query, showMessage]
  );

  const validate = useCallback(values => passwordValidator(values), []);

  return (
    <SignPageTemplate title="CHANGE PASSWORD" isLogoLeft>
      <Formik
        initialValues={{password: '', confirmPassword: ''}}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({
          isValidating,
          errors,
          isSubmitting,
          isValid,
          touched,
          values,
          handleChange,
          setFieldTouched,
        }) => (
          <Form className={classes.root}>
            <SetPassword
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
            />
            <Button
              type="submit"
              testID="resetPasswordButton"
              disabled={!isValid || isValidating || isSubmitting}
            >
              RESET PASSWORD
            </Button>
          </Form>
        )}
      </Formik>
    </SignPageTemplate>
  );
};
