import React, {FC, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Typography} from '@material-ui/core';

import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button} from '../../../shared/components/button';
import {initialResendInvitationModalWindowsState} from '../constants/initialResendInvitationModalWindowsState';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {HandleResendInvitationModalWindowsType} from '../constants/types';

import {ModalWindowBody} from './modalWindow/modalWindowBody';
import {ModalWindowFooter} from './modalWindow/modalWindowFooter';
import {ModalWindowTitle} from './modalWindow/modalWindowTitle';

type Props = {
  patientName: string;
  isShowModalWindow: boolean;
  setIsShowModalWindow: (val: HandleResendInvitationModalWindowsType) => void;
};

export const PatientAlreadyConnectedModalWindow: FC<Props> = ({
  patientName,
  isShowModalWindow,
  setIsShowModalWindow,
}) => {
  const [existingRedirect, setExistingRedirect] = useState<null | string>(null);

  const viewPatient = async () => {
    setIsShowModalWindow(initialResendInvitationModalWindowsState);
    setExistingRedirect(PORTAL_PATIENTS_ROUTES.CONNECTIONS);
  };

  const onBack = () => {
    setIsShowModalWindow(initialResendInvitationModalWindowsState);
    setExistingRedirect(PORTAL_PATIENTS_ROUTES.INVITATIONS);
  };

  if (existingRedirect) {
    return <Redirect to={existingRedirect} />;
  }

  return (
    <BaseModalWindow minWidth={350} maxWidth={480} open={isShowModalWindow}>
      <ModalWindowTitle>Patient already connected</ModalWindowTitle>
      <ModalWindowBody>
        <Typography data-testid="patient-already-connected-modal">
          {`${patientName} made a connection with you. You can now view this
          patient on the Connections tab.`}
        </Typography>
      </ModalWindowBody>
      <ModalWindowFooter>
        <Button
          color="primary"
          variant="outlined"
          onClick={onBack}
          data-testid="back-to-invitations"
        >
          Back to Invitations
        </Button>
        <Button
          color="primary"
          onClick={viewPatient}
          data-testid="view-patient"
        >
          View Connections
        </Button>
      </ModalWindowFooter>
    </BaseModalWindow>
  );
};
