import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';
import {ItemCell} from '../../layout/components/itemCell';

export default function FieldsInfo({title, fields, name, ...props}) {
  return (
    <InformationContainer {...props}>
      <Title>{title}</Title>
      <FieldsContainer>
        {fields?.map(({label, value}) => (
          <Field key={label} label={label} value={value} title={name} />
        ))}
      </FieldsContainer>
    </InformationContainer>
  );
}

const InformationContainer = styled.div``;

const Title = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  color: ${colors.black};
`;

const FieldsContainer = styled.div`
  display: flex;
`;

const Field = styled(ItemCell)`
  margin-right: 20px;
`;

FieldsInfo.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};
