import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {fetchAddMRN, fetchDeleteMRN, fetchUpdateMRN} from '../utils/fetchers';

import {EHR} from './useEHRs';

export const useMRNs = (): {
  addMRNisLoading: boolean;
  onAddMRN: (
    consumerUuid: string,
    ehr: EHR,
    mrn: string,
    successCallback: () => void
  ) => Promise<void>;
  editMRNisLoading: boolean;
  onEditMRN: (
    consumerUuid: string,
    mrnId: string,
    ehr: EHR,
    mrn: string,
    successCallback: () => void
  ) => Promise<void>;
  deleteMRNisLoading: boolean;
  onDeleteMRN: (
    consumerUuid: string,
    mrnId: string,
    relyingPartyId: number,
    successCallback: () => void
  ) => Promise<void>;
} => {
  const queryClient = useQueryClient();
  const showMessage = useCustomizedSnackbar();

  const [addMRNisLoading, setAddMRNisLoading] = useState<boolean>(false);
  const [editMRNisLoading, setEditMRNisLoading] = useState<boolean>(false);
  const [deleteMRNisLoading, setDeleteMRNisLoading] = useState<boolean>(false);
  const onAddMRN = async (
    consumerUuid: string,
    ehr: EHR,
    mrn: string,
    successCallback: () => void
  ) => {
    try {
      setAddMRNisLoading(true);

      await fetchAddMRN(consumerUuid, {
        ehr,
        mrn,
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['patientDetails', consumerUuid, ehr.relying_party_id],
        }),
        queryClient.invalidateQueries({
          queryKey: ['myPatients'],
        }),
        await queryClient.invalidateQueries({
          queryKey: ['enrolledPatients'],
        }),
      ]);

      successCallback();
    } catch (e) {
      showMessage('Something went wrong.', 'error');
    } finally {
      setAddMRNisLoading(false);
    }
  };

  const onEditMRN = async (
    consumerUuid: string,
    mrnId: string,
    ehr: EHR,
    mrn: string,
    successCallback: () => void
  ) => {
    try {
      setEditMRNisLoading(true);

      await fetchUpdateMRN(consumerUuid, mrnId, {
        ehr: {
          id: ehr.id,
          link: ehr.link,
          relying_party_id: ehr.relying_party_id,
        },
        mrn,
      });

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['patientDetails', consumerUuid, ehr.relying_party_id],
        }),
        queryClient.invalidateQueries({
          queryKey: ['myPatients'],
        }),
        await queryClient.invalidateQueries({
          queryKey: ['enrolledPatients'],
        }),
      ]);

      successCallback();
    } catch (e) {
      showMessage('Something went wrong.', 'error');
    } finally {
      setEditMRNisLoading(false);
    }
  };

  const onDeleteMRN = async (
    consumerUuid: string,
    mrnId: string,
    relyingPartyId: number,
    successCallback: () => void
  ) => {
    try {
      setDeleteMRNisLoading(true);

      await fetchDeleteMRN(consumerUuid, mrnId);

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['patientDetails', consumerUuid, relyingPartyId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['myPatients'],
        }),
        await queryClient.invalidateQueries({
          queryKey: ['enrolledPatients'],
        }),
      ]);

      successCallback();
    } catch (e) {
      showMessage('Something went wrong.', 'error');
    } finally {
      setDeleteMRNisLoading(false);
    }
  };

  return {
    addMRNisLoading,
    onAddMRN,
    editMRNisLoading,
    onEditMRN,
    deleteMRNisLoading,
    onDeleteMRN,
  };
};
