import React, {createRef, forwardRef, useCallback} from 'react';
import {Checkbox, FormControlLabel, Menu, MenuItem} from '@material-ui/core';
import {PopoverOrigin} from '@material-ui/core/Popover/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {ANCHOR} from '../styles/theme';

import {Button} from './button';

type Props = {
  id: string;
  testId: string;
  label: string;
  items: {[n: string]: string};
  selectedItems: string[];
  toggleItem: (item: string) => void;
  disabled?: boolean;
};

export const FilterWithCheckboxes: React.FC<Props> = ({
  id,
  items,
  label,
  testId,
  selectedItems,
  toggleItem,
  disabled,
}) => {
  const ref = createRef<HTMLInputElement>();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <Button
        data-testid={testId ? `${testId}-filter-with-checkboxes` : null}
        variant="outlined"
        aria-controls={id}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <Menu
        id={id}
        elevation={ANCHOR.elevation}
        getContentAnchorEl={ANCHOR.getContentAnchorEl}
        anchorOrigin={ANCHOR.anchorOrigin as PopoverOrigin}
        style={ANCHOR.style}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(items).map(item => (
          <MenuItemFun
            key={item}
            items={items}
            item={item}
            selectedItems={selectedItems}
            toggleItem={toggleItem}
            ref={ref}
          />
        ))}
      </Menu>
    </div>
  );
};

interface MenuItemFunProps {
  items: {[n: string]: string};
  item: string;
  selectedItems: string[];
  toggleItem: (item: string) => void;
}

type RefType = HTMLInputElement;

const MenuItemFun = forwardRef<RefType, MenuItemFunProps>(
  ({items, item, selectedItems, toggleItem}, _ref) => {
    const onClick = useCallback(
      e => {
        e.preventDefault();
        toggleItem(item);
      },
      [item, toggleItem]
    );

    return (
      <MenuItem
        data-testid={items[item]}
        disableRipple
        key={item}
        selected={selectedItems.includes(item)}
        onClick={onClick}
      >
        <FormControlLabel
          ref={_ref}
          control={
            <Checkbox color="primary" checked={selectedItems.includes(item)} />
          }
          label={items[item]}
        />
      </MenuItem>
    );
  }
);

MenuItemFun.displayName = 'MenuItemFun';
