import {useCallback} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {PaginationState} from '@tanstack/react-table';

import {REFETCH_INTERVAL} from '../../../shared/constants/refetchInterval';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {addDays, formatLocalTimeToUTC} from '../../../shared/utils/formatDate';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {getTotalPages} from '../../portalPatients/utils/getTotalPages';
import {
  EVENTS_CATEGORY,
  EVENTS_CATEGORY_DROPDOWN,
  filterValuesForRequestType,
  filterValuesType,
  PatientsNamesType,
  ProviderInboxEventsResponseType,
  RemappedPatientsNamesType,
  RemappedPhysiciansType,
  TABLE_TYPE_ENUM,
} from '../constatnts/types';
import {fetchInboxProviderEvents} from '../utils/fetchers';

type HookReturnType = {
  data: ProviderInboxEventsResponseType;
  isLoading: boolean;
  isFetching: boolean;
  isFetchedAfterMount: boolean;
};

const getClientsIds = (provider: null | RemappedPhysiciansType) => {
  if (provider) {
    return [provider.id];
  }
  return [];
};

const getPatientsNames = (patientsNames: RemappedPatientsNamesType[]) => {
  if (patientsNames && patientsNames?.length !== 0) {
    return patientsNames.map(
      (el: RemappedPatientsNamesType): PatientsNamesType => ({
        first_name: el.label.split(' ')[0],
        last_name: el.label.split(' ')[1],
      })
    );
  }
  return [];
};

const handleEndDate = (date: Date) => {
  const endDay = addDays(date.toDateString(), 1);
  return formatLocalTimeToUTC(endDay);
};

const getEventsCategoryTypeForRequest = (
  eventCategories: EVENTS_CATEGORY_DROPDOWN[]
): EVENTS_CATEGORY[] => {
  const formattedEventCategories: EVENTS_CATEGORY[] = eventCategories
    .map(eventCategory => {
      switch (eventCategory) {
        case EVENTS_CATEGORY_DROPDOWN.CONNECTION_DENIED:
          return EVENTS_CATEGORY.PATIENT_DENIED_CONNECTION_REQUEST;
        case EVENTS_CATEGORY_DROPDOWN.CONNECTION_REQUEST_EXPIRED:
          return EVENTS_CATEGORY.PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED;
        case EVENTS_CATEGORY_DROPDOWN.INVITATION_EXPIRED:
          return EVENTS_CATEGORY.PATIENT_INVITATION_EXPIRED;
        case EVENTS_CATEGORY_DROPDOWN.DISCONNECTED:
          return EVENTS_CATEGORY.PATIENT_DISCONNECTED;
        case EVENTS_CATEGORY_DROPDOWN.SHARING_DENIED:
          return EVENTS_CATEGORY.PATIENT_DENIED_SHARE_REQUEST;
        case EVENTS_CATEGORY_DROPDOWN.SHARING_REQUEST_EXPIRED:
          return EVENTS_CATEGORY.PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED;
        case EVENTS_CATEGORY_DROPDOWN.UPLOAD_IMAGES:
          return [
            EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD,
            EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE,
          ];
        case EVENTS_CATEGORY_DROPDOWN.UPLOAD_TEXT_RECORDS:
          return [
            EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD,
            EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE,
          ];
        case EVENTS_CATEGORY_DROPDOWN.RECORD_UPDATE_RECEIVED:
          return EVENTS_CATEGORY.PATIENT_RECORD_UPDATE_RECEIVED;
        default:
          return [];
      }
    })
    .flat();

  return formattedEventCategories;
};

const formatFilterValuesForRequest = (
  filterValues: filterValuesType
): filterValuesForRequestType => ({
  status: filterValues.status,
  event_categories: getEventsCategoryTypeForRequest(
    filterValues.eventCategories
  ),
  client_ids: getClientsIds(filterValues.provider),
  patient_names: getPatientsNames(filterValues.patientNames),
  from_date: filterValues.dateRange[0]
    ? formatLocalTimeToUTC(filterValues.dateRange[0])
    : null,
  to_date: filterValues.dateRange[1]
    ? handleEndDate(filterValues.dateRange[1])
    : null,
});

const getInboxProviderEvents = async (
  filterValues: filterValuesType,
  pageIndex: number,
  pageSize: number
) => {
  try {
    const requestBody: filterValuesForRequestType =
      formatFilterValuesForRequest(filterValues);
    const inboxProviderEvents = await fetchInboxProviderEvents(
      requestBody,
      pageIndex,
      pageSize
    );
    return inboxProviderEvents;
  } catch (e) {
    throw new Error('Something went wrong. Please try refresh the page.');
  }
};

export const useInboxProviderEvents = (
  filterValues: filterValuesType,
  {pageIndex, pageSize}: PaginationState,
  nextPage: number
): HookReturnType => {
  const handleError = useErrorHandling();
  const queryClient = useQueryClient();

  const prefetchNextPage = useCallback(
    async (useQueryKey, fetchFn) => {
      await queryClient.prefetchQuery({
        queryKey: useQueryKey,
        queryFn: () => fetchFn(),
      });
    },
    [queryClient]
  );

  const handleGetInboxProviderEvents = async () => {
    const inboxProviderEvents = await getInboxProviderEvents(
      filterValues,
      pageIndex,
      pageSize
    );
    if (nextPage < getTotalPages(inboxProviderEvents.count)) {
      await prefetchNextPage(
        ['inbox-providers-events', filterValues, nextPage],
        () => getInboxProviderEvents(filterValues, nextPage, pageSize)
      );
    }
    return inboxProviderEvents;
  };

  const {
    data = {count: 0, events: []},
    isLoading,
    isFetching,
    isFetchedAfterMount,
  } = useQuery({
    queryKey: ['inbox-providers-events', filterValues, pageIndex],
    queryFn: () => handleGetInboxProviderEvents(),
    onError: wrapAsyncFunction(async e => {
      await handleError(e);
    }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: () =>
      filterValues.status === TABLE_TYPE_ENUM.TO_DO ? REFETCH_INTERVAL : false,
  });

  return {
    data,
    isLoading,
    isFetching,
    isFetchedAfterMount,
  };
};
