import useSWR from 'swr';

import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {urls} from '../../../shared/utils/urls';

export const useRelyingPartyPortalUsers = relyingPartyId => {
  const {data: portalUsers} = useSWR(urls.relyingPartyUsers(relyingPartyId));

  return portalUsers?.map(user => ({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    phone_number: formatPhoneNumber(user.phone_number),
    roles: user.roles,
  }));
};
