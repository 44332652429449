import {useState} from 'react';

import {MedicalFileObject} from '../../../../../shared/atoms/medicalFileInAiChat';
import {
  ALLOWED_FILE_TYPE,
  FileUploadLinkResponse,
} from '../../../../../shared/interfaces/aIChat';
import {
  fetchAIChatFileLink,
  saveAIChatFileInLink,
} from '../../../utils/fetchers';
import {SelectedFileMedicalRecords} from '../chatFooter';

interface UseFileHandlerReturn {
  selectedFile: File | SelectedFileMedicalRecords | null;
  fileContent: string | ArrayBuffer | null;
  fileLoading: boolean;
  isMedicalFiles: boolean;
  errorInFile: string | null;
  saveFileInLinkResponse: FileUploadLinkResponse;
  handleMedicalRecordsSelection: (
    file: SelectedFileMedicalRecords | null,
    selectFileFromMedicalRecord: MedicalFileObject
  ) => void;
  handleFileSelect: (consumerId: string, file: File | null) => void;
  handleRemoveFile: () => void;
}
export const getMimeFromFile = (fileType: string): string =>
  ALLOWED_FILE_TYPE[fileType as keyof typeof ALLOWED_FILE_TYPE];

const fetchLinkToSaveFile = async (
  consumerId: string,
  file: File
): Promise<FileUploadLinkResponse> =>
  fetchAIChatFileLink(consumerId, file.name);

const saveFile = async (
  {upload_url, content_type, max_content_length}: FileUploadLinkResponse,
  file: File
): Promise<void> => {
  await saveAIChatFileInLink(
    upload_url,
    content_type,
    max_content_length,
    file
  );
};

function convertToMb(bytes: number): number {
  return bytes / 1000000;
}

function getFileSize(file: File): number {
  if (!file) return 0;
  const bytes = file.size;
  return bytes;
}

const useFileHandler = (scrollBottom: () => void): UseFileHandlerReturn => {
  const [saveFileInLinkResponse, setSaveFileInLinkResponse] =
    useState<FileUploadLinkResponse>({
      upload_url: '',
      file_name: '',
      content_type: '',
      max_content_length: 0,
    });
  const [selectedFile, setSelectedFile] = useState<
    File | SelectedFileMedicalRecords | null
  >(null);
  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    null
  );
  const [isMedicalFiles, setIsMedicalFiles] = useState<boolean>(false);
  const [errorInFile, setErrorInFile] = useState<string | null>(null);
  const [fileLoading, setFileLoading] = useState(false);

  const handleFileSelect = async (consumerId: string, file: File | null) => {
    setErrorInFile(null);
    setIsMedicalFiles(false);
    if (file) {
      const response = await fetchLinkToSaveFile(consumerId, file);
      if (getFileSize(file as File) > response.max_content_length) {
        setIsMedicalFiles(false);
        setSelectedFile(file);
        setErrorInFile(
          `File is too large. Max size - ${convertToMb(response.max_content_length)} MB`
        );
      } else {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadstart = async () => {
          scrollBottom();
          setFileLoading(true);
          await saveFile(response, file);
          setSaveFileInLinkResponse(response);
        };
        reader.onloadend = () => {
          scrollBottom();
          setFileLoading(false);
        };
        reader.onload = () => setFileContent(reader.result);
        if (file.type.startsWith('image/')) reader.readAsDataURL(file);
        else reader.readAsText(file);
      }
    }
  };

  const handleMedicalRecordsSelection = (
    file: SelectedFileMedicalRecords | null,
    selectFileFromMedicalRecord: MedicalFileObject
  ) => {
    setSelectedFile(file as SelectedFileMedicalRecords);
    setIsMedicalFiles(true);
    if (file) {
      scrollBottom();
      setFileLoading(true);
      const data: FileUploadLinkResponse = {
        upload_url: selectFileFromMedicalRecord.file_path,
        file_name: selectFileFromMedicalRecord.file_path,
        content_type: getMimeFromFile(selectFileFromMedicalRecord.content_type),
        max_content_length: 0,
      };
      setSaveFileInLinkResponse(data);

      scrollBottom();
      setFileLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileContent(null);
  };

  return {
    selectedFile,
    fileContent,
    fileLoading,
    isMedicalFiles,
    saveFileInLinkResponse,
    errorInFile,
    handleMedicalRecordsSelection,
    handleFileSelect,
    handleRemoveFile,
  };
};

export default useFileHandler;
