import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';

import {NotFoundPage} from '../notFound';

import {INBOX_ROUTES} from './constatnts/inboxRoutes';
import {InboxCompletedPage} from './pages/inboxCompletedPage';
import {InboxToDoPage} from './pages/inboxToDoPage';

const routes = [
  {
    path: INBOX_ROUTES.TODO_ROUTE,
    exact: true,
    component: InboxToDoPage,
    title: 'Inbox',
  },
  {
    path: INBOX_ROUTES.COMPLETED_ROUTE,
    exact: true,
    component: InboxCompletedPage,
    title: 'Inbox',
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const InboxRoutes: FC = () => (
  <Switch>
    {routes.map(route => {
      const ContentComponent = route.component;
      const getComponent = () => <ContentComponent title={route.title ?? ''} />;
      return (
        <Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          render={getComponent}
        />
      );
    })}
  </Switch>
);
