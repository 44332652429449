import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const UploadIcon: React.FC<any> = props => (
  <SvgIcon
    {...props}
    style={{width: 14}}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      className="a"
      d="M1.19403 12C0.537313 12 1.39925e-08 11.46 0 10.8V7.2C0 6.84 0.238806 6.6 0.597015 6.6C0.955224 6.6 1.19403 6.84 1.19403 7.2V10.56C1.19403 10.68 1.31343 10.8 1.49254 10.8H10.5075C10.6866 10.8 10.806 10.68 10.806 10.5V7.2C10.806 6.84 11.0448 6.6 11.403 6.6C11.7612 6.6 12 6.84 12 7.2V10.8C12 11.46 11.4627 12 10.806 12H1.19403ZM5.37313 8.82V1.8L3.8806 3.3C3.64179 3.54 3.28358 3.54 3.04478 3.3C2.80597 3.06 2.80597 2.7 3.04478 2.46L5.13433 0.36C5.61194 -0.12 6.32836 -0.12 6.80597 0.36L8.95522 2.46C9.19403 2.7 9.19403 3.06 8.95522 3.3C8.71642 3.54 8.35821 3.54 8.1194 3.3L6.56716 1.8V8.82C6.56716 9.18 6.32836 9.42 5.97015 9.42C5.67164 9.42 5.37313 9.18 5.37313 8.82Z"
      fill="white"
    />
  </SvgIcon>
);
