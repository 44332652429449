export const resetOwnerForm = formik => {
  formik.resetForm({
    values: {
      ...formik.values,
      ownerNpi: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    errors: {
      ...formik.errors,
      ownerNpi: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    touched: {
      ...formik.touched,
      ownerNpi: false,
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      zipCodeAsync: false,
      primaryLocationAddress: false,
    },
  });
};

export const resetOrganizationForm = formik => {
  formik.resetForm({
    values: {
      ...formik.values,
      organizationNpi: '',
      name: '',
      domain: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    errors: {
      ...formik.errors,
      organizationNpi: '',
      name: '',
      domain: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    touched: {
      ...formik.touched,
      organizationNpi: false,
      name: false,
      domain: false,
      zipCodeAsync: false,
      primaryLocationAddress: false,
    },
  });
};

export const onResetPrimaryLocationSubFormWithManuallyTypedAddress = formik =>
  formik.resetForm({
    values: {
      ...formik.values,
      primaryLocationManually: !formik.values.primaryLocationManually,
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zipCode: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    errors: {
      ...formik.errors,
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zipCode: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    },
    touched: {
      ...formik.touched,
      addressOne: false,
      addressTwo: false,
      city: false,
      state: false,
      zipCode: false,
      zipCodeAsync: false,
      primaryLocationAddress: false,
    },
  });

export const onResetPrimaryLocationSubFormWithPrefilledByZipCodeAndNpiAddress =
  formik =>
    formik.resetForm({
      values: {
        ...formik.values,
        zipCodeAsync: '',
        primaryLocationAddress: '',
      },
      errors: {
        ...formik.errors,
        zipCodeAsync: '',
        primaryLocationAddress: '',
      },
      touched: {
        ...formik.touched,
        zipCodeAsync: false,
        primaryLocationAddress: false,
      },
    });

export const onResetPrimaryLocationAddressFiled = formik =>
  formik.resetForm({
    values: {
      ...formik.values,
      primaryLocationAddress: '',
    },
    errors: {
      ...formik.errors,
      primaryLocationAddress: '',
    },
    touched: {
      ...formik.touched,
      primaryLocationAddress: false,
    },
  });
