import React from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {
  DEFAULT_PATIENT_MEDICAL_RECORDS_TAB,
  PORTAL_PATIENTS_ROUTES,
} from '../../main/portalPatients/constants/routes';
import {confirmViewMedicalRecordsState} from '../atoms/confirmViewMedicalRecordsState';

import {ConfirmModal} from './confirmModal';

export const ConfirmViewMedicalRecords = (): JSX.Element => {
  const history = useHistory();

  const [confirmViewMedicalRecords, setConfirmViewMedicalRecords] =
    useRecoilState(confirmViewMedicalRecordsState);

  const handleConfirm = (): void => {
    setConfirmViewMedicalRecords({
      ...confirmViewMedicalRecords,
      isOpen: false,
    });

    history.push(
      PORTAL_PATIENTS_ROUTES.CONNECTIONS_PATIENT_PROFILE.replace(
        ':patientUuid',
        confirmViewMedicalRecords.data.patientUuid
      ).replace(':patientTab', DEFAULT_PATIENT_MEDICAL_RECORDS_TAB)
    );
  };

  const handleClose = (): void => {
    setConfirmViewMedicalRecords({
      ...confirmViewMedicalRecords,
      isOpen: false,
    });
  };

  const patientName = (
    <strong>{confirmViewMedicalRecords.data.patientName}</strong>
  );
  return (
    <ConfirmModal
      title="Confirm viewing patient record"
      isOpen={confirmViewMedicalRecords.isOpen}
      variant="h6"
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmBtnText="Confirm"
      confirmBtnVariant="primary"
    >
      <ContentWrapper>
        <>
          Please confirm you would like to proceed with access to patient&nbsp;
          {patientName}
          &nbsp;record.
        </>
      </ContentWrapper>
    </ConfirmModal>
  );
};

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
`;
