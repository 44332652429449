import {
  MedicalDocumentsResponseType,
  SortingDirections,
} from '../constants/types';

import {fetchMedicalDocuments} from './fetchers';

export const getMedicalDocuments = async (
  consumerUuid: string,
  selectedFileTypes: string,
  sortingByUploadedOn: SortingDirections,
  page: number,
  pageSize: number
): Promise<MedicalDocumentsResponseType> => {
  try {
    const data = await fetchMedicalDocuments(
      consumerUuid,
      sortingByUploadedOn,
      selectedFileTypes,
      page,
      pageSize
    );

    return data;
  } catch (e) {
    throw new Error('Something went wrong. Please try refresh the page.');
  }
};
