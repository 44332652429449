import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {colors} from './styles/theme';

type Props = {
  handleClick?: (documents: any) => void;
  isSelected?: boolean;
  children: ReactNode;
  testId?: string;
  fixedSize?: boolean;
};

export const CustomizedCard: React.FC<Props> = ({
  isSelected = false,
  testId,
  handleClick,
  children,
  fixedSize = false,
}) => (
  <Container
    isClickable={handleClick !== undefined}
    onClick={handleClick}
    isSelected={isSelected}
    data-testid={testId ?? null}
    fixedSize={fixedSize}
  >
    {children}
  </Container>
);

const Container = styled.div<{
  isSelected: boolean;
  isClickable: boolean;
  fixedSize?: boolean;
}>`
  border: 1px solid
    ${props => (props.isSelected ? colors.primary : colors.grey800)};
  background-color: ${colors.grey1000};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 8px;
  ${props => (props.fixedSize ? 'width: 380px;' : '')};

  ${props =>
    props.isClickable &&
    `&:hover {
    border: 1px solid ${colors.blue};
    background-color: ${colors.blue}10;
    cursor: pointer;

    .title {
      color: ${colors.blue};
    }
  }`}
`;
