import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const DownloadIcon: FC<any> = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M8 11.25a.94.94 0 0 1-.34-.06.7.7 0 0 1-.29-.21l-3.12-3.1a.71.71 0 0 1-.2-.53c0-.22.07-.4.2-.52a.78.78 0 0 1 .54-.24c.2-.01.38.06.54.21l1.92 1.93V1.08A.73.73 0 0 1 8 .33c.22 0 .4.07.54.2.14.15.21.33.21.55v7.65l1.93-1.93a.7.7 0 0 1 .52-.2c.22 0 .4.08.55.23.13.13.2.3.2.5a.8.8 0 0 1-.2.55l-3.13 3.1a.7.7 0 0 1-.28.2.94.94 0 0 1-.34.07ZM2.3 15.5c-.5 0-.92-.17-1.27-.52A1.74 1.74 0 0 1 .5 13.7v-1.95a.73.73 0 0 1 .75-.75.73.73 0 0 1 .75.75v1.95c0 .07.03.13.1.2s.13.1.2.1h11.4c.07 0 .13-.03.2-.1s.1-.13.1-.2v-1.95c0-.22.07-.4.21-.54a.73.73 0 0 1 .54-.21c.22 0 .4.07.54.21.14.14.21.32.21.54v1.95c0 .5-.18.93-.53 1.28-.35.35-.77.52-1.27.52H2.3Z"
    />
  </SvgIcon>
);
