import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/styles';
import {noop} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.grey50,
    boxShadow: 'none',
    fontWeight: 'bold',
    color: colors.grey200,

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.grey50,
    },

    '&.MuiButton-containedPrimary': {
      backgroundColor: colors.lightPrimary,
      color: colors.white,
    },
  },
}));

export const ButtonSwitcher = ({buttons, activeButton, onClick = noop}) => {
  const classes = useStyles();

  return (
    <ButtonContainer>
      {buttons.map((button, index) => {
        const isButtonActive = index === activeButton;
        return (
          <Button
            key={button}
            variant="contained"
            className={classes.root}
            onClick={!isButtonActive ? onClick : noop}
            color={isButtonActive ? 'primary' : 'default'}
            style={{borderRadius: index === 0 ? '4px 0 0 4px' : '0 4px 4px 0'}}
          >
            {button}
          </Button>
        );
      })}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  background-color: ${colors.grey50};
  border-radius: 4px;
  display: flex;
`;

ButtonSwitcher.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.any),
  activeButton: PropTypes.number,
  onClick: PropTypes.func,
};
