export enum MEDICAL_RECORDS_INITIATION_TYPES {
  CHANGE_CONNECTION = 'CHANGE_CONNECTION',
}

export enum CONNECTION_STATUSES {
  CONNECTED = 'CONNECTED',
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  EXPIRED = 'EXPIRED',
  DISCONNECTED = 'DISCONNECTED',
}

export const CONNECTION_STATUSES_LABELS = {
  [CONNECTION_STATUSES.CONNECTED]: 'Connected',
  [CONNECTION_STATUSES.PENDING]: 'Pending',
  [CONNECTION_STATUSES.DENIED]: 'Denied',
  [CONNECTION_STATUSES.EXPIRED]: 'Expired',
  [CONNECTION_STATUSES.DISCONNECTED]: 'Disconnected',
};
