import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Table} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {colors} from '../../../shared/styles/theme';
import {locationPropType} from '../../../shared/utils/propTypes';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 14,
    padding: '8px 0 8px 12px',
    fontWeight: 600,
    width: 176,
    borderBottom: `1px solid ${colors.grey50}`,
  },
}));

export const LocationTable = ({locations, relyingPartyName, policyId}) => {
  const classes = useStyles();
  const history = useHistory();

  const {roles} = useRecoilValue(currentUserState);
  const canViewLocation = isUserRelyingPartyAdminOrOwner(roles);

  const onClickEntranceName = useCallback(
    location => {
      if (canViewLocation) {
        history.push(`/health-policies/${policyId}/locations/${location.id}`);
      }
    },
    [canViewLocation, history, policyId]
  );

  return (
    <LocationContainer>
      <TableHead>
        <TableRow>
          <HeadCell className={classes.root}>Location name</HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {locations.map(location => (
          <TableRow key={location.name}>
            <DataCell className={classes.root}>
              <EntranceName
                canViewLocation={canViewLocation}
                onClick={onClickEntranceName(location)}
              >
                {`${relyingPartyName} ${location.name}`}
              </EntranceName>
            </DataCell>
          </TableRow>
        ))}
      </TableBody>
    </LocationContainer>
  );
};

LocationTable.propTypes = {
  locations: PropTypes.arrayOf(locationPropType),
  relyingPartyName: PropTypes.string,
  policyId: PropTypes.number,
};

const LocationContainer = styled(Table)`
  width: 100%;
  border: 1px solid ${colors.grey50};
`;

const HeadCell = styled(TableCell)`
  background-color: ${colors.grey25};
  border-bottom: 2px solid ${colors.grey50};
`;

const DataCell = styled(TableCell)`
  width: 218px;
`;

const EntranceName = styled.p`
  font-size: 14px;
  color: ${colors.black};
  font-weight: 600;
  margin: 0;
  cursor: ${props => (props.canViewLocation ? 'pointer' : 'default')};
`;
