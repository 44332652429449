interface ValidationProps {
  isValidating: boolean;
  isValid: boolean;
  dirty: boolean;
  isSubmitting: boolean;
}

export const checkEditFormButtonDisability = ({
  isValidating,
  isValid,
  dirty,
}: Omit<ValidationProps, 'isSubmitting'>): boolean =>
  isValidating || !isValid || !dirty;

export const checkAddFormButtonDisability = ({
  isValidating,
  isValid,
  isSubmitting,
}: Omit<ValidationProps, 'dirty'>): boolean =>
  isValidating || !isValid || isSubmitting;
