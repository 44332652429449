import React, {useCallback} from 'react';
import {useRecoilState} from 'recoil';

import {isOpenAccessDeniedModalState} from '../atoms/isOpenAccessDeniedModalState';
import {isOpenDisconnectionAccessDeniedModalState} from '../atoms/isOpenDisconnectionAccessDeniedModalState';
import {patientDataWasNotSharedState} from '../atoms/patientDataWasNotSharedState';
import {patientWasNotConnectedState} from '../atoms/patientWasNotConnectedState';
import {ConfirmDisconnectPatient} from '../components/confirmDisconnectPatient';
import {ConfirmViewMedicalRecords} from '../components/confirmViewMedicalRecords';
import {InfoDialog} from '../components/infoDialog';

type Props = {
  children: React.ReactNode;
};
export const PatientNameClickModalWindowsProvider: React.FC<Props> = ({
  children,
}) => {
  const [
    isOpenAccessDeniedModalWindow,
    setIsOpenIsOpenAccessDeniedModalWindow,
  ] = useRecoilState(isOpenAccessDeniedModalState);

  const [
    isOpenDisconnectionAccessDeniedModalWindow,
    setIsOpenDisconnectionAccessDeniedModalWindow,
  ] = useRecoilState(isOpenDisconnectionAccessDeniedModalState);

  const [patientDataWasNotShared, setPatientDataWasNotShared] = useRecoilState(
    patientDataWasNotSharedState
  );
  const [patientWasNotConnected, setPatientWasNotConnected] = useRecoilState(
    patientWasNotConnectedState
  );

  const handleClosePatientDataWasNotSharedModalWindow = useCallback(() => {
    setPatientDataWasNotShared({
      ...patientDataWasNotShared,
      isOpen: false,
    });
  }, [patientDataWasNotShared, setPatientDataWasNotShared]);

  const handleClosePatientWasNotConnectedModalWindow = useCallback(() => {
    setPatientWasNotConnected({
      ...patientWasNotConnected,
      isOpen: false,
    });
  }, [patientWasNotConnected, setPatientWasNotConnected]);

  const handleCloseAccessDeniedModalWindow = useCallback(() => {
    setIsOpenIsOpenAccessDeniedModalWindow(false);
  }, [setIsOpenIsOpenAccessDeniedModalWindow]);

  const handleCloseDisconnectionAccessDeniedModalWindow = useCallback(() => {
    setIsOpenDisconnectionAccessDeniedModalWindow(false);
  }, [setIsOpenDisconnectionAccessDeniedModalWindow]);

  return (
    <>
      {children}

      <ConfirmViewMedicalRecords />
      <ConfirmDisconnectPatient />

      <InfoDialog
        title="Insufficient permissions"
        content="You do not have permissions to access medical records. Contact your administrator for more details."
        isOpen={isOpenAccessDeniedModalWindow}
        onClose={handleCloseAccessDeniedModalWindow}
        buttonTitle="Ok"
      />

      <InfoDialog
        title="Insufficient permissions"
        content="You do not have permissions to disconnect patients. Contact your administrator for more details."
        isOpen={isOpenDisconnectionAccessDeniedModalWindow}
        onClose={handleCloseDisconnectionAccessDeniedModalWindow}
        buttonTitle="Ok"
      />

      <InfoDialog
        title="Patient records are not available"
        content={`Patient ${patientWasNotConnected.data.patientName} has not been connected.`}
        isOpen={patientWasNotConnected.isOpen}
        onClose={handleClosePatientWasNotConnectedModalWindow}
        buttonTitle="Ok"
      />

      <InfoDialog
        title="Patient records are not available"
        content={`Patient ${patientDataWasNotShared.data.patientName} declined request to share their medical records.`}
        isOpen={patientDataWasNotShared.isOpen}
        onClose={handleClosePatientDataWasNotSharedModalWindow}
        buttonTitle="Ok"
      />
    </>
  );
};
