/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../../shared/components/list';
import {colors} from '../../../../../shared/styles/theme';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordDateByType} from '../electronicRecordDateByType';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ElectronicRecordHorizontalDivider} from '../electronicRecordHorizonralDivider';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {StyledDivider} from '../styledDivider';
import {FetchedProcedure} from '../types/procedureTypes';
import {CommonSectionProps} from '../types/types';
import {getCodeDescriptionBySystem} from '../utils/getDescriptionFromCodesBySystem';
import {getProcedures} from '../utils/proceduresMapper';

const LABELS_COLUMN_WIDTH = '140px';

const useStyles = makeStyles(() => ({
  accordionContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    width: '100%',
    boxSizing: 'border-box',
  },
  contentBlockItemGrid: {
    gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
  },
  wrapperForBlockWithSomeCodes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  notesWrapper: {
    padding: '24px',
  },
  noteText: {
    color: colors.black,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
}));

export const Procedures: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<FetchedProcedure[]>(
    urls.getProceduresOfConsolidatedMedicalRecord(consumerUuid)
  );

  const procedures = useMemo(() => getProcedures(data), [data]);

  const hasNoData = procedures.length === 0 && !isFetching && !error;

  const listsWrapperClasses = cx(
    listTemplateClasses.wrapper,
    classes.contentBlockItemGrid
  );

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Procedures"
    >
      <ElectronicRecordAccordionWrapper>
        {procedures?.map(({id, ...procedure}) => (
          <ElectronicRecordAccordion
            key={id}
            title={getCodeDescriptionBySystem(procedure?.codings)}
            content={
              <div className={classes.accordionContentWrapper}>
                <div className={classes.contentBlock}>
                  <div className={listsWrapperClasses}>
                    <div>
                      <ElectronicRecordGroupTitle title="Details" />

                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ListItemKey>Status</ListItemKey>
                        <ListItemValue testId="status">
                          {procedure.status}
                        </ListItemValue>
                        <ListItemKey>Data Last Updated</ListItemKey>
                        <ListItemValue testId="last_updated">
                          {procedure.last_updated_at}
                        </ListItemValue>
                        <dt />
                        <dd />
                        <ListTitleRow title="Performed" />
                        <ElectronicRecordDateByType
                          date={procedure.performed}
                        />
                      </ListWithFixedLabelsColumn>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div data-testid="categories_block">
                      <ElectronicRecordGroupTitle title="Category" />
                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ElectronicRecordCodesList codes={procedure.category} />
                      </ListWithFixedLabelsColumn>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div data-testid="main_codes_block">
                      <ElectronicRecordGroupTitle title="Codes" />
                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ElectronicRecordCodesList codes={procedure.codings} />
                      </ListWithFixedLabelsColumn>
                    </div>
                  </div>
                  <StyledDivider />
                  <div className={listsWrapperClasses}>
                    <div data-testid="status_reasons_block">
                      <ElectronicRecordGroupTitle title="Status Reason" />
                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ElectronicRecordCodesList
                          codes={procedure.status_reason}
                        />
                      </ListWithFixedLabelsColumn>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div>
                      <ElectronicRecordGroupTitle title="Procedure Reasons" />
                      <div
                        data-testid="procedure_reasons_block"
                        className={classes.wrapperForBlockWithSomeCodes}
                      >
                        {procedure.reasons.length !== 0 ? (
                          procedure.reasons.map(({id, codes}, index) => (
                            <>
                              <ElectronicRecordHorizontalDivider
                                index={index}
                                length={procedure.reasons.length}
                              />

                              <ListWithFixedLabelsColumn
                                key={id}
                                labelsColumnWidth={LABELS_COLUMN_WIDTH}
                              >
                                <ElectronicRecordCodesList codes={codes} />
                              </ListWithFixedLabelsColumn>
                            </>
                          ))
                        ) : (
                          <ListWithNoDataValue />
                        )}
                      </div>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div>
                      <ElectronicRecordGroupTitle title="Body Sites" />
                      <div
                        data-testid="body_sites_block"
                        className={classes.wrapperForBlockWithSomeCodes}
                      >
                        {procedure.body_site.length !== 0 ? (
                          procedure.body_site.map(({id, codes}, index) => (
                            <>
                              <ElectronicRecordHorizontalDivider
                                index={index}
                                length={procedure.body_site.length}
                              />

                              <ListWithFixedLabelsColumn
                                key={id}
                                labelsColumnWidth={LABELS_COLUMN_WIDTH}
                              >
                                <ElectronicRecordCodesList codes={codes} />
                              </ListWithFixedLabelsColumn>
                            </>
                          ))
                        ) : (
                          <ListWithNoDataValue />
                        )}
                      </div>
                    </div>
                  </div>
                  <StyledDivider />
                  <div className={listsWrapperClasses}>
                    <div>
                      <ElectronicRecordGroupTitle title="Complications" />
                      <div
                        data-testid="complications_block"
                        className={classes.wrapperForBlockWithSomeCodes}
                      >
                        {procedure.complications.length !== 0 ? (
                          procedure.complications.map(({id, codes}, index) => (
                            <>
                              <ElectronicRecordHorizontalDivider
                                index={index}
                                length={procedure.complications.length}
                              />

                              <ListWithFixedLabelsColumn
                                key={id}
                                labelsColumnWidth={LABELS_COLUMN_WIDTH}
                              >
                                <ElectronicRecordCodesList codes={codes} />
                              </ListWithFixedLabelsColumn>
                            </>
                          ))
                        ) : (
                          <ListWithNoDataValue />
                        )}
                      </div>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div data-testid="outcomes_block">
                      <ElectronicRecordGroupTitle title="Outcome" />
                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ElectronicRecordCodesList codes={procedure.outcome} />
                      </ListWithFixedLabelsColumn>
                    </div>
                    <StyledDivider orientation="vertical" />
                    <div />
                  </div>
                </div>
                <StyledDivider isBoldDivider />

                <div data-testid="notes" className={classes.notesWrapper}>
                  <ElectronicRecordGroupTitle title="Provider Notes" />

                  <div
                    className={classes.wrapperForBlockWithSomeCodes}
                    data-testid="notes_block"
                  >
                    {procedure.notes.length !== 0 ? (
                      procedure.notes.map((note, index) => (
                        <React.Fragment key={note.id}>
                          <ElectronicRecordHorizontalDivider
                            index={index}
                            length={procedure.notes.length}
                          />

                          <ListWithFixedLabelsColumn
                            labelsColumnWidth={LABELS_COLUMN_WIDTH}
                          >
                            <ListTitleRow
                              testId="note_recorded_date"
                              title={note.recorded_date ?? 'Date Unavailable'}
                            />
                            <ListItemKey>Author</ListItemKey>
                            <ListItemValue testId="note_author">
                              {note.author ?? 'Author Unavailable'}
                            </ListItemValue>

                            <ListItemKey>Note</ListItemKey>
                            <ListItemValue
                              testId="note_text"
                              classNames={classes.noteText}
                            >
                              {note.text}
                            </ListItemValue>
                          </ListWithFixedLabelsColumn>
                        </React.Fragment>
                      ))
                    ) : (
                      <ListWithNoDataValue />
                    )}
                  </div>
                </div>
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
