import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useSetRecoilState} from 'recoil';

import {MRNDetailsType} from '../../../shared/constants/formattedMRNsDetailsType';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {dateFormatter} from '../../../shared/utils/formatDate';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {PATIENT_DETAILS_LABELS} from '../constants/PatientDetailsLabels';
import {
  PatientsProfileDetailsFormattedType,
  PatientsProfileDetailsResponseType,
} from '../constants/types';
import {fetchPatientDetails} from '../utils/fetchers';
import {getAddress} from '../utils/getAddress';

const formatPatientDetails = (
  patientDetails: PatientsProfileDetailsResponseType
): PatientsProfileDetailsFormattedType => ({
  publicDetails: {
    consumerUuid: patientDetails.consumer_uuid,
    firstName: patientDetails.first_name,
    lastName: patientDetails.last_name,
    middleName: patientDetails.middle_name,
    src: patientDetails.selfie
      ? `data:image/png;base64,${patientDetails.selfie}`
      : '',
    primaryInsuranceId: patientDetails.primary_insurance_id,
    secondaryInsuranceId: patientDetails.secondary_insurance_id,
  },
  personalDetails: [
    {
      title: PATIENT_DETAILS_LABELS.DATE_OF_BIRTH,
      value: dateFormatter(patientDetails.date_of_birth) || 'N/A',
    },
    {
      title: PATIENT_DETAILS_LABELS.GENDER,
      value: patientDetails.gender || 'N/A',
    },
    {
      title: PATIENT_DETAILS_LABELS.MOBILE_PHONE,
      value: formatPhoneNumber(patientDetails.mobile_phone || 'N/A'),
    },
  ],
  addressDetails: [
    {
      title: PATIENT_DETAILS_LABELS.EMAIL,
      value: patientDetails.email || 'N/A',
    },
    {
      title: PATIENT_DETAILS_LABELS.ADDRESS,
      value: getAddress(patientDetails.address),
    },
  ],
  mrnDetails: patientDetails.mrn_ehr_responses.map(
    (mrn_ehr_response: MRNDetailsType) => ({
      id: mrn_ehr_response.id,
      ehrId: mrn_ehr_response.ehr_id,
      ehrName: mrn_ehr_response.ehr_name,
      mrn: mrn_ehr_response.mrn,
      ehrLink: mrn_ehr_response.ehr_link,
    })
  ),
  connection: patientDetails.connection,
});

const getPatientDetails = async (
  consumerUuid: string,
  relyingPartyId: number
): Promise<PatientsProfileDetailsFormattedType | null> => {
  const patientDetailsData = await fetchPatientDetails(
    consumerUuid,
    relyingPartyId
  );

  return patientDetailsData ? formatPatientDetails(patientDetailsData) : null;
};

export const usePatientDetails = (
  consumerUuid: string | null,
  relyingPartyId: number | null
): UseQueryResult<PatientsProfileDetailsFormattedType | null> => {
  const setSelectedPatient = useSetRecoilState(selectedPatientState);
  const handleError = useErrorHandling('Fetching patient details went wrong');

  return useQuery({
    queryKey: ['patientDetails', consumerUuid, relyingPartyId],
    queryFn: async () => {
      if (consumerUuid && relyingPartyId) {
        const patientDetailsData = await getPatientDetails(
          consumerUuid,
          relyingPartyId
        );

        setSelectedPatient({
          patientName: `${patientDetailsData?.publicDetails.firstName} ${patientDetailsData?.publicDetails.lastName}`,
          patientUuid: consumerUuid,
        });

        return patientDetailsData;
      }

      return null;
    },
    onError: wrapAsyncFunction(async error => {
      await handleError(error);
    }),
    refetchOnMount: true,
  });
};
