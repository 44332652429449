import React, {useEffect, useRef} from 'react';
import {CircularProgress, IconButton, InputAdornment} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Cancel from '@material-ui/icons/Cancel';
import {Form, Formik} from 'formik';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {NPI_STATUSES} from '../constants/npi';
import {onlyNumbersPattern} from '../constants/patterns';

import {Button} from './button';
import {
  AdornmentWrapper,
  InputFieldWithFormik,
  InputHelperText,
  InputLabel,
} from './form';
import {SearchFormTemplate} from './searchFormTemplate';

const NPI_LENGTH = 10;

export const npiValidationSchema = yup.object({
  npi: yup
    .string()
    .trim()
    .max(NPI_LENGTH, 'Must be 10 characters')
    .required('NPI is required'),
});

export default function SearchNPI({
  label,
  placeholder,
  disabled,
  isLoading,
  npiState,
  onChange,
  onSearch,
  onClear,
  defaultValue,
  testIdPrefix = 'default',
}) {
  const formikRef = useRef();

  const setValidNPI = (value, callback) => {
    if (
      isEmpty(value) ||
      (value.match(onlyNumbersPattern) && value.length <= NPI_LENGTH)
    ) {
      callback(value);
    }
  };

  useEffect(() => {
    if (disabled && !isLoading) {
      formikRef.current.resetForm({
        npi: '',
      });
    }
  }, [disabled, isLoading]);

  const isFormDisabled = disabled || isLoading;

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      onSubmit={values => onSearch(values.npi)}
      validationSchema={npiValidationSchema}
      initialValues={{
        npi: defaultValue || '',
      }}
    >
      {formikProps => (
        <Form autoComplete="off">
          <FormControl
            variant="standard"
            error={
              (formikProps.touched.npi && Boolean(formikProps.errors.npi)) ||
              npiState?.status === NPI_STATUSES.ERROR
            }
          >
            <SearchFormTemplate
              label={
                <InputLabel
                  htmlFor="npi-search-field"
                  disabled={isFormDisabled}
                  required
                  shrink
                >
                  {label || 'NPI'}
                </InputLabel>
              }
              input={
                <InputFieldWithFormik
                  width="small"
                  disabled={isFormDisabled}
                  placeholder={placeholder}
                  name="npi"
                  inputProps={{
                    'data-testid': `${testIdPrefix}-npi-input`,
                  }}
                  endAdornment={
                    formikProps.values.npi.length > 0 ? (
                      <InputAdornment position="end">
                        <AdornmentWrapper>
                          <IconButton
                            data-testid={`${testIdPrefix}-npi-clear-button`}
                            disabled={isFormDisabled}
                            size="small"
                            onClick={() => {
                              onClear();
                              formikProps.handleReset();
                            }}
                            edge="end"
                          >
                            <Cancel fontSize="small" />
                          </IconButton>
                        </AdornmentWrapper>
                      </InputAdornment>
                    ) : null
                  }
                  onChange={e => {
                    e.preventDefault();
                    setValidNPI(e.target.value, value => {
                      formikProps.setFieldValue('npi', value);

                      if (onChange) {
                        onChange(value);
                      }
                    });
                  }}
                />
              }
              button={
                <Button
                  type="submit"
                  data-testid={`${testIdPrefix}-npi-button`}
                  disabled={
                    formikProps.values.npi.length < NPI_LENGTH ||
                    !!formikProps.errors.npi ||
                    !formikProps.values.npi ||
                    isFormDisabled
                  }
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    'Search'
                  )}
                </Button>
              }
              inputHelper={
                <InputHelperText
                  shrink
                  data-testid={`${testIdPrefix}-npi-input-helper`}
                  disabled={isFormDisabled}
                >
                  {formikProps.errors.npi || npiState?.message}
                </InputHelperText>
              }
            />
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}

SearchNPI.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string,
  testIdPrefix: PropTypes.string,
  onChange: PropTypes.func,
  npiState: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    status: PropTypes.oneOf([
      NPI_STATUSES.DEFAULT,
      NPI_STATUSES.SUCCESS,
      NPI_STATUSES.ERROR,
    ]),
  }),
};
