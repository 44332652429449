import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {PenIcon} from '../../../shared/icons/penIcon';

interface DetailProps {
  path: string;
}

export const EditIcon: React.FC<DetailProps> = ({path}) => {
  const history = useHistory();
  const goTo = useCallback(
    () => history.push({pathname: path, state: {startEditing: true}}),
    [history, path]
  );

  return (
    <ActionTableButton title="Edit" testId="editIcon" onClick={goTo}>
      <PenIcon />
    </ActionTableButton>
  );
};
