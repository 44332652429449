import {useCallback, useState} from 'react';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';

export default function useSendInvitation() {
  const showMessage = useCustomizedSnackbar();
  const [loading, setLoading] = useState(false);

  const handleError = useErrorHandling();

  const sendInvitation = useCallback(
    async email => {
      setLoading(true);
      try {
        await fetcher(urls.invitationEmail, 'POST', {email});
        showMessage('Resend successfully', 'success');
      } catch (error) {
        handleError(error);
      }
      setLoading(false);
    },
    [handleError, showMessage]
  );

  return {sendInvitation, loading};
}
