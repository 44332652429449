import {atom} from 'recoil';

import {DrawerStateType} from '../constants/types';

export const DRAWER_DEFAULT_STATE: DrawerStateType = {
  isOpen: false,
  patientUuid: null,
};

export const drawerState = atom<DrawerStateType>({
  key: 'isShowDrawer',
  default: DRAWER_DEFAULT_STATE,
});
