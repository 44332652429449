import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {urls} from '../../../shared/utils/urls';

export const useInvitedPatients = () => {
  const {relyingParty} = useRecoilValue(currentUserState);

  const {data: invitedPatients = [], isValidating} = useSWR(
    urls.invitedPatients(relyingParty.id)
  );

  const remappedInvitedPatients = useMemo(
    () =>
      invitedPatients.map(invitedPatient => ({
        invitation_id: invitedPatient.invitation_id,
        name:
          `${invitedPatient.first_name || ''} ` +
          `${invitedPatient.last_name || ''}`,
        phone_number: invitedPatient.mobile_phone_number || '',
        status: invitedPatient.status,
        is_number_opted_out: invitedPatient.is_number_opted_out,
      })),

    [invitedPatients]
  );

  return [remappedInvitedPatients, isValidating];
};
