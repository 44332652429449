import {
  FormattedMRNsDetailsType,
  MRNDetailsType,
} from '../constants/formattedMRNsDetailsType';

export const getRemappedMedicalRecordNumbers = (
  mrnEhrs: MRNDetailsType[]
): FormattedMRNsDetailsType[] => {
  if (mrnEhrs?.length === 0) {
    return [];
  }

  return mrnEhrs.map(({id, ehr_id, ehr_name, ehr_link, mrn}) => ({
    id,
    ehrId: ehr_id || 0,
    ehrName: ehr_name || '',
    ehrLink: ehr_link || '',
    mrn: mrn || '',
  }));
};
