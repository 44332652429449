import React, {FC} from 'react';
import {Box} from '@material-ui/core';

import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button} from '../../../shared/components/button';
import {initialResendInvitationModalWindowsState} from '../constants/initialResendInvitationModalWindowsState';
import {HandleResendInvitationModalWindowsType} from '../constants/types';

import {ModalWindowBody} from './modalWindow/modalWindowBody';
import {ModalWindowFooter} from './modalWindow/modalWindowFooter';
import {ModalWindowTitle} from './modalWindow/modalWindowTitle';

type Props = {
  isShowModalWindow: boolean;
  setIsShowModalWindow: (val: HandleResendInvitationModalWindowsType) => void;
};

export const MobilePhoneAlreadyRegisteredModalWindow: FC<Props> = ({
  isShowModalWindow,
  setIsShowModalWindow,
}) => {
  const onClose = () => {
    setIsShowModalWindow(initialResendInvitationModalWindowsState);
  };

  return (
    <BaseModalWindow minWidth={350} maxWidth={480} open={isShowModalWindow}>
      <ModalWindowTitle>Mobile number is already registered</ModalWindowTitle>
      <ModalWindowBody>
        <Box component="p" margin={0}>
          The patient name associated with this mobile number does not match the
          name you entered.
        </Box>
        <Box component="p" fontWeight="bold" marginY={2}>
          The patient name must match the name on their driver&#39;s license.
        </Box>
        <Box component="p" margin={0}>
          Please edit and resend the invitation.
        </Box>
      </ModalWindowBody>
      <ModalWindowFooter>
        <Button color="primary" onClick={onClose} data-testid="confirm-btn">
          Close
        </Button>
      </ModalWindowFooter>
    </BaseModalWindow>
  );
};
