import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  label: {
    transform: 'translate(0, 1.5px) scale(1)',
  },
}));

/**
 * @deprecated Don't use this component. InputTextField needs to be deleted.
 */
export const Label = ({id, label, error = false}) => {
  const classes = useStyles();

  return (
    <InputLabel shrink htmlFor={id} className={classes.label} error={error}>
      {label}
    </InputLabel>
  );
};

Label.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
};
