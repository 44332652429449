import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
} from '../../../../../shared/components/list';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {Lozenge} from '../lozenge';
import {StyledDivider} from '../styledDivider';
import {FetchedCondition} from '../types/conditionTypes';
import {CommonSectionProps} from '../types/types';
import {
  getConditions,
  getConditionSeverityColor,
} from '../utils/conditionsMapper';
import {getCodeDescriptionBySystem} from '../utils/getDescriptionFromCodesBySystem';

const LABELS_COLUMN_WIDTH = '125px';

const useStyles = makeStyles(() => ({
  accordionContentWrapper: {
    gridTemplateColumns: '1fr 1px 1fr',
    padding: '24px',
  },
}));

export const Conditions: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<FetchedCondition[]>(
    urls.getConditionsOfConsolidatedMedicalRecord(consumerUuid)
  );

  const conditions = useMemo(() => getConditions(data), [data]);

  const hasNoData = conditions.length === 0 && !isFetching && !error;

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Conditions"
    >
      <ElectronicRecordAccordionWrapper>
        {conditions?.map(condition => (
          <ElectronicRecordAccordion
            key={condition.id}
            title={getCodeDescriptionBySystem(condition?.codings)}
            content={
              <div
                className={cx(
                  listTemplateClasses.wrapper,
                  classes.accordionContentWrapper
                )}
              >
                <div>
                  <ElectronicRecordGroupTitle title="Details" />

                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ListItemKey>Clinical Status</ListItemKey>
                    <ListItemValue testId="clinical-status">
                      {condition.clinical_status}
                    </ListItemValue>
                    <ListItemKey>Category</ListItemKey>
                    <ListItemValue testId="category">
                      {condition.category}
                    </ListItemValue>
                    <ListItemKey>Severity</ListItemKey>
                    <ListItemValue testId="severity">
                      {condition.severity ? (
                        <Lozenge
                          appearance={getConditionSeverityColor(
                            condition.severity
                          )}
                        >
                          {condition.severity}
                        </Lozenge>
                      ) : null}
                    </ListItemValue>
                    <ListItemKey>Stage</ListItemKey>
                    <ListItemValue testId="stage">
                      {condition.stage}
                    </ListItemValue>
                    <ListItemKey>Recorded Date</ListItemKey>
                    <ListItemValue testId="recorded-date">
                      {condition.recorded_date}
                    </ListItemValue>
                    <ListItemKey>Data Last Updated</ListItemKey>
                    <ListItemValue testId="last-updated">
                      {condition.last_updated_at}
                    </ListItemValue>
                  </ListWithFixedLabelsColumn>
                </div>

                <StyledDivider orientation="vertical" />

                <div data-testid="codes-block">
                  <ElectronicRecordGroupTitle title="Codes" />

                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ElectronicRecordCodesList codes={condition.codings} />
                  </ListWithFixedLabelsColumn>
                </div>
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
