import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {theme} from '../styles/theme';

export const TooltipContent = ({tips = []}) => (
  <TipContainer>
    {tips.map(tip => (
      <TipText key={tip}>{tip}</TipText>
    ))}
  </TipContainer>
);
const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TipText = styled.p`
  font-size: 12px;
  color: ${theme.palette.grey[800]};
`;

TooltipContent.propTypes = {
  tips: PropTypes.arrayOf(PropTypes.string.isRequired),
};
