import React from 'react';
import {Box} from '@material-ui/core';
import {ColumnDef} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {NoDataDash} from '../../../shared/components/noDataDash';
import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {StyledCell} from '../../../shared/components/table/styledCell';
import {Tag} from '../../../shared/components/tag';
import {ActionIcon} from '../../../shared/icons/actionIcon';
import {PenIcon} from '../../../shared/icons/penIcon';
import {TrashIcon} from '../../../shared/icons/trashIcon';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';

export enum LocationType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}
enum PhysicalLocationTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

type Location = {
  id: number;
  name: string;
  policy_name: string;
  policy_type: string;
  location_ehr: string;
  ehr_connection: string;
  location_type: LocationType;
  physical_location_type: PhysicalLocationTypes;
};

type CellType = {
  row: {
    original: Location;
  };
};

type ActionMethods = {
  handleDetail: (location: Location) => void;
  handleEdit: (location: Location) => void;
  handleDelete: (location: Location) => void;
};

const LocationsTableActionButtons = ({
  actionMethods,
  cell,
}: {
  actionMethods: ActionMethods;
  cell: CellType;
}): JSX.Element => {
  const currentUser = useRecoilValue(currentUserState);

  return isUserRelyingPartyAdminOrOwner(currentUser?.roles) ? (
    <>
      <ActionTableButton
        title="Detail"
        testId="Detail-icon"
        onClick={() => actionMethods.handleDetail(cell.row.original)}
      >
        <ActionIcon />
      </ActionTableButton>
      {cell.row.original.location_type === LocationType.VIRTUAL ? (
        <>
          <ActionTableButton
            title="Edit"
            testId="Edit-icon"
            onClick={() => actionMethods.handleEdit(cell.row.original)}
          >
            <PenIcon />
          </ActionTableButton>
          <ActionTableButton
            title="Delete"
            testId="Delete-icon"
            onClick={() => actionMethods.handleDelete(cell.row.original)}
          >
            <TrashIcon />
          </ActionTableButton>
        </>
      ) : null}
    </>
  ) : (
    <></>
  );
};

export const getLocationsTableConfig = (actionMethods: {
  handleDetail: (location: Location) => void;
  handleEdit: (location: Location) => void;
  handleDelete: (location: Location) => void;
}): (ColumnDef<Location> & AdditionalTableProps)[] => [
  {
    id: 'name',
    header: 'Location Name',
    width: '310px',
    minWidth: '310px',
    isStickyLeft: true,
    cell({row: {original}}: CellType): JSX.Element {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            component="span"
            fontWeight={600}
            overflow="hidden"
            textOverflow="ellipsis"
            marginRight={2}
          >
            <span>{original.name}</span>
          </Box>
          {original.physical_location_type === PhysicalLocationTypes.PRIMARY ? (
            <Tag text="Primary" />
          ) : null}
        </Box>
      );
    },
  },
  {
    id: 'policy_name',
    header: 'Assigned Policy',
    width: '280px',
    minWidth: '280px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.policy_name} />;
    },
  },
  {
    id: 'policy_type',
    header: 'Policy Type',
    width: '200px',
    minWidth: '200px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.policy_type} />;
    },
  },
  {
    id: 'location_ehr',
    header: 'Location EHR',
    width: '160px',
    minWidth: '160px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.location_ehr} />;
    },
  },
  {
    id: 'ehr_connection',
    header: 'Redox EHR Connection',
    width: '220px',
    minWidth: '220px',
    cell(cell: CellType): JSX.Element {
      if (cell.row.original.ehr_connection === 'No Connection') {
        return (
          <NoDataDash
            testId={`redox-ehr-connection-dash-${cell.row.original.id}`}
          />
        );
      }
      return <StyledCell info={cell.row.original.ehr_connection} />;
    },
  },
  {
    header: 'Action',
    cell(cell: CellType): JSX.Element {
      return (
        <LocationsTableActionButtons
          cell={cell}
          actionMethods={actionMethods}
        />
      );
    },
    width: '145px',
    minWidth: '145px',
    maxWidth: '145px',
    isStickyRight: true,
  },
];
