import {patientConnectionType} from '../../../shared/constants/patientConnectionType';
import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {
  filterValuesForRequestType,
  PatientsNamesType,
  ProviderInboxEventsResponseType,
  ProviderInboxEventsType,
  ResolutionEnum,
} from '../constatnts/types';
import {newEventsCount} from '../types/newEventsCount';

export const fetchPatientsNames = (
  relyingPartyId: string
): Promise<PatientsNamesType[]> => fetcher(urls.patientsNames(relyingPartyId));

export const fetchInboxProviderEvents = (
  body: filterValuesForRequestType,
  pageIndex: number,
  pageSize: number
): Promise<ProviderInboxEventsResponseType> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.inboxProviderEvents(pageIndex, pageSize), HttpMethod.POST, body);

export const fetchInboxProviderResolutionEvent = (
  eventType: ResolutionEnum,
  eventId: string
): Promise<void> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.inboxProviderResolutionEvent(eventId), HttpMethod.POST, {
    resolution: eventType,
  });

export const fetchInboxProviderDismissEvent = (
  eventType: ResolutionEnum,
  eventId: string
): Promise<void> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.inboxProviderDismissEvent(eventId), HttpMethod.POST, {
    resolution: eventType,
  });

export const fetchPatientConnection = (
  relyingPartyId: number,
  consumerUuid: string | null
): Promise<patientConnectionType> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.patientConnection(relyingPartyId, consumerUuid), HttpMethod.GET);

export const fetchInboxNewEventsCount = (): Promise<newEventsCount> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.inboxNewEventsCount(), HttpMethod.GET);

export const fetchRequestedRecordInfo = (
  eventId: string | null
): Promise<Omit<ProviderInboxEventsType, 'event_details'>> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.inboxRequestedRecordInfo(eventId), HttpMethod.GET);
