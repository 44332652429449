import React, {FC, useCallback, useMemo} from 'react';
import {Card, Popper, TextField} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Autocomplete} from '@material-ui/lab';

import {ANCHOR, colors} from '../../../shared/styles/theme';
import {RemappedPhysiciansType} from '../constatnts/types';

interface CustomizedSelectProps {
  data: RemappedPhysiciansType[];
  loading: boolean;
  placeholderText?: string;
  noOptionsFoundText?: string;
  errorText?: string;
  emptyDataText?: string;
  error: boolean;
  testId?: string;
  setSelectedProvider: (val: RemappedPhysiciansType | null) => void;
  selectedValue: RemappedPhysiciansType | null;
}

export const SingleAutocomplete: FC<CustomizedSelectProps> = ({
  data,
  loading,
  placeholderText = 'Search',
  noOptionsFoundText = 'No options found',
  errorText = 'Something went wrong. Please reload this page',
  emptyDataText = 'No data is available',
  error,
  testId,
  selectedValue,
  setSelectedProvider,
}) => {
  const noOptionsText = useMemo(() => {
    if (error) {
      return <div style={{color: colors.red}}>{errorText}</div>;
    }
    if (data.length === 0 && !error) {
      return emptyDataText;
    }
    return noOptionsFoundText;
  }, [data.length, emptyDataText, error, errorText, noOptionsFoundText]);

  const getOptionLabel = useCallback(
    (option: {label: string}) => option?.label,
    []
  );

  const getOptionSelected = useCallback(
    (option, value) => option.label === value.label,
    []
  );

  const getPaperComponent = useCallback(
    props => <Card variant="outlined" {...props} />,
    []
  );

  const onChange = useCallback(
    (_, value) => setSelectedProvider(value),
    [setSelectedProvider]
  );

  const getPopperComponent = useCallback(
    props => (
      <Popper
        modifiers={{
          offset: {enabled: true, offset: `0, ${ANCHOR.style.top}`},
        }}
        {...props}
      />
    ),
    []
  );

  const getInput = useCallback(
    params => (
      <TextField
        {...params}
        variant="outlined"
        size="small"
        placeholder={placeholderText}
        fullWidth
      />
    ),
    [placeholderText]
  );

  return (
    <Autocomplete
      popupIcon={<KeyboardArrowDownIcon />}
      closeIcon={<CancelIcon fontSize="small" />}
      data-testid={testId ? `${testId}-single-autocomplete` : null}
      options={data}
      value={selectedValue}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={loading}
      style={{width: '100%'}}
      PaperComponent={getPaperComponent}
      noOptionsText={noOptionsText}
      onChange={onChange}
      PopperComponent={getPopperComponent}
      renderInput={getInput}
    />
  );
};
