import React, {Fragment, useCallback, useState} from 'react';
import {IconButton} from '@material-ui/core';
import styled from 'styled-components';

import {
  List,
  ListItemKey,
  ListItemValue,
} from '../../../shared/components/list';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {CopyIcon} from '../../../shared/icons/copyIcon';
import {DocumentType, InsuranceType} from '../constants/types';

type Props = {
  patientDocumentInfo?: DocumentType;
};

function isInsuranceType(
  patientDocumentInfo: DocumentType
): patientDocumentInfo is InsuranceType {
  const insuranceInfo = patientDocumentInfo as InsuranceType;
  return (
    'health_insurance_provider' in patientDocumentInfo &&
    insuranceInfo.health_insurance_provider?.length !== 0 &&
    'member_id_number' in patientDocumentInfo &&
    insuranceInfo.member_id_number?.length !== 0 &&
    'member_name' in patientDocumentInfo
  );
}

const formatInsuranceDetails = (patientDocumentInfo: InsuranceType) => [
  {
    title: 'Payer',
    values: [
      {
        title: 'Name',
        value: patientDocumentInfo.health_insurance_provider,
      },
    ],
  },
  {
    title: 'Patient',
    values: [
      {
        title: 'Name',
        value: patientDocumentInfo.member_name,
      },
      {
        title: 'Member ID',
        value: patientDocumentInfo.member_id_number,
      },
      {
        title: 'Group #',
        value: patientDocumentInfo.group_number,
      },
    ],
  },
];

const formatPatientDocumentDetails = (
  patientDocumentInfo: DocumentType | undefined
) => {
  if (!patientDocumentInfo) {
    return null;
  }
  if (isInsuranceType(patientDocumentInfo)) {
    return formatInsuranceDetails(patientDocumentInfo);
  }
  return null;
};

const CopyItemOnHover: React.FC<{title: string; value: string}> = ({
  title,
  value,
}) => {
  const showMessage = useCustomizedSnackbar();

  const [isHovering, setIsHovering] = useState(false);

  const onClickCopyIcon = useCallback(
    async fieldToCopy => {
      await navigator.clipboard.writeText(fieldToCopy);
      showMessage(<span>Copied!</span>, 'success');
    },
    [showMessage]
  );

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  return (
    <ItemWrapper onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
      <ItemValue key={value}>{value}</ItemValue>
      {isHovering && (
        <div>
          <IconButton
            data-testid={`copy-btn-${title}`}
            size="small"
            onClick={() => onClickCopyIcon(value)}
          >
            <CopyIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </ItemWrapper>
  );
};

export const PatientDocumentDetails: React.FC<Props> = ({
  patientDocumentInfo,
}) => {
  const formattedDetails = formatPatientDocumentDetails(patientDocumentInfo);

  if (formattedDetails === null) {
    return null;
  }

  return (
    <DetailsWrapper>
      {formattedDetails.map(({title, values}) => (
        <DetailsContainer key={title}>
          <Title>{title}</Title>
          <ListWrapper>
            {values.map(({title, value}) => (
              <Fragment key={title}>
                <ItemKey key={title}>{title}</ItemKey>
                <CopyItemOnHover title={title} value={value} />
              </Fragment>
            ))}
          </ListWrapper>
        </DetailsContainer>
      ))}
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 0 0;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
`;

const ListWrapper = styled(List)`
  grid-gap: 8px 16px;
`;

const ItemWrapper = styled.div`
  line-height: 20px;
  display: grid;
  grid-template-columns: max-content 50px;
  column-gap: 10px;
  max-width: 300px;
  word-break: break-all;
`;

const ItemValue = styled(ListItemValue)`
  max-width: 270px;
  line-height: 20px;
`;

const ItemKey = styled(ListItemKey)`
  min-width: 75px;
`;
