import React, {useCallback, useEffect} from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  MenuProps,
  Select,
} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import * as yup from 'yup';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button, CancelButton} from '../../../shared/components/button';
import {
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {FormattedMRNsDetailsType} from '../../../shared/constants/formattedMRNsDetailsType';
import {ANCHOR, colors} from '../../../shared/styles/theme';
import {EHR, useEHRs} from '../hooks/useEHRs';
import {useMRNs} from '../hooks/useMRNs';

const PROGRESS_SIZE = 22;

const mrnValidationSchema = () =>
  yup.object().shape({
    mrn: yup.string().trim().required('MRN is required'),
    ehrId: yup
      .string()
      .trim()
      .required('EHR is required')
      .notOneOf(['default'], 'EHR is required'),
  });

type Props = {
  data: FormattedMRNsDetailsType | null;
  consumerUuid: string;
  isOpen: boolean;
  patientName: string;
  onClose: () => void;
  container: HTMLElement | null;
};

type InitialValues = {
  ehrId: string;
  mrn: string;
};

type MenuSelectProps = {
  data: FormattedMRNsDetailsType | null;
  iconComponent: (() => null) | undefined;
  getEHRname: (selectedEHRid: string) => string | JSX.Element;
  values: InitialValues;
  formik: any;
  maxWidthMenu: string;
  menuDisplay: string;
  EHRsIsLoading: boolean;
  EHRsHasError: string | null;
  EHRs: EHR[];
  getEHRmenuItems: () => JSX.Element[] | null;
};

function MenuSelect({
  data,
  iconComponent,
  getEHRname,
  values,
  formik,
  maxWidthMenu,
  menuDisplay,
  EHRsIsLoading,
  EHRsHasError,
  EHRs,
  getEHRmenuItems,
}: Readonly<MenuSelectProps>): JSX.Element {
  return (
    <Select
      labelId="ehrId-label"
      id="ehrId"
      name="ehrId"
      autoWidth={false}
      disabled={!!data}
      data-testid="ehr-select"
      IconComponent={iconComponent}
      inputProps={{
        'data-testid': 'ehr-select-input',
      }}
      MenuProps={ANCHOR as Partial<MenuProps>}
      renderValue={() => getEHRname(values.ehrId)}
      value={String(values.ehrId)}
      onChange={formik.handleChange}
      onBlur={e => {
        formik.handleBlur(e);
        formik.setFieldTouched('ehr', true);
      }}
    >
      <MenuItem
        key="default"
        value="default"
        style={{
          width: '100%',
          pointerEvents: 'none',
          whiteSpace: 'normal',
          backgroundColor: 'transparent',
          maxWidth: maxWidthMenu,
          display: menuDisplay,
        }}
      >
        {EHRsIsLoading ? (
          <LoadingWrapper>
            <div>Loading...</div>
            <CircularProgress color="inherit" size={16} />
          </LoadingWrapper>
        ) : null}

        {EHRsHasError && !EHRsIsLoading ? (
          <div style={{color: colors.red}}>{EHRsHasError}</div>
        ) : null}

        {EHRs.length === 0 && !EHRsIsLoading && !EHRsHasError ? (
          <div style={{color: colors.grey801}}>No data is available</div>
        ) : null}
      </MenuItem>

      {getEHRmenuItems()}
    </Select>
  );
}

export const ModalWindowManageMRN: React.FC<Props> = ({
  data,
  consumerUuid,
  patientName,
  onClose,
  isOpen,
  container,
}) => {
  const currentUser = useRecoilValue(currentUserState);
  const {EHRs, EHRsIsLoading, EHRsHasError, getEHRs} = useEHRs();
  const {
    addMRNisLoading,
    onAddMRN,
    editMRNisLoading,
    onEditMRN,
    deleteMRNisLoading,
    onDeleteMRN,
  } = useMRNs();
  const initialValues: InitialValues = {
    ehrId: data?.ehrId && !EHRsIsLoading ? String(data?.ehrId) : 'default',
    mrn: data?.mrn ?? '',
  };

  const getEHRname = useCallback(
    (selectedEHRid: string) => {
      if (data) {
        return data.ehrName;
      }
      const ehr = EHRs.find(({id}) => id === selectedEHRid);
      return ehr?.name ?? <Placeholder>Select EHR</Placeholder>;
    },
    [EHRs, data]
  );

  const getEHRmenuItems = () => {
    if (!EHRsIsLoading && !EHRsHasError && EHRs.length !== 0) {
      return EHRs.map(ehr => (
        <MenuItem key={ehr.id} value={String(ehr.id)}>
          {ehr.name}
        </MenuItem>
      ));
    }

    return null;
  };

  const onSave = useCallback(
    async (ehrId: string, mrn: string) => {
      const ehr = EHRs.find(({id}) => ehrId === id);

      if (ehr && !data) {
        await onAddMRN(consumerUuid, ehr, mrn, onClose);
      } else if (ehr && data) {
        const mrnId = data.id;

        await onEditMRN(consumerUuid, mrnId, ehr, mrn, onClose);
      }
    },
    [EHRs, consumerUuid, data, onAddMRN, onClose, onEditMRN]
  );

  const onSubmit = useCallback(({ehrId, mrn}) => onSave(ehrId, mrn), [onSave]);

  const onDelete = useCallback(async () => {
    if (data) {
      const mrnId = data.id;

      if (currentUser?.relyingParty.id) {
        await onDeleteMRN(
          consumerUuid,
          mrnId,
          currentUser?.relyingParty.id,
          onClose
        );
      }
    }
  }, [consumerUuid, currentUser?.relyingParty.id, data, onClose, onDeleteMRN]);

  useEffect(() => {
    if (currentUser?.relyingParty.id && isOpen) {
      getEHRs(currentUser.relyingParty.id);
    }
  }, [getEHRs, currentUser?.relyingParty.id, isOpen]);
  const iconComponent = data ? () => null : undefined;
  const maxWidthMenu = EHRsIsLoading ? 'inherit' : 'fit-content';
  const menuDisplay =
    EHRsIsLoading || EHRsHasError || EHRs.length === 0
      ? 'inline-block'
      : 'none';
  const DeleteButton = (
    <Button
      disabled={editMRNisLoading || deleteMRNisLoading}
      onClick={onDelete}
      testID="delete-button"
      color="dangerous"
    >
      {deleteMRNisLoading ? (
        <CircularProgress size={PROGRESS_SIZE} />
      ) : (
        'Delete'
      )}
    </Button>
  );
  return (
    <BaseModalWindow open={isOpen} container={container}>
      {data ? (
        <Box
          data-testid="edit-mrn-modal-window-title"
          component="p"
          fontWeight="bold"
          margin={0}
        >
          Edit MRN
        </Box>
      ) : (
        <>
          <Box
            data-testid="add-mrn-modal-window-title"
            component="p"
            fontWeight="bold"
            margin={0}
          >
            Add MRN
          </Box>
          <p>
            Please select ERH from the dropdown list and specify MRN related to
            the <b>{patientName}</b>
          </p>
        </>
      )}

      <Formik
        validationSchema={mrnValidationSchema}
        initialValues={initialValues}
        initialErrors={{
          ehrId: '',
          mrn: '',
        }}
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({values, ...formik}) => {
          const helperErrors = formik.touched.ehrId && formik.errors.ehrId;
          const inputErrors = formik.touched.mrn && formik.errors.mrn;
          const showProgress = addMRNisLoading || editMRNisLoading;

          return (
            <Form>
              <FormWrapper>
                <div>
                  <SelectWrapper
                    variant="outlined"
                    required
                    error={!!formik.errors.ehrId && !!formik.touched.ehrId}
                  >
                    <InputLabel id="ehrId-label">EHR</InputLabel>
                    <MenuSelect
                      data={data}
                      iconComponent={iconComponent}
                      getEHRname={getEHRname}
                      values={values}
                      formik={formik}
                      maxWidthMenu={maxWidthMenu}
                      menuDisplay={menuDisplay}
                      EHRsIsLoading={EHRsIsLoading}
                      EHRsHasError={EHRsHasError}
                      EHRs={EHRs}
                      getEHRmenuItems={getEHRmenuItems}
                    />
                    <InputHelperText shrink>
                      {helperErrors ?? ''}
                    </InputHelperText>
                  </SelectWrapper>
                </div>
                <div>
                  <InputFormControlWithFormik
                    required
                    id="mrn"
                    name="mrn"
                    label="MRN"
                    placeholder="Type MRN"
                    testIdPrefix="mrn"
                    error={inputErrors || ''}
                  />
                </div>
              </FormWrapper>

              <FooterWrapper>
                <div>{data ? DeleteButton : null}</div>
                <div>
                  <CancelButton
                    disabled={
                      addMRNisLoading || editMRNisLoading || deleteMRNisLoading
                    }
                    onClick={onClose}
                  />
                  <Button
                    type="submit"
                    testID="save-button"
                    disabled={
                      formik.isSubmitting ||
                      !formik.dirty ||
                      !formik.isValid ||
                      addMRNisLoading ||
                      editMRNisLoading ||
                      deleteMRNisLoading
                    }
                  >
                    {showProgress ? (
                      <CircularProgress size={PROGRESS_SIZE} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </FooterWrapper>
            </Form>
          );
        }}
      </Formik>
    </BaseModalWindow>
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 8px;
  margin-top: 16px;
  margin-bottom: 44px;
`;

const SelectWrapper = styled(FormControl)`
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Placeholder = styled.span`
  opacity: 0.42;
`;
