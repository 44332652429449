import React from 'react';
import {Backdrop, LinearProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  loading: {
    width: '100%',
    position: 'absolute',
  },
  backdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.59)',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));

export const LoadingBar = ({loading = false, masking = false}) => {
  const styles = useStyles();

  return (
    <>
      {loading && (
        <Container fullHeight={masking}>
          <LinearProgress className={styles.loading} />
          {masking && <Backdrop className={styles.backdrop} open />}
        </Container>
      )}
    </>
  );
};

LoadingBar.propTypes = {
  loading: PropTypes.bool,
  masking: PropTypes.bool,
};

const Container = styled.div`
  position: absolute;
  height: ${({fullHeight}) => (fullHeight ? '100%' : 'auto')};
  width: 100%;
  z-index: ${({fullHeight}) => (fullHeight ? 1 : 0)};
`;
