import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {REFETCH_INTERVAL} from '../../../shared/constants/refetchInterval';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {getRemappedMedicalRecordNumbers} from '../../../shared/utils/getRemappedMedicalRecordNumbers';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';
import {
  PatientFormattedType,
  PatientResponseType,
  SelectedPhysicianType,
} from '../constants/types';
import {
  fetchEnrolledPatientsBySearchOptions,
  fetchMyPatientsConsumerUUIDS,
  fetchPhysiciansUUIDS,
} from '../utils/fetchers';

const getPhysiciansUUIDS = async (
  selectedPhysicians: SelectedPhysicianType[],
  relyingPartyId: number | null
) => {
  const physiciansIDS = selectedPhysicians.map(
    (physician: SelectedPhysicianType) => physician.id
  );
  const physiciansUUIDS = await fetchPhysiciansUUIDS(
    relyingPartyId,
    physiciansIDS
  );

  return physiciansUUIDS;
};

const fetchEnrolledPatients = async (
  partyId: number | null,
  currentUserType: USER_TYPES | null,
  search: string,
  physicians: SelectedPhysicianType[],
  statuses: CONNECTION_STATUSES[]
) => {
  const uuids = physicians.length
    ? await getPhysiciansUUIDS(physicians, partyId)
    : [];

  const promiseEnrolledPatients =
    physicians.length !== 0 && uuids.length === 0
      ? Promise.resolve([])
      : fetchEnrolledPatientsBySearchOptions(partyId, search, uuids, statuses);

  const promiseMyPatientsConsumerUuids =
    currentUserType === USER_TYPES.PHYSICIAN
      ? fetchMyPatientsConsumerUUIDS(partyId)
      : Promise.resolve([]);

  const [myPatientsConsumerUuids, enrolledPatients] = await Promise.all([
    promiseMyPatientsConsumerUuids,
    promiseEnrolledPatients,
  ]);

  return {
    myPatientsConsumerUuids,
    enrolledPatients,
  };
};

const formatEnrolledPatients = (
  patients: PatientResponseType[],
  uuids: string[]
) =>
  patients.map((user: PatientResponseType) => ({
    name: `${user.first_name} ${user.last_name}`,
    firstName: user.first_name,
    date_of_birth: user.date_of_birth,
    mobile_phone_number: user.mobile_phone_number,
    isPreferred: uuids.includes(user.consumer_uuid),
    consumer_uuid: user.consumer_uuid,
    medicalRecordNumbers: getRemappedMedicalRecordNumbers(
      user.mrn_ehr_responses
    ),
    patientSharingRequestData: user.connection,
  }));

const getEnrolledPatients = async (
  relyingPartyId: number | null,
  currentUserType: USER_TYPES | null,
  searchValue: string,
  selectedPhysicians: SelectedPhysicianType[],
  selectedStatuses: CONNECTION_STATUSES[]
) => {
  try {
    const {myPatientsConsumerUuids: uuids, enrolledPatients: patients} =
      await fetchEnrolledPatients(
        relyingPartyId,
        currentUserType,
        searchValue,
        selectedPhysicians,
        selectedStatuses
      );

    return formatEnrolledPatients(patients, uuids);
  } catch (e) {
    throw new Error('Something went wrong. Please try refresh the page.');
  }
};

export const useEnrolledPatients = (
  relyingPartyId: number | null,
  currentUserType: USER_TYPES | null,
  searchValue: string,
  selectedPhysicians: SelectedPhysicianType[],
  selectedStatuses: CONNECTION_STATUSES[]
): UseQueryResult<PatientFormattedType[]> =>
  useQuery({
    queryKey: [
      'enrolledPatients',
      searchValue,
      ...selectedPhysicians,
      ...selectedStatuses,
    ],
    queryFn: () =>
      getEnrolledPatients(
        relyingPartyId,
        currentUserType,
        searchValue,
        selectedPhysicians,
        selectedStatuses
      ),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: REFETCH_INTERVAL,
  });
