import {useCallback, useState} from 'react';

import {fetchEHRs} from '../utils/fetchers';

export type EHR = {
  id: string;
  link: string;
  name: string;
  relying_party_id: number;
};

export const useEHRs = (): {
  EHRs: EHR[];
  EHRsIsLoading: boolean;
  EHRsHasError: null | string;
  getEHRs: (relyingPartyId: number) => Promise<void>;
} => {
  const [currentEHRs, setCurrentEHRs] = useState<EHR[]>([]);
  const [currentEHRsIsLoading, setCurrentEHRsIsLoading] =
    useState<boolean>(false);
  const [currentEHRsHasError, setCurrentEHRsHasError] = useState<string | null>(
    null
  );

  const getEHRs = useCallback(async (relyingPartyId: number) => {
    try {
      setCurrentEHRsIsLoading(true);
      setCurrentEHRsHasError(null);

      const currentEHRs = await fetchEHRs(relyingPartyId);

      setCurrentEHRs(currentEHRs);
    } catch (e) {
      setCurrentEHRsHasError('Something went wrong. Please reload this page.');
    } finally {
      setCurrentEHRsIsLoading(false);
    }
  }, []);

  return {
    EHRs: currentEHRs,
    EHRsIsLoading: currentEHRsIsLoading,
    EHRsHasError: currentEHRsHasError,
    getEHRs,
  };
};
