import React, {FC} from 'react';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';
import {ReturnDocumentType} from '../constants/types';

type Props = {
  patientDocumentsInfo: ReturnDocumentType[];
};

export const PatientDocumentContentFullSize: FC<Props> = ({
  patientDocumentsInfo,
}) => (
  <ContentWrapper>
    {patientDocumentsInfo.map(({image, subtitle}) =>
      image ? (
        <div key={image.link}>
          <DocumentName className="title">{subtitle}</DocumentName>
          <DocumentImg src={image.link} alt={subtitle} />
        </div>
      ) : null
    )}
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const DocumentName = styled.p`
  margin: 0 0 8px 0;
  font-size: 14px;
`;

const DocumentImg = styled.img`
  width: 400px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
`;
