import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/theme';

type Props = {
  title?: string;
  description?: string;
  isError?: boolean;
};

export const Description: React.FC<Props> = ({
  title,
  description,
  isError = false,
}) => (
  <>
    <Title isError={isError}>{title}</Title>
    {description ? <Content>{description}</Content> : null}
  </>
);

const Title = styled('p')<{isError: boolean}>`
  margin: 0;
  line-height: 36px;
  font-size: 24px;
  font-weight: 700;
  color: ${props => (props?.isError ? colors.red : colors.black)};
`;

export const Content = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${colors.grey801};
  font-style: italic;
  margin: 0 0 24px;
`;
