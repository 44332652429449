import React, {useCallback, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {getAuth} from 'firebase/auth';
import {isNil} from 'lodash';
import {useRecoilState, useRecoilValue} from 'recoil';
import useSWR from 'swr';

import {
  currentTenantIdState,
  currentUserState,
} from '../../shared/atoms/authAtom';
import {ROLES} from '../../shared/constants/roles';
import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../shared/hooks/useErrorHandling';
import {fetcher} from '../../shared/utils/fetcher';
import {HttpResponseError} from '../../shared/utils/httpResponseError';
import {urls} from '../../shared/utils/urls';
import {SignPageTemplate} from '../components/signPageTemplate';
import {SignUpForm} from '../components/signUpForm';
import {VerifySMS} from '../components/verifySMS';
import {errorMessage, pageTitle, successMessage} from '../constants/constants';
import {useAuth} from '../hooks/useAuth';
import {useMFAErrorHandling} from '../hooks/useMFAErrorHandling';
import {useSkipMfa} from '../hooks/useSkipMfa';

export const SignUpPage = () => {
  const history = useHistory();
  const {tenantId, invitationId} = useParams();
  const currentUser = useRecoilValue(currentUserState);

  const [currentTenantId, setTenantId] = useRecoilState(currentTenantIdState);
  if (tenantId) {
    getAuth().tenantId = tenantId;
    setTenantId(tenantId);
  }

  const showMessage = useCustomizedSnackbar();
  const handleMFAError = useMFAErrorHandling();
  const handleError = useErrorHandling(errorMessage.ACTIVATION);
  const {signIn, startMfaForSignUp, finishMfaForSignUp, signOut} = useAuth();

  const [loading, setLoading] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);

  const {data: user, error} = useSWR(urls.userInvitation(invitationId));
  const {generateMfaRecord} = useSkipMfa(user?.email, currentTenantId);

  const createAccount = useCallback(
    async password => {
      let accountInfo;
      try {
        accountInfo = await fetcher(urls.users, 'POST', {
          password,
          invitation_id: invitationId,
          tenant_id: currentTenantId,
        });
      } catch (e) {
        await handleError(e);
      }
      return accountInfo;
    },
    [handleError, currentTenantId, invitationId]
  );

  const startEnrollment = useCallback(
    async (password, verifier) => {
      setLoading(true);
      await signOut();
      try {
        if (currentUser?.email !== user?.email) {
          const accountInfo = await createAccount(password);
          if (isNil(accountInfo)) {
            return;
          }
        }
        await signIn(user?.email, password);
        await startMfaForSignUp(verifier);
        setAuthenticating(true);
      } catch (e) {
        handleMFAError(e);
      }
      setLoading(false);
    },
    [
      createAccount,
      currentUser,
      handleMFAError,
      signIn,
      startMfaForSignUp,
      user,
      signOut,
    ]
  );

  const finishEnrollment = useCallback(
    async verificationCode => {
      await finishMfaForSignUp(verificationCode);

      if (user.roles === ROLES.SCREENER) {
        await signOut();
        history.replace('/set-password-successful');
      } else {
        generateMfaRecord();
        history.replace('/');
      }
      showMessage(successMessage.SIGN_UP, 'success');
    },
    [finishMfaForSignUp, generateMfaRecord, history, showMessage, signOut, user]
  );

  if (error instanceof HttpResponseError && error.response.status === 410) {
    history.replace(`/${currentTenantId}/sign-in`);
    return null;
  }

  return (
    <SignPageTemplate
      title={
        authenticating ? pageTitle.VERIFICATION : pageTitle.MANAGER_SIGN_UP
      }
      isLogoLeft={false}
      loading={loading}
    >
      {user &&
        (authenticating ? (
          <VerifySMS
            loading={loading}
            setLoading={setLoading}
            onSubmit={finishEnrollment}
            resendCode={startMfaForSignUp}
            error={errorMessage.ACTIVATION}
            phoneNumber={user.phone_number}
          />
        ) : (
          <SignUpForm
            onSubmit={startEnrollment}
            email={user.email}
            phoneNumber={user.phone_number}
            setLoading={setLoading}
          />
        ))}
    </SignPageTemplate>
  );
};
