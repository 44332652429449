import React from 'react';
import {Field} from 'formik';
import {isBoolean} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {InputTextField} from '../../../shared/components/inputTextField';
import {POLICY_TYPES} from '../constants/defaultPolicy';
import {vaccineRequired} from '../constants/policies';
import {policyTitleMap} from '../utils/policyFormatter';

const PolicyInputFields = ({policies, policyType}) => {
  const mapKey = POLICY_TYPES[policies.policy_type_id];
  const titleMap = policyTitleMap[mapKey];

  if (policyType === 'maximum_self_assessment_age') {
    return (
      <Input
        label={titleMap[policyType]}
        name={policyType}
        type="number"
        suffix="hour(s)"
        testID="policyInputField"
        placeholder="Required"
      />
    );
  }
  if (policyType === 'vaccine_required') {
    return (
      <Field
        as={CustomizedSelect}
        name={policyType}
        label={titleMap[policyType]}
        options={vaccineRequired}
        values={vaccineRequired}
      />
    );
  }
  if (isBoolean(policies[policyType])) {
    return (
      <Field
        as={CustomizedSelect}
        name={policyType}
        label={titleMap[policyType]}
        options={['Yes', 'No']}
        values={[true, false]}
        style={{marginBottom: 20}}
        testID={policyType}
      />
    );
  }
  return null;
};

PolicyInputFields.propTypes = {
  policies: PropTypes.shape({
    policy_type_id: PropTypes.string,
  }),
  policyType: PropTypes.string,
};

export const PolicyInputs = ({policies}) => {
  const mapKey = POLICY_TYPES[policies?.policy_type_id];
  const titleMap = policyTitleMap[mapKey];
  return (
    <PolicyContainer>
      {Object.keys(titleMap)?.map(policyType => (
        <PolicyInputFields
          policies={policies}
          policyType={policyType}
          key={policyType}
        />
      ))}
    </PolicyContainer>
  );
};

PolicyInputs.propTypes = {
  policies: PropTypes.shape({
    policy_type_id: PropTypes.string,
  }),
};

const PolicyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-bottom: 38px;
`;

const Input = styled(InputTextField)`
  margin-bottom: 14px;
`;
