import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const ActionIcon = props => (
  <SvgIcon {...props} viewBox="2 3 15 15" width="20" height="20">
    <path
      d="M16.125 3H3.875C3.392 3 3 3.36894 3 3.82353V16.1765C3 16.6315 3.392 17 3.875 17H16.125C16.6084 17 17 16.6315 17 16.1765V3.82353C17 3.36894 16.6084 3 16.125 3ZM16.125 16.1765H3.875V3.82353H16.125V16.1765Z"
      fill="#7A7A7A"
    />
    <path
      d="M6.4 7.75H13.6C13.8212 7.75 14 7.58238 14 7.375C14 7.168 13.8212 7 13.6 7H6.4C6.1792 7 6 7.168 6 7.375C6 7.58237 6.1792 7.75 6.4 7.75ZM13.6 9.625H6.4C6.1792 9.625 6 9.793 6 10C6 10.2074 6.1792 10.375 6.4 10.375H13.6C13.8212 10.375 14 10.2074 14 10C14 9.793 13.8212 9.625 13.6 9.625ZM13.6 12.25H6.4C6.1792 12.25 6 12.4176 6 12.625C6 12.8324 6.1792 13 6.4 13H13.6C13.8212 13 14 12.8324 14 12.625C14 12.4176 13.8212 12.25 13.6 12.25Z"
      fill="#7A7A7A"
    />
  </SvgIcon>
);
