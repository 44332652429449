import React from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {menuCollapseStore} from '../../../shared/atoms/menuCollapseStore';
import {matchRoutePattern} from '../../../shared/constants/patterns';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';

const useStyles = makeStyles(() => ({
  root: {
    width: '300px',

    '& .MuiList-root a': {
      color: `${colors.black}`,
    },
  },

  firstLevel: {
    height: '48px',
    paddingLeft: '20px',
    cursor: 'not-allowed',
    pointerEvents: 'none',

    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },

    '& .MuiTypography-root': {
      fontFamily: 'Roboto-Medium',
      fontSize: '18px',
    },
  },

  secondLevel: {
    paddingLeft: '20px',
    height: '44px',

    fontFamily: 'Open Sans',
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },

    '&:hover': {
      backgroundColor: `${colors.grey250}`,
    },
    '& img': {
      display: 'none',
    },

    '&:hover img': {
      display: 'block',
      width: '30px',
      height: '30px',
    },
  },

  isActive: {
    backgroundColor: `${colors.grey250}`,
  },
}));

const MenuItem = ({item, roles, matchRoute}) => {
  const classes = useStyles();
  const {title, icon} = item;

  return (
    <>
      <ListItem button className={classes.firstLevel}>
        <ListItemIcon>
          <img src={icon} alt={title} />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      <Collapse in>
        <List component="div" disablePadding>
          {renderSubMenu(item, roles, matchRoute)}
        </List>
      </Collapse>
    </>
  );
};

const menuItemTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.string,
  prerequisiteRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.arrayOf(PropTypes.any),
});

MenuItem.propTypes = {
  item: menuItemTypes,
  roles: PropTypes.arrayOf(PropTypes.any),
  matchRoute: PropTypes.string,
};

const SubMenuItem = props => {
  const {path, icon, title, isActive} = props;
  const classes = useStyles();
  const {pathname: currentRoute} = useLocation();

  return (
    <NavLink to={getPath(path, currentRoute)}>
      <ListItem
        button
        className={clsx(classes.secondLevel, {
          [classes.isActive]: isActive,
        })}
      >
        <ListItemIcon>
          <img src={icon} alt={title} />
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </NavLink>
  );
};

SubMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

function renderSubMenu(menuItem, roles, matchRoute) {
  return menuItem.children.map(subMenuItem => {
    if (!isRoleMatched(roles, subMenuItem.prerequisiteRoles)) {
      return null;
    }
    const {title, icon, path} = subMenuItem;

    const matchPath = path.match(matchRoutePattern)[0];

    return (
      <SubMenuItem
        isActive={matchPath === matchRoute}
        key={title}
        title={title}
        path={path}
        icon={icon}
      />
    );
  });
}

export const Aside = ({menu = []}) => {
  const classes = useStyles();
  const collapsed = useRecoilValue(menuCollapseStore);
  const history = useHistory();
  const currentUser = useRecoilValue(currentUserState);
  const {roles} = currentUser;
  const matchRouteResult = matchRoutePattern.exec(history.location.pathname);
  const matchRoute = matchRouteResult ? matchRouteResult[0] : null;

  if (collapsed) {
    return <Container />;
  }
  return (
    <Container>
      <List component="nav" className={classes.root}>
        {menu.map(item =>
          isRoleMatched(roles, item.prerequisiteRoles) ? (
            <MenuItem
              key={item.title}
              item={item}
              roles={roles}
              matchRoute={matchRoute}
            />
          ) : null
        )}
      </List>
      <Footer>
        Proprietary & Confidential AllClear ID Health, Inc. © 2024. All Rights
        Reserved
      </Footer>
    </Container>
  );
};

Aside.propTypes = {
  menu: PropTypes.arrayOf(menuItemTypes),
};

/**
 *
 * @desc change path to '/activity-log/list' to stop user from redirecting to '/activity-log' when user click the menu item at the activity log page
 * @param {string} path
 * @param {string} currentRoute
 * @return {string} finally path
 */
function getPath(path, currentRoute) {
  if (
    path === '/activity-log' &&
    /\/activity-log\/relying-party/.exec(currentRoute)
  ) {
    return currentRoute;
  }

  return path;
}

const Container = styled.div`
  border-right: 1px solid ${colors.grey50};
  overflow-y: auto;
  padding-right: 1px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  color: ${colors.grey801};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  padding: 0 20px 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 260px;
`;
