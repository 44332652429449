import React, {FC, useCallback, useMemo} from 'react';
import {Box, Chip} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import {useQueryClient} from '@tanstack/react-query';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ChipsWrapper} from '../../../shared/components/chip';
import {FilterWithCheckboxes} from '../../../shared/components/filterWithCheckboxes';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SearchInput} from '../../../shared/components/searchInput';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {Crumb} from '../../../shared/interfaces/crumb';
import {colors} from '../../../shared/styles/theme';
import {isMyPatientAddingState} from '../atoms/isMyPatientAddingState';
import {isMyPatientRemovingState} from '../atoms/isMyPatientRemovingState';
import {isPatientConnectingState} from '../atoms/isPatientConnectingState';
import {isSharingMedicalRecordsWithProviderState} from '../atoms/isSharingMedicalRecordsWithProviderState';
import {PatientsPageWrapper} from '../components/patientsPageWrapper';
import {
  getMyPatientNameCell,
  getPatientsTableCells,
} from '../constants/patientsTableConfig';
import {
  CONNECTION_STATUSES,
  CONNECTION_STATUSES_LABELS,
} from '../constants/patientStatuses';
import {useMyPatients} from '../hooks/useMyPatients';
import {useSearch} from '../hooks/useSearch';
import {useSelectConnectionStatus} from '../hooks/useSelectConnectionStatus';

type Props = {
  title: string;
  crumbs: Crumb[];
  activeTabIndex: number;
};

export const MyPatientsPage: FC<Props> = ({title, crumbs, activeTabIndex}) => {
  const queryClient = useQueryClient();

  const [searchValue, searchError, onClearSearch, onSearch] = useSearch();

  const currentUser = useRecoilValue(currentUserState);
  const isMyPatientRemoving = useRecoilValue(isMyPatientRemovingState);
  const isMyPatientAdding = useRecoilValue(isMyPatientAddingState);
  const isPatientConnecting = useRecoilValue(isPatientConnectingState);
  const isPatientSharingRecords = useRecoilValue(
    isSharingMedicalRecordsWithProviderState
  );
  const {selectedConnectionStatuses, handleToggleConnectionStatuses} =
    useSelectConnectionStatus();

  const {
    data: myPatients = [],
    isLoading: isMyPatientsLoading,
    isFetching: isMyPatientsFetching,
    isFetchedAfterMount: isMyPatientsFetchedAfterMount,
    isError: isMyPatientsError,
  } = useMyPatients(
    currentUser?.relyingParty.id ?? null,
    searchValue,
    selectedConnectionStatuses
  );

  const tableColumns = useMemo(() => {
    const updateMyPatients = async () => {
      await queryClient.invalidateQueries({
        queryKey: ['myPatients'],
      });
    };

    return [
      getMyPatientNameCell(updateMyPatients),
      ...getPatientsTableCells(updateMyPatients),
    ];
  }, [queryClient]);

  const isFirstLoadingCase =
    isMyPatientsLoading &&
    searchValue.length === 0 &&
    myPatients.length === 0 &&
    selectedConnectionStatuses.length === 0;

  const noDataCase =
    (!isMyPatientsLoading || !isMyPatientsFetching) &&
    searchValue.length === 0 &&
    myPatients.length === 0 &&
    selectedConnectionStatuses.length === 0;

  const nothingFoundCase =
    myPatients.length === 0 &&
    (searchValue.length !== 0 || selectedConnectionStatuses.length !== 0);

  const isLoading =
    (isMyPatientsLoading ||
      isMyPatientRemoving ||
      isMyPatientAdding ||
      isPatientConnecting ||
      isPatientSharingRecords ||
      isMyPatientsFetching) &&
    !isMyPatientsFetchedAfterMount;

  const placeholderComponent = useMemo(() => {
    if (searchError || nothingFoundCase) {
      return (
        <Box
          component="div"
          fontStyle="italic"
          textAlign="center"
          color={colors.red}
        >
          {searchError ?? 'No results found. Please try again.'}
        </Box>
      );
    }

    return null;
  }, [nothingFoundCase, searchError]);

  const table = useReactTable({
    data: myPatients,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOnMouseDown = useCallback(event => event.stopPropagation(), []);

  return (
    <PatientsPageWrapper
      title={title}
      crumbs={crumbs}
      activeTabIndex={activeTabIndex}
      isLoading={isLoading}
    >
      {noDataCase && !isFirstLoadingCase && !isMyPatientsError ? (
        <NoDataPlaceholder noDataText="Select your patients from the Connections list" />
      ) : null}
      {!noDataCase && !isFirstLoadingCase && !isMyPatientsError ? (
        <>
          <FilterWrapper>
            <SearchInput
              placeholder="Search by Name, Phone or MRN"
              focusedPlaceholder="Type at least 3 characters to search"
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              testId="my-patients"
            />
            <FilterWithCheckboxesWrapper>
              <FilterWithCheckboxes
                id="connection-status-filter"
                testId="connection-status"
                label="Connection Status"
                items={CONNECTION_STATUSES_LABELS}
                selectedItems={selectedConnectionStatuses}
                toggleItem={
                  handleToggleConnectionStatuses as (item: string) => void
                }
              />
            </FilterWithCheckboxesWrapper>
            <ChipsWrapper data-testid="chips-wrapper">
              {selectedConnectionStatuses.map(
                (selectedStatus: CONNECTION_STATUSES) => (
                  <Chip
                    key={selectedStatus}
                    label={CONNECTION_STATUSES_LABELS[selectedStatus]}
                    deleteIcon={<CancelIcon onMouseDown={handleOnMouseDown} />}
                    onDelete={() =>
                      handleToggleConnectionStatuses(selectedStatus)
                    }
                    data-testid="DeleteIcon"
                  />
                )
              )}
            </ChipsWrapper>
          </FilterWrapper>
          <StyledTable
            table={table}
            testId="my-patients"
            placeholderComponent={placeholderComponent}
            isHeaderSticky
          />
        </>
      ) : null}
    </PatientsPageWrapper>
  );
};

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const FilterWithCheckboxesWrapper = styled.div`
  min-width: 175px;
`;
