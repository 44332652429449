import {useRef} from 'react';

interface UseFileInputReturn {
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleGetFile: () => void;
}

const useFileInput = (
  consumerId: string,
  handleFileSelect: (consumerId: string, file: File | null) => void
): UseFileInputReturn => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0] || null;
    handleFileSelect(consumerId, file);
    if (input) {
      input.value = '';
    }
  };

  const handleGetFile = (): void => {
    fileInputRef.current?.click();
  };

  return {
    fileInputRef,
    handleFileChange,
    handleGetFile,
  };
};

export default useFileInput;
