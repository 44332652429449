import React, {FC, ReactNode} from 'react';
import Markdown from 'markdown-to-jsx';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import AICareGuideProfileIcon from '../../../../../assets/icons/aICareGuideProfileIcon.svg';
import FileIcon from '../../../../../assets/icons/fileIcon.svg';
import {currentUserState} from '../../../../shared/atoms/authAtom';
import {AIChatProfileIcon} from '../../../../shared/icons/aIChatProfile';
import {Message as MessageType} from '../../../../shared/interfaces/aIChat';
import {colors} from '../../../../shared/styles/theme';
import {getInitials} from '../../../portalUsers/utils/formatter';
import {getExtension} from '../ChatFooterPreviewFiles';

interface ChatMessageProps {
  headerText: string;
  message: MessageType;
}

const BoldParagraph: React.FC<{children: ReactNode; level: number}> = ({
  children,
  level,
}) => (
  <div
    data-testid="markdown-text"
    style={{
      fontSize: `${26 - (level - 1) * 2}px`,
      fontWeight: 'bold',
      textAlign: 'start',
    }}
  >
    {children}
  </div>
);

const Paragraph: React.FC<{children: ReactNode}> = ({children}) => (
  <div
    data-testid="paragraph-text"
    style={{
      textAlign: 'start',
      marginBottom: '8px',
    }}
  >
    {children}
  </div>
);

const Code: React.FC<{children: ReactNode}> = ({children}) => (
  <div
    data-testid="markdown-code"
    style={{
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'break-spaces',
      width: '100%',
      background: colors.grey25,
    }}
  >
    {children}
  </div>
);

const markdownOverrides = {
  overrides: {
    h1: {component: BoldParagraph, props: {level: 1}},
    h2: {component: BoldParagraph, props: {level: 2}},
    h3: {component: BoldParagraph, props: {level: 3}},
    h4: {component: BoldParagraph, props: {level: 4}},
    h5: {component: BoldParagraph, props: {level: 5}},
    h6: {component: BoldParagraph, props: {level: 6}},
    p: {component: Paragraph},
    code: {component: Code},
  },
};

const ChatMessage: FC<ChatMessageProps> = ({headerText, message}) => {
  const {text, isFile, fileType, fileShowInChat, isUser, isAMedicalRecord} =
    message;
  const user = useRecoilValue(currentUserState);

  return (
    <StyledMessage>
      <ChatMessageHeader>
        {isUser ? (
          <AIChatProfileIcon initials={getInitials(user?.name)} />
        ) : (
          <ChatMessageIcon data-testid="ai-icon" src={AICareGuideProfileIcon} />
        )}
        {headerText}
      </ChatMessageHeader>
      {isFile && !isAMedicalRecord && fileType?.startsWith('image/') && (
        <img
          src={fileShowInChat}
          alt="file preview"
          data-testid="img-chat"
          style={{maxWidth: '223px', paddingLeft: '46px'}}
        />
      )}
      {isFile && (!fileType?.startsWith('image/') || isAMedicalRecord) && (
        <FileNameInChat
          data-testid="file-chat"
          style={{whiteSpace: 'pre-wrap', marginTop: '8px'}}
        >
          <FileIconContainer>
            <ChatMessageIcon width={32} height={32} src={FileIcon} />
            <FilePreviewIcon>{getExtension(fileType)}</FilePreviewIcon>
          </FileIconContainer>

          <FilePreviewText>{fileShowInChat}</FilePreviewText>
        </FileNameInChat>
      )}
      <ChatMessageContent>
        <Markdown options={markdownOverrides}>{text}</Markdown>
      </ChatMessageContent>
    </StyledMessage>
  );
};

export default ChatMessage;

const StyledMessage = styled.div<{isUser?: boolean}>`
  background-color: ${({isUser}) => (isUser ? '#DCF8C6' : '#FFF')};
  align-self: ${({isUser}) => (isUser ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
`;

const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: -4px;
  gap: 16px;
`;

const ChatMessageIcon = styled.img<{
  width?: number;
  height?: number;
}>`
  width: ${props => `${props.width ?? 32}px`};
  height: ${props => `${props.height ?? 32}px`};
`;

const ChatMessageContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 47px;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const FileNameInChat = styled.div`
  border-radius: 6px;
  border: 1px solid ${colors.backgroundPrimary};
  background: ${colors.white};
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  padding: 10px 8px 10px 16px;
  margin-left: 46px;
  width: 310px;
`;

const FilePreviewText = styled.div`
  height: 18px;
  width: 81%;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
`;

const FilePreviewIcon = styled.div`
  position: absolute;
  bottom: 6px;
  color: ${colors.white};
  font-family: 'Open Sans';
  font-size: 8px;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;
