import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import {MultiSelect} from '../../../shared/components/multiSelect';
import {urls} from '../../../shared/utils/urls';

const formatLocationData = locations => {
  if (locations) {
    return locations.map(location => ({
      ...location,
      value: location.id,
      label: location.name,
    }));
  }
  return [];
};

export const Locations = ({
  selectedLocations,
  onLocationsChange,
  relyingPartyId,
  policyTypeId,
  handleLoading,
}) => {
  const {data: locations} = useSWR(
    urls.relyingPartyLocationsByPolicyType(relyingPartyId, policyTypeId)
  );

  useEffect(() => {
    if (locations) {
      onLocationsChange(formatLocationData(locations));
      handleLoading(false);
    }
  }, [handleLoading, locations, onLocationsChange]);

  const onToggleOption = useCallback(
    selectedOptions => onLocationsChange(selectedOptions),
    [onLocationsChange]
  );
  const onClearOptions = useCallback(
    () => onLocationsChange([]),
    [onLocationsChange]
  );
  const onSelectAll = useCallback(
    isSelected =>
      onLocationsChange(isSelected ? formatLocationData(locations) : []),
    [locations, onLocationsChange]
  );

  return (
    <MultiSelect
      items={formatLocationData(locations)}
      selectedValues={selectedLocations}
      label="Select locations"
      placeholder={selectedLocations.length === 0 ? 'Select locations' : ''}
      selectAllLabel="All active locations"
      onToggleOption={onToggleOption}
      onClearOptions={onClearOptions}
      onSelectAll={onSelectAll}
    />
  );
};

Locations.propTypes = {
  selectedLocations: PropTypes.arrayOf(PropTypes.any).isRequired,
  onLocationsChange: PropTypes.func.isRequired,
  relyingPartyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  policyTypeId: PropTypes.string,
  handleLoading: PropTypes.func.isRequired,
};
