import {isEmpty, isNil} from 'lodash';
import useSWR from 'swr';

import {ACTIVITY_LOG_MODE} from '../../../shared/constants/activityLogMode';
import {
  ActivityLogFetchResult,
  ActivityLogType,
} from '../../../shared/interfaces/activityLogs';
import {
  isEndTimeInvalid,
  isFromTimeInvalid,
} from '../../../shared/utils/dateTimeCheck';
import {urls} from '../../../shared/utils/urls';
import {
  ActivityLogFetchConfigs,
  generateActivityLogPageUrl,
} from '../utils/activityFetchHelper';

interface ActivityLogHookListeners {
  logs?: ActivityLogType[];
  loading: boolean;
  totalPages?: number;
}

const isStreaming = (mode: string) => mode === ACTIVITY_LOG_MODE.LIVE_STREAM;
const isSelectedTimeInValid = (beginTime: Date, endTime: Date, mode: string) =>
  !isStreaming(mode) &&
  isEndTimeInvalid(endTime) &&
  isFromTimeInvalid(beginTime, endTime);

export const useActivityLog = (
  configs: ActivityLogFetchConfigs,
  isFirstRequest: boolean
): ActivityLogHookListeners => {
  const {locationIds, page = 0, beginTime, endTime, mode} = configs;
  const areParamsInvalid =
    isSelectedTimeInValid(beginTime, endTime, mode) || isEmpty(locationIds);

  const params = generateActivityLogPageUrl({...configs, page});

  const {data} = useSWR<ActivityLogFetchResult>(
    areParamsInvalid
      ? null
      : `${urls.activityLog}&${params}&first_request=${isFirstRequest}`,
    {
      refreshInterval: isStreaming(mode) ? 3000 : 0,
    }
  );

  return {
    logs: data?.content,
    loading: !areParamsInvalid && isNil(data),
    totalPages: data?.total_pages,
  };
};
