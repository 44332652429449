import {includes} from 'lodash';

import {ROLES, rolesMap} from '../constants/roles';

export const formatRoles = (roles: ROLES[]): string =>
  roles.reduce((accumulator, role) => {
    if (includes(Object.keys(rolesMap), role)) {
      if (accumulator === '') {
        return `${rolesMap[role]}`;
      }
      return `${accumulator}; ${rolesMap[role]}`;
    }
    return accumulator;
  }, '');
