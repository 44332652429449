import React, {useCallback} from 'react';

import useInputFieldFormik from '../../hooks/useInputFieldFormik';

import {InputField} from './InputField';

export const InputFieldWithFormik = props => {
  const {field, helpers} = useInputFieldFormik(props);
  const onBlur = useCallback(
    e => {
      field.onBlur(e);
      helpers.setTouched(true);
    },
    [field, helpers]
  );

  return <InputField {...field} {...props} onBlur={onBlur} />;
};
