import React from 'react';

import {UndoSnackbarContent} from '../../../shared/components/undoSnackbarContent';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';

export const usePatientsSnackbars = () => {
  const snackbar = useCustomizedSnackbar();

  const onShowDeleteMyPatientSnackbar = ({name, uuid, cb}) => {
    snackbar(
      <UndoSnackbarContent
        message={`${name} removed from the My Connections list`}
        testId={uuid}
        handleUndo={cb}
      />,
      'info',
      false
    );
  };

  return {
    onShowDeleteMyPatientSnackbar,
  };
};
