import React from 'react';
import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {INPUT_WIDTH_SIZES} from './form';

const useStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    display: 'grid',
    gridTemplateColumns: `${INPUT_WIDTH_SIZES.small} auto`,
    columnGap: theme.spacing(2.5),
  },
}));

export const SearchFormTemplate: React.FC<{
  label: JSX.Element;
  button: JSX.Element;
  input: JSX.Element;
  inputHelper: JSX.Element;
}> = ({label, button, input, inputHelper}) => {
  const {formWrapper} = useStyles();

  return (
    <div className={formWrapper}>
      <div>{label}</div>
      <div />
      <div>{input}</div>
      <div>{button}</div>
      <div>{inputHelper}</div>
      <div />
    </div>
  );
};
