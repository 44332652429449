import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import styled from 'styled-components';

import {ALLOWED_FILE_TYPE} from '../../../../shared/interfaces/aIChat';

import useFileHandler from './hooks/useFileHandler';
import useFileInput from './hooks/useFileInput';
import useMessages from './hooks/useMessages';
import ChatBody from './chatBody';
import ChatFileMedicalRecordsWindow from './chatFileMedicalRecordsWindow';
import ChatFileModalWindow from './chatFileModalWindow';
import ChatFooter from './chatFooter';
import Header from './chatHeader';
import {ChatHistory} from './chatHistory';

const CIRCULAR_PROGRESS_PROPS = {
  size: 120,
  thickness: 2,
};

const getAllowedImages = (): string =>
  Object.values(ALLOWED_FILE_TYPE)
    .filter(type => type.startsWith('image/'))
    .join(', ');

const scrollBottom = (messageContainerRef: RefObject<HTMLDivElement>) => {
  messageContainerRef.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
  });
};

export const ChatDrawer: FC<{contentWidth: number}> = ({contentWidth}) => {
  const ref = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const {patientUuid} = useParams<{patientUuid: string}>();

  const [isMedicalFilesSelected, setIsMedicalFilesSelected] =
    useState<boolean>(false);
  const [isOpenFileModal, setIsOpenFileModal] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const {
    selectedFile,
    fileContent,
    fileLoading,
    isMedicalFiles,
    errorInFile,
    saveFileInLinkResponse,
    handleMedicalRecordsSelection,
    handleFileSelect,
    handleRemoveFile,
  } = useFileHandler(() => scrollBottom(messageContainerRef));
  const {fileInputRef, handleFileChange, handleGetFile} = useFileInput(
    patientUuid,
    handleFileSelect
  );

  const {chat, setChatFromHistory, isLoadingChat} = useMessages();

  const selectChatFromHistory = useCallback(
    async (conversationId: string) => {
      if (chat.chatId === conversationId) {
        return;
      }
      handleRemoveFile();
      setChatFromHistory(conversationId);
    },
    [chat.chatId, handleRemoveFile, setChatFromHistory]
  );

  useEffect(() => {
    scrollBottom(messageContainerRef);
  }, [chat.messages, errorInFile]);

  useEffect(() => {
    setSelectedItem(chat.chatId);
  }, [chat.chatId]);

  if (isLoadingChat) {
    return (
      <CenteringWrapper data-testid="ai-chat-loader">
        <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
      </CenteringWrapper>
    );
  }

  return (
    <ComponentWrapper
      ref={ref}
      width={contentWidth}
      data-testid="ai-chat-container"
    >
      <Header
        setOpenHistory={setIsHistoryOpen}
        isDisabled={chat?.messages?.length === 1}
        width={contentWidth}
        isHistory={isHistoryOpen}
      />

      {!isLoadingChat && !isHistoryOpen && (
        <>
          <ChatWrapper>
            <ChatBody
              saveFileInLinkResponse={saveFileInLinkResponse}
              hasSpace={
                !!fileContent && !!selectedFile?.type.startsWith('image/')
              }
              messageContainerRef={messageContainerRef}
            />
            <ChatFooter
              saveFileInLinkResponse={saveFileInLinkResponse}
              selectedFile={selectedFile}
              fileLoading={fileLoading}
              fileContent={fileContent}
              isMedicalFiles={isMedicalFiles}
              errorInFile={errorInFile}
              handleRemoveFile={handleRemoveFile}
              handleOpenModal={(): void => setIsOpenFileModal(true)}
              handleGetFile={handleGetFile}
            />
          </ChatWrapper>
          <ChatFileModalWindow
            isOpen={isOpenFileModal}
            handleClose={(): void => setIsOpenFileModal(false)}
            onFileSelect={handleFileSelect}
            setIsMedicalFilesSelected={setIsMedicalFilesSelected}
          />
          <ChatFileMedicalRecordsWindow
            isOpen={isMedicalFilesSelected}
            onFileSelect={handleMedicalRecordsSelection}
            handleClose={() => setIsMedicalFilesSelected(false)}
          />
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            accept={getAllowedImages()}
          />
        </>
      )}
      {isHistoryOpen && (
        <ChatHistory
          setOpenHistory={setIsHistoryOpen}
          selectChatFromHistory={selectChatFromHistory}
          selectedItem={selectedItem}
        />
      )}
    </ComponentWrapper>
  );
};

const ChatWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
`;

const ComponentWrapper = styled.div<{width: number}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${props => `${props.width}px`};
`;

const VisuallyHiddenInput = styled.input`
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
`;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex: 1;
  height: -webkit-fill-available;
  width: inherit;
`;
