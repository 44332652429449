import {PARTY, ROLES} from '../../../shared/constants/roles';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';

interface UserInfo {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  npi: string | null;
  phone_number: string;
  roles: ROLES[];
  status: string;
  type: PARTY;
}

export const fetchIssuerPortalUsersSorted = (
  issuerId: string
): Promise<UserInfo> => fetcher(urls.issuerPortalUsersSorted(issuerId));

export const fetchRelyingPartyPortalUsersSorted = (
  relyingPartyId: string,
  searchValue: string
): Promise<UserInfo> =>
  fetcher(urls.relyingPartyPortalUsersSorted(relyingPartyId, searchValue));
