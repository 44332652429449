import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {RedirectToFirstAvailableRoute} from '../shared/components/redirectToFirstAvailableRoute';

import {AddNewLocationPage} from './locations/pages/addNewLocationPage';
import {ActivityLogListPage} from './activityLog';
import {ForbiddenPage} from './forbidden';
import {HealthPolicyPage} from './healthPolicies';
import {InboxRoutes} from './inbox';
import {IssuerSettingsRoutes} from './issuerSettings';
import {Layout} from './layout';
import {LocationRoutes} from './locations';
import {NotFoundPage} from './notFound';
import {PortalPatientsRoutes} from './portalPatients';
import {PortalUsersRoutes} from './portalUsers';
import {RelyingPartiesRoutes} from './relyingParties';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <RedirectToFirstAvailableRoute />,
  },
  {
    path: '/activity-log',
    component: ActivityLogListPage,
  },
  {
    path: '/health-policies',
    component: HealthPolicyPage,
  },
  {
    path: '/locations',
    component: LocationRoutes,
  },
  {
    path: '/relying-parties',
    component: RelyingPartiesRoutes,
  },
  {
    path: '/add-location',
    exact: true,
    component: AddNewLocationPage,
  },
  {
    path: '/portal-users',
    component: PortalUsersRoutes,
  },
  {
    path: '/portal-patients',
    component: PortalPatientsRoutes,
  },
  {
    path: '/inbox',
    component: InboxRoutes,
  },
  {
    path: '/settings',
    component: IssuerSettingsRoutes,
  },
  {path: '/forbidden', exact: true, component: ForbiddenPage},
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const Main = () => (
  <Layout>
    <Switch>
      {routes.map(route => {
        const ContentComponent = route.component;
        return (
          <Route path={route.path} key={route.path} exact={route.exact}>
            <ContentComponent />
          </Route>
        );
      })}
    </Switch>
  </Layout>
);
