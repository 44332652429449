import {PatientInvitation} from '../../../shared/interfaces/patient';

export const defaultPatient: PatientInvitation = {
  consumer_uuid: '',
  first_name: '',
  last_name: '',
  mobile_phone_number: '',
  ehr_id: 0,
  mrn: '',
  invitation_provider_id: 0,
};
