import {atom} from 'recoil';

import {ProviderInboxEventsType} from '../../inbox/constatnts/types';

type StateType = {
  isOpen: boolean;
  data: ProviderInboxEventsType[] | null;
};

export const outstandingRecordRequestsModalWindowDefaultState: StateType = {
  isOpen: false,
  data: null,
};

export const outstandingRecordRequestsModalWindowState = atom<StateType>({
  key: 'outstandingRecordRequests',
  default: outstandingRecordRequestsModalWindowDefaultState,
});
