import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {REFETCH_INTERVAL} from '../../../shared/constants/refetchInterval';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {getRemappedMedicalRecordNumbers} from '../../../shared/utils/getRemappedMedicalRecordNumbers';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';
import {PatientFormattedType, PatientResponseType} from '../constants/types';
import {fetchMyPatientsList} from '../utils/fetchers';

const formatMyPatients = (initialData: PatientResponseType[]) =>
  initialData.map((user: PatientResponseType) => ({
    name: `${user.first_name} ${user.last_name}`,
    firstName: user.first_name,
    date_of_birth: user.date_of_birth,
    mobile_phone_number: user.mobile_phone_number,
    consumer_uuid: user.consumer_uuid,
    patientSharingRequestData: user.connection,
    medicalRecordNumbers: getRemappedMedicalRecordNumbers(
      user.mrn_ehr_responses
    ),
  }));

const getMyPatients = async (
  relyingPartyId: number | null,
  searchValue: string,
  selectedConnectionStatuses: CONNECTION_STATUSES[]
) => {
  try {
    const myPatients = await fetchMyPatientsList(
      relyingPartyId,
      searchValue,
      selectedConnectionStatuses
    );

    return formatMyPatients(myPatients);
  } catch (e) {
    throw new Error('Something went wrong. Please try refresh the page.');
  }
};

export const useMyPatients = (
  relyingPartyId: number | null,
  searchValue: string,
  selectedConnectionStatuses: CONNECTION_STATUSES[]
): UseQueryResult<PatientFormattedType[]> => {
  const handleError = useErrorHandling();

  return useQuery({
    queryKey: ['myPatients', searchValue, ...selectedConnectionStatuses],
    queryFn: () =>
      getMyPatients(relyingPartyId, searchValue, selectedConnectionStatuses),
    onError: wrapAsyncFunction(async e => {
      await handleError(e);
    }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: REFETCH_INTERVAL,
  });
};
