import {ActionIcon} from '../../../shared/icons/actionIcon';

export const policiesLocationsColumns = [
  {
    key: 'policyName',
    label: 'Policy name',
    minWidth: 180,
    maxWidth: 240,
  },
  {
    key: 'numberOfLocations',
    label: 'Number of locations',
    minWidth: 200,
    maxWidth: 240,
  },
  {
    key: 'assignedLocations',
    label: 'Assigned locations',
    minWidth: 400,
    maxWidth: 500,
  },
  {
    key: 'action',
    label: 'Action',
    minWidth: 120,
    icons: [{component: ActionIcon, title: 'Detail'}],
  },
];
