import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const DownloadRecordsIcon: React.FC<any> = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 22 22">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 .3C1 .3.3 1.1.3 2v11c0 1 .8 1.7 1.7 1.7h1.8a.7.7 0 1 0 0-1.4H2a.3.3 0 0 1-.3-.3V2c0-.2.1-.3.3-.3h5.7l2.5 2.5H18c.2 0 .3.1.3.3V13c0 .2-.1.3-.3.3h-1.8a.7.7 0 1 0 0 1.4H18c1 0 1.7-.8 1.7-1.7V4.5c0-1-.8-1.7-1.7-1.7h-7.2L8.3.3H2ZM10 18Zm3-3-2.3 2.3V9.7a.7.7 0 1 0-1.4 0v7.6L7 15l-1 1 4 4 4-4-1-1Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
