import React, {FC} from 'react';
import styled from 'styled-components';

import AICareGuideProfileIcon from '../../../../../assets/icons/aICareGuideProfileIcon.svg';
import {RetryChatIcon} from '../../../../shared/icons/retryChatIcon';

interface CareGuideRetryMessageProps {
  onRetry: () => void;
}

export const CareGuideRetryMessage: FC<CareGuideRetryMessageProps> = ({
  onRetry,
}) => (
  <>
    <ChatMessageHeader>
      <ChatMessageIcon src={AICareGuideProfileIcon} />
      Care Guide
    </ChatMessageHeader>
    <ChatMessageContent>
      <RetryMessage data-testid="message-retry">
        Something went wrong.
      </RetryMessage>
      <ChatIconButton
        data-testid="message-retry-button"
        onClick={() => onRetry()}
      >
        <RetryChatIcon />
        Retry
      </ChatIconButton>
    </ChatMessageContent>
  </>
);

const ChatIconButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: relative;
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Mobile-UI-Grey-70, #ccc);
  background: var(--background-seconday, #f6f6f6);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const RetryMessage = styled.div`
  color: #d7373f;
  font-feature-settings:
    'clig' off,
    'liga' off;
`;

const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: -4px;
  gap: 16px;
`;

const ChatMessageIcon = styled.img<{
  width?: number;
  height?: number;
}>`
  width: ${props => `${props.width ?? 32}px`};
  height: ${props => `${props.height ?? 32}px`};
`;

const ChatMessageContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 47px;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
`;
