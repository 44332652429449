import React, {FC} from 'react';
import styled from 'styled-components';

import {ReplaceIcon} from './icons/replaceIcon';
import {UploadIcon} from './icons/uploadIcon';

type Props = {
  issuerLogoUrl: string | null | File;
};

export const ButtonText: FC<Props> = ({issuerLogoUrl}) => {
  if (issuerLogoUrl) {
    return (
      <IconWrapper>
        <ReplaceIcon />
        <span>Replace Logo</span>
      </IconWrapper>
    );
  }
  return (
    <IconWrapper>
      <UploadIcon />
      <span>Upload Logo</span>
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  path {
    fill: currentColor;
  }

  & :first-child {
    font-size: 14px;
    margin-right: 12px;
  }
`;
