import {envConfig} from '../../../env';

import {HttpResponseError} from './httpResponseError';
import {
  NetworkError,
  networkErrorMessage,
  NetworkErrorType,
} from './networkError';
import {parseResponseData} from './parseResponseData';
import {ResponseType} from './responseType';
import {getTokenIfSessionNotExpired} from './token';

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const AuthType = {
  Bearer: 'Bearer',
  None: 'None',
};

export const ContentType = {
  HTML: 'text/html',
};

const baseUrl = envConfig.REACT_APP_API_URL;
const TIMEOUT_DURATION = 15000;

const isFullLink = url => /^https?:\/\//i.test(url);
const isFormData = data => data instanceof FormData;
const getContentType = response =>
  response.headers.get('content-type') || 'None';

const getHeaders = async (data, authType) => {
  const headers = {};

  if (!isFormData(data)) {
    headers['Content-Type'] = 'application/json; charset=utf-8';
  }

  const token = await getTokenIfSessionNotExpired();
  if (token && authType === AuthType.Bearer) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const getBody = data => {
  if (data) {
    return isFormData(data) ? data : JSON.stringify(data);
  }
  return null;
};

const throwNetworkError = (request, error) => {
  let type;
  let message = networkErrorMessage.NETWORK_ERROR;
  if (error.name === 'AbortError') {
    type = NetworkErrorType.Timeout;
    message = networkErrorMessage.TIMOUT_ERROR;
  } else if (
    error.name === 'TypeError' &&
    (error.message === 'Failed to fetch' ||
      error.message === 'Network request failed')
  ) {
    type = NetworkErrorType.NoConnection;
  }
  throw new NetworkError(request, type, message);
};

const throwHttpResponseError = (request, response) => {
  let message = response.data.message || response.data.error;
  if (response.status === 401) {
    message = networkErrorMessage.UNAUTHORIZED_ERROR;
  } else if (response.status >= 500) {
    message = networkErrorMessage.SERVER_ERROR;
  }

  throw new HttpResponseError(message, request, response);
};

export const fetcher = async (
  channel,
  method = HttpMethod.GET,
  data = null,
  {
    responseType = ResponseType.JSON,
    authType = AuthType.Bearer,
    responseDataParser = parseResponseData,
    timeout = TIMEOUT_DURATION,
  } = {}
) => {
  const requestUrl = isFullLink(channel) ? channel : `${baseUrl}${channel}`;
  const headers = await getHeaders(data, authType);
  const body = getBody(data);

  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);

  const request = new Request(requestUrl, {
    method,
    headers,
    body,
    signal: controller.signal,
  });

  let response;
  try {
    response = await fetch(request);
    if (
      !responseDataParser &&
      getContentType(response).startsWith(ContentType.HTML)
    ) {
      throwNetworkError(
        request,
        new NetworkError(
          request,
          NetworkErrorType.Unknown,
          networkErrorMessage.UNKNOWN_ERROR
        )
      );
    }
  } catch (error) {
    throwNetworkError(request, error);
  }

  if (getContentType(response) === ResponseType.None) {
    return null;
  }

  const responseData = await responseDataParser(response, responseType);

  if (!response.ok) {
    response.data = responseData;
    responseData.type = responseType;
    throwHttpResponseError(request, response);
  }

  return responseData;
};
