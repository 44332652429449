import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';

export default function useCreateRelyingParty() {
  const currentUser = useRecoilValue(currentUserState);
  const issuerId = currentUser.relyingParty.id;

  const history = useHistory();
  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();

  const [loading, setLoading] = useState(false);

  const createRelyingParty = useCallback(
    async data => {
      setLoading(true);
      if (!issuerId) {
        showMessage(`Issuer id not found`, 'error');
      }

      let result;
      try {
        result = await fetcher(
          urls.issuerRelyingParties(issuerId),
          HttpMethod.POST,
          data
        );
        showMessage('Invitation sent successfully.', 'success');
        history.push('/relying-parties');
      } catch (error) {
        handleError(error);
      }

      return result;
    },
    [handleError, history, issuerId, showMessage]
  );

  return {createRelyingParty, loading};
}
