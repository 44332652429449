import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

import OverflowTip from './overFlowTip';

export const DataDisplayField = ({label, value, disabled, size = 'small'}) => (
  <Container>
    <Label disabled={disabled}>{label}</Label>
    <DataField disabled={disabled} size={size} value={value} tipValue={value} />
  </Container>
);

DataDisplayField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 12px 0;
`;

const Label = styled.p`
  font-size: 12px;
  color: ${prop => (prop.disabled ? colors.grey900 : colors.grey400)};
  margin: 0;
  line-height: 24px;
`;

const DataField = styled(OverflowTip)`
  width: ${props => (props.size === 'small' ? '190px' : '432px')};
  height: 24px;
  background-color: ${colors.grey1000};
  border-radius: 4px;
  padding: 10px 16px;
  color: ${prop => (prop.disabled ? colors.grey1100 : colors.black)};
`;
