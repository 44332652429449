import React from 'react';
import {SvgIcon, SvgIconTypeMap} from '@material-ui/core';

export const SettingsIcon: React.FC<SvgIconTypeMap> = props => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 7.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM8.75 11a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.5 2A1.5 1.5 0 0 0 12 .5h-2A1.5 1.5 0 0 0 8.5 2v.874a8.456 8.456 0 0 0-1.478.613l-.618-.619a1.5 1.5 0 0 0-2.122 0L2.868 4.282a1.5 1.5 0 0 0 0 2.122l.619.618A8.456 8.456 0 0 0 2.874 8.5H2A1.5 1.5 0 0 0 .5 10v2A1.5 1.5 0 0 0 2 13.5h.874c.158.515.364 1.01.613 1.478l-.619.618a1.5 1.5 0 0 0 0 2.122l1.414 1.414a1.5 1.5 0 0 0 2.122 0l.618-.619c.468.249.963.455 1.478.613V20a1.5 1.5 0 0 0 1.5 1.5h2a1.5 1.5 0 0 0 1.5-1.5v-.874a8.455 8.455 0 0 0 1.478-.613l.618.619a1.5 1.5 0 0 0 2.122 0l1.414-1.415a1.5 1.5 0 0 0 0-2.12l-.619-.62c.249-.467.455-.962.613-1.477H20a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 20 8.5h-.874a8.457 8.457 0 0 0-.613-1.478l.619-.618a1.5 1.5 0 0 0 0-2.122l-1.415-1.414a1.5 1.5 0 0 0-2.12 0l-.62.619a8.454 8.454 0 0 0-1.477-.613V2ZM6.808 5.394A6.964 6.964 0 0 1 10 4.07V2h2v2.07a6.965 6.965 0 0 1 3.192 1.324l1.465-1.465 1.414 1.414-1.465 1.465A6.964 6.964 0 0 1 17.93 10H20v2h-2.07a6.965 6.965 0 0 1-1.324 3.192l1.465 1.465-1.414 1.414-1.465-1.465A6.965 6.965 0 0 1 12 17.93V20h-2v-2.07a6.964 6.964 0 0 1-3.192-1.324l-1.465 1.465-1.414-1.414 1.465-1.465A6.965 6.965 0 0 1 4.07 12H2v-2h2.07a6.964 6.964 0 0 1 1.324-3.192L3.929 5.343 5.343 3.93l1.465 1.465Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
