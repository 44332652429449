import React, {ReactNode} from 'react';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {ColumnDef, flexRender, Row, Table} from '@tanstack/react-table';
import classNames from 'classnames';
import styled from 'styled-components';

import {AdditionalTableProps} from '../../interfaces/table';

import {Pagination} from './pagination';

type ExtendedColumn = ColumnDef<unknown> & AdditionalTableProps;

type Props<T> = {
  table: Table<T>;
  placeholderComponent: ReactNode;
  withPagination?: boolean;
  testId?: string;
  isHeaderSticky?: boolean;
  isHighlightRow?: (cell: Row<T>) => boolean;
};

export const StyledTable = <T extends unknown>({
  table,
  testId,
  placeholderComponent,
  withPagination = false,
  isHeaderSticky = false,
  isHighlightRow,
}: Props<T>): JSX.Element => {
  const {
    getHeaderGroups,
    getRowModel,
    getAllColumns,
    getPageCount,
    setPageIndex,
    getState,
  } = table;

  const isShowPagination = !placeholderComponent && withPagination;

  return (
    <>
      <TableContainer>
        <TableWrapper
          stickyHeader={isHeaderSticky}
          data-testid={testId ? `${testId}-table` : null}
        >
          {!placeholderComponent ? (
            <colgroup>
              {getHeaderGroups().map(headerGroup =>
                headerGroup.headers.map(header => {
                  const {width, minWidth, maxWidth} = header.getContext().column
                    .columnDef as ExtendedColumn;

                  return (
                    <col
                      key={header.id}
                      style={{
                        width: width ?? 'auto',
                        minWidth: minWidth ?? '',
                        maxWidth: maxWidth ?? '',
                      }}
                    />
                  );
                })
              )}
            </colgroup>
          ) : null}

          <TableHead>
            {getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(
                  ({id, getContext, isPlaceholder, column}) => (
                    <TableCell
                      key={id}
                      className={classNames({
                        stickyLeft:
                          (getContext().column.columnDef as ExtendedColumn)
                            .isStickyLeft && !placeholderComponent,
                        stickyRight:
                          (getContext().column.columnDef as ExtendedColumn)
                            .isStickyRight && !placeholderComponent,
                      })}
                    >
                      {!isPlaceholder
                        ? flexRender(column.columnDef.header, getContext())
                        : null}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {!placeholderComponent ? (
              getRowModel().rows.map(row => (
                <TableRow
                  hover
                  key={row.id}
                  className={classNames({
                    highlight: isHighlightRow?.(row),
                  })}
                  selected={row.getIsSelected()}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      className={classNames({
                        stickyLeft:
                          (cell.getContext().column.columnDef as ExtendedColumn)
                            .isStickyLeft && !placeholderComponent,
                        stickyRight:
                          (cell.getContext().column.columnDef as ExtendedColumn)
                            .isStickyRight && !placeholderComponent,
                      })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={getAllColumns().length}>
                  {placeholderComponent}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableWrapper>
      </TableContainer>
      {isShowPagination && (
        <PaginationWrapper>
          <Pagination
            count={getPageCount()}
            page={getState().pagination.pageIndex + 1}
            variant="outlined"
            shape="rounded"
            setSelectedPage={setPageIndex}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

const TableWrapper = styled(MUITable)`
  width: 100%;
  height: auto;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
  margin-top: 16px;
`;
