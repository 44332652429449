import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const SendIcon: React.FC<any> = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 16 17" width="20" height="18">
    <path
      fill="#7A7A7A"
      d="M15.333 1.13c.128.227.184.488.163.747l-.014.111-2.091 12.459c-.117.691-.752 1.154-1.42 1.034a1.182 1.182 0 0 1-.197-.054l-.095-.039-4.957-2.312v2.441a.61.61 0 0 1-1.218.071l-.004-.07v-2.956c0-.358.106-.708.316-1.02l.084-.115 5.397-6.338a.614.614 0 0 1 .862-.07c.237.2.283.542.117.795l-.046.062-5.108 5.994 5.06 2.36 2.092-12.46L1.726 9.355l1.98.923a.645.645 0 0 1 .003 1.158.606.606 0 0 1-.439.027l-.067-.026-1.98-.924c-.617-.287-.892-1.04-.615-1.679.09-.207.231-.384.409-.515l.092-.061L13.656.673a1.204 1.204 0 0 1 1.677.458Z"
    />
  </SvgIcon>
);
