import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../../shared/styles/theme';

const resendText = "Didn't receive a code?";

const useStyles = makeStyles(() => ({
  root: {
    '& .button': {
      height: 14,
      margin: 0,
      minWidth: 50,
      backgroundColor: 'transparent',
      padding: 0,
      border: 0,
    },
  },
}));

export const ResendPrompt = ({
  disabled,
  onClick,
  containerId,
  text = resendText,
}) => {
  const classes = useStyles();

  return (
    <ResendButtonContainer className={classes.root}>
      <ResendText>{text}</ResendText>
      <button
        className="button"
        id={containerId}
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        <ResendButtonText disabled={disabled}>Resend</ResendButtonText>
      </button>
    </ResendButtonContainer>
  );
};

ResendPrompt.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  containerId: PropTypes.string,
  text: PropTypes.string,
};

const ResendButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const ResendText = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  color: ${({disabled}) => (disabled ? colors.grey1100 : colors.black)};
`;

const ResendButtonText = styled.p`
  text-decoration: underline;
  font-size: 12px;
  color: ${({disabled}) => (disabled ? colors.grey1100 : colors.primary)};
  cursor: pointer;
  margin: 0;
  line-height: 14px;
`;
