import React, {useEffect, useState} from 'react';
import {useParams, useRouteMatch} from 'react-router-dom';
import {isNil} from 'lodash';
import PropTypes from 'prop-types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {urls} from '../../../shared/utils/urls';
import {currentRelyingPartyState} from '../atoms/relyingPartyAtom';
import {ProfileDisplayField} from '../components/profileDisplayField';
import {ProfileEditForm} from '../components/profileEditForm';
import {TabTable} from '../components/tabTable';
import {usePoliciesAndLocations} from '../hooks/usePoliciesAndLocations';
import {useRelyingPartyPortalUsers} from '../hooks/useRelyingPartyPortalUsers';

export const RelyingPartyProfilePage = ({crumbs, title}) => {
  const {id: relyingPartyId} = useParams();
  const {url} = useRouteMatch();

  const [editMode, setEditMode] = useState(false);

  const {roles} = useRecoilValue(currentUserState);
  const {data: profileData} = useSWR(urls.relyingPartyProfile(relyingPartyId));

  const portalUsers = useRelyingPartyPortalUsers(relyingPartyId);
  const policiesAndLocations = usePoliciesAndLocations(relyingPartyId);

  const setCurrentRelyingParty = useSetRecoilState(currentRelyingPartyState);

  useEffect(() => {
    if (profileData) {
      setCurrentRelyingParty(profileData);
    }
  }, [profileData, setCurrentRelyingParty]);

  if (!profileData) {
    return null;
  }

  return (
    <Container>
      {isNil(profileData) && isNil(policiesAndLocations) ? (
        <NoDataPlaceholder noDataText="No item found." />
      ) : (
        <>
          <BreadCrumbNavigator crumbs={crumbs} title={title} />
          {editMode ? (
            <ProfileEditForm
              editMode={editMode}
              onEditModeChanged={setEditMode}
              data={profileData}
              profileUrl={urls.relyingParty(relyingPartyId)}
            />
          ) : (
            <>
              <ProfileDisplayField
                editMode={editMode}
                roles={roles}
                data={profileData}
                onEditModeChanged={setEditMode}
              />
              {profileData.relying_party_owner.status === 'CONFIRMED' && (
                <TabTable
                  url={url}
                  policiesAndLocations={policiesAndLocations}
                  portalUsers={portalUsers}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

RelyingPartyProfilePage.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};
