import React from 'react';
import {SvgIcon} from '@material-ui/core';

interface AINewChatIconProps {
  isGray: boolean;
}
export const AINewChatIcon: React.FC<AINewChatIconProps> = ({isGray}) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4032 4.04987C19.2803 3.99959 19.1487 3.97429 19.0159 3.97544C18.8831 3.97659 18.7519 4.00418 18.6299 4.05659C18.5079 4.109 18.3975 4.18518 18.3053 4.28069L18.2931 4.29309L10 12.5862V14H11.4138L19.7069 5.70687L19.7193 5.69469C19.8148 5.60244 19.891 5.4921 19.9434 5.3701C19.9958 5.24809 20.0234 5.11687 20.0245 4.98409C20.0257 4.85132 20.0004 4.71964 19.9501 4.59674C19.8998 4.47384 19.8256 4.36219 19.7317 4.2683C19.6378 4.1744 19.5261 4.10015 19.4032 4.04987ZM18.9985 1.97552C19.3968 1.97205 19.7919 2.04796 20.1606 2.1988C20.5293 2.34965 20.8642 2.5724 21.1459 2.85408C21.4276 3.13576 21.6503 3.47072 21.8012 3.83941C21.952 4.20809 22.0279 4.60313 22.0245 5.00147C22.021 5.39981 21.9382 5.79347 21.781 6.15948C21.6251 6.52255 21.3989 6.8512 21.1156 7.12658L12.5351 15.7071C12.3476 15.8946 12.0932 16 11.828 16H9C8.44772 16 8 15.5523 8 15V12.172C8 11.9068 8.10536 11.6524 8.29289 11.4649L16.8734 2.88439C17.1488 2.60108 17.4774 2.37493 17.8405 2.21896C18.2065 2.06174 18.6002 1.97898 18.9985 1.97552ZM3.87868 4.87866C4.44129 4.31605 5.20435 3.99998 6 3.99998H11C11.5523 3.99998 12 4.44769 12 4.99998C12 5.55226 11.5523 5.99998 11 5.99998H6C5.73478 5.99998 5.48043 6.10534 5.29289 6.29287C5.10536 6.48041 5 6.73476 5 6.99998V19C5 19.2652 5.10536 19.5195 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5195 18 19.2652 18 19V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V19C20 19.7956 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7956 3 19V6.99998C3 6.20433 3.31607 5.44127 3.87868 4.87866Z"
      fill={isGray ? '#7A7A7A' : '#323232'}
    />
  </SvgIcon>
);
