import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function ConfirmIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15" width="15" height="15">
      <path d="M5.54998 11.65L0.224976 6.32498L1.27498 5.24998L5.54998 9.52497L14.725 0.349976L15.775 1.42498L5.54998 11.65Z" />
    </SvgIcon>
  );
}
