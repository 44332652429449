import PropTypes from 'prop-types';

export const locationPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  policy: PropTypes.objectOf,
  relying_party_id: PropTypes.number,
  relying_party_name: PropTypes.string,
});

export const policyPropType = PropTypes.shape({
  hand_sanitizer: PropTypes.bool,
  locations: PropTypes.arrayOf,
  mask_required: PropTypes.bool,
  maximum_self_assessment_age: PropTypes.number,
  name: PropTypes.string,
  policy_id: PropTypes.number,
  social_distancing_required: PropTypes.bool,
  temperature_check_required: PropTypes.bool,
  vaccine_required: PropTypes.string,
});
