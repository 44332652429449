import * as yup from 'yup';

import {
  codePattern,
  passwordPattern,
  passwordSymbols,
} from '../../shared/constants/patterns';

export const signUpFormValidationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'length')
    .test('test numbers', 'number', value => /\d/.test(value))
    .test('test lowercaseLetter', 'lowercaseLetter', value =>
      /[a-z]/.test(value)
    )
    .test('test uppercaseLetter', 'uppercaseLetter', value =>
      /[A-Z]/.test(value)
    )
    .test('test symbols', 'symbol', value => passwordSymbols.test(value))
    .required('Please enter more than 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'confirmPassword')
    .required('confirmPassword'),
});

export const signInFormValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .max(200, 'Must be 200 characters or less')
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Please enter more than 8 characters')
    .max(50, 'Must be 50 characters or less')
    .matches(passwordPattern, 'Invalid character')
    .required('Password is required'),
});

export const forgotPasswordFormValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .max(200, 'Must be 200 characters or less')
    .email('Please enter a valid email address')
    .required('Email is required'),
});

export const loginInfoValidationSchema = () =>
  yup.object({
    email: yup
      .string()
      .required('Please enter a valid email address')
      .email('Please enter a valid email address')
      .max(200, 'Invalid email address'),
    password: yup
      .string()
      .required('Please enter your password')
      .max(50, 'Maximum 50 characters')
      .min(8, 'Minimum 8 characters'),
  });

export const authenticationCodeValidationSchema = () =>
  yup.object({
    code: yup
      .string()
      .required('Please enter your verification code')
      .matches(codePattern, 'Please enter a 6-digit verification code'),
  });
