import {isNil} from 'lodash';

import {fetcher} from './fetcher';

export const checkDuplicates = async (value, getUrl) => {
  const trimmedValue = encodeURIComponent(value.trim());
  if (!isNil(trimmedValue)) {
    return fetcher(getUrl(trimmedValue));
  }
  return true;
};
