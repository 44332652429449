import useSWR from 'swr';

import {Fn} from '../../../shared/interfaces/common';
import {
  PolicyTypeId,
  PolicyTypeKeyValue,
} from '../../../shared/interfaces/policy';
import {urls} from '../../../shared/utils/urls';
import {POLICY_TYPES} from '../constants/defaultPolicy';

type RequestPolicyTypesResult = [PolicyTypeKeyValue];

function compare(value1: PolicyTypeKeyValue, value2: PolicyTypeKeyValue) {
  if (value1.key > value2.key) {
    return -1;
  }
  return 1;
}

export const usePolicyTypes: Fn<void, RequestPolicyTypesResult> = () => {
  const {data} = useSWR<RequestPolicyTypesResult>(urls.policyTypes);
  const defaultKey = Object.keys(POLICY_TYPES)[0] as PolicyTypeId;
  return (
    data?.sort(compare) ?? [{key: defaultKey, value: POLICY_TYPES[defaultKey]}]
  );
};
