import React, {FC} from 'react';
import Avatar from '@material-ui/core/Avatar';
import MuiButton, {ButtonProps} from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/styles';

import avatar from '../../../../assets/icons/defaultAvatar.svg';
import {colors} from '../../../shared/styles/theme';

interface CustomizedButtonProps extends ButtonProps {
  isActive: boolean;
  testID?: string;
  patientName: string;
  handleOnClick: () => void;
}

const useStyles = makeStyles({
  root: ({isActive}: {isActive: boolean}) => {
    const backgroundColor = isActive ? colors.darkPrimary : colors.primary;

    return {
      textTransform: 'none',
      fontWeight: 400,
      color: 'white',
      height: '36px',
      lineHeight: '24px',
      fontSize: '14px',
      paddingLeft: '16px',
      paddingRight: '16px',
      '&.MuiButtonBase-root': {
        backgroundColor,
      },
    };
  },
  avatar: {
    width: '19px',
    height: '19px',
    marginLeft: '18px',
  },
});

export const UserInfoButton: FC<CustomizedButtonProps> = ({
  isActive,
  testID,
  patientName,
  handleOnClick,
  ...rest
}) => {
  const classes = useStyles({isActive});
  return (
    <MuiButton
      data-testid={testID}
      className={classes.root}
      onClick={handleOnClick}
      {...rest}
    >
      <>
        <div>{patientName}</div>
        <Avatar alt="avatar" src={avatar} className={classes.avatar} />
      </>
    </MuiButton>
  );
};
