import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const AIChatHistoryIcon = props => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20783 3.70783C3.66104 3.25461 4.27573 3 4.91667 3H19.0833C19.7243 3 20.339 3.25461 20.7922 3.70783C21.2454 4.16104 21.5 4.77573 21.5 5.41667V12.5C21.5 13.0523 21.0523 13.5 20.5 13.5C19.9477 13.5 19.5 13.0523 19.5 12.5V5.41667C19.5 5.30616 19.4561 5.20018 19.378 5.12204C19.2998 5.0439 19.1938 5 19.0833 5H4.91667C4.80616 5 4.70018 5.0439 4.62204 5.12204C4.5439 5.20018 4.5 5.30616 4.5 5.41667V19.5833C4.5 19.6938 4.5439 19.7998 4.62204 19.878C4.70018 19.9561 4.80616 20 4.91667 20H12C12.5523 20 13 20.4477 13 21C13 21.5523 12.5523 22 12 22H4.91667C4.27573 22 3.66104 21.7454 3.20783 21.2922C2.75461 20.839 2.5 20.2243 2.5 19.5833V5.41667C2.5 4.77573 2.75461 4.16104 3.20783 3.70783ZM15.7777 14.4445C14.7651 14.4445 13.9443 15.2653 13.9443 16.2778C13.9443 17.2903 14.7651 18.1111 15.7777 18.1111C16.7902 18.1111 17.611 17.2903 17.611 16.2778C17.611 15.2653 16.7902 14.4445 15.7777 14.4445ZM11.9443 16.2778C11.9443 14.1607 13.6606 12.4445 15.7777 12.4445C17.8948 12.4445 19.611 14.1607 19.611 16.2778C19.611 16.8222 19.4975 17.3401 19.2929 17.8092L21.1248 19.2746C21.556 19.6196 21.6259 20.2489 21.2809 20.6802C20.9359 21.1115 20.3066 21.1814 19.8754 20.8364L18.043 19.3705C17.4084 19.8361 16.6251 20.1111 15.7777 20.1111C13.6606 20.1111 11.9443 18.3949 11.9443 16.2778ZM7.27783 7.72217C6.72555 7.72217 6.27783 8.16988 6.27783 8.72217C6.27783 9.27445 6.72555 9.72217 7.27783 9.72217H16.7223C17.2746 9.72217 17.7223 9.27445 17.7223 8.72217C17.7223 8.16988 17.2746 7.72217 16.7223 7.72217H7.27783ZM7.27783 11.4999C6.72555 11.4999 6.27783 11.9477 6.27783 12.4999C6.27783 13.0522 6.72555 13.4999 7.27783 13.4999H11.0556C11.6079 13.4999 12.0556 13.0522 12.0556 12.4999C12.0556 11.9477 11.6079 11.4999 11.0556 11.4999H7.27783Z"
      fill="black"
    />
  </SvgIcon>
);
