import {v4 as uuid} from 'uuid';

export const ELECTRONIC_RECORD_SECTIONS = {
  allergies: {
    id: uuid(),
    label: 'Allergies',
    path: 'allergies',
  },
  conditions: {
    id: uuid(),
    label: 'Conditions',
    path: 'conditions',
  },
  familyHistory: {
    id: uuid(),
    label: 'Family History',
    path: 'family-history',
  },
  images: {
    id: uuid(),
    label: 'Imaging Study',
    path: 'imaging-study',
  },
  immunizations: {
    id: uuid(),
    label: 'Immunizations',
    path: 'immunizations',
  },
  medications: {
    id: uuid(),
    label: 'Medications',
    path: 'medications',
  },
  procedures: {
    id: uuid(),
    label: 'Procedures',
    path: 'procedures',
  },
  providers: {
    id: uuid(),
    label: 'Providers',
    path: 'providers',
  },
  clinicalNotes: {
    id: uuid(),
    label: 'Clinical Notes',
    path: 'clinical-notes',
  },
  clinicalVitals: {
    id: uuid(),
    label: 'Clinical Vitals',
    path: 'clinical-vitals',
  },
  labResults: {
    id: uuid(),
    label: 'Lab Results',
    path: 'lab-results',
  },
};
