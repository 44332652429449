import React, {FC, ReactNode, useCallback} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

import {DocumentActiveStatusLabel} from '../../main/portalPatients/components/documentActiveStatusLabel';
import {
  OnDownloadDocumentFnType,
  ReturnDocumentType,
} from '../../main/portalPatients/constants/types';
import {DownloadIcon} from '../icons/downloadIcon';
import {colors} from '../styles/theme';

import {Button} from './button';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 700,
    margin: 0,
    lineHeight: '24px',
  },

  modalBodyWrapper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '888px',

    '&:focus': {
      outline: 'none',
    },
  },
}));

type Props = {
  title: string | null;
  onDownloadDocuments: OnDownloadDocumentFnType;
  onClose: VoidFunction;
  children: ReactNode;
  open: boolean;
  container?: HTMLElement | null;
  patientDocumentsInfo: ReturnDocumentType[] | null;
};

export const PreviewDocumentModalWindow: FC<Props> = ({
  title,
  onDownloadDocuments,
  onClose,
  children,
  open,
  container,
  patientDocumentsInfo,
}) => {
  const classes = useStyles();

  const handleDownloadDocuments = useCallback(() => {
    if (patientDocumentsInfo) {
      return onDownloadDocuments(patientDocumentsInfo);
    }
    return null;
  }, [onDownloadDocuments, patientDocumentsInfo]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      container={container ?? document.body}
    >
      <Fade in={open}>
        <ModalWindowWrapper className={classes.modalBodyWrapper}>
          <Header>
            <h2 className={classes.modalTitle}>{title}</h2>
          </Header>
          <SubHeader>
            {patientDocumentsInfo !== null && (
              <DocumentActiveStatusLabel
                patientDocumentInfo={patientDocumentsInfo[0]}
                fontSize="16px"
              />
            )}
          </SubHeader>
          <Body>{children}</Body>
          <Footer>
            <Button
              testID="download-button-preview-modal-window"
              variant="outlined"
              aria-haspopup="true"
              onClick={handleDownloadDocuments}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
            <Button onClick={onClose} testID="close_preview_documents_btn">
              Close
            </Button>
          </Footer>
        </ModalWindowWrapper>
      </Fade>
    </Modal>
  );
};

const ModalWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 24px 35px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const SubHeader = styled.div`
  padding: 8px 35px 16px;
`;

const Body = styled.div`
  border-top: 1px solid ${colors.grey800};
  border-bottom: 1px solid ${colors.grey800};
  padding: 16px 35px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  border-top: 1px solid ${colors.grey800};
  padding: 24px 35px;
  display: flex;
  justify-content: space-between;
`;
