import React, {useCallback, useRef, useState} from 'react';
import {isEmpty} from 'lodash';
import styled from 'styled-components';

import {InputTextField} from '../../shared/components/inputTextField';
import {passwordFilter} from '../utils/passwordFilter';
import {
  confirmPasswordRequirementMap,
  passwordRequirementsMap,
} from '../utils/passwordValidationMessage';
import {
  shouldDisplayConfirmationPopper,
  shouldDisplayPasswordPopper,
} from '../utils/shouldDisplayPopper';

import {PasswordHelper} from './passwordHelper';

type PasswordInfo<T> = {password?: T; confirmPassword?: T};

interface SetPasswordFormProps {
  values: PasswordInfo<string>;
  errors: PasswordInfo<string[]>;
  touched: PasswordInfo<boolean>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  handleChange: (param: string) => (e: React.ChangeEvent) => void;
}

export const SetPassword: React.FC<SetPasswordFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldTouched,
}) => {
  const passwordAnchorRef = useRef(null);
  const passwordConfirmAnchorRef = useRef(null);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);

  const onChange = useCallback(
    (event: React.ChangeEvent) =>
      handleChange('password')(passwordFilter(event)),
    [handleChange]
  );

  const onPasswordFocus = useCallback(() => setPasswordFocus(true), []);
  const onBlurPasswordFocus = useCallback(() => {
    setPasswordFocus(false);
    if (!values.password) {
      setFieldTouched('password', false);
    }
  }, [setFieldTouched, values.password]);

  const onConfirmPasswordFocus = useCallback(
    () => setConfirmPasswordFocus(true),
    []
  );
  const onBlurConfirmPasswordFocus = useCallback(() => {
    setConfirmPasswordFocus(false);
    if (!values.confirmPassword) {
      setFieldTouched('confirmPassword', false);
    }
  }, [setFieldTouched, values.confirmPassword]);

  return (
    <>
      <InputField
        inputRef={passwordAnchorRef}
        autoComplete="off"
        name="password"
        type="password"
        label="Password"
        size="auto"
        placeholder="New password"
        displayError={false}
        testID="passwordInput"
        onFocus={onPasswordFocus}
        onChange={onChange}
        onBlur={onBlurPasswordFocus}
        inputProps={{
          maxLength: 50,
        }}
      />
      <PasswordHelper
        prompt="Your password must contain: "
        requirements={passwordRequirementsMap}
        anchorEl={passwordAnchorRef.current}
        open={shouldDisplayPasswordPopper(
          errors,
          passwordFocus,
          values.password,
          touched.password
        )}
        touched={touched.password}
        errors={errors.password}
        value={values.password!}
      />
      <InputField
        autoComplete="off"
        inputRef={passwordConfirmAnchorRef}
        name="confirmPassword"
        displayError={false}
        type="password"
        label="Confirm password"
        size="auto"
        shouldCheckError={!isEmpty(values.password)}
        placeholder="Confirm password"
        testID="confirmPasswordInput"
        onFocus={onConfirmPasswordFocus}
        onBlur={onBlurConfirmPasswordFocus}
        inputProps={{
          maxLength: 50,
        }}
      />
      <PasswordHelper
        prompt="Confirm your password: "
        requirements={confirmPasswordRequirementMap}
        anchorEl={passwordConfirmAnchorRef.current}
        open={shouldDisplayConfirmationPopper(
          errors,
          values,
          touched,
          passwordFocus,
          confirmPasswordFocus
        )}
        touched={touched.confirmPassword}
        errors={errors.confirmPassword}
        value={values.confirmPassword!}
      />
    </>
  );
};

const InputField = styled(InputTextField)`
  margin-bottom: 10px;
  width: 100%;
`;
