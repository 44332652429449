import React from 'react';
import {Divider, DividerProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {colors} from '../../../../shared/styles/theme';

const useStyles = makeStyles(() => ({
  divider: {
    margin: 0,
    backgroundColor: colors.grey800,
  },
  boldDivider: {
    margin: 0,
    height: '6px',
    backgroundColor: colors.grey70,
  },
}));

type Props = {
  isBoldDivider?: boolean;
} & Omit<DividerProps, 'className'>;

export const StyledDivider: React.FC<Props> = ({isBoldDivider, ...props}) => {
  const classes = useStyles();

  return (
    <Divider
      className={cx(classes.divider, {
        [classes.boldDivider]: !!isBoldDivider,
      })}
      {...props}
    />
  );
};
