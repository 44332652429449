import React, {FC} from 'react';
import styled from 'styled-components';

import smthWentWrongIcon from '../../../assets/icons/smthWentWrongIcon.svg';
import {colors} from '../styles/theme';

import {CustomizedButton} from './customizedButton';

export const SmthWentWrongPlaceholder: FC<{
  labelText: string | JSX.Element;
  onClick: VoidFunction;
}> = ({labelText, onClick}) => (
  <Container>
    <img src={smthWentWrongIcon} alt="smthWentWrongIcon" />
    <LabelWrapper>{labelText}</LabelWrapper>
    <CustomizedButton
      variant="contained"
      color="primary"
      onClick={onClick}
      testID="retry_btn"
    >
      Retry
    </CustomizedButton>
  </Container>
);

const Container = styled.div`
  text-align: center;
`;

const LabelWrapper = styled.p`
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  color: ${colors.grey801};
`;
