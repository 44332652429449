import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {Button} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {Subheader} from '../../../shared/components/subheader';
import {urls} from '../../../shared/utils/urls';

export const SelectRelyingParty = ({crumbs, title}) => {
  const history = useHistory();
  const [relyingParty, setRelyingParty] = useState('');

  const currentUser = useRecoilValue(currentUserState);

  const {data: relyingPartyList} = useSWR(
    urls.issuerConfirmedRelyingParties(currentUser.relyingParty.id)
  );

  const handleConfirm = useCallback(
    () => history.push(`/activity-log/relying-party/${relyingParty}`),
    [history, relyingParty]
  );

  const onChangeSelect = useCallback(
    event => setRelyingParty(event.target.value),
    []
  );

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <Subheader title="Select a relying party" />
      <FormContainer>
        <CustomizedSelect
          value={relyingParty}
          label="Relying party"
          values={relyingPartyList?.map(party => party.id)}
          options={relyingPartyList?.map(party => party.name)}
          onChange={onChangeSelect}
        />
        <Button
          onClick={handleConfirm}
          disabled={!relyingParty || Number.isNaN(relyingParty)}
        >
          Confirm
        </Button>
      </FormContainer>
    </Container>
  );
};

SelectRelyingParty.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const Container = styled.div`
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  padding-left: 40px;
  margin-top: 16px;
  align-items: flex-end;
`;
