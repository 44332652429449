import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

export const useCustomizedButtonStyles = makeStyles((theme: Theme) => ({
  customizedBtn: {
    '&.MuiButton-root': {
      border: `1px solid ${theme.palette.grey[200]}`,
      '&:not(:first-of-type)': {
        marginLeft: 0,
      },
    },
    '&.MuiButton-root:hover': {
      boxShadow: '0px 4px 10px rgba(36, 71, 130, 0.16)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));
