/* eslint-disable indent */
import React, {useCallback, useMemo} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../../shared/components/list';
import {colors} from '../../../../../shared/styles/theme';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ElectronicRecordHorizontalDivider} from '../electronicRecordHorizonralDivider';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {StyledDivider} from '../styledDivider';
import {
  FetchedCareTeam,
  FetchedProvider,
  MedicalPersonEntity,
} from '../types/providerTypes';
import {CommonSectionProps, ElectronicRecordCode} from '../types/types';
import {getCareTeams, getProviders} from '../utils/providersMapper';

const LABELS_COLUMN_WIDTH = '125px';

const useStyles = makeStyles(() => ({
  blockHeader: {
    color: colors.black,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    margin: '24px 0 8px 0',
  },
  blockHeaderDescription: {
    color: colors.grey801,
    fontWeight: 400,
    lineHeight: '24px',
    margin: '0 0 16px 0',
  },
  primaryCareProviderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  primaryCareProviderItemGrid: {
    padding: '24px',
    gridTemplateColumns: '1fr',
  },
  wrapperForBlockWithSomeCodes: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  blockGutter: {
    padding: '24px',
  },
  rolesItemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  rolesContent: {
    gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
  },
  accordionSubTitle: {
    color: colors.grey600,
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '24px',
    margin: 0,
  },
  participantsContent: {
    gridTemplateColumns: '1fr 1px 1fr',
  },
  careTeamItemGrid: {
    padding: '24px',
    gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
  },
  accordionsWrapper: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const getAccordionName = (name?: string | null): string =>
  !name ? 'Name Unavailable' : name;

export const CodesBlock: React.FC<{
  title?: string;
  codes: ElectronicRecordCode[] | null;
  codeBlocksLength: number;
  currentCodeBlockIndex: number;
}> = ({title, codes, codeBlocksLength, currentCodeBlockIndex}) => (
  <>
    <ElectronicRecordHorizontalDivider
      index={currentCodeBlockIndex}
      length={codeBlocksLength}
    />

    <ListWithFixedLabelsColumn labelsColumnWidth={LABELS_COLUMN_WIDTH}>
      {currentCodeBlockIndex === 0 && title ? (
        <ListTitleRow title={title} />
      ) : null}
      <ElectronicRecordCodesList codes={codes} />
    </ListWithFixedLabelsColumn>
  </>
);

export const MedicalPersonEntities: React.FC<{
  testId: string;
  entities: MedicalPersonEntity[];
  specialitiesCodesBlockTitle: string;
  mainCodesBlockTitle: string;
}> = ({entities, specialitiesCodesBlockTitle, mainCodesBlockTitle, testId}) => {
  const {
    primaryCareProviderItemGrid,
    rolesContent,
    participantsContent,
    ...classes
  } = useStyles();
  const listTemplateClasses = useGeneralListStyles();

  return (
    <div className={classes.accordionsWrapper} data-testid={testId}>
      {entities.map(entity => (
        <ElectronicRecordAccordion
          key={entity.id}
          title={getAccordionName(entity?.name)}
          content={
            <div className={classes.primaryCareProviderWrapper}>
              <div
                className={cx(
                  listTemplateClasses.wrapper,
                  primaryCareProviderItemGrid
                )}
              >
                <div>
                  <ElectronicRecordGroupTitle title="Details" />
                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ListItemKey>NPI</ListItemKey>
                    <ListItemValue testId="npis">
                      {entity.identifiers}
                    </ListItemValue>
                    <ListItemKey>Practicing Status</ListItemKey>
                    <ListItemValue testId="practicing_status">
                      {entity.status}
                    </ListItemValue>
                    {entity.telecoms.map(telecom => (
                      <React.Fragment key={telecom.id}>
                        <ListItemKey>{telecom.key}</ListItemKey>
                        <ListItemValue>{telecom.value}</ListItemValue>
                      </React.Fragment>
                    ))}
                    <ListItemKey>Data Last Updated</ListItemKey>
                    <ListItemValue testId="last_updated_at">
                      {entity.last_updated_at}
                    </ListItemValue>
                  </ListWithFixedLabelsColumn>
                </div>
              </div>

              <StyledDivider isBoldDivider />

              <div
                className={classes.blockGutter}
                data-testid={`${testId}_roles_block`}
              >
                <ElectronicRecordGroupTitle title="Roles" />

                <div className={classes.rolesItemsWrapper}>
                  {entity.roles && entity.roles?.length !== 0 ? (
                    entity.roles.map((role, index) => (
                      <>
                        <ElectronicRecordHorizontalDivider
                          index={index}
                          length={entity.roles.length}
                        />

                        <p className={classes.accordionSubTitle}>
                          Role at {role?.organization ?? 'Organization'}
                        </p>

                        <div
                          key={role.id}
                          className={cx(
                            listTemplateClasses.wrapper,
                            rolesContent
                          )}
                        >
                          <ListWithFixedLabelsColumn
                            labelsColumnWidth={LABELS_COLUMN_WIDTH}
                          >
                            <ListTitleRow title="General" />
                            <ListItemKey>Organization</ListItemKey>
                            <ListItemValue testId="role_organization">
                              {role.organization}
                            </ListItemValue>
                            <ListItemKey>Practicing Status</ListItemKey>
                            <ListItemValue testId="role_practicing_status">
                              {role.active}
                            </ListItemValue>
                            <ListItemKey>Data Last Updated</ListItemKey>
                            <ListItemValue testId="role_last_updated">
                              {role.last_updated_at}
                            </ListItemValue>
                          </ListWithFixedLabelsColumn>

                          <StyledDivider orientation="vertical" />

                          <div
                            data-testid={`${testId}_roles_specialities_block`}
                            className={classes.wrapperForBlockWithSomeCodes}
                          >
                            {role?.specialties &&
                            role.specialties.length !== 0 ? (
                              role.specialties.map(({id, codes}, index) => (
                                <CodesBlock
                                  title={specialitiesCodesBlockTitle}
                                  codes={codes}
                                  codeBlocksLength={role.specialties.length}
                                  currentCodeBlockIndex={index}
                                  key={id}
                                />
                              ))
                            ) : (
                              <ListWithNoDataValue />
                            )}
                          </div>

                          <StyledDivider orientation="vertical" />

                          <div
                            data-testid={`${testId}_roles_main_codes_block`}
                            className={classes.wrapperForBlockWithSomeCodes}
                          >
                            {role?.codings && role.codings.length !== 0 ? (
                              role.codings.map(({id, codes}, index) => (
                                <CodesBlock
                                  title={mainCodesBlockTitle}
                                  codes={codes}
                                  codeBlocksLength={role.codings.length}
                                  currentCodeBlockIndex={index}
                                  key={id}
                                />
                              ))
                            ) : (
                              <ListWithNoDataValue />
                            )}
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <ListWithNoDataValue />
                  )}
                </div>
              </div>
            </div>
          }
        />
      ))}
    </div>
  );
};

export const Providers: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const {participantsContent, careTeamItemGrid, ...classes} = useStyles();
  const {wrapper} = useGeneralListStyles();
  const {
    data: fetchedProviders,
    error: providersError,
    isFetching: isFetchingProviders,
    refetch: refetchProviders,
  } = useSectionData<FetchedProvider>(
    urls.getProvidersOfConsolidatedMedicalRecord(consumerUuid)
  );

  const {
    data: fetchedCareTeams,
    error: careTeamsError,
    isFetching: isFetchingCareTeams,
    refetch: refetchCareTeams,
  } = useSectionData<FetchedCareTeam[]>(
    urls.getCareTeamsOfConsolidatedMedicalRecord(consumerUuid)
  );

  const providers = useMemo(
    () => getProviders(fetchedProviders),
    [fetchedProviders]
  );

  const careTeams = useMemo(
    () => getCareTeams(fetchedCareTeams),
    [fetchedCareTeams]
  );

  const refetchSectionData = useCallback(async () => {
    await Promise.all([refetchProviders(), refetchCareTeams()]);
  }, [refetchCareTeams, refetchProviders]);

  const isLoading = isFetchingProviders || isFetchingCareTeams;
  const hasError = !!providersError || !!careTeamsError;
  const hasNoData =
    providers.primary_care_providers?.length === 0 &&
    providers.specialists?.length === 0 &&
    careTeams.length === 0 &&
    !isLoading &&
    !hasError;

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isLoading}
      hasNoData={hasNoData}
      hasError={hasError}
      refetch={refetchSectionData}
      title="Providers"
    >
      <>
        {providers.primary_care_providers.length !== 0 ? (
          <>
            <h3 className={classes.blockHeader}>Primary Care</h3>
            <p className={classes.blockHeaderDescription}>
              Please contact the patient to determine their current primary care
              provider.
            </p>
            <MedicalPersonEntities
              testId="primary_care"
              entities={providers.primary_care_providers}
              specialitiesCodesBlockTitle="Specialties"
              mainCodesBlockTitle="Main Codes"
            />
          </>
        ) : null}

        {providers.specialists.length !== 0 ? (
          <>
            <h3 className={classes.blockHeader}>Specialists</h3>
            <MedicalPersonEntities
              testId="specialists"
              entities={providers.specialists}
              specialitiesCodesBlockTitle="Specialties"
              mainCodesBlockTitle="Codes"
            />
          </>
        ) : null}

        {careTeams.length !== 0 ? (
          <>
            <h3 className={classes.blockHeader}>Care Teams</h3>

            <div
              className={classes.accordionsWrapper}
              data-testid="care_teams_block"
            >
              {careTeams.map(careTeam => (
                <ElectronicRecordAccordion
                  key={careTeam.id}
                  title={getAccordionName(careTeam.name)}
                  content={
                    <Box width="100%">
                      <div className={cx(wrapper, careTeamItemGrid)}>
                        <div>
                          <ElectronicRecordGroupTitle title="Details" />
                          <ListWithFixedLabelsColumn
                            labelsColumnWidth={LABELS_COLUMN_WIDTH}
                          >
                            <ListItemKey>Status</ListItemKey>
                            <ListItemValue testId="status">
                              {careTeam.status}
                            </ListItemValue>
                            <ListItemKey>Start Date</ListItemKey>
                            <ListItemValue testId="start_date">
                              {careTeam.period?.start}
                            </ListItemValue>
                            <ListItemKey>End Date</ListItemKey>
                            <ListItemValue testId="end_date">
                              {careTeam.period?.end}
                            </ListItemValue>
                            <ListItemKey>Organizations</ListItemKey>
                            <ListItemValue testId="organizations">
                              {careTeam.organizations}
                            </ListItemValue>
                            {careTeam.telecoms.map(telecom => (
                              <React.Fragment key={telecom.id}>
                                <ListItemKey>{telecom.key}</ListItemKey>
                                <ListItemValue>{telecom.value}</ListItemValue>
                              </React.Fragment>
                            ))}
                            <ListItemKey>Data Last Updated</ListItemKey>
                            <ListItemValue testId="last_updated_at">
                              {careTeam.last_updated_at}
                            </ListItemValue>
                          </ListWithFixedLabelsColumn>
                        </div>
                        <StyledDivider orientation="vertical" />

                        <div>
                          <ElectronicRecordGroupTitle title="Categories" />
                          <div
                            data-testid="care_teams_categories_block"
                            className={classes.wrapperForBlockWithSomeCodes}
                          >
                            {careTeam?.categories &&
                            careTeam.categories.length !== 0 ? (
                              careTeam.categories.map(({id, codes}, index) => (
                                <CodesBlock
                                  currentCodeBlockIndex={index}
                                  key={id}
                                  codes={codes}
                                  codeBlocksLength={careTeam.categories.length}
                                />
                              ))
                            ) : (
                              <ListWithNoDataValue />
                            )}
                          </div>
                        </div>

                        <StyledDivider orientation="vertical" />

                        <div>
                          <ElectronicRecordGroupTitle title="Reasons" />

                          <div
                            data-testid="care_teams_reasons_block"
                            className={classes.wrapperForBlockWithSomeCodes}
                          >
                            {careTeam?.reasons &&
                            careTeam.reasons.length !== 0 ? (
                              careTeam.reasons.map(({id, codes}, index) => (
                                <CodesBlock
                                  currentCodeBlockIndex={index}
                                  key={id}
                                  codes={codes}
                                  codeBlocksLength={careTeam.reasons.length}
                                />
                              ))
                            ) : (
                              <ListWithNoDataValue />
                            )}
                          </div>
                        </div>
                      </div>

                      <StyledDivider isBoldDivider />

                      <div className={classes.blockGutter}>
                        <ElectronicRecordGroupTitle title="Participants" />

                        <div
                          className={classes.rolesItemsWrapper}
                          data-testid="care_teams_participants_block"
                        >
                          {careTeam.participants &&
                          careTeam.participants.length !== 0 ? (
                            careTeam.participants.map((participant, index) => (
                              <>
                                <ElectronicRecordHorizontalDivider
                                  index={index}
                                  length={careTeam.participants.length}
                                />

                                <div
                                  key={participant.id}
                                  className={cx(wrapper, participantsContent)}
                                >
                                  <ListWithFixedLabelsColumn
                                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                                  >
                                    <ListTitleRow title="General" />
                                    <ListItemKey>Name</ListItemKey>
                                    <ListItemValue testId="name">
                                      {participant.name}
                                    </ListItemValue>
                                  </ListWithFixedLabelsColumn>

                                  <StyledDivider orientation="vertical" />

                                  <div
                                    data-testid="care_teams_participants_roles_block"
                                    className={
                                      classes.wrapperForBlockWithSomeCodes
                                    }
                                  >
                                    {participant?.roles &&
                                    participant.roles.length !== 0 ? (
                                      participant.roles.map(
                                        ({id, codes}, index) => (
                                          <CodesBlock
                                            title="Roles"
                                            currentCodeBlockIndex={index}
                                            key={id}
                                            codes={codes}
                                            codeBlocksLength={
                                              participant.roles.length
                                            }
                                          />
                                        )
                                      )
                                    ) : (
                                      <ListWithNoDataValue />
                                    )}
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <ListWithNoDataValue />
                          )}
                        </div>
                      </div>
                    </Box>
                  }
                />
              ))}
            </div>
          </>
        ) : null}
      </>
    </ElectronicRecordSectionWrapper>
  );
};
