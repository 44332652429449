/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../../shared/components/list';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordDateByType} from '../electronicRecordDateByType';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ElectronicRecordHorizontalDivider} from '../electronicRecordHorizonralDivider';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {StyledDivider} from '../styledDivider';
import {FetchedFamilyHistories} from '../types/familyHistoryTypes';
import {CommonSectionProps} from '../types/types';
import {getFamilyHistories} from '../utils/familyHistoriesMapper';

const LABELS_COLUMN_WIDTH = '125px';

const useStyles = makeStyles(() => ({
  accordionContentWrapper: {
    gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
    padding: '24px',
  },
  codesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const FamilyHistory: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<
    FetchedFamilyHistories[]
  >(urls.getFamilyHistoriesOfConsolidatedMedicalRecord(consumerUuid));

  const familyHistories = useMemo(() => getFamilyHistories(data), [data]);

  const hasNoData = familyHistories.length === 0 && !isFetching && !error;

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Family History"
    >
      <ElectronicRecordAccordionWrapper>
        {familyHistories?.map(
          ({conditions, reason_codes, ...familyHistory}) => (
            <ElectronicRecordAccordion
              key={familyHistory.id}
              title={familyHistory?.relationship ?? 'Name Unavailable'}
              content={
                <div
                  className={cx(
                    listTemplateClasses.wrapper,
                    classes.accordionContentWrapper
                  )}
                >
                  <div>
                    <ElectronicRecordGroupTitle title="Details" />

                    <ListWithFixedLabelsColumn
                      labelsColumnWidth={LABELS_COLUMN_WIDTH}
                    >
                      <ListItemKey>Relationship</ListItemKey>
                      <ListItemValue testId="relationship">
                        {familyHistory.relationship}
                      </ListItemValue>
                      <ListItemKey>Name</ListItemKey>
                      <ListItemValue testId="name">
                        {familyHistory.name}
                      </ListItemValue>
                      <ListItemKey>Sex</ListItemKey>
                      <ListItemValue testId="sex">
                        {familyHistory.sex}
                      </ListItemValue>
                      <ListItemKey>Birth Date</ListItemKey>
                      <ListItemValue testId="birth-date">
                        {familyHistory.birth_date}
                      </ListItemValue>
                      <ListItemKey>Age</ListItemKey>
                      <ListItemValue testId="age">
                        {familyHistory.age}
                      </ListItemValue>
                      <ListItemKey>Recorded Date</ListItemKey>
                      <ListItemValue testId="recorded_date">
                        {familyHistory.recorded_date}
                      </ListItemValue>
                      <ListItemKey>Data Last Updated</ListItemKey>
                      <ListItemValue testId="last_updated_at">
                        {familyHistory.last_updated_at}
                      </ListItemValue>
                      <dt />
                      <dd />
                      <ListTitleRow title="Decased" />
                      <ElectronicRecordDateByType
                        date={familyHistory.deceased}
                      />
                    </ListWithFixedLabelsColumn>
                  </div>

                  <StyledDivider orientation="vertical" />

                  <div data-testid="conditions_container">
                    <ElectronicRecordGroupTitle title="Conditions" />

                    {conditions &&
                    Array.isArray(conditions) &&
                    conditions.length !== 0 ? (
                      <div className={classes.codesWrapper}>
                        {conditions.map(({codes, id}, index) => (
                          <React.Fragment key={id}>
                            <ElectronicRecordHorizontalDivider
                              index={index}
                              length={conditions.length}
                            />

                            <ListWithFixedLabelsColumn
                              labelsColumnWidth={LABELS_COLUMN_WIDTH}
                            >
                              <ElectronicRecordCodesList codes={codes} />
                            </ListWithFixedLabelsColumn>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <ListWithNoDataValue />
                    )}
                  </div>

                  <StyledDivider orientation="vertical" />

                  <div data-testid="reason_codes_container">
                    <ElectronicRecordGroupTitle title="Reason Codes" />

                    {Array.isArray(reason_codes) &&
                    reason_codes.length !== 0 ? (
                      <div className={classes.codesWrapper}>
                        {reason_codes.map(({codes, id}, index) => (
                          <React.Fragment key={id}>
                            <ElectronicRecordHorizontalDivider
                              index={index}
                              length={reason_codes.length}
                            />

                            <ListWithFixedLabelsColumn
                              labelsColumnWidth={LABELS_COLUMN_WIDTH}
                            >
                              <ElectronicRecordCodesList codes={codes} />
                            </ListWithFixedLabelsColumn>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <ListWithNoDataValue />
                    )}
                  </div>
                </div>
              }
            />
          )
        )}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
