export const passwordRequirementsMap = [
  {
    key: 'length',
    description: 'At least 8 characters',
  },
  {
    key: 'uppercaseLetter',
    description: 'Uppercase letters: A-Z',
  },
  {
    key: 'lowercaseLetter',
    description: 'Lowercase letters: a-z',
  },
  {
    key: 'number',
    description: 'Numbers: 0-9',
  },
  {
    key: 'symbol',
    description: 'At least 1 symbol',
  },
];

export const confirmPasswordRequirementMap = [
  {
    key: 'confirmPassword',
    description: 'Passwords must match',
  },
];
