import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
}));

type Props = {
  children: React.ReactNode;
};
export const ElectronicRecordAccordionWrapper: React.FC<Props> = ({
  children,
}) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
