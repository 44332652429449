import React, {FC, useCallback, useRef, useState} from 'react';

import {defaultPatientDocumentsState} from '../constants/defaultPatientDocumentsState';
import {
  CardsTitles,
  ImageInfoType,
  PreviewDocumentModalWindowStateType,
  ReturnDocumentType,
} from '../constants/types';
import {onClickOnEmulatedDownloadLink} from '../utils/emulatedDownloadLink';

import {PatientDocumentDetailsPopup} from './patientDocumentDetailsPopup';
import {PatientDocumentsContent} from './patientDocumentsContent';

type Props = {
  primaryInsuranceId: number | null;
  secondaryInsuranceId: number | null;
  consumerUuid: string | null;
  patientFirstName: string;
  patientLastName: string;
  horizontal?: boolean;
};

export const PatientDocuments: FC<Props> = ({
  primaryInsuranceId,
  secondaryInsuranceId,
  consumerUuid,
  patientFirstName,
  patientLastName,
  horizontal = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{isOpenDialog, title, data}, setDocument] =
    useState<PreviewDocumentModalWindowStateType>(defaultPatientDocumentsState);

  const handleToggleSetDocument = useCallback(
    (documents: ReturnDocumentType[] | null, title: CardsTitles | null) =>
      setDocument({
        title,
        data: documents,
        isOpenDialog: !isOpenDialog,
      }),
    [isOpenDialog]
  );

  const handleCloseModalWindow = useCallback(() => {
    handleToggleSetDocument(data, title);
  }, [data, handleToggleSetDocument, title]);

  const onDownloadPatientDocuments = useCallback(patientDocumentsInfo => {
    if (patientDocumentsInfo) {
      patientDocumentsInfo.map(
        ({fileName, image}: {fileName: string; image: ImageInfoType}): void =>
          onClickOnEmulatedDownloadLink(fileName, image.file)
      );
    }
    return null;
  }, []);

  return (
    <div ref={ref} data-testid="patient_documents">
      <PatientDocumentsContent
        primaryInsuranceId={primaryInsuranceId}
        secondaryInsuranceId={secondaryInsuranceId}
        consumerUuid={consumerUuid}
        patientFirstName={patientFirstName}
        patientLastName={patientLastName}
        handleToggleSetDocument={handleToggleSetDocument}
        onDownloadDocuments={onDownloadPatientDocuments}
        horizontal={horizontal}
      />
      <PatientDocumentDetailsPopup
        title={title}
        open={isOpenDialog}
        container={ref.current}
        onClose={handleCloseModalWindow}
        onDownloadDocuments={onDownloadPatientDocuments}
        patientDocumentsInfo={data}
      />
    </div>
  );
};
