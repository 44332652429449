import React, {FC, useState} from 'react';
import styled from 'styled-components';

import {CustomizedButton} from '../../../shared/components/customizedButton';
import {DocumentVerificationModalWindow} from '../../../shared/components/documentVerificationModalWindow';
import {SmthWentWrongPlaceholderDrawer} from '../../../shared/components/smthWentWrongPlaceholderDrawer';
import {ReturnDocumentType} from '../constants/types';
import {isSmthWentWrong} from '../utils/isSmthWentWrong';

import {DocumentActiveStatusLabel} from './documentActiveStatusLabel';
import {PatientDocumentDetails} from './PatientDocumentDetails';

type Props = {
  patientDocumentsInfo: ReturnDocumentType[];
  refetchDocuments: () => Promise<any>;
  isSecondaryInsurance?: boolean;
};

export const InsuranceVerificationSmallDrawer: FC<Props> = ({
  patientDocumentsInfo,
  refetchDocuments,
  isSecondaryInsurance,
}) => {
  const isError = patientDocumentsInfo && isSmthWentWrong(patientDocumentsInfo);
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(true);
  };
  return (
    <ContentWrapper>
      {!isError ? (
        <>
          <ColWrapper>
            <Title className="title">Insurance Verification</Title>
            <DocumentActiveStatusLabel
              patientDocumentInfo={patientDocumentsInfo[0]}
            />
          </ColWrapper>
          <CustomizedButton
            color="primary"
            style={{width: '134px'}}
            variant="outlined"
            testID={`request-update-btn-${
              isSecondaryInsurance ? 'secondary' : 'primary'
            }`}
            onClick={handleClick}
          >
            Request Update
          </CustomizedButton>
          <DocumentVerificationModalWindow
            title={`${
              isSecondaryInsurance ? 'Secondary' : 'Primary'
            } Insurance Verification`}
            documentName="Insurance"
            open={showPopup}
            onClose={() => setShowPopup(false)}
            patientDocumentsInfo={patientDocumentsInfo}
            refetchDocuments={refetchDocuments}
          >
            {patientDocumentsInfo ? (
              <PatientDocumentDetails
                patientDocumentInfo={patientDocumentsInfo[0].data}
              />
            ) : null}
          </DocumentVerificationModalWindow>
        </>
      ) : (
        <SmthWentWrongPlaceholderDrawer
          labelText="Something went wrong requesting your information. Please retry."
          onClick={refetchDocuments}
        />
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const Title = styled.p`
  max-width: 190px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
