import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const UploadRecordsIcon: React.FC<any> = props => (
  <SvgIcon {...props} fill="none" viewBox="0 0 22 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00078 4.2998C3.0619 4.2998 2.30078 5.06092 2.30078 5.9998V16.9998C2.30078 17.9387 3.0619 18.6998 4.00078 18.6998H5.80098C6.18758 18.6998 6.50098 18.3864 6.50098 17.9998C6.50098 17.6132 6.18758 17.2998 5.80098 17.2998H4.00078C3.8351 17.2998 3.70078 17.1655 3.70078 16.9998V5.9998C3.70078 5.83412 3.8351 5.6998 4.00078 5.6998H9.71083L12.2108 8.1998H20.0008C20.1665 8.1998 20.3008 8.33412 20.3008 8.4998V16.9998C20.3008 17.1655 20.1665 17.2998 20.0008 17.2998H18.201C17.8144 17.2998 17.501 17.6132 17.501 17.9998C17.501 18.3864 17.8144 18.6998 18.201 18.6998H20.0008C20.9397 18.6998 21.7008 17.9387 21.7008 16.9998V8.4998C21.7008 7.56092 20.9397 6.7998 20.0008 6.7998H12.7907L10.2907 4.2998H4.00078ZM12.001 12.9797L11.9807 13H12.0213L12.001 12.9797ZM15.011 15.9897L12.7008 13.6795V21.3C12.7008 21.6866 12.3874 22 12.0008 22C11.6142 22 11.3008 21.6866 11.3008 21.3V13.6799L8.99093 15.9897L8.00098 14.9998L12.001 10.9998L16.001 14.9998L15.011 15.9897Z"
      fill="currentColor"
    />
  </SvgIcon>
);
