import React from 'react';
import styled from 'styled-components';

import {StatusText} from '../../../shared/components/statusText';
import {colors} from '../../../shared/styles/theme';

export const ContactInfoTitle = () => (
  <StatusContainer>
    <Title>Contact Information</Title>
    <StatusText color={colors.green}>Confirmed</StatusText>
  </StatusContainer>
);

const StatusContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-wrap: nowrap;
`;

const Title = styled.p`
  font-weight: 700;
  margin-right: 12px;
`;
