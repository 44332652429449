import React, {useCallback, useMemo, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR, {mutate} from 'swr';
import * as yup from 'yup';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, CancelButton} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {InputTextField} from '../../../shared/components/inputTextField';
import {Subheader} from '../../../shared/components/subheader';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {checkDuplicates} from '../../../shared/utils/checkDuplicates';
import {fetcher} from '../../../shared/utils/fetcher';
import {checkEditFormButtonDisability} from '../../../shared/utils/formikUtils';
import trimValues from '../../../shared/utils/trimValues';
import {urls} from '../../../shared/utils/urls';
import {usePolicyTypes} from '../../healthPolicies/hooks/usePolicyTypes';
import {useEhrs} from '../../relyingParties/hooks/useEhrs';

const validationSchema = yup.object({
  locationName: yup
    .string()
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('Location name is required'),
});

const initialEhrValue = {
  id: 0,
  name: 'No EHR for this location',
  link: '',
};

const SELECT_PLACEHOLDER_OPTION = {id: 'no-key', name: 'Select a policy'};

export const LocationEditForm = ({location, cancelEdit}) => {
  const currentUser = useRecoilValue(currentUserState);
  const fetchedEhrs = useEhrs(currentUser.relyingParty.id);
  const policyTypes = usePolicyTypes();

  const [selectedPolicyType, setSelectedPolicyType] = useState(
    location?.policy?.policy_type_id
  );

  const ehrs = useMemo(() => [initialEhrValue, ...fetchedEhrs], [fetchedEhrs]);

  const ehrNames = ehrs.map(ehr => ehr.name);
  const ehrIds = ehrs.map(ehr => ehr.id);

  const {data: fetchedPolicies} = useSWR(
    urls.relyingPartyPoliciesByPolicyType(
      currentUser.relyingParty.id,
      selectedPolicyType
    )
  );

  const policyData = [SELECT_PLACEHOLDER_OPTION, ...(fetchedPolicies || [])];

  const [currentPolicyAssignment, setCurrentPolicyAssignment] = useState(
    location?.policy?.id
  );

  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling('Unable to save changes.');

  const handleSave = useCallback(
    async (values, {setFieldError}) => {
      const {locationName, locationEHR, policyAssignment} = values;
      const result = await checkDuplicates(
        locationName,
        urls.checkLocationName
      );
      if (trimValues(locationName) !== location.name && result) {
        setFieldError('locationName', 'This name has already been registered.');
      } else {
        try {
          await fetcher(urls.location(location.id), 'PATCH', {
            name: locationName,
            policy_id: policyAssignment,
            ehr_id: locationEHR === 0 ? null : locationEHR,
            status: 'Public',
          });

          await mutate(urls.location(location.id));
          showMessage('Changes saved successfully.', 'success');
          cancelEdit();
        } catch (error) {
          handleError(error);
        }
      }
    },
    [cancelEdit, handleError, location, showMessage]
  );

  const handlePolicyTypeChange = useCallback(
    policyType => {
      setCurrentPolicyAssignment('no-key');
      setSelectedPolicyType(policyType);
    },
    [setCurrentPolicyAssignment, setSelectedPolicyType]
  );

  const handleSelectedPolicyChange = useCallback(
    policyId => {
      setCurrentPolicyAssignment(policyId);
    },
    [setCurrentPolicyAssignment]
  );

  if (!fetchedPolicies && currentPolicyAssignment !== 'no-key') {
    return null;
  }

  return (
    <Formik
      initialValues={{
        locationName: location.name,
        locationEHR: location.ehr_id ? location.ehr_id : 0,
        policyAssignment: location.policy.id,
        status: location.status,
      }}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form>
          <Subheader title={`${location.relying_party_name} ${location.name}`}>
            <Button
              testID="saveButton"
              type="submit"
              position="section"
              disabled={
                currentPolicyAssignment === 'no-key' ||
                checkEditFormButtonDisability(formikProps)
              }
            >
              Save
            </Button>
            <CancelButton position="section" onClick={cancelEdit} />
          </Subheader>

          <ContentContainer>
            <Subtitle>Details</Subtitle>
            <InputField>
              <LocationNameInput
                name="locationName"
                label="Location name"
                size="!small"
                prefix={location.relying_party_name}
                prefixMaxWidth={150}
                testID="inputField"
              />
              <Field
                as={CustomizedSelect}
                size="large"
                name="locationEHR"
                label="Location EHR"
                options={ehrNames}
                values={ehrIds}
              />
            </InputField>
            <EditPolicyContainer>
              <CustomizedSelect
                label="Policy type"
                options={policyTypes?.map(({value}) => value)}
                values={policyTypes?.map(({key}) => key)}
                value={selectedPolicyType}
                defaultValue=""
                onChange={e => handlePolicyTypeChange(e.target.value)}
              />
              <Field
                as={CustomizedSelect}
                name="policyAssignment"
                label="Policy assignment"
                options={policyData?.map(policy => policy.name)}
                values={policyData?.map(policy => policy.id)}
                onChange={e => {
                  formikProps.handleChange(e);
                  handleSelectedPolicyChange(e.target.value);
                }}
                value={currentPolicyAssignment}
              />
            </EditPolicyContainer>
          </ContentContainer>
        </Form>
      )}
    </Formik>
  );
};

LocationEditForm.propTypes = {
  cancelEdit: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.any),
};

const Subtitle = styled.h6`
  padding-bottom: 4px;
  margin: 0;
  line-height: 24px;
`;

const ContentContainer = styled.div`
  padding: 24px 40px;
`;

const LocationNameInput = styled(InputTextField)`
  margin-right: -1px;
`;

const InputField = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EditPolicyContainer = styled.div`
  margin-top: 28px;
`;
