import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function PenIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15" width="15" height="15">
      <path
        className="a"
        d="M13.488,1.539,12.463.513a1.75,1.75,0,0,0-2.475,0L.352,10.15,0,13.272A.656.656,0,0,0,.729,14l3.122-.347,9.636-9.636a1.75,1.75,0,0,0,0-2.475Zm-2.6,3.84L4.758,11.506v-.95H3.446V9.243H2.5L8.622,3.116ZM3.45,12.814l-1.613.179-.829-.829.179-1.613.543-.543h.95v1.313H3.993v.95Zm9.419-9.419L11.5,4.76,9.241,2.5l1.366-1.366a.875.875,0,0,1,1.237,0l1.025,1.025a.875.875,0,0,1,0,1.237Z"
        transform="translate(-0.001 -0.001)"
      />
    </SvgIcon>
  );
}
