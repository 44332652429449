export default function trimValues(inputValues) {
  if (inputValues instanceof Object) {
    const obj = inputValues;
    Object.keys(obj).forEach(key => {
      obj[key] = String(obj[key]).trim();
    });
    return obj;
  }

  return inputValues;
}
