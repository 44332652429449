import React, {useCallback, useState} from 'react';
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {isEmpty} from 'lodash';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {InfoTooltip} from '../../../shared/components/infoTooltip';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import OverflowTip from '../../../shared/components/overFlowTip';
import {TooltipContent} from '../../../shared/components/toolTipContent';
import {
  MergedPolicyType,
  PolicyTypeId,
} from '../../../shared/interfaces/policy';
import {colors} from '../../../shared/styles/theme';
import {urls} from '../../../shared/utils/urls';
import {AddPolicyForm} from '../../healthPolicies/components/addPolicyForm';
import {SelectPolicyTypeForm} from '../../healthPolicies/components/selectPolicyTypeForm';
import {POLICY_TYPES} from '../../healthPolicies/constants/defaultPolicy';
import {usePolicyTypes} from '../../healthPolicies/hooks/usePolicyTypes';
import {
  policyDataFormatter,
  policyTitleMap,
} from '../../healthPolicies/utils/policyFormatter';
import {MainHeader} from '../../layout/components/mainHeader';
import {NEW_POLICY_STEPS} from '../constants/constants';

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    border: `1px solid ${colors.grey50}`,
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
      backgroundColor: colors.grey700,
    },
  },
  policyName: {
    fontWeight: 'bold',
  },
  backButton: {
    marginRight: '24px',
  },
});

const policyTypeTips = [
  'There are two types of policies - a registration policy and an entry policy.',
  'Registration policies set requirements for collecting information from a patient for registration.',
  'Entry policies set requirements for entering a specific location.',
];

export type CachedPoliciesType = {
  toAdd?: MergedPolicyType;
  selected?: MergedPolicyType;
};

enum PolicyStep {
  CHOOSING_POLICY_TYPE,
  FILLING_POLICY_RULES,
}

interface AttachHealthPolicyProps {
  onBack: () => void;
  onNext: () => void;
  addingPolicy: boolean;
  cachedPolicies: CachedPoliciesType;
  setAddingPolicy: (isAdding: boolean) => void;
  policyStep: PolicyStep;
  setPolicyStep: (step: PolicyStep) => void;
  setCachedPolicies: (cachedPolicies: CachedPoliciesType) => void;
}

export const AttachHealthPolicy: React.FC<AttachHealthPolicyProps> = ({
  onBack,
  onNext,
  addingPolicy,
  cachedPolicies,
  setAddingPolicy,
  policyStep,
  setPolicyStep,
  setCachedPolicies,
}) => {
  const classes = useStyles();

  const policyTypes = usePolicyTypes();

  const currentUser = useRecoilValue(currentUserState) as any;

  const [selectedPolicy, setSelectedPolicy] = useState(
    cachedPolicies?.selected
  );

  const defaultKey = policyTypes[0].key;
  const [policyKey, setPolicyKey] = useState(defaultKey);

  const {data: policies, isValidating} = useSWR<MergedPolicyType[]>(
    urls.relyingPartyPoliciesByPolicyType(
      currentUser.relyingParty.id,
      policyKey
    )
  );

  const mapKey = POLICY_TYPES[policyKey];
  const titleMap = policyTitleMap[mapKey];

  const policyToAdd = cachedPolicies?.toAdd;

  const backToPreviousStep = useCallback(() => {
    setPolicyStep(policyStep - 1);
  }, [setPolicyStep, policyStep]);

  const handleBack = useCallback(() => {
    setCachedPolicies({});
    if (policyStep > 0) {
      return backToPreviousStep();
    }
    return onBack();
  }, [onBack, setCachedPolicies, backToPreviousStep, policyStep]);

  const handleAddPolicy = useCallback(
    policy => {
      setCachedPolicies({
        ...cachedPolicies,
        toAdd: policy,
      });
      setPolicyKey(policy.policy_type_id);

      if (policyStep === Object.keys(NEW_POLICY_STEPS).length - 1) {
        return onNext();
      }

      setPolicyStep(policyStep + 1);
    },
    [
      cachedPolicies,
      onNext,
      setCachedPolicies,
      setPolicyStep,
      setPolicyKey,
      policyStep,
    ]
  );

  const handleSelectPolicy = useCallback(
    policy => {
      setSelectedPolicy(policy);
      setCachedPolicies({...cachedPolicies, selected: policy});
    },
    [cachedPolicies, setCachedPolicies]
  );

  const onClick = useCallback(() => setAddingPolicy(true), [setAddingPolicy]);
  const onChangeSelect = useCallback(
    e => setPolicyKey(e?.target?.value as PolicyTypeId),
    []
  );
  const handleAddPolicyCancel = useCallback(() => {
    setCachedPolicies({...cachedPolicies, toAdd: undefined});
    setAddingPolicy(false);
  }, [setAddingPolicy, setCachedPolicies, cachedPolicies]);

  if (addingPolicy && policyStep === NEW_POLICY_STEPS.CHOOSING_POLICY_TYPE) {
    return (
      <SelectPolicyTypeForm
        onNext={handleAddPolicy}
        onBack={handleAddPolicyCancel}
        cancelButtonText="Back to existing policy"
        initialValues={
          policyToAdd && {
            policyKey: policyToAdd?.policy_type_id,
            policyName: cachedPolicies.toAdd?.name,
          }
        }
      />
    );
  }

  if (addingPolicy && policyStep === NEW_POLICY_STEPS.FILLING_POLICY_RULES) {
    return (
      <AddPolicyForm
        policy={cachedPolicies.toAdd}
        onNext={handleAddPolicy}
        onBack={backToPreviousStep}
      />
    );
  }

  return (
    <PolicyContainer>
      <MainHeader
        title="Assign or Create Policy"
        description="Select a policy type, then select an existing policy from the list below or create a new policy."
      />

      <Subtitle>Select policy type</Subtitle>
      <SelectContainer>
        <CustomizedSelect
          value={policyKey}
          label="Policy type"
          options={policyTypes.map(({value}) => value)}
          values={policyTypes.map(({key}) => key)}
          onChange={onChangeSelect}
        />
        <InfoTipContainer>
          <InfoTooltip description={<TooltipContent tips={policyTypeTips} />} />
        </InfoTipContainer>
      </SelectContainer>

      {policies && isEmpty(policies) && (
        <NoDataPlaceholder noDataText="No policy has been created. Set up a new policy to start your management." />
      )}

      <div className={classes.table}>
        {isValidating && <LoadingBar loading />}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Policy</TableCell>
              {Object.values(titleMap).map(value => (
                <TableCell align="left" key={value}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {policies?.map(policy => (
              <TableRow key={policy.id}>
                <TableCell>
                  <Radio
                    color="primary"
                    onChange={() => handleSelectPolicy(policy)}
                    inputProps={{'data-testid': 'radio-button'} as any}
                    checked={policy.id === selectedPolicy?.id}
                  />
                </TableCell>

                <TableCell className={classes.policyName}>
                  <OverflowTip
                    value={policy.name}
                    tipValue={policy.name}
                    maxWidth={150}
                  />
                </TableCell>

                {Object.keys(titleMap).map(policyTitle => (
                  <TableCell align="left" key={policyTitle}>
                    {policyDataFormatter((policy as any)[policyTitle])}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <LinkContainer>
        <PolicyLink onClick={onClick}>Or create a new policy</PolicyLink>
      </LinkContainer>

      <ButtonContainer>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button disabled={!selectedPolicy} onClick={onNext}>
          Next
        </Button>
      </ButtonContainer>
    </PolicyContainer>
  );
};

const PolicyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LinkContainer = styled.div`
  margin-top: 16px;
`;

const PolicyLink = styled.span`
  color: ${colors.lightPrimary};
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Subtitle = styled.h6`
  margin-bottom: 20px;
  margin-top: 10px;
`;

const SelectContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const InfoTipContainer = styled.div`
  margin-top: 22px;
  margin-left: -10px;
`;
