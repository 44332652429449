import {useState} from 'react';

import {NPI_STATES} from '../constants/npi';

const NPI_DATA_DEFAULT_VALUE = null;
const NPI_ERROR_DEFAULT_VALUE = null;
const NPI_STATUS_DEFAULT_VALUE = NPI_STATES.DEFAULT;

const getErrorMessage = errorStatus => {
  if (errorStatus === 404) {
    return NPI_STATES.NOT_FOUND;
  }

  return NPI_STATES.SOMETHING_WENT_WRONG;
};

export default function useSearchNPI() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(NPI_DATA_DEFAULT_VALUE);
  const [error, setError] = useState(NPI_ERROR_DEFAULT_VALUE);
  const [npiStatus, setNpiStatus] = useState(NPI_STATUS_DEFAULT_VALUE);

  const onSearchCallback = (callback, npiData) => {
    if (callback) {
      callback(npiData);
    }
  };

  const onSearchNPI = (npiDataFetcher, callback) => {
    setIsLoading(true);

    npiDataFetcher
      .then(response => {
        setData(response);
        setError(NPI_ERROR_DEFAULT_VALUE);
        setNpiStatus(response.status);

        onSearchCallback(callback, response);
      })
      .catch(e => {
        const response = {
          status: getErrorMessage(e.response?.status),
        };

        setData(NPI_DATA_DEFAULT_VALUE);
        setError(response);
        setNpiStatus(response.status);

        onSearchCallback(callback, {});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClearDataNPI = () => {
    setData(NPI_DATA_DEFAULT_VALUE);
    setError(NPI_ERROR_DEFAULT_VALUE);
    setNpiStatus(NPI_STATUS_DEFAULT_VALUE);
  };

  const setNpiEditedStatus = npi => {
    if (npi && data?.npi && npi !== data.npi) {
      setNpiStatus(NPI_STATES.EDITED);
    } else {
      setNpiStatus(data?.status || error?.status || NPI_STATUS_DEFAULT_VALUE);
    }
  };

  return {
    data,
    isLoading,
    onSearchNPI,
    onClearDataNPI,
    npiStatus,
    setNpiEditedStatus,
  };
}
