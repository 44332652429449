import {PORTAL_PATIENTS_ROUTES} from '../../main/portalPatients/constants/routes';

export enum ROLES {
  ISSUER_OWNER = 'issuer-owner',
  ISSUER_ADMIN = 'issuer-admin',
  ISSUER_SUPPORT_AGENT = 'issuer-support-agent',
  RELYING_PARTY_OWNER = 'relying-party-owner',
  RELYING_PARTY_ADMIN = 'relying-party-admin',
  RELYING_PARTY_RECORDS_REQUESTER = 'medical-records-requester',
  RELYING_PARTY_RECORDS_VIEWER = 'medical-records-viewer',
  RELYING_PARTY_ACTIVITY_LOG_VIEWER = 'relying-party-activity-log-viewer',
  SCREENER = 'screener', // Isn't available role now
}

export const ROLES_LABEL = {
  ISSUER_OWNER: 'Owner',
  ISSUER_ADMIN: 'Admin',
  ISSUER_SUPPORT_AGENT: 'Support agent',
  RELYING_PARTY_OWNER: 'Owner',
  RELYING_PARTY_ADMIN: 'Admin',
  RELYING_PARTY_RECORDS_REQUESTER: 'Records requester',
  RELYING_PARTY_RECORDS_VIEWER: 'Records viewer',
  RELYING_PARTY_ACTIVITY_LOG_VIEWER: 'Activity log viewer',
  SCREENER: 'Manager',
};

export const ROLES_DESCRIPTIONS = {
  [ROLES.ISSUER_OWNER]:
    'Owner: Initial invitee for the Issuer Admin Portal. Same permissions as Admins except Admins are not able to edit their own roles (note, this description is for your purposes, not for adding as a description in the portal).',
  [ROLES.ISSUER_ADMIN]:
    'Admin: Admins are able to invite new users and Relying Parties and can view/edit data available in the Admin Portal.',
  [ROLES.ISSUER_SUPPORT_AGENT]:
    'Support Agent: Support Agents are only able to view data available in the Admin Portal.',
  [ROLES.RELYING_PARTY_OWNER]:
    'Owner: Initial invitee for the Relying Party Admin Portal. Same permissions as Admins except Admins are not able to edit their own roles (note, this description is for your purposes, not for adding as a description in the portal).',
  [ROLES.RELYING_PARTY_ADMIN]:
    'Admin: The user with this role is able to  invite new Portal users, request a connection with the patient, and access their medical records.',
  [ROLES.RELYING_PARTY_RECORDS_REQUESTER]:
    'Records requester: The user with this role is able to request a connection with the patient.',
  [ROLES.RELYING_PARTY_RECORDS_VIEWER]:
    'Records viewer: The user with this role is able to view the patient’s medical records and IDs.',
  [ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER]:
    'Activity log viewer: The user with this role is able to view the Activity Log page.',
  [ROLES.SCREENER]:
    'Manager: Managers are limited to conducting transactions with patients; they are not able to view data in the Admin Portal.',
};

export const rolesMap = {
  [ROLES.ISSUER_OWNER]: ROLES_LABEL.ISSUER_OWNER,
  [ROLES.ISSUER_ADMIN]: ROLES_LABEL.ISSUER_ADMIN,
  [ROLES.ISSUER_SUPPORT_AGENT]: ROLES_LABEL.ISSUER_SUPPORT_AGENT,
  [ROLES.RELYING_PARTY_OWNER]: ROLES_LABEL.RELYING_PARTY_OWNER,
  [ROLES.RELYING_PARTY_ADMIN]: ROLES_LABEL.RELYING_PARTY_ADMIN,
  [ROLES.RELYING_PARTY_RECORDS_REQUESTER]:
    ROLES_LABEL.RELYING_PARTY_RECORDS_REQUESTER,
  [ROLES.RELYING_PARTY_RECORDS_VIEWER]:
    ROLES_LABEL.RELYING_PARTY_RECORDS_VIEWER,
  [ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER]:
    ROLES_LABEL.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
  [ROLES.SCREENER]: ROLES_LABEL.SCREENER,
};

export enum PARTY {
  ISSUER = 'issuer',
  RELYING_PARTY = 'relying-party',
}

export const routesAvailableForDefaultRedirect = [
  {
    path: '/inbox/to-do',
    roles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ],
  },
  {
    path: PORTAL_PATIENTS_ROUTES.CONNECTIONS,
    roles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ],
  },
  {
    path: '/activity-log',
    roles: [
      ROLES.ISSUER_OWNER,
      ROLES.ISSUER_ADMIN,
      ROLES.ISSUER_SUPPORT_AGENT,
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
      ROLES.SCREENER,
    ],
  },
  {
    path: '/relying-parties',
    roles: [ROLES.ISSUER_OWNER, ROLES.ISSUER_ADMIN, ROLES.ISSUER_SUPPORT_AGENT],
  },
  {
    path: '/locations',
    roles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ],
  },
  {
    path: '/health-policies',
    roles: [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ],
  },
  {
    path: '/portal-users',
    roles: [
      ROLES.ISSUER_OWNER,
      ROLES.ISSUER_ADMIN,
      ROLES.ISSUER_SUPPORT_AGENT,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_OWNER,
    ],
  },
];
