import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

import {NoDataDash} from '../../../../shared/components/noDataDash';
import {colors} from '../../../../shared/styles/theme';

type Props = {
  consumerUuid: string;
  isPatientConnected: boolean;
  isReceivingMedicalRecords: boolean;
  isSharingMedicalRecords: boolean;
  isUpdateRequested: boolean;
};

export const MedicalRecordsStatus = ({
  consumerUuid,
  isPatientConnected,
  isReceivingMedicalRecords,
  isSharingMedicalRecords,
  isUpdateRequested,
}: Props): JSX.Element => {
  const isSharingRecord = isReceivingMedicalRecords || isSharingMedicalRecords;
  const isNotSharingRecord =
    (isReceivingMedicalRecords && !isSharingMedicalRecords) ||
    (!isReceivingMedicalRecords && !isSharingMedicalRecords);

  const getStatusMessage = (): JSX.Element | null => {
    if (isUpdateRequested) {
      return (
        <Box data-testid={`pending-status-${consumerUuid}`} color={colors.blue}>
          Pending
        </Box>
      );
    }
    if (isNotSharingRecord) {
      return (
        <Box
          data-testid={`not-sharing-record-status-${consumerUuid}`}
          color={colors.red}
        >
          Not Sharing Record
        </Box>
      );
    }
    if (isSharingRecord) {
      return (
        <Box data-testid={`sharing-record-status-${consumerUuid}`}>
          Sharing Record
        </Box>
      );
    }
    return null;
  };

  return isPatientConnected ? (
    <Wrapper>
      <IconsWrapper
        data-testid={`medical-records-status-wrapper-${consumerUuid}`}
      >
        {getStatusMessage()}
      </IconsWrapper>
    </Wrapper>
  ) : (
    <NoDataDash
      testId={`medical-records-status-not-connected-${consumerUuid}`}
    />
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
`;

const IconsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;
