import React, {FC} from 'react';
import {Box} from '@material-ui/core';

import {colors} from '../../styles/theme';

type Props = {
  searchError?: string;
};

export const PlaceholderComponent: FC<Props> = ({
  searchError = 'No results found. Please try again.',
}) => (
  <Box component="div" fontStyle="italic" textAlign="center" color={colors.red}>
    {searchError}
  </Box>
);
