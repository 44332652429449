import {PatientInvitationStatusLabelAndColor} from '../../../shared/interfaces/patient';
import {colors} from '../../../shared/styles/theme';

export const INVITED_PATIENTS_TABLE_STATUSES: PatientInvitationStatusLabelAndColor =
  {
    PENDING: {
      label: 'INVITED',
      statusText: 'Invited',
      color: colors.statusBlue,
    },
    CONFIRMED: {
      label: 'CONFIRMED',
      statusText: 'Confirmed',
      color: colors.statusGreen,
    },
    MISMATCH: {label: 'MISMATCH', statusText: 'Mismatch', color: colors.red},
    EXPIRED: {label: 'EXPIRED', statusText: 'Expired', color: colors.red},
    OPTED_OUT: {label: 'OPTED OUT', statusText: 'Opted Out', color: colors.red},
  };
