import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';

export const fetchUploadIssuerLogo = (
  formData: FormData
): Promise<{path: string}> =>
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  fetcher(urls.uploadIssuerLogo, HttpMethod.POST, formData);

export const fetchGetIssuerLogo = (): Promise<{image: File}> =>
  fetcher(urls.getIssuerLogo, HttpMethod.GET);
