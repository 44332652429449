import React from 'react';
import {Tab as MuiTab, Tabs as MuiTabs} from '@material-ui/core';
import PropTypes from 'prop-types';

export const Tab = ({activeIndex, onTabChange, tabs}) => (
  <MuiTabs value={activeIndex} onChange={onTabChange} aria-label="tab">
    {tabs.map(tab => (
      <MuiTab label={tab} key={tab} />
    ))}
  </MuiTabs>
);

Tab.propTypes = {
  activeIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.any),
};
