import React, {useCallback} from 'react';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {ActionTableButton} from '../../../../shared/components/table/actionTableButton';
import {RemoveIcon} from '../../../../shared/icons/removeIcon';
import {colors} from '../../../../shared/styles/theme';
import {isMyPatientRemovingState} from '../../atoms/isMyPatientRemovingState';
import {useManageMyPatient} from '../../hooks/useManageMyPatient';
import {usePatientsSnackbars} from '../../hooks/usePatientsSnackbars';

type Props = {
  patientName: string;
  patientUuid: string;
  getMyPatients: () => Promise<void>;
};

export const MyPatientRemoveButton: React.FC<Props> = ({
  patientName,
  patientUuid,
  getMyPatients,
}) => {
  const {onShowDeleteMyPatientSnackbar} = usePatientsSnackbars();
  const {removeMyPatient, addMyPatient} = useManageMyPatient();
  const isMyPatientRemoving = useRecoilValue(isMyPatientRemovingState);

  const onClick = useCallback(async () => {
    await removeMyPatient(patientUuid);
    onShowDeleteMyPatientSnackbar({
      name: patientName,
      uuid: patientUuid,
      cb: async () => {
        await addMyPatient(patientUuid);
        await getMyPatients();
      },
    });
    await getMyPatients();
  }, [
    addMyPatient,
    getMyPatients,
    onShowDeleteMyPatientSnackbar,
    patientName,
    patientUuid,
    removeMyPatient,
  ]);

  return (
    <Wrapper>
      <ActionTableButton
        disabled={isMyPatientRemoving}
        testId={patientUuid}
        title="Remove from My Connections"
        onClick={onClick}
      >
        <RemoveIcon />
      </ActionTableButton>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  margin-right: 6px;
  display: inline-block;
  &:hover .removeIcon {
    background-color: ${colors.red};
    border: 1px solid ${colors.red};
    &:after {
      background-color: ${colors.white};
    }
  }
`;
