import moment from 'moment';

import {
  ChatHistoryConversation,
  ChatHistoryConversationResponse,
} from '../../../../../shared/interfaces/aIChat';

export const getRelativeTime = (date: Date): string => {
  const fromNow = getFromNow(date);
  return moment(date).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: `[${fromNow}]`,
    sameElse: `[${fromNow}]`,
  });
};
const getFromNow = (date: Date) => {
  const years = moment().diff(date, 'years');
  if (years) {
    return years === 1 ? 'Last year' : moment(date).fromNow();
  }
  const months = moment().diff(date, 'months');
  if (months) {
    return months === 1 ? 'Last month' : moment(date).fromNow();
  }
  const weeks = moment().diff(date, 'weeks');
  if (weeks) {
    return weeks === 1 ? 'Last week' : `${weeks} weeks ago`;
  }
  return moment(date).fromNow();
};

export const transformHistory = (
  array: ChatHistoryConversationResponse[]
): {[date: string]: ChatHistoryConversation[]} => {
  const conversations = array.map(obj => ({
    conversationId: obj.conversation_id,
    lastMessageAt: getRelativeTime(obj.last_message_at),
    title: obj.title,
  }));
  return groupByDate(conversations);
};

const groupByDate = (
  conversations: ChatHistoryConversation[]
): {[date: string]: ChatHistoryConversation[]} =>
  conversations.reduce<{[date: string]: ChatHistoryConversation[]}>(
    (acc, {lastMessageAt, ...rest}) => {
      const date = lastMessageAt;
      acc[date] = acc[date] || [];
      acc[date].push({lastMessageAt, ...rest});
      return acc;
    },
    {}
  );
