/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../../shared/components/list';
import {colors} from '../../../../../shared/styles/theme';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ElectronicRecordHorizontalDivider} from '../electronicRecordHorizonralDivider';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {StyledDivider} from '../styledDivider';
import {FetchedImages} from '../types/imagingStudyTypes';
import {CommonSectionProps, ElectronicRecordCode} from '../types/types';
import {getImages} from '../utils/imagingStudyMapper';

const LABELS_COLUMN_WIDTH = '140px';

const useStyles = makeStyles(() => ({
  sectionContentWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  accordionSubTitle: {
    color: colors.grey600,
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '24px',
    margin: '0 0 16px 0',
  },
  sectionItemWrapper: {
    padding: '24px',
  },
  sectionItemContent: {
    gridTemplateColumns: '1fr 1px 1fr',
  },
  instanceTitle: {
    color: colors.black,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  noteText: {
    color: colors.black,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
  },
  breakTextByCharacter: {
    wordBreak: 'break-all',
  },
}));

const ImagingStudySerieCode: React.FC<{
  title: string;
  valueTestId?: string | null;
  keyTestId?: string | null;
  titleTestId?: string | null;
  noCodeTestId?: string | null;
  codes: ElectronicRecordCode[];
}> = ({title, codes, valueTestId, noCodeTestId, keyTestId, titleTestId}) => (
  <>
    <dt />
    <dd />
    <ListTitleRow title={title} />

    <ElectronicRecordCodesList
      codes={codes}
      valueTestId={valueTestId}
      keyTestId={keyTestId}
      titleTestId={titleTestId}
      noCodeTestId={noCodeTestId}
    />
  </>
);

export const ImagingStudy: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<FetchedImages[]>(
    urls.getImageStudiesOfConsolidatedMedicalRecord(consumerUuid)
  );

  const images = useMemo(() => getImages(data), [data]);

  const hasNoData = images.length === 0 && !isFetching && !error;

  const listsWrapperClasses = cx(
    listTemplateClasses.wrapper,
    classes.sectionItemContent
  );

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Imaging Study"
    >
      <ElectronicRecordAccordionWrapper>
        {images?.map(({series, notes, encounter, ...image}) => (
          <ElectronicRecordAccordion
            key={image.id}
            title={image?.description ?? 'Name Unavailable'}
            content={
              <div className={classes.sectionContentWrapper}>
                <div className={classes.sectionItemWrapper}>
                  <ElectronicRecordGroupTitle title="Details" />
                  <div className={listsWrapperClasses}>
                    <ListWithFixedLabelsColumn
                      labelsColumnWidth={LABELS_COLUMN_WIDTH}
                    >
                      <ListItemKey>Procedure Reference</ListItemKey>
                      <ListItemValue testId="procedure_reference">
                        {image.procedure_reference}
                      </ListItemValue>
                      <ListItemKey>Modalities</ListItemKey>
                      <ListItemValue testId="modalities">
                        {image.modalities}
                      </ListItemValue>
                      <ListItemKey>Reasons</ListItemKey>
                      <ListItemValue testId="reasons">
                        {image.reasons}
                      </ListItemValue>
                    </ListWithFixedLabelsColumn>

                    <StyledDivider orientation="vertical" />

                    <ListWithFixedLabelsColumn
                      labelsColumnWidth={LABELS_COLUMN_WIDTH}
                    >
                      <ListItemKey>Description</ListItemKey>
                      <ListItemValue testId="description">
                        {image.description}
                      </ListItemValue>
                      <ListItemKey>Data Last Updated</ListItemKey>
                      <ListItemValue testId="last_updated">
                        {image.last_updated_at}
                      </ListItemValue>
                    </ListWithFixedLabelsColumn>
                  </div>
                </div>

                {series && series.length !== 0 ? (
                  <>
                    <StyledDivider isBoldDivider />

                    <div
                      className={classes.sectionItemWrapper}
                      data-testid="series_block"
                    >
                      <ElectronicRecordGroupTitle title="Series" />
                      {series.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <ElectronicRecordHorizontalDivider
                            index={index}
                            length={series.length}
                          />

                          <div>
                            <p className={classes.accordionSubTitle}>
                              Serie {index + 1}
                            </p>
                            <div className={listsWrapperClasses}>
                              <ListWithFixedLabelsColumn
                                labelsColumnWidth={LABELS_COLUMN_WIDTH}
                              >
                                <ListTitleRow title="General" />
                                <ListItemKey>UID</ListItemKey>
                                <ListItemValue
                                  classNames={classes.breakTextByCharacter}
                                  testId="serie_uid"
                                >
                                  {item.uid}
                                </ListItemValue>
                                <ListItemKey>Number</ListItemKey>
                                <ListItemValue testId="serie_number">
                                  {item.number}
                                </ListItemValue>
                                <ListItemKey>Description</ListItemKey>
                                <ListItemValue testId="serie_description">
                                  {item.description}
                                </ListItemValue>
                                <ListItemKey>Started Date</ListItemKey>
                                <ListItemValue testId="serie_started_date">
                                  {item.started_date}
                                </ListItemValue>
                                <ListItemKey>Performed</ListItemKey>
                                <ListItemValue testId="serie_performed">
                                  {item.performer}
                                </ListItemValue>

                                <ImagingStudySerieCode
                                  keyTestId="modality_code"
                                  valueTestId="modality_display"
                                  titleTestId="modality_system"
                                  noCodeTestId="modality_no_code"
                                  codes={item.modality}
                                  title="Modality"
                                />
                                <ImagingStudySerieCode
                                  keyTestId="body_site_code"
                                  valueTestId="body_site_display"
                                  titleTestId="body_site_system"
                                  noCodeTestId="body_site_no_code"
                                  codes={item.body_site}
                                  title="Body Site"
                                />
                                <ImagingStudySerieCode
                                  keyTestId="laterality_code"
                                  valueTestId="laterality_display"
                                  titleTestId="laterality_system"
                                  noCodeTestId="laterality_no_code"
                                  codes={item.laterality}
                                  title="Laterality"
                                />
                              </ListWithFixedLabelsColumn>

                              <StyledDivider orientation="vertical" />

                              <div>
                                {item.instance.length !== 0 ? (
                                  <>
                                    {item.instance.map((instance, index) => (
                                      <React.Fragment key={instance.id}>
                                        <ElectronicRecordHorizontalDivider
                                          index={index}
                                          length={item.instance.length}
                                        />

                                        <ListWithFixedLabelsColumn
                                          labelsColumnWidth={
                                            LABELS_COLUMN_WIDTH
                                          }
                                        >
                                          {index === 0 ? (
                                            <ListTitleRow title="Instances" />
                                          ) : null}

                                          <ListItemKey
                                            testId="serie_instance_title"
                                            classNames={classes.instanceTitle}
                                          >
                                            {instance.title}
                                          </ListItemKey>
                                          <dd />
                                          <ListItemKey>UID</ListItemKey>
                                          <ListItemValue
                                            classNames={
                                              classes.breakTextByCharacter
                                            }
                                            testId="serie_instance_uid"
                                          >
                                            {instance.uid}
                                          </ListItemValue>
                                          <ListItemKey>Number</ListItemKey>
                                          <ListItemValue testId="serie_instance_number">
                                            {instance.number}
                                          </ListItemValue>

                                          <ListItemKey testId="serie_instance_sop_class_system">
                                            {instance.sop_class?.system}
                                          </ListItemKey>
                                          <dd />
                                          <ListItemKey testId="serie_instance_sop_class_code">
                                            {instance.sop_class?.code}
                                          </ListItemKey>
                                          <ListItemValue testId="serie_instance_sop_class_display">
                                            {instance.sop_class?.display}
                                          </ListItemValue>
                                        </ListWithFixedLabelsColumn>
                                      </React.Fragment>
                                    ))}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                ) : null}

                <StyledDivider isBoldDivider />

                <div
                  className={classes.sectionItemWrapper}
                  data-testid="encounter_block"
                >
                  <ElectronicRecordGroupTitle title="Encounter" />

                  {encounter ? (
                    <div className={listsWrapperClasses}>
                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ListTitleRow title="General" />

                        <ListItemKey>Status</ListItemKey>
                        <ListItemValue testId="encounter_status">
                          {encounter.status}
                        </ListItemValue>
                        <ListItemKey>Service Provider</ListItemKey>
                        <ListItemValue testId="encounter_service_provider">
                          {encounter.service_provider}
                        </ListItemValue>

                        <dt />
                        <dd />
                        <ListTitleRow title="Period" />

                        <ListItemKey>Start Date</ListItemKey>
                        <ListItemValue testId="encounter_start_date">
                          {encounter.period.start}
                        </ListItemValue>
                        <ListItemKey>End Date</ListItemKey>
                        <ListItemValue testId="encounter_end_date">
                          {encounter.period.end}
                        </ListItemValue>
                      </ListWithFixedLabelsColumn>

                      <StyledDivider orientation="vertical" />

                      <ListWithFixedLabelsColumn
                        labelsColumnWidth={LABELS_COLUMN_WIDTH}
                      >
                        <ListTitleRow title="Classification" />

                        <ElectronicRecordCodesList
                          titleTestId="encounter_classification_system"
                          keyTestId="encounter_classification_code"
                          valueTestId="encounter_classification_display"
                          noCodeTestId="encounter_classification_no_code"
                          codes={encounter?.classification}
                        />

                        <dt />
                        <dd />
                        <ListTitleRow title="Type" />

                        <ElectronicRecordCodesList
                          titleTestId="encounter_type_system"
                          keyTestId="encounter_type_code"
                          valueTestId="encounter_type_display"
                          noCodeTestId="encounter_type_no_code"
                          codes={encounter?.type}
                        />
                      </ListWithFixedLabelsColumn>
                    </div>
                  ) : (
                    <ListWithNoDataValue />
                  )}
                </div>

                <StyledDivider isBoldDivider />

                <div className={classes.sectionItemWrapper} data-testid="notes">
                  <ElectronicRecordGroupTitle title="Provider Notes" />

                  {notes && notes.length !== 0 ? (
                    notes.map((note, index) => (
                      <>
                        <ElectronicRecordHorizontalDivider
                          index={index}
                          length={notes.length}
                        />

                        <ListWithFixedLabelsColumn
                          key={note.id}
                          labelsColumnWidth={LABELS_COLUMN_WIDTH}
                        >
                          <ListTitleRow
                            testId="note_recorded_date"
                            title={note.recordedDate ?? 'Date Unavailable'}
                          />
                          <ListItemKey>Author</ListItemKey>
                          <ListItemValue testId="note_author">
                            {note.author ?? 'Author Unavailable'}
                          </ListItemValue>

                          <ListItemKey>Note</ListItemKey>
                          <ListItemValue
                            testId="note_text"
                            classNames={classes.noteText}
                          >
                            {note.text}
                          </ListItemValue>
                        </ListWithFixedLabelsColumn>
                      </>
                    ))
                  ) : (
                    <ListWithNoDataValue />
                  )}
                </div>
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
