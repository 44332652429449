import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import noDataIcon from '../../../assets/icons/noData.svg';
import {colors} from '../styles/theme';

export function NoDataPlaceholderDrawer({noDataText}) {
  return (
    <Container>
      <NoDataImage src={noDataIcon} alt="noDataIcon" />
      <NoDataText>{noDataText}</NoDataText>
    </Container>
  );
}
const Container = styled.div`
  text-align: center;
`;

const NoDataText = styled.div`
  font-style: italic;
  color: ${colors.grey801};
`;

const NoDataImage = styled.img`
  height: 32px;
  width: 32px;
`;

NoDataPlaceholderDrawer.propTypes = {
  noDataText: PropTypes.string,
};
