import React, {InputHTMLAttributes} from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {ColumnDef} from '@tanstack/react-table';

import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {INBOX_TABLE_COLUMNS} from '../../inbox/constatnts/inboxTableConfig';
import {FormattedProviderInboxEventsType} from '../../inbox/constatnts/types';

type CellType = {
  row: {
    original: FormattedProviderInboxEventsType;
  };
};

export const getOutstandingRecordRequestsTableCells = ({
  selectedEventIds,
  toggleEvent,
}: {
  selectedEventIds: string[];
  toggleEvent: (id: string) => void;
}): (ColumnDef<FormattedProviderInboxEventsType> & AdditionalTableProps)[] => [
  ...INBOX_TABLE_COLUMNS,
  {
    id: 'id',
    header: 'Actions',
    minWidth: '150px',
    maxWidth: '150px',
    width: '150px',
    isStickyRight: true,
    cell(cell: CellType): JSX.Element {
      return (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="Hide expired requests"
              checked={selectedEventIds.includes(cell.row.original.id)}
              onClick={() => toggleEvent(cell.row.original.id)}
              inputProps={
                {
                  'data-testid': `complete-request-inbox-${cell.row.original.id}`,
                } as InputHTMLAttributes<HTMLInputElement>
              }
            />
          }
          label="Completed"
        />
      );
    },
  },
];
