import {ResponseType} from './responseType';

export const parseResponseData = async (response, responseType) => {
  switch (responseType) {
    case ResponseType.JSON:
      return response.json();
    case ResponseType.Text:
      return response.text();
    case ResponseType.Blob:
      return response.blob();
    case ResponseType.FormData:
      return response.formData();
    case ResponseType.ArrayBuffer:
      return response.arrayBuffer();
    case ResponseType.None:
    default:
      return null;
  }
};
