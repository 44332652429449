import React from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../shared/components/list';

import {useGeneralListStyles} from './constants/listTemplate';
import {MedicationStatement} from './types/medicationTypes';
import {formatDate} from './utils/mappersUtils';
import {ElectronicRecordCodesList} from './electronicRecordCodesList';
import {ElectronicRecordDateByType} from './electronicRecordDateByType';
import {ElectronicRecordGroupTitle} from './electronicRecordGroupTitle';
import {ListWithFixedLabelsColumn} from './electronicRecordListWithFixedLabelsColumn';
import {StyledDivider} from './styledDivider';

const LABELS_COLUMN_WIDTH = '150px';

const useStyles = makeStyles(() => ({
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    width: '100%',
    boxSizing: 'border-box',
  },
  contentBlockItemGrid: {
    gridTemplateColumns: '1fr 1px 1fr',
    padding: '24px',
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'red',
  },
}));

type Props = {
  statements: MedicationStatement[];
};

export const MedicationStatementComponent: React.FC<Props> = ({statements}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const listsWrapperClasses = cx(
    listTemplateClasses.wrapper,
    classes.contentBlockItemGrid
  );
  return (
    <>
      {statements.length > 0 ? (
        statements.map(statement => (
          <div className={listsWrapperClasses} key={statement.date_asserted}>
            <div>
              <ElectronicRecordGroupTitle title="Statements" />
              <ListWithFixedLabelsColumn
                labelsColumnWidth={LABELS_COLUMN_WIDTH}
              >
                <ListItemKey>Status</ListItemKey>
                <ListItemValue testId="statement_status">
                  {statement.status}
                </ListItemValue>
                <ListItemKey>Category</ListItemKey>
                <div className="listWrapper">
                  {statement.category ? (
                    statement.category.map(category => (
                      <ListItemValue key={category.code}>
                        {category.display}
                      </ListItemValue>
                    ))
                  ) : (
                    <ListItemValue />
                  )}
                </div>
                <ListItemKey>Dosage Instruction</ListItemKey>
                <ListItemValue testId="statement_dosage_instruction">
                  {statement.dosage}
                </ListItemValue>
                <ElectronicRecordDateByType date={statement.effective} />
                <ListItemKey>Information Source</ListItemKey>
                <ListItemValue testId="statement_information_source">
                  {statement.information_source}
                </ListItemValue>
                <ListItemKey>Date Asserted</ListItemKey>
                <ListItemValue testId="statement_date_asserted">
                  {formatDate(statement.date_asserted)}
                </ListItemValue>
                <ListItemKey>Note</ListItemKey>
                <div className="listWrapper">
                  {statement.notes ? (
                    statement.notes.map(note => (
                      <ListItemValue key={note.recordedDate}>
                        {note.text}
                      </ListItemValue>
                    ))
                  ) : (
                    <ListItemValue />
                  )}
                </div>
                <ListItemKey>Data Last Updated</ListItemKey>
                <ListItemValue testId="statement_data_last_updated">
                  {formatDate(statement.last_updated_at)}
                </ListItemValue>
              </ListWithFixedLabelsColumn>
            </div>
            <StyledDivider orientation="vertical" />
            <div data-testid="statement_codes_block">
              <ElectronicRecordGroupTitle title="Codes" />
              <ListWithFixedLabelsColumn
                labelsColumnWidth={LABELS_COLUMN_WIDTH}
              >
                <ListTitleRow title="Reasons" />
                {statement.reasons ? (
                  statement.reasons.map((reason, index) => (
                    <ElectronicRecordCodesList
                      noCodeTestId="statement_no_code_medication_codings"
                      codes={reason.codes ?? []}
                      key={reason.codes[index].code}
                    />
                  ))
                ) : (
                  <ListItemValue />
                )}
              </ListWithFixedLabelsColumn>
            </div>
          </div>
        ))
      ) : (
        <div className={listsWrapperClasses}>
          <div>
            <ElectronicRecordGroupTitle title="Statements" />
            <ListItemKey>Not Available</ListItemKey>
          </div>
          <StyledDivider orientation="vertical" />
        </div>
      )}
    </>
  );
};
