import {useSetRecoilState} from 'recoil';

import {relyingPartyInitialState, relyingPartyState, stepState} from '../store';

export default function useClearOutInvitation() {
  const setRelyingParty = useSetRecoilState(relyingPartyState);
  const setStep = useSetRecoilState(stepState);
  function clearOutInvitation() {
    setRelyingParty(relyingPartyInitialState);
    setStep(0);
  }

  return {clearOutInvitation};
}
