import React, {FC, ReactNode} from 'react';
import MuiButton, {ButtonProps} from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import clsx from 'clsx';

import {colors} from '../styles/theme';

interface CustomizedButtonProps extends ButtonProps {
  isActive?: boolean;
  children?: ReactNode;
  testID?: string;
}
type styleProps = {
  isActive: boolean;
};

const useStyles = makeStyles({
  root: ({isActive}: styleProps) => {
    const borderColor = isActive ? colors.primary : colors.backgroundPrimary;
    const backgroundColor = isActive
      ? colors.backgroundLightPrimary
      : colors.white;

    return {
      textTransform: 'none',
      '&.MuiButton-outlined': {
        border: `1px solid ${borderColor}`,
        backgroundColor,
      },
      '&.MuiButton-outlined:hover': {
        boxShadow: '0px 4px 10px rgba(36, 71, 130, 0.16)',
        border: `1px solid ${colors.primary}`,
      },
    };
  },
});

export const CustomizedButton: FC<CustomizedButtonProps> = ({
  children,
  isActive = false,
  testID,
  className,
  ...rest
}) => {
  const classes = useStyles({isActive});
  return (
    <MuiButton
      data-testid={testID ?? null}
      className={clsx(classNames(classes.root), className)}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
