export const passwordFilter = event => {
  const value = event.target.value.replace(
    /[^\w ~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/g,
    ''
  );
  return {
    ...event,
    target: {...event.target, value},
  };
};
