import React, {InputHTMLAttributes, useCallback} from 'react';
import {Box, FormControlLabel, RadioGroup, Typography} from '@material-ui/core';
import {ColumnDef} from '@tanstack/react-table';

import {InputRadioButton} from '../../../shared/components/form';
import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {dateFormatWithTime} from '../../../shared/constants/dateFormat';
import {DownloadIcon} from '../../../shared/icons/downloadIcon';
import {EyeIcon} from '../../../shared/icons/eyeIcon';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {formatBytes} from '../../../shared/utils/formatBites';
import {formatUTCDate} from '../../../shared/utils/formatDate';
import {IndeterminateCheckbox} from '../components/indeterminateCheckbox';
import {SortingHeaderWrapper} from '../components/table/sortingHeaderWrapper';
import {FILE_WITHOUT_EXTENSION} from '../utils/fileViewer';

import {MedicalDocument, SortingDirections} from './types';

export const getMedicalDocumentsTableCells = (
  onSortByUploadedOn: () => void,
  sortingByUploadedOn: SortingDirections,
  onTogglePreviewModalWindow: (file: MedicalDocument) => void,
  onDownloadFiles: (files: MedicalDocument[]) => Promise<void>,
  isAIChat = false
): (ColumnDef<MedicalDocument> & AdditionalTableProps)[] => {
  const checkBoxComponent = row => {
    const {
      getIsSelected,
      getIsSomeSelected,
      getToggleSelectedHandler,
      original,
    } = row;

    return isAIChat ? (
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel
          control={<InputRadioButton />}
          checked={getIsSelected()}
          onClick={getToggleSelectedHandler()}
          label={
            <Typography
              style={{fontWeight: 600}}
              title={original.file_name}
              noWrap
            >
              {original.file_name}
            </Typography>
          }
        />
      </RadioGroup>
    ) : (
      <>
        <IndeterminateCheckbox
          {...{
            checked: getIsSelected(),
            indeterminate: getIsSomeSelected(),
            onChange: getToggleSelectedHandler(),
            inputProps: {
              'data-testid': `file-checkbox-${original.id}`,
            } as InputHTMLAttributes<HTMLInputElement>,
          }}
        />
        <Typography
          style={{fontWeight: 600}}
          title={row.original.file_name}
          noWrap
        >
          {row.original.file_name}
        </Typography>
      </>
    );
  };

  const columns = [
    {
      id: 'fileName',
      width: '400px',
      isStickyLeft: true,
      header({table}) {
        return (
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {!isAIChat && (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                  inputProps: {
                    'data-testid': 'select-all-files-checkbox',
                  } as InputHTMLAttributes<HTMLInputElement>,
                }}
              />
            )}
            <span>File Name</span>
          </Box>
        );
      },
      cell({row}) {
        return (
          <Box display="flex" flexWrap="nowrap" alignItems="center">
            {checkBoxComponent(row)}
          </Box>
        );
      },
    },
    {
      id: 'provider',
      header: 'Organization',
      width: '220px',
      cell({row: {original}}) {
        return (
          <Typography title={original.provider_name} noWrap>
            {original.provider_name}
          </Typography>
        );
      },
    },
    {
      id: 'uploadedBy',
      header: 'Uploaded By',
      width: '220px',
      cell({row: {original}}) {
        return (
          <Typography title={original.uploaded_by} noWrap>
            {original.uploaded_by}
          </Typography>
        );
      },
    },
    {
      id: 'uploadedOn',
      header() {
        return (
          <SortingHeaderWrapper
            onClick={onSortByUploadedOn}
            sortingDirection={sortingByUploadedOn}
          >
            <span>Uploaded On</span>
          </SortingHeaderWrapper>
        );
      },
      cell({row: {original}}) {
        return (
          <Typography title={original.uploaded_on} noWrap>
            {formatUTCDate(new Date(original.uploaded_on), dateFormatWithTime)}
          </Typography>
        );
      },
      width: '200px',
    },
    {
      id: 'fileType',
      header: 'File Type',
      accessorKey: 'file_type',
      cell({row: {original}}) {
        return original.file_type === FILE_WITHOUT_EXTENSION
          ? 'No File Type'
          : original.file_type;
      },
      width: '140px',
    },
    {
      id: 'fileSize',
      header: 'Size',
      cell({row: {original}}) {
        const size = formatBytes(original.file_size) || '';

        return (
          <Typography title={size} noWrap>
            {size}
          </Typography>
        );
      },
      width: '150px',
    },
  ];

  if (!isAIChat) {
    columns.push({
      id: 'actions',
      header: 'Actions',
      width: '170px',
      isStickyRight: true,
      cell({row: {original}}) {
        return (
          <Actions
            onTogglePreviewModalWindow={onTogglePreviewModalWindow}
            original={original}
            onDownloadFiles={onDownloadFiles}
          />
        );
      },
    });
  }

  return columns;
};

interface ActionsProps {
  onTogglePreviewModalWindow: (file: MedicalDocument) => void;
  original: MedicalDocument;
  onDownloadFiles: (files: MedicalDocument[]) => Promise<void>;
}

function Actions({
  onTogglePreviewModalWindow,
  original,
  onDownloadFiles,
}: Readonly<ActionsProps>): any {
  const onClickPreview = useCallback(
    () => onTogglePreviewModalWindow(original),
    [onTogglePreviewModalWindow, original]
  );

  const onClickDownload = useCallback(
    () => onDownloadFiles([original]),
    [onDownloadFiles, original]
  );
  return (
    <>
      <ActionTableButton
        onClick={onClickPreview}
        title="Preview Document"
        testId={`preview-document-${original.id}`}
      >
        <EyeIcon fontSize="inherit" />
      </ActionTableButton>
      <ActionTableButton
        onClick={onClickDownload}
        title="Download Document"
        testId={`download-document-${original.id}`}
      >
        <DownloadIcon />
      </ActionTableButton>
    </>
  );
}
