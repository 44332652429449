import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {envConfig} from '../../../env';
import {Button} from '../../shared/components/button';
import {InputTextField} from '../../shared/components/inputTextField';
import {colors} from '../../shared/styles/theme';
import {useHideRecaptcha} from '../hooks/useHideRecaptcha';
import {useRecaptcha} from '../hooks/useRecaptcha';
import {passwordValidator} from '../utils/passwordValidator';

import {SetPassword} from './setPassword';
import {VerificationTip} from './verificationTip';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: '30px',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      backgroundColor: colors.grey70,
      color: colors.grey500,
    },
    '& .MuiButton-root': {
      width: 256,
      height: 32,
      alignSelf: 'center',
      fontSize: 12,
      marginTop: 26,
    },
  },
}));

const containerId = 'sign-up-button';
const maskEmail = email => email.replace(/(.)[^@]*(.)@(.*)/, '$1***$2@$3');
const maskPhoneNumber = phoneNumber =>
  `(***)***-${phoneNumber.substring(phoneNumber.length - 4)}`;

export const SignUpForm = ({onSubmit, email, phoneNumber, setLoading}) => {
  const classes = useStyles();
  const getVerifier = useRecaptcha();

  const recaptchaVisible = useHideRecaptcha(setLoading);

  const handleSubmit = useCallback(
    async ({password}) => {
      const verifier = getVerifier(containerId);
      await onSubmit(password, verifier);
    },
    [getVerifier, onSubmit]
  );

  const validate = useCallback(values => passwordValidator(values), []);

  return (
    <>
      <Content>
        If you want to change the email or phone number, please contact Health
        Bank One support via email at support@healthbankone.com.
      </Content>
      <Formik
        initialValues={{
          email: maskEmail(email),
          phoneNumber: maskPhoneNumber(phoneNumber),
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          submitForm,
          isSubmitting,
          dirty,
          errors,
          handleChange,
          touched,
          isValid,
          values,
          setFieldTouched,
        }) => (
          <Form className={classes.root}>
            <InputField
              disabled
              name="email"
              label="Email address"
              size="auto"
              testID="emailAddressInput"
            />
            <InputField
              disabled
              name="phoneNumber"
              label="Phone number"
              size="auto"
              testID="phoneNumberInput"
            />
            <SetPassword
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
            />
            <Button
              id={containerId}
              type="submit"
              testID="sendSMSButton"
              onClick={submitForm}
              disabled={
                (isSubmitting || !dirty || !isValid) && recaptchaVisible
              }
            >
              SEND VERIFICATION CODE
            </Button>
            <VerificationTip />
          </Form>
        )}
      </Formik>
    </>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  setLoading: PropTypes.func,
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Content = styled.p`
  font-size: 12px;
  color: ${colors.black};
  width: 400px;
  height: 40px;
  margin: 0 auto 15px;
`;

const InputField = styled(InputTextField)`
  margin-bottom: 10px;
`;
