import {atom} from 'recoil';

type ConfirmViewMedicalRecordsState = {
  isOpen: boolean;
  data: {
    patientUuid: string;
    patientName: string;
  };
};

export const confirmViewMedicalRecordsState =
  atom<ConfirmViewMedicalRecordsState>({
    key: 'confirmViewMedicalRecords',
    default: {
      isOpen: false,
      data: {
        patientUuid: '',
        patientName: '',
      },
    },
  });
