import {formatRoles} from '../../../shared/utils/formatRoles';

export const portalUserListColumns = [
  {
    key: 'name',
    label: 'Name',
    minWidth: 127,
    maxWidth: 200,
  },
  {
    key: 'roles',
    label: 'Role',
    minWidth: 127,
    maxWidth: 200,
    formatter: formatRoles,
  },
  {
    key: 'email',
    label: 'Email address',
    minWidth: 127,
    maxWidth: 200,
  },
  {
    key: 'phoneNumber',
    label: 'Phone number',
    minWidth: 127,
    maxWidth: 200,
  },
];
