import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {
  ALLOWED_FILE_TYPE,
  ALLOWED_FILE_TYPES_LIST,
  CareGuide,
  FileUploadLinkResponse,
  Message,
  MessageFileResponse,
  MessageResponse,
} from '../../../shared/interfaces/aIChat';
import {postMessageisLoadingState} from '../atoms/chatState';
import {
  fetchAIChat,
  fetchAIConversation,
  fetchDeleteAIConversation,
} from '../utils/fetchers';

interface ChatMessage {
  message: string;
  conversation_id: string;
  isRetry: boolean;
}

export const useAIChat = (): {
  postMessageisLoading: boolean;
  isLoadingChat: boolean;
  onPostMessage: (
    message: string,
    chatId: string | null,
    fileName: string | null,
    savePictureResponse: FileUploadLinkResponse | null,
    isAMedicalRecord: boolean
  ) => Promise<ChatMessage>;
  getConversation: (conversationId: string) => Promise<CareGuide | undefined>;
  onDeleteConversation: (conversationId: string) => Promise<void>;
} => {
  const showMessage = useCustomizedSnackbar();
  const {patientUuid} = useParams<{patientUuid: string}>();
  const [postMessageisLoading, setPostMessageisLoading] = useRecoilState(
    postMessageisLoadingState
  );
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const onPostMessage = async (
    message: string,
    chatId: string | null,
    fileName: string | null,
    savePictureResponse: FileUploadLinkResponse | null,
    isAMedicalRecord: boolean
  ) => {
    try {
      setPostMessageisLoading(true);
      const response = await fetchAIChat(
        patientUuid,
        message,
        chatId,
        fileName && {
          original_file_name: fileName,
          content_type: savePictureResponse?.content_type,
          file_path: savePictureResponse?.file_name,
          health_record: isAMedicalRecord,
        }
      );
      return {...response, isRetry: false};
    } catch (e) {
      return {isRetry: true};
    } finally {
      setPostMessageisLoading(false);
    }
  };

  const getConversation = async (
    conversationId: string
  ): Promise<CareGuide | undefined> => {
    setIsLoadingChat(true);
    try {
      const {conversation_id, messages} = (await fetchAIConversation(
        conversationId
      )) as {
        conversation_id: string;
        messages: MessageResponse[];
      };

      const fileShowInChat = (file?: MessageFileResponse) =>
        file?.content ? file?.content : file?.original_file_name;

      const mapMessages: Message[] = messages.map(m => ({
        text: m.text,
        isUser: m.role === 'USER',
        isFile: !!m.file?.content_type,
        fileType: (m.file?.content_type as ALLOWED_FILE_TYPE) ?? '',
        fileName: m.file?.original_file_name ?? '',
        isAMedicalRecord: m.file?.medical_records,
        fileShowInChat: ALLOWED_FILE_TYPES_LIST.includes(
          m.file?.content_type ?? ''
        )
          ? fileShowInChat(m.file)
          : '',
      }));

      return {
        messages: mapMessages,
        chatId: conversation_id,
      };
    } catch (e) {
      showMessage('Something went wrong.', 'error');
      return undefined;
    } finally {
      setIsLoadingChat(false);
    }
  };

  const onDeleteConversation = async (conversationId: string | null) => {
    try {
      return await fetchDeleteAIConversation(conversationId);
    } catch (e) {
      showMessage('Something went wrong.', 'error');
    }
  };

  return {
    postMessageisLoading,
    isLoadingChat,
    onPostMessage,
    getConversation,
    onDeleteConversation,
  };
};
