import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

export const ItemCell = ({label, value, className, size}) => (
  <Container className={className}>
    <Label>{label}</Label>
    <Value size={size} title={value}>
      {value}
    </Value>
  </Container>
);

ItemCell.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: ${colors.grey801};
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 3px;
`;

const Value = styled.span`
  background: #fafafa;
  border-radius: 4px;
  color: ${colors.black100};
  width: ${props => (props.size === 'large' ? '463px' : '222px')};
  line-height: 44px;
  padding-left: 16px;
  height: 44px;
  padding-right: 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
