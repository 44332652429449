import {useCallback} from 'react';

import {fetcher} from '../../shared/utils/fetcher';
import {ResponseType} from '../../shared/utils/responseType';
import {urls} from '../../shared/utils/urls';
import {deleteMfaRecord, updateMfaRecord} from '../utils/mfaRecord';

export const useSkipMfa = (email, tenantId) => {
  const generateMfaRecord = useCallback(async () => {
    const uuid = await updateMfaRecord(email);

    try {
      await fetcher(
        urls.recordMfa,
        'POST',
        {
          unique_id: uuid,
          tenant_id: tenantId,
        },
        {responseType: ResponseType.None}
      );
    } catch (e) {
      await deleteMfaRecord(uuid);
    }
  }, [email, tenantId]);

  const getCustomToken = useCallback(
    async (uuid, userEmail, password) => {
      const {value} = await fetcher(urls.customToken, 'POST', {
        unique_id: uuid,
        email: userEmail,
        password,
        tenant_id: tenantId,
      });
      return value;
    },
    [tenantId]
  );

  return {generateMfaRecord, getCustomToken};
};
