import React, {FC, useCallback, useMemo} from 'react';
import {Box, Chip} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import {useQueryClient} from '@tanstack/react-query';
import {getCoreRowModel, Row, useReactTable} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ChipsWrapper} from '../../../shared/components/chip';
import {FilterWithCheckboxes} from '../../../shared/components/filterWithCheckboxes';
import {MultipleAutocompleteWithCheckBoxes} from '../../../shared/components/multipleSelect/multipleAutocompleteWithCheckBoxes';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SearchInput} from '../../../shared/components/searchInput';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {Crumb} from '../../../shared/interfaces/crumb';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';
import {drawerState} from '../atoms/drawerState';
import {isMyPatientAddingState} from '../atoms/isMyPatientAddingState';
import {isMyPatientRemovingState} from '../atoms/isMyPatientRemovingState';
import {isPatientConnectingState} from '../atoms/isPatientConnectingState';
import {isSharingMedicalRecordsWithProviderState} from '../atoms/isSharingMedicalRecordsWithProviderState';
import {PatientsPageWrapper} from '../components/patientsPageWrapper';
import {
  getEnrolledPatientNameCell,
  getPatientsTableCells,
} from '../constants/patientsTableConfig';
import {
  CONNECTION_STATUSES,
  CONNECTION_STATUSES_LABELS,
} from '../constants/patientStatuses';
import {PatientFormattedType} from '../constants/types';
import {useEnrolledPatients} from '../hooks/useEnrolledPatients';
import {usePhysicians} from '../hooks/usePhysicians';
import {useSearch} from '../hooks/useSearch';
import {useSelectConnectionStatus} from '../hooks/useSelectConnectionStatus';

type Props = {
  title: string;
  crumbs: Crumb[];
  activeTabIndex: number;
};

export const EnrolledPatientsPage: FC<Props> = ({
  title,
  crumbs,
  activeTabIndex,
}) => {
  const queryClient = useQueryClient();
  const drawer = useRecoilValue(drawerState);
  const currentUser = useRecoilValue(currentUserState);
  const isMyPatientRemoving = useRecoilValue(isMyPatientRemovingState);
  const isMyPatientAdding = useRecoilValue(isMyPatientAddingState);
  const isPatientConnecting = useRecoilValue(isPatientConnectingState);
  const isPatientSharingRecords = useRecoilValue(
    isSharingMedicalRecordsWithProviderState
  );
  const isNonPhysicianHasRightRolesForMultiSelect =
    isRoleMatched(currentUser?.roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ]) && currentUser?.type === USER_TYPES.NON_PHYSICIAN;

  const [searchValue, searchError, onClearSearch, onSearch] = useSearch();
  const {
    physicians,
    physiciansIsLoading,
    error,
    selectedPhysicians,
    handleSelectedPhysicians,
    removeSelectedPhysician,
  } = usePhysicians(isNonPhysicianHasRightRolesForMultiSelect);

  const {selectedConnectionStatuses, handleToggleConnectionStatuses} =
    useSelectConnectionStatus();

  const {
    data: enrolledPatients = [],
    isLoading: enrolledPatientsIsLoading,
    isFetching: enrolledPatientsIsFetching,
    isFetchedAfterMount: enrolledPatientsIsFetchedAfterMount,
    isError: enrolledPatientsIsError,
  } = useEnrolledPatients(
    currentUser?.relyingParty.id ?? null,
    currentUser?.type ?? null,
    searchValue,
    selectedPhysicians,
    selectedConnectionStatuses
  );

  const isFirstLoadingCase =
    enrolledPatientsIsLoading &&
    searchValue.length === 0 &&
    enrolledPatients.length === 0 &&
    selectedConnectionStatuses.length === 0;

  const noDataCase =
    (!enrolledPatientsIsLoading || !enrolledPatientsIsFetching) &&
    searchValue.length === 0 &&
    enrolledPatients.length === 0 &&
    selectedPhysicians.length === 0 &&
    selectedConnectionStatuses.length === 0;

  const nothingFoundCase =
    enrolledPatients.length === 0 &&
    (selectedPhysicians.length !== 0 ||
      selectedConnectionStatuses.length !== 0 ||
      searchValue.length !== 0);

  const isLoading =
    (enrolledPatientsIsLoading ||
      isMyPatientRemoving ||
      isMyPatientAdding ||
      isPatientConnecting ||
      isPatientSharingRecords ||
      enrolledPatientsIsFetching) &&
    !enrolledPatientsIsFetchedAfterMount;

  const placeholderComponent = useMemo(() => {
    if (searchError || nothingFoundCase) {
      return (
        <Box
          component="div"
          fontStyle="italic"
          textAlign="center"
          color={colors.red}
        >
          {searchError ?? 'No results found. Please try again.'}
        </Box>
      );
    }

    return null;
  }, [nothingFoundCase, searchError]);

  const columns = useMemo(() => {
    const updateEnrolledPatients = async () => {
      await queryClient.invalidateQueries({
        queryKey: ['enrolledPatients'],
      });
    };

    return [
      getEnrolledPatientNameCell(updateEnrolledPatients),
      ...getPatientsTableCells(updateEnrolledPatients),
    ];
  }, [queryClient]);

  const table = useReactTable({
    data: enrolledPatients,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isHighlightRow = (row: Row<PatientFormattedType>) =>
    row.original.consumer_uuid === drawer.patientUuid;

  return (
    <PatientsPageWrapper
      title={title}
      crumbs={crumbs}
      activeTabIndex={activeTabIndex}
      isLoading={isLoading}
    >
      {noDataCase && !isFirstLoadingCase && !enrolledPatientsIsError ? (
        <NoDataPlaceholder noDataText="No Connections yet" />
      ) : null}
      {!noDataCase && !isFirstLoadingCase && !enrolledPatientsIsError ? (
        <>
          <FilterWrapper>
            <SearchInput
              placeholder="Search by Name, Phone or MRN"
              focusedPlaceholder="Type at least 3 characters to search"
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              testId="enrolled-patients"
            />
            {isNonPhysicianHasRightRolesForMultiSelect && (
              <AutocompleteWrapper>
                <MultipleAutocompleteWithCheckBoxes
                  placeholderText="Search by Provider"
                  noOptionsFoundText="No provider found"
                  data={physicians}
                  loading={physiciansIsLoading}
                  error={error}
                  selectedOptions={selectedPhysicians}
                  setSelectedOptions={handleSelectedPhysicians}
                  errorText="Something went wrong. Please reload this page"
                  emptyDataText="No data is available"
                  testId="physicians"
                />
              </AutocompleteWrapper>
            )}
            <FilterWithCheckboxesWrapper>
              <FilterWithCheckboxes
                id="connection-status-filter"
                testId="connection-status"
                label="Connection Status"
                items={CONNECTION_STATUSES_LABELS}
                selectedItems={selectedConnectionStatuses}
                toggleItem={
                  handleToggleConnectionStatuses as (item: string) => void
                }
              />
            </FilterWithCheckboxesWrapper>
            <ChipsWrapper data-testid="chips-wrapper">
              {selectedPhysicians.map(({id, label}) => (
                <ChipItem
                  key={id}
                  id={id}
                  label={label}
                  removeSelectedPhysician={removeSelectedPhysician}
                />
              ))}
              {selectedConnectionStatuses.map(
                (selectedStatus: CONNECTION_STATUSES) => (
                  <ChipItemConnection
                    key={selectedStatus}
                    selectedStatus={selectedStatus}
                    handleToggleConnectionStatuses={
                      handleToggleConnectionStatuses
                    }
                  />
                )
              )}
            </ChipsWrapper>
          </FilterWrapper>
          <StyledTable
            table={table}
            isHighlightRow={isHighlightRow}
            testId="enrolled-patients"
            placeholderComponent={placeholderComponent}
            isHeaderSticky
          />
        </>
      ) : null}
    </PatientsPageWrapper>
  );
};

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const AutocompleteWrapper = styled.div`
  min-width: 222px;
`;

const FilterWithCheckboxesWrapper = styled.div`
  min-width: 175px;
`;

interface ChipItemConnectionProps {
  selectedStatus: CONNECTION_STATUSES;
  handleToggleConnectionStatuses: (val: CONNECTION_STATUSES) => void;
}

function ChipItemConnection({
  selectedStatus,
  handleToggleConnectionStatuses,
}: Readonly<ChipItemConnectionProps>): JSX.Element {
  const onDelete = useCallback(
    () => handleToggleConnectionStatuses(selectedStatus),
    [handleToggleConnectionStatuses, selectedStatus]
  );
  const handleOnMouseDown = useCallback(event => event.stopPropagation(), []);

  return (
    <Chip
      key={selectedStatus}
      label={CONNECTION_STATUSES_LABELS[selectedStatus]}
      deleteIcon={<CancelIcon onMouseDown={handleOnMouseDown} />}
      onDelete={onDelete}
      data-testid="DeleteIcon"
    />
  );
}

interface ChipItemProps {
  id: never;
  label: never;
  removeSelectedPhysician: (id: any) => void;
}

function ChipItem({
  id,
  label,
  removeSelectedPhysician,
}: Readonly<ChipItemProps>): JSX.Element {
  const onDelete = useCallback(
    () => removeSelectedPhysician(id),
    [id, removeSelectedPhysician]
  );
  const handleOnMouseDown = useCallback(event => event.stopPropagation(), []);

  return (
    <Chip
      key={id}
      label={label}
      deleteIcon={<CancelIcon onMouseDown={handleOnMouseDown} />}
      onDelete={onDelete}
    />
  );
}
