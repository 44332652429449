import {atom} from 'recoil';

export type MedicalFileObject = {
  original_file_name: string;
  content_type: string;
  health_record: boolean;
  file_path: string;
};
export const selectedMedicalFileAtom = atom<MedicalFileObject>({
  key: 'selectedMedicalFileAtom',
  default: {
    original_file_name: '',
    content_type: '',
    health_record: false,
    file_path: '',
  },
});
