import React, {FC} from 'react';
import {Typography} from '@material-ui/core';
import {Variant} from '@material-ui/core/styles/createTypography';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

import {ConnectedPatientStatusLabel} from './connectedPatientStatusLabel';

type Props = {
  title: string;
  variant?: Variant | 'inherit';
  status?: string;
};

export const PatientPageHeaderTitle: FC<Props> = ({
  title,
  status,
  variant = 'h4',
}) => (
  <PageHeaderTitleWrapper>
    <PatientNameWrapper title={title} variant={variant} noWrap>
      {title}
    </PatientNameWrapper>

    <ConnectedPatientStatusLabel status={status} />
  </PageHeaderTitleWrapper>
);

const PageHeaderTitleWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const PatientNameWrapper = styled(Typography)`
  font-weight: 700;
  color: ${colors.black};
  display: inline-block;
  margin-right: 12px;
  max-width: 60%;
  overflow: hidden;
  text-overflow: 'ellipsis';
`;
