import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Chip} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {ChipsWrapper} from '../../../shared/components/chip';
import {FilterWithCheckboxes} from '../../../shared/components/filterWithCheckboxes';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SmthWentWrongPlaceholder} from '../../../shared/components/smthWentWrongPlaceholder';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {DownloadRecordsIcon} from '../../../shared/icons/downloadRecordsIcon';
import {UploadRecordsIcon} from '../../../shared/icons/uploadRecordsIcon';
import {getMedicalDocumentsTableCells} from '../constants/medicalDocumentsTableConfig';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {MedicalDocument, SortingDirections} from '../constants/types';
import {useDownloadFiles} from '../hooks/useDownloadFiles';
import {useFilterWithCheckboxes} from '../hooks/useFilterWithCheckboxes';
import {useMedicalDocuments} from '../hooks/useMedicalDocuments';
import {getTotalPages} from '../utils/getTotalPages';

import {usePagination} from './table/hooks/usePagination';
import {PreviewFileModalWindow} from './previewFileModalWindow';

const STATUSES_FILTER: {[k: string]: string} = {
  CCDA: 'CCDA',
  DICOM: 'DICOM',
  IMAGE: 'JPEG / JPG',
  PDF: 'PDF',
  PNG: 'PNG',
  ZIP: 'ZIP',
  BIN: 'No File Type',
};

type Props = {
  testId: string;
  isAIChat?: boolean;
  onSelect?: any;
};

export const MedicalDocumentsTab: FC<Props> = ({
  testId,
  isAIChat = false,
  onSelect,
}) => {
  const {patientUuid} = useParams<{patientUuid: string}>();
  const history = useHistory();
  const [rowSelection, setRowSelection] = useState({});
  const {onDownloadFiles} = useDownloadFiles();
  const [selectedItems, toggleItem] = useFilterWithCheckboxes();
  const [isDeletingLastItemForNoDataCase, setIsDeletingLastItemForNoDataCase] =
    useState<boolean>(false);

  const {setPagination, pagination, resetPagination, nextPage} =
    usePagination();

  const [previewModalWindowData, setPreviewModalWindowData] = useState<{
    isOpen: boolean;
    data: MedicalDocument | null;
  }>({
    isOpen: false,
    data: null,
  });
  const [sortingByUploadedOn, setSortingByUploadedOn] =
    useState<SortingDirections>('DESC');
  const {
    data: {files: medicalDocuments, count: countOfPages},
    isLoading: medicalDocumentsIsLoading,
    isFetching: medicalDocumentsIsFetching,
    refetch: refetchMedicalDocuments,
    isError: medicalDocumentsIsError,
  } = useMedicalDocuments(
    patientUuid,
    selectedItems,
    sortingByUploadedOn,
    pagination,
    nextPage
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(
        Object.keys(rowSelection).map(key => medicalDocuments[Number(key)])
      );
    }
  }, [medicalDocuments, onSelect, rowSelection]);

  const onOpenPreviewFileModalWindow = useCallback(
    (file: MedicalDocument) => {
      setPreviewModalWindowData(prevState => ({
        ...prevState,
        isOpen: true,
        data: file,
      }));
    },
    [setPreviewModalWindowData]
  );

  const onClosePreviewFileModalWindow = useCallback(() => {
    setPreviewModalWindowData(prevState => ({
      ...prevState,
      isOpen: false,
    }));
  }, [setPreviewModalWindowData]);

  const onSortByUploadedOn = useCallback(() => {
    setSortingByUploadedOn(prevState =>
      prevState === 'DESC' ? 'ASC' : 'DESC'
    );
  }, []);

  const columns = useMemo(
    () =>
      getMedicalDocumentsTableCells(
        onSortByUploadedOn,
        sortingByUploadedOn,
        onOpenPreviewFileModalWindow,
        onDownloadFiles,
        isAIChat
      ),
    [
      onSortByUploadedOn,
      sortingByUploadedOn,
      onOpenPreviewFileModalWindow,
      onDownloadFiles,
      isAIChat,
    ]
  );

  const table = useReactTable<MedicalDocument>({
    data: medicalDocuments,
    columns,
    pageCount: getTotalPages(countOfPages),
    state: {
      rowSelection,
      pagination,
    },
    enableMultiRowSelection: !isAIChat,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const onToggleFileType = useCallback(
    (item: string) => {
      setIsDeletingLastItemForNoDataCase(
        selectedItems.length === 1 && selectedItems.includes(item)
      );
      toggleItem(item);
      resetPagination();
    },
    [resetPagination, selectedItems, toggleItem]
  );

  const isNoDataFound =
    medicalDocuments.length === 0 &&
    !medicalDocumentsIsLoading &&
    selectedItems.length !== 0;

  const isNoDataFoundForLastDeletedFilterItem =
    medicalDocuments.length === 0 &&
    selectedItems.length === 0 &&
    isDeletingLastItemForNoDataCase;

  const isNoAnyDocumentYet =
    medicalDocuments.length === 0 && !medicalDocumentsIsLoading;

  const filesForDownload = useMemo(
    () => Object.keys(rowSelection).map(key => medicalDocuments[Number(key)]),
    [rowSelection, medicalDocuments]
  );

  const tablePlaceholder = useMemo(() => {
    if (isNoDataFound || isNoDataFoundForLastDeletedFilterItem) {
      return (
        <NoDataPlaceholderWrapper>
          <NoDataPlaceholder noDataText="No data found" />
        </NoDataPlaceholderWrapper>
      );
    }

    if (isNoAnyDocumentYet) {
      return (
        <NoDataPlaceholderWrapper>
          <NoDataPlaceholder noDataText="There are no Medical Documents uploaded yet" />
        </NoDataPlaceholderWrapper>
      );
    }

    return null;
  }, [
    isNoDataFound,
    isNoDataFoundForLastDeletedFilterItem,
    isNoAnyDocumentYet,
  ]);

  useEffect(() => {
    table.resetRowSelection();
  }, [selectedItems, sortingByUploadedOn, table, patientUuid, pagination]);

  return (
    <TabWrapper data-testid={testId}>
      <LoadingBar loading={medicalDocumentsIsFetching} />

      {medicalDocumentsIsError ? (
        <CenteringErrorPlaceholder>
          <SmthWentWrongPlaceholder
            labelText="Something went wrong during request processing. Please retry."
            onClick={refetchMedicalDocuments}
          />
        </CenteringErrorPlaceholder>
      ) : null}

      {!medicalDocumentsIsLoading && !medicalDocumentsIsError ? (
        <>
          <TitleWrapper>
            <SelectFileTypeWrapper>
              <FilterWithCheckboxes
                testId="file-type"
                id="file-type"
                disabled={medicalDocumentsIsLoading || isNoAnyDocumentYet}
                items={STATUSES_FILTER}
                selectedItems={selectedItems}
                toggleItem={onToggleFileType}
                label="File Type"
              />
              <ChipsWrapper>
                {selectedItems.map(item => (
                  <Chip
                    key={item}
                    label={STATUSES_FILTER[item]}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={event => event.stopPropagation()}
                      />
                    }
                    onDelete={() => onToggleFileType(item)}
                  />
                ))}
              </ChipsWrapper>
            </SelectFileTypeWrapper>
            {!isAIChat && (
              <div>
                <Button
                  testID="download-files"
                  variant="contained"
                  aria-haspopup="true"
                  onClick={() => {
                    table.resetRowSelection();
                    onDownloadFiles(filesForDownload);
                  }}
                  disabled={filesForDownload.length === 0}
                  startIcon={<DownloadRecordsIcon />}
                >
                  Download selected
                </Button>

                <Button
                  variant="outlined"
                  aria-haspopup="true"
                  onClick={() =>
                    history.push(
                      PORTAL_PATIENTS_ROUTES.UPLOAD_MEDICAL_RECORDS_DATA.replace(
                        ':patientUuid',
                        patientUuid
                      )
                    )
                  }
                  startIcon={<UploadRecordsIcon />}
                >
                  Upload Records
                </Button>
              </div>
            )}
          </TitleWrapper>
          <TableWrapper>
            <StyledTable
              table={table}
              testId="medical-documents"
              placeholderComponent={tablePlaceholder}
              withPagination
            />
          </TableWrapper>
        </>
      ) : null}

      {previewModalWindowData.isOpen && previewModalWindowData.data ? (
        <PreviewFileModalWindow
          onClose={onClosePreviewFileModalWindow}
          gcpFileName={previewModalWindowData.data.uploaded_file_name}
          fileContentType={previewModalWindowData.data.file_type}
          onDownloadFile={() =>
            previewModalWindowData?.data
              ? onDownloadFiles([previewModalWindowData.data])
              : null
          }
          fileName={previewModalWindowData.data.file_name}
        />
      ) : null}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  position: relative;
`;

const TitleWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  margin: 20px 0 24px 0;
`;

const SelectFileTypeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const NoDataPlaceholderWrapper = styled.div`
  margin-bottom: 80px;
`;

const CenteringErrorPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 100px 0;
`;
