import {formatHours} from '../../../shared/utils/formatter';
import {POLICY_TYPES} from '../constants/defaultPolicy';

export const policyDataFormatter = (
  rowData: number | boolean | string
): string => {
  if (typeof rowData === 'number') {
    return formatHours(rowData);
  }
  if (typeof rowData === 'boolean') {
    return rowData ? 'Yes' : 'No';
  }
  return rowData;
};

const EntryPolicyTitleMap = {
  vaccine_required: 'COVID-19 vaccination',
  maximum_self_assessment_age: 'Self check frequency',
  mask_required: 'Mask',
  temperature_check_required: 'Temperature check',
  social_distancing_required: 'Social distancing',
  hand_sanitizer: 'Hand sanitizer',
};

const RegistrationPolicyTitleMap = {
  patient_medical_record_number_required: 'MRN',
  patient_full_name_required: 'Full Name',
  patient_address_required: 'Address',
  patient_birth_date_required: 'Date of Birth',
  patient_email_required: 'Email',
  patient_phone_number_required: 'Phone Number',
  patient_gender_required: 'Gender',
  patient_insurance_info_required: 'Insurance',
};

export const policyTitleMap = {
  [POLICY_TYPES.ENTRY]: EntryPolicyTitleMap,
  [POLICY_TYPES.REGISTRATION]: RegistrationPolicyTitleMap,
};
