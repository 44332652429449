import React from 'react';
import {SvgIcon, SvgIconTypeMap} from '@material-ui/core';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';

import {colors} from '../styles/theme';

interface LockIconSvgType extends OverridableComponent<SvgIconTypeMap> {
  color?: string;
}

export const LockIcon: React.FC<LockIconSvgType> = ({
  color = colors.primary,
  ...props
}) => (
  <SvgIcon {...props} width="10" height="12" viewBox="0 0 10 12">
    <path
      d="M8.15789 5.48857H7.83684V3.71143C7.83684 2.96549 7.53773 2.25029 7.00562 1.72312C6.47354 1.19598 5.75208 0.9 5 0.9C4.24792 0.9 3.52646 1.19598 2.99438 1.72312C2.46227 2.25029 2.16316 2.96549 2.16316 3.71143V5.48857H1.84211C1.59254 5.48857 1.35302 5.58678 1.17627 5.76189C0.999486 5.93703 0.9 6.17477 0.9 6.42286V10.5943C0.9 10.8424 0.999486 11.0801 1.17627 11.2553C1.35302 11.4304 1.59254 11.5286 1.84211 11.5286H8.15789C8.40746 11.5286 8.64698 11.4304 8.82373 11.2553C9.00051 11.0801 9.1 10.8424 9.1 10.5943V6.42286C9.1 6.17477 9.00051 5.93703 8.82373 5.76189C8.64698 5.58678 8.40746 5.48857 8.15789 5.48857ZM2.78421 3.71143C2.78421 3.12977 3.01743 2.57175 3.43287 2.16016C3.84834 1.74855 4.41204 1.51714 5 1.51714C5.58796 1.51714 6.15166 1.74855 6.56713 2.16016C6.98257 2.57175 7.21579 3.12977 7.21579 3.71143V5.48857H2.78421V3.71143ZM8.47895 10.5943C8.47895 10.6781 8.44535 10.7587 8.38524 10.8182C8.32511 10.8778 8.24334 10.9114 8.15789 10.9114H1.84211C1.75666 10.9114 1.67489 10.8778 1.61476 10.8182C1.55465 10.7587 1.52105 10.6781 1.52105 10.5943V6.42286C1.52105 6.33905 1.55465 6.25848 1.61476 6.19893C1.6749 6.13935 1.75666 6.10571 1.84211 6.10571H8.15789C8.24334 6.10571 8.32511 6.13935 8.38524 6.19893C8.44535 6.25848 8.47895 6.33905 8.47895 6.42286V10.5943Z"
      stroke={color}
      strokeWidth="0.2"
    />
    <path d="M5.58511 7.83474C5.584 7.68149 5.52192 7.53486 5.41234 7.42669C5.30276 7.31852 5.15454 7.25754 4.99985 7.25699C4.92276 7.25615 4.84627 7.27058 4.77488 7.29942C4.70349 7.32827 4.63864 7.37094 4.58412 7.42495C4.5296 7.47896 4.48653 7.54322 4.45741 7.61394C4.4283 7.68467 4.41374 7.76044 4.41459 7.83682C4.41409 7.94994 4.4485 8.06051 4.51322 8.15371C4.57794 8.24691 4.66987 8.31827 4.77669 8.35825L4.47353 9.55128H5.52616L5.22301 8.35825C5.33016 8.31815 5.42233 8.24646 5.48708 8.15285C5.55183 8.05924 5.58604 7.94821 5.58511 7.83474Z" />
  </SvgIcon>
);
