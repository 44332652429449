import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {checkAddFormButtonDisability} from '../../../shared/utils/formikUtils';
import {MainHeader} from '../../layout/components/mainHeader';
import {POLICY_TYPES} from '../constants/defaultPolicy';
import {policyFormValidateSchema} from '../utils/policyFormValidate';

import {PolicyInputs} from './policyInputs';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      height: 'auto',
    },
  },
}));

export const AddPolicyForm = ({
  onBack,
  onNext,
  policy,
  cancelButtonText = 'Back',
}) => {
  const classes = useStyles();

  const handleSubmit = useCallback(
    values => {
      onNext(values);
    },
    [onNext]
  );

  return (
    <>
      <MainHeader
        title="Create new policy"
        description="First select policy type, then name the policy, and finally select all requirements."
      />
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={policy}
        initialErrors={{name: ''}}
        onSubmit={handleSubmit}
        validationSchema={
          policyFormValidateSchema[POLICY_TYPES[policy?.policy_type_id]]
        }
      >
        {({submitForm, values, ...props}) => (
          <Form autoComplete="off" className={classes.root}>
            <Subtitle>Select policy requirements</Subtitle>
            <PolicyInputs policies={values} />
            <ButtonContainer>
              <Button variant="outlined" onClick={onBack}>
                {cancelButtonText}
              </Button>
              <Button
                type="submit"
                onClick={submitForm}
                disabled={checkAddFormButtonDisability(props)}
              >
                Next
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

AddPolicyForm.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  policy: PropTypes.shape({
    policy_type_id: PropTypes.string,
  }),
  cancelButtonText: PropTypes.string,
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;
