import {useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {confirmViewMedicalRecordsState} from '../../../shared/atoms/confirmViewMedicalRecordsState';
import {isOpenAccessDeniedModalState} from '../../../shared/atoms/isOpenAccessDeniedModalState';
import {isPatientConnectionDataLoadingState} from '../../../shared/atoms/isPatientConnectionDataLoadingState';
import {patientDataWasNotSharedState} from '../../../shared/atoms/patientDataWasNotSharedState';
import {patientWasNotConnectedState} from '../../../shared/atoms/patientWasNotConnectedState';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {HttpResponseError} from '../../../shared/utils/httpResponseError';
import {isRoleMatched} from '../../../shared/utils/user';
import {fetchPatientConnection} from '../../inbox/utils/fetchers';
import {CONNECTION_STATUSES} from '../../portalPatients/constants/patientStatuses';

type Return = (patientUuid: string, patientName: string) => Promise<void>;

export const usePatientNameClick = (): Return => {
  const showMessage = useCustomizedSnackbar();
  const currentUser = useRecoilValue(currentUserState);
  const openConfirmModal = useSetRecoilState(confirmViewMedicalRecordsState);
  const openAccessDeniedModal = useSetRecoilState(isOpenAccessDeniedModalState);
  const setIsPatientConnectionDataLoadingState = useSetRecoilState(
    isPatientConnectionDataLoadingState
  );
  const openPatientWasNotConnectedModal = useSetRecoilState(
    patientWasNotConnectedState
  );
  const openPatientDataWasNotSharedModal = useSetRecoilState(
    patientDataWasNotSharedState
  );

  const getPatientConnectionData = async (
    patientUuid: string
  ): Promise<{
    isPatientConnected: boolean;
    isSharingMedicalRecords: boolean;
  }> => {
    if (!currentUser?.relyingParty.id) {
      throw new Error('Relying party id does not exist');
    }

    const {status, share_patient_to_provider} = await fetchPatientConnection(
      currentUser?.relyingParty.id,
      patientUuid
    );

    return {
      isPatientConnected: status === CONNECTION_STATUSES.CONNECTED,
      isSharingMedicalRecords: share_patient_to_provider,
    };
  };

  const isUserHasRightRoles = useMemo(() => {
    const isPhysicianHasRightRolesForViewRecords =
      isRoleMatched(currentUser?.roles, [
        ROLES.RELYING_PARTY_OWNER,
        ROLES.RELYING_PARTY_ADMIN,
        ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ]) && USER_TYPES.PHYSICIAN === currentUser?.type;

    const isNonPhysicianHasRightRolesForViewRecords =
      isRoleMatched(currentUser?.roles, [ROLES.RELYING_PARTY_RECORDS_VIEWER]) &&
      USER_TYPES.NON_PHYSICIAN === currentUser?.type;

    return (
      isPhysicianHasRightRolesForViewRecords ||
      isNonPhysicianHasRightRolesForViewRecords
    );
  }, [currentUser?.roles, currentUser?.type]);

  return async (patientUuid: string, patientName: string) => {
    try {
      setIsPatientConnectionDataLoadingState(true);

      const {isPatientConnected, isSharingMedicalRecords} =
        await getPatientConnectionData(patientUuid);

      const isOpenConfirmModalWindow =
        isUserHasRightRoles && isPatientConnected && isSharingMedicalRecords;

      const isOpenNoConnectedModalWindow =
        isUserHasRightRoles && !isPatientConnected;

      const isOpenNoSharedModalWindow =
        isUserHasRightRoles && isPatientConnected && !isSharingMedicalRecords;

      if (isOpenConfirmModalWindow) {
        openConfirmModal({
          isOpen: true,
          data: {
            patientUuid,
            patientName,
          },
        });
      } else if (isOpenNoConnectedModalWindow) {
        openPatientWasNotConnectedModal({
          isOpen: true,
          data: {
            patientName,
          },
        });
      } else if (isOpenNoSharedModalWindow) {
        openPatientDataWasNotSharedModal({
          isOpen: true,
          data: {
            patientName,
          },
        });
      } else {
        openAccessDeniedModal(true);
      }
    } catch (e) {
      if (e instanceof HttpResponseError && e.response.status === 403) {
        openAccessDeniedModal(true);
      } else {
        showMessage('Something went wrong. Please try again later.', 'error');
      }
    } finally {
      setIsPatientConnectionDataLoadingState(false);
    }
  };
};
