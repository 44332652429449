import React, {useCallback, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {AddButton} from '../../../shared/components/button';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {Subheader} from '../../../shared/components/subheader';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {urls} from '../../../shared/utils/urls';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';
import {DeleteModal} from '../../healthPolicies/components/deleteModal';
import {POLICY_TYPES} from '../../healthPolicies/constants/defaultPolicy';
import {getLocationsTableConfig} from '../constants/locationsTableConfig';

const formatLocationData = locations =>
  locations.map(location => ({
    id: location.id,
    name: location.name,
    policy_name: location.policy?.name,
    policy_type: POLICY_TYPES[location.policy?.policy_type_id],
    location_ehr: location.location_ehr,
    ehr_connection: location.ehr_connection,
    location_type: location.location_type,
    physical_location_type: location.physical_location_type,
  }));

export const LocationsPage = ({crumbs, title}) => {
  const history = useHistory();
  const {path} = useRouteMatch();

  const {relyingParty, roles} = useRecoilValue(currentUserState);

  const [locationToDelete, setLocationToDelete] = useState(null);
  const onClick = useCallback(
    () =>
      history.push({
        pathname: '/locations/add-location',
        state: {from: path},
      }),
    [history, path]
  );

  const closeModal = useCallback(() => setLocationToDelete(null), []);

  const {data: locations, isValidating} = useSWR(
    urls.relyingPartyLocations(relyingParty.id)
  );

  const iconMethodMap = {
    handleDetail: item =>
      history.push(`/locations/${item.id}/${item.location_type}`),
    handleEdit: item =>
      history.push({
        pathname: `/locations/${item.id}/${item.location_type}`,
        state: {from: path},
      }),
    handleDelete: item => setLocationToDelete(item),
  };

  const tableData = useMemo(
    () => formatLocationData(locations || []),
    [locations]
  );

  const table = useReactTable({
    data: tableData,
    columns: getLocationsTableConfig(iconMethodMap),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <Subheader title="Locations">
        {isUserRelyingPartyAdminOrOwner(roles) && (
          <AddButton text="Add New Location" onClick={onClick} />
        )}
      </Subheader>
      {isValidating && <LoadingBar loading />}
      {locations && locations.length > 0 && (
        <>
          <TotalText>{`Total: ${locations.length}`}</TotalText>
          <TableWrapper>
            <StyledTable isHeaderSticky table={table} />
          </TableWrapper>
        </>
      )}
      {locations && locations.length === 0 && (
        <NoDataPlaceholder noDataText="No location has been created. Set up a new location to start your management." />
      )}

      {locationToDelete && (
        <DeleteModal
          type="location"
          data={locationToDelete}
          open={!!locationToDelete}
          closeModal={closeModal}
        />
      )}
    </Container>
  );
};

LocationsPage.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const TotalText = styled.p`
  font-style: italic;
  font-size: 12px;
  margin: 24px 0 10px 40px;
`;

const TableWrapper = styled.div`
  margin: 0 60px 0 40px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow-y: auto;
  min-height: 300px;
  display: flex;
  flex-direction: column;
`;
