import {useEffect, useState} from 'react';

import {DateRangeType} from '../../../shared/components/datePicker/constants/types';
import {
  EVENTS_CATEGORY_DROPDOWN,
  filterValuesType,
  RemappedPatientsNamesType,
  RemappedPhysiciansType,
} from '../constatnts/types';

type HookReturnType = {
  filterValues: filterValuesType;
  setFilterValues: (val: filterValuesType) => void;
  handleToggleCategoryValue: (val: EVENTS_CATEGORY_DROPDOWN) => void;
  handleToggleProviderValue: (val: RemappedPhysiciansType | null) => void;
  handleTogglePatientValue: (val: RemappedPatientsNamesType[]) => void;
  handleToggleDateRange: (val: DateRangeType) => void;
  handleToggleExpiredRequests: (val: EVENTS_CATEGORY_DROPDOWN[]) => void;
  handleClearFilters: () => void;
};

export const useInboxFilters = (
  defaultFilterValues: filterValuesType,
  onChangeFilterCallback: () => void
): HookReturnType => {
  const [filterValues, setFilterValues] =
    useState<filterValuesType>(defaultFilterValues);

  const handleToggleCategoryValue = (
    selectedCategory: EVENTS_CATEGORY_DROPDOWN
  ): void => {
    if (filterValues.eventCategories.includes(selectedCategory)) {
      setFilterValues((prevState: filterValuesType) => ({
        ...prevState,
        eventCategories: prevState.eventCategories.filter(
          (category: EVENTS_CATEGORY_DROPDOWN) => category !== selectedCategory
        ),
      }));
    } else {
      setFilterValues((prevState: filterValuesType) => ({
        ...prevState,
        eventCategories: [...prevState.eventCategories, selectedCategory],
      }));
    }
  };

  const handleToggleProviderValue = (
    selectedProvider: RemappedPhysiciansType | null
  ) => {
    setFilterValues((prevState: filterValuesType) => ({
      ...prevState,
      provider: selectedProvider,
    }));
  };

  const handleTogglePatientValue = (
    selectedPatient: RemappedPatientsNamesType[]
  ): void => {
    setFilterValues((prevState: filterValuesType) => ({
      ...prevState,
      patientNames: selectedPatient,
    }));
  };

  const handleToggleDateRange = (selectedRange: DateRangeType) => {
    setFilterValues((prevState: filterValuesType) => ({
      ...prevState,
      dateRange: selectedRange,
    }));
  };

  const handleToggleExpiredRequests = (
    eventsCategories: EVENTS_CATEGORY_DROPDOWN[]
  ) => {
    setFilterValues((prevState: filterValuesType) => ({
      ...prevState,
      eventCategories: eventsCategories,
    }));
  };

  const handleClearFilters = () => {
    setFilterValues((prevState: filterValuesType) => ({
      ...prevState,
      eventCategories: [],
      provider: null,
      patientNames: [],
      dateRange: [null, null],
    }));
  };

  useEffect(() => {
    onChangeFilterCallback();
  }, [filterValues, onChangeFilterCallback]);

  return {
    filterValues,
    setFilterValues,
    handleToggleCategoryValue,
    handleToggleProviderValue,
    handleTogglePatientValue,
    handleToggleDateRange,
    handleToggleExpiredRequests,
    handleClearFilters,
  };
};
