import {USER_TYPES} from '../../../shared/constants/userTypes';

export const INITIAL_USER_PORTAL = {
  npi: null,
  userType: USER_TYPES.NON_PHYSICIAN,
  phoneNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  role: [],
};
