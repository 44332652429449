import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';

import {colors} from '../styles/theme';

const useStyles = makeStyles({
  td: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: isError => (isError ? colors.red : colors.grey801),
  },
});

export default function OverlayTableMessage({
  overlayMessage,
  isError,
  numberColumns,
}) {
  const {td} = useStyles(isError);

  return (
    <TableRow>
      <TableCell colSpan={numberColumns} className={td}>
        {overlayMessage}
      </TableCell>
    </TableRow>
  );
}

OverlayTableMessage.propTypes = {
  overlayMessage: PropTypes.string,
  isError: PropTypes.bool,
  numberColumns: PropTypes.number,
};
