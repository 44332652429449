import React from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {BaseModalWindow} from './baseModalWindow';
import {Button, CancelButton, CustomButtonProps} from './button';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose?: () => void;
  confirmBtnText?: string;
  confirmBtnVariant?: CustomButtonProps['color'];
  title?: string;
  disabled?: boolean;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  isShowCancelBtn?: boolean;
  minWidth: number;
};

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  title,
  children,
  onConfirm,
  onClose,
  disabled = false,
  confirmBtnText = 'Yes',
  confirmBtnVariant = 'primary',
  variant = 'h3',
  isShowCancelBtn = true,
  minWidth = 626,
}) => (
  <BaseModalWindow open={isOpen} onClose={onClose} minWidth={minWidth}>
    {title && <Title variant={variant}>{title}</Title>}
    {children}
    <ConfirmButtonGroup>
      {isShowCancelBtn ? <CancelButton onClick={onClose} /> : null}
      <Button color={confirmBtnVariant} onClick={onConfirm} disabled={disabled}>
        <div data-testid="confirm-btn">{confirmBtnText}</div>
      </Button>
    </ConfirmButtonGroup>
  </BaseModalWindow>
);

const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
`;

const ConfirmButtonGroup = styled.div`
  margin-top: 16px;
  text-align: right;
`;
