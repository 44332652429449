import React from 'react';

export const AIChatHistoryDeleteIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8 7C8.41421 7 8.75 7.33579 8.75 7.75V13.75C8.75 14.1642 8.41421 14.5 8 14.5C7.58579 14.5 7.25 14.1642 7.25 13.75V7.75C7.25 7.33579 7.58579 7 8 7Z"
      fill="#EB5757"
    />
    <path
      d="M12.75 7.75C12.75 7.33579 12.4142 7 12 7C11.5858 7 11.25 7.33579 11.25 7.75V13.75C11.25 14.1642 11.5858 14.5 12 14.5C12.4142 14.5 12.75 14.1642 12.75 13.75V7.75Z"
      fill="#EB5757"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C7.48122 0 6.25 1.23122 6.25 2.75V3H2.5C2.22386 3 2 3.22386 2 3.5V4C2 4.27614 2.22386 4.5 2.5 4.5H3.25V15.75C3.25 17.2688 4.48122 18.5 6 18.5H14C15.5188 18.5 16.75 17.2688 16.75 15.75V4.5H17.5C17.7761 4.5 18 4.27614 18 4V3.5C18 3.22386 17.7761 3 17.5 3H13.75V2.75C13.75 1.23122 12.5188 0 11 0H9ZM12.25 3V2.75C12.25 2.05964 11.6904 1.5 11 1.5H9C8.30964 1.5 7.75 2.05964 7.75 2.75V3H12.25ZM4.75 4.5V15.75C4.75 16.4404 5.30964 17 6 17H14C14.6904 17 15.25 16.4404 15.25 15.75V4.5H4.75Z"
      fill="#EB5757"
    />
  </svg>
);
