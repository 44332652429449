import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const INPUT_WIDTH_TYPES = {
  AUTO: 'auto',
  SMALL: 'small',
};

export const INPUT_WIDTH_SIZES = {
  auto: '100%',
  small: '222px',
  default: '464px',
};

export const AdornmentWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const TextField = props => <OutlinedInput {...props} variant="outlined" />;

export const InputField = styled(TextField)`
  width: ${props => {
    switch (props.width) {
      case INPUT_WIDTH_TYPES.AUTO:
        return INPUT_WIDTH_SIZES.auto;
      case INPUT_WIDTH_TYPES.SMALL:
        return INPUT_WIDTH_SIZES.small;
      default:
        return INPUT_WIDTH_SIZES.default;
    }
  }};
`;

InputField.propTypes = {
  width: PropTypes.oneOf(Object.values(INPUT_WIDTH_TYPES)),
};
