import React from 'react';
import {Redirect} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../atoms/authAtom';
import {routesAvailableForDefaultRedirect} from '../constants/roles';
import {isRoleMatched} from '../utils/user';

export const RedirectToFirstAvailableRoute = () => {
  const {roles} = useRecoilValue(currentUserState);

  const firstAvailableRoute = routesAvailableForDefaultRedirect.find(route =>
    isRoleMatched(roles, route.roles)
  );

  return <Redirect to={firstAvailableRoute.path} />;
};
