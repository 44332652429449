export const NetworkErrorType = {
  Timeout: 'Timeout',
  NoConnection: 'NoConnection',
  Unknown: 'Unknown',
};

export const networkErrorMessage = {
  SERVER_ERROR: 'Something went wrong. Please try again later.',
  TIMOUT_ERROR: 'Request timed out. Please try again later.',
  UNAUTHORIZED_ERROR: 'Session expired, please log in again.',
  UNKNOWN_ERROR: 'Unexpected error, please try again later.',
  NETWORK_ERROR:
    'No internet connection found. Check your connection and try again.',
};

export class NetworkError extends Error {
  constructor(
    request,
    type = NetworkErrorType.Unknown,
    message = networkErrorMessage.NETWORK_ERROR
  ) {
    super(message);
    this.name = 'NetworkError';
    this.type = type;
    this.request = request;
  }
}
