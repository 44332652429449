import React from 'react';
import {Checkbox} from '@material-ui/core';
import {CheckboxProps} from '@material-ui/core/Checkbox/Checkbox';

export const IndeterminateCheckbox: React.FC<CheckboxProps> = ({
  indeterminate,
  className = '',
  ...rest
}) => (
  <Checkbox
    color="primary"
    indeterminate={!rest.checked && indeterminate}
    className={className}
    {...rest}
  />
);
