import {getAuth} from 'firebase/auth';

export async function getTokenIfSessionNotExpired() {
  if (getAuth().currentUser) {
    const {token} = await getAuth().currentUser.getIdTokenResult();
    return token;
  }
  return null;
}

export async function getRefreshTokenIfSessionNotExpired() {
  if (getAuth().currentUser) {
    return getAuth().currentUser.refreshToken;
  }
  return null;
}
