import React from 'react';
import {FormHelperText} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HelperText = ({shrink, ...props}) => <FormHelperText {...props} />;

export const InputHelperText = styled(HelperText)`
  ${props => {
    if (props.shrink) {
      return `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }

    return '';
  }};
`;

HelperText.propTypes = {
  shrink: PropTypes.bool,
};
