/* eslint-disable indent */
import {isBoolean} from 'lodash';
import {v4 as uuid} from 'uuid';

import {FetchedImmunizations, Immunizations} from '../types/immunizationTypes';

import {formatDate, formatStringsArray} from './mappersUtils';

const immunizationStatuses: {[key: string]: string} = {
  completed: 'Completed',
  'entered-in-error': 'Entered in error',
  'not-done': 'Not done',
};

const getPrimarySourceValue = (
  primarySource?: boolean | null
): string | null => {
  if (isBoolean(primarySource)) {
    return primarySource ? 'True' : 'False';
  }

  return null;
};

export const getImmunizations = (
  immunizations?: FetchedImmunizations[]
): Immunizations[] => {
  if (!immunizations) {
    return [];
  }

  return immunizations.map(
    ({
      occurrence,
      last_updated_at,
      encounter_date,
      performers,
      reaction,
      status,
      primary_source,
      reason_codes,
      ...immunizations
    }) => ({
      ...immunizations,
      id: uuid(),
      performers: formatStringsArray(performers),
      reaction: formatStringsArray(reaction),
      primary_source: getPrimarySourceValue(primary_source),
      status: status ? immunizationStatuses[status] : null,
      occurrence: formatDate(occurrence),
      last_updated_at: formatDate(last_updated_at),
      reason_codes:
        reason_codes && Array.isArray(reason_codes)
          ? reason_codes.map(code => ({
              id: uuid(),
              ...code,
            }))
          : [],
      encounter_date: {
        start: formatDate(encounter_date?.start),
        end: formatDate(encounter_date?.end),
      },
    })
  );
};
