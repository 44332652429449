import {
  MergedPolicyType,
  PolicyTypeIdSelector,
} from '../../../shared/interfaces/policy';

const policyModel: Omit<MergedPolicyType, 'name' | 'policy_type_id'> = {
  id: 'policy-id',
  vaccine_required: 'Complete dose',
  maximum_self_assessment_age: '',
  mask_required: true,
  temperature_check_required: true,
  hand_sanitizer: true,
  social_distancing_required: true,
  patient_medical_record_number_required: true,
  patient_full_name_required: true,
  patient_address_required: true,
  patient_gender_required: true,
  patient_birth_date_required: true,
  patient_email_required: true,
  patient_phone_number_required: true,
  patient_insurance_info_required: true,
};

export const defaultPolicy: MergedPolicyType = {
  ...policyModel,
  name: '',
  policy_type_id: 'ENTRY',
};

export const defaultPatientPolicy: MergedPolicyType = {
  ...policyModel,
  name: '',
  policy_type_id: 'REGISTRATION',
};

export const PLACEHOLDER_ENTRY_POLICY = {
  vaccine_required: 'Complete dose',
  maximum_self_assessment_age: '1',
  mask_required: true,
  temperature_check_required: true,
  hand_sanitizer: true,
  social_distancing_required: true,
};

export const POLICY_TYPES: PolicyTypeIdSelector = {
  REGISTRATION: 'Registration policy',
  ENTRY: 'Entry policy',
};

export const POLICY_TYPES_DENIED_FILTER_LABEL: PolicyTypeIdSelector = {
  REGISTRATION: 'Only show denied activities',
  ENTRY: 'Only show denied access checks',
};

export const POLICY_TYPES_QR_CODE_SCAN_MESSAGE = {
  REGISTRATION: 'SCAN QR CODE\nTO CHECK-IN',
  ENTRY: 'SCAN QR CODE\nFOR ENTRY\nREQUIREMENTS',
};

export const defaultPolicyMap = {
  [POLICY_TYPES.REGISTRATION]: defaultPatientPolicy,
  [POLICY_TYPES.ENTRY]: defaultPolicy,
};
