import {useRecoilState, useSetRecoilState} from 'recoil';

import {DRAWER_DEFAULT_STATE, drawerState} from '../atoms/drawerState';
import {selectedPatientState} from '../atoms/selectedPatientState';

export const useToggleDrawer = () => {
  const setSelectedPatient = useSetRecoilState(selectedPatientState);
  const [drawer, setDrawer] = useRecoilState(drawerState);

  const toggleDrawer = (patientUuid, patientName) => {
    setDrawer(
      drawer.isOpen
        ? DRAWER_DEFAULT_STATE
        : {
            isOpen: true,
            patientUuid,
          }
    );
    setSelectedPatient({patientUuid, patientName});
  };

  return [toggleDrawer];
};
