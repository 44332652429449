import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ExitBar} from '../../../shared/components/exitBar';
import {PARTY} from '../../../shared/constants/roles';

import {UserInvitationPageIssuer} from './userInvitationPageIssuer';
import {UserInvitationPageRelyingParty} from './userInvitationPageRelyingParty';

export const UserInvitationPage = () => {
  const history = useHistory();
  const {party} = useRecoilValue(currentUserState);
  const onClick = useCallback(() => history.goBack(), [history]);
  return (
    <Container>
      <ExitBar onClick={onClick} title="Invite admin portal user" />
      {party === PARTY.RELYING_PARTY ? (
        <UserInvitationPageRelyingParty />
      ) : (
        <UserInvitationPageIssuer />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;
