import {useCallback} from 'react';

import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {errorMessage, firebaseError} from '../constants/constants';

export const useMFAErrorHandling = customizedErrorMessage => {
  const showMessage = useCustomizedSnackbar();

  return useCallback(
    error => {
      switch (error.code) {
        case firebaseError.INVALID_EMAIL:
        case firebaseError.WRONG_PASSWORD:
          showMessage(errorMessage.INVALID_AUTH, 'error');
          break;
        case firebaseError.EXPIRED_CODE:
          showMessage(errorMessage.EXPIRED_CODE, 'error');
          break;
        case firebaseError.INVALID_CODE:
          showMessage(errorMessage.INVALID_CODE, 'error');
          break;
        case firebaseError.TOO_MANY_ATTEMPTS:
          showMessage(errorMessage.MFA_TOO_MANY_ATTEMPTS, 'error');
          break;
        case firebaseError.USER_NOT_FOUND:
          showMessage(errorMessage.USER_NOT_FOUND, 'error');
          break;
        case firebaseError.INTERNET_ERROR:
          showMessage(errorMessage.NETWORK_ERROR, 'error');
          break;
        default:
          showMessage(customizedErrorMessage || error.message, 'error');
          break;
      }
    },
    [customizedErrorMessage, showMessage]
  );
};
