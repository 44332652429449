import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ConfirmModal from '../../relyingPartyInvitation/components/confirmModal';

const useStyles = makeStyles({
  confirmModal: {
    textAlign: 'center',
  },
});

export const CancelModal = ({
  open,
  onConfirm,
  onCancel,
  isFromLocationsPage,
}) => {
  const classes = useStyles();

  return (
    <ConfirmModal
      open={open}
      onConfirm={onConfirm}
      onClose={onCancel}
      className={classes.confirmModal}
    >
      <Container>
        <Alert>
          {isFromLocationsPage
            ? 'Are you sure you want to return to locations list?'
            : 'Are you sure you want to return to policies and locations list?'}
        </Alert>
      </Container>
    </ConfirmModal>
  );
};

CancelModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isFromLocationsPage: PropTypes.bool,
};

const Container = styled.div`
  text-align: center;
`;

const Alert = styled.p`
  font-weight: bold;
`;
