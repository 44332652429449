import React from 'react';
import {Box, Typography} from '@material-ui/core';

import {NoDataDash} from '../../../shared/components/noDataDash';
import {StyledPopover} from '../../../shared/components/styledPopover';
import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {dateFormat} from '../../../shared/constants/dateFormat';
import {CommentIcon} from '../../../shared/icons/commentIcon';
import {formatUTCDate} from '../../../shared/utils/formatDate';

import {
  EVENTS_CATEGORY,
  ProviderInboxEventsType,
  TABLE_EVENT_ENUM,
  WordingsRequestRecordsType,
} from './types';

export const WORDINGS_REQUEST_RECORDS_TYPE: WordingsRequestRecordsType = {
  images: {
    title: 'Request for Images',
    description:
      'Please determine if any images are available for this patient.',
    formTitle: 'Please select whether you have medical images for this patient',
    isRecordsCheckBoxTitle: 'I have medical images for this patient',
    isRecordsCheckBoxDescription:
      'The images you upload will be securely shared with the patient',
    noRecordsCheckBoxTitle: 'I do not have medical images for this patient',
    noRecordsCheckBoxDescription:
      'The patient will be notified that there are no medical images available at this time',
  },
  'text-records': {
    title: 'Request for Medical Records',
    description:
      'Please determine if any medical records are available for this patient.',
    formTitle:
      'Please select whether you have medical records for this patient',
    isRecordsCheckBoxTitle: 'I have medical records for this patient',
    isRecordsCheckBoxDescription:
      'The records you upload will be securely shared with the patient',
    noRecordsCheckBoxTitle: 'I do not have medical records for this patient',
    noRecordsCheckBoxDescription:
      'The patient will be notified that there are no medical records available at this time',
  },
  'medical-records': {
    title: 'Patient Medical Record Request',
    description:
      'Patient is requesting all their medical records. Please check the medical record availabiity for this patient in your EHR\u2032s.',
    formTitle:
      'Please select whether you have medical records for this patient',
    isRecordsCheckBoxTitle: 'I have medical records for this patient',
    isRecordsCheckBoxDescription:
      'The medical records you upload will be securely shared with the patient',
    noRecordsCheckBoxTitle: 'I do not have medical records for this patient',
    noRecordsCheckBoxDescription:
      'The patient will be notified that there are no medical records available at this time',
  },
};

const getEventCommentPopover = (id: string, comment?: string | null) => {
  if (comment) {
    return (
      <StyledPopover
        id={id}
        content={
          <Box maxWidth={300}>
            <Box component="p" margin={0}>
              <b>Patient&apos;s Note:</b>
            </Box>
            <Box component="p" marginTop={1} marginBottom={0}>
              {comment}
            </Box>
          </Box>
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ActionTableButton
          title="Patient comment"
          color="primary"
          testId={`patient-comment-${id}`}
        >
          <CommentIcon />
        </ActionTableButton>
      </StyledPopover>
    );
  }

  return null;
};

export const getEventDescription = ({
  event_category,
  event_details,
  event_entity_type,
}: ProviderInboxEventsType): string | JSX.Element => {
  const dateFrom = event_details?.date_from;
  const dateTo = event_details?.date_to;

  const getMedicalRecordEventDescription = (
    dateFrom?: Date | null,
    dateTo?: Date | null
  ) => (
    <Box display="flex" alignItems="center">
      {dateFrom && dateTo ? (
        <Typography>
          <b>Medical Records</b> requested from{' '}
          <b>{formatUTCDate(dateFrom, dateFormat)}</b> to{' '}
          <b>{formatUTCDate(dateTo, dateFormat)}</b>.
        </Typography>
      ) : (
        <NoDataDash />
      )}
    </Box>
  );

  const getImageRecordEventDescription = (
    dateFrom?: Date | null,
    dateTo?: Date | null
  ) => (
    <Box display="flex" alignItems="center">
      {dateFrom && dateTo ? (
        <Typography>
          <b>Images</b> requested from{' '}
          <b>{formatUTCDate(dateFrom, dateFormat)}</b> to{' '}
          <b>{formatUTCDate(dateTo, dateFormat)}</b>.
        </Typography>
      ) : (
        <NoDataDash />
      )}
    </Box>
  );

  switch (event_category) {
    case EVENTS_CATEGORY.PATIENT_DENIED_CONNECTION_REQUEST:
      return 'Patient received connection request but chose to decline it.';
    case EVENTS_CATEGORY.PATIENT_CREATE_CONNECTION_REQUEST_EXPIRED:
      return 'The Connection Request for the patient has expired.';
    case EVENTS_CATEGORY.PATIENT_INVITATION_EXPIRED:
      return 'Invitation to connect with this patient has expired.';
    case EVENTS_CATEGORY.PATIENT_DISCONNECTED:
      return 'Patient disconnected from provider.';
    case EVENTS_CATEGORY.PATIENT_DENIED_SHARE_REQUEST:
      return 'Patient declined request to share medical record.';
    case EVENTS_CATEGORY.PATIENT_CHANGE_CONNECTION_REQUEST_EXPIRED:
      return 'The Records Sharing Request for the patient has expired.';
    case EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPLOAD:
      return <NoDataDash />;
    case EVENTS_CATEGORY.PATIENT_REQUESTED_DOCUMENT_UPDATE:
      return <NoDataDash />;
    case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPDATE:
    case EVENTS_CATEGORY.PATIENT_REQUESTED_TEXT_DOCUMENT_UPLOAD:
      return getMedicalRecordEventDescription(dateFrom, dateTo);
    case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPLOAD:
    case EVENTS_CATEGORY.PATIENT_REQUESTED_IMAGE_DOCUMENT_UPDATE:
      return getImageRecordEventDescription(dateFrom, dateTo);
    case EVENTS_CATEGORY.PATIENT_RECORD_UPDATE_RECEIVED:
      return event_entity_type === TABLE_EVENT_ENUM.RELYING_PARTY
        ? 'Records received from provider'
        : 'Provider responded No Records available';
    default:
      return <NoDataDash />;
  }
};

export const getPatientFullNameWithComment = ({
  id,
  patient_first_name,
  patient_last_name,
  event_details,
}: ProviderInboxEventsType): JSX.Element => {
  const comment = event_details?.patient_comment ? (
    <Box
      component="span"
      marginLeft="8px"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
    >
      {getEventCommentPopover(id, event_details?.patient_comment)}
    </Box>
  ) : null;

  return (
    <>
      <Box component="span" display="inline-block">
        {patient_first_name}
      </Box>
      &nbsp;
      <Box component="span" display="inline-flex" alignItems="center">
        {patient_last_name}
        {comment}
      </Box>
    </>
  );
};
