import React, {FC, ReactNode} from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {Subheader} from '../../../shared/components/subheader';
import {colors} from '../../../shared/styles/theme';

type Props = {
  title: string;
  isLoading: boolean;
  onSave: (val: File | null) => Promise<void>;
  selectedFile: File | null;
  setPreviewUrlIssuerLogo: (val: string) => void;
  setSelectedFile: (val: null | File) => void;
  children: ReactNode;
};

export const IssuerSettingsWrapper: FC<Props> = ({
  title,
  isLoading,
  onSave,
  selectedFile,
  setPreviewUrlIssuerLogo,
  setSelectedFile,
  children,
}) => {
  const handleSave = async () => {
    await onSave(selectedFile);
    setPreviewUrlIssuerLogo('');
    setSelectedFile(null);
  };

  return (
    <Container>
      <BreadCrumbsWrapper>
        <Breadcrumbs aria-label="breadcrumb" separator={null}>
          <Title>{title}</Title>
        </Breadcrumbs>
      </BreadCrumbsWrapper>
      <LoadingBar loading={isLoading} />
      <Subheader title={title}>
        <Button
          position="section"
          disabled={!selectedFile || isLoading}
          onClick={handleSave}
          testID="save-changes-btn"
        >
          Save Changes
        </Button>
      </Subheader>
      <PageBodyWrapper>{children}</PageBodyWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const BreadCrumbsWrapper = styled.div`
  background-color: ${colors.grey40};
  padding-left: 40px;
  height: auto;
`;

const Title = styled.p`
  font-weight: bold;
`;

const PageBodyWrapper = styled.div`
  margin: 16px 60px 0 40px;
`;
