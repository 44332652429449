import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const AIChatHistoryBackArrow = props => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    style={{height: '24px', width: '24px'}}
  >
    <path
      d="M8.33008 12.0001L14.0051 6.3501L15.0551 7.4001L10.4551 12.0001L15.0551 16.6001L14.0051 17.6501L8.33008 12.0001Z"
      fill="black"
    />
  </SvgIcon>
);
