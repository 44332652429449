import {useField} from 'formik';

const shouldShowErrorMessage = meta => {
  if (!meta.touched) {
    return false;
  }

  return (
    !!(meta.value && !meta.touched && meta.error) ||
    !!(meta.touched && meta.error)
  );
};

export default function useInputFieldFormik(props) {
  const [field, meta, helpers] = useField(props);

  if (!meta.error && shouldShowErrorMessage(meta)) {
    helpers.setError(true);
  }

  return {field, meta, helpers};
}
