import React from 'react';
import {makeStyles} from '@material-ui/styles';

import {colors} from '../styles/theme';

type Props = {
  text: string;
};

const useTagStyles = makeStyles(() => ({
  tag: {
    backgroundColor: colors.backgroundPrimary,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '16px',
    padding: '4px 12px',
  },
}));
export const Tag: React.FC<Props> = ({text}) => {
  const styles = useTagStyles();

  return <div className={styles.tag}>{text}</div>;
};
