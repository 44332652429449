import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';

import {NotFoundPage} from '../notFound';

import {ISSUER_SETTINGS_ROUTES} from './constants/issuerSettingsRoutes';
import {UploadLogoPage} from './pages/uploadLogoPage';

const routes = [
  {
    path: ISSUER_SETTINGS_ROUTES.UPLOAD_LOGO,
    exact: true,
    component: UploadLogoPage,
    title: 'Issuer Settings',
  },

  {
    path: '*',
    component: NotFoundPage,
  },
];

export const IssuerSettingsRoutes: FC = () => (
  <Switch>
    {routes.map(route => {
      const ContentComponent = route.component;

      return (
        <Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          render={() => <ContentComponent title={route.title ?? ''} />}
        />
      );
    })}
  </Switch>
);
