import {ROLES} from '../../../shared/constants/roles';
import {
  isUserIssuerAdminOrOwner,
  isUserRelyingPartyAdminOrOwner,
} from '../../../shared/utils/user';

export const getPermissionRoles = roles => {
  if (isUserIssuerAdminOrOwner(roles)) {
    return [ROLES.ISSUER_ADMIN, ROLES.ISSUER_SUPPORT_AGENT];
  }
  if (isUserRelyingPartyAdminOrOwner(roles)) {
    return [
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ];
  }
  return null;
};
