export const extractUserFromUserCredentials = claims => ({
  email: claims.email,
  name: claims.name,
  roles: claims.roles,
  party: claims.party,
  relyingParty: {
    id: claims.party_id,
    name: claims.party_name,
  },
});

export const extractUserFromSelfRequest = user => ({
  id: user.id,
  email: user.email,
  firstName: user.first_name,
  lastName: user.last_name,
  type: user.type,
  name: `${user.first_name} ${user.last_name}`,
  roles: user.roles,
  party: user.party,
  relyingParty: {
    id: user.party_id,
    name: user.party_name,
    providerType: user.provider_type,
    npi: user.party_npi,
  },
});
