import React, {FC} from 'react';
import {Theme} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import {ChevronLeftIcon} from '../../../icons/chevronLeftIcon';
import {ChevronRightIcon} from '../../../icons/chevronRightIcon';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    height: '36px',
    width: '36px',
    minWidth: 'unset',
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.primary.main,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))(Button);

type Props = {
  onClick: VoidFunction;
  disabled?: boolean;
};

export const PreviousButton: FC<Props> = ({onClick, disabled}) => (
  <StyledButton onClick={onClick} data-testid="prev-btn" disabled={disabled}>
    <ChevronLeftIcon />
  </StyledButton>
);

export const NextButton: FC<Props> = ({onClick, disabled}) => (
  <StyledButton onClick={onClick} data-testid="next-btn" disabled={disabled}>
    <ChevronRightIcon />
  </StyledButton>
);
