import React, {useEffect, useRef, useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';

type OverflowTipStaticStyleProps = Pick<
  React.CSSProperties,
  'maxWidth' | 'whiteSpace' | 'overflow'
>;
interface OverflowTipProps
  extends OverflowTipStaticStyleProps,
    React.HTMLAttributes<HTMLDivElement> {
  tipValue?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
  value?: string;
  bold?: boolean;
}

const OverflowTip: React.FC<OverflowTipProps> = ({
  tipValue = '',
  value = '',
  maxWidth,
  whiteSpace = 'nowrap',
  overflow = 'hidden',
  bold = false,
  style,
  ...props
}) => {
  const textElementRef = useRef<any>();
  const [hoverStatus, setHoverStatus] = useState(false);

  const compareSize = () => {
    const compare =
      textElementRef?.current?.scrollWidth >
      textElementRef?.current?.clientWidth;
    setHoverStatus(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  return (
    <Tooltip title={tipValue} interactive disableHoverListener={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          maxWidth,
          whiteSpace,
          overflow,
          textOverflow: 'ellipsis',
          fontWeight: bold ? 'bold' : undefined,
          ...style,
        }}
        {...props}
      >
        {value}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
