import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionTableButton} from '../../../../shared/components/table/actionTableButton';
import {PenIcon} from '../../../../shared/icons/penIcon';

type Props = {
  userId: string;
  hasPermission: boolean;
};

export const PortalUserEditButton = ({
  userId,
  hasPermission,
}: Props): JSX.Element | null => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: `/portal-users/${userId}/profile`,
      state: {startEditing: true},
    });
  }, [history, userId]);

  return hasPermission ? (
    <ActionTableButton
      title="Edit"
      onClick={handleClick}
      testId={`${userId}-portal-user-edit-btn`}
    >
      <PenIcon />
    </ActionTableButton>
  ) : null;
};
