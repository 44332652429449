import React from 'react';
import {Divider, Typography} from '@material-ui/core';
import styled from 'styled-components';

import {NoDataPlaceholderDrawer} from '../../../shared/components/noDataPlaceholderDrawer';
import {CustomizedCard} from '../../../shared/customizedCard';
import {
  CardsTitles,
  InsuranceType,
  ReturnDocumentType,
} from '../constants/types';
import {noDocuments} from '../utils/noDocuments';

import {InsurancesProps} from './insuranceCardsDrawer';
import {InsuranceVerificationSmallDrawer} from './insuranceVerificationSmallDrawer';
import {PatientDocumentContentSmallSizeDrawer} from './patientDocumentContentSmallSizeDrawer';

interface InsuranceCardProps {
  name: string;
  testId: string;
  insurance: ReturnDocumentType[];
  insuranceInfo: InsuranceType;
  handleClick: () => void;
  refetchDocuments: () => Promise<any>;
  horizontal: boolean;
  isSecondaryInsurance?: boolean;
}
export const InsuranceCard: React.FC<InsuranceCardProps> = ({
  name,
  testId,
  insurance,
  insuranceInfo,
  handleClick,
  refetchDocuments,
  horizontal,
  isSecondaryInsurance = false,
}) => (
  <InsuranceCardDiv horizontal={horizontal}>
    <Title>
      <strong>{name}</strong>
    </Title>
    {!noDocuments(insurance) ? (
      <CustomizedCard testId={testId} fixedSize={horizontal}>
        <PatientDocumentContentSmallSizeDrawer
          title={
            insuranceInfo?.health_insurance_provider ??
            CardsTitles.NO_INSURANCE_TITLE
          }
          patientDocumentsInfo={insurance}
          handleClick={handleClick}
        />
        <CustomDivider />
        <InsuranceVerificationSmallDrawer
          patientDocumentsInfo={insurance}
          refetchDocuments={refetchDocuments}
          isSecondaryInsurance={isSecondaryInsurance}
        />
      </CustomizedCard>
    ) : (
      <CustomizedCard fixedSize={horizontal}>
        <NoDataPlaceholderDrawer noDataText={`${name} is not available`} />
      </CustomizedCard>
    )}
  </InsuranceCardDiv>
);

const InsuranceCardDiv = styled.div<InsurancesProps>`
  display: flex;
  flex-direction: column;
  ${props => (props.horizontal ? 'margin-right: 24px' : ' ')};
`;

const Title = styled(Typography)`
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const CustomDivider = styled(Divider)`
  margin: 16px 0;
`;
