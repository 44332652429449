import {useState} from 'react';
import {useSetRecoilState} from 'recoil';

import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {ProviderInboxEventsType} from '../../inbox/constatnts/types';
import {outstandingRecordRequestsModalWindowState} from '../atoms/outstandingRecordRequestsModalWindowState';
import {fetchOutstandingRecordRequests} from '../utils/fetchers';

type Return = {
  isLoading: boolean;
  handleOutstandingRecords: (
    relyingPartyId: number,
    consumerUuid: string,
    onResolveCurrentEvent: (eventId?: string) => Promise<void>
  ) => void;
};

export const useOutstandingRecords = (): Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setOutstandingRecordsModalWindow = useSetRecoilState(
    outstandingRecordRequestsModalWindowState
  );

  const handleOutstandingRecords = async (
    relyingPartyId: number,
    consumerUuid: string,
    onResolveCurrentEvent: (eventId?: string) => Promise<void>
  ) => {
    try {
      setIsLoading(true);

      const data: ProviderInboxEventsType[] =
        await fetchOutstandingRecordRequests(relyingPartyId, consumerUuid);

      if (data.length === 0) {
        await onResolveCurrentEvent();
      } else {
        setOutstandingRecordsModalWindow({
          isOpen: true,
          data,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleOutstandingRecords: wrapAsyncFunction(handleOutstandingRecords),
  };
};
