export enum PROVIDER_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
}

export const PROVIDER_TYPES_LABELS = {
  [PROVIDER_TYPES.INDIVIDUAL]: 'Individual',
  [PROVIDER_TYPES.ORGANIZATION]: 'Organization',
};

export const PROVIDER_TYPES_SEARCH_VALUES = {
  [PROVIDER_TYPES.INDIVIDUAL]: 'individuals',
  [PROVIDER_TYPES.ORGANIZATION]: 'organizations',
};

export enum USER_TYPES {
  NON_PHYSICIAN = 'NON_PHYSICIAN',
  PHYSICIAN = 'PHYSICIAN',
}

export const USER_TYPES_LABELS = {
  [USER_TYPES.NON_PHYSICIAN]: 'Non-Provider',
  [USER_TYPES.PHYSICIAN]: 'Provider',
};
