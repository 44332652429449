import React, {useCallback} from 'react';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';
import {FileForPreview, FilePreview} from '../hooks/usePatientsFiles';

import {FileViewer} from './fileViewer/fileViewer';
import {ViewerScreenError} from './fileViewer/viewerScreenError';
import {ViewerScreenLoading} from './fileViewer/viewerScreenLoading';
import {ViewerScreenMessage} from './fileViewer/viewerScreenMessage';
import {FileItem} from './fileListItem';

type Props = {
  filesForPreview: FileForPreview[];
  onSelectFileForPreview: (data: FileForPreview) => void;
  selectedFileForPreview: FileForPreview | null;
  onDeleteFile: (id: string) => void;
  onRetryUpload: (data: FileForPreview) => Promise<void>;
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewData: FilePreview;
};

export const UploadMedicalRecordsDataViewer: React.FC<Props> = ({
  filesForPreview,
  onDeleteFile,
  onSelectFileForPreview,
  onRetryUpload,
  selectedFileForPreview,
  filePreviewIsLoading,
  filePreviewHasError,
  filePreviewData,
}) => {
  const onRetry = useCallback(
    () => selectedFileForPreview && onRetryUpload(selectedFileForPreview),
    [onRetryUpload, selectedFileForPreview]
  );

  return (
    <UploadMedicalRecordsDataViewerWrapper>
      <FilesListWrapper>
        <FilesListWrapperOverflowWrapper>
          {filesForPreview.map(data => (
            <FilePreviewItem
              key={data.id}
              data={data}
              selectedFileForPreview={selectedFileForPreview}
              onSelectFileForPreview={onSelectFileForPreview}
              onDeleteFile={onDeleteFile}
              onRetryUpload={onRetryUpload}
            />
          ))}
        </FilesListWrapperOverflowWrapper>
      </FilesListWrapper>
      <FilesPreviewWrapper>
        {!selectedFileForPreview ? (
          <ViewerScreenMessage
            title="Secure Viewer for Personal Health Data"
            description="Select file on the left panel to preview"
          />
        ) : null}
        {selectedFileForPreview?.isUploading ? (
          <ViewerScreenLoading message="Loading File" />
        ) : null}
        {selectedFileForPreview?.hasError ? (
          <ViewerScreenError
            title="Loading Failed"
            description="Something went wrong during file loading"
            onRetry={onRetry}
          />
        ) : null}
        {selectedFileForPreview?.isUploaded ? (
          <FileViewer
            filePreviewIsLoading={filePreviewIsLoading}
            filePreviewHasError={filePreviewHasError}
            filePreviewType={filePreviewData?.type}
            filePreviewData={filePreviewData?.data}
          />
        ) : null}
      </FilesPreviewWrapper>
    </UploadMedicalRecordsDataViewerWrapper>
  );
};

const UploadMedicalRecordsDataViewerWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: 8px;
  display: flex;
  gap: 8px;
  min-height: 200px;
`;

const FilesListWrapper = styled.div`
  border: 1px solid ${colors.grey800};

  border-radius: 6px;
  width: 320px;
  overflow-y: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  & > div:last-of-type {
    margin-bottom: 0;
  }
`;

const FilesListWrapperOverflowWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding: 8px;
`;

const FilesPreviewWrapper = styled.div`
  border: 1px solid ${colors.grey800};
  border-radius: 6px;
  flex-grow: 1;
  overflow: hidden;
`;

interface FilePreviewItemProps {
  data: FileForPreview;
  selectedFileForPreview: FileForPreview | null;
  onSelectFileForPreview: (data: FileForPreview) => void;
  onDeleteFile: (id: string) => void;
  onRetryUpload: (data: FileForPreview) => Promise<void>;
}

function FilePreviewItem({
  data,
  selectedFileForPreview,
  onSelectFileForPreview,
  onDeleteFile,
  onRetryUpload,
}: Readonly<FilePreviewItemProps>): JSX.Element {
  const onSelect = useCallback(
    () => onSelectFileForPreview(data),
    [data, onSelectFileForPreview]
  );
  const onDelete = useCallback(
    () => onDeleteFile(data.id),
    [data.id, onDeleteFile]
  );
  const onRetry = useCallback(() => onRetryUpload(data), [data, onRetryUpload]);
  return (
    <FileItem
      key={data.id}
      file={data.file}
      hasError={data.hasError}
      isUploaded={data.isUploaded}
      isLoading={data.isUploading}
      isSelected={data.id === selectedFileForPreview?.id}
      onSelect={onSelect}
      onDelete={onDelete}
      onRetry={onRetry}
      testId={`files-list-${data.file.name}`}
    />
  );
}
