import {useRecoilValue} from 'recoil';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {urls} from '../../../shared/utils/urls';

export const useRelyingParties = () => {
  const currentUser = useRecoilValue(currentUserState);

  const {data, isValidating} = useSWR(
    urls.issuerRelyingPartiesSorted(currentUser.relyingParty.id)
  );

  const relyingParties = data
    ?.filter(item => !!item)
    .map(relyingParty => ({
      id: relyingParty.id,
      name: relyingParty.name,
      npi: relyingParty.npi,
      logo: `${relyingParty.logo_file}`,
      owner: `${relyingParty.relying_party_owner?.first_name} ${relyingParty.relying_party_owner?.last_name}`,
      email: relyingParty.relying_party_owner?.email,
      phone_number: formatPhoneNumber(
        relyingParty.relying_party_owner?.phone_number
      ),
      status: relyingParty.relying_party_owner?.status,
    }));
  return {relyingParties, isLoading: isValidating};
};
