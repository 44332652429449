import {atom} from 'recoil';

type PatientDataWasNotSharedState = {
  isOpen: boolean;
  data: {
    patientName: string;
  };
};

export const patientDataWasNotSharedState = atom<PatientDataWasNotSharedState>({
  key: 'patientDataWasNotShared',
  default: {
    isOpen: false,
    data: {
      patientName: '',
    },
  },
});
