export enum NPI_TYPES {
  USER_RELYING_PARTY = 'PRACTITIONER',
  ISSUER_RELYING_PARTY = 'ORGANIZATION ',
}

export enum NPI_STATUSES {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}

export enum NPI_STATES {
  ACTIVE = 'ACTIVE',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  NOT_FOUND = 'NOT_FOUND',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  EDITED = 'EDITED',
  DEFAULT = 'DEFAULT',
}
