import React from 'react';
import {Box, BoxProps} from '@material-ui/core';

import {colors} from '../styles/theme';

export const Card: React.FC<BoxProps> = ({...props}) => (
  <Box
    bgcolor={colors.grey700}
    paddingX={3}
    paddingY={2}
    marginBottom={3}
    borderRadius={8}
    {...props}
  />
);
