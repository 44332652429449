import {isString} from 'lodash';

import {dateFormatWithTime} from '../../../../../shared/constants/dateFormat';
import {formatUTCDate} from '../../../../../shared/utils/formatDate';
import {ElectronicRecordCode} from '../types/types';

export const formatDate = (
  date?: string | null,
  formatter = dateFormatWithTime
): string | null => (date ? formatUTCDate(new Date(date), formatter) : null);

export const formatStringsArray = (items?: string[] | null): string | null =>
  items && Array.isArray(items) && items.length !== 0 ? items.join(', ') : null;

export const makeStringUppercase = (string?: string | null): string | null =>
  string && isString(string)
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : null;

export const wrapCodeToArray = (
  code?: ElectronicRecordCode | null
): ElectronicRecordCode[] => (code ? [code] : []);
