import React from 'react';
import {FormControl} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  INPUT_WIDTH_SIZES,
  INPUT_WIDTH_TYPES,
  InputField,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {profileTitleMap} from '../utils/profileFormatter';

export const InformationContainer = ({title, data, children, disabled}) => (
  <>
    <TitleContainer>
      <TitleText>{title}</TitleText>
      {children || null}
    </TitleContainer>
    <InputContainer>
      {Object.keys(data).map(key => (
        <FormControl disabled variant="standard" key={key}>
          <InputLabel htmlFor={key}>{profileTitleMap[key]}</InputLabel>
          <InputField
            key={key}
            id={key}
            width="small"
            value={data[key]}
            disabled={disabled}
          />
          <InputHelperText />
        </FormControl>
      ))}
    </InputContainer>
  </>
);

InformationContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    ${INPUT_WIDTH_SIZES[INPUT_WIDTH_TYPES.SMALL]}
  );
  grid-column-gap: 20px;
  grid-row-gap: 2px;
  justify-items: stretch;
  align-items: stretch;
`;

const TitleText = styled.h6`
  font-weight: 700;
  margin: 0 12px 0 0;
  line-height: 24px;
`;
