import React, {ReactNode} from 'react';
import styled from 'styled-components';

type Props = {
  isActive: boolean;
  children: ReactNode;
};
export const RedDotWrapper: React.FC<Props> = ({children, isActive}) => (
  <Wrapper>
    {isActive ? <RedDot data-testid="red-dot" /> : null}
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const RedDot = styled.div`
  display: block;
  position: absolute;
  right: -2px;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d7373f;
`;
