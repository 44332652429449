import React, {useCallback} from 'react';
import DayjsUtils from '@date-io/dayjs';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {makeStyles} from '@material-ui/styles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {dateFormatWithTimeAndComma} from '../constants/dateFormat';
import {colors} from '../styles/theme';
import {isEndTimeInvalid, isFromTimeInvalid} from '../utils/dateTimeCheck';

const useStyles = makeStyles(() => ({
  textField: {
    '& > .MuiInputBase-root': {
      height: '34px',
      width: '240px',
    },
    '& > .MuiInputBase-input': {
      padding: '8px 10px',
    },
  },
}));

export const TimePeriod = ({
  fromTime,
  toTime,
  onFromTimeChange,
  onToTimeChange,
}) => {
  const classes = useStyles();

  const toTimeErrorCheck = isFromTimeInvalid(fromTime, toTime);
  const fromTimeErrorCheck = isEndTimeInvalid(toTime);

  const handleChangeFromTime = useCallback(
    date => {
      const fromTimeText = dayjs(date).format('YYYY-MM-DDTHH:mm');
      onFromTimeChange(fromTimeText);
    },
    [onFromTimeChange]
  );

  const handleChangeToTime = useCallback(
    date => {
      const toTimeText = dayjs(date).format('YYYY-MM-DDTHH:mm');
      onToTimeChange(toTimeText);
    },
    [onToTimeChange]
  );

  return (
    <DateTimeContainer>
      <TimePickerForm noValidate>
        <DateLabel>From</DateLabel>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <PickerContainer>
            <DateTimePicker
              id="from-datetime"
              value={fromTime}
              label="Start date & time"
              inputVariant="outlined"
              variant="inline"
              format={dateFormatWithTimeAndComma}
              className={classes.textField}
              onChange={handleChangeFromTime}
              error={toTimeErrorCheck}
              disableFuture
            />
            <ErrorMessage>
              {toTimeErrorCheck && 'Start time should be earlier than end time'}
            </ErrorMessage>
          </PickerContainer>
        </MuiPickersUtilsProvider>
      </TimePickerForm>
      <TimePickerForm noValidate>
        <DateLabel>To</DateLabel>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <PickerContainer>
            <DateTimePicker
              id="to-datetime"
              value={toTime}
              label="End date & time"
              inputVariant="outlined"
              variant="inline"
              format={dateFormatWithTimeAndComma}
              className={classes.textField}
              onChange={handleChangeToTime}
              error={fromTimeErrorCheck}
              disableFuture
            />
            <ErrorMessage>
              {fromTimeErrorCheck &&
                'End time should not be later than current time'}
            </ErrorMessage>
          </PickerContainer>
        </MuiPickersUtilsProvider>
      </TimePickerForm>
    </DateTimeContainer>
  );
};

TimePeriod.propTypes = {
  fromTime: PropTypes.string.isRequired,
  toTime: PropTypes.string.isRequired,
  onFromTimeChange: PropTypes.func.isRequired,
  onToTimeChange: PropTypes.func.isRequired,
};

const DateTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 15px;
`;

const PickerContainer = styled.div`
  position: relative;
`;

const DateLabel = styled.span`
  margin-right: 10px;
  font-weight: 600;
  width: 40px;
  text-align: right;
`;

const TimePickerForm = styled.form`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  position: absolute;
  margin: 0 0 0 3px;
  color: ${colors.red};
  max-width: 400px;
`;
