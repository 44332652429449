import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function AddIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-1 0 14 12" width="15" height="15">
      <path
        d="M4.91671 4.41732V0.917318C4.91671 0.762608 4.97817 0.614235 5.08756 0.504839C5.19696 0.395442 5.34533 0.333984 5.50004 0.333984C5.65475 0.333984 5.80312 0.395442 5.91252 0.504839C6.02192 0.614235 6.08337 0.762608 6.08337 0.917318V4.41732H9.58338C9.73809 4.41732 9.88646 4.47878 9.99585 4.58817C10.1053 4.69757 10.1667 4.84594 10.1667 5.00065C10.1667 5.15536 10.1053 5.30373 9.99585 5.41313C9.88646 5.52253 9.73809 5.58398 9.58338 5.58398H6.08337V9.08398C6.08337 9.23869 6.02192 9.38707 5.91252 9.49646C5.80312 9.60586 5.65475 9.66732 5.50004 9.66732C5.34533 9.66732 5.19696 9.60586 5.08756 9.49646C4.97817 9.38707 4.91671 9.23869 4.91671 9.08398V5.58398H1.41671C1.262 5.58398 1.11362 5.52253 1.00423 5.41313C0.894832 5.30373 0.833374 5.15536 0.833374 5.00065C0.833374 4.84594 0.894832 4.69757 1.00423 4.58817C1.11362 4.47878 1.262 4.41732 1.41671 4.41732H4.91671Z"
        fill="white"
      />
    </SvgIcon>
  );
}
