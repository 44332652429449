import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

import {
  CardsTitles,
  InsuranceType,
  OnDownloadDocumentFnType,
  ReturnDocumentType,
} from '../constants/types';
import {isSmthWentWrong} from '../utils/isSmthWentWrong';

import {InsuranceCard} from './insuranceCard';

type Props = {
  primaryInsurance: ReturnDocumentType[];
  secondaryInsurance: ReturnDocumentType[];
  setPatientDocument: (
    documents: ReturnDocumentType[],
    title: CardsTitles
  ) => void;
  refetchDocuments: () => Promise<any>;
  onDownloadDocuments: OnDownloadDocumentFnType;
  horizontal?: boolean;
};

export const InsuranceCardsDrawer: FC<Props> = ({
  primaryInsurance,
  secondaryInsurance,
  setPatientDocument,
  onDownloadDocuments,
  refetchDocuments,
  horizontal = false,
}) => {
  const handleSetDocument = useCallback(
    (document, title) => setPatientDocument(document, title),
    [setPatientDocument]
  );
  const isErrorPrimary = isSmthWentWrong(primaryInsurance);
  const isErrorSecondary = isSmthWentWrong(secondaryInsurance);

  const handleClickPrimary = useCallback(() => {
    if (isErrorPrimary) {
      refetchDocuments();
    } else {
      handleSetDocument(
        primaryInsurance,
        `${CardsTitles.PRIMARY_INSURANCE_TITLE} Details`
      );
    }
  }, [handleSetDocument, isErrorPrimary, primaryInsurance, refetchDocuments]);

  const handleClickSecondary = useCallback(() => {
    if (isErrorSecondary) {
      refetchDocuments();
    } else {
      handleSetDocument(
        secondaryInsurance,
        `${CardsTitles.SECONDARY_INSURANCE_TITLE} Details`
      );
    }
  }, [
    handleSetDocument,
    isErrorSecondary,
    refetchDocuments,
    secondaryInsurance,
  ]);

  const primaryInsuranceInfo = primaryInsurance[0].data as InsuranceType;
  const secondaryInsuranceInfo = secondaryInsurance[0].data as InsuranceType;

  return (
    <Insurances horizontal={horizontal}>
      <InsuranceCard
        name="Primary Insurance"
        testId="primary_insurance_card"
        insurance={primaryInsurance}
        insuranceInfo={primaryInsuranceInfo}
        handleClick={handleClickPrimary}
        refetchDocuments={refetchDocuments}
        horizontal={horizontal}
      />
      <InsuranceCard
        name="Secondary Insurance"
        testId="secondary_insurance_card"
        insurance={secondaryInsurance}
        insuranceInfo={secondaryInsuranceInfo}
        handleClick={handleClickSecondary}
        refetchDocuments={refetchDocuments}
        horizontal={horizontal}
        isSecondaryInsurance
      />
    </Insurances>
  );
};

export interface InsurancesProps {
  horizontal: boolean;
}

const Insurances = styled.div<InsurancesProps>`
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
`;
