import React, {FC} from 'react';
import {Avatar, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {colors} from '../../../shared/styles/theme';
import {AvatarSize, IPatientAvatar} from '../constants/types';

const useStyles = (size: string) =>
  makeStyles((theme: Theme) => ({
    avatar: {
      width: size === AvatarSize.small ? theme.spacing(8) : theme.spacing(12),
      height: size === AvatarSize.small ? theme.spacing(8) : theme.spacing(12),
      color: colors.backgroundPrimary,
      fontWeight: 700,
      fontSize: size === AvatarSize.small ? '24px' : '40px',
      lineHeight: '32px',
      backgroundColor: colors.grey40,
      border: `1px solid ${colors.backgroundPrimary}`,
    },
  }));

export const PatientAvatar: FC<IPatientAvatar> = ({
  src,
  firstName,
  lastName,
  size = AvatarSize.default,
}) => {
  const classes = useStyles(size)();

  return (
    <Avatar
      alt={`${firstName} ${lastName}`}
      src={src}
      variant="rounded"
      className={classes.avatar}
    >
      {`${firstName[0] || ''}${lastName[0] || ''}`}
    </Avatar>
  );
};
