import React from 'react';
import {Box} from '@material-ui/core';
import {ColumnDef} from '@tanstack/react-table';

import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {StyledCell} from '../../../shared/components/table/styledCell';
import {INVITATION_STATUS} from '../../../shared/constants/status';
import {ActionIcon} from '../../../shared/icons/actionIcon';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {colors} from '../../../shared/styles/theme';

type RelyingParty = {
  id: number;
  name: string;
  npi: string;
  owner: string;
  email: string;
  phone_number: string;
  status: INVITATION_STATUS;
};

type CellType = {
  row: {
    original: RelyingParty;
  };
};

type ActionMethods = {
  handleDetail: (relyingParty: RelyingParty) => void;
};

const Actions = ({
  actionMethods,
  cell,
}: {
  actionMethods: ActionMethods;
  cell: CellType;
}): JSX.Element => (
  <ActionTableButton
    title="Detail"
    testId="Detail-icon"
    onClick={() => actionMethods.handleDetail(cell.row.original)}
  >
    <ActionIcon />
  </ActionTableButton>
);

export const getRelyingPartiesTableConfig = (actionMethods: {
  handleDetail: (relyingParty: RelyingParty) => void;
}): (ColumnDef<RelyingParty> & AdditionalTableProps)[] => [
  {
    id: 'name',
    header: 'Name',
    width: '300px',
    isStickyLeft: true,
    cell({row: {original}}: CellType): JSX.Element {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            component="span"
            fontWeight={600}
            overflow="hidden"
            textOverflow="ellipsis"
            marginRight={2}
          >
            <span>{original.name}</span>
          </Box>
        </Box>
      );
    },
  },
  {
    id: 'npi',
    header: 'NPI',
    width: '140px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.npi} />;
    },
  },
  {
    id: 'owner',
    header: 'Owner',
    width: '190px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.owner} />;
    },
  },
  {
    id: 'email',
    header: 'Email',
    width: '280px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.email} />;
    },
  },
  {
    id: 'phone_number',
    header: 'Phone Number',
    width: '170px',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.phone_number} />;
    },
  },
  {
    id: 'status',
    header: 'Invitation Status',
    width: '200px',
    cell(cell: CellType): JSX.Element {
      return (
        <Box
          fontWeight={600}
          color={
            cell.row.original.status === INVITATION_STATUS.INVITED
              ? colors.statusBlue
              : colors.statusGreen
          }
        >
          {cell.row.original.status}
        </Box>
      );
    },
  },
  {
    header: 'Action',
    cell(cell: CellType): JSX.Element {
      return <Actions cell={cell} actionMethods={actionMethods} />;
    },
    width: '120px',
    minWidth: '120px',
    maxWidth: '120px',
    isStickyRight: true,
  },
];
