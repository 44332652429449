import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {MainHeader} from '../../layout/components/mainHeader';
import {PLACEHOLDER_ENTRY_POLICY} from '../constants/defaultPolicy';

import {PolicyDetail} from './policyDetail';

export const PolicyPreview = ({policy, onBack}) => {
  const history = useHistory();
  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();

  const currentUser = useRecoilValue(currentUserState);

  const handleCreatePolicy = useCallback(async () => {
    const payloadComplete =
      policy.policy_type_id === 'REGISTRATION' ? PLACEHOLDER_ENTRY_POLICY : {};
    try {
      await fetcher(urls.policies, 'POST', {
        ...policy,
        relying_party_id: currentUser.relyingParty.id,
        ...payloadComplete,
      });
      showMessage(
        `You successfully added a policy, ${policy.name}.`,
        'success'
      );
      history.push({
        pathname: '/health-policies',
        state: {policyTypeId: policy?.policy_type_id},
      });
    } catch (error) {
      handleError(error);
    }
  }, [currentUser, handleError, history, policy, showMessage]);

  return (
    <>
      <MainHeader
        title="Confirm policy"
        description="Upon clicking confirm, the following policy will be created."
      />

      <PolicyDetail policies={policy} />
      <ButtonContainer>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button type="submit" onClick={handleCreatePolicy}>
          Confirm
        </Button>
      </ButtonContainer>
    </>
  );
};

PolicyPreview.propTypes = {
  policy: PropTypes.objectOf(PropTypes.any),
  onBack: PropTypes.func,
};

const ButtonContainer = styled.p`
  display: flex;
`;
