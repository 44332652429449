import React, {useCallback} from 'react';
import {Box} from '@material-ui/core';
import {useFormikContext} from 'formik';

import {InputFormControlWithFormik} from '../../../../shared/components/form';
import {ManuallyTypedAddressForm} from '../../../../shared/components/manuallyTypedAddressForm';
import {PrefilledByZipCodeAddressForm} from '../../../../shared/components/prefilledByZipCodeAddressForm';
import {PROVIDER_TYPES} from '../../../../shared/constants/userTypes';
import {LocationAddress} from '../../../../shared/hooks/useLocationAddresses';
import {RelyingParty} from '../../store';
import {onResetPrimaryLocationAddressFiled} from '../../utils/formik';

type Props = {
  isNpiCorrect: boolean;
  addresses: LocationAddress[] | null;
  addressesIsLoading: boolean;
  addressesHaveError: boolean;
  lastSearchedZipCode: string;
  onClearLocationAddresses: () => void;
  fetchLocationAddresses: (
    providerType: PROVIDER_TYPES,
    npi: string,
    zipCode: string
  ) => Promise<LocationAddress[]>;
};

export const PrimaryLocationForm: React.FC<Props> = ({
  isNpiCorrect,
  addresses,
  addressesIsLoading,
  addressesHaveError,
  lastSearchedZipCode,
  onClearLocationAddresses,
  fetchLocationAddresses,
}) => {
  const formik = useFormikContext<RelyingParty>();

  const onSubmitZipCode = useCallback(async (): Promise<LocationAddress[]> => {
    const {
      values: {providerType, zipCodeAsync: zipCode, ownerNpi, organizationNpi},
    } = formik;

    const npi =
      providerType === PROVIDER_TYPES.ORGANIZATION ? organizationNpi : ownerNpi;

    onResetPrimaryLocationAddressFiled(formik);

    const addresses = await fetchLocationAddresses(providerType, npi, zipCode);

    return addresses;
  }, [fetchLocationAddresses, formik]);

  return (
    <>
      <Box fontWeight={700} component="p" marginTop={5}>
        Set Primary Location
      </Box>

      <InputFormControlWithFormik
        required
        id="primaryLocation"
        name="primaryLocation"
        label="Primary Location Name"
        placeholder="Enter the primary location name"
        testIdPrefix="primary-location"
        error={
          (formik.touched.primaryLocation && formik.errors.primaryLocation) ||
          ''
        }
      />

      {formik.values.primaryLocationManually ? (
        <ManuallyTypedAddressForm />
      ) : (
        <PrefilledByZipCodeAddressForm
          disabled={!isNpiCorrect}
          enterLocationManuallyLinkMessage="Enter Primary Location manually"
          addressNotFoundMessage="Sorry, but we could not find an address associated with this NPI and Zip code. Please try another Zip code or"
          addresses={addresses}
          addressesIsLoading={addressesIsLoading}
          addressesHaveError={addressesHaveError}
          onClearLocationAddresses={onClearLocationAddresses}
          lastSearchedZipCode={lastSearchedZipCode}
          onSubmitZipCode={onSubmitZipCode}
        />
      )}
    </>
  );
};
