import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const ReplaceIcon: React.FC<any> = props => (
  <SvgIcon {...props} viewBox="0 0 14 14" width="15" height="15">
    <path
      d="M13.9521 5.52982L13.7421 0.209823L12.3421 0.279822L12.4121 2.51982C10.9421 0.769823 8.77214 -0.210177 6.39214 0.0698225C4.50214 0.279822 2.82214 1.18982 1.63214 2.58982C0.442144 4.05982 -0.117856 5.87982 0.0921444 7.76982C0.302144 9.58982 1.21214 11.2698 2.68214 12.4598C3.94214 13.5098 5.48214 13.9998 7.09214 13.9998C7.30214 13.9998 7.58214 13.9998 7.79214 13.9298L7.65214 12.5298C6.18214 12.6698 4.71214 12.2498 3.52214 11.2698C2.40214 10.4298 1.63214 9.09982 1.49214 7.62982C1.35214 6.08982 1.77214 4.68982 2.75214 3.49982C3.66214 2.30982 4.99214 1.60982 6.53214 1.46982C8.84214 1.18982 11.0121 2.44982 12.0621 4.47982L8.49214 5.24982L8.77214 6.64982L13.0421 5.73982L13.9521 5.52982Z"
      fill="currentColor"
      {...props}
    />
  </SvgIcon>
);
