import {atom} from 'recoil';

type ConfirmDisconnectPatientState = {
  isOpen: boolean;
  data: {
    connectionId: number | undefined;
    patientName: string;
  };
};

export const confirmDisconnectPatientState =
  atom<ConfirmDisconnectPatientState>({
    key: 'confirmDisconnectPatient',
    default: {
      isOpen: false,
      data: {
        connectionId: undefined,
        patientName: '',
      },
    },
  });
