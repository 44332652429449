import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const DisconnectIcon: FC<any> = props => (
  <SvgIcon viewBox="5 5 20 20" width="20" height="20" {...props}>
    <path
      d="M19.8559 7.30875C20.103 6.97338 20.5884 6.89395 20.9326 7.14989H20.9414C21.1003 7.27345 21.215 7.44996 21.2415 7.65294C21.2768 7.85593 21.2238 8.05891 21.1003 8.22659L9.65368 23.7064C9.50364 23.9094 9.27418 24.0241 9.02707 24.0241C8.85939 24.0241 8.70053 23.9712 8.56815 23.8741C8.23278 23.627 8.15335 23.1416 8.40929 22.7974L19.8559 7.30875ZM8.77119 20.0795L9.72434 18.7998C8.40935 18.3762 7.44737 17.0789 7.44737 15.5168C7.44737 13.6193 8.86827 12.0837 10.6245 12.0837H14.6931L15.8315 10.5392H10.6245C8.07398 10.5304 6 12.7632 6 15.5168C6 17.5554 7.14731 19.3117 8.77119 20.0795ZM21.0032 10.5832L19.9 12.0747H20.3854C22.1329 12.0747 23.5538 13.6191 23.5538 15.5078C23.5538 17.3964 22.1329 18.9409 20.3854 18.9409H14.8166L13.6781 20.4853H20.3854C22.936 20.4853 25.0011 18.2525 25.0011 15.5078C25.0011 12.9925 23.2537 10.9009 20.9944 10.5743L21.0032 10.5832Z"
      fill="#7A7A7A"
    />
  </SvgIcon>
);
