import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionTableButton} from '../../../../shared/components/table/actionTableButton';
import {ActionIcon} from '../../../../shared/icons/actionIcon';

type Props = {
  userId: string;
  hasPermission: boolean;
};

export const PortalUserDetailsButton = ({
  userId,
  hasPermission,
}: Props): JSX.Element | null => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(`/portal-users/${userId}/profile`);
  }, [history, userId]);

  return hasPermission ? (
    <ActionTableButton
      title="Detail"
      onClick={handleClick}
      testId={`${userId}-portal-user-details-btn`}
    >
      <ActionIcon />
    </ActionTableButton>
  ) : null;
};
