/* eslint-disable indent */
import {isBoolean} from 'lodash';
import {v4 as uuid} from 'uuid';

import {getFullName} from '../../../../../shared/utils/getFullName';
import {
  CareTeam,
  CareTeamTelecom,
  FetchedCareTeam,
  FetchedCareTeamParticipant,
  FetchedCodesGroup,
  FetchedMedicalPersonEntity,
  FetchedProvider,
  FetchedTelecom,
  MedicalPersonEntity,
  Provider,
} from '../types/providerTypes';
import {ElectronicRecordCode} from '../types/types';

import {
  formatDate,
  formatStringsArray,
  makeStringUppercase,
} from './mappersUtils';

const getStatusValue = (status?: null | boolean): string | null => {
  if (isBoolean(status)) {
    return status ? 'Active' : 'Inactive';
  }

  return null;
};

const getTelecoms = (fetchedTelecoms: FetchedTelecom[]): CareTeamTelecom[] =>
  fetchedTelecoms.map(({type, use, value}) => {
    const dash = type && use ? '-' : '';
    const formattedUse = use ? makeStringUppercase(use) : '';

    return {
      id: uuid(),
      key: type
        ? `${makeStringUppercase(type)} ${dash} ${formattedUse}`.trim()
        : '',
      value,
    };
  });

const getMedicalPersonEntity = (
  fetchedMedicalPersonEntity: FetchedMedicalPersonEntity[]
): MedicalPersonEntity[] =>
  fetchedMedicalPersonEntity.map(entity => ({
    ...entity,
    id: uuid(),
    identifiers: formatStringsArray(entity.identifiers),
    name: getFullName(
      entity.name?.first_name,
      entity.name?.last_name,
      entity.name?.middle_name
    ),
    status: getStatusValue(entity.active),
    last_updated_at: formatDate(entity?.last_updated_at),
    telecoms:
      entity?.telecoms && Array.isArray(entity.telecoms)
        ? getTelecoms(entity.telecoms)
        : [],
    roles:
      entity?.roles && Array.isArray(entity?.roles)
        ? entity?.roles.map(role => ({
            ...role,
            id: uuid(),
            active: getStatusValue(role.active),
            last_updated_at: formatDate(role?.last_updated_at),
            specialties: role?.specialties
              ? role.specialties.map(specialties => ({
                  ...specialties,
                  id: uuid(),
                }))
              : [],
            codings: role?.codings
              ? role.codings.map(code => ({
                  ...code,
                  id: uuid(),
                }))
              : [],
          }))
        : [],
  }));

export const getProviders = (providers?: FetchedProvider): Provider => {
  if (!providers) {
    return {
      primary_care_providers: [],
      specialists: [],
    };
  }

  return {
    ...providers,
    primary_care_providers:
      providers?.primary_care_providers &&
      Array.isArray(providers?.primary_care_providers)
        ? getMedicalPersonEntity(providers.primary_care_providers)
        : [],
    specialists:
      providers?.specialists && Array.isArray(providers?.specialists)
        ? getMedicalPersonEntity(providers.specialists)
        : [],
  };
};

export const getCareTeams = (careTeams?: FetchedCareTeam[]): CareTeam[] => {
  if (!careTeams) {
    return [];
  }

  const getRoles = (
    role: FetchedCodesGroup
  ): {id: string; codes: ElectronicRecordCode[] | null} => ({
    ...role,
    id: uuid(),
  });

  const getParticipants = (
    participant: FetchedCareTeamParticipant
  ): {
    id: string;
    roles: {id: string; codes: ElectronicRecordCode[] | null}[];
    name: string | null;
  } => ({
    ...participant,
    id: uuid(),
    roles:
      participant?.roles && Array.isArray(participant.roles)
        ? participant.roles.map(getRoles)
        : [],
  });

  const getCategories = (category: {
    codes: ElectronicRecordCode[] | null;
  }): {id: string; codes: ElectronicRecordCode[] | null} => ({
    ...category,
    id: uuid(),
  });

  const getReasons = (
    reason: FetchedCodesGroup
  ): {id: string; codes: ElectronicRecordCode[] | null} => ({
    ...reason,
    id: uuid(),
  });

  const mapArrayWithFunction = (
    array: any[] | null,
    mapperFunction: (item: any) => any
  ): any[] => (array && Array.isArray(array) ? array.map(mapperFunction) : []);

  return careTeams.map(careTeam => ({
    ...careTeam,
    id: uuid(),
    organizations: formatStringsArray(careTeam?.organizations),
    reasons: mapArrayWithFunction(careTeam?.reasons, getReasons),
    categories: mapArrayWithFunction(careTeam?.categories, getCategories),
    participants: mapArrayWithFunction(careTeam?.participants, getParticipants),
    period: {
      start: formatDate(careTeam?.period?.start),
      end: formatDate(careTeam?.period?.end),
    },
    telecoms:
      careTeam?.telecoms && Array.isArray(careTeam.telecoms)
        ? getTelecoms(careTeam.telecoms)
        : [],
    last_updated_at: formatDate(careTeam?.last_updated_at),
  }));
};
