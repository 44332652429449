import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {PaginationState} from '@tanstack/react-table';

import {DEFAULT_PAGE_SIZE} from '../../../constants/defaultPageSize';

type HookReturnType = {
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pagination: PaginationState;
  resetPagination: () => void;
  nextPage: number;
};

export const usePagination = (): HookReturnType => {
  const [{pageIndex, pageSize}, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const resetPagination = useCallback(
    () =>
      setPagination(prevState => ({
        ...prevState,
        pageIndex: 0,
      })),
    []
  );

  const nextPage = useMemo(() => pageIndex + 1, [pageIndex]);

  return {
    setPagination,
    pagination,
    resetPagination,
    nextPage,
  };
};
