import * as yup from 'yup';

import {POLICY_TYPES} from '../constants/defaultPolicy';

export const COVIDPolicyFormValidateSchema = yup.object({
  vaccine_required: yup.string().required('Required'),
  maximum_self_assessment_age: yup
    .number()
    .typeError('Input must be a number')
    .min(0, 'Must not be less than 0 hours')
    .max(1000, 'Must be less than 1000 hours')
    .required('Required')
    .integer('Decimals are not allowed'),
});

export const choosePolicyTypeFormValidateSchema = yup.object({
  policyName: yup
    .string()
    .trim()
    .max(50, 'Please enter 1-50 characters')
    .required('Required'),
});

export const patientPolicyFormValidateSchema = yup.object({});

export const policyFormValidateSchema = {
  [POLICY_TYPES.ENTRY]: COVIDPolicyFormValidateSchema,
  [POLICY_TYPES.REGISTRATION]: patientPolicyFormValidateSchema,
};
