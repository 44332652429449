import React, {FC} from 'react';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {DateRangeType} from '../../../shared/components/datePicker/constants/types';
import {DatePicker as DateRangePicker} from '../../../shared/components/datePicker/datePicker';
import {FilterWithCheckboxes} from '../../../shared/components/filterWithCheckboxes';
import {MultipleAutocompleteWithCheckBoxes} from '../../../shared/components/multipleSelect/multipleAutocompleteWithCheckBoxes';
import {usePhysicians} from '../../portalPatients/hooks/usePhysicians';
import {CATEGORY_EVENTS_DROPDOWN_LABELS} from '../constatnts/categoryFilters';
import {
  EVENTS_CATEGORY_DROPDOWN,
  filterValuesType,
  RemappedPatientsNamesType,
  RemappedPhysiciansType,
} from '../constatnts/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {usePatientsNames} from '../hooks/usePatientsNames';

import {SingleAutocomplete} from './singleAutocomplete';

type Props = {
  filterValues: filterValuesType;
  handleToggleCategoryValue: (val: EVENTS_CATEGORY_DROPDOWN) => void;
  handleToggleProviderValue: (val: null | RemappedPhysiciansType) => void;
  handleTogglePatientValue: (val: RemappedPatientsNamesType[]) => void;
  handleToggleDateRange: (val: DateRangeType) => void;
  handleClearFilters: () => void;
};

export const FiltersWrapper: FC<Props> = ({
  filterValues,
  handleToggleCategoryValue,
  handleToggleProviderValue,
  handleTogglePatientValue,
  handleToggleDateRange,
  // isFilterValuesChanged,
  // setSelectedFilterValues,
  // applyFilters,
  handleClearFilters,
}) => {
  const classes = useCustomizedButtonStyles();
  const {physicians, physiciansIsLoading, error} = usePhysicians();
  const {patientsIsLoading, patientsNames, patientsNamesError} =
    usePatientsNames();

  const isEnabledClearFilterBtn =
    !filterValues.eventCategories.length &&
    !filterValues.provider &&
    !filterValues.patientNames.length &&
    !(filterValues.dateRange[0] && filterValues.dateRange[1]);

  return (
    <Container>
      <FilterWithCheckboxes
        id="category-filter"
        testId="category"
        items={CATEGORY_EVENTS_DROPDOWN_LABELS}
        selectedItems={filterValues.eventCategories}
        toggleItem={handleToggleCategoryValue as (item: string) => void}
        label="Category"
      />
      <AutocompleteWrapper>
        <SingleAutocomplete
          placeholderText="Filter by Provider"
          noOptionsFoundText="No Provider found"
          data={physicians}
          loading={physiciansIsLoading}
          error={error}
          testId="filter-physicians"
          selectedValue={filterValues.provider}
          setSelectedProvider={handleToggleProviderValue}
        />
      </AutocompleteWrapper>
      <AutocompleteWrapper>
        <MultipleAutocompleteWithCheckBoxes
          placeholderText="Filter by Patient"
          noOptionsFoundText="No Patient found"
          errorText="Something went wrong. Please reload this page"
          emptyDataText="No data is available"
          data={patientsNames}
          loading={patientsIsLoading}
          error={patientsNamesError}
          selectedOptions={filterValues.patientNames}
          setSelectedOptions={handleTogglePatientValue}
          testId="patients"
        />
      </AutocompleteWrapper>
      <DatePickerWrapper>
        <DateRangePicker
          dateRange={filterValues.dateRange}
          setDateRange={handleToggleDateRange}
          placeholderText="Start Date – End Date"
          isSelectRange
        />
      </DatePickerWrapper>
      <Button
        variant="outlined"
        className={classes.customizedBtn}
        disabled={isEnabledClearFilterBtn}
        onClick={handleClearFilters}
        data-testid="clear-filters-btn"
      >
        Clear Filters
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const DatePickerWrapper = styled.div`
  width: 254px;
  min-width: 254px;
`;

const AutocompleteWrapper = styled.div`
  min-width: 222px;
`;
