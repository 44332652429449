import React from 'react';
import {Box} from '@material-ui/core';

import {NPI_STATES, NPI_STATUSES} from '../../../shared/constants/npi';
import {colors} from '../../../shared/styles/theme';

const DEFAULT_NPI_STATES = {
  [NPI_STATES.ACTIVE]: {
    message: (
      <Box component="span" color={colors.green}>
        You can apply this NPI
      </Box>
    ),
    status: NPI_STATUSES.SUCCESS,
  },
  [NPI_STATES.ALREADY_EXISTS]: {
    message: 'This NPI is already registered',
    status: NPI_STATUSES.ERROR,
  },
  [NPI_STATES.NOT_FOUND]: {
    message: 'NPI is not found',
    status: NPI_STATUSES.ERROR,
  },
  [NPI_STATES.SOMETHING_WENT_WRONG]: {
    message: 'Something went wrong',
    status: NPI_STATUSES.ERROR,
  },
  [NPI_STATES.EDITED]: {
    message: 'NPI was edited. Search it again.',
    status: NPI_STATUSES.ERROR,
  },
  [NPI_STATES.DEFAULT]: {
    message: 'Type NPI and press Search to proceed',
    status: NPI_STATUSES.DEFAULT,
  },
};

export const getOwnerNpiState = npiStatus => {
  const states = {
    ...DEFAULT_NPI_STATES,
    [NPI_STATES.ACTIVE]: {
      message: (
        <Box component="span" color={colors.green}>
          You can apply this user
        </Box>
      ),
      status: NPI_STATUSES.SUCCESS,
    },
  };

  return states[npiStatus];
};

export const getOrganizationNpiState = npiStatus => {
  const states = {
    ...DEFAULT_NPI_STATES,
    [NPI_STATES.ACTIVE]: {
      message: (
        <Box component="span" color={colors.green}>
          You can invite this relying party
        </Box>
      ),
      status: NPI_STATUSES.SUCCESS,
    },
  };

  return states[npiStatus];
};
