import React, {useCallback, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {ExitBar} from '../../../shared/components/exitBar';
import {StepSidebar} from '../../../shared/components/stepBar';
import {AddPolicyForm} from '../components/addPolicyForm';
import {PolicyPreview} from '../components/policyPreview';
import {SelectPolicyTypeForm} from '../components/selectPolicyTypeForm';
import {defaultPolicyMap, POLICY_TYPES} from '../constants/defaultPolicy';

const STEPS = {
  SELECT_POLICY_TYPE: 'Name policy',
  CREATE_HEALTH_POLICY: 'Select requirements',
  CONFIRM_CREATION: 'Confirm policy',
};

const ADD_POLICY_STEPS = [
  STEPS.SELECT_POLICY_TYPE,
  STEPS.CREATE_HEALTH_POLICY,
  STEPS.CONFIRM_CREATION,
];

export const AddPolicyPage = () => {
  const history = useHistory();
  const location = useLocation();
  const defaultKey = Object.keys(POLICY_TYPES)[0];
  const [policyKey, setPolicyKey] = useState(
    location?.state?.policyTypeId || defaultKey
  );
  const defaultPolicy = defaultPolicyMap[POLICY_TYPES[policyKey]];
  const [policy, setPolicy] = useState(defaultPolicy);
  const [activeStep, setActiveStep] = useState(0);
  const goToStep = useCallback(nextStep => {
    const stepIndex = ADD_POLICY_STEPS.findIndex(step => step === nextStep);
    setActiveStep(stepIndex);
  }, []);

  const goToNextStep = useCallback(() => {
    if (activeStep < ADD_POLICY_STEPS.length) {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const isOnStep = useCallback(
    step => ADD_POLICY_STEPS[activeStep] === step,
    [activeStep]
  );

  const handlePolicyChange = useCallback(
    policyToAdd => {
      setPolicy(policyToAdd);
      setPolicyKey(policyToAdd.policy_type_id);
      goToNextStep();
    },
    [goToNextStep]
  );

  const exit = useCallback(
    () =>
      history.push({
        pathname: '/health-policies',
        state: location?.state,
      }),
    [history, location?.state]
  );

  return (
    <AddPolicyContainer>
      <ExitBar onClick={exit} title="Create new policy" />

      <LayoutColumn>
        <StepSidebar steps={ADD_POLICY_STEPS} activeStep={activeStep} />
        <ModalContent>
          {isOnStep(STEPS.SELECT_POLICY_TYPE) && (
            <SelectPolicyTypeForm
              onNext={handlePolicyChange}
              onBack={exit}
              initialValues={{policyKey, policyName: policy?.name}}
            />
          )}
          {isOnStep(STEPS.CREATE_HEALTH_POLICY) && (
            <AddPolicyForm
              policy={policy}
              onNext={handlePolicyChange}
              onBack={() => goToStep(STEPS.SELECT_POLICY_TYPE)}
            />
          )}
          {isOnStep(STEPS.CONFIRM_CREATION) && (
            <PolicyPreview
              policy={policy}
              onBack={() => goToStep(STEPS.CREATE_HEALTH_POLICY)}
            />
          )}
        </ModalContent>
      </LayoutColumn>
    </AddPolicyContainer>
  );
};

const AddPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const LayoutColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;

const ModalContent = styled.div`
  padding-top: 18px;
  padding-left: 24px;
  flex: 1;
`;
