import React, {FC} from 'react';
import styled from 'styled-components';

import {FileUploadLinkResponse} from '../../../../shared/interfaces/aIChat';

import useMessages from './hooks/useMessages';
import {CareGuideLoadingMessage} from './careGuideLoadingMessage';
import ChatContainer from './chatContainer';

interface ChatBodyProps {
  hasSpace: boolean;
  messageContainerRef: React.RefObject<HTMLDivElement>;
  saveFileInLinkResponse: FileUploadLinkResponse;
}

const ChatBody: FC<ChatBodyProps> = ({
  hasSpace,
  messageContainerRef,
  saveFileInLinkResponse,
}) => {
  const {postMessageisLoading} = useMessages();
  return (
    <MessageContainerWrapper
      ref={messageContainerRef}
      data-testid="message-container"
    >
      <ChatContainer saveFileInLinkResponse={saveFileInLinkResponse} />
      {postMessageisLoading && <CareGuideLoadingMessage />}
      <ChatEmptySpace hasSpace={hasSpace} />
    </MessageContainerWrapper>
  );
};

const MessageContainerWrapper = styled.div`
  display: flex;
  padding: 24px 0px 100px 40px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
  margin-top: 70px;
`;

const ChatEmptySpace = styled.div<{hasSpace: boolean}>`
  margin: 10px 10px;
  height: ${props => `${props.hasSpace ? 85 : 0}px`};
`;

export default ChatBody;
