import {useCallback} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {PaginationState} from '@tanstack/react-table';

import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {
  MedicalDocumentsResponseType,
  SortingDirections,
} from '../constants/types';
import {getMedicalDocuments} from '../utils/getMedicalDocuments';
import {getSelectedFileTypesAsString} from '../utils/getSelectedFileTypesAsString';
import {getTotalPages} from '../utils/getTotalPages';

type HookReturn = {
  data: MedicalDocumentsResponseType;
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  isError: boolean;
};

export const useMedicalDocuments = (
  consumerUuid: string,
  selectedFileTypes: string[],
  sortingByUploadedOn: SortingDirections,
  {pageIndex, pageSize}: PaginationState,
  nextPage: number
): HookReturn => {
  const handleError = useErrorHandling();
  const queryClient = useQueryClient();

  const prefetchNextPage = useCallback(
    async (useQueryKey, fetchFn) => {
      await queryClient.prefetchQuery({
        queryKey: useQueryKey,
        queryFn: () => fetchFn(),
      });
    },
    [queryClient]
  );

  const handleGetMedicalDocuments = async () => {
    const medicalDocuments = await getMedicalDocuments(
      consumerUuid,
      getSelectedFileTypesAsString(selectedFileTypes),
      sortingByUploadedOn,
      pageIndex,
      pageSize
    );
    if (nextPage < getTotalPages(medicalDocuments.count)) {
      await prefetchNextPage(
        [
          'medicalDocuments',
          consumerUuid,
          nextPage,
          ...selectedFileTypes,
          sortingByUploadedOn,
        ],
        () =>
          getMedicalDocuments(
            consumerUuid,
            getSelectedFileTypesAsString(selectedFileTypes),
            sortingByUploadedOn,
            nextPage,
            pageSize
          )
      );
    }
    return medicalDocuments;
  };

  const {
    data = {count: 0, files: []},
    isLoading,
    isFetching,
    refetch,
    isError,
  } = useQuery({
    queryKey: [
      'medicalDocuments',
      consumerUuid,
      pageIndex,
      ...selectedFileTypes,
      sortingByUploadedOn,
    ],
    queryFn: () => handleGetMedicalDocuments(),
    onError: wrapAsyncFunction(async e => {
      await handleError(e);
    }),
    refetchOnMount: true,
    keepPreviousData: true,
  });

  return {
    data,
    isLoading,
    isFetching,
    refetch: wrapAsyncFunction(refetch),
    isError,
  };
};
