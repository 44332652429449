import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.grey40,
    paddingLeft: 40,
    '& .MuiBreadcrumbs-separator': {
      margin: 0,
    },
  },
}));

const BreadCrumbsSeparator = () => <Separator>|</Separator>;

export const BreadCrumbNavigator = ({crumbs, title}) => {
  const classes = useStyles();
  const {url} = useRouteMatch();

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" separator={null}>
        <p>{title}</p>
        <BreadCrumbsSeparator />
        {crumbs?.map(crumb => {
          if (crumb.path === url) {
            return <CurrentPage key={crumb.title}>{crumb.title}</CurrentPage>;
          }
          return (
            <PreviousPageContainer key={crumb.title}>
              <Link to={{pathname: crumb.path, state: crumb.locationState}}>
                <PreviousPage>{crumb.title}</PreviousPage>
              </Link>
              <BreadCrumbsSeparator />
            </PreviousPageContainer>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

BreadCrumbNavigator.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const CurrentPage = styled.span`
  font-weight: bold;
  margin: 0;
`;

const PreviousPageContainer = styled.div`
  display: flex;
`;

const PreviousPage = styled.p`
  color: ${colors.black};
  &:hover {
    text-decoration: underline;
  }
`;

const Separator = styled.p`
  padding: 0 10px;
`;

BreadCrumbNavigator.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};
