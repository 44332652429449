import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const InboxIcon: FC<any> = props => (
  <SvgIcon {...props} viewBox="0 0 23 23" width="20" height="18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9081 5.5H8.0919C7.92177 5.5 7.76331 5.58651 7.67131 5.72962L6.40955 4.91849C6.77755 4.34604 7.41138 4 8.0919 4H16.9081C17.5886 4 18.2225 4.34604 18.5905 4.91848L17.3287 5.72962C17.2367 5.58651 17.0782 5.5 16.9081 5.5ZM21 11.4406L17.3287 5.72962L18.5905 4.91848L22.5 11V18C22.5 19.1046 21.6046 20 20.5 20H4.5C3.39543 20 2.5 19.1046 2.5 18V11L6.40955 4.91849L7.67131 5.72962L4 11.4406V18C4 18.2761 4.22386 18.5 4.5 18.5H20.5C20.7761 18.5 21 18.2761 21 18V11.4406Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 12.25H3.5V10.75H9C9.41421 10.75 9.75 11.0858 9.75 11.5V13.75H15.25V11.5C15.25 11.0858 15.5858 10.75 16 10.75H21.5V12.25H16.75V14.5C16.75 14.9142 16.4142 15.25 16 15.25H9C8.58579 15.25 8.25 14.9142 8.25 14.5V12.25Z"
      fill="#fff"
    />
  </SvgIcon>
);
