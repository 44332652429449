import React from 'react';
import {Box} from '@material-ui/core';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  INPUT_WIDTH_TYPES,
  InputFormControlWithFormik,
} from '../../../../shared/components/form';

export const OwnerForm = ({children, disabledFrom}) => {
  const formik = useFormikContext();

  return (
    <OwnerFormWrapper>
      <Box fontWeight={700} component="p">
        Set Owner
      </Box>

      {children}

      <FormWrapper>
        <InputFormControlWithFormik
          required
          id="firstName"
          name="firstName"
          label="First name"
          width={INPUT_WIDTH_TYPES.SMALL}
          disabled
          testIdPrefix="first-name"
        />

        <InputFormControlWithFormik
          required
          id="lastName"
          name="lastName"
          label="Last name"
          width={INPUT_WIDTH_TYPES.SMALL}
          disabled
          testIdPrefix="last-name"
        />

        <InputFormControlWithFormik
          required
          id="email"
          name="email"
          label="Email Address"
          width={INPUT_WIDTH_TYPES.SMALL}
          disabled={disabledFrom}
          error={(formik.touched.email && formik.errors.email) || ''}
          testIdPrefix="email"
        />

        <InputFormControlWithFormik
          required
          id="phoneNumber"
          name="phoneNumber"
          label="Phone number"
          placeholder="(XXX) XXX-XXXX"
          width={INPUT_WIDTH_TYPES.SMALL}
          disabled={disabledFrom}
          error={
            (formik.touched.phoneNumber && formik.errors.phoneNumber) || ''
          }
          testIdPrefix="phone-number"
        />
      </FormWrapper>
    </OwnerFormWrapper>
  );
};

OwnerForm.propTypes = {
  children: PropTypes.node,
  disabledFrom: PropTypes.bool,
};

const OwnerFormWrapper = styled.div`
  margin-top: 40px;
`;

const FormWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;
