import {replaceLastSlash} from '../../../../../shared/constants/patterns';
import {codeSystemLabels} from '../constants/codeSystemLabels';

export const getFormattedCodeSystem = (codeSystem: string): string =>
  codeSystem
    .replace('https://', '')
    .replace('http://', '')
    .replace(replaceLastSlash, '');

export const getCodeSystemLabel = (
  codeSystem?: string | null
): string | null =>
  codeSystem
    ? codeSystemLabels[getFormattedCodeSystem(codeSystem)] || null
    : null;
