/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
} from '../../../../../shared/components/list';
import {urls} from '../../../../../shared/utils/urls';
import {codeSystems} from '../constants/codeSystemLabels';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {Lozenge} from '../lozenge';
import {StyledDivider} from '../styledDivider';
import {FetchedAllergies} from '../types/allergyTypes';
import {CommonSectionProps} from '../types/types';
import {
  getAllergies,
  getAllergyCriticallyColor,
  getAllergySeverityColors,
} from '../utils/allergiesMapper';
import {getCodeDescriptionBySystem} from '../utils/getDescriptionFromCodesBySystem';

const LABELS_COLUMN_WIDTH = '125px';

const useStyles = makeStyles(() => ({
  accordionContentWrapper: {
    padding: '24px',
    gridTemplateColumns: '1fr 1px 1fr 1px 1fr',
  },
}));

export const Allergies: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<FetchedAllergies[]>(
    urls.getAllergiesOfConsolidatedMedicalRecord(consumerUuid)
  );

  const allergies = useMemo(() => getAllergies(data), [data]);

  const hasNoData = allergies.length === 0 && !isFetching && !error;

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Allergies"
    >
      <ElectronicRecordAccordionWrapper>
        {allergies?.map(({reaction, ...allergy}) => (
          <ElectronicRecordAccordion
            key={allergy.id}
            title={getCodeDescriptionBySystem(
              allergy.codings,
              codeSystems.snomedCt
            )}
            content={
              <div
                className={cx(
                  listTemplateClasses.wrapper,
                  classes.accordionContentWrapper
                )}
              >
                <div>
                  <ElectronicRecordGroupTitle title="Details" />

                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ListItemKey>Type</ListItemKey>
                    <ListItemValue testId="type">{allergy.type}</ListItemValue>
                    <ListItemKey>Category</ListItemKey>
                    <ListItemValue testId="category">
                      {allergy.category}
                    </ListItemValue>
                    <ListItemKey>Status</ListItemKey>
                    <ListItemValue testId="clinical_status">
                      {allergy.clinical_status}
                    </ListItemValue>

                    <ListItemKey>Criticality</ListItemKey>
                    <ListItemValue testId="criticality">
                      {allergy.criticality ? (
                        <Lozenge
                          appearance={getAllergyCriticallyColor(
                            allergy.criticality
                          )}
                        >
                          {allergy.criticality}
                        </Lozenge>
                      ) : null}
                    </ListItemValue>
                    <ListItemKey>Verification Status</ListItemKey>
                    <ListItemValue testId="verification_status">
                      {allergy.verification_status}
                    </ListItemValue>
                    <ListItemKey>Last Occurrence</ListItemKey>
                    <ListItemValue testId="last_occurrence">
                      {allergy.last_occurrence}
                    </ListItemValue>

                    <ListItemKey>Recorded Date</ListItemKey>
                    <ListItemValue testId="recorded_date">
                      {allergy.recorded_date}
                    </ListItemValue>
                    <ListItemKey>Data Last Updated</ListItemKey>
                    <ListItemValue testId="last_updated_at">
                      {allergy.last_updated_at}
                    </ListItemValue>
                  </ListWithFixedLabelsColumn>
                </div>

                <StyledDivider orientation="vertical" />

                <div>
                  <ElectronicRecordGroupTitle title="Reactions" />
                  {reaction &&
                  Array.isArray(reaction) &&
                  reaction.length !== 0 ? (
                    <ListWithFixedLabelsColumn
                      labelsColumnWidth={LABELS_COLUMN_WIDTH}
                    >
                      {reaction.map((item, index) => (
                        <React.Fragment key={item.id}>
                          {index !== 0 && reaction.length !== index ? (
                            <>
                              <dt />
                              <dd />
                            </>
                          ) : null}

                          <ListItemKey>Reaction</ListItemKey>
                          <ListItemValue testId="reaction_description">
                            {item.description}
                          </ListItemValue>
                          <ListItemKey>Severity</ListItemKey>
                          <ListItemValue testId="reaction_severity">
                            {item.severity ? (
                              <Lozenge
                                appearance={getAllergySeverityColors(
                                  item.severity
                                )}
                              >
                                {item.severity}
                              </Lozenge>
                            ) : null}
                          </ListItemValue>
                        </React.Fragment>
                      ))}
                    </ListWithFixedLabelsColumn>
                  ) : (
                    <ListWithNoDataValue />
                  )}
                </div>

                <StyledDivider orientation="vertical" />

                <div data-testid="codes-block">
                  <ElectronicRecordGroupTitle title="Codes" />
                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ElectronicRecordCodesList codes={allergy.codings} />
                  </ListWithFixedLabelsColumn>
                </div>
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
