import React, {useCallback, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {ExitBar} from '../../../shared/components/exitBar';
import {StepSidebar} from '../../../shared/components/stepBar';
import {useLocationAddresses} from '../../../shared/hooks/useLocationAddresses';
import {NoPropsComponent} from '../../../shared/interfaces/common';
import {LocationPreview} from '../../../shared/interfaces/location';
import {
  AttachHealthPolicy,
  CachedPoliciesType,
} from '../components/attachHealthPolicy';
import {CancelModal} from '../components/cancelModal';
import {ConfirmCreation} from '../components/confirmCreation';
import {CreateLocation} from '../components/createLocation';
import {
  CREATE_LOCATION_STEPS,
  defaultLocation,
  NEW_POLICY_STEPS,
  STEPS,
} from '../constants/constants';

interface AddNewLocationRouteState {
  from?: string;
}

export const AddNewLocationPage: NoPropsComponent = () => {
  const history = useHistory();
  const {state} = useLocation<AddNewLocationRouteState>();
  const isFromLocationsPage = state?.from?.includes('locations');

  const [exiting, setExiting] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [location, setLocation] = useState(defaultLocation);
  const locationAddresses = useLocationAddresses('true');
  const [addingPolicy, setAddingPolicy] = useState(false);
  const [cachedPolicies, setCachedPolicies] = useState<CachedPoliciesType>({});
  const [newPolicyStep, setNewPolicyStep] = useState(
    NEW_POLICY_STEPS.CHOOSING_POLICY_TYPE
  );

  const goToStep = useCallback((nextStep: string) => {
    const stepIndex = CREATE_LOCATION_STEPS.findIndex(
      step => step === nextStep
    );
    setActiveStep(stepIndex);
  }, []);

  const handleNext = useCallback(
    (locationToAdd: LocationPreview) => {
      setLocation(locationToAdd);
      goToStep(STEPS.ASSIGN_HEALTH_POLICY);
    },
    [goToStep]
  );

  const isOnStep = useCallback(
    (step: string) => CREATE_LOCATION_STEPS[activeStep] === step,
    [activeStep]
  );

  const handleExiting = useCallback(() => {
    history.push(isFromLocationsPage ? '/locations' : '/health-policies');
  }, [history, isFromLocationsPage]);

  return (
    <>
      <AddPolicyContainer>
        <ExitBar onClick={() => setExiting(true)} title="Set Up New Location" />

        <LayoutColumn>
          <StepSidebar steps={CREATE_LOCATION_STEPS} activeStep={activeStep} />
          <Content>
            {isOnStep(STEPS.CREATE_LOCATION) && (
              <CreateLocation
                location={location}
                locationAddresses={locationAddresses}
                onNext={handleNext}
                onBack={() => setExiting(true)}
              />
            )}
            {isOnStep(STEPS.ASSIGN_HEALTH_POLICY) && (
              <AttachHealthPolicy
                addingPolicy={addingPolicy}
                cachedPolicies={cachedPolicies}
                setAddingPolicy={setAddingPolicy}
                setCachedPolicies={setCachedPolicies}
                policyStep={newPolicyStep}
                setPolicyStep={setNewPolicyStep}
                onBack={() => goToStep(STEPS.CREATE_LOCATION)}
                onNext={() => goToStep(STEPS.CONFIRM_CREATION)}
              />
            )}
            {isOnStep(STEPS.CONFIRM_CREATION) && (
              <ConfirmCreation
                addingPolicy={addingPolicy}
                location={location}
                associatedPolicies={cachedPolicies as any}
                onBack={() => goToStep(STEPS.ASSIGN_HEALTH_POLICY)}
              />
            )}
          </Content>
        </LayoutColumn>
      </AddPolicyContainer>

      {exiting && (
        <CancelModal
          open={exiting}
          onConfirm={handleExiting}
          onCancel={() => setExiting(false)}
          isFromLocationsPage={isFromLocationsPage}
        />
      )}
    </>
  );
};

const AddPolicyContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LayoutColumn = styled.div`
  display: flex;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 18px 24px 0;
  margin-bottom: 40px;
`;
