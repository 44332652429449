import React, {FC, ReactNode, useState} from 'react';
import {Box, CircularProgress} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {DocumentActiveStatusLabel} from '../../main/portalPatients/components/documentActiveStatusLabel';
import {
  EligibilityStatus,
  ReturnDocumentType,
} from '../../main/portalPatients/constants/types';
import {currentUserState} from '../atoms/authAtom';
import {useErrorHandling} from '../hooks/useErrorHandling';
import {colors} from '../styles/theme';
import {fetcher, HttpMethod} from '../utils/fetcher';
import {formatUsTime} from '../utils/formatDate';
import {urls} from '../utils/urls';

import {Button} from './button';
import {CloseButton} from './closeButton';
import {InlineMessage, InlineMessageType} from './inlineMessage';

const CIRCULAR_PROGRESS_PROPS = {
  size: 80,
  thickness: 2,
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  modalTitle: {
    fontSize: '18px',
    fontWeight: 700,
    margin: 0,
    lineHeight: '24px',
  },
  modalBodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '888px',
    minHeight: '388px',
  },
  modalLoadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '888px',
    minHeight: '388px',
  },
}));

type Props = {
  title: string | null;
  onClose: VoidFunction;
  children: ReactNode;
  open: boolean;
  container?: HTMLElement | null;
  patientDocumentsInfo: ReturnDocumentType[];
  documentName: 'Insurance';
  refetchDocuments: () => Promise<any>;
};

export const DocumentVerificationModalWindow: FC<Props> = ({
  title,
  onClose,
  children,
  open,
  container,
  patientDocumentsInfo,
  documentName,
  refetchDocuments,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [inlineMessage, setInlineMessage] = useState<{
    type?: InlineMessageType;
    text?: string;
  }>();

  const currentUser = useRecoilValue(currentUserState);
  const patientDocumentWithInfo = patientDocumentsInfo[0];
  const handleError = useErrorHandling();

  const handleClose = () => {
    if (!isLoading) {
      onClose();
      setInlineMessage({type: undefined, text: undefined});
    }
  };

  const handleRequest = async () => {
    setIsLoading(true);
    try {
      const response = await fetcher(
        urls.insuranceEligibilityCheck(
          currentUser?.relyingParty.id,
          patientDocumentWithInfo.data?.id
        ),
        HttpMethod.POST
      );
      const isValid = response?.eligibility_status === EligibilityStatus.VALID;
      const statusDate = new Date(response?.last_eligibility_check_at);
      if (statusDate == null) {
        return;
      }
      setInlineMessage({
        type: isValid ? 'success' : 'error',
        text: `${documentName} is ${
          isValid ? 'active' : 'inactive'
        } as of ${formatUsTime(statusDate)}.`,
      });
      refetchDocuments().then(() => setIsLoading(false));
    } catch (e) {
      handleError(e);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      container={container ?? document.body}
    >
      <Fade in={open}>
        {isLoading ? (
          <Box className={classes.modalLoadingWrapper}>
            <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
          </Box>
        ) : (
          <Box className={classes.modalBodyWrapper}>
            <Header>
              <HeaderTitle>
                <h2 className={classes.modalTitle}>{title}</h2>
                <SubHeader>
                  Request an update to determine if this insurance is currently
                  active
                </SubHeader>
              </HeaderTitle>
              <CloseButtonContainer>
                <CloseButton
                  onClick={handleClose}
                  data-testid="close-verification-model-btn"
                />
              </CloseButtonContainer>
            </Header>
            <Body>
              {children}
              {inlineMessage?.type && inlineMessage?.text && (
                <InlineMessageContainer>
                  <InlineMessage
                    inlineMessageType={inlineMessage.type}
                    text={inlineMessage.text}
                  />
                </InlineMessageContainer>
              )}
              <StatusContainer>
                <HeaderTitle>
                  <Title>Status</Title>
                  <DocumentActiveStatusLabel
                    patientDocumentInfo={patientDocumentsInfo[0]}
                    shouldShowTime
                  />
                </HeaderTitle>
                <Button
                  onClick={handleRequest}
                  testID="request_document_update_btn"
                >
                  Request Update
                </Button>
              </StatusContainer>
            </Body>
          </Box>
        )}
      </Fade>
    </Modal>
  );
};

const HeaderTitle = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const SubHeader = styled.p`
  color: ${colors.grey801};
  margin: 0;
`;

const Header = styled.div`
  justify-content: space-between;
  padding: 32px;
  display: flex;
  box-sizing: border-box;
`;

const CloseButtonContainer = styled.div`
  align-self: flex-start;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
`;

const Body = styled.div`
  border-top: 1px solid ${colors.grey800};
  border-bottom: 1px solid ${colors.grey800};
  padding: 24px 32px 32px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const InlineMessageContainer = styled.div`
  margin-top: 24px;
`;

const StatusContainer = styled.div`
  display: flex;
  width: 824px;
  padding: 16px;
  margin-top: 16px;
  align-items: center;
  gap: 12px;
  width: 824px;
  align-self: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid ${colors.grey800};
`;
