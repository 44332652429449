import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from '@material-ui/styles';
import localforage from 'localforage';
import {isNil} from 'lodash';
import {useRecoilState, useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {useAuth} from '../../../entry/hooks/useAuth';
import {
  currentTenantIdState,
  currentUserState,
} from '../../../shared/atoms/authAtom';
import {issuerLogoState} from '../../../shared/atoms/issuerLogoState';
import {menuCollapseStore} from '../../../shared/atoms/menuCollapseStore';
import {ConfirmModal} from '../../../shared/components/confirmModal';
import {ROLES} from '../../../shared/constants/roles';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {SettingsIcon} from '../../../shared/icons/settingsIcon';
import {SignOutIcon} from '../../../shared/icons/signOutIcon';
import {colors} from '../../../shared/styles/theme';
import {autoLogout} from '../../../shared/utils/autoLogout';
import {isRoleMatched} from '../../../shared/utils/user';
import {InboxButton} from '../../inbox/components/inboxButton';
import {ISSUER_SETTINGS_ROUTES} from '../../issuerSettings/constants/issuerSettingsRoutes';

import {UserInfoButton} from './userInfoButton';

const useStyles = makeStyles(theme => ({
  collapse: {
    marginRight: '20px',
    fontSize: '24px',
    cursor: 'pointer',
  },
  menuIcon: {
    minWidth: '26px',
    color: colors.primary,
  },
  logoContainer: {
    flex: 1,
  },
  logoBanner: {
    maxWidth: '240px',
    height: '25px',
  },
  divider: ({issuerLogo}) => ({
    backgroundColor: colors.white,
    margin: issuerLogo ? theme.spacing(0, 1.8) : theme.spacing(0, 1.8, 0, 0),
    height: '35px',
  }),
  organizationNameWrapper: {
    maxWidth: theme.spacing(55),
    fontWeight: 600,
  },
}));

const StyledMenu = withStyles({
  paper: {
    'box-shadow': '0px 3px 6px rgba(0, 0, 0, 0.16)',
    color: colors.primary,
    width: '280px',
    marginTop: '10px',
  },
})(props => (
  <Menu
    getContentAnchorEl={null}
    autoFocus={false}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export function Header() {
  const issuerLogo = useRecoilValue(issuerLogoState);
  const classes = useStyles({issuerLogo});
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const {signOut} = useAuth();

  const [collapse, setCollapsed] = useRecoilState(menuCollapseStore);

  const handleToggleCollapsed = useCallback(() => {
    setCollapsed(!collapse);
  }, [collapse, setCollapsed]);
  const userState = useRecoilValue(currentUserState);
  const tenantIdState = useRecoilValue(currentTenantIdState);

  const openMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openConfirmModal = useCallback(() => {
    setOpenLogoutModal(true);
    closeMenu();
  }, [closeMenu]);

  const closeConfirmModal = useCallback(() => {
    setOpenLogoutModal(false);
  }, []);

  const onSignOut = useCallback(async () => {
    try {
      const signInPath = `/${tenantIdState}/sign-in`;
      await localforage.removeItem('fromTime');
      await localforage.removeItem('toTime');
      closeConfirmModal();
      history.replace(signInPath);

      await signOut();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error', err);
    }
  }, [closeConfirmModal, history, signOut, tenantIdState]);

  const onClickIssuerSettings = useCallback(() => {
    history.push(ISSUER_SETTINGS_ROUTES.UPLOAD_LOGO);
    setAnchorEl(null);
  }, [history]);

  const {roles, type, relyingParty} = userState;

  const showMessage = useCustomizedSnackbar();

  useEffect(() => {
    autoLogout(showMessage, onSignOut);
  }, [onSignOut, showMessage]);

  const isPhysicianHasRolesViewInbox =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_OWNER,
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ]) && type === USER_TYPES.PHYSICIAN;

  const isNonPhysicianHasRolesViewInbox =
    isRoleMatched(roles, [
      ROLES.RELYING_PARTY_ADMIN,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ]) && type === USER_TYPES.NON_PHYSICIAN;

  const isAvailableUploadIssuerLogo = isRoleMatched(roles, [
    ROLES.ISSUER_OWNER,
    ROLES.ISSUER_ADMIN,
    ROLES.ISSUER_SUPPORT_AGENT,
  ]);

  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container alignItems="center" className={classes.logoContainer}>
          <MenuIcon
            className={classes.collapse}
            onClick={handleToggleCollapsed}
          />
          {issuerLogo && (
            <img
              src={issuerLogo}
              alt="allClearId Logo For Header"
              className={classes.logoBanner}
            />
          )}
          <Divider
            orientation="vertical"
            flexItem
            classes={{vertical: classes.divider}}
          />
          <div className={classes.organizationNameWrapper}>
            {relyingParty.name}
          </div>
        </Grid>
        <div>
          <UserPanel>
            {isPhysicianHasRolesViewInbox || isNonPhysicianHasRolesViewInbox ? (
              <InboxButton />
            ) : null}
            <UserInfoButton
              patientName={userState.name}
              handleOnClick={openMenu}
              isActive={!isNil(anchorEl)}
              testID="user-info-btn"
            />
          </UserPanel>
          <StyledMenu
            data-testid="user-header-menu"
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!isNil(anchorEl)}
            onClose={closeMenu}
          >
            {isAvailableUploadIssuerLogo ? (
              <div>
                <MenuItem onClick={onClickIssuerSettings}>
                  <ListItemIcon className={classes.menuIcon}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Issuer Settings" />
                </MenuItem>
                <Divider />
              </div>
            ) : null}
            <MenuItem onClick={openConfirmModal}>
              <ListItemIcon className={classes.menuIcon}>
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
      <ConfirmModal
        isOpen={openLogoutModal}
        onClose={closeConfirmModal}
        onConfirm={onSignOut}
      >
        <LogoutContent>Are you sure you want to log out?</LogoutContent>
      </ConfirmModal>
    </AppBar>
  );
}

const UserPanel = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
`;

const LogoutContent = styled.h5`
  margin: 0;
  font-weight: bold;
`;
