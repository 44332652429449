import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {FormControl} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {omit} from 'lodash';
import PropTypes from 'prop-types';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import PenIcon from '../../../../assets/icons/edit.svg';
import {envConfig} from '../../../../env';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, CancelButton} from '../../../shared/components/button';
import {
  INPUT_WIDTH_SIZES,
  INPUT_WIDTH_TYPES,
  InputField,
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {Subheader} from '../../../shared/components/subheader';
import {PARTY} from '../../../shared/constants/roles';
import {
  USER_TYPES,
  USER_TYPES_LABELS,
} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher} from '../../../shared/utils/fetcher';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {checkEditFormButtonDisability} from '../../../shared/utils/formikUtils';
import {urls} from '../../../shared/utils/urls';
import {convertRequestBody} from '../utils/convertRequestBody';
import {formatNames} from '../utils/formatter';
import {hasEditPermission, hasRoleEditPermission} from '../utils/permissions';
import {editUserValidationSchema} from '../utils/validationSchema';

import {ContactInfoTitle} from './contactInfoTitle';
import {RolesSelect} from './rolesSelect';

const getPortalUserUrl = (currentUser, userId) =>
  currentUser.party === PARTY.ISSUER
    ? urls.issuerPortalUserDetail(currentUser.relyingParty.id, userId)
    : urls.relyingPartyPortalUserDetail(currentUser.relyingParty.id, userId);

const attachTenantId = requestBody => ({
  ...requestBody,
  tenant_id: envConfig.REACT_APP_TENANT_ID,
});

export const UserForm = ({
  editedUser,
  currentUser,
  cancelEdit,
  fromList,
  revalidateEditedUser,
  isEditMode,
  startEditing,
}) => {
  const history = useHistory();
  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling('Unable to save changes.');
  const setUser = useSetRecoilState(currentUserState);

  const portalUserUrl = getPortalUserUrl(currentUser, editedUser.id);

  const updateCurrentUser = useCallback(
    (values, editedUserId, currentUserId) => {
      if (editedUserId === currentUserId) {
        setUser({
          ...currentUser,
          firstName: values.firstName,
          lastName: values.lastName,
          name: `${values.firstName} ${values.lastName}`,
          roles: values.role,
        });
      }
    },
    [setUser, currentUser]
  );

  const handleSubmit = useCallback(
    async values => {
      try {
        await fetcher(
          portalUserUrl,
          'PATCH',
          attachTenantId(
            hasRoleEditPermission(currentUser, editedUser)
              ? convertRequestBody(values)
              : omit(convertRequestBody(values), ['roles'])
          )
        );

        await revalidateEditedUser();

        updateCurrentUser(values, editedUser.id, currentUser.id);
        showMessage('Changes saved successfully.', 'success');

        if (fromList) {
          history.goBack();
        } else {
          cancelEdit();
        }
      } catch (e) {
        await handleError(e);
      }
    },
    [
      revalidateEditedUser,
      cancelEdit,
      currentUser,
      fromList,
      handleError,
      history,
      editedUser,
      portalUserUrl,
      showMessage,
      updateCurrentUser,
    ]
  );

  const getEditModeHeaderButtons = formik => (
    <>
      <CancelButton
        position="section"
        onClick={
          fromList
            ? () => history.goBack()
            : () => {
                formik.resetForm();
                cancelEdit();
              }
        }
      />
      <Button
        testID="saveButton"
        type="submit"
        position="section"
        disabled={checkEditFormButtonDisability(formik)}
      >
        Save
      </Button>
    </>
  );

  const getNonEditModeHeaderButtons = () =>
    hasEditPermission(currentUser, editedUser) ? (
      <Button
        startIcon={<img src={PenIcon} alt="editIcon" />}
        position="section"
        testID="editButton"
        onClick={startEditing}
      >
        Edit
      </Button>
    ) : null;

  const canEditRoles =
    hasRoleEditPermission(currentUser, editedUser) && isEditMode;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: editedUser.first_name,
        lastName: editedUser.last_name,
        role: editedUser.roles,
      }}
      validationSchema={editUserValidationSchema}
      onSubmit={handleSubmit}
    >
      {({values, ...formik}) => (
        <Form>
          <Subheader title={formatNames(editedUser)}>
            {isEditMode
              ? getEditModeHeaderButtons(formik)
              : getNonEditModeHeaderButtons()}
          </Subheader>
          <Container>
            <ContactInfoTitle />
            <ContactInfoContainer>
              {currentUser.party === PARTY.RELYING_PARTY ? (
                <>
                  <FormControl disabled>
                    <InputLabel htmlFor="type">User type</InputLabel>
                    <InputField
                      width={INPUT_WIDTH_TYPES.SMALL}
                      id="type"
                      value={USER_TYPES_LABELS[editedUser.type]}
                    />
                    <InputHelperText />
                  </FormControl>

                  <div>
                    {editedUser.type === USER_TYPES.PHYSICIAN ? (
                      <FormControl disabled>
                        <InputLabel htmlFor="npi">NPI</InputLabel>
                        <InputField
                          width={INPUT_WIDTH_TYPES.SMALL}
                          id="npi"
                          value={editedUser.npi}
                        />
                        <InputHelperText />
                      </FormControl>
                    ) : null}
                  </div>
                </>
              ) : null}

              <InputFormControlWithFormik
                id="firstName"
                name="firstName"
                label="First name"
                width={INPUT_WIDTH_TYPES.SMALL}
                disabled={!isEditMode}
                error={formik.touched.firstName && formik.errors.firstName}
              />

              <InputFormControlWithFormik
                id="lastName"
                name="lastName"
                label="Last name"
                width={INPUT_WIDTH_TYPES.SMALL}
                disabled={!isEditMode}
                error={formik.touched.lastName && formik.errors.lastName}
              />

              <FormControl disabled>
                <InputLabel htmlFor="email">Email address</InputLabel>
                <InputField
                  width={INPUT_WIDTH_TYPES.SMALL}
                  id="email"
                  value={editedUser.email}
                />
                <InputHelperText />
              </FormControl>

              <FormControl disabled>
                <InputLabel htmlFor="phone">Phone number</InputLabel>
                <InputField
                  disabled
                  width={INPUT_WIDTH_TYPES.SMALL}
                  id="phone"
                  value={formatPhoneNumber(editedUser.phone_number)}
                />
                <InputHelperText />
              </FormControl>
            </ContactInfoContainer>
            <Title>Permission</Title>
            <Field
              as={RolesSelect}
              name="role"
              value={values.role}
              testID="roleSelectInput"
              disabled={!canEditRoles}
              currentUserRoles={currentUser.roles}
              openedUserRoles={editedUser.roles}
            />
          </Container>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  editedUser: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    npi: PropTypes.string,
    type: PropTypes.oneOf([USER_TYPES.PHYSICIAN, USER_TYPES.NON_PHYSICIAN]),
    phone_number: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
  }),
  revalidateEditedUser: PropTypes.func,
  cancelEdit: PropTypes.func,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.string),
    party: PropTypes.string,
    relyingParty: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  fromList: PropTypes.bool,
  isEditMode: PropTypes.bool,
  startEditing: PropTypes.func,
};

const Container = styled.div`
  margin-left: 40px;
`;

const ContactInfoContainer = styled.div`
  display: inline-grid;
  grid-template-columns: ${INPUT_WIDTH_SIZES[INPUT_WIDTH_TYPES.SMALL]} ${INPUT_WIDTH_SIZES[
      INPUT_WIDTH_TYPES.SMALL
    ]};
  grid-column-gap: 20px;
  grid-row-gap: 2px;
`;

const Title = styled.p`
  font-weight: 700;
`;
