import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {POLICY_TYPES} from '../constants/defaultPolicy';
import {policyDataFormatter, policyTitleMap} from '../utils/policyFormatter';

export const PolicyDetail = ({policies}) => {
  const mapKey = POLICY_TYPES[policies.policy_type_id];
  const titleMap = policyTitleMap[mapKey];

  return (
    <>
      <Subtitle>Policy Information</Subtitle>
      <PolicyContainer>
        <DataDisplayField
          label="Policy Type"
          value={POLICY_TYPES[policies.policy_type_id]}
          title={POLICY_TYPES[policies.policy_type_id]}
        />
        <DataDisplayField
          label="Policy Name"
          value={policies.name}
          title={policies.name}
        />
      </PolicyContainer>
      <Subtitle>Policy Requirements</Subtitle>
      <PolicyContainer>
        {Object.keys(titleMap).map(policy => (
          <DataDisplayField
            label={titleMap[policy]}
            value={policyDataFormatter(policies[policy])}
            key={`${policy}${policies[policy]}`}
          />
        ))}
      </PolicyContainer>
    </>
  );
};
PolicyDetail.propTypes = {
  policies: PropTypes.objectOf(PropTypes.any),
};

const PolicyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-bottom: 38px;
`;

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;
