import React, {FC} from 'react';
import {Box} from '@material-ui/core';

import {PatientInvitationStatus} from '../../../shared/interfaces/patient';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {DetailIcon} from '../components/detailIcon';
import {EditIcon} from '../components/editIcon';
import {getCurrentInvitationStatus} from '../utils/getCurrentInvitationStatus';

import {PORTAL_PATIENTS_ROUTES} from './routes';
import {RemappedInvitedPatientsType} from './types';

type CellType = {
  row: {
    original: RemappedInvitedPatientsType;
  };
};

const Actions: FC<{invitationId: number; isOptedOut: boolean}> = ({
  invitationId,
  isOptedOut,
}) => {
  const invitationDetailsPath =
    PORTAL_PATIENTS_ROUTES.INVITATION_DETAILS.replace(
      ':invitationId',
      invitationId.toString()
    );
  return (
    <>
      <DetailIcon path={invitationDetailsPath} />
      {!isOptedOut ? <EditIcon path={invitationDetailsPath} /> : null}
    </>
  );
};

const InvitationStatus: FC<{
  isOptedOut: boolean;
  status: PatientInvitationStatus;
}> = ({isOptedOut, status}) => {
  const currentInvitationStatus = getCurrentInvitationStatus({
    status,
    opt_out: isOptedOut,
  });

  return currentInvitationStatus ? (
    <Box
      component="span"
      fontWeight="bold"
      color={currentInvitationStatus.color}
    >
      {currentInvitationStatus.label}
    </Box>
  ) : (
    <></>
  );
};

export const INVITED_PATIENTS_TABLE_COLUMNS = [
  {
    id: 'name',
    header: 'Name',
    cell: (cell: CellType): string => cell.row.original.name,
    width: '272px',
    minWidth: '272px',
    columnClassNames: 'stickyLeft',
    searchable: true,
    isStickyLeft: true,
  },
  {
    id: 'phone_number',
    header: 'Phone Number',
    cell(cell: CellType): string {
      return formatPhoneNumber(cell.row.original.phone_number);
    },
    searchable: true,
    minWidth: '190px',
  },
  {
    id: 'status',
    header: 'Status',
    minWidth: '200px',
    cell({
      row: {
        original: {status, is_number_opted_out},
      },
    }: CellType): JSX.Element {
      return (
        <InvitationStatus status={status} isOptedOut={is_number_opted_out} />
      );
    },
  },
  {
    id: 'action',
    header: 'Action',
    cell(cell: CellType): JSX.Element {
      const {invitation_id, is_number_opted_out} = cell.row.original;

      return (
        <Actions
          invitationId={invitation_id}
          isOptedOut={is_number_opted_out}
        />
      );
    },
    width: '150px',
    minWidth: '150px',
    isStickyRight: true,
    columnClassNames: 'stickyRight',
  },
];
