import React, {FC, useCallback} from 'react';
import {Button, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

type Props = {
  message: string;
  handleUndo: VoidFunction;
  onClose?: VoidFunction;
};

export const UndoSnackbarContent: FC<Props> = ({
  message,
  handleUndo,
  onClose,
}) => {
  const handleClick = useCallback(() => {
    handleUndo();
    if (onClose) {
      onClose();
    }
  }, [handleUndo, onClose]);

  return (
    <ContentWrapper>
      <MessageContainer data-testid="undo-message">{message}</MessageContainer>
      <ButtonWrapper>
        <Button data-testid="undo-btn" color="inherit" onClick={handleClick}>
          <b>UNDO</b>
        </Button>
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClose}
          data-testid="undo-close-snackbar-btn"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </ButtonWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MessageContainer = styled.div`
  word-wrap: break-word;
  white-space: normal;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
`;
