import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Field, Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import * as yup from 'yup';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, CancelButton} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {InfoTooltip} from '../../../shared/components/infoTooltip';
import {InputTextField} from '../../../shared/components/inputTextField';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {colors} from '../../../shared/styles/theme';
import {checkDuplicates} from '../../../shared/utils/checkDuplicates';
import {checkAddFormButtonDisability} from '../../../shared/utils/formikUtils';
import {urls} from '../../../shared/utils/urls';
import {MainHeader} from '../../layout/components/mainHeader';
import {useEhrs} from '../../relyingParties/hooks/useEhrs';
import {
  LOCATION_TOOLTIP_DESCRIPTION,
  SELECT_EHR,
  SELECT_EHR_ID,
} from '../constants/constants';

import {LocationAddressForm} from './locationAddressForm';

const locationValidationSchema = yup.object({
  name: yup
    .string('Location name')
    .trim()
    .required('Required')
    .max(50, 'Please enter 1-50 characters'),
  addressOne: yup
    .string()
    .when(['havePhysicalAddress', 'primaryLocationManually'], {
      is: (havePhysicalAddress, primaryLocationManually) =>
        havePhysicalAddress && primaryLocationManually,
      then: yup
        .string()
        .trim()
        .max(256, 'Must be 256 characters or less')
        .required('Address Line 1 is required'),
    }),
  addressTwo: yup
    .string()
    .when(['havePhysicalAddress', 'primaryLocationManually'], {
      is: (havePhysicalAddress, primaryLocationManually) =>
        havePhysicalAddress && primaryLocationManually,
      then: yup.string().trim().max(256, 'Must be 256 characters or less'),
    }),
  city: yup.string().when(['havePhysicalAddress', 'primaryLocationManually'], {
    is: (havePhysicalAddress, primaryLocationManually) =>
      havePhysicalAddress && primaryLocationManually,
    then: yup
      .string()
      .trim()
      .max(256, 'Must be 256 characters or less')
      .required('City is required'),
  }),
  state: yup.string().when(['havePhysicalAddress', 'primaryLocationManually'], {
    is: (havePhysicalAddress, primaryLocationManually) =>
      havePhysicalAddress && primaryLocationManually,
    then: yup.string().trim().required('State is required'),
  }),
  zipCode: yup
    .string()
    .when(['havePhysicalAddress', 'primaryLocationManually'], {
      is: (havePhysicalAddress, primaryLocationManually) =>
        havePhysicalAddress && primaryLocationManually,
      then: yup
        .string()
        .trim()
        .required('Zip Code is required')
        .test(
          'zipCode',
          'Zip Code is not valid',
          zipCode => zipCode?.length === 5 || zipCode?.length === 9
        ),
    }),
  zipCodeAsync: yup
    .string()
    .when(['havePhysicalAddress', 'primaryLocationManually'], {
      is: (havePhysicalAddress, primaryLocationManually) =>
        havePhysicalAddress && !primaryLocationManually,
      then: yup
        .string()
        .trim()
        .required('Zip Code is required')
        .test(
          'zipCode',
          'Zip should contain 5 or 9 digits',
          zipCode => zipCode?.length === 5 || zipCode?.length === 9
        ),
    }),
  primaryLocationAddress: yup
    .string()
    .when(['havePhysicalAddress', 'primaryLocationManually'], {
      is: (havePhysicalAddress, primaryLocationManually) =>
        havePhysicalAddress && !primaryLocationManually,
      then: yup.string().trim().required('Address is required'),
    }),
});

const initialEhrValue = {
  id: 0,
  name: 'No EHR for this location',
  link: '',
};
const initialEhrValueSelect = {
  id: SELECT_EHR_ID,
  name: SELECT_EHR,
  link: '',
};

const useStyles = makeStyles({
  input: {
    '& .MuiInputBase-root > div': {
      '& > div': {
        color: colors.grey801,
        paddingRight: '8px',
      },
      '& > p': {
        display: 'none',
      },
    },
    '& .MuiInputBase-root > input': {
      paddingTop: '4px',
      paddingBottom: '6px',
      paddingLeft: '8px',
    },
  },
});

export const CreateLocation = ({
  onNext,
  onBack,
  location,
  locationAddresses,
}) => {
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const [selectedEhr, setSelectedEhr] = useState(SELECT_EHR_ID);
  const [link, setLink] = useState(location.ehr_link);
  const [ehrName, setEhrName] = useState(location.ehr_name);
  const custom = useStyles();
  const handleError = useErrorHandling();
  const {
    relyingParty: {name, id, providerType, npi},
  } = useRecoilValue(currentUserState);
  const fetchedEhrs = useEhrs(id);
  const ehrs = useMemo(
    () => [initialEhrValueSelect, initialEhrValue, ...fetchedEhrs],
    [fetchedEhrs]
  );

  const ehrNames = ehrs.map(ehr => ehr.name);
  const ehrIds = ehrs.map(ehr => ehr.id);
  const setState = useCallback(
    ehrId => {
      const selected = ehrs.filter(ehr => ehr.id === ehrId);
      setEhrName(selected[0].name);
      setLink(selected[0].link);
      setSelectedEhr(ehrId);
    },
    [ehrs]
  );

  useEffect(() => {
    setIsLinkVisible(link !== '');
  }, [link]);

  const handleChange = useCallback(
    ehr => {
      setState(ehr);
    },
    [setState]
  );

  const getPrefilledAddress = useCallback(
    values => {
      const {address_1, address_2, postal_code, city, state} =
        locationAddresses.data[Number(values.primaryLocationAddress)];

      return {
        addressOne: address_1,
        addressTwo: address_2,
        zipCode: postal_code,
        city,
        state,
      };
    },
    [locationAddresses.data]
  );

  const handleSubmit = useCallback(
    async (values, {setFieldError}) => {
      const addressFromCareMESH =
        values.primaryLocationManually || !values.havePhysicalAddress
          ? {}
          : getPrefilledAddress(values);

      const valuesToSubmit = {
        ...values,
        ehr_link: link,
        ehr_name: ehrName,
        ...addressFromCareMESH,
      };

      try {
        const result = await checkDuplicates(
          values.name,
          urls.checkLocationName
        );
        if (result) {
          setFieldError('name', 'This name has already been registered.');
        } else {
          onNext(valuesToSubmit);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [getPrefilledAddress, link, ehrName, onNext, handleError]
  );

  return (
    <>
      <MainHeader
        title="Create Location"
        description="A location can be an office or space within a building. It can also be a website or portal.  
        You wiil assign a policy to each location you create."
      />
      <Formik
        validateOnMount
        initialValues={location}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={locationValidationSchema}
      >
        {formik => (
          <Form>
            <Subtitle>Location Information</Subtitle>

            <ContentContainer>
              <LocationInput
                style={{
                  backgroundColor: colors.grey1200,
                }}
                type="text"
                size="large"
                required
                className={custom.input}
                name="name"
                prefixMaxWidth={150}
                label="Location Name"
                placeholder="Enter Location Name"
                testID="locationNameTextField"
                prefix={name}
              />
              <FieldContainer>
                <Field
                  as={CustomizedSelect}
                  testID="locationEhrField"
                  size="large"
                  name="ehr_id"
                  label="Location EHR"
                  options={ehrNames}
                  values={ehrIds}
                  hiddenValue={SELECT_EHR_ID}
                  onChange={e => {
                    formik.handleChange(e);
                    handleChange(e.target.value, formik);
                  }}
                />
              </FieldContainer>

              {isLinkVisible && (
                <FieldContainer>
                  <DataDisplayField
                    label="EHR Link for Patient Search"
                    value={link}
                    size="large"
                  />
                  <TooltipContainer>
                    <InfoTooltip description={LOCATION_TOOLTIP_DESCRIPTION} />
                  </TooltipContainer>
                </FieldContainer>
              )}
            </ContentContainer>
            <LocationAddressForm
              locationAddresses={locationAddresses}
              npi={npi}
              providerType={providerType}
            />
            <ButtonContainer>
              <CancelButton onClick={onBack}>Cancel</CancelButton>
              <Button
                disabled={
                  checkAddFormButtonDisability(formik) ||
                  (selectedEhr === SELECT_EHR_ID && location.ehr_id === 1)
                }
                type="submit"
              >
                Next
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

CreateLocation.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  location: PropTypes.shape({
    name: PropTypes.string,
    ehr_id: PropTypes.number,
    ehr_link: PropTypes.string,
    ehr_name: PropTypes.string,
  }),
  locationAddresses: PropTypes.shape({
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          address_1: PropTypes.string,
          address_2: PropTypes.string,
          city: PropTypes.string,
          country: PropTypes.string,
          district: PropTypes.string,
          postal_code: PropTypes.string,
          state: PropTypes.string,
        })
      ),
    ]),
  }),
};

const LocationInput = styled(InputTextField)`
  width: 464px;
  margin: 0 20px 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 48px;
`;

const ContentContainer = styled.div`
  margin: 0 0 30px 0;
`;

const TooltipContainer = styled.div`
  padding-top: 32px;
  margin-left: -10px;
`;

const Subtitle = styled.h6`
  margin: 18px 0;
`;

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
