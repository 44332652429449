import React from 'react';
import {Box, FormControl} from '@material-ui/core';
import {useRecoilState, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {
  InputField,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {ROLES} from '../../../shared/constants/roles';
import {
  PROVIDER_TYPES,
  PROVIDER_TYPES_LABELS,
} from '../../../shared/constants/userTypes';
import {STEPS, STEPS_TITLE} from '../constants/stepper';
import useCreateRelyingParty from '../hooks/useCreateRelyingParty';
import {relyingPartyState, stepState} from '../store';

import InvitationLayout from './invitationLayout';

export const StepConfirmInvitation = () => {
  const [relyingParty] = useRecoilState(relyingPartyState);

  const setCurStep = useSetRecoilState(stepState);

  const {
    name,
    domain,
    ownerNpi,
    providerType,
    firstName,
    lastName,
    email,
    phoneNumber,
    organizationNpi,
    primaryLocation,
    addressOne,
    addressTwo,
    city,
    state,
    zipCode,
  } = relyingParty;

  const handleBack = () => {
    setCurStep(STEPS.FIRST);
  };

  const {createRelyingParty, loading} = useCreateRelyingParty();

  const handleConfirm = async () => {
    await createRelyingParty({
      name,
      owner: {
        email,
        npi: ownerNpi,
        roles: ROLES.RELYING_PARTY_OWNER,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
      },
      domain,
      organization_npi: organizationNpi,
      provider_type: providerType,
      physical_location: {
        name: primaryLocation,
        address_line_1: addressOne,
        address_line_2: addressTwo,
        city,
        state,
        postal_code: zipCode,
      },
    });
  };

  return (
    <InvitationLayout
      title={STEPS_TITLE.CONFIRM_INVITATION.TITLE}
      description={STEPS_TITLE.CONFIRM_INVITATION.DESCRIPTION}
    >
      <Box fontWeight={700} component="p">
        Provider Type
      </Box>

      <FormControl disabled variant="standard">
        <InputLabel htmlFor="providerType">Provider Type</InputLabel>
        <InputField
          inputProps={{
            'data-testid': `confirm-input-provider-type`,
          }}
          id="providerType"
          width="small"
          value={PROVIDER_TYPES_LABELS[providerType]}
        />
        <InputHelperText />
      </FormControl>

      <OwnerInformationWrapper>
        <Box fontWeight={700} component="p">
          Owner Information
        </Box>

        <FormControl disabled variant="standard">
          <InputLabel htmlFor="ownerNpi">Owner NPI</InputLabel>
          <InputField
            inputProps={{
              'data-testid': `confirm-input-owner-npi`,
            }}
            id="ownerNpi"
            width="small"
            value={ownerNpi}
          />
          <InputHelperText />
        </FormControl>

        <OwnerInformationFieldsWrapper>
          <FormControl disabled variant="standard">
            <InputLabel htmlFor="firstName">First Name</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-first-name`,
              }}
              id="firstName"
              width="small"
              value={firstName}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="lastName">Last Name</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-last-name`,
              }}
              id="lastName"
              width="small"
              value={lastName}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-email`,
              }}
              id="email"
              width="small"
              value={email}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="phone">Phone Number</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-phone`,
              }}
              id="phone"
              width="small"
              value={phoneNumber}
            />
            <InputHelperText />
          </FormControl>
        </OwnerInformationFieldsWrapper>
      </OwnerInformationWrapper>

      {providerType === PROVIDER_TYPES.ORGANIZATION ? (
        <>
          <Box fontWeight={700} component="p">
            Organization Information
          </Box>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="organizationNpi">Organization NPI</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-organization-npi`,
              }}
              id="organizationNpi"
              width="small"
              value={organizationNpi}
            />
            <InputHelperText />
          </FormControl>

          <FieldsWrapper>
            <OrganizationInformationFieldsWrapper>
              <FormControl disabled variant="standard">
                <InputLabel htmlFor="name">Relying party name</InputLabel>
                <InputField
                  inputProps={{
                    'data-testid': `confirm-input-relying-party-name`,
                  }}
                  id="name"
                  value={name}
                />
                <InputHelperText />
              </FormControl>

              <FormControl disabled variant="standard">
                <InputLabel htmlFor="name">Domain</InputLabel>
                <InputField
                  inputProps={{
                    'data-testid': `confirm-input-relying-party-domain`,
                  }}
                  id="domain"
                  value={domain}
                />
                <InputHelperText />
              </FormControl>
            </OrganizationInformationFieldsWrapper>
          </FieldsWrapper>
        </>
      ) : null}

      <Box fontWeight={700} component="p">
        Primary Location
      </Box>

      <FormControl disabled variant="standard">
        <InputLabel htmlFor="primaryLocation">Primary Location Name</InputLabel>
        <InputField
          inputProps={{
            'data-testid': `confirm-input-primary-location-name`,
          }}
          id="primaryLocation"
          width="default"
          value={primaryLocation}
        />
        <InputHelperText />
      </FormControl>

      <OwnerInformationWrapper>
        <FieldsWrapper>
          <FormControl disabled variant="standard">
            <InputLabel htmlFor="addressOne">Address Line 1</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-address-one`,
              }}
              id="addressOne"
              width="default"
              value={addressOne}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="addressTwo">Address Line 2</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-address-two`,
              }}
              id="addressTwo"
              width="default"
              value={addressTwo}
            />
            <InputHelperText />
          </FormControl>
        </FieldsWrapper>

        <FieldsWrapper>
          <FormControl disabled variant="standard">
            <InputLabel htmlFor="city">City</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-address-city`,
              }}
              id="city"
              width="small"
              value={city}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="state">State</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-address-state`,
              }}
              id="state"
              width="small"
              value={state}
            />
            <InputHelperText />
          </FormControl>

          <FormControl disabled variant="standard">
            <InputLabel htmlFor="zipCode">Zip Code</InputLabel>
            <InputField
              inputProps={{
                'data-testid': `confirm-input-zip-code`,
              }}
              id="zipCode"
              width="small"
              value={zipCode}
            />
            <InputHelperText />
          </FormControl>
        </FieldsWrapper>
      </OwnerInformationWrapper>

      <ButtonGroup>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button
          data-testid="invite-relying-party-button"
          onClick={handleConfirm}
          disabled={loading}
        >
          Invite
        </Button>
      </ButtonGroup>
    </InvitationLayout>
  );
};

const OwnerInformationWrapper = styled.div`
  margin: 40px 0;
`;

const OrganizationInformationFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const OwnerInformationFieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 30px;
`;
