import React, {useCallback} from 'react';
import {Box, Paper, Popover} from '@material-ui/core';
import {PopoverOrigin} from '@material-ui/core/Popover/Popover';

import {ANCHOR} from '../styles/theme';

type Props = {
  id: string;
  children: React.ReactElement;
  content: React.ReactElement | string;
  transformOrigin: PopoverOrigin;
};
export const StyledPopover: React.FC<Props> = ({
  id,
  children,
  transformOrigin,
  content,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {React.cloneElement(children, {
        onClick: handleClick,
        isActive: Boolean(anchorEl),
      })}
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={ANCHOR.anchorOrigin as PopoverOrigin}
        style={ANCHOR.style}
        elevation={1}
        transformOrigin={transformOrigin}
      >
        <Box component={Paper} padding={2}>
          {content}
        </Box>
      </Popover>
    </>
  );
};
