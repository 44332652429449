import {urls} from '../../../shared/utils/urls';

export const modalWordings = {
  location: {
    title: 'DELETE LOCATION',
    label: 'Location name *',
    errorMessage: 'Location name does not match',
    successMessage: (name: string): string =>
      `You successfully deleted a location, ${name}.`,
    instruction:
      'If you are certain you want to delete this location, enter the entrance’s name, ',
    api: (id: number): string => urls.location(id),
  },
  policy: {
    title: 'DELETE POLICY',
    label: 'Policy name',
    errorMessage: 'Policy name does not match',
    successMessage: (name: string): string =>
      `You successfully deleted a policy, ${name}.`,
    instruction:
      'If you are certain you want to delete this policy, enter the policy’s name, ',
    disabledText:
      'You must delete all locations assigned to this policy before you can delete the policy.',
    api: (id: number): string => urls.policy(id),
  },
};
