import React from 'react';
import {pick} from 'lodash';
import PropTypes from 'prop-types';

import {StatusText} from '../../../shared/components/statusText';
import {INVITATION_STATUS} from '../../../shared/constants/status';
import {colors} from '../../../shared/styles/theme';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';

import {InformationContainer} from './informationContainer';

export const OwnerInformationField = ({ownerInfo}) => {
  const {phone_number: phoneNumber} = ownerInfo;
  const ownerInfoData = {
    ...pick(ownerInfo, ['first_name', 'last_name', 'email']),
    phone_number: formatPhoneNumber(phoneNumber),
  };

  return (
    <InformationContainer title="Owner information" data={ownerInfoData}>
      <StatusText
        color={
          ownerInfo.status === INVITATION_STATUS.INVITED
            ? colors.statusBlue
            : colors.statusGreen
        }
      >
        {ownerInfo.status === INVITATION_STATUS.INVITED
          ? 'Invited'
          : 'Confirmed'}
      </StatusText>
    </InformationContainer>
  );
};

OwnerInformationField.propTypes = {
  ownerInfo: PropTypes.objectOf(PropTypes.any),
};
