import {useCallback} from 'react';
import {useSetRecoilState} from 'recoil';

import {issuerLogoState} from '../../../shared/atoms/issuerLogoState';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {HttpResponseError} from '../../../shared/utils/httpResponseError';
import {fetchGetIssuerLogo} from '../utils/fetchers';

export const useSetStateIssuerLogo = (): (() => Promise<void>) => {
  const showMessage = useCustomizedSnackbar();
  const setIssuerLogo = useSetRecoilState(issuerLogoState);

  const setStateIssuerLogo = useCallback(async () => {
    try {
      const response = await fetchGetIssuerLogo();
      const imageData = response?.image;
      const imageBase64 = `data:image/png;base64,${imageData}`;
      setIssuerLogo(imageBase64);
    } catch (error) {
      if (error instanceof HttpResponseError) {
        showMessage(
          'Issuer logo getting failed. Please refresh the page or try again later.',
          'error'
        );
      }
    }
  }, [setIssuerLogo, showMessage]);

  return setStateIssuerLogo;
};
