import React, {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import {previousPathState} from '../atoms/previousPathState';

type Props = {
  children: React.ReactElement;
};
export const StepBackProvider: React.FC<Props> = ({children}) => {
  const setPreviousPath = useSetRecoilState(previousPathState);
  const location = useLocation();
  const history = useMemo(
    () => ({
      currentPath: '',
      previousPath: '',
    }),
    []
  );

  useEffect(() => {
    history.previousPath = history.currentPath;
    history.currentPath = `${location.pathname}${location.search}`;

    setPreviousPath(history.previousPath);
  }, [history, location.search, location.pathname, setPreviousPath]);

  return children;
};
