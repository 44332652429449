import React from 'react';
import {MenuProps} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select, {SelectProps} from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

import {ANCHOR} from '../styles/theme';

import {InputHelperText, InputLabel} from './form';
import OverflowTip from './overFlowTip';

type CustomizedSelectProps = Omit<SelectProps, 'label' | 'size'> & {
  label: string;
  values: string[];
  testID?: string;
  options: string[];
  selectedValue?: string;
  required?: boolean;
  errorDescription?: string;
  size?: 'medium' | 'small';
  disabled?: boolean;
  hiddenValue?: string;
};

export const CustomizedSelect: React.FC<CustomizedSelectProps> = ({
  label,
  values,
  testID,
  options,
  selectedValue,
  required,
  error = false,
  errorDescription,
  size = 'small',
  multiple = false,
  disabled,
  hiddenValue,
  ...props
}) => (
  <SelectForm
    required={required}
    error={error}
    variant="outlined"
    $inputSize={size}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      IconComponent={!disabled ? ExpandMoreIcon : () => null}
      disabled={disabled}
      MenuProps={ANCHOR as Partial<MenuProps>}
      multiple={multiple}
      value={multiple && selectedValue}
      inputProps={{'data-testid': testID}}
      {...props}
    >
      {options?.map((option, index) => (
        <MenuItem
          style={hiddenValue === values[index] ? {display: 'none'} : {}}
          value={values[index]}
          key={`${option}-${label}`}
        >
          {multiple && (
            <Checkbox
              color="primary"
              checked={
                !!(selectedValue && selectedValue.indexOf(values[index]) > -1)
              }
            />
          )}
          <OverflowTip tipValue={option} value={option} />
        </MenuItem>
      ))}
    </Select>
    {error && <InputHelperText shrink>{errorDescription}</InputHelperText>}
  </SelectForm>
);

const SelectForm = styled(FormControl)<{$inputSize: string}>`
  width: ${({$inputSize}) => ($inputSize === 'small' ? '222px' : '464px')};
  margin-right: 20px;
`;
