import React from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSvgIcon-root': {
      height: '15px',
      width: '15px',
      padding: '3px',
      cursor: 'pointer',
    },
  },
}));

export const ActionTableButton = ({
  title,
  onClick,
  testId,
  children,
  disabled,
  color = 'default',
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          color={color}
          disabled={disabled}
          data-testid={testId || null}
          size="small"
          className={classes.root}
          onClick={onClick}
          component="span"
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

ActionTableButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};
