import React from 'react';
import Grow from '@material-ui/core/Grow';
import {makeStyles} from '@material-ui/styles';
import {SnackbarProvider} from 'notistack';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  snackContainer: {top: 78},
}));

export const SnackbarWrapper = ({children}) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={2}
      preventDuplicate
      TransitionComponent={Grow}
      classes={{
        containerRoot: classes.snackContainer,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

SnackbarWrapper.propTypes = {
  children: PropTypes.node,
};
