import React from 'react';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {disconnectPatient} from '../../main/portalPatients/utils/fetchers';
import {confirmDisconnectPatientState} from '../atoms/confirmDisconnectPatientState';
import {useCustomizedSnackbar} from '../hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../hooks/useErrorHandling';

import {ConfirmModal} from './confirmModal';

export const ConfirmDisconnectPatient = (): JSX.Element => {
  const handleError = useErrorHandling();
  const handleSuccess = useCustomizedSnackbar();

  const [confirmDisconnectPatient, setConfirmDisconnectPatient] =
    useRecoilState(confirmDisconnectPatientState);

  const handleConfirm = async () => {
    try {
      await disconnectPatient(confirmDisconnectPatient.data.connectionId);
      setConfirmDisconnectPatient({
        ...confirmDisconnectPatient,
        isOpen: false,
      });
      handleSuccess(
        `You have successfully disconnected from ${confirmDisconnectPatient.data.patientName}.`,
        'success'
      );
    } catch (e) {
      handleError(e);
    }
  };

  const handleClose = (): void => {
    setConfirmDisconnectPatient({
      ...confirmDisconnectPatient,
      isOpen: false,
    });
  };

  const patientName = (
    <strong>{confirmDisconnectPatient.data.patientName}</strong>
  );
  return (
    <ConfirmModal
      title="Disconnect Patient?"
      isOpen={confirmDisconnectPatient.isOpen}
      variant="h6"
      onClose={handleClose}
      onConfirm={handleConfirm}
      confirmBtnText="Disconnect"
      confirmBtnVariant="dangerous"
    >
      <ContentWrapper>
        <>
          If you disconnect, you will not be able to share or receive records
          from&nbsp;
          {patientName}.<br />
          <br />
          Should you want to reconnect with this patient in future, click the
          airplane icon to send a connection request.
        </>
      </ContentWrapper>
    </ConfirmModal>
  );
};

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
`;
