import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useField} from 'formik';
import {isFunction} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

import CustomizedInput from './customizedInput';

const useStyle = makeStyles({
  input: {
    '& .MuiInputBase-root > div': {
      '& > div': {
        color: colors.grey900,
      },
      '& > p': {
        display: 'none',
      },
    },
    '& .MuiInputBase-root > input': {
      paddingTop: '4px',
      paddingBottom: '6px',
    },
  },
});

const shouldShowErrorMessage = (shouldCheckError, meta, focused) => {
  if (shouldCheckError === false) {
    return false;
  }
  if (!meta.touched) {
    return false;
  }
  return (
    !!(meta.value && !meta.touched && meta.error && !focused) ||
    !!(meta.touched && meta.error)
  );
};

/**
 * @deprecated Don't use this component. InputTextField needs to be deleted.
 */
export const InputTextField = ({
  type,
  label,
  prefix,
  required,
  testID,
  suffix,
  className,
  helperText,
  prefixMaxWidth,
  disabled,
  shouldCheckError = true,
  displayError = true,
  size = 'small',
  placeholder = 'Required',
  inputRef,
  onBlur,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [focused, setFocused] = useState(false);
  const custom = useStyle();

  return (
    <InputContainer className={className}>
      <InputField
        {...field}
        size={size}
        type={type}
        label={label}
        disabled={disabled}
        testID={testID}
        required={required}
        prefix={prefix}
        inputRef={inputRef}
        suffix={suffix}
        helperText={helperText}
        placeholder={placeholder}
        prefixMaxWidth={prefixMaxWidth}
        className={custom.input}
        onBlur={event => {
          field.onBlur(event);
          setFocused(false);
          if (isFunction(onBlur)) {
            onBlur(event);
          }
        }}
        onFocus={() => setFocused(true)}
        error={shouldShowErrorMessage(shouldCheckError, meta, focused)}
        {...props}
      />
      {displayError &&
        shouldShowErrorMessage(shouldCheckError, meta, focused) && (
          <ErrorMessage>{meta.error}</ErrorMessage>
        )}
      {!shouldShowErrorMessage(shouldCheckError, meta, focused) &&
        helperText && (
          <CustomizedFormHelperText>{helperText}</CustomizedFormHelperText>
        )}
    </InputContainer>
  );
};

InputTextField.propTypes = {
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  prefix: PropTypes.string,
  testID: PropTypes.string,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  prefixMaxWidth: PropTypes.number,
  displayError: PropTypes.bool,
  inputRef: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.any),
  shouldCheckError: PropTypes.bool,
};

const InputContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const InputField = styled(CustomizedInput)`
  margin: 0 21px 27px 0;
  width: ${props => {
    if (props.size === 'auto') {
      return '100%';
    }
    return props.size === 'small' ? '222px' : '464px';
  }};
  height: 42px;
`;

const ErrorMessage = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  position: absolute;
  color: ${colors.red};
`;

const CustomizedFormHelperText = styled.p`
  margin: 0;
  max-width: 463px;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  color: ${colors.grey500};
`;
