import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Button, CancelButton} from '../../../shared/components/button';
import {Description} from '../../../shared/components/description';
import {NPI_STATES, NPI_STATUSES} from '../../../shared/constants/npi';
import {USER_TYPES} from '../../../shared/constants/userTypes';
import {SelectUserTypeForm} from '../components/selectUserTypeForm';

export default function SelectUserType({
  setStepper,
  information,
  setInformation,
  dataNPI,
  isLoadingNPI,
  onSearchNPI,
  onClearDataNPI,
  nextStep,
  npiState,
}) {
  const history = useHistory();

  const onUpdateUserInformation = useCallback(() => {
    if (
      dataNPI?.npi &&
      dataNPI?.first_name &&
      dataNPI?.last_name &&
      dataNPI?.status === NPI_STATES.ACTIVE
    ) {
      setInformation(prevState => ({
        ...prevState,
        npi: dataNPI.npi,
        firstName: dataNPI.first_name,
        lastName: dataNPI.last_name,
      }));
    }
  }, [dataNPI, setInformation]);

  const isNextButtonDisabled = userType => {
    if (USER_TYPES.NON_PHYSICIAN === userType) {
      return false;
    }

    return isLoadingNPI || npiState.status !== NPI_STATUSES.SUCCESS;
  };

  const onClick = useCallback(() => {
    onUpdateUserInformation();
    setStepper(nextStep);
  }, [nextStep, onUpdateUserInformation, setStepper]);
  return (
    <>
      <Description
        title="Select user type"
        description="Please select user type. You should specify an NPI number if you want to invite a provider."
      />

      <SelectUserTypeForm
        setInformation={setInformation}
        information={information}
        dataNPI={dataNPI}
        isLoadingNPI={isLoadingNPI}
        onSearchNPI={onSearchNPI}
        onClearDataNPI={onClearDataNPI}
        npiState={npiState}
      />

      <CancelButton
        onClick={() => {
          history.goBack();
        }}
      />
      <Button
        data-testid="select-user-type-next-btn"
        onClick={onClick}
        disabled={isNextButtonDisabled(information.userType)}
      >
        Next
      </Button>
    </>
  );
}

SelectUserType.propTypes = {
  information: PropTypes.objectOf(PropTypes.any),
  setInformation: PropTypes.func,
  setStepper: PropTypes.func,
  dataNPI: PropTypes.objectOf(PropTypes.any),
  isLoadingNPI: PropTypes.bool,
  onSearchNPI: PropTypes.func,
  onClearDataNPI: PropTypes.func,
  nextStep: PropTypes.number,
  npiState: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    status: PropTypes.oneOf([
      NPI_STATUSES.DEFAULT,
      NPI_STATUSES.SUCCESS,
      NPI_STATUSES.ERROR,
    ]),
  }),
};
