import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

type UnblockFunction = () => void;

type ReturnHookType = {
  handleConfirmNavigation: () => void;
  routeIsBlocked: boolean;
  setRouteIsBlocked: (val: boolean) => void;
};

export const useConfirmNavigation = (shouldBlock: boolean): ReturnHookType => {
  const history = useHistory();
  const [routeIsBlocked, setRouteIsBlocked] = useState(false);
  const [unblock, setUnblock] = useState<UnblockFunction | null>(null);
  const [nextBlockedLocation, setNextBlockedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    let unblockFunc: UnblockFunction | null = null;

    const onBlock = (location: Location) => {
      setRouteIsBlocked(true);
      setNextBlockedLocation(location.pathname);
      return false;
    };

    if (shouldBlock) {
      unblockFunc = history.block(onBlock as never);
    }

    setUnblock(() => unblockFunc);

    return () => {
      if (unblockFunc) {
        unblockFunc();
      }
    };
  }, [history, shouldBlock]);

  const handleConfirmNavigation = () => {
    setRouteIsBlocked(false);
    if (unblock) {
      unblock();
    }

    if (nextBlockedLocation) {
      history.push(nextBlockedLocation);
      setNextBlockedLocation(null);
    }
  };

  return {handleConfirmNavigation, routeIsBlocked, setRouteIsBlocked};
};
