import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {AddButton} from '../../../shared/components/button';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SearchInput} from '../../../shared/components/searchInput';
import {Subheader} from '../../../shared/components/subheader';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {PARTY} from '../../../shared/constants/roles';
import {colors} from '../../../shared/styles/theme';
import {
  isUserIssuerAdminOrOwner,
  isUserRelyingPartyAdminOrOwner,
} from '../../../shared/utils/user';
import {useSearch} from '../../portalPatients/hooks/useSearch';
import {PORTAL_USERS_TABLE_COLUMNS} from '../constants/portalUsersTableConfig';
import {usePortalUsers} from '../hooks/usePortalUsers';

const hasInvitePermission = roles =>
  isUserIssuerAdminOrOwner(roles) || isUserRelyingPartyAdminOrOwner(roles);

const NPI_KEY_COLUMN = 'npi';

export const PortalUserPage = ({title, crumbs}) => {
  const [searchValue, searchError, onClearSearch, onSearch] = useSearch();
  const {portalUsers, portalUsersIsLoading, getPortalUsers} = usePortalUsers();
  const history = useHistory();
  const {roles, party, relyingParty} = useRecoilValue(currentUserState);

  const isFirstLoadingCase =
    portalUsersIsLoading &&
    searchValue.length === 0 &&
    portalUsers.length === 0;

  const noDataCase =
    !portalUsersIsLoading &&
    searchValue.length === 0 &&
    portalUsers.length === 0;

  const nothingFoundCase = portalUsers.length === 0 && !portalUsersIsLoading;

  const columns = useMemo(
    () =>
      party !== PARTY.RELYING_PARTY
        ? PORTAL_USERS_TABLE_COLUMNS.filter(
            portalUserColumn => portalUserColumn.id !== NPI_KEY_COLUMN
          )
        : PORTAL_USERS_TABLE_COLUMNS,
    [party]
  );

  const placeholderComponent = useMemo(() => {
    if (searchError || nothingFoundCase) {
      return (
        <Box
          component="div"
          fontStyle="italic"
          textAlign="center"
          color={colors.red}
        >
          {searchError || 'No results found. Please try again.'}
        </Box>
      );
    }

    return null;
  }, [nothingFoundCase, searchError]);

  const onClick = useCallback(() => {
    history.push('/portal-users/invitation');
  }, [history]);

  useEffect(() => {
    getPortalUsers(relyingParty.id, party, searchValue);
  }, [relyingParty.id, party, searchValue, getPortalUsers]);

  const table = useReactTable({
    data: portalUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <Subheader title="Portal users">
        {hasInvitePermission(roles) && (
          <AddButton
            onClick={onClick}
            text="Invite new user"
            testID="userInvitationBtn"
          />
        )}
      </Subheader>
      {portalUsersIsLoading && <LoadingBar loading />}
      {noDataCase && !isFirstLoadingCase && (
        <NoDataPlaceholder noDataText="No user found." />
      )}
      {!noDataCase && !isFirstLoadingCase && (
        <ContentWrapper>
          {party === PARTY.RELYING_PARTY && (
            <SearchInput
              placeholder="Search by Name, Email, Phone or NPI"
              focusedPlaceholder="Type at least 3 characters to search"
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              testId="portal-users"
            />
          )}
          {!noDataCase && !isFirstLoadingCase && (
            <StyledTable
              isHeaderSticky
              table={table}
              placeholderComponent={placeholderComponent}
            />
          )}
        </ContentWrapper>
      )}
    </Container>
  );
};

PortalUserPage.propTypes = {
  title: PropTypes.string,
  crumbs: PropTypes.arrayOf(PropTypes.any),
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapper = styled.div`
  margin: 20px 60px 60px 40px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 300px;
  overflow-y: auto;
  gap: 20px;
`;
