import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const EyeIcon: FC<any> = props => (
  <SvgIcon viewBox="0 0 18 12" {...props}>
    <path
      fill="currentColor"
      d="M9 9.5c.97 0 1.8-.34 2.48-1.02A3.38 3.38 0 0 0 12.5 6c0-.97-.34-1.8-1.02-2.48A3.38 3.38 0 0 0 9 2.5c-.97 0-1.8.34-2.48 1.02A3.38 3.38 0 0 0 5.5 6c0 .97.34 1.8 1.02 2.48C7.2 9.16 8.02 9.5 9 9.5ZM9 8c-.56 0-1.03-.2-1.42-.58A1.93 1.93 0 0 1 7 6a1.98 1.98 0 0 1 2-2c.56 0 1.03.2 1.42.58.39.4.58.86.58 1.42 0 .56-.2 1.03-.58 1.42-.4.39-.86.58-1.42.58Zm0 4a9.64 9.64 0 0 1-9-6 9.64 9.64 0 0 1 3.58-4.36A9.51 9.51 0 0 1 9 0c1.99 0 3.8.55 5.42 1.64A9.64 9.64 0 0 1 18 6a9.64 9.64 0 0 1-3.58 4.37A9.51 9.51 0 0 1 9 12Zm0-1.5c1.56 0 3-.4 4.31-1.2A8.08 8.08 0 0 0 16.35 6a8.08 8.08 0 0 0-3.04-3.3A8.12 8.12 0 0 0 9 1.5c-1.56 0-3 .4-4.31 1.2A8.08 8.08 0 0 0 1.65 6a8.08 8.08 0 0 0 3.04 3.3C6 10.1 7.44 10.5 9 10.5Z"
    />
  </SvgIcon>
);
