import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {useAuth} from '../../entry/hooks/useAuth';
import {currentTenantIdState} from '../atoms/authAtom';
import {networkErrorMessage} from '../utils/networkError';

import {useCustomizedSnackbar} from './useCustomizedSnackbar';

export function useErrorHandling(errorMessage) {
  const history = useHistory();
  const showMessage = useCustomizedSnackbar();
  const {signOut} = useAuth();
  const tenantId = useRecoilValue(currentTenantIdState);

  return useCallback(
    async error => {
      if (
        error instanceof DOMException &&
        error.message === 'The user aborted a request.'
      ) {
        showMessage(networkErrorMessage.TIMOUT_ERROR, 'error');
        return;
      }

      switch (error.response?.status) {
        case 401:
          signOut();
          history.replace(`/${tenantId}/sign-in`);
          showMessage(error.message, 'error');
          break;
        case 403:
          history.push('/forbidden');
          break;
        case 404:
          showMessage(
            errorMessage ||
              error?.response?.data?.errors?.find(e => e.code === 'Not Found')
                .message,
            'error'
          );
          break;
        default:
          showMessage(errorMessage || error.message, 'error');
          break;
      }
    },
    [showMessage, signOut, tenantId, history, errorMessage]
  );
}
