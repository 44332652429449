import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {fetcher} from '../../../shared/utils/fetcher';
import {HttpResponseError} from '../../../shared/utils/httpResponseError';
import {urls} from '../../../shared/utils/urls';
import {
  DocumentKeys,
  InsuranceType,
  ResponseStatuses,
  ReturnDocumentsType,
} from '../constants/types';
import {fetchPatientDocument} from '../utils/fetchers';

export const usePatientDocuments = (
  consumerUuid: string | null,
  primaryInsuranceId: number | null,
  secondaryInsuranceId: number | null,
  patientFirstName: string,
  patientLastName: string
): UseQueryResult<ReturnDocumentsType> => {
  const getPatientDocument = async (
    url: string
  ): Promise<{link: string; file: Blob}> => {
    try {
      const file = await fetchPatientDocument(url);
      return {link: URL.createObjectURL(file), file};
    } catch (error) {
      if (error instanceof HttpResponseError && error.response.status === 404) {
        throw ResponseStatuses.NO_DOCUMENT;
      }
      throw ResponseStatuses.SOMETHING_WENT_WRONG;
    }
  };

  const currentUser = useRecoilValue(currentUserState);
  const npi = currentUser?.relyingParty.npi;

  const documentMetadata = {
    driverLicenceFront: {
      getDocumentFn: async () => ({
        image: await getPatientDocument(
          urls.patientDriverLicenceFront(consumerUuid)
        ),
        info: undefined,
      }),
      subtitle: 'Front side',
      key: DocumentKeys.driverLicense,
      fileName: `${patientFirstName}_${patientLastName}_dl_front`,
    },
    driverLicenceBack: {
      getDocumentFn: async () => ({
        image: await getPatientDocument(
          urls.patientDriverLicenceBack(consumerUuid)
        ),
        info: undefined,
      }),
      key: DocumentKeys.driverLicense,
      subtitle: 'Back side',
      fileName: `${patientFirstName}_${patientLastName}_dl_back`,
    },
    insurancePrimaryFront: {
      getDocumentFn: async () => {
        if (primaryInsuranceId) {
          const image = await getPatientDocument(
            urls.patientInsuranceFront(consumerUuid, primaryInsuranceId)
          );
          const info = (await fetcher(
            urls.patientInsuranceInfo(consumerUuid, primaryInsuranceId, npi)
          )) as InsuranceType;
          if (info) {
            info.id = primaryInsuranceId;
          }
          return {image, info};
        }
        throw ResponseStatuses.NO_DOCUMENT;
      },
      key: DocumentKeys.insurancePrimary,
      subtitle: undefined,
      fileName: `${patientFirstName}_${patientLastName}_prim_ins`,
    },
    insurancePrimaryBack: {
      getDocumentFn: async () => {
        if (primaryInsuranceId) {
          const image = await getPatientDocument(
            urls.patientInsuranceBack(consumerUuid, primaryInsuranceId)
          );
          return {image, info: undefined};
        }
        throw ResponseStatuses.NO_DOCUMENT;
      },
      key: DocumentKeys.insurancePrimary,
      subtitle: undefined,
      fileName: `${patientFirstName}_${patientLastName}_prim_ins_back`,
    },
    insuranceSecondaryFront: {
      getDocumentFn: async () => {
        if (secondaryInsuranceId) {
          const image = await getPatientDocument(
            urls.patientInsuranceFront(consumerUuid, secondaryInsuranceId)
          );
          const info = (await fetcher(
            urls.patientInsuranceInfo(consumerUuid, secondaryInsuranceId, npi)
          )) as InsuranceType;
          if (info) {
            info.id = secondaryInsuranceId;
          }
          return {image, info};
        }
        throw ResponseStatuses.NO_DOCUMENT;
      },
      key: DocumentKeys.insuranceSecondary,
      subtitle: undefined,
      fileName: `${patientFirstName}_${patientLastName}_second_ins`,
    },
    insuranceSecondaryBack: {
      getDocumentFn: async () => {
        if (secondaryInsuranceId) {
          const image = await getPatientDocument(
            urls.patientInsuranceBack(consumerUuid, secondaryInsuranceId)
          );
          return {image, info: undefined};
        }
        throw ResponseStatuses.NO_DOCUMENT;
      },
      key: DocumentKeys.insuranceSecondary,
      subtitle: undefined,
      fileName: `${patientFirstName}_${patientLastName}_second_ins_back`,
    },
  };

  const getPatientDocuments = async (): Promise<ReturnDocumentsType> => {
    const responses = await Promise.allSettled(
      Object.values(documentMetadata).map(({getDocumentFn}) => getDocumentFn())
    );

    const remappedData = responses.reduce<ReturnDocumentsType>(
      (accumulator, currentValue, idx) => {
        const {key, subtitle, fileName} = Object.values(documentMetadata)[idx];
        if (currentValue.status === 'fulfilled') {
          accumulator[key].push({
            image: currentValue.value.image,
            subtitle,
            status: ResponseStatuses.SUCCESS,
            fileName,
            data: currentValue.value.info,
          });
        } else {
          accumulator[key].push({
            image: null,
            subtitle,
            status: currentValue.reason,
            fileName,
          });
        }

        return accumulator;
      },
      {
        driverLicense: [],
        insurancePrimary: [],
        insuranceSecondary: [],
      }
    );

    return remappedData;
  };

  return useQuery({
    queryKey: ['patientDocuments', consumerUuid],
    queryFn: () => getPatientDocuments(),
    keepPreviousData: true,
  });
};
