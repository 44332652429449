import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {QueryClientProvider} from '@tanstack/react-query';
import {initializeApp} from 'firebase/app';
import {RecoilRoot} from 'recoil';

import {App} from './app/app';
import {SnackbarWrapper} from './app/shared/components/snackbarWrapper';
import {StepBackProvider} from './app/shared/providers/stepBackProvider';
import {theme} from './app/shared/styles/theme';
import reportWebVitals from './setup/reportWebVitals';
import {envConfig} from './env';
import {queryClient} from './queryClient';

import './app/shared/styles/fonts.css';
import './app/shared/styles/global.css';

initializeApp({
  apiKey: envConfig.REACT_APP_API_KEY,
  authDomain: envConfig.REACT_APP_AUTH_DOMAIN,
  databaseURL: envConfig.REACT_APP_DATA_BASEURL,
  projectId: envConfig.REACT_APP_PROJECT_ID,
  storageBucket: envConfig.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: envConfig.REACT_APP_MESSAGING_SENDER_ID,
  appId: envConfig.REACT_APP_APP_ID,
  measurementId: envConfig.REACT_APP_MEASUREMENT_ID,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Router>
          <StepBackProvider>
            <SnackbarWrapper>
              <App />
            </SnackbarWrapper>
          </StepBackProvider>
        </Router>
      </RecoilRoot>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
