import React, {FC} from 'react';
import styled from 'styled-components';

import {CustomizedButton} from '../../../shared/components/customizedButton';
import {SmthWentWrongPlaceholderDrawer} from '../../../shared/components/smthWentWrongPlaceholderDrawer';
import {colors} from '../../../shared/styles/theme';
import {ReturnDocumentType} from '../constants/types';
import {isSmthWentWrong} from '../utils/isSmthWentWrong';

type Props = {
  title: string;
  patientDocumentsInfo: ReturnDocumentType[];
  handleClick: () => void;
};

export const PatientDocumentContentSmallSizeDrawer: FC<Props> = ({
  title,
  patientDocumentsInfo,
  handleClick,
}) => {
  const isError = patientDocumentsInfo && isSmthWentWrong(patientDocumentsInfo);
  return (
    <ContentWrapper>
      {!isError ? (
        <>
          <RowWrapper>
            <DocumentName className="title">{title}</DocumentName>
          </RowWrapper>
          <RowWrapper>
            <ImgWrapper>
              {patientDocumentsInfo.map(({image, subtitle}) => (
                <DocumentImg
                  key={image?.link}
                  src={image?.link ?? ''}
                  alt={subtitle}
                  data-testid={image?.link}
                />
              ))}
            </ImgWrapper>
            <CustomizedButton
              style={{width: '134px'}}
              color="primary"
              size="large"
              variant="outlined"
              testID={`${title}-view-details-btn`}
              onClick={handleClick}
            >
              View Details
            </CustomizedButton>
          </RowWrapper>
        </>
      ) : (
        <SmthWentWrongPlaceholderDrawer
          labelText="Something went wrong requesting your information. Please retry."
          onClick={handleClick}
        />
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const RowWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: start;
  height: 100%;
`;

const DocumentName = styled.p`
  max-width: 190px;
  margin: 0 0 8px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentImg = styled.img`
  width: 56px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  margin-right: 8px;
`;
