import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
} from '../../../../../shared/components/list';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ElectronicRecordHorizontalDivider} from '../electronicRecordHorizonralDivider';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ListWithNoDataValue} from '../electronicRecordListWithNoDataRow';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {StyledDivider} from '../styledDivider';
import {FetchedImmunizations} from '../types/immunizationTypes';
import {CommonSectionProps} from '../types/types';
import {getImmunizations} from '../utils/immunizationsMapper';

const LABELS_COLUMN_WIDTH = '130px';

const useStyles = makeStyles(() => ({
  accordionContentWrapper: {
    gridTemplateColumns: '1fr 1px 1fr',
    padding: '24px',
  },
  reasonCodesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const Immunizations: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const {data, error, isFetching, refetch} = useSectionData<
    FetchedImmunizations[]
  >(urls.getImmunizationsOfConsolidatedMedicalRecord(consumerUuid));

  const immunizations = useMemo(() => getImmunizations(data), [data]);

  const hasNoData = immunizations.length === 0 && !isFetching && !error;

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Immunizations"
    >
      <ElectronicRecordAccordionWrapper>
        {immunizations?.map(({reason_codes, ...immunization}) => (
          <ElectronicRecordAccordion
            key={immunization.id}
            title={immunization.vaccine_code?.display ?? 'Name Unavailable'}
            content={
              <div
                className={cx(
                  listTemplateClasses.wrapper,
                  classes.accordionContentWrapper
                )}
              >
                <div>
                  <ElectronicRecordGroupTitle title="Details" />
                  <ListWithFixedLabelsColumn
                    labelsColumnWidth={LABELS_COLUMN_WIDTH}
                  >
                    <ListItemKey>Vaccine Code</ListItemKey>
                    <ListItemValue testId="vaccine-code">
                      {immunization.vaccine_code?.code}
                    </ListItemValue>
                    <ListItemKey>Lot Number</ListItemKey>
                    <ListItemValue testId="lot-number">
                      {immunization.lot_number}
                    </ListItemValue>
                    <ListItemKey>Dose</ListItemKey>
                    <ListItemValue testId="dose">
                      {immunization.dose}
                    </ListItemValue>
                    <ListItemKey>Occurrence Date</ListItemKey>
                    <ListItemValue testId="occurrence-date">
                      {immunization.occurrence}
                    </ListItemValue>
                    <ListItemKey>Start Date</ListItemKey>
                    <ListItemValue testId="start_date">
                      {immunization.encounter_date?.start}
                    </ListItemValue>
                    <ListItemKey>End Date</ListItemKey>
                    <ListItemValue testId="end_date">
                      {immunization.encounter_date?.end}
                    </ListItemValue>
                    <ListItemKey>Primary Source</ListItemKey>
                    <ListItemValue testId="primary-source">
                      {immunization.primary_source}
                    </ListItemValue>
                    <ListItemKey>Information Source</ListItemKey>
                    <ListItemValue testId="information-source">
                      {immunization.information_source}
                    </ListItemValue>
                    <ListItemKey>Status</ListItemKey>
                    <ListItemValue testId="status">
                      {immunization.status}
                    </ListItemValue>
                    <ListItemKey>Status Reason</ListItemKey>
                    <ListItemValue testId="status_reason">
                      {immunization.status_reason}
                    </ListItemValue>
                    <ListItemKey>Performer</ListItemKey>
                    <ListItemValue testId="performer">
                      {immunization.performers}
                    </ListItemValue>
                    <ListItemKey>Reaction</ListItemKey>
                    <ListItemValue testId="reaction">
                      {immunization.reaction}
                    </ListItemValue>
                    <ListItemKey>Data Last Updated</ListItemKey>
                    <ListItemValue testId="last_updated">
                      {immunization.last_updated_at}
                    </ListItemValue>
                  </ListWithFixedLabelsColumn>
                </div>

                <StyledDivider orientation="vertical" />

                <div data-testid="codes-block">
                  <ElectronicRecordGroupTitle title="Reason Codes" />

                  {Array.isArray(reason_codes) && reason_codes.length !== 0 ? (
                    <div className={classes.reasonCodesWrapper}>
                      {reason_codes.map(({codes, id}, index) => (
                        <React.Fragment key={id}>
                          <ElectronicRecordHorizontalDivider
                            index={index}
                            length={reason_codes.length}
                          />

                          <ListWithFixedLabelsColumn
                            labelsColumnWidth={LABELS_COLUMN_WIDTH}
                          >
                            <ElectronicRecordCodesList codes={codes} />
                          </ListWithFixedLabelsColumn>
                        </React.Fragment>
                      ))}
                    </div>
                  ) : (
                    <ListWithNoDataValue />
                  )}
                </div>
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
