import {ACTIVITY_LOG_MODE} from '../../../shared/constants/activityLogMode';
import {fetcher, HttpMethod} from '../../../shared/utils/fetcher';
import {formatTimeByZone} from '../../../shared/utils/formatDate';
import {ResponseType} from '../../../shared/utils/responseType';
import {urls} from '../../../shared/utils/urls';

const urlParams = {
  filterDenied: 'admitted',
  beginTime: 'begin_time',
  endTime: 'end_time',
  page: 'page',
  locationId: 'location_ids',
  relyingPartyId: 'relying_party_id',
  policyTypeId: 'policy_type_id',
};

export type ActivityLogFetchConfigs = {
  locationIds: string[];
  policyTypeId: string;
  filterDenied: boolean;
  page?: number;
  beginTime: Date;
  endTime: Date;
  mode: string;
  relyingPartyId: string;
};

const isStreaming = (mode: string) => mode === ACTIVITY_LOG_MODE.LIVE_STREAM;

export const generateActivityLogPageUrl = ({
  locationIds,
  policyTypeId,
  filterDenied,
  page = 0,
  beginTime,
  endTime,
  mode,
  relyingPartyId,
}: ActivityLogFetchConfigs): string => {
  const params = new URLSearchParams();

  params.set(urlParams.relyingPartyId, relyingPartyId);

  params.set(urlParams.policyTypeId, policyTypeId);

  if (filterDenied) {
    params.set(urlParams.filterDenied, 'false');
  }

  if (isStreaming(mode)) {
    params.set(urlParams.page, '0');
  } else {
    params.set(urlParams.page, `${page}`);
    params.set(urlParams.beginTime, formatTimeByZone(beginTime));
    params.set(urlParams.endTime, formatTimeByZone(endTime));
  }

  params.set(urlParams.locationId, locationIds as any);

  return params.toString();
};

const download = (filename: string, blob: Blob): void => {
  const element = document.createElement('a');
  element.download = filename;
  element.href = window.URL.createObjectURL(blob);

  element.click();
};

const getActivityLog = async (
  baseUrl: string,
  configs: ActivityLogFetchConfigs
): Promise<Blob> => {
  const queryParams = generateActivityLogPageUrl(configs);
  const url = `${baseUrl}?${queryParams}`;

  return fetcher(url, HttpMethod.GET, null, {responseType: ResponseType.Blob});
};

export const exportAllActivityLogsAsCsv = async ({
  ...configs
}: ActivityLogFetchConfigs): Promise<void> => {
  const response = await getActivityLog(urls.activityLogExportAsCsv, configs);
  download('activityLogReport.csv', response);
};

export const updateMRN = async (
  consumerUuid: string,
  mrnId: number,
  ehr_id: number,
  mrn: string,
  ehr_link: string,
  relying_party_id: number
): Promise<any> => {
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  await fetcher(urls.updateMRN(consumerUuid, mrnId), HttpMethod.PATCH, {
    ehr: {
      id: ehr_id,
      link: ehr_link,
      relying_party_id,
    },
    mrn,
  });
};

export const addMRN = async (
  consumerUuid: string,
  ehr_id: number,
  mrn: string,
  ehr_link: string,
  relying_party_id: number
): Promise<any> => {
  // Pending refactor on fetcher to TS to accept types different from null | undefined of data
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: disable-next-line
  await fetcher(urls.addMRN(consumerUuid), HttpMethod.POST, {
    ehr: {
      id: ehr_id,
      link: ehr_link,
      relying_party_id,
    },
    mrn,
  });
};
