import React from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {colors} from '../../../../shared/styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '4px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
  },
  default: {
    border: `1px solid ${colors.grey800}`,
    color: colors.grey801,
  },
  warning: {
    border: '1px solid rgba(209, 130, 10, 0.38)',
    color: '#D1820A',
  },
  error: {
    border: '1px solid rgba(235, 87, 87, 0.40)',
    color: '#EB5757',
  },
}));

export type LozengeAppearances = 'error' | 'warning' | 'default';

type Props = {
  appearance: LozengeAppearances;
  children: string;
};
export const Lozenge: React.FC<Props> = ({
  children,
  appearance,
}): JSX.Element => {
  const classnames = useStyles();

  const appearances: {[key: string]: string} = {
    error: classnames.error,
    warning: classnames.warning,
    default: classnames.default,
  };

  return (
    <span className={cx(classnames.root, appearances[appearance])}>
      {children}
    </span>
  );
};
