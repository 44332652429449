import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, CancelButton} from '../../../shared/components/button';
import {InputTextField} from '../../../shared/components/inputTextField';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {colors} from '../../../shared/styles/theme';
import {checkDuplicates} from '../../../shared/utils/checkDuplicates';
import {checkAddFormButtonDisability} from '../../../shared/utils/formikUtils';
import {urls} from '../../../shared/utils/urls';
import {STEP} from '../constants/stepper';
import {addUserValidationSchema} from '../utils/validationSchema';

import {RolesSelect} from './rolesSelect';

export const InformationForm = ({
  setCurStep,
  setInformationState,
  informationState,
  nextStep,
  prevStep,
}) => {
  const history = useHistory();
  const {roles} = useRecoilValue(currentUserState);
  const handleError = useErrorHandling();

  const handleSubmit = useCallback(
    async (values, {setFieldError}) => {
      const result = await checkDuplicates(values.email, urls.checkEmail).catch(
        handleError
      );
      if (result) {
        setFieldError('email', 'This email has already been registered.');
      } else {
        setCurStep(nextStep);
        setInformationState(prevState => ({
          ...prevState,
          phoneNumber: values.phoneNumber.toString(),
          email: values.email.trim(),
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          role: values.role,
        }));
      }
    },
    [nextStep, setCurStep, setInformationState, handleError]
  );
  const onClickBack = useCallback(() => setCurStep(STEP.FIRST), [setCurStep]);
  const onClickCancel = useCallback(() => history.goBack(), [history]);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={informationState}
      validationSchema={addUserValidationSchema}
    >
      {({values, ...props}) => (
        <Form autoComplete="off">
          <Title>Contact information</Title>
          <ContactInfoContainer>
            <InputField name="firstName" label="First name" />
            <InputField name="lastName" label="Last name" />
            <InputField name="email" label="Email address" />
            <InputField name="phoneNumber" label="Phone number" />
          </ContactInfoContainer>
          <PermissionTitle>Permission</PermissionTitle>
          <Field
            as={RolesSelect}
            name="role"
            value={values.role}
            testID="roleSelectInput"
            currentUserRoles={roles}
            openedUserRoles={[]}
          />
          <ButtonWrapper>
            {prevStep || prevStep === STEP.FIRST ? (
              <Button
                data-testid="back-button"
                variant="outlined"
                onClick={onClickBack}
              >
                Back
              </Button>
            ) : (
              <CancelButton onClick={onClickCancel} />
            )}
            <Button
              disabled={checkAddFormButtonDisability(props)}
              type="submit"
            >
              Next
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

InformationForm.propTypes = {
  setCurStep: PropTypes.func,
  setInformationState: PropTypes.func,
  informationState: PropTypes.objectOf(PropTypes.any),
  nextStep: PropTypes.number,
  prevStep: PropTypes.number,
};

const Title = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  color: ${colors.black};
`;

const ContactInfoContainer = styled.div`
  width: 520px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const InputField = styled(InputTextField)`
  margin-bottom: 10px;
`;

const PermissionTitle = styled.p`
  margin: 0 0 8px;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  color: ${colors.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 160px;
`;
