export const firebaseError = {
  INVALID_EMAIL: 'auth/invalid-email',
  WRONG_PASSWORD: 'auth/wrong-password',
  MFA_REQUIRED: 'auth/multi-factor-auth-required',
  EXPIRED_CODE: 'auth/code-expired',
  INVALID_CODE: 'auth/invalid-verification-code',
  TOO_MANY_ATTEMPTS: 'auth/too-many-requests',
  USER_NOT_FOUND: 'auth/user-not-found',
  INTERNET_ERROR: 'auth/network-request-failed',
};

export const errorMessage = {
  LOG_IN: `Unable to login, please contact Health Bank One support via email at support@healthbankone.com.`,
  ACTIVATION: `Unable to activate your account, please contact Health Bank One support via email at support@healthbankone.com.`,
  INVALID_AUTH: 'Invalid email address or password.',
  INVALID_CODE: 'Incorrect verification code, please try again.',
  MFA_TOO_MANY_ATTEMPTS:
    'Sorry, you have requested your verification code too many times. Please try again later.',
  LOG_IN_TOO_MANY_ATTEMPTS:
    'Too many failed login attempts. Please try again later.',
  USER_NOT_FOUND:
    'That account doesn’t exist. Please enter a different email address.',
  NETWORK_ERROR:
    'No internet connection found. Check your connection and try again.',
  EXPIRED_CODE: 'Your verification code has expired. Please send a new code.',
  MANAGER_LOGIN:
    'Please download the manager app, and enter your email and password to sign in.',
  MFA_INCOMPLETE:
    'You must complete sign-up in the Health ID Client Portal before you can log in.',
  MANAGER_APP_LOGIN_ERROR: 'Only Health ID managers can access this app.',
};

export const successMessage = {
  SIGN_UP: 'You’ve signed up successfully!',
  RESEND_CODE: 'Resent successfully.',
  MFA_ENROLLMENT: 'You have created your account successfully.',
};

export const warningMessage = {
  NOT_SET_MFA:
    'You did not complete your account creation. Please enter your verification code.',
};

export const pageTitle = {
  SIGN_IN: 'SIGN IN',
  MANAGER_SIGN_UP: 'CREATE ACCOUNT',
  VERIFICATION: 'VERIFICATION CODE',
};

export const MFA_HINT_NAME = 'MFA Phone number';
