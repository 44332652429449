import {groupBy, mapValues} from 'lodash';

import {signUpFormValidationSchema} from './validationSchema';

export const passwordValidator = async values => {
  try {
    await signUpFormValidationSchema.validate(
      {password: values?.password, confirmPassword: values?.confirmPassword},
      {abortEarly: false}
    );
  } catch (error) {
    const errors = groupBy(error.inner, 'path');
    return mapValues(errors, errorList => errorList.map(e => e.message));
  }
  return {};
};
