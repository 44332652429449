import {useRecoilValue} from 'recoil';

import {currentUserState} from '../atoms/authAtom';
import {previousPathState} from '../atoms/previousPathState';
import {routesAvailableForDefaultRedirect} from '../constants/roles';
import {isRoleMatched} from '../utils/user';

type ReturnHookType = {
  getPreviousLocationPath: (defaultPath?: string) => string;
};
export const useMoveToPreviousLocation = (): ReturnHookType => {
  const currentUser = useRecoilValue(currentUserState);

  const firstAvailableRoute = routesAvailableForDefaultRedirect.find(route =>
    isRoleMatched(currentUser?.type, route.roles)
  );

  const previousPath = useRecoilValue(previousPathState);

  const getPreviousLocationPath = (defaultPath?: string): string => {
    if (previousPath) {
      return previousPath;
    }

    if (defaultPath) {
      return defaultPath;
    }

    return firstAvailableRoute?.path ?? '/';
  };

  return {
    getPreviousLocationPath,
  };
};
