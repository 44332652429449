import styled from 'styled-components';

import {colors} from '../styles/theme';

export const StatusText = styled('p')<{color: string}>`
  font-size: 12px;
  font-weight: 600;
  color: ${colors.white};
  background-color: ${props => props.color};
  padding: 0 18px 0;
  height: 26px;
  border-radius: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;
