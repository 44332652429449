export const USA_STATES_LIST = [
  {id: 1, label: 'AL'},
  {id: 2, label: 'AK'},
  {id: 3, label: 'AZ'},
  {id: 4, label: 'AR'},
  {id: 5, label: 'AS'},
  {id: 6, label: 'CA'},
  {id: 7, label: 'CO'},
  {id: 8, label: 'CT'},
  {id: 9, label: 'DE'},
  {id: 10, label: 'DC'},
  {id: 11, label: 'FL'},
  {id: 12, label: 'GA'},
  {id: 13, label: 'GU'},
  {id: 14, label: 'HI'},
  {id: 15, label: 'ID'},
  {id: 16, label: 'IL'},
  {id: 17, label: 'IN'},
  {id: 18, label: 'IA'},
  {id: 19, label: 'KS'},
  {id: 20, label: 'KY'},
  {id: 21, label: 'LA'},
  {id: 22, label: 'ME'},
  {id: 23, label: 'MD'},
  {id: 24, label: 'MA'},
  {id: 25, label: 'MI'},
  {id: 26, label: 'MN'},
  {id: 27, label: 'MS'},
  {id: 28, label: 'MO'},
  {id: 29, label: 'MT'},
  {id: 30, label: 'NE'},
  {id: 31, label: 'NV'},
  {id: 32, label: 'NH'},
  {id: 33, label: 'NJ'},
  {id: 34, label: 'NM'},
  {id: 35, label: 'NY'},
  {id: 36, label: 'NC'},
  {id: 37, label: 'ND'},
  {id: 38, label: 'MP'},
  {id: 39, label: 'OH'},
  {id: 40, label: 'OK'},
  {id: 41, label: 'OR'},
  {id: 42, label: 'PA'},
  {id: 43, label: 'PR'},
  {id: 44, label: 'RI'},
  {id: 45, label: 'SC'},
  {id: 46, label: 'SD'},
  {id: 47, label: 'TN'},
  {id: 48, label: 'TX'},
  {id: 49, label: 'TT'},
  {id: 50, label: 'UT'},
  {id: 51, label: 'VT'},
  {id: 52, label: 'VA'},
  {id: 53, label: 'VI'},
  {id: 54, label: 'WA'},
  {id: 55, label: 'WV'},
  {id: 56, label: 'WI'},
  {id: 57, label: 'WY'},
];
