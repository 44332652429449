import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../shared/atoms/authAtom';
import {getCrumbs} from '../../shared/utils/getCrumbs';
import {isUserIssuers} from '../../shared/utils/user';
import {NotFoundPage} from '../notFound';

import {ActivityLogPage} from './pages/activityLogPage';
import {SelectRelyingParty} from './pages/selectRelyingParty';

export const ActivityLogListPage = () => {
  const {roles, relyingParty} = useRecoilValue(currentUserState);

  const routes = [
    {
      path: '/activity-log',
      exact: true,
      component: isUserIssuers(roles)
        ? SelectRelyingParty
        : () => (
            <Redirect to={`/activity-log/relying-party/${relyingParty.id}`} />
          ),
      title: 'Select a relying party',
    },
    {
      path: '/activity-log/relying-party/:relyingPartyId',
      exact: true,
      component: ActivityLogPage,
      title: isUserIssuers(roles) ? 'View activity log' : 'Activity log',
    },
    {
      path: '*',
      component: NotFoundPage,
    },
  ];

  return (
    <Switch>
      {routes.map(route => {
        const ContentComponent = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => (
              <ContentComponent {...getCrumbs(props, routes)} title="Reports" />
            )}
          />
        );
      })}
    </Switch>
  );
};
