import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {REFETCH_INTERVAL} from '../../../shared/constants/refetchInterval';
import {ProviderInboxEventsType} from '../constatnts/types';
import {newEventsCount} from '../types/newEventsCount';
import {fetchInboxNewEventsCount} from '../utils/fetchers';

const getInboxNewEventsCount = async () => {
  try {
    const requestBody: newEventsCount = await fetchInboxNewEventsCount();

    return requestBody?.count;
  } catch (e) {
    throw new Error('Inbox synchronization events error');
  }
};
export const useInboxNewEventsCount = (): UseQueryResult<
  ProviderInboxEventsType[]
> =>
  useQuery({
    queryKey: ['inbox-new-events-count'],
    queryFn: () => getInboxNewEventsCount(),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchInterval: () => REFETCH_INTERVAL,
  });
