import React, {useMemo, useState} from 'react';
import styled from 'styled-components';

import {StepSidebar} from '../../../shared/components/stepBar';
import {NPI_STATES, NPI_STATUSES} from '../../../shared/constants/npi';
import useSearchNPI from '../../../shared/hooks/useSearchNPI';
import {STEP} from '../constants/stepper';
import {INITIAL_USER_PORTAL} from '../constants/userPortal';

import {ConfirmUser} from './confirmUser';
import {InviteUser} from './inviteUser';
import SelectUserType from './selectUserType';

const invitationSteps = [
  'Select user type',
  'Invite admin portal user',
  'Confirm invitation',
];

const getNpiState = npiStatus => {
  switch (npiStatus) {
    case NPI_STATES.ACTIVE:
      return {
        message: 'You can invite this user',
        status: NPI_STATUSES.SUCCESS,
      };
    case NPI_STATES.ALREADY_EXISTS:
      return {
        message: 'The user with this NPI is already registered',
        status: NPI_STATUSES.ERROR,
      };
    case NPI_STATES.NOT_FOUND:
      return {
        message: 'NPI is not found',
        status: NPI_STATUSES.ERROR,
      };
    case NPI_STATES.SOMETHING_WENT_WRONG:
      return {
        message: 'Something went wrong',
        status: NPI_STATUSES.ERROR,
      };
    default:
      return {
        message: 'Please, specify the NPI number and press Search button.',
        status: NPI_STATUSES.DEFAULT,
      };
  }
};

export const UserInvitationPageRelyingParty = () => {
  const [activeStep, setActiveStep] = useState(STEP.FIRST);
  const [informationState, setInformationState] = useState(INITIAL_USER_PORTAL);
  const {data, isLoading, onSearchNPI, onClearDataNPI, npiStatus} =
    useSearchNPI();

  const searchNpiState = useMemo(() => getNpiState(npiStatus), [npiStatus]);

  return (
    <LayoutColumn>
      <StepSidebar steps={invitationSteps} activeStep={activeStep} />
      <Content>
        {activeStep === STEP.FIRST && (
          <SelectUserType
            setStepper={setActiveStep}
            setInformation={setInformationState}
            information={informationState}
            dataNPI={data}
            isLoadingNPI={isLoading}
            onSearchNPI={onSearchNPI}
            npiState={searchNpiState}
            onClearDataNPI={onClearDataNPI}
            nextStep={STEP.SECOND}
          />
        )}
        {activeStep === STEP.SECOND && (
          <InviteUser
            setStepper={setActiveStep}
            setInformation={setInformationState}
            information={informationState}
            nextStep={STEP.THIRD}
            prevStep={STEP.FIRST}
          />
        )}
        {activeStep === STEP.THIRD && (
          <ConfirmUser
            setStepper={setActiveStep}
            information={informationState}
            prevStep={STEP.SECOND}
          />
        )}
      </Content>
    </LayoutColumn>
  );
};

const LayoutColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;

const Content = styled.div`
  padding-top: 18px;
  padding-left: 41px;
  flex: 1;
`;
