import React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {StyledCell} from '../../../shared/components/table/styledCell';
import {ActionIcon} from '../../../shared/icons/actionIcon';
import {PenIcon} from '../../../shared/icons/penIcon';
import {TrashIcon} from '../../../shared/icons/trashIcon';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {formatHours} from '../../../shared/utils/formatter';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';
import {policyDataFormatter} from '../utils/policyFormatter';

type Policy = {
  name: string;
  vaccine_required: string;
  patient_medical_record_number_required: boolean;
  patient_full_name_required: boolean;
  patient_address_required: boolean;
  patient_birth_date_required: boolean;
  patient_email_required: boolean;
  patient_phone_number_required: boolean;
  patient_gender_required: boolean;
  patient_insurance_info_required: boolean;
  maximum_self_assessment_age: number;
  temperature_check_required: boolean;
  social_distancing_required: boolean;
  mask_required: boolean;
  hand_sanitizer: boolean;
  locations: {id: number; name: string}[];
};

type CellType = {
  row: {
    original: Policy;
  };
};

type ActionMethods = {
  handleDetail: (location: Policy) => void;
  handleEdit: (location: Policy) => void;
  handleDelete: (location: Policy) => void;
};

const PolicyTableActionButtons = ({
  actionMethods: {handleDetail, handleEdit, handleDelete},
  cell: {
    row: {original},
  },
}: {
  actionMethods: ActionMethods;
  cell: CellType;
}): JSX.Element => {
  const currentUser = useRecoilValue(currentUserState);

  return isUserRelyingPartyAdminOrOwner(currentUser?.roles) ? (
    <>
      <ActionTableButton
        title="Detail"
        testId="Detail-icon"
        onClick={() => handleDetail(original)}
      >
        <ActionIcon />
      </ActionTableButton>
      <ActionTableButton
        title="Edit"
        testId="Edit-icon"
        onClick={() => handleEdit(original)}
      >
        <PenIcon />
      </ActionTableButton>
      <ActionTableButton
        title="Delete"
        testId="Delete-icon"
        onClick={() => handleDelete(original)}
      >
        <TrashIcon />
      </ActionTableButton>
    </>
  ) : (
    <></>
  );
};

const getRegistrationColumns = () => [
  {
    id: 'mrn',
    header: 'MRN',
    width: '90px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.patient_medical_record_number_required
          )}
        />
      );
    },
  },
  {
    id: 'fullName',
    header: 'Full name',
    width: '130px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.patient_full_name_required
          )}
        />
      );
    },
  },
  {
    id: 'address',
    header: 'Address',
    width: '100px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.patient_address_required)}
        />
      );
    },
  },
  {
    id: 'dateOfBirth',
    header: 'Date of birth',
    width: '145px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.patient_birth_date_required
          )}
        />
      );
    },
  },
  {
    id: 'email',
    header: 'Email',
    width: '100px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.patient_email_required)}
        />
      );
    },
  },
  {
    id: 'phoneNumber',
    header: 'Phone number',
    width: '160px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.patient_phone_number_required
          )}
        />
      );
    },
  },
  {
    id: 'gender',
    header: 'Gender',
    width: '90px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.patient_gender_required)}
        />
      );
    },
  },
  {
    id: 'insurance',
    header: 'Insurance',
    width: '100px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.patient_insurance_info_required
          )}
        />
      );
    },
  },
];
const getEntryColumns = () => [
  {
    id: 'vaccine_required',
    header: 'COVID-19 vaccination',
    width: '220px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.vaccine_required)}
        />
      );
    },
  },
  {
    id: 'maximum_self_assessment_age',
    header: 'Self check frequency',
    width: '220px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={formatHours(cell.row.original.maximum_self_assessment_age)}
        />
      );
    },
  },
  {
    id: 'mask_required',
    header: 'Mask',
    width: '90px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.mask_required)}
        />
      );
    },
  },
  {
    id: 'temperature_check_required',
    header: 'Temperature check',
    width: '190px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.temperature_check_required
          )}
        />
      );
    },
  },
  {
    id: 'social_distancing_required',
    header: 'Social distancing',
    width: '180px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(
            cell.row.original.social_distancing_required
          )}
        />
      );
    },
  },
  {
    id: 'hand_sanitizer',
    header: 'Hand sanitizer',
    width: '160px',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell
          info={policyDataFormatter(cell.row.original.hand_sanitizer)}
        />
      );
    },
  },
];

export const getRegistrationPolicyTableConfig = (
  type: string,
  actionMethods: {
    handleDetail: (location: Policy) => void;
    handleEdit: (location: Policy) => void;
    handleDelete: (location: Policy) => void;
  }
): (ColumnDef<Policy> & AdditionalTableProps)[] => {
  const middleColumns =
    type === 'REGISTRATION' ? getRegistrationColumns() : getEntryColumns();

  return [
    {
      id: 'name',
      header: 'Policy',
      width: '200px',
      isStickyLeft: true,
      cell(cell: CellType): JSX.Element {
        return <StyledCell info={cell.row.original.name} fontWeight={700} />;
      },
    },
    ...middleColumns,
    {
      id: 'assignedLocations',
      header: 'Assigned locations',
      width: '190px',
      cell(cell: CellType): JSX.Element {
        return <StyledCell info={String(cell.row.original.locations.length)} />;
      },
    },
    {
      header: 'Action',
      cell(cell: CellType): JSX.Element {
        return (
          <PolicyTableActionButtons cell={cell} actionMethods={actionMethods} />
        );
      },
      width: '150px',
      isStickyRight: true,
    },
  ];
};
