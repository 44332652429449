import React, {useCallback, useRef, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {getAuth} from 'firebase/auth';
import {isNil} from 'lodash';
import styled from 'styled-components';

import {NoPropsComponent} from '../../shared/interfaces/common';
import {colors} from '../../shared/styles/theme';
import {SignInForm} from '../components/signInForm';
import {SignPageTemplate} from '../components/signPageTemplate';
import {VerificationTip} from '../components/verificationTip';
import {VerifySMS} from '../components/verifySMS';
import {errorMessage, pageTitle} from '../constants/constants';
import {useAuth} from '../hooks/useAuth';
import {useSkipMfa} from '../hooks/useSkipMfa';
import {deleteMfaRecord, findRecentMfaRecordUuid} from '../utils/mfaRecord';

export const SignInPage: NoPropsComponent = () => {
  const history = useHistory();
  const {tenantId} = useParams<{tenantId: string}>();

  getAuth().tenantId = tenantId;
  const {state} = useLocation<{from: string}>();
  const {
    signIn,
    signInWithCustomToken,
    startMfaForSignIn,
    startMfaForSignUp,
    finishMfaForSignIn,
    finishMfaForSignUp,
    signOut,
  } = useAuth();

  const [email, setEmail] = useState('');
  const [title, setTitle] = useState(pageTitle.SIGN_IN);
  const [loading, setLoading] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);

  const resolverRef = useRef(null);
  const phoneNumber = useRef(null);

  const {generateMfaRecord, getCustomToken} = useSkipMfa(email, tenantId);

  const handleGoBack = useCallback(async () => {
    await signOut();
    setAuthenticating(false);
    setTitle(pageTitle.SIGN_IN);
  }, [signOut]);

  const signInAndEnrollMfaIfNeeded = useCallback(
    async (signInEmail, signInPassword, verifier) => {
      // For account with MFA, signIn method will throw "MFA required" error
      await signIn(signInEmail, signInPassword);

      // If no error throwed above, it means the user account has not set MFA
      await startMfaForSignUp(verifier);

      setAuthenticating(true);
      setTitle(pageTitle.VERIFICATION);
    },
    [signIn, startMfaForSignUp]
  );

  const finishEnrollMFA = useCallback(
    async verificationCode => {
      await finishMfaForSignUp(verificationCode);

      generateMfaRecord();
      localStorage.setItem('closeWindowTimestamp', Date.now().toString());
      history.replace('/');
    },
    [finishMfaForSignUp, generateMfaRecord, history]
  );

  const continueWithTheRemainingMfaSteps = useCallback(
    async (resolverMFA, recaptchaVerifier) => {
      const maskedPhoneNumber = await startMfaForSignIn(
        resolverMFA,
        recaptchaVerifier
      );
      resolverRef.current = resolverMFA;
      phoneNumber.current = maskedPhoneNumber;
      setAuthenticating(true);
      setTitle(pageTitle.VERIFICATION);
    },
    [startMfaForSignIn]
  );

  const tryToSignInWithoutMfa = useCallback(
    async (userEmail, password, resolverMFA, recaptchaVerifier) => {
      const recentMfaRecordUuid = await findRecentMfaRecordUuid(userEmail);

      if (isNil(recentMfaRecordUuid)) {
        await continueWithTheRemainingMfaSteps(resolverMFA, recaptchaVerifier);
        return;
      }

      try {
        // Front-end thinks MFA skippable, still need back-end check
        const customToken = await getCustomToken(
          recentMfaRecordUuid,
          userEmail,
          password
        );
        await signInWithCustomToken(customToken);
        localStorage.setItem('closeWindowTimestamp', Date.now().toString());
        history.replace(state?.from || '/');
      } catch (error) {
        // If any error is throwed in this request, we need to delete this MFA record locally
        // and then sign in through normal MFA progress.
        // Note that we checked if password valid before,
        // therefore the error here can't be invalid password error,
        // and we can delete local mfa record when other error occurs
        await deleteMfaRecord(recentMfaRecordUuid);

        await continueWithTheRemainingMfaSteps(resolverMFA, recaptchaVerifier);
      }
    },
    [
      continueWithTheRemainingMfaSteps,
      getCustomToken,
      history,
      signInWithCustomToken,
      state,
    ]
  );

  const continueWithTheRemainingSignInSteps = useCallback(
    async (userEmail, password, resolverMFA, recaptchaVerifier) => {
      await tryToSignInWithoutMfa(
        userEmail,
        password,
        resolverMFA,
        recaptchaVerifier
      );
    },
    [tryToSignInWithoutMfa]
  );

  const completeMFA = useCallback(
    async verificationCode => {
      await finishMfaForSignIn(verificationCode, resolverRef.current);

      generateMfaRecord();

      localStorage.setItem('closeWindowTimestamp', Date.now().toString());

      history.replace(state?.from || '/');
    },
    [finishMfaForSignIn, generateMfaRecord, history, state]
  );

  const resendCode = useCallback(
    verifier => {
      if (isNil(resolverRef.current)) {
        return startMfaForSignUp(verifier);
      }
      return startMfaForSignIn(resolverRef.current, verifier);
    },
    [resolverRef, startMfaForSignIn, startMfaForSignUp]
  );

  return (
    <SignPageTemplate title={title} isLogoLeft loading={loading}>
      {authenticating ? (
        <VerifySMS
          onSubmit={isNil(resolverRef.current) ? finishEnrollMFA : completeMFA}
          resendCode={resendCode}
          onBack={handleGoBack}
          setLoading={setLoading}
          loading={loading}
          phoneNumber={phoneNumber.current}
          error={errorMessage.LOG_IN}
        />
      ) : (
        <>
          <SignInForm
            setLoading={setLoading}
            continueWithTheRemainingSignInSteps={
              continueWithTheRemainingSignInSteps
            }
            signInAndEnrollMfaIfNeeded={signInAndEnrollMfaIfNeeded}
            email={email}
            setEmail={setEmail}
          />
          <Link to="/reset-password">
            <ResetPassword>Forgot password?</ResetPassword>
          </Link>
          <VerificationTip />
        </>
      )}
    </SignPageTemplate>
  );
};

const ResetPassword = styled.p`
  color: ${colors.primary};
  text-decoration: underline;
  margin-top: 20px;
  font-size: 12px;
`;
