/* eslint-disable indent */
import {v4 as uuid} from 'uuid';

import {FetchedImages, Images} from '../types/imagingStudyTypes';

import {getCodeSystemLabel} from './codeLabels';
import {formatDate, formatStringsArray, wrapCodeToArray} from './mappersUtils';

export const getImages = (images?: FetchedImages[]): Images[] => {
  if (!images) {
    return [];
  }

  return images.map(
    ({series, last_updated_at, notes, encounter, ...image}) => ({
      ...image,
      id: uuid(),
      last_updated_at: formatDate(last_updated_at),
      series: Array.isArray(series)
        ? series.map(item => ({
            ...item,
            id: uuid(),
            instance: Array.isArray(item.instance)
              ? item.instance.map(instance => ({
                  id: uuid(),
                  ...instance,
                  sop_class: {
                    ...instance.sop_class,
                    system: getCodeSystemLabel(instance.sop_class?.system),
                  },
                }))
              : [],
            started_date: formatDate(item.started_date),
            performer: formatStringsArray(item?.performer),
            modality: wrapCodeToArray(item?.modality),
            body_site: wrapCodeToArray(item?.body_site),
            laterality: wrapCodeToArray(item.laterality),
          }))
        : [],

      notes: notes
        ? notes.reduce<Images['notes']>((acc, curr) => {
            if (curr.text) {
              acc.push({
                ...curr,
                id: uuid(),
                recordedDate: formatDate(curr.recordedDate),
              });
            }

            return acc;
          }, [])
        : [],
      encounter: encounter
        ? {
            ...encounter,
            period: {
              start: formatDate(encounter.period?.start),
              end: formatDate(encounter.period?.end),
            },
            classification: wrapCodeToArray(encounter?.classification),
            type: wrapCodeToArray(encounter?.type),
          }
        : null,
    })
  );
};
