import {atom} from 'recoil';

import {envConfig} from '../../../env';
import {User} from '../interfaces/user';

export const currentUserState = atom<User | null>({
  key: 'currentSignedInUser',
  default: null,
});

export const currentTenantIdState = atom({
  key: 'currentTenantId',
  default: envConfig.REACT_APP_TENANT_ID,
});
