import {atom} from 'recoil';

import {initialResendInvitationModalWindowsState} from '../constants/initialResendInvitationModalWindowsState';
import {HandleResendInvitationModalWindowsType} from '../constants/types';

export const handleResendInvitationModalWindowsState =
  atom<HandleResendInvitationModalWindowsType>({
    key: 'isShowHandleResendInvitationModalWindows',
    default: initialResendInvitationModalWindowsState,
  });
