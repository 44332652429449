/* eslint-disable indent */
import {v4 as uuid} from 'uuid';

import {dateFormat} from '../../../../../shared/constants/dateFormat';
import {
  FamilyHistories,
  FetchedFamilyHistories,
} from '../types/familyHistoryTypes';

import {formatDate} from './mappersUtils';

export const getFamilyHistories = (
  familyHistories?: FetchedFamilyHistories[]
): FamilyHistories[] => {
  if (!familyHistories) {
    return [];
  }

  return familyHistories.map(
    ({
      birth_date,
      deceased,
      recorded_date,
      conditions,
      last_updated_at,
      reason_codes,
      ...familyHistory
    }) => ({
      ...familyHistory,
      id: uuid(),
      deceased: deceased
        ? {
            ...deceased,
            value_date: formatDate(deceased?.value_date),
            value_period: {
              ...deceased?.value_period,
              start: formatDate(deceased?.value_period?.start),
              end: formatDate(deceased?.value_period?.end),
            },
          }
        : null,
      conditions:
        conditions && Array.isArray(conditions)
          ? conditions.map(condition => ({
              id: uuid(),
              ...condition,
            }))
          : [],
      birth_date: formatDate(birth_date, dateFormat),
      recorded_date: formatDate(recorded_date),
      last_updated_at: formatDate(last_updated_at),
      reason_codes:
        reason_codes && Array.isArray(reason_codes)
          ? reason_codes.map(code => ({
              id: uuid(),
              ...code,
            }))
          : [],
    })
  );
};
