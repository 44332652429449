import React from 'react';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import PenIcon from '../../../../assets/icons/edit.svg';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, DeleteButton} from '../../../shared/components/button';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {Subheader} from '../../../shared/components/subheader';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';

import {LocationTable} from './locationTable';
import {PolicyDetail} from './policyDetail';

export const PolicyDetailTable = ({
  policies = {},
  startDeleting,
  startEditing,
}) => {
  const {roles} = useRecoilValue(currentUserState);
  return (
    <>
      <Subheader title={policies.name}>
        {isUserRelyingPartyAdminOrOwner(roles) && (
          <>
            <Button
              onClick={startEditing}
              startIcon={<img src={PenIcon} alt="editIcon" />}
              testID="editButton"
              position="section"
            >
              Edit
            </Button>
            <DeleteButton onClick={startDeleting} />
          </>
        )}
      </Subheader>
      <MainContainer>
        <PolicyDetail policies={policies} />
        <Subtitle>{`Assigned locations: ${policies.locations?.length}`}</Subtitle>
        {!isEmpty(policies.locations) ? (
          <LocationTable
            locations={policies.locations}
            relyingPartyName={policies.relying_party_name}
            policyId={policies.id}
          />
        ) : (
          <NoDataPlaceholder noDataText="No locations assigned to this policy." />
        )}
      </MainContainer>
    </>
  );
};

PolicyDetailTable.propTypes = {
  policies: PropTypes.objectOf(PropTypes.any),
  startEditing: PropTypes.func,
  startDeleting: PropTypes.func,
};

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;

const MainContainer = styled.div`
  padding: 16px 40px;
`;
