import React, {FC} from 'react';
import styled from 'styled-components';

import smthWentWrongIcon from '../../../assets/icons/smthWentWrongIcon.svg';
import {colors} from '../styles/theme';

import {CustomizedButton} from './customizedButton';

export const SmthWentWrongPlaceholderDrawer: FC<{
  labelText: string;
  onClick: VoidFunction;
}> = ({labelText, onClick}) => (
  <Container>
    <ImageWrapper>
      <img src={smthWentWrongIcon} alt="smthWentWrongIcon" height={45} />
    </ImageWrapper>
    <LabelWrapper>{labelText}</LabelWrapper>
    <ButtonWrapper>
      <CustomizedButton
        variant="contained"
        color="primary"
        onClick={onClick}
        testID="retry_btn"
        style={{width: 90}}
      >
        Retry
      </CustomizedButton>
    </ButtonWrapper>
  </Container>
);

const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ImageWrapper = styled.div`
  margin-top: 2px;
  margin-right: 4px;
  margin-left: -10px;
`;

const LabelWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
  color: ${colors.grey801};
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
  margin-left: 16px;
`;
