export enum ALLOWED_FILE_TYPE {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif',
}

export const ALLOWED_FILE_TYPES_LIST = [
  ALLOWED_FILE_TYPE.PDF,
  ALLOWED_FILE_TYPE.JPG,
  ALLOWED_FILE_TYPE.JPEG,
  ALLOWED_FILE_TYPE.PNG,
  ALLOWED_FILE_TYPE.GIF,
  '',
];

export type Message = {
  text: string & React.ReactNode;
  isUser: boolean;
  isFile: boolean;
  fileType: ALLOWED_FILE_TYPE | '';
  fileName: string | undefined;
  fileShowInChat: string | undefined;
  isRetry?: boolean;
  isAMedicalRecord?: boolean;
};

export type MessageResponse = {
  text: string;
  role: string;
  file?: MessageFileResponse;
};

export type MessageFileResponse = {
  id: string;
  medical_records: boolean;
  original_file_name: string;
  content_type: string;
  content: string;
};

export type ChatHistoryConversation = {
  title: string;
  conversationId: string;
  lastMessageAt: string;
};

export type ChatHistoryConversationResponse = {
  title: string;
  conversation_id: string;
  last_message_at: Date;
};

export type ChatConversation = {
  title: string;
  conversation_id: string;
  last_message_at: Date;
};

export type CareGuide = {
  messages: Message[];
  chatId: string | null;
};

export type AddMessageFunction = (
  messageText: string,
  isFile?: boolean,
  fileType?: ALLOWED_FILE_TYPE | '',
  fileName?: string,
  fileShowInChat?: string,
  saveFileInLinkResponse?: FileUploadLinkResponse,
  isAMedicalRecord?: boolean
) => Promise<void>;

export type UpdateMessageFunction = (
  messageText: string,
  fileType: ALLOWED_FILE_TYPE | '',
  fileName: string | null,
  saveFileInLinkResponse: FileUploadLinkResponse,
  isAMedicalRecord?: boolean
) => Promise<void>;

export type FileUploadLinkResponse = {
  upload_url: string;
  file_name: string;
  content_type: string;
  max_content_length: number;
};
