import React, {FC} from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {ConfirmModal} from '../../../../shared/components/confirmModal';

interface ChatHistoryDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChatHistoryDeleteModal: FC<ChatHistoryDeleteModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    title="Delete Chat?"
    variant="h6"
    confirmBtnText="Delete"
    confirmBtnVariant="dangerous"
    minWidth={464}
    data-testid="history-confirm-modal"
  >
    <ConfirmModalContentWrapper>
      <Typography component="p">
        This will delete user request and Care Guide response.
      </Typography>
    </ConfirmModalContentWrapper>
  </ConfirmModal>
);

const ConfirmModalContentWrapper = styled.div`
  margin: 16px 0 40px;
`;
