import React from 'react';
import styled from 'styled-components';

import noItem from '../../../../../../assets/icons/noItemElectronicRecord.svg';
import {colors} from '../../../../../shared/styles/theme';
import {ElectronicRecordAccordionHeader} from '../electronicRecordAccordionHeader';

interface Props {
  isInProgress?: boolean;
  sectionName: string;
}

export const ElectronicRecordNoData: React.FC<Props> = ({
  isInProgress,
  sectionName,
}) => (
  <>
    {isInProgress && <ElectronicRecordAccordionHeader title={sectionName} />}
    <Container>
      <Icon src={noItem} alt="noItemIcon" />
      {!isInProgress ? (
        <NoItemText>No {sectionName} yet</NoItemText>
      ) : (
        <>
          <NoItemText>Coming Soon</NoItemText>
          <NoItemText>{sectionName} will appear here.</NoItemText>
        </>
      )}
    </Container>
  </>
);

const Container = styled.div`
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  border: 1px solid ${colors.grey800};
  border-radius: 4px;
`;

const Icon = styled.img`
  margin-bottom: 20px;
`;

const NoItemText = styled.div`
  font-style: italic;
  color: ${colors.grey801};
`;
