import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {getCrumbs} from '../../shared/utils/getCrumbs';
import {PolicyDetailPage} from '../healthPolicies/pages/policyDetailPage';
import {NotFoundPage} from '../notFound';
import RelyingPartyInvitationPage from '../relyingPartyInvitation';

import {currentRelyingPartyState} from './atoms/relyingPartyAtom';
import {RelyingPartiesPage} from './pages/relyingPartiesPage';
import {RelyingPartyProfilePage} from './pages/relyingPartyProfilePage';

const routes = [
  {
    path: '/relying-parties',
    exact: true,
    component: RelyingPartiesPage,
    title: 'Relying parties',
  },
  {
    path: '/relying-parties/:id/profile',
    exact: true,
    component: RelyingPartyProfilePage,
  },
  {
    path: '/relying-parties/:id/profile/:policyId',
    component: PolicyDetailPage,
    title: 'Policy detail',
  },
  {
    path: '/relying-parties/invitation',
    exact: true,
    component: RelyingPartyInvitationPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const RelyingPartiesRoutes = () => {
  const relyingParty = useRecoilValue(currentRelyingPartyState);
  return (
    <Switch>
      {routes.map(route => {
        const ContentComponent = route.component;
        return (
          <Route
            path={route.path}
            key={route.path}
            exact={route.exact}
            render={props => (
              <ContentComponent
                {...getCrumbs(props, routes, relyingParty?.relying_party.name)}
                title="Relying party management"
              />
            )}
          />
        );
      })}
    </Switch>
  );
};
