import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {ArrowIcon} from '../../../../shared/icons/arrowIcon';
import {SortingDirections} from '../../constants/types';

type Props = {
  sortingDirection: SortingDirections;
  onClick: () => void;
  children: ReactNode;
};
export const SortingHeaderWrapper: React.FC<Props> = ({
  children,
  onClick,
  sortingDirection,
}) => (
  <Wrapper>
    {children}&nbsp;
    <IconWrapper sortingDirection={sortingDirection}>
      <ArrowIcon onClick={onClick} />
    </IconWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{sortingDirection: SortingDirections}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: ${({sortingDirection}) =>
    sortingDirection === 'ASC' ? 'rotate(180deg)' : null};
`;
