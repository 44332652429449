export const phonePattern = /^[1-9]\d{9}$/;
export const domainPattern =
  /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}$/;
export const passwordPattern = /^[\w[ ~`!@#$%^&*()\-+={}\]|\\:;"'<,>.?/]+$/g;
export const passwordSymbols = /[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]/;
export const codePattern = /^\d{6}$/;
export const onlyNumbersPattern = /^\d+$/;
export const matchRoutePattern = /\/([a-z]|\d|-)*/g;
export const onlyTenDigitsPattern = /\b\d{10}\b/;
export const replaceToNumbersPattern = /[ _()-]/g;
export const replaceLastSlash = /\/$/;
