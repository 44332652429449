import React, {FC, useCallback} from 'react';
import {Typography} from '@material-ui/core';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button} from '../../../shared/components/button';
import {patientDataForRequestState} from '../atoms/patientDataForRequestState';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';

export const SendRequestModalWindow: FC = () => {
  const [patientDataForRequest, setPatientDataForRequest] = useRecoilState(
    patientDataForRequestState
  );

  const handleClose = useCallback(
    () =>
      setPatientDataForRequest({
        ...patientDataForRequest,
        isOpenModalWindow: false,
      }),
    [patientDataForRequest, setPatientDataForRequest]
  );

  const handleSubmit = useCallback(() => {
    handleClose();
    if (patientDataForRequest.data?.requestCallBack) {
      patientDataForRequest.data?.requestCallBack();
    }
  }, [handleClose, patientDataForRequest.data]);

  const isRequestSharingRecords =
    patientDataForRequest.data?.connectionStatus ===
      CONNECTION_STATUSES.CONNECTED &&
    !patientDataForRequest.data?.isUpdateRequested;

  const getRequestTitle = () =>
    isRequestSharingRecords ? 'Request record sharing' : 'Request connection';

  const getRequestDescription = (patientName: string | null = '') =>
    isRequestSharingRecords ? (
      <>
        Patient <strong>{patientName}</strong> declined request to share their
        medical record. Do you want to ask the patient to share their record?
      </>
    ) : (
      <>
        Patient <strong>{patientName}</strong> has not been connected. Do you
        want to ask the patient to connect?
      </>
    );

  return (
    <BaseModalWindow
      open={patientDataForRequest.isOpenModalWindow}
      onClose={handleClose}
    >
      <Title>{getRequestTitle()}</Title>
      <ContentWrapper data-testid="content">
        {getRequestDescription(patientDataForRequest.data?.patientName)}
      </ContentWrapper>
      <ButtonWrapper>
        <Button
          variant="outlined"
          onClick={handleClose}
          testID="cancel-request-btn"
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} testID="request-btn">
          {getRequestTitle()}
        </Button>
      </ButtonWrapper>
    </BaseModalWindow>
  );
};

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
`;

const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
