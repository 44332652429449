import React from 'react';
import styled from 'styled-components';

import Error from '../../../assets/icons/error.svg';
import Success from '../../../assets/icons/success.svg';
import {colors} from '../styles/theme';

export type InlineMessageType = 'success' | 'error';

type Props = {
  inlineMessageType: InlineMessageType;
  text: string;
};

type InlineProps = {
  icon: typeof Success;
  backgroundColor: string;
  borderColor: string;
  contentColor: string;
};

const inlineProps: Map<InlineMessageType, InlineProps> = new Map<
  InlineMessageType,
  InlineProps
>([
  [
    'success',
    {
      icon: Success,
      backgroundColor: colors.green50,
      borderColor: colors.green100,
      contentColor: colors.green,
    },
  ],
  [
    'error',
    {
      icon: Error,
      backgroundColor: colors.red50,
      borderColor: colors.red100,
      contentColor: colors.red,
    },
  ],
]);

export const InlineMessage: React.FC<Props> = ({inlineMessageType, text}) => {
  const inlineObject = inlineProps.get(inlineMessageType);
  if (!inlineObject) {
    return null;
  }
  return (
    <Container
      backgroundColor={inlineObject.backgroundColor}
      borderColor={inlineObject.borderColor}
    >
      <img src={inlineObject.icon} alt="at" />
      <Content color={inlineObject.contentColor}>{text}</Content>
    </Container>
  );
};

const Container = styled.div<{backgroundColor: string; borderColor: string}>`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${props => props.borderColor};
  background: ${props => props.backgroundColor};
`;

export const Content = styled.p<{color: string}>`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.color};
  margin: 0;
`;
