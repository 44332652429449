import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {Form, Formik} from 'formik';
import styled from 'styled-components';

import BackIcon from '../../../assets/icons/back.svg';
import {Button} from '../../shared/components/button';
import {InputTextField} from '../../shared/components/inputTextField';
import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {ResendPrompt} from '../components/resendPrompt';
import {SignPageTemplate} from '../components/signPageTemplate';
import {errorMessage, firebaseError} from '../constants/constants';
import {forgotPasswordFormValidationSchema} from '../utils/validationSchema';

const resendText = "Didn't receive email?";

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiButton-root': {
      width: 256,
      height: 32,
      fontSize: 12,
    },
  },
}));

export const ResetPasswordPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const showMessage = useCustomizedSnackbar();
  const handleSubmit = useCallback(
    async values => {
      try {
        await sendPasswordResetEmail(getAuth(), values.email);
        showMessage('Email sent successfully.', 'success');
      } catch (error) {
        if (error.code === firebaseError.USER_NOT_FOUND) {
          showMessage(errorMessage.USER_NOT_FOUND, 'error');
        } else if (error.code === firebaseError.INTERNET_ERROR) {
          showMessage(errorMessage.NETWORK_ERROR, 'error');
        } else {
          showMessage(error.message, 'error');
        }
      }
    },
    [showMessage]
  );

  const onClickBack = useCallback(() => history.goBack(), [history]);
  const onClickResend = useCallback(
    values => () => handleSubmit(values),
    [handleSubmit]
  );

  return (
    <SignPageTemplate title="RESET PASSWORD" isLogoLeft>
      <BackButton src={BackIcon} onClick={onClickBack} />

      <SendEmailTip>
        An email with a reset password link will be sent to you.
      </SendEmailTip>
      <Formik
        initialValues={{email: ''}}
        validationSchema={forgotPasswordFormValidationSchema}
        onSubmit={handleSubmit}
      >
        {({isValidating, isSubmitting, isValid, dirty, values}) => (
          <Form>
            <EmailInput
              name="email"
              testID="emailInput"
              label="Email address"
              placeholder="Email address"
              type="text"
              size="auto"
            />
            <div className={classes.root}>
              <Button
                type="submit"
                disabled={!isValid || isValidating || isSubmitting}
              >
                SEND EMAIL
              </Button>
            </div>
            <ResendPrompt
              text={resendText}
              onClick={onClickResend(values)}
              disabled={!isValid || !dirty}
            />
          </Form>
        )}
      </Formik>
    </SignPageTemplate>
  );
};

const SendEmailTip = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
`;

const EmailInput = styled(InputTextField)`
  margin-bottom: 28px;
  width: 100%;
`;

const BackButton = styled.img`
  top: 24px;
  left: 24px;
  position: absolute;
  cursor: pointer;
`;
