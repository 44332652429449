import dayjs from 'dayjs';
import localforage from 'localforage';
import {filter, find, findIndex} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import {envConfig} from '../../../env';

const clearExpiredMfaRecords = async () => {
  const records = (await localforage.getItem('mfaRecords')) || [];

  const skipMfaHours = Number(envConfig.REACT_APP_SKIP_MFA_HOURS) || 0;

  const eightHoursAgo = dayjs().subtract(skipMfaHours, 'hour');
  const oneSecondLater = dayjs().add(1, 'second');

  const filteredRecords = filter(records, record => {
    const time = dayjs(record?.time || 0);
    // set an upper limit of time scope so that some fake records will likely be removed
    return time.isAfter(eightHoursAgo) && time.isBefore(oneSecondLater);
  });

  await localforage.setItem('mfaRecords', filteredRecords);
};

export const updateMfaRecord = async email => {
  await clearExpiredMfaRecords();

  const records = (await localforage.getItem('mfaRecords')) || [];

  const recordIndexWithSameEmail = findIndex(
    records,
    record => record.email === email
  );

  if (recordIndexWithSameEmail > -1) {
    records.splice(recordIndexWithSameEmail, 1);
  }

  const newUuid = uuidv4();
  records.push({
    email,
    uuid: newUuid,
    time: Date.now(),
  });

  await localforage.setItem('mfaRecords', records);
  return newUuid;
};

export const findRecentMfaRecordUuid = async email => {
  await clearExpiredMfaRecords();

  const records = (await localforage.getItem('mfaRecords')) || [];

  const targetRecord = find(records, record => record.email === email);

  return targetRecord?.uuid || null;
};

export const deleteMfaRecord = async uuid => {
  const records = (await localforage.getItem('mfaRecords')) || [];

  const targetRecordIndex = findIndex(records, record => record.uuid === uuid);

  if (targetRecordIndex > -1) {
    records.splice(targetRecordIndex, 1);
  }

  await localforage.setItem('mfaRecords', records);
};
