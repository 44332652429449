import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/styles';
import {getAuth, getMultiFactorResolver} from 'firebase/auth';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button} from '../../shared/components/button';
import {InputTextField} from '../../shared/components/inputTextField';
import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {errorMessage, firebaseError} from '../constants/constants';
import {useHideRecaptcha} from '../hooks/useHideRecaptcha';
import {useMFAErrorHandling} from '../hooks/useMFAErrorHandling';
import {useRecaptcha} from '../hooks/useRecaptcha';
import {signInFormValidationSchema} from '../utils/validationSchema';

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiButton-root': {
      width: 256,
      height: 32,
      fontSize: 12,
      marginTop: 14,
    },
  },
}));

const containerId = 'send-verification-code-button';

export const SignInForm = ({
  setLoading,
  continueWithTheRemainingSignInSteps,
  signInAndEnrollMfaIfNeeded,
  email,
  setEmail,
}) => {
  const classes = useStyles();
  const getVerifier = useRecaptcha();
  const showMessage = useCustomizedSnackbar();
  const recaptchaVisible = useHideRecaptcha(setLoading);
  const handleMFAError = useMFAErrorHandling(errorMessage);

  const handleSignInError = useCallback(
    async (error, userEmail, password, verifier) => {
      if (error.code === firebaseError.MFA_REQUIRED) {
        try {
          await continueWithTheRemainingSignInSteps(
            userEmail,
            password,
            getMultiFactorResolver(getAuth(), error),
            verifier
          );
        } catch (e) {
          handleMFAError(e);
        }
      } else if (error.code === firebaseError.TOO_MANY_ATTEMPTS) {
        showMessage(errorMessage.LOG_IN_TOO_MANY_ATTEMPTS, 'error');
      } else {
        handleMFAError(error);
      }
    },
    [handleMFAError, showMessage, continueWithTheRemainingSignInSteps]
  );

  const handleSubmit = useCallback(
    async values => {
      const userEmail = values.email.trim();
      const {password} = values;
      setEmail(userEmail);
      setLoading(true);
      const verifier = getVerifier(containerId);
      try {
        await signInAndEnrollMfaIfNeeded(userEmail, password, verifier);
        setLoading(false);
      } catch (error) {
        await handleSignInError(error, userEmail, password, verifier);
        setLoading(false);
      }
    },
    [
      getVerifier,
      handleSignInError,
      setEmail,
      setLoading,
      signInAndEnrollMfaIfNeeded,
    ]
  );

  return (
    <Formik
      initialValues={{email, password: ''}}
      validationSchema={signInFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({submitForm, isValidating, isSubmitting, isValid}) => (
        <Form className={classes.root}>
          <EmailInput
            name="email"
            type="text"
            label="Email address"
            testID="emailInput"
            size="auto"
            placeholder="Email address"
          />
          <PasswordInput
            name="password"
            type="password"
            label="Password"
            testID="passwordInput"
            size="auto"
            placeholder="Password"
          />
          <Button
            id={containerId}
            onClick={submitForm}
            testID="sendCodeButton"
            type="submit"
            disabled={
              (!isValid || isValidating || isSubmitting) && recaptchaVisible
            }
          >
            SEND VERIFICATION CODE
          </Button>
        </Form>
      )}
    </Formik>
  );
};

SignInForm.propTypes = {
  setLoading: PropTypes.func,
  continueWithTheRemainingSignInSteps: PropTypes.func,
  signInAndEnrollMfaIfNeeded: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
};

const EmailInput = styled(InputTextField)`
  margin-bottom: 12px;
  width: 100%;
`;

const PasswordInput = styled(InputTextField)`
  margin-bottom: 20px;
  width: 100%;
`;
