import {v4 as uuid} from 'uuid';

import {LozengeAppearances} from '../lozenge';
import {Condition, FetchedCondition} from '../types/conditionTypes';

import {formatDate} from './mappersUtils';

const conditionSeverityColors: {[key: string]: LozengeAppearances} = {
  severe: 'error',
  moderate: 'warning',
  mild: 'default',
};

export const getConditionSeverityColor = (
  severity: FetchedCondition['severity']
): LozengeAppearances =>
  severity ? conditionSeverityColors[severity?.toLowerCase()] : 'default';

export const getConditions = (conditions?: FetchedCondition[]): Condition[] => {
  if (!conditions) {
    return [];
  }

  return conditions.map(condition => ({
    ...condition,
    id: uuid(),
    recorded_date: formatDate(condition.recorded_date),
    last_updated_at: formatDate(condition.last_updated_at),
  }));
};
