import {DateListType} from '../constants/types';

export const getYearsRange = (
  end: number,
  start: number,
  step: number
): DateListType => {
  if (start === end || start > end) return {[end]: end.toString()};
  return {
    [end]: end.toString(),
    ...getYearsRange(end - step, start, step),
  };
};
