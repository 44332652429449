import React, {useCallback, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {AddButton} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {Subheader} from '../../../shared/components/subheader';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {urls} from '../../../shared/utils/urls';
import {isUserRelyingPartyAdminOrOwner} from '../../../shared/utils/user';
import {DeleteModal} from '../components/deleteModal';
import {getRegistrationPolicyTableConfig} from '../constants/policiesTableConfig';
import {usePolicyTypes} from '../hooks/usePolicyTypes';

export const PoliciesPage = ({crumbs, title}) => {
  const history = useHistory();
  const location = useLocation();
  const {path} = useRouteMatch();

  const policyTypes = usePolicyTypes();

  const {relyingParty, roles} = useRecoilValue(currentUserState);

  const [policyToDelete, setPolicyToDelete] = useState(null);
  const [policyTypeId, setPolicyTypeId] = useState(
    location?.state?.policyTypeId || 'REGISTRATION'
  );

  const {data: policies, isValidating} = useSWR(
    urls.relyingPartyPoliciesByPolicyType(relyingParty.id, policyTypeId)
  );

  const handleAddPolicyRule = useCallback(() => {
    history.push({pathname: `${path}/add-policy`, state: {policyTypeId}});
  }, [history, path, policyTypeId]);

  const handlePolicyTypeChange = useCallback(
    policy => {
      setPolicyTypeId(policy.value);
    },
    [setPolicyTypeId]
  );

  const iconMethodMap = {
    handleDetail: item =>
      history.push({
        pathname: `/health-policies/${item.id}`,
        state: {policyTypeId},
      }),
    handleEdit: item =>
      history.push({
        pathname: `/health-policies/${item.id}`,
        state: {
          startEditing: true,
          from: path,
          policyTypeId,
        },
      }),
    handleDelete: item => setPolicyToDelete(item),
  };

  const table = useReactTable({
    data: policies,
    columns: getRegistrationPolicyTableConfig(policyTypeId, iconMethodMap),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <Subheader title="Policies">
        {isUserRelyingPartyAdminOrOwner(roles) && (
          <AddButton onClick={handleAddPolicyRule} text="Add new policy" />
        )}
      </Subheader>
      {isValidating && <LoadingBar loading />}
      <SelectContainer>
        <Subtitle>Select policy type</Subtitle>
        <CustomizedSelect
          value={policyTypeId}
          label="Policy type"
          options={policyTypes.map(({value}) => value)}
          values={policyTypes.map(({key}) => key)}
          onChange={e => handlePolicyTypeChange(e.target)}
        />
      </SelectContainer>
      {policies && isEmpty(policies) && (
        <NoDataPlaceholder noDataText="No policy has been created. Set up a new policy to start your management." />
      )}
      {policies && !isEmpty(policies) && (
        <TableWrapper>
          <StyledTable table={table} isHeaderSticky />
        </TableWrapper>
      )}
      {policyToDelete && (
        <DeleteModal
          type="policy"
          data={policyToDelete}
          open={!!policyToDelete}
          disabled={policyToDelete.locations.length > 0}
          closeModal={() => setPolicyToDelete(null)}
        />
      )}
    </Container>
  );
};

PoliciesPage.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const SelectContainer = styled.div`
  margin: 24px 40px;
  margin-bottom: 50px;
`;

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;

const TableWrapper = styled.div`
  margin: 0 60px 0 40px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow-y: auto;
  min-height: 180px;
  display: flex;
  flex-direction: column;
`;
