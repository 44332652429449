import React from 'react';
import {Color} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {colors} from '../../../shared/styles/theme';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '30px',
    minWidth: '566px',
    '&:focus': {
      outline: 'none',
    },
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface ConfirmModalProps {
  className?: string;
  testID?: string;
  open?: boolean;
  onClose: () => void;
  children: React.ElementType<any>;
  onConfirm: () => void;
  disabled?: boolean;
  description: string;
  sortRevert?: boolean;
  confirmText?: string;
  confirmButtonColor?: Color & ('inherit' | 'dangerous');
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  className,
  testID,
  open,
  onClose,
  children,
  onConfirm,
  disabled,
  description,
  confirmText = 'Yes',
  sortRevert = false,
  confirmButtonColor,
}) => {
  const classes = useStyles();

  return (
    <Modal
      data-testid={testID}
      aria-labelledby="transition-modal-title"
      className={classNames(classes.modal, className)}
      open={open!}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classNames(classes.paper, 'paper')}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          {description && <ContentText>{description}</ContentText>}
          {children}

          {sortRevert ? (
            <ConfirmButtonGroup>
              <Button
                testID="confirmModalWindowButton"
                onClick={onConfirm}
                disabled={disabled}
                color={confirmButtonColor}
              >
                {confirmText}
              </Button>
              <Button
                testID="unConfirmModalWindowButton"
                className="cancelButton"
                variant="outlined"
                onClick={onClose}
              >
                No
              </Button>
            </ConfirmButtonGroup>
          ) : (
            <ConfirmButtonGroup>
              <Button
                testID="unConfirmModalWindowButton"
                className="cancelButton"
                variant="outlined"
                onClick={onClose}
              >
                No
              </Button>
              <Button
                onClick={onConfirm}
                disabled={disabled}
                color={confirmButtonColor}
                testID="confirmModalWindowButton"
              >
                {confirmText}
              </Button>
            </ConfirmButtonGroup>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

const ConfirmButtonGroup = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const ContentText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.grey600};
  white-space: pre-line;
`;

export default ConfirmModal;
