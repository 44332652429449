import React, {useCallback, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {useSetRecoilState} from 'recoil';

import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {CONNECTION_STATUSES} from '../../portalPatients/constants/patientStatuses';
import {PORTAL_PATIENTS_ROUTES} from '../../portalPatients/constants/routes';
import {notValidTaskModalWindowState} from '../atoms/notValidTaskModalWindowState';
import {EVENTS_CATEGORY, ResolutionEnum} from '../constatnts/types';
import {useCustomizedButtonStyles} from '../hooks/useCustomizedButtonStyles';
import {
  fetchInboxProviderResolutionEvent,
  fetchPatientConnection,
} from '../utils/fetchers';

type Props = {
  consumerId: string;
  eventId: string;
  relyingPartyId: number;
  eventCategory: EVENTS_CATEGORY;
};
export const UploadMedicalRecordsButton: React.FC<Props> = ({
  consumerId,
  eventId,
  relyingPartyId,
  eventCategory,
}) => {
  const classes = useCustomizedButtonStyles();
  const snackbar = useCustomizedSnackbar();
  const queryClient = useQueryClient();
  const [redirectLink, setRedirectLink] = useState<string | null>(null);
  const showMessage = useCustomizedSnackbar();
  const setNotValidTaskModalWindowState = useSetRecoilState(
    notValidTaskModalWindowState
  );

  const handleDismissAction = useCallback(async () => {
    try {
      await fetchInboxProviderResolutionEvent(
        ResolutionEnum.DISMISSED,
        eventId
      );
      await queryClient.invalidateQueries({
        queryKey: ['inbox-providers-events'],
      });
    } catch {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
  }, [eventId, queryClient, snackbar]);

  const onUploadMedicalRecords = async () => {
    try {
      const {status, share_provider_to_patient} = await fetchPatientConnection(
        relyingPartyId,
        consumerId
      );

      if (
        status !== CONNECTION_STATUSES.CONNECTED ||
        (status === CONNECTION_STATUSES.CONNECTED && !share_provider_to_patient)
      ) {
        setNotValidTaskModalWindowState({callBackFunc: handleDismissAction});
      } else {
        setRedirectLink(
          PORTAL_PATIENTS_ROUTES.MEDICAL_RECORDS_REQUEST.replace(
            ':patientUuid',
            consumerId
          ).concat(`?event-id=${eventId}`)
        );
      }
    } catch (e) {
      showMessage('Something went wrong. Please try again later.', 'error');
    }
  };

  if (redirectLink) {
    return <Redirect to={redirectLink} />;
  }

  return (
    <Button
      data-testid={`inbox-upload-btn-${eventId}`}
      position="section"
      onClick={onUploadMedicalRecords}
      variant="outlined"
      className={classes.customizedBtn}
    >
      Upload
    </Button>
  );
};
