import React from 'react';
import {makeStyles} from '@material-ui/styles';

import {colors} from '../../../../shared/styles/theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: '16px',
  },
  title: {
    color: colors.grey600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '32px',
    margin: '0 0 16px 0',
  },
}));

type Props = {
  title: string;
};
export const ElectronicRecordAccordionHeader: React.FC<Props> = ({title}) => {
  const classes = useStyles();

  return (
    <h2 data-testid="electronic-record-section-title" className={classes.title}>
      {title}
    </h2>
  );
};
