import React from 'react';
import styled from 'styled-components';

import {StatusText} from '../../../shared/components/statusText';
import {colors} from '../../../shared/styles/theme';
import {CONNECTION_STATUSES} from '../constants/patientStatuses';

type Props = {
  status?: string;
};
export const ConnectedPatientStatusLabel: React.FC<Props> = ({status}) =>
  status === CONNECTION_STATUSES.CONNECTED ? (
    <PatientStatusWrapper>
      <StatusText color={colors.green}>Connected</StatusText>
    </PatientStatusWrapper>
  ) : null;

const PatientStatusWrapper = styled.div`
  display: inline-block;
`;
