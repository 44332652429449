import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import ResendIcon from '../../../../assets/icons/resend.svg';
import {Button} from '../../../shared/components/button';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import useSendInvitation from '../hooks/useSendInvitation';

export const InvitationButton = ({email}) => {
  const showMessage = useCustomizedSnackbar();
  const {sendInvitation, loading} = useSendInvitation();
  const handleResendInvitation = useCallback(async () => {
    if (!email) {
      showMessage('Email not found');
      return;
    }
    await sendInvitation(email);
  }, [email, sendInvitation, showMessage]);

  return (
    <Button
      testID="resendInvitation"
      position="section"
      startIcon={<img src={ResendIcon} alt="resendIcon" />}
      onClick={handleResendInvitation}
      disabled={loading}
    >
      Resend invitation
    </Button>
  );
};

InvitationButton.propTypes = {
  email: PropTypes.string,
};
