import React, {useCallback} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from '@material-ui/styles';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CustomizedTable} from '../../../shared/components/customizedTable';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {activityLogColumns} from '../constants/tableSchema';

const useStyles = makeStyles(() => ({
  overflowTable: {
    overflowY: 'auto',
    height: '100%',
  },
}));
export const ActivityLogTable = ({
  page,
  count,
  loading,
  logs = [],
  policyTypeId,
  locations,
  onPageChange,
  hasPagination,
}) => {
  const classes = useStyles();
  const handlePageChange = useCallback(
    (_event, pageSize) => {
      onPageChange(pageSize);
    },
    [onPageChange]
  );

  const activityTableColumns = React.useMemo(
    () => activityLogColumns(policyTypeId),
    [policyTypeId]
  );

  if (loading) {
    return null;
  }

  if (isEmpty(locations)) {
    return (
      <NoDataPlaceholder noDataText="Please select policy type and location(s) first, the corresponding records will be displayed here." />
    );
  }
  return (
    <TableWrapper>
      {isEmpty(logs) && <NoDataPlaceholder noDataText="No item found." />}
      {!isEmpty(logs) && (
        <>
          <CustomizedTable
            columns={activityTableColumns}
            data={logs}
            keyExtractor="activity_log_id"
            className={classes.overflowTable}
            isStickyHeader
          />
          {hasPagination && (
            <PaginationContainer>
              <Pagination
                count={count}
                onChange={handlePageChange}
                page={page}
              />
            </PaginationContainer>
          )}
        </>
      )}
    </TableWrapper>
  );
};

ActivityLogTable.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.any),
  count: PropTypes.number,
  loading: PropTypes.bool,
  logs: PropTypes.arrayOf(PropTypes.any),
  policyTypeId: PropTypes.string,
  hasPagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  iconMethodMap: PropTypes.shape({}),
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 200px;
  padding-bottom: 60px;
`;

const PaginationContainer = styled.div`
  align-self: flex-end;
  margin: 10px 64px 0 0;
`;
