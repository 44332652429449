import React, {FC} from 'react';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';

import {useCustomizedSnackbar} from '../../../../shared/hooks/useCustomizedSnackbar';
import {
  ALLOWED_FILE_TYPE,
  ALLOWED_FILE_TYPES_LIST,
  FileUploadLinkResponse,
} from '../../../../shared/interfaces/aIChat';

import {getMimeFromFile} from './hooks/useFileHandler';
import useMessages from './hooks/useMessages';
import {CareGuideRetryMessage} from './careGuideRetryMessage';
import Message from './message';

interface ChatContainerProps {
  saveFileInLinkResponse: FileUploadLinkResponse;
}

const ChatContainer: FC<ChatContainerProps> = ({saveFileInLinkResponse}) => {
  const showMessage = useCustomizedSnackbar();
  const {chat, updateMessage} = useMessages();
  const {messages} = chat;

  const retrySendMessage = async (i: number): Promise<void> => {
    const {text, fileType, fileName, isAMedicalRecord} = messages[i - 1];
    const fileMimeType = isAMedicalRecord
      ? getMimeFromFile(fileType)
      : fileType;
    if (
      (isAMedicalRecord || ALLOWED_FILE_TYPES_LIST.includes(fileType)) &&
      fileName !== undefined
    ) {
      return updateMessage(
        text,
        fileMimeType as ALLOWED_FILE_TYPE,
        fileName,
        saveFileInLinkResponse,
        isAMedicalRecord
      );
    }
    showMessage('File type error.', 'error');
    return Promise.resolve();
  };

  return (
    <>
      {messages?.map((message, i) => (
        <DataDisplayField key={uuidv4()}>
          {message.isRetry ? (
            <CareGuideRetryMessage
              data-testid="retry-message"
              onRetry={() => retrySendMessage(i)}
            />
          ) : (
            <Message message={message} />
          )}
        </DataDisplayField>
      ))}
    </>
  );
};

export default ChatContainer;

const DataDisplayField = styled.div`
  display: flex;
  width: 380px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
  transform: translateZ(0);
`;
