import {atom} from 'recoil';

type stateType = {
  callBackFunc: () => Promise<void>;
};

export const notValidTaskModalWindowState = atom<null | stateType>({
  key: 'notValidTaskModalWindow',
  default: null,
});
