import React, {useCallback, useMemo} from 'react';
import {Card, Link, Popper, TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {Autocomplete} from '@material-ui/lab';
import {useFormikContext} from 'formik';
import {isEmpty} from 'lodash';
import styled from 'styled-components';

import {USA_STATES_LIST} from '../../main/relyingPartyInvitation/constants/postalStatesAbbreviations';
import {RelyingPartyMainFields} from '../../main/relyingPartyInvitation/store';
import {onResetPrimaryLocationSubFormWithManuallyTypedAddress} from '../../main/relyingPartyInvitation/utils/formik';
import {onlyNumbersPattern} from '../constants/patterns';
import {ANCHOR} from '../styles/theme';

import {
  INPUT_WIDTH_SIZES,
  INPUT_WIDTH_TYPES,
  InputFieldWithFormik,
  InputFormControlWithFormik,
  InputHelperText,
  InputLabel,
} from './form';
import {SearchZipCodeFields} from './prefilledByZipCodeAddressForm';

const MAX_ZIP_CODE_LENGTH = 16;

export type LocationFields = {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
};

export const locationInitialFieldsState: LocationFields = {
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zipCode: '',
};

export const ManuallyTypedAddressForm: React.FC = () => {
  const formik = useFormikContext<
    RelyingPartyMainFields & LocationFields & SearchZipCodeFields
  >();

  const getStateOptionLabel = useCallback(
    (option: {label: string}) => option?.label,
    []
  );

  const getStatePaperComponent = useCallback(
    props => <Card variant="outlined" {...props} />,
    []
  );

  const getStateInputPopperComponent = useCallback(
    props => (
      <Popper
        modifiers={{
          offset: {enabled: true, offset: `0, ${ANCHOR.style.top}`},
        }}
        {...props}
      />
    ),
    []
  );

  const getStateOptionSelected = useCallback(
    (option, value) => option.label === value.label,
    []
  );

  const onChangeState = useCallback(
    (e, value) => {
      if (value) {
        formik.setFieldValue('state', value.label);
      } else {
        formik.setFieldValue('state', '');
      }
    },
    [formik]
  );

  const getStateInput = useCallback(
    params => (
      <FormControl
        error={formik.touched.state && !!formik.errors.state}
        required
      >
        <InputLabel htmlFor="state">State</InputLabel>
        <TextField
          {...params}
          id="state"
          variant="outlined"
          size="small"
          error={formik.touched.state && !!formik.errors.state}
          style={{width: INPUT_WIDTH_SIZES.small}}
        />
        <InputHelperText shrink>
          {(formik.touched.state && formik.errors.state) || ''}
        </InputHelperText>
      </FormControl>
    ),
    [formik]
  );

  const autocompleteStateValue = useMemo(
    () =>
      USA_STATES_LIST.find(state => state.label === formik.values.state) ??
      null,
    [formik.values.state]
  );

  return (
    <>
      <InputWrapper>
        <InputFormControlWithFormik
          required
          id="addressOne"
          name="addressOne"
          label="Address Line 1"
          placeholder="Street number and name"
          error={(formik.touched.addressOne && formik.errors.addressOne) || ''}
          testIdPrefix="address-one"
        />

        <InputFormControlWithFormik
          id="addressTwo"
          name="addressTwo"
          label="Address Line 2 (optional)"
          placeholder="Apartment unit and number"
          error={(formik.touched.addressTwo && formik.errors.addressTwo) || ''}
          testIdPrefix="address-two"
        />
      </InputWrapper>

      <InputWrapper>
        <InputFormControlWithFormik
          required
          id="city"
          name="city"
          width={INPUT_WIDTH_TYPES.SMALL}
          label="City"
          error={(formik.touched.city && formik.errors.city) || ''}
          testIdPrefix="city"
        />

        <Autocomplete
          popupIcon={<KeyboardArrowDownIcon />}
          closeIcon={<CancelIcon fontSize="small" />}
          value={autocompleteStateValue}
          data-testid="state"
          options={USA_STATES_LIST}
          getOptionLabel={getStateOptionLabel}
          getOptionSelected={getStateOptionSelected}
          PaperComponent={getStatePaperComponent}
          onBlur={() => {
            formik.setFieldTouched('state');
          }}
          onChange={onChangeState}
          PopperComponent={getStateInputPopperComponent}
          renderInput={getStateInput}
        />

        <FormControl
          required
          error={formik.touched.zipCode && !!formik.errors.zipCode}
        >
          <InputLabel name="zipCode" htmlFor="zipCode">
            Zip Code
          </InputLabel>
          <InputFieldWithFormik
            {...formik.getFieldProps('zipCode')}
            id="zipCode"
            inputProps={{
              'data-testid': 'zip-code-input',
            }}
            style={{width: INPUT_WIDTH_SIZES.small}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const {value} = e.target;
              if (
                isEmpty(value) ||
                (onlyNumbersPattern.exec(value) !== null &&
                  value.length <= MAX_ZIP_CODE_LENGTH)
              ) {
                formik.setFieldValue('zipCode', e.target.value);
              }
            }}
          />

          <InputHelperText shrink>
            {(formik.touched.zipCode && formik.errors.zipCode) || ''}
          </InputHelperText>
        </FormControl>
      </InputWrapper>

      <p>
        Changed your mind?{' '}
        <Link
          href="/#"
          onClick={e => {
            e.preventDefault();
            onResetPrimaryLocationSubFormWithManuallyTypedAddress(formik);
          }}
          underline="always"
          data-testid="enter-primary-location-link-manualy"
        >
          Search address by Zip code
        </Link>
      </p>
    </>
  );
};

const InputWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
`;
