import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import {makeStyles} from '@material-ui/styles';
import dayjs from 'dayjs';
import localforage from 'localforage';
import {isEmpty} from 'lodash';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {isPatientConnectionDataLoadingState} from '../../../shared/atoms/isPatientConnectionDataLoadingState';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {DownloadButton} from '../../../shared/components/button';
import {ButtonSwitcher} from '../../../shared/components/buttonSwitcher';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {LoadingBar} from '../../../shared/components/loadingBar';
import {Subheader} from '../../../shared/components/subheader';
import {TimePeriod} from '../../../shared/components/timePeriod';
import {ACTIVITY_LOG_MODE} from '../../../shared/constants/activityLogMode';
import {
  useCloseCustomizedSnackbar,
  useCustomizedSnackbar,
} from '../../../shared/hooks/useCustomizedSnackbar';
import {Crumb} from '../../../shared/interfaces/crumb';
import {User} from '../../../shared/interfaces/user';
import {PatientNameClickModalWindowsProvider} from '../../../shared/providers/patientNameClickModalWindowsProvider';
import {colors} from '../../../shared/styles/theme';
import {isUserRelyingParty} from '../../../shared/utils/user';
import {POLICY_TYPES_DENIED_FILTER_LABEL} from '../../healthPolicies/constants/defaultPolicy';
import {usePolicyTypes} from '../../healthPolicies/hooks/usePolicyTypes';
import {ActivityLogTable} from '../components/activityLogTable';
import {Locations} from '../components/locations';
import {useActivityLog} from '../hooks/useActivityLog';
import {exportAllActivityLogsAsCsv} from '../utils/activityFetchHelper';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSwitch-colorSecondary': {
      color: colors.grey200,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: colors.red,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.red,
    },
  },
  main: {
    margin: '20px 0',
  },
  select: {
    '&': {
      width: '383px',
    },
  },
}));

interface ActivityLogPageProps {
  crumbs: Crumb[];
  title: string;
}

interface ActivityLogParams {
  relyingPartyId: string;
}

const customizeCrumbs = (crumbs: Crumb[], user: User | null) =>
  user && isUserRelyingParty(user.roles)
    ? [crumbs.find(crumb => crumb.path.endsWith(String(user.relyingParty.id)))]
    : crumbs;

export const ActivityLogPage: React.FC<ActivityLogPageProps> = ({
  crumbs,
  title,
}) => {
  const classes = useStyles();
  const {relyingPartyId} = useParams<ActivityLogParams>();
  const currentUser = useRecoilValue(currentUserState);
  const isPatientConnectionDataLoading = useRecoilValue(
    isPatientConnectionDataLoadingState
  );

  const showMessage = useCustomizedSnackbar();
  const hideMessage = useCloseCustomizedSnackbar();

  const policyTypes = usePolicyTypes();

  const [page, setPage] = useState(0);
  const [streaming, setStreaming] = useState(true);
  const [showDenied, setShowDenied] = useState(false);
  const [fetchingLocations, setFetchingLocations] = useState(true);
  const [policyTypeId, setPolicyTypeId] = useState(policyTypes[0].key);
  const [selectedLocations, setSelectedLocations] = useState<{value: string}[]>(
    []
  );
  const [firstRequest, setFirstRequest] = useState(true);
  const [fromTime, setFromTime] = useState(
    dayjs().subtract(6, 'day').format('YYYY-MM-DDT00:00')
  );
  const [toTime, setToTime] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));

  useEffect(() => {
    localforage.getItem<string>('fromTime').then(time => {
      if (time) {
        setFromTime(time);
      }
    });
    localforage.getItem<string>('toTime').then(time => {
      if (time) {
        setToTime(time);
      }
    });
  }, []);

  const getActivityLogsFetchConfigs = useCallback(
    () => ({
      locationIds: selectedLocations.map(location => location.value),
      filterDenied: showDenied,
      page,
      relyingPartyId,
      policyTypeId,
      beginTime: new Date(fromTime),
      endTime: new Date(toTime),
      mode: streaming
        ? ACTIVITY_LOG_MODE.LIVE_STREAM
        : ACTIVITY_LOG_MODE.VIEW_BY_DATE,
    }),
    [
      fromTime,
      page,
      showDenied,
      toTime,
      streaming,
      relyingPartyId,
      policyTypeId,
      selectedLocations,
    ]
  );

  const activityLogsFetchConfigs = getActivityLogsFetchConfigs();

  const {logs, totalPages, loading} = useActivityLog(
    activityLogsFetchConfigs,
    firstRequest
  );
  useEffect(() => {
    if (streaming && logs && firstRequest) {
      setFirstRequest(false);
    }
  }, [firstRequest, setFirstRequest, streaming, logs]);

  const handleDeniedSwitchChange = useCallback(event => {
    setShowDenied(event.target.checked);
    setPage(0);
  }, []);

  const handlePageChange = useCallback(curPage => {
    setFirstRequest(true);
    setPage(curPage - 1);
  }, []);

  const handleSetFromTime = useCallback(async time => {
    setFromTime(time);
    setFirstRequest(true);
    setPage(0);
    try {
      await localforage.setItem('fromTime', time);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const handleSetToTime = useCallback(async time => {
    setToTime(time);
    setFirstRequest(true);
    setPage(0);
    try {
      await localforage.setItem('toTime', time);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const handlePolicyTypeChange = useCallback(event => {
    setPolicyTypeId(event.target.value);
    setSelectedLocations([]);
    setFetchingLocations(true);
    setFirstRequest(true);
    setPage(0);
  }, []);

  const handleLocationsChange = useCallback(locationsData => {
    setSelectedLocations(locationsData);
    setFirstRequest(true);
    setPage(0);
  }, []);

  const handleGenerateCsv = useCallback(async () => {
    const snackbarKey = showMessage(
      'Preparing download.',
      'loading',
      <CircularProgress size="24px" />
    );
    await exportAllActivityLogsAsCsv({
      ...activityLogsFetchConfigs,
    });

    setTimeout(() => {
      hideMessage(snackbarKey);
      showMessage('Your file is ready for download.', 'success');
    }, 1000);
  }, [activityLogsFetchConfigs, showMessage, hideMessage]);

  const onClickSwitchButtons = useCallback(() => {
    setFirstRequest(true);
    setStreaming(!streaming);
  }, [streaming]);

  return (
    <PatientNameClickModalWindowsProvider>
      <ContainerWrapper>
        <BreadCrumbNavigator
          crumbs={customizeCrumbs(crumbs, currentUser)}
          title={title}
        />

        <Subheader title="Activity Log" />
        <LoadingBar
          loading={
            fetchingLocations || loading || isPatientConnectionDataLoading
          }
        />

        <TableOptionsContainer>
          <Subtitle>Select policy type</Subtitle>
          <CustomizedSelect
            value={policyTypeId}
            label="Policy type"
            options={policyTypes.map(({value}) => value)}
            values={policyTypes.map(({key}) => key)}
            onChange={handlePolicyTypeChange}
            className={classes.select}
          />
          <Subtitle>Select locations</Subtitle>
          <BottomContainer>
            <Locations
              selectedLocations={selectedLocations}
              onLocationsChange={handleLocationsChange}
              relyingPartyId={relyingPartyId}
              policyTypeId={policyTypeId}
              handleLoading={setFetchingLocations}
            />
            <DownloadButton
              text="Export"
              onClick={handleGenerateCsv}
              disabled={!logs}
            />
          </BottomContainer>
          <LogSwitcherContainer>
            <ButtonSwitcher
              buttons={[
                ACTIVITY_LOG_MODE.LIVE_STREAM,
                ACTIVITY_LOG_MODE.VIEW_BY_DATE,
              ]}
              activeButton={streaming ? 0 : 1}
              onClick={onClickSwitchButtons}
            />

            <StatusContainer>
              {!streaming && (
                <TimePeriod
                  fromTime={fromTime}
                  toTime={toTime}
                  onFromTimeChange={handleSetFromTime}
                  onToTimeChange={handleSetToTime}
                />
              )}
            </StatusContainer>

            <SwitchContainer data-testid="denied-filter">
              {!isEmpty(selectedLocations) && (
                <>
                  <Switch
                    checked={showDenied}
                    className={classes.root}
                    onChange={handleDeniedSwitchChange}
                    color="secondary"
                  />
                  <Label>
                    {POLICY_TYPES_DENIED_FILTER_LABEL[policyTypeId]}
                  </Label>
                </>
              )}
            </SwitchContainer>
          </LogSwitcherContainer>
        </TableOptionsContainer>

        <ActivityLogTable
          policyTypeId={policyTypeId}
          logs={logs}
          page={page + 1}
          count={totalPages}
          hasPagination={
            !streaming && totalPages !== undefined && totalPages > 1
          }
          locations={selectedLocations}
          onPageChange={handlePageChange}
          loading={fetchingLocations || loading}
        />
      </ContainerWrapper>
    </PatientNameClickModalWindowsProvider>
  );
};

const ContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const TableOptionsContainer = styled.div`
  padding: 30px 64px 20px 41px;
`;

const LogSwitcherContainer = styled.div`
  display: flex;
  margin-top: 20px;
  min-height: 54px;
  align-items: center;
`;

const StatusContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
`;

const Label = styled.p`
  font-weight: 600;
`;

const Subtitle = styled.h6`
  padding-bottom: 10px;
  padding-top: 24px;
  margin: 0;
  &:nth-child(1) {
    padding-top: 0;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
