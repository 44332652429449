import React from 'react';
import styled from 'styled-components';

import Ellipse from '../../../../assets/icons/ellipse.svg';
import {colors} from '../../../shared/styles/theme';
import {
  convertDateToRelativeDate,
  formatUsDate,
} from '../../../shared/utils/formatDate';
import {EligibilityStatus, ReturnDocumentType} from '../constants/types';

type Props = {
  patientDocumentInfo: ReturnDocumentType;
  fontSize?: string;
  shouldShowTime?: boolean;
};

interface ValidationInfoProps {
  fontSize: string;
  color: string;
  lineHeight: string;
  fontWeight: string;
}

interface BorderInfoProps {
  withBorder?: boolean;
}

export const DocumentActiveStatusLabel: React.FC<Props> = ({
  patientDocumentInfo,
  fontSize = '14px',
  shouldShowTime,
}) => {
  const documentData = patientDocumentInfo.data;
  if (!documentData) {
    return null;
  }

  let statusDate = documentData.last_eligibility_check_at;
  if (!statusDate) {
    return null;
  }
  statusDate = new Date(statusDate);
  const isValidDocument =
    documentData.eligibility_status === EligibilityStatus.VALID;
  const greyColorForText = shouldShowTime ? colors.grey600 : colors.grey801;
  return (
    <StatusWrapper>
      <StatusDate
        withBorder={shouldShowTime}
        color={isValidDocument ? colors.grey800 : 'rgba(235, 87, 87, 0.40)'}
      >
        <ValidationInfo
          fontSize={fontSize}
          lineHeight={shouldShowTime ? '20px' : '16px'}
          fontWeight={shouldShowTime ? '600' : '400'}
          color={isValidDocument ? greyColorForText : colors.red}
        >
          {isValidDocument ? 'Active' : 'Inactive'} as of{' '}
          {formatUsDate(statusDate)}
        </ValidationInfo>
      </StatusDate>
      {shouldShowTime && (
        <StatusTime>
          <img src={Ellipse} alt="at" />
          {convertDateToRelativeDate(statusDate)}
        </StatusTime>
      )}
    </StatusWrapper>
  );
};

const StatusWrapper = styled.div`
  display: flex;
  align-times: center;
  gap: 8px;
`;

const StatusDate = styled.div<BorderInfoProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${props =>
    props.withBorder &&
    `border: 1px solid ${props.color};
    padding: 4px 8px;
    border-radius: 4px;`}}
`;

const StatusTime = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  color: ${colors.grey801};
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: center;
`;

const ValidationInfo = styled.p<ValidationInfoProps>`
  margin: 0;
  line-height: ${props => props.lineHeight};
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  white-space: nowrap;
`;
