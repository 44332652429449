type addressDetailsType = {
  city: string | null;
  state: string | null;
  street1: string | null;
  street2: string | null;
  zip: string | null;
};

export const getAddress = (addressDetails: addressDetailsType): string =>
  Object.values(addressDetails).filter(Boolean).join(', ') || 'N/A';
