import React from 'react';
import styled from 'styled-components';

import {colors} from '../../shared/styles/theme';

export const VerificationTip = () => (
  <Content>
    You will receive an SMS message on your phone for verification. Message and
    data rates may apply.
  </Content>
);

const Content = styled.p`
  color: ${colors.grey801};
  font-style: italic;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 0;
`;
