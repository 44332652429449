import React, {FC, useMemo, useState} from 'react';
import styled from 'styled-components';

import {AIChatHistoryDeleteIcon} from '../../../../shared/icons/aIChatHistoryDeleteIcon';
import {AIChatHistoryMoreIcon} from '../../../../shared/icons/aIChatHistoryMoreIcon';
import {ChatHistoryConversation} from '../../../../shared/interfaces/aIChat';
import {colors} from '../../../../shared/styles/theme';
import {getChatPromptKeyByStringStartWith} from '../../constants/careGuidePrompts';

interface ChatHistoryItemProps {
  conversation: ChatHistoryConversation;
  isSelectedItem: boolean;
  isMenuOpen: boolean;
  handleToggleMenu: (id: string) => void;
  handleItemClick: (id: string) => void;
  setDeleteModalConversationId: (id: string) => void;
}

export const ChatHistoryItem: FC<ChatHistoryItemProps> = ({
  conversation,
  isSelectedItem,
  isMenuOpen,
  handleToggleMenu,
  handleItemClick,
  setDeleteModalConversationId,
}) => {
  const [hoveredConversationId, setHoveredConversationId] = useState('');
  const {conversationId: id, title} = conversation;

  const isHovered = hoveredConversationId === id;
  const shouldShowOptions = useMemo(
    () => isSelectedItem || isHovered || isMenuOpen,
    [isSelectedItem, isHovered, isMenuOpen]
  );

  const handleMouseEnter = () => setHoveredConversationId(id);
  const handleMouseLeave = () => setHoveredConversationId('');
  const handleItemClickWrapper = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    handleItemClick(id);
  };

  const handleDeleteClick = () => {
    handleToggleMenu(id);
    setDeleteModalConversationId(id);
  };

  return (
    <>
      <Item
        key={id}
        isGrey={isMenuOpen}
        data-testid={`history-item-${id}`}
        className={isSelectedItem ? 'selected' : ''}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container onClick={handleItemClickWrapper}>
          <Title>{getChatPromptKeyByStringStartWith(title) ?? title}</Title>
          {shouldShowOptions && (
            <OptionsContainer>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  handleToggleMenu(id);
                }}
              >
                <AIChatHistoryMoreIcon
                  data-testid={`history-more-icon-${id}`}
                />
              </Button>
            </OptionsContainer>
          )}
        </Container>
      </Item>
      {isMenuOpen && (
        <DeleteWrapper>
          <ButtonDelete onClick={handleDeleteClick}>
            <AIChatHistoryDeleteIcon />
            <DeleteTitle>Delete Chat</DeleteTitle>
          </ButtonDelete>
        </DeleteWrapper>
      )}
    </>
  );
};
const DeleteTitle = styled.div`
  color: var(--UI-red, #eb5757);
  font-weight: 600;
`;
const DeleteWrapper = styled.div`
  position: relative;
  left: 239px;
  z-index: 1;
  top: 6px;
`;
const Container = styled.div`
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  gap: 10px;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: column;
`;

const ButtonDelete = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 5px;
  height: 41px;
  width: 141px;
  border-top: 1px solid var(--UI-table-stroke, #e6e6e6);
  border-right: 1px solid var(--UI-table-stroke, #e6e6e6);
  border-left: 1px solid var(--UI-table-stroke, #e6e6e6);
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(36, 71, 130, 0.16);
`;

const Button = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  position: relative;
`;

const Item = styled.div<{isGrey: boolean}>`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 36px;
  padding: 0px 8px;
  gap: 10px;
  align-self: stretch;
  justify-content: space-around;
  background: ${props => (props.isGrey ? colors.grey700 : colors.white)};

  &:hover {
    border-radius: 5px;
    background: ${colors.grey700};
    cursor: pointer;
  }

  &.selected {
    border-radius: 5px;
    background: ${colors.grey800};
  }
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  flex: 1 0 0;
`;
