import React from 'react';
import {Box, FormControlLabel, RadioGroup} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';

import {InputLabel, InputRadioButton} from '../../../../shared/components/form';
import {
  PROVIDER_TYPES,
  PROVIDER_TYPES_LABELS,
} from '../../../../shared/constants/userTypes';

export const ProviderTypeForm = ({setProviderType}) => {
  const formik = useFormikContext();

  return (
    <>
      <Box fontWeight={700} component="p">
        Set Provider Type
      </Box>

      <FormControl>
        <InputLabel shrink>Provider Type</InputLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            control={
              <InputRadioButton
                onClick={() => {
                  setProviderType(PROVIDER_TYPES.INDIVIDUAL);
                }}
                inputProps={{
                  'data-testid': 'radio-btn-individual-npi',
                }}
              />
            }
            checked={formik.values.providerType === PROVIDER_TYPES.INDIVIDUAL}
            label={PROVIDER_TYPES_LABELS[PROVIDER_TYPES.INDIVIDUAL]}
          />
          <FormControlLabel
            control={
              <InputRadioButton
                inputProps={{
                  'data-testid': 'radio-btn-organization-npi',
                }}
                onClick={() => {
                  setProviderType(PROVIDER_TYPES.ORGANIZATION);
                }}
              />
            }
            checked={formik.values.providerType === PROVIDER_TYPES.ORGANIZATION}
            label={PROVIDER_TYPES_LABELS[PROVIDER_TYPES.ORGANIZATION]}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

ProviderTypeForm.propTypes = {
  setProviderType: PropTypes.func,
};
