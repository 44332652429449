import React, {FC, useCallback} from 'react';
import {IconButton} from '@material-ui/core';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {CloseButton} from '../../../../shared/components/closeButton';
import {AIChatHistoryBackArrow} from '../../../../shared/icons/aIChatHistoryBackArrow';
import {AIChatHistoryIcon} from '../../../../shared/icons/aIChatHistoryIcon';
import {AINewChatIcon} from '../../../../shared/icons/aINewChatIcon';
import {colors} from '../../../../shared/styles/theme';
import {DRAWER_DEFAULT_STATE, drawerState} from '../../atoms/drawerState';

import useMessages from './hooks/useMessages';

interface HeaderProps {
  width: number;
  isDisabled: boolean;
  isHistory: boolean;
  setOpenHistory: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomIconButton = styled(IconButton)({
  padding: '0px',
  margin: '24px 0 24px 40px',
});

const Header: FC<HeaderProps> = ({
  width,
  isDisabled,
  isHistory,
  setOpenHistory,
}) => {
  const {handleClickNewChat} = useMessages();
  const setDrawerState = useSetRecoilState(drawerState);
  const handleClose = useCallback((): void => {
    setDrawerState(DRAWER_DEFAULT_STATE);
    handleClickNewChat();
  }, [handleClickNewChat, setDrawerState]);

  return (
    <HeaderContainer data-testid="header-container">
      <HeaderWrapper width={width}>
        {isHistory ? (
          <>
            <CustomIconButton
              data-testid="back-arrow"
              onClick={() => setOpenHistory(false)}
            >
              <BackArrowIconWrapper>
                <AIChatHistoryBackArrow />
              </BackArrowIconWrapper>
            </CustomIconButton>
            <HistoryTitle>Chat History</HistoryTitle>
          </>
        ) : (
          <IconWrapper>
            <IconButton
              data-testid="history-chat"
              onClick={() => setOpenHistory(true)}
            >
              <AIChatHistoryIcon />
            </IconButton>
            <IconButton
              disabled={isDisabled}
              data-testid="new-chat"
              onClick={handleClickNewChat}
            >
              <AINewChatIcon isGray={isDisabled} />
            </IconButton>
          </IconWrapper>
        )}
        <CloseIconWrapper>
          <CloseButton onClick={handleClose} data-testid="close-drawer-btn" />
        </CloseIconWrapper>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 28px;
`;

const BackArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;
`;

const HistoryTitle = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const HeaderContainer = styled.div`
  overflow-y: hidden;
`;

const HeaderWrapper = styled.div<{width: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid ${colors.grey800};
  align-vertical: center;
  position: absolute;
  width: ${props => `${props.width}px`};
  background-color: ${colors.white};
  top: 64px;
  z-index: 1;
`;
