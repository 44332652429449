export const STEPS = {
  FIRST: 0,
  SECOND: 1,
};

export const STEPS_TITLE = {
  SET_NPI: {
    TITLE: 'Set Relying Party',
    DESCRIPTION: 'Please fill in the required relying party information.',
  },
  CONFIRM_INVITATION: {
    TITLE: 'Confirm Invitation',
    DESCRIPTION: 'Please confirm information below before sending invitation.',
  },
};
