import React from 'react';
import {Step, StepContent, StepLabel, Stepper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

const useStyles = makeStyles({
  stepper: {
    '& .MuiStepper-root': {
      paddingLeft: 32,
      paddingTop: 21,
    },
    '& .MuiStep-root.MuiStep-vertical': {
      height: 32,
    },
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
    '& .MuiStepLabel-label': {
      marginLeft: 21,
      fontSize: 14,
    },
    '& .MuiStepIcon-text': {
      fontSize: 15,
    },
    '& .MuiStepConnector-lineVertical': {
      minHeight: 10,
      marginBottom: 8,
      borderColor: colors.grey200,
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: 8,
      paddingBottom: 0,
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: colors.green,
    },
    '& .MuiStepLabel-root.Mui-disabled ': {
      color: colors.grey300,
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: colors.green,
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
  },
});

export const StepSidebar = ({steps, activeStep}) => {
  const classes = useStyles();

  return (
    <StepBar className={classes.stepper}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent />
          </Step>
        ))}
      </Stepper>
    </StepBar>
  );
};

StepSidebar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
};

const StepBar = styled.div`
  min-width: 300px;
  height: 100%;
  border-right: 1px solid ${colors.grey50};
`;
