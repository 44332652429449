import React, {FC, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Typography} from '@material-ui/core';

import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button} from '../../../shared/components/button';
import {initialResendInvitationModalWindowsState} from '../constants/initialResendInvitationModalWindowsState';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {HandleResendInvitationModalWindowsType} from '../constants/types';

import {ModalWindowBody} from './modalWindow/modalWindowBody';
import {ModalWindowFooter} from './modalWindow/modalWindowFooter';
import {ModalWindowTitle} from './modalWindow/modalWindowTitle';

type Props = {
  isShowModalWindow: boolean;
  setIsShowModalWindow: (val: HandleResendInvitationModalWindowsType) => void;
};

export const PatientAlreadyEnrolledModalWindow: FC<Props> = ({
  isShowModalWindow,
  setIsShowModalWindow,
}) => {
  const [existingRedirect, setExistingRedirect] = useState<null | string>(null);

  const handleOnClick = () => {
    setIsShowModalWindow(initialResendInvitationModalWindowsState);
    setExistingRedirect(PORTAL_PATIENTS_ROUTES.CONNECTIONS);
  };

  const onBack = () => {
    setIsShowModalWindow(initialResendInvitationModalWindowsState);
    setExistingRedirect(PORTAL_PATIENTS_ROUTES.INVITATIONS);
  };

  if (existingRedirect) {
    return <Redirect to={existingRedirect} />;
  }

  return (
    <BaseModalWindow minWidth={350} maxWidth={480} open={isShowModalWindow}>
      <ModalWindowTitle>
        New invitation to connect has been sent
      </ModalWindowTitle>
      <ModalWindowBody>
        <Typography data-testid="patient-already-enrolled-modal">
          This patient will now be moved to the Connections tab. There you can
          review the connection status.
        </Typography>
      </ModalWindowBody>
      <ModalWindowFooter>
        <Button
          color="primary"
          variant="outlined"
          onClick={onBack}
          data-testid="confirm-btn"
        >
          Back to Invitations
        </Button>
        <Button
          color="primary"
          onClick={handleOnClick}
          data-testid="confirm-btn"
        >
          View Connections
        </Button>
      </ModalWindowFooter>
    </BaseModalWindow>
  );
};
