import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {StyledCell} from '../../../shared/components/table/styledCell';
import {INVITATION_STATUS} from '../../../shared/constants/status';
import {colors} from '../../../shared/styles/theme';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {formatRoles} from '../../../shared/utils/formatRoles';
import {PortalUserDetailsButton} from '../components/table/partalUserDetailsBtn';
import {PortalUserEditButton} from '../components/table/portalUserEditBtn';
import {hasEditPermission} from '../utils/permissions';

import {UserInfoType} from './types';

type CellType = {
  row: {
    original: UserInfoType;
  };
};

const Actions: FC<UserInfoType> = ({id, status, roles, email}) => {
  const currentUser = useRecoilValue(currentUserState);
  return (
    <>
      <PortalUserDetailsButton
        userId={id}
        hasPermission={status === INVITATION_STATUS.CONFIRMED}
      />
      <PortalUserEditButton
        userId={id}
        hasPermission={
          status === INVITATION_STATUS.CONFIRMED &&
          hasEditPermission(currentUser, {roles, email})
        }
      />
    </>
  );
};

export const PORTAL_USERS_TABLE_COLUMNS = [
  {
    id: 'name',
    header: 'Name',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.name} fontWeight={600} />;
    },
    width: '272px',
    minWidth: '272px',
    columnClassNames: 'stickyLeft',
    isStickyLeft: true,
  },

  {
    id: 'npi',
    header: 'NPI',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.npi} />;
    },
    width: '145px',
    minWidth: '145px',
    maxWidth: '145px',
  },

  {
    id: 'roles',
    header: 'Role',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={formatRoles(cell.row.original.roles)} />;
    },
    width: '280px',
    minWidth: '280px',
  },

  {
    id: 'email',
    header: 'Email',
    cell(cell: CellType): JSX.Element {
      return <StyledCell info={cell.row.original.email} />;
    },
    width: '300px',
    minWidth: '300px',
  },
  {
    id: 'phone_number',
    header: 'Phone Number',
    cell(cell: CellType): JSX.Element {
      return (
        <StyledCell info={formatPhoneNumber(cell.row.original.phone_number)} />
      );
    },
    minWidth: '170px',
    maxWidth: '170px',
    width: '170px',
  },
  {
    id: 'status',
    header: 'Invitation Status',
    cell(cell: CellType): JSX.Element {
      const {status} = cell.row.original;
      return (
        <StyledCell
          info={status}
          fontWeight="bold"
          color={
            status === INVITATION_STATUS.INVITED
              ? colors.statusBlue
              : colors.statusGreen
          }
        />
      );
    },
    minWidth: '180px',
    maxWidth: '180px',
    width: '180px',
  },
  {
    header: 'Action',
    cell(cell: CellType): JSX.Element {
      return <Actions {...cell.row.original} />;
    },
    width: '115px',
    minWidth: '115px',
    maxWidth: '115px',
    isStickyRight: true,
    columnClassNames: 'stickyRight',
  },
];
