import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {dateFormat} from '../constants/dateFormat';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const formatDate = (
  date: Date | null,
  formatter = 'MMM DD, YYYY hh:mm A (z)'
): string => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format(formatter);
};

export const formatUTCDate = (
  date: Date | null | undefined,
  formatter: string
): string => {
  if (!date) {
    return '-';
  }

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

  return dayjs(date).utc(true).tz(timeZone).format(formatter);
};

export const formatLocalTimeToUTC = (date: Date): string =>
  dayjs(date).utc().format();

export const formatTimeByZone = (time: Date): string => {
  const timezoneOffset = new Date().getTimezoneOffset();

  return dayjs(time).add(timezoneOffset, 'm').format('YYYY-MM-DDTHH:mm');
};

export const formatUsDate = (date: Date): string =>
  dayjs(date).format('M/D/YYYY');

export const formatUsTime = (time: Date): string =>
  dayjs(time).format('M/D/YYYY h:mm A');

export const dateFormatter = (date: Date | null): string =>
  date ? dayjs(date).format(dateFormat) : '';

export const addDays = (date: string, days: number): Date =>
  dayjs(date).add(days, 'day').toDate();

export const convertYearNumberToDate = (year: number): Date =>
  dayjs(year.toString()).toDate();

export const convertDateToRelativeDate = (date: Date): string =>
  dayjs(date).fromNow();
