import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {getCrumbs} from '../../shared/utils/getCrumbs';
import {currentLocationState} from '../locations/atoms/currentLocationState';
import {LocationDetailPage} from '../locations/pages/locationDetailPage';
import {NotFoundPage} from '../notFound';

import {currentPolicyState} from './atoms/currentPolicyState';
import {AddPolicyPage} from './pages/addPolicyPage';
import {PoliciesPage} from './pages/policiesPage';
import {PolicyDetailPage} from './pages/policyDetailPage';

const routes = (policyName, locationName) => [
  {
    path: '/health-policies',
    exact: true,
    component: PoliciesPage,
    title: 'Policies',
  },
  {
    path: '/health-policies/add-policy',
    exact: true,
    component: AddPolicyPage,
    title: 'Add policy',
  },
  {
    path: '/health-policies/:policyId',
    exact: true,
    component: PolicyDetailPage,
    title: policyName,
  },
  {
    path: '/health-policies/:policyId/locations/:locationId',
    exact: true,
    component: LocationDetailPage,
    title: locationName,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const HealthPolicyPage = () => {
  const policyName = useRecoilValue(currentPolicyState);
  const locationName = useRecoilValue(currentLocationState);

  const routesArray = routes(policyName, locationName);
  return (
    <Switch>
      {routesArray.map(route => {
        const ContentComponent = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => (
              <ContentComponent
                {...getCrumbs(props, routesArray)}
                title="Location management"
              />
            )}
          />
        );
      })}
    </Switch>
  );
};
