import React from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {ListItemKey, ListItemValue} from '../../../../shared/components/list';
import {getFullName} from '../../../../shared/utils/getFullName';

import {useGeneralListStyles} from './constants/listTemplate';
import {MedicationDispense} from './types/medicationTypes';
import {formatDate} from './utils/mappersUtils';
import {ElectronicRecordGroupTitle} from './electronicRecordGroupTitle';
import {ListWithFixedLabelsColumn} from './electronicRecordListWithFixedLabelsColumn';
import {StyledDivider} from './styledDivider';

const LABELS_COLUMN_WIDTH = '150px';

const useStyles = makeStyles(() => ({
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    width: '100%',
    boxSizing: 'border-box',
  },
  contentBlockItemGrid: {
    gridTemplateColumns: '1fr 1px 1fr',
    padding: '24px',
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'red',
  },
}));

type Props = {
  dispenses: MedicationDispense[];
};

export const MedicationDispenseComponent: React.FC<Props> = ({dispenses}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();
  const listsWrapperClasses = cx(
    listTemplateClasses.wrapper,
    classes.contentBlockItemGrid
  );
  if (dispenses && dispenses.length > 0) {
    return (
      <>
        {dispenses.map(dispense => (
          <div className={listsWrapperClasses} key={dispense.when_handed_over}>
            <div>
              <ElectronicRecordGroupTitle title="Dispenses" />
              <ListWithFixedLabelsColumn
                labelsColumnWidth={LABELS_COLUMN_WIDTH}
              >
                <ListItemKey>Status</ListItemKey>
                <ListItemValue testId="dispense_status">
                  {dispense.status}
                </ListItemValue>
                <ListItemKey>Not Performed Reason</ListItemKey>
                <ListItemValue testId="dispense_not_performed_reason">
                  {dispense.not_performed_reason}
                </ListItemValue>
                <ListItemKey>Category</ListItemKey>
                <ListItemValue testId="dispense_category">
                  {dispense.category}
                </ListItemValue>
                <ListItemKey>Subject</ListItemKey>
                <ListItemValue testId="dispense_subject">
                  {getFullName(
                    dispense.subject?.name?.first_name,
                    dispense.subject?.name?.middle_name,
                    dispense.subject?.name?.last_name
                  )}
                </ListItemValue>
                <ListItemKey>Performer</ListItemKey>
                {dispense.performer ? (
                  <div>
                    {dispense.performer.map(performer => (
                      <ListItemValue
                        testId="dispense_performer"
                        key={performer}
                      >
                        {performer}
                      </ListItemValue>
                    ))}
                  </div>
                ) : (
                  <ListItemValue testId="dispense_performer" />
                )}

                <ListItemKey>Quantity</ListItemKey>
                <ListItemValue testId="dispense_quantity">
                  {dispense.quantity}
                </ListItemValue>
                <ListItemKey>Days Supply</ListItemKey>
                <ListItemValue testId="dispense_days_supply">
                  {dispense.days_supply}
                </ListItemValue>
                <ListItemKey>Receiver</ListItemKey>
                {dispense.receiver ? (
                  <div>
                    {dispense.receiver?.map(receiver => (
                      <ListItemValue
                        testId="dispense_receiver"
                        key={receiver.last_updated_at}
                      >
                        {getFullName(
                          receiver.name?.first_name,
                          receiver.name?.middle_name,
                          receiver.name?.last_name
                        )}
                      </ListItemValue>
                    ))}
                  </div>
                ) : (
                  <ListItemValue testId="dispense_receiver" />
                )}

                <ListItemKey>Handed Over</ListItemKey>
                <ListItemValue testId="dispense_handed_over">
                  {dispense.when_handed_over}
                </ListItemValue>
                <ListItemKey>Dosage Instruction</ListItemKey>
                <ListItemValue testId="dispense_dosage_instruction">
                  {dispense.dosage_instruction}
                </ListItemValue>
                <ListItemKey>Data Last Updated</ListItemKey>
                <ListItemValue testId="dispense_last_updated_dispense">
                  {formatDate(dispense.last_updated_at)}
                </ListItemValue>
              </ListWithFixedLabelsColumn>
            </div>
            <StyledDivider orientation="vertical" />
          </div>
        ))}
      </>
    );
  }
  return (
    <div className={listsWrapperClasses}>
      <div>
        <ElectronicRecordGroupTitle title="Dispenses" />
        <ListItemKey>Not Available</ListItemKey>
      </div>
      <StyledDivider orientation="vertical" />
    </div>
  );
};
