import React from 'react';
import styled from 'styled-components';

import {colors} from '../styles/theme';

export const RemoveIcon = (): JSX.Element => <Icon className="removeIcon" />;

export const Icon = styled.span`
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 1px solid ${colors.grey801};
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 8px;
    height: 2px;
    background-color: ${colors.grey801};
    top: 6px;
    left: 3px;
    border-radius: 1px;
  }
}
`;
