import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

export default function InvitationLayout({children, title, description}) {
  return (
    <>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {children}
    </>
  );
}

const Title = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: ${colors.black};
  min-width: 760px;
`;

const Description = styled.p`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  color: ${colors.grey801};
  margin: 6px 0 18px;
`;

InvitationLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};
