import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {colors} from '../styles/theme';

import {LogoImageContainer} from './logoImageContainer';

const useStyles = makeStyles({
  logoContainer: ({imgSrc}: {imgSrc: string | null}) => ({
    width: '280px',
    height: '120px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    backgroundColor: !imgSrc ? colors.grey1200 : colors.primary,
    border: `1px solid ${colors.grey200}`,
    fontSize: '12px',
    lineHeight: '120px',
    textAlign: 'center',
    color: `${colors.grey500}`,
    padding: '20px 10px',
    boxSizing: 'border-box',
  }),
});

type Props = {
  imgSrc: string | null;
  placeholder: string;
};

export const LogoPreview: FC<Props> = ({imgSrc, placeholder}) => {
  const classes = useStyles({imgSrc});

  return (
    <div className={classes.logoContainer} data-testid="logo-preview-container">
      <LogoImageContainer placeholder={placeholder} imgSrc={imgSrc} />
    </div>
  );
};
