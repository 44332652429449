import React from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {BaseModalWindow} from './baseModalWindow';
import {CustomizedButton} from './customizedButton';

type Props = {
  title: string;
  content: string;
  isOpen: boolean;
  onClose: () => void;
  buttonTitle: string;
};

export const InfoDialog = ({
  title,
  content,
  isOpen,
  onClose,
  buttonTitle,
  ...props
}: Props): JSX.Element => (
  <BaseModalWindow open={isOpen} onClose={onClose}>
    <Title {...props}>{title}</Title>
    <ContentWrapper>{content}</ContentWrapper>
    <ButtonWrapper>
      <CustomizedButton
        color="primary"
        variant="contained"
        size="large"
        onClick={onClose}
        testID="close-btn"
      >
        {buttonTitle}
      </CustomizedButton>
    </ButtonWrapper>
  </BaseModalWindow>
);

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
`;

const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  text-align: right;
`;
