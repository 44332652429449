import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {getCrumbs} from '../../shared/utils/getCrumbs';
import {NotFoundPage} from '../notFound';

import {currentPortalUserState} from './atoms/currentPortalUserState';
import {PortalUserPage} from './pages/portalUserPage';
import {PortalUserProfilePage} from './pages/portalUserProfilePage';
import {UserInvitationPage} from './pages/userInvitationPage';

const routes = [
  {
    path: '/portal-users',
    exact: true,
    component: PortalUserPage,
    title: 'Portal users',
  },
  {
    path: '/portal-users/:portalUserId/profile',
    exact: true,
    component: PortalUserProfilePage,
  },
  {
    path: '/portal-users/invitation',
    exact: true,
    component: UserInvitationPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const PortalUsersRoutes = () => {
  const portalUserName = useRecoilValue(currentPortalUserState);
  return (
    <Switch>
      {routes.map(route => {
        const ContentComponent = route.component;
        return (
          <Route
            path={route.path}
            exact={route.exact}
            key={route.path}
            render={props => (
              <ContentComponent
                {...getCrumbs(props, routes, portalUserName)}
                title="User management"
              />
            )}
          />
        );
      })}
    </Switch>
  );
};
