import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {MedicalDocument} from '../constants/types';
import {onClickOnEmulatedDownloadLink} from '../utils/emulatedDownloadLink';
import {fetchDownloadFile, fetchDownloadFilesUrls} from '../utils/fetchers';

export const useDownloadFiles = (): {
  onDownloadFiles: (files: MedicalDocument[]) => Promise<void>;
} => {
  const showMessage = useCustomizedSnackbar();
  const onDownloadFile = async ({
    upload_url,
    file_name,
  }: {
    upload_url: string;
    file_name: string;
  }) => {
    const response = await fetchDownloadFile(upload_url);
    const file = await response.blob();

    onClickOnEmulatedDownloadLink(file_name, file);
  };

  const onDownloadFiles = async (files: MedicalDocument[]) => {
    try {
      const payload = files.map(
        ({file_name, uploaded_file_name, consumer_uuid}) => ({
          file_name,
          uploaded_file_name,
          consumer_uuid,
        })
      );
      const downloadFilesUrlsData: {
        upload_url: string;
        file_name: string;
      }[] = await fetchDownloadFilesUrls(payload);

      Promise.allSettled(
        downloadFilesUrlsData.map(file => onDownloadFile(file))
      ).then(values => {
        values.forEach(({status}, index) => {
          if (status === 'rejected') {
            showMessage(
              `Failed download ${files[index].file_name} file. Please try again later.`,
              'error'
            );
          }
        });
      });
    } catch (e) {
      showMessage(
        'Something went wrong while the files download. Please try again later.',
        'error'
      );
    }
  };

  return {
    onDownloadFiles,
  };
};
