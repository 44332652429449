import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {CustomizedSelect} from '../../../shared/components/customizedSelect';
import {InfoTooltip} from '../../../shared/components/infoTooltip';
import {InputTextField} from '../../../shared/components/inputTextField';
import {TooltipContent} from '../../../shared/components/toolTipContent';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {
  MergedPolicyType,
  PolicyTypeId,
} from '../../../shared/interfaces/policy';
import {checkDuplicates} from '../../../shared/utils/checkDuplicates';
import {checkAddFormButtonDisability} from '../../../shared/utils/formikUtils';
import {urls} from '../../../shared/utils/urls';
import {MainHeader} from '../../layout/components/mainHeader';
import {defaultPolicyMap, POLICY_TYPES} from '../constants/defaultPolicy';
import {usePolicyTypes} from '../hooks/usePolicyTypes';
import {choosePolicyTypeFormValidateSchema} from '../utils/policyFormValidate';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      height: 'auto',
    },
  },
}));

const policyTypeTips = [
  'There are two types of policies - a registration policy and an entry policy.',
  'Registration policies set requirements for collecting information from a patient for registration.',
  'Entry policies set requirements for entering a specific location.',
];

interface PolicySelectValuesType {
  policyKey: PolicyTypeId;
  policyName?: string;
}

interface SelectPolicyTypeFormProps {
  onNext: (policy: MergedPolicyType) => void;
  onBack: () => void;
  cancelButtonText?: string;
  initialValues?: PolicySelectValuesType;
}

export const SelectPolicyTypeForm: React.FC<SelectPolicyTypeFormProps> = ({
  onNext,
  onBack,
  cancelButtonText = 'Cancel',
  initialValues,
}) => {
  const handleError = useErrorHandling();

  const handleSubmit = useCallback(
    async (
      values: PolicySelectValuesType,
      {setFieldError}: FormikHelpers<PolicySelectValuesType>
    ) => {
      try {
        const doesExist = await checkDuplicates(
          values.policyName,
          urls.checkPolicyName
        );
        const choosenPolicyKey = values.policyKey;
        const mapPolicyKey = POLICY_TYPES[choosenPolicyKey];
        const policy = defaultPolicyMap[mapPolicyKey];
        const newPolicy = {...policy, name: values.policyName ?? ''};

        if (doesExist) {
          setFieldError('policyName', 'This name has already been registered.');
        } else {
          onNext(newPolicy);
        }
      } catch (error) {
        await handleError(error);
      }
    },
    [handleError, onNext]
  );
  const classes = useStyles();

  const policyTypes = usePolicyTypes();

  const defaultInitialValues = {
    policyKey: policyTypes[0].key,
    policyName: '',
  };

  return (
    <>
      <MainHeader
        title="Create new policy"
        description="First select policy type, then name the policy, and finally select all requirements."
      />
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialValues ?? defaultInitialValues}
        initialErrors={{policyKey: '', policyName: ''}}
        onSubmit={handleSubmit}
        validationSchema={choosePolicyTypeFormValidateSchema}
      >
        {({submitForm, ...props}) => (
          <Form autoComplete="off" className={classes.root}>
            <Subtitle>Select policy type</Subtitle>
            <SelectInputContainer>
              <FieldContainer>
                <Field
                  as={CustomizedSelect}
                  name="policyKey"
                  label="Policy types"
                  testID="policyTypeInput"
                  options={policyTypes.map(({value}) => value)}
                  values={policyTypes.map(({key}) => key)}
                />
                <InfoTipContainer>
                  <InfoTooltip
                    description={<TooltipContent tips={policyTypeTips} />}
                  />
                </InfoTipContainer>
              </FieldContainer>
            </SelectInputContainer>
            <Subtitle>Name policy</Subtitle>
            <TextInput
              label="Policy name"
              name="policyName"
              testID="policyNameInput"
              placeholder="Enter policy name"
            />
            <ButtonContainer>
              <Button variant="outlined" onClick={onBack}>
                {cancelButtonText}
              </Button>
              <Button
                type="submit"
                onClick={submitForm}
                disabled={checkAddFormButtonDisability(props)}
              >
                Next
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;

const SelectInputContainer = styled.div`
  margin-bottom: 21px;
`;

const TextInput = styled(InputTextField)`
  margin-bottom: 14px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoTipContainer = styled.div`
  margin-top: 22px;
  margin-left: -10px;
`;
