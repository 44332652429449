import React, {useCallback} from 'react';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../../shared/atoms/authAtom';
import {ActionTableButton} from '../../../../shared/components/table/actionTableButton';
import {ROLES} from '../../../../shared/constants/roles';
import {USER_TYPES} from '../../../../shared/constants/userTypes';
import {StarFilledIcon} from '../../../../shared/icons/starFilledIcon';
import {StarOutlinedIcon} from '../../../../shared/icons/starOutlinedIcon';
import {isRoleMatched} from '../../../../shared/utils/user';
import {isMyPatientAddingState} from '../../atoms/isMyPatientAddingState';
import {isMyPatientRemovingState} from '../../atoms/isMyPatientRemovingState';
import {HIDE_MY_CONNECTIONS} from '../../constants/flags';
import {useManageMyPatient} from '../../hooks/useManageMyPatient';
import {usePatientsSnackbars} from '../../hooks/usePatientsSnackbars';

type Props = {
  patientIsPreferred: boolean;
  patientUuid: string;
  patientName: string;
  getEnrolledPatients: () => Promise<void>;
};

export const ToggleMyPatientBtn: React.FC<Props> = ({
  patientIsPreferred,
  patientUuid,
  patientName,
  getEnrolledPatients,
}) => {
  const isMyPatientRemoving = useRecoilValue(isMyPatientRemovingState);
  const isMyPatientAdding = useRecoilValue(isMyPatientAddingState);
  const currentUser = useRecoilValue(currentUserState);
  const {onShowDeleteMyPatientSnackbar} = usePatientsSnackbars();
  const {removeMyPatient, addMyPatient} = useManageMyPatient();

  const isCurrentUserPhysician = USER_TYPES.PHYSICIAN === currentUser?.type;
  const isCurrentUserHasRightRoles = isRoleMatched(currentUser?.roles ?? [], [
    ROLES.RELYING_PARTY_OWNER,
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
  ]);

  const onToggleMyPatient = useCallback(async () => {
    if (patientIsPreferred) {
      await removeMyPatient(patientUuid);
      onShowDeleteMyPatientSnackbar({
        name: patientName,
        uuid: patientUuid,
        cb: async () => {
          await addMyPatient(patientUuid);
          await getEnrolledPatients();
        },
      });
    } else {
      await addMyPatient(patientUuid);
    }
    await getEnrolledPatients();
  }, [
    addMyPatient,
    getEnrolledPatients,
    onShowDeleteMyPatientSnackbar,
    patientIsPreferred,
    patientName,
    patientUuid,
    removeMyPatient,
  ]);

  const isShowToggleMyPatientBtn =
    isCurrentUserPhysician &&
    isCurrentUserHasRightRoles &&
    !HIDE_MY_CONNECTIONS;

  return isShowToggleMyPatientBtn ? (
    <Wrapper>
      <ActionTableButton
        testId={patientUuid}
        disabled={isMyPatientRemoving || isMyPatientAdding}
        onClick={onToggleMyPatient}
        title={
          patientIsPreferred
            ? 'Remove from My Connections'
            : 'Add to My Connections'
        }
      >
        {patientIsPreferred ? <StarFilledIcon /> : <StarOutlinedIcon />}
      </ActionTableButton>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  margin-right: 6px;
  height: 24px;
  display: flex;
  align-items: center;
`;
