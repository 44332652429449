import {useCallback, useState} from 'react';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {fetchUploadIssuerLogo} from '../utils/fetchers';

import {useSetStateIssuerLogo} from './useSetStateIssuerLogo';

type ReturnHookType = {
  uploadIssuerLogoLoading: boolean;
  uploadIssuerLogo: (val: File | null) => Promise<void>;
};

export const useUploadIssuerLogo = (): ReturnHookType => {
  const showMessage = useCustomizedSnackbar();
  const setStateIssuerLogo = useSetStateIssuerLogo();
  const [uploadIssuerLogoLoading, setUploadIssuerLogoLoading] = useState(false);

  const uploadIssuerLogo = useCallback(
    async file => {
      setUploadIssuerLogoLoading(true);

      const formData: FormData = new FormData();
      formData.append('file', file);

      try {
        await fetchUploadIssuerLogo(formData);

        await setStateIssuerLogo();

        showMessage('Changes saved successfully.', 'success');
      } catch {
        showMessage('Logo upload failed. Please try again.', 'error');
      }

      setUploadIssuerLogoLoading(false);
    },
    [setStateIssuerLogo, showMessage]
  );

  return {uploadIssuerLogo, uploadIssuerLogoLoading};
};
