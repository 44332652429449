import React from 'react';

import {Description} from '../../../../shared/components/description';

import {ViewFileWrapper} from './viewerWrapper';

type Prpos = {
  title: string;
  description?: string;
  dataTestId?: string;
};

export const ViewerScreenMessage: React.FC<Prpos> = ({
  title,
  description,
  dataTestId,
}) => (
  <ViewFileWrapper data-testid={dataTestId}>
    <Description title={title} description={description} />
  </ViewFileWrapper>
);
