import React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import styled from 'styled-components';

import {ContentMRN} from '../../../shared/components/contentMRN';
import {NoDataDash} from '../../../shared/components/noDataDash';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {dateFormatter} from '../../../shared/utils/formatDate';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {ConnectionStatus} from '../components/table/connectionStatus';
import {MedicalRecordsStatus} from '../components/table/medicalRecordsStatus';
import {MyPatientRemoveButton} from '../components/table/myPatientRemoveBtn';
import {PatientDataRequestActionsButtons} from '../components/table/patientDataRequestActionsButtons';
import {PatientNameLink} from '../components/table/patientNameLink';
import {ToggleMyPatientBtn} from '../components/table/toggleMyPatientBtn';

import {CONNECTION_STATUSES} from './patientStatuses';
import {PatientFormattedType} from './types';

export const getMyPatientNameCell = (
  getMyPatients: () => Promise<void>
): ColumnDef<PatientFormattedType> & AdditionalTableProps => ({
  id: 'name',
  header: 'Name',
  width: '272px',
  minWidth: '272px',
  isStickyLeft: true,
  searchable: true,
  accessorKey: 'name',
  cell({row: {original}}) {
    return (
      <NameContainer>
        <MyPatientRemoveButton
          patientName={original.name}
          patientUuid={original.consumer_uuid}
          getMyPatients={getMyPatients}
        />
        <PatientNameLink
          patientName={original.name}
          patientUuid={original.consumer_uuid}
          firstName={original.firstName}
          clientId={original.patientSharingRequestData?.client_id}
          invalidateKey="myPatients"
          connectionStatus={original.patientSharingRequestData?.status}
          connectionId={original?.patientSharingRequestData?.id}
          relyingPartyId={original.patientSharingRequestData?.relying_party_id}
          isSharingMedicalRecords={
            original.patientSharingRequestData?.share_patient_to_provider
          }
          isUpdateRequested={
            original.patientSharingRequestData?.is_update_requested
          }
          isConnectionData={!!original.patientSharingRequestData}
        />
      </NameContainer>
    );
  },
});

export const getEnrolledPatientNameCell = (
  getEnrolledPatients: () => Promise<void>
): ColumnDef<PatientFormattedType> & AdditionalTableProps => ({
  id: 'name',
  header: 'Name',
  width: '272px',
  minWidth: '272px',
  isStickyLeft: true,
  searchable: true,
  accessorKey: 'name',
  cell({row: {original}}) {
    return (
      <NameContainer>
        <ToggleMyPatientBtn
          patientIsPreferred={original.isPreferred}
          patientUuid={original.consumer_uuid}
          patientName={original.name}
          getEnrolledPatients={getEnrolledPatients}
        />
        <PatientNameLink
          patientName={original.name}
          patientUuid={original.consumer_uuid}
          firstName={original.firstName}
          clientId={original.patientSharingRequestData?.client_id}
          invalidateKey="enrolledPatients"
          connectionStatus={original.patientSharingRequestData?.status}
          connectionId={original.patientSharingRequestData?.id}
          relyingPartyId={original.patientSharingRequestData?.relying_party_id}
          isSharingMedicalRecords={
            original.patientSharingRequestData?.share_patient_to_provider
          }
          isUpdateRequested={
            original.patientSharingRequestData?.is_update_requested
          }
          isConnectionData={!!original.patientSharingRequestData}
        />
      </NameContainer>
    );
  },
});

const NameContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  word-break: break-word;
`;

export const getPatientsTableCells = (
  getEnrolledPatients: () => Promise<void>
): (ColumnDef<PatientFormattedType> & AdditionalTableProps)[] => [
  {
    id: 'MRN',
    header: 'MRN',
    minWidth: '180px',
    cell({row: {original}}) {
      return (
        <ContentMRN
          MRNData={original.medicalRecordNumbers}
          id={original.consumer_uuid}
        />
      );
    },
  },
  {
    id: 'dateOfBirth',
    header: 'Date of Birth',
    cell: cell => dateFormatter(cell.row.original.date_of_birth),
    minWidth: '160px',
  },
  {
    id: 'phoneNumber',
    accessorKey: 'mobile_phone_number',
    header: 'Mobile Phone',
    cell(cell) {
      return cell.row.original.mobile_phone_number ? (
        formatPhoneNumber(cell.row.original.mobile_phone_number)
      ) : (
        <NoDataDash
          testId={`no-phone-dash-${cell.row.original.consumer_uuid}`}
        />
      );
    },
    searchable: true,
    minWidth: '190px',
  },
  {
    id: 'connectionStatus',
    header: 'Connection Status',
    minWidth: '190px',
    cell(cell) {
      return cell.row.original?.patientSharingRequestData ? (
        <ConnectionStatus
          status={cell.row.original?.patientSharingRequestData?.status}
          consumerUuid={cell.row.original.consumer_uuid}
        />
      ) : null;
    },
  },
  {
    id: 'medicalRecordsStatus',
    header: 'Patient Record Status',
    minWidth: '350px',
    cell({row: {original}}) {
      return original?.patientSharingRequestData ? (
        <MedicalRecordsStatus
          consumerUuid={original?.consumer_uuid}
          isPatientConnected={
            original?.patientSharingRequestData?.status ===
            CONNECTION_STATUSES.CONNECTED
          }
          isReceivingMedicalRecords={
            original?.patientSharingRequestData?.share_provider_to_patient
          }
          isSharingMedicalRecords={
            original?.patientSharingRequestData?.share_patient_to_provider
          }
          isUpdateRequested={
            original?.patientSharingRequestData?.is_update_requested
          }
        />
      ) : null;
    },
  },
  {
    id: 'actions',
    header: 'Actions',
    width: '150px',
    minWidth: '150px',
    isStickyRight: true,
    cell({row: {original}}) {
      return original?.patientSharingRequestData ? (
        <PatientDataRequestActionsButtons
          updatePatientsList={getEnrolledPatients}
          consumerUuid={original?.consumer_uuid}
          name={original?.name}
          firstName={original?.firstName}
          connectionStatus={original?.patientSharingRequestData?.status}
          connectionId={original?.patientSharingRequestData?.id}
          relyingPartyId={original?.patientSharingRequestData?.relying_party_id}
          isSharingMedicalRecords={
            original?.patientSharingRequestData?.share_patient_to_provider
          }
          isUpdateRequested={
            original?.patientSharingRequestData?.is_update_requested
          }
        />
      ) : null;
    },
  },
];
