import React from 'react';

export function AllClearIDLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="71"
      viewBox="0 0 175 71"
      fill="none"
      style={{
        position: 'absolute',
        bottom: '349px',
      }}
    >
      <g clipPath="url(#clip0_234_186)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.45 21.8C164.45 33.84 154.69 43.6 142.65 43.6C130.61 43.6 120.85 33.84 120.85 21.8C120.85 9.76 130.61 0 142.65 0C154.69 0 164.45 9.76 164.45 21.8ZM156.54 17.35H147.09V7.89H138.2V17.35H128.74V26.25H138.19V35.71H147.1V26.25H156.56L156.54 17.35Z"
          fill="white"
        />
        <path
          d="M167.25 70.46H166.43V66.15H164.96V65.45H168.73V66.15H167.26V70.46H167.25Z"
          fill="white"
        />
        <path
          d="M171.66 70.46L170.21 66.27H170.18C170.22 66.89 170.24 67.47 170.24 68.02V70.46H169.5V65.45H170.66L172.05 69.44H172.07L173.5 65.45H174.66V70.46H173.87V67.98C173.87 67.73 173.87 67.41 173.89 67.01C173.9 66.61 173.91 66.37 173.92 66.28H173.89L172.39 70.46H171.67H171.66Z"
          fill="white"
        />
        <path
          d="M17.23 38.01V27.17H4.34V38.01H0V13.25H4.34V23.35H17.22V13.25H21.56V38.01H17.22H17.23Z"
          fill="white"
        />
        <path
          d="M25.7998 38.01V17.59H40.6898V21.12H29.8898V25.83H40.5098V29.36H29.8898V34.48H40.6898V38.01H25.7998Z"
          fill="white"
        />
        <path
          d="M54.7997 17.59L63.2297 38.01H58.6997L57.1397 34.15H47.5997L46.0397 38.01H41.4697L49.9297 17.59H54.7897H54.7997ZM52.3797 21.71L48.7797 30.62H55.9497L52.3897 21.71H52.3797Z"
          fill="white"
        />
        <path
          d="M64.8203 38.01V17.59H68.9003V34.48H78.2603V38.01H64.8203Z"
          fill="white"
        />
        <path
          d="M82.34 38.01V21.12H76.29V17.59H92.4V21.12H86.39V38.01H82.34Z"
          fill="white"
        />
        <path
          d="M109.7 38.01V29.29H98.9296V38.01H94.8496V17.59H98.9296V25.76H109.7V17.59H113.71V38.01H109.7Z"
          fill="white"
        />
        <path
          d="M0 70.01V45.25H12.18C16.75 45.25 19.23 48.07 19.23 51.56C19.23 54.64 17.23 56.76 14.92 57.24C17.59 57.65 19.71 60.28 19.71 63.29C19.71 67.15 17.19 70.01 12.51 70.01H0ZM14.78 52.27C14.78 50.45 13.52 49.08 11.33 49.08H4.35V55.47H11.33C13.52 55.47 14.78 54.17 14.78 52.28V52.27ZM15.26 62.74C15.26 60.88 13.96 59.29 11.51 59.29H4.34V66.2H11.51C13.85 66.2 15.26 64.9 15.26 62.75V62.74Z"
          fill="white"
        />
        <path
          d="M34.4101 49.59L42.8401 70.01H38.3101L36.7501 66.15H27.2101L25.6501 70.01H21.0801L29.5401 49.59H34.4001H34.4101ZM32.0001 53.71L28.4001 62.62H35.5701L32.0101 53.71H32.0001Z"
          fill="white"
        />
        <path
          d="M59.6602 70.01L49.0402 55.86V70.01H44.9902V49.59H49.3302L59.7202 63.33V49.59H63.7702V70.01H59.6502H59.6602Z"
          fill="white"
        />
        <path
          d="M81.26 70.01L74.21 61.58L72.54 63.47V70H68.46V49.58H72.54V58.9L80.6 49.58H85.57L76.85 59.2L86.21 70H81.27L81.26 70.01Z"
          fill="white"
        />
        <path
          d="M94.6299 57.65C94.6299 50.26 99.8599 44.84 107.29 44.84C114.72 44.84 119.95 50.26 119.95 57.65C119.95 65.04 114.68 70.46 107.29 70.46C99.8999 70.46 94.6299 65.04 94.6299 57.65ZM115.49 57.65C115.49 52.53 112.26 48.7 107.29 48.7C102.32 48.7 99.0899 52.52 99.0899 57.65C99.0899 62.78 102.28 66.6 107.29 66.6C112.3 66.6 115.49 62.74 115.49 57.65Z"
          fill="white"
        />
        <path
          d="M138.29 70.01L127.67 55.86V70.01H123.62V49.59H127.96L138.35 63.33V49.59H142.4V70.01H138.28H138.29Z"
          fill="white"
        />
        <path
          d="M147.08 70.01V49.59H161.97V53.12H151.17V57.83H161.79V61.36H151.17V66.48H161.97V70.01H147.08Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_234_186">
          <rect width="174.66" height="70.46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
