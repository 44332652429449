import React, {FC} from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';

export const CommentIcon: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 18 12" {...props}>
    <path
      d="M3.83 5.33c0-.46.38-.83.84-.83h6.66a.83.83 0 0 1 0 1.67H4.67a.83.83 0 0 1-.84-.84ZM4.67 7.83a.83.83 0 0 0 0 1.67h4.16a.83.83 0 1 0 0-1.67H4.67Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.92 15.2c0 .36-.45.55-.71.29l-2.24-2.24H1.59c-.42 0-.78-.15-1.07-.44-.29-.29-.44-.65-.44-1.07V2.26c0-.42.15-.78.44-1.07.3-.3.65-.44 1.07-.44h12.82c.42 0 .78.15 1.07.44.3.29.44.65.44 1.07v12.93ZM1.33 2.25V12H13.5l1.18 1.18V2.26a.25.25 0 0 0-.07-.19.25.25 0 0 0-.19-.07H1.6a.25.25 0 0 0-.18.07.25.25 0 0 0-.08.19Z"
      fill="currentColor"
    />
  </SvgIcon>
);
