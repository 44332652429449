import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.grey400,
    fontStyle: 'italic',
    fontSize: 12,
    borderRadius: 4,
    padding: '10px 20px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: colors.white,
  },
}));

export const InfoTooltip = ({
  description,
  placement = 'right',
  testId = 'info-tooltip',
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={classes}
      title={description}
      disableFocusListener
      arrow
      placement={placement}
    >
      <IconContainer aria-label="description" data-testid={testId}>
        <InfoIcon style={{color: colors.grey1100}} />
      </IconContainer>
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placement: PropTypes.string,
  testId: PropTypes.string,
};

const IconContainer = styled.div`
  display: flex;
`;
