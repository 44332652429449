import React, {FC} from 'react';
import styled from 'styled-components';

type Props = {
  placeholder: string;
  imgSrc: string | null;
};

export const LogoImageContainer: FC<Props> = ({placeholder, imgSrc}) =>
  imgSrc ? <LogoImg src={imgSrc} alt="logo" /> : <span>{placeholder}</span>;

const LogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
`;
