import React from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {NoPropsComponent} from '../../shared/interfaces/common';
import {colors} from '../../shared/styles/theme';
import {SignPageTemplate} from '../components/signPageTemplate';

const defaultDescription = `Your account has been created successfully.

Please download the MockMed Health ID Manager app and enter your email and password to sign in.`;

export const SetPasswordSuccessPage: NoPropsComponent = () => {
  const {state} = useLocation<{
    title?: string;
    isLogoLeft?: boolean;
    description?: string;
  }>();
  return (
    <SignPageTemplate
      title={state?.title ?? 'ACCOUNT CREATED'}
      isLogoLeft={state?.isLogoLeft}
    >
      <Content>{state?.description ?? defaultDescription}</Content>
    </SignPageTemplate>
  );
};

const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.black};
  width: 380px;
  margin: 0 auto 24px;
  white-space: pre-line;
`;
