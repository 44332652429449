import React, {Dispatch, SetStateAction, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import {BaseModalWindow} from '../../../../shared/components/baseModalWindow';
import {Button} from '../../../../shared/components/button';
import {ALLOWED_FILE_TYPE} from '../../../../shared/interfaces/aIChat';

interface ModalWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
  onFileSelect: (consumerId: string, file: File | null) => void;
  setIsMedicalFilesSelected: Dispatch<SetStateAction<boolean>>;
}
const ChatFileModalWindow: React.FC<ModalWrapperProps> = ({
  isOpen,
  handleClose,
  onFileSelect,
  setIsMedicalFilesSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {patientUuid} = useParams<{patientUuid: string}>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleContinue = () => {
    if (selectedValue === 'computer') {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      setIsMedicalFilesSelected(true);
      handleClose();
    }
  };

  const handleModalClose = () => {
    handleClose();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    onFileSelect(patientUuid, file);
    handleClose();
  };
  const getAllowedFiles = (): string =>
    Object.values(ALLOWED_FILE_TYPE)
      .filter(type => !type.startsWith('image/'))
      .join(', ');

  return (
    <BaseModalWindow
      minWidth={528}
      maxWidth={528}
      open={isOpen}
      onClose={handleModalClose}
    >
      <ModalWrapper>
        <ContentWrapper>
          <Title>Select File Location</Title>
          <SubTitle>
            Please choose where you would like to retrieve your file.
          </SubTitle>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
          >
            <FormControlLabel
              value="medical-files"
              control={<Radio />}
              label="File from Patient Medical Files"
              data-testid="file-from-medical"
            />
            <FormControlLabel
              value="computer"
              control={<Radio />}
              label="File from computer"
              data-testid="file-from-computer"
            />
          </RadioGroup>
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            className="cancelButton"
            variant="outlined"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button data-testid="select-file" onClick={handleContinue}>
            Continue
          </Button>
          <VisuallyHiddenInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            data-testid="file-input"
            accept={getAllowedFiles()}
          />
        </ButtonWrapper>
      </ModalWrapper>
    </BaseModalWindow>
  );
};

export default ChatFileModalWindow;

const VisuallyHiddenInput = styled.input`
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
`;

const Title = styled(Typography)`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const SubTitle = styled(Typography)`
  margin: 0;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const ModalWrapper = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;
