export const codeSystems = {
  icd9cm: 'terminology.hl7.org/CodeSystem/icd9cm',
  icd10cm: 'hl7.org/fhir/sid/icd-10-cm',
  icd10pcs: 'www.cms.gov/medicare/icd-10/2023-icd-10-pcs',
  cpt: 'www.ama-assn.org/go/cpt',
  hcpcs: 'www.nlm.nih.gov/umls/current/HCPCS',
  snomedCt: 'snomed.info/sct',
  loinc: 'loinc.org',
  rxNorm: 'www.nlm.nih.gov/research/umls/rxnorm',
  ndc: 'hl7.org/fhir/sid/ndc',
  imoHealth: 'imohealth.com/ontology',
};
export const codeSystemLabels: {[key: string]: string} = {
  [codeSystems.icd9cm]: 'ICD-9-CM',
  [codeSystems.icd10cm]: 'ICD-10-CM',
  [codeSystems.icd10pcs]: 'ICD-10-PCS',
  [codeSystems.cpt]: 'CPT',
  [codeSystems.hcpcs]: 'HCPCS',
  [codeSystems.snomedCt]: 'SNOMED CT',
  [codeSystems.loinc]: 'LOINC',
  [codeSystems.rxNorm]: 'RxNorm',
  [codeSystems.ndc]: 'NDC',
  [codeSystems.imoHealth]: 'IMO Health',
};
