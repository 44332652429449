import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {urls} from '../../../shared/utils/urls';
import {DeleteModal} from '../../healthPolicies/components/deleteModal';
import {currentLocationState} from '../atoms/currentLocationState';
import {LocationDetail} from '../components/locationDetail';
import {LocationEditForm} from '../components/locationEditForm';
import {LocationType} from '../constants/locationsTableConfig';

const getLocationUrl = (id, type) => {
  switch (type) {
    case LocationType.PHYSICAL:
      return urls.physicalLocation(id);
    case LocationType.VIRTUAL:
      return urls.location(id);
    default:
      return null;
  }
};

export const LocationDetailPage = ({crumbs, title}) => {
  const {state} = useLocation();
  const history = useHistory();
  const {locationId, locationType} = useParams();

  const {data: locationData} = useSWR(getLocationUrl(locationId, locationType));

  const [editMode, setEditMode] = useState(!!state?.from || false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const setCurrentLocation = useSetRecoilState(currentLocationState);

  const onEdit = useCallback(() => setEditMode(true), []);

  const onDelete = useCallback(() => setOpenDeleteModal(true), []);

  const closeModal = useCallback(() => setOpenDeleteModal(false), []);

  useEffect(() => {
    if (locationData) {
      setCurrentLocation(
        `${locationData.relying_party_name} ${locationData.name}`
      );
    }
  }, [locationData, setCurrentLocation]);

  const cancelEdit = useCallback(() => {
    if (state?.from) {
      history.push(state?.from);
    } else {
      setEditMode(false);
    }
  }, [state, history]);

  if (!locationData) {
    return null;
  }

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      {editMode ? (
        <LocationEditForm cancelEdit={cancelEdit} location={locationData} />
      ) : (
        <LocationDetail
          location={locationData}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          data={locationData}
          open={openDeleteModal}
          closeModal={closeModal}
          type="location"
        />
      )}
    </Container>
  );
};

LocationDetailPage.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const Container = styled.div`
  width: 100%;
`;
