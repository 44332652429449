import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import {getCrumbs} from '../../shared/utils/getCrumbs';
import {NotFoundPage} from '../notFound';

import {currentLocationState} from './atoms/currentLocationState';
import {AddNewLocationPage} from './pages/addNewLocationPage';
import {LocationDetailPage} from './pages/locationDetailPage';
import {LocationsPage} from './pages/locationsPage';

const routes = [
  {
    path: '/locations',
    exact: true,
    component: LocationsPage,
    title: 'Locations',
  },
  {
    path: '/locations/add-location',
    exact: true,
    component: AddNewLocationPage,
    title: 'Add new location',
  },
  {
    path: '/locations/:locationId/:locationType',
    exact: true,
    component: LocationDetailPage,
  },
  {
    path: '*',
    component: NotFoundPage,
  },
];

export const LocationRoutes = () => {
  const locationName = useRecoilValue(currentLocationState);

  return (
    <Switch>
      {routes.map(route => {
        const ContentComponent = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={props => (
              <ContentComponent
                {...getCrumbs(props, routes, locationName)}
                title="Location management"
              />
            )}
          />
        );
      })}
    </Switch>
  );
};
