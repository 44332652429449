import {includes} from 'lodash';

export const getCrumbs = (props, routes, name = '') => {
  const {params, path} = props.match;

  const replaceParams = url =>
    Object.keys(params).reduce(
      (accumPath, key) => accumPath.replace(`:${key}`, params[key]),
      url
    );

  const isItSubRouteOrMatch = (
    currentRoutePathInBrowser,
    pathFromListOfRoutes
  ) => {
    if (!includes(currentRoutePathInBrowser, pathFromListOfRoutes)) {
      return false;
    }

    const routeTail = currentRoutePathInBrowser.replace(
      pathFromListOfRoutes,
      ''
    );

    return routeTail === '' || routeTail[0] === '/';
  };

  const crumbs = routes
    .filter(route => isItSubRouteOrMatch(path, route.path))
    .map(route => ({
      path:
        Object.keys(params).length > 0 ? replaceParams(route.path) : route.path,
      title: route.title || name,
      locationState: props.location?.state,
    }));

  return {...props, crumbs};
};
