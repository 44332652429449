import React from 'react';
import {DropzoneRef, useDropzone} from 'react-dropzone';
import {Box, Typography} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import styled from 'styled-components';

import {colors} from '../styles/theme';
import {wrapAsyncFunction} from '../utils/wrapAsyncFunction';

import {Button} from './button';

const useStyles = makeStyles(() => ({
  dropzoneIsDragAccepted: {
    borderWidth: '4px',
    borderStyle: 'dashed',
    backgroundColor: `${colors.blue}10`,
    borderColor: colors.blue,
  },
  dropzoneIsWaiting: {
    borderWidth: '4px',
    borderStyle: 'dashed',
    borderColor: colors.grey800,
  },
  dropzone: {
    borderRadius: '8px',
    padding: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneHasNoFiles: {
    minHeight: '300px',
  },
  dropzoneHasFiles: {
    minHeight: '160px',
  },
}));

const FixedDivider = () => (
  <Box
    marginTop="16px"
    marginBottom="16px"
    marginLeft="auto"
    marginRight="auto"
    width="128px"
  >
    <Divider />
  </Box>
);

const DropZoneScreen: React.FC<{
  open: () => void;
  isDragAccept: boolean;
  hasFiles: boolean;
}> = ({
  open,
  isDragAccept,
  hasFiles,
}: DropzoneRef & {isDragAccept: boolean; hasFiles: boolean}) => (
  <DropZoneScreenWrapper>
    <Typography
      component="p"
      style={{
        fontSize: 16,
        lineHeight: '28px',
        color: isDragAccept ? colors.primary : colors.grey801,
      }}
    >
      Drag and drop your files here. Supported file extensions: <br />
      <b>ccd, сcda, dcm, jpeg, jpg, pdf, png, xml, zip</b>
    </Typography>
    {!isDragAccept ? (
      <>
        <FixedDivider />
        <Typography
          component="p"
          style={{fontWeight: 600, lineHeight: '28px', marginBottom: '16px'}}
        >
          OR
        </Typography>
      </>
    ) : null}
    {!isDragAccept ? (
      <Button
        onClick={open}
        position="section"
        data-testid="click-to-browse-btn"
      >
        {hasFiles ? 'Click to Browse' : 'Browse on Device'}
      </Button>
    ) : null}
  </DropZoneScreenWrapper>
);

type Props = {
  hasFiles: boolean;
  onDrop: (files: File[]) => Promise<void>;
};
export const DropZone: React.FC<Props> = ({hasFiles, onDrop}) => {
  const {getRootProps, getInputProps, open, isDragAccept} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: wrapAsyncFunction(onDrop),
  });
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.dropzone, {
        [classes.dropzoneIsWaiting]: !isDragAccept,
        [classes.dropzoneIsDragAccepted]: isDragAccept,
        [classes.dropzoneHasNoFiles]: !hasFiles,
        [classes.dropzoneHasFiles]: hasFiles,
      })}
      {...getRootProps()}
    >
      <input data-testid="dropzone" {...getInputProps()} />
      <DropZoneScreen
        open={open}
        isDragAccept={isDragAccept}
        hasFiles={hasFiles}
      />
    </div>
  );
};

const DropZoneScreenWrapper = styled.div`
  text-align: center;
  color: ${colors.grey801};
`;
