import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

import {LoadingBar} from '../../../shared/components/loadingBar';
import {SmthWentWrongPlaceholder} from '../../../shared/components/smthWentWrongPlaceholder';
import {usePatientDocuments} from '../hooks/usePatientDocuments';
import {isSmthWentWrong} from '../utils/isSmthWentWrong';

import {PatientDocuments} from './patientDocuments';

type Props = {
  testId: string;
  consumerUuid: string;
  primaryInsuranceId: number | null;
  secondaryInsuranceId: number | null;
  patientFirstName: string;
  patientLastName: string;
};

export const DocumentsTab: FC<Props> = ({
  consumerUuid,
  primaryInsuranceId,
  secondaryInsuranceId,
  testId,
  patientFirstName,
  patientLastName,
}) => {
  const {
    data: patientDocuments,
    isLoading: isPatientDocumentsLoading,
    isError: isPatientDocumentsError,
    refetch: refetchPatientDocuments,
  } = usePatientDocuments(
    consumerUuid,
    primaryInsuranceId,
    secondaryInsuranceId,
    patientFirstName,
    patientLastName
  );

  const isError =
    isPatientDocumentsError ||
    (patientDocuments &&
      isSmthWentWrong([
        ...patientDocuments.driverLicense,
        ...patientDocuments.insurancePrimary,
      ]));

  const handleRefetchDocuments = useCallback(
    () => refetchPatientDocuments(),
    [refetchPatientDocuments]
  );

  return (
    <TabWrapper data-testid={testId}>
      <LoadingBar loading={isPatientDocumentsLoading} />
      {isError ? (
        <SmthWentWrongWrapper>
          <SmthWentWrongPlaceholder
            labelText="Something went wrong during request processing. Please retry."
            onClick={handleRefetchDocuments}
          />
        </SmthWentWrongWrapper>
      ) : null}
      {!isPatientDocumentsLoading && !isError && patientDocuments ? (
        <PatientDocuments
          primaryInsuranceId={primaryInsuranceId}
          secondaryInsuranceId={secondaryInsuranceId}
          consumerUuid={consumerUuid}
          patientFirstName={patientFirstName}
          patientLastName={patientLastName}
          horizontal
        />
      ) : null}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  position: relative;
  margin-top: 16px;
`;

const SmthWentWrongWrapper = styled.div`
  padding-top: 100px;
`;
