import React, {useRef} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import PenIcon from '../../../../assets/icons/edit.svg';
import PrintIcon from '../../../../assets/icons/print.svg';
import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button, DeleteButton} from '../../../shared/components/button';
import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {Subheader} from '../../../shared/components/subheader';
import {colors} from '../../../shared/styles/theme';
import {
  isUserRelyingParty,
  isUserRelyingPartyAdminOrOwner,
} from '../../../shared/utils/user';
import {QRCodeDisplay} from '../../healthPolicies/components/qRCode';
import {POLICY_TYPES} from '../../healthPolicies/constants/defaultPolicy';
import {usePrinter} from '../../healthPolicies/hooks/usePrinter';
import {LocationType} from '../constants/locationsTableConfig';

export const LocationDetail = ({location, onDelete, onEdit}) => {
  const currentUser = useRecoilValue(currentUserState);
  const {roles} = currentUser;
  const componentRef = useRef(null);
  const printer = usePrinter(location, componentRef);
  const {locationType} = useParams();

  return (
    <>
      <Subheader title={`${location.relying_party_name} ${location.name}`}>
        {isUserRelyingParty(roles) && locationType === LocationType.VIRTUAL ? (
          <>
            <Button
              onClick={printer}
              position="section"
              testID="printButton"
              startIcon={<img src={PrintIcon} alt="printIcon" />}
            >
              Print decal
            </Button>
            {isUserRelyingPartyAdminOrOwner(roles) && (
              <Button
                position="section"
                startIcon={<img src={PenIcon} alt="editIcon" />}
                testID="editButton"
                onClick={onEdit}
              >
                Edit
              </Button>
            )}
            {isUserRelyingPartyAdminOrOwner(roles) && (
              <DeleteButton onClick={onDelete} />
            )}
          </>
        ) : null}
      </Subheader>
      <ContentContainer>
        <Subtitle>Location information</Subtitle>
        <LocationContainer>
          <DataDisplayField
            label="Location name"
            value={`${location.relying_party_name} ${location.name}`}
            size="large"
          />
          <DataDisplayField
            label="Location EHR"
            value={`${
              location.location_ehr === null
                ? 'None configured'
                : location.location_ehr
            }`}
          />
        </LocationContainer>

        {locationType === LocationType.PHYSICAL ? (
          <>
            <Subtitle>Location address</Subtitle>
            <LocationContainer>
              <DataDisplayField
                label="Address Line 1"
                value={location.address_line_1}
                size="large"
              />
              <DataDisplayField
                label="Address Line 2"
                value={location.address_line_2}
                size="large"
              />
            </LocationContainer>
            <LocationContainer>
              <DataDisplayField
                label="City"
                value={location.city}
                size="small"
              />
              <DataDisplayField
                label="State"
                value={location.state}
                size="small"
              />
              <DataDisplayField
                label="Zip Code"
                value={location.postal_code}
                size="small"
              />
            </LocationContainer>
          </>
        ) : null}

        {location.policy ? (
          <>
            <Subtitle>Policy Information</Subtitle>
            <PolicyContainer>
              <DataDisplayField
                label="Policy type"
                value={POLICY_TYPES[location.policy.policy_type_id]}
                title={POLICY_TYPES[location.policy.policy_type_id]}
              />
              <DataDisplayField
                label="Policy assignment"
                value={location.policy.name}
              />
            </PolicyContainer>
          </>
        ) : null}

        {locationType === LocationType.VIRTUAL ? (
          <>
            <Subtitle>QR code</Subtitle>
            <Description>
              {`Your QR code can be displayed at the location by printing
          the supplied decal.`}
            </Description>

            <div
              style={{
                display: 'inline-block',
                width: '20%',
              }}
            >
              <QRCode
                location={location}
                componentRef={componentRef}
                testId="locationQRCode"
              />
            </div>
          </>
        ) : null}
      </ContentContainer>
    </>
  );
};

LocationDetail.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.any),
};

const ContentContainer = styled.div`
  padding: 24px 40px;
`;

const Subtitle = styled.h6`
  padding-bottom: 4px;
  margin: 0;
  line-height: 24px;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

const PolicyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
  margin-top: 16px;
`;

const Description = styled.p`
  font-style: italic;
  color: ${colors.grey400};
  margin: 0 0 21px 0;
  white-space: pre-line;
`;

const QRCode = styled(QRCodeDisplay).attrs(props => ({
  width: props.width || null,
  height: props.height || null,
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  border: 1px solid ${colors.grey400};
`;
