import React, {useCallback} from 'react';
import {Box, FormControlLabel, RadioGroup, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useFormikContext} from 'formik';

import {InputRadioButton} from '../../../shared/components/form';
import {
  LocationFields,
  ManuallyTypedAddressForm,
} from '../../../shared/components/manuallyTypedAddressForm';
import {
  PrefilledByZipCodeAddressForm,
  SearchZipCodeFields,
} from '../../../shared/components/prefilledByZipCodeAddressForm';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';
import {LocationAddress} from '../../../shared/hooks/useLocationAddresses';

const useStyles = makeStyles((theme: Theme) => ({
  radioGroup: {
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

export const LocationAddressForm: React.FC<{
  npi: string;
  providerType: PROVIDER_TYPES;
  locationAddresses: {
    data: LocationAddress[] | null;
    isLoading: boolean;
    hasError: boolean;
    lastSearchedZipCode: string;
    onClearLocationAddresses: () => void;
    fetchLocationAddresses: (
      providerType: PROVIDER_TYPES,
      npi: string,
      zipCode: string
    ) => Promise<LocationAddress[]>;
  };
}> = ({locationAddresses, npi, providerType}) => {
  const classes = useStyles();

  const formik = useFormikContext<
    LocationFields &
      SearchZipCodeFields & {
        havePhysicalAddress: boolean;
        primaryLocationManually: boolean;
      }
  >();

  const toggleManuallyPrimaryLocation = useCallback(async () => {
    const updatedValues = {
      havePhysicalAddress: !formik.values.havePhysicalAddress,
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zipCode: '',
      zipCodeAsync: '',
      primaryLocationAddress: '',
    };

    formik.setFormikState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        ...updatedValues,
      },
      errors: {
        ...prevState.errors,
        addressOne: '',
        addressTwo: '',
        city: '',
        state: '',
        zipCode: '',
        zipCodeAsync: '',
        primaryLocationAddress: '',
      },
      touched: {
        ...prevState.touched,
        addressOne: false,
        addressTwo: false,
        city: false,
        state: false,
        zipCode: false,
        zipCodeAsync: false,
        primaryLocationAddress: false,
      },
    }));

    await formik.validateForm({
      ...formik.values,
      ...updatedValues,
    });

    locationAddresses.onClearLocationAddresses();
  }, [formik, locationAddresses]);

  return (
    <>
      <Box component="h6" marginBottom={1} marginTop={8}>
        Location Address
      </Box>
      <p>Does this location have a physical address?</p>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        className={classes.radioGroup}
        onChange={toggleManuallyPrimaryLocation}
      >
        <FormControlLabel
          control={<InputRadioButton />}
          checked={formik.values.havePhysicalAddress}
          label="Yes"
        />
        <FormControlLabel
          control={<InputRadioButton />}
          checked={!formik.values.havePhysicalAddress}
          label="No"
        />
      </RadioGroup>

      {formik.values.havePhysicalAddress ? (
        <LocationAddressFormForLocationWithPhysicalAddress
          npi={npi}
          providerType={providerType}
          locationAddresses={locationAddresses}
        />
      ) : null}
    </>
  );
};

const LocationAddressFormForLocationWithPhysicalAddress: React.FC<{
  npi?: string | null;
  providerType: PROVIDER_TYPES;
  locationAddresses: {
    data: LocationAddress[] | null;
    isLoading: boolean;
    hasError: boolean;
    lastSearchedZipCode: string;
    onClearLocationAddresses: () => void;
    fetchLocationAddresses: (
      providerType: PROVIDER_TYPES,
      npi: string,
      zipCode: string
    ) => Promise<LocationAddress[]>;
  };
}> = ({npi, locationAddresses, providerType}) => {
  const formik = useFormikContext<
    LocationFields &
      SearchZipCodeFields & {
        havePhysicalAddress: boolean;
        primaryLocationManually: boolean;
      }
  >();

  const {
    data: addresses,
    hasError: addressesHaveError,
    isLoading: addressesIsLoading,
    fetchLocationAddresses,
    onClearLocationAddresses,
    lastSearchedZipCode,
  } = locationAddresses;

  const onSubmitZipCode = useCallback(async (): Promise<LocationAddress[]> => {
    if (npi) {
      const {
        values: {zipCodeAsync},
      } = formik;

      const addresses = await fetchLocationAddresses(
        providerType,
        npi,
        zipCodeAsync
      );

      return addresses;
    }

    return [];
  }, [fetchLocationAddresses, formik, npi, providerType]);

  return formik.values.primaryLocationManually ? (
    <ManuallyTypedAddressForm />
  ) : (
    <PrefilledByZipCodeAddressForm
      disabled={!npi}
      enterLocationManuallyLinkMessage="Enter Location manually"
      addressNotFoundMessage="Sorry, but we could not find an address associated with this Zip code. Please try another Zip code or"
      addresses={addresses}
      addressesIsLoading={addressesIsLoading}
      addressesHaveError={addressesHaveError}
      onClearLocationAddresses={onClearLocationAddresses}
      onSubmitZipCode={onSubmitZipCode}
      lastSearchedZipCode={lastSearchedZipCode}
    />
  );
};
