type Props = {
  first_name: string;
  last_name: string;
};

export const formatNames = ({first_name, last_name}: Props): string =>
  `${first_name} ${last_name}`;

export const getInitials = (fullName: string | undefined): string => {
  if (!fullName) return 'YOU';
  const parts = fullName.trim().split(/\s+/);
  return (
    parts[0][0] + (parts[1] ? parts[1][0] : parts[0][1] || '')
  ).toUpperCase();
};
