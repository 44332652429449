import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {isMyPatientAddingState} from '../atoms/isMyPatientAddingState';
import {isMyPatientRemovingState} from '../atoms/isMyPatientRemovingState';
import {fetchAddMyPatient, fetchRemoveMyPatient} from '../utils/fetchers';

export const useManageMyPatient = () => {
  const showMessage = useCustomizedSnackbar();
  const setIsMyPatientAddingState = useSetRecoilState(isMyPatientAddingState);
  const setIsMyPatientRemovingState = useSetRecoilState(
    isMyPatientRemovingState
  );
  const {relyingParty} = useRecoilValue(currentUserState);

  const addMyPatient = async uuid => {
    try {
      setIsMyPatientAddingState(true);
      await fetchAddMyPatient(relyingParty.id, uuid);
    } catch (error) {
      showMessage(
        error?.message ||
          'Something went wrong. Connection is not added to My Connections.',
        'error'
      );
    } finally {
      setIsMyPatientAddingState(false);
    }
  };

  const removeMyPatient = async uuid => {
    try {
      setIsMyPatientRemovingState(true);
      await fetchRemoveMyPatient(relyingParty.id, uuid);
    } catch (error) {
      showMessage(
        error?.message ||
          'Something went wrong. Connection is not removed from My Connections.',
        'error'
      );
    } finally {
      setIsMyPatientRemovingState(false);
    }
  };

  return {
    removeMyPatient,
    addMyPatient,
  };
};
