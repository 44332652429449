import React, {FC, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import {useSetRecoilState} from 'recoil';

import {ViewRecordsIcon} from '../../../shared/icons/viewRecordsIcon';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {SelectedUserState} from '../constants/types';
import {useConfirmViewRecords} from '../hooks/useConfirmViewRecords';

type Props = {
  patientName: string | null;
  patientUuid: string | null;
  isPatientConnected: boolean;
  isSharingMedicalRecords: boolean;
  onClick: () => void;
};

export const ViewMedicalRecordsBtn: FC<Props> = ({
  patientUuid,
  patientName,
  onClick,
  isPatientConnected,
  isSharingMedicalRecords,
}) => {
  const setSelectedPatient =
    useSetRecoilState<SelectedUserState>(selectedPatientState);

  const confirmOpenRecords = useConfirmViewRecords(
    isPatientConnected,
    isSharingMedicalRecords
  );

  const handleClick = useCallback(() => {
    onClick();
    confirmOpenRecords(patientUuid, patientName);
    setSelectedPatient({patientName, patientUuid});
  }, [
    confirmOpenRecords,
    onClick,
    patientName,
    patientUuid,
    setSelectedPatient,
  ]);

  return (
    <Button
      color="primary"
      size="small"
      variant="outlined"
      startIcon={<ViewRecordsIcon />}
      onClick={handleClick}
      data-testid={`${patientUuid}-view-medical-records`}
    >
      View Records
    </Button>
  );
};
