import {PatientInvitationStatus} from '../../../shared/interfaces/patient';
import {INVITED_PATIENTS_TABLE_STATUSES} from '../constants/invitedPatientTableLabelAndColor';

export const getCurrentInvitationStatus = (invitation: {
  opt_out?: boolean;
  status?: PatientInvitationStatus;
}): {
  label: string;
  statusText: string;
  color: string;
} | null => {
  if (invitation?.opt_out) {
    return INVITED_PATIENTS_TABLE_STATUSES.OPTED_OUT;
  }

  return invitation?.status
    ? INVITED_PATIENTS_TABLE_STATUSES[invitation.status]
    : null;
};
