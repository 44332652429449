import {codeSystems} from '../constants/codeSystemLabels';
import {ElectronicRecordCode} from '../types/types';

import {getFormattedCodeSystem} from './codeLabels';

export const getCodeDescriptionBySystem = (
  codes: ElectronicRecordCode[] | null = [],
  codeSystem = codeSystems.imoHealth
): string => {
  const systemCode = codes?.find(
    code => getFormattedCodeSystem(code.system ?? '') === codeSystem
  );
  const otherCode = codes?.[0]?.display ?? 'Name Unavailable';
  return systemCode?.display ?? otherCode;
};
