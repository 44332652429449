import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useSetRecoilState} from 'recoil';

import {MRNDetailsType} from '../../../shared/constants/formattedMRNsDetailsType';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {dateFormatter} from '../../../shared/utils/formatDate';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {selectedPatientState} from '../atoms/selectedPatientState';
import {PATIENT_DETAILS_LABELS} from '../constants/PatientDetailsLabels';
import {
  PatientsProfileLimitedDetailsFormattedType,
  PatientsProfileLimitedDetailsResponseType,
} from '../constants/types';
import {fetchPatientLimitedDetails} from '../utils/fetchers';

const formatPatientLimitedDetails = (
  patientLimitedDetails: PatientsProfileLimitedDetailsResponseType
): PatientsProfileLimitedDetailsFormattedType => ({
  publicDetails: {
    consumerUuid: patientLimitedDetails.consumer_uuid,
    firstName: patientLimitedDetails.first_name,
    lastName: patientLimitedDetails.last_name,
    middleName: patientLimitedDetails.middle_name,
  },
  personalDetails: [
    {
      title: PATIENT_DETAILS_LABELS.DATE_OF_BIRTH,
      value: dateFormatter(patientLimitedDetails.date_of_birth) || 'N/A',
    },
    {
      title: PATIENT_DETAILS_LABELS.GENDER,
      value: patientLimitedDetails.gender || 'N/A',
    },
  ],
  mrnDetails: patientLimitedDetails.mrn_ehr_responses.map(
    (mrn_ehr_response: MRNDetailsType) => ({
      id: mrn_ehr_response.id,
      ehrId: mrn_ehr_response.ehr_id,
      ehrName: mrn_ehr_response.ehr_name,
      mrn: mrn_ehr_response.mrn,
      ehrLink: mrn_ehr_response.ehr_link,
    })
  ),
  connection: patientLimitedDetails.connection,
});

const getPatientLimitedDetails = async (
  consumerUuid: string,
  relyingPartyId: number
): Promise<PatientsProfileLimitedDetailsFormattedType | null> => {
  const patientLimitedDetailsData = await fetchPatientLimitedDetails(
    consumerUuid,
    relyingPartyId
  );

  return patientLimitedDetailsData
    ? formatPatientLimitedDetails(patientLimitedDetailsData)
    : null;
};

export const usePatientLimitedDetails = (
  consumerUuid: string | null,
  relyingPartyId: number | null
): UseQueryResult<PatientsProfileLimitedDetailsFormattedType | null> => {
  const setSelectedPatient = useSetRecoilState(selectedPatientState);
  const handleError = useErrorHandling('Fetching patient details went wrong');

  return useQuery({
    queryKey: ['patientLimitedDetails', consumerUuid, relyingPartyId],
    queryFn: async () => {
      if (consumerUuid && relyingPartyId) {
        const patientLimitedDetailsData = await getPatientLimitedDetails(
          consumerUuid,
          relyingPartyId
        );

        setSelectedPatient({
          patientName: `${patientLimitedDetailsData?.publicDetails.firstName} ${patientLimitedDetailsData?.publicDetails.lastName}`,
          patientUuid: consumerUuid,
        });

        return patientLimitedDetailsData;
      }

      return null;
    },
    onError: wrapAsyncFunction(async error => {
      await handleError(error);
    }),
    refetchOnMount: true,
  });
};
