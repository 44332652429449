import React, {FC, useState} from 'react';
import styled from 'styled-components';

import {ConfirmModal} from '../../../shared/components/confirmModal';
import {PatientInvitationStatus} from '../../../shared/interfaces/patient';

type Props = {
  invitationStatus?: PatientInvitationStatus;
};

export const PatientInfoMisMatchModalWindow: FC<Props> = ({
  invitationStatus,
}) => {
  const [
    isOpenPatientInfoMisMatchModalWindow,
    setIsOpenPatientInfoMisMatchModalWindow,
  ] = useState(invitationStatus ? invitationStatus === 'MISMATCH' : false);

  const handleOnContinueClick = () => {
    setIsOpenPatientInfoMisMatchModalWindow(false);
  };

  return (
    <ConfirmModal
      title="Patient information does not match"
      isOpen={isOpenPatientInfoMisMatchModalWindow}
      variant="h6"
      onConfirm={handleOnContinueClick}
      confirmBtnText="Continue"
      confirmBtnVariant="primary"
      isShowCancelBtn={false}
    >
      <ContentWrapper>
        The patient name must match the name on their driver&apos;s license.
        Additionally, check the mobile phone number for accuracy. Please edit
        and resend the invitation.
      </ContentWrapper>
    </ConfirmModal>
  );
};

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
  line-height: 24px;
`;
