import React, {useCallback, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {mutate} from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {ConfirmModal} from '../../../shared/components/confirmModal';
import CustomizedInput from '../../../shared/components/customizedInput';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {colors} from '../../../shared/styles/theme';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {modalWordings} from '../constants/modalWordings';

const useStyles = makeStyles(() => ({
  modalInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 12px',
      height: 24,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: 36,
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
    '& .MuiInputLabel-root': {color: colors.grey500},
    '& .MuiInputLabel-root.Mui-focused': {color: colors.primary},
    '& .MuiInputLabel-root.Mui-error': {color: colors.red},
  },
}));

export const DeleteModal = ({open, type, data, disabled, closeModal}) => {
  const history = useHistory();
  const classes = useStyles();
  const {path} = useRouteMatch();

  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();
  const {relyingParty} = useRecoilValue(currentUserState);

  const [input, setInput] = useState('');
  const [displayError, setDisplayError] = useState(false);

  const handleDelete = useCallback(async () => {
    if (input === data.name) {
      try {
        await fetcher(modalWordings[type].api(data.id), 'DELETE');
        closeModal();
        showMessage(modalWordings[type].successMessage(data.name), 'success');

        const isFromPolicyPage = path.includes('health-policies');
        await mutate(
          isFromPolicyPage
            ? urls.relyingPartyPoliciesByPolicyType(
                relyingParty.id,
                data.policy_type_id
              )
            : urls.relyingPartyLocations(relyingParty.id)
        );

        const isOnSubRoute =
          path.endsWith('/health-policies') || path.endsWith('locations');
        if (!isOnSubRoute) {
          history.replace(isFromPolicyPage ? '/health-policies' : '/locations');
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      setDisplayError(true);
    }
  }, [
    closeModal,
    data.id,
    data.name,
    data.policy_type_id,
    handleError,
    history,
    input,
    path,
    relyingParty.id,
    showMessage,
    type,
  ]);

  const onChangeModal = useCallback(event => {
    setInput(event.target.value);
    setDisplayError(false);
  }, []);

  return (
    <ConfirmModal
      isOpen={open}
      onClose={closeModal}
      onConfirm={handleDelete}
      buttonType="delete"
      confirmBtnText="Delete"
      confirmBtnVariant="dangerous"
      title={modalWordings[type].title}
      style={{height: 265}}
      disabled={disabled}
    >
      {disabled ? (
        <ModalDescription>{modalWordings[type].disabledText}</ModalDescription>
      ) : (
        <>
          <ModalDescription>
            {modalWordings[type].instruction}
            <strong>{data?.name}</strong>.
          </ModalDescription>
          <ModalInput
            value={input}
            variant="outlined"
            error={displayError}
            className={classes.modalInput}
            onChange={onChangeModal}
            label={modalWordings[type].label}
            testID="deleteConfirmField"
            errorMessage={modalWordings[type].errorMessage}
          />
        </>
      )}
    </ConfirmModal>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
};

DeleteModal.defaultProps = {
  disabled: false,
  data: {},
};

const ModalInput = styled(CustomizedInput)`
  width: 100%;
  margin-top: 16px;
`;

const ModalDescription = styled.p`
  font-size: 14px;
  margin: 17px 0 0;
`;
