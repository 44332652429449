/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';

import {
  ListItemKey,
  ListItemValue,
  ListNoDataRow,
} from '../../../../shared/components/list';
import {colors} from '../../../../shared/styles/theme';

import {codeSystemLabels} from './constants/codeSystemLabels';
import {ElectronicRecordCode} from './types/types';
import {getFormattedCodeSystem} from './utils/codeLabels';

const useStyles = makeStyles(() => ({
  key: {
    color: colors.black,
  },
}));

type GroupedCodes = {[key: string]: ElectronicRecordCode[]};

type Props = {
  codes: ElectronicRecordCode[] | null;
  noCodesMessage?: string | null;
  titleTestId?: string | null;
  keyTestId?: string | null;
  valueTestId?: string | null;
  noCodeTestId?: string | null;
};

const unknownCodeSystem = 'unknown';
const codeLabels: {[key: string]: string} = {
  ...codeSystemLabels,
  [unknownCodeSystem]: 'System Unknown',
};

const makeUnknownCodesLast = (keys: string[]): string[] => {
  const copiedKeys = [...keys];
  const unknownCodesIndex = copiedKeys.findIndex(item => item === 'unknown');

  const item = copiedKeys.splice(unknownCodesIndex, 1)[0];
  copiedKeys.push(item);

  return copiedKeys;
};

export const ElectronicRecordCodesList: React.FC<Props> = ({
  codes,
  noCodesMessage = 'Not Available',
  titleTestId,
  keyTestId = 'code',
  valueTestId,
  noCodeTestId = 'no-data',
}) => {
  const classes = useStyles();
  const groupedCodes: GroupedCodes = useMemo(
    () =>
      codes
        ? codes?.reduce<GroupedCodes>((acc, code) => {
            if (!code?.code && !code?.display) {
              return acc;
            }

            const codeSystem =
              code?.system && codeLabels[getFormattedCodeSystem(code.system)]
                ? getFormattedCodeSystem(code.system)
                : unknownCodeSystem;

            if (acc[codeSystem]) {
              acc[codeSystem] = [...acc[codeSystem], code];
            } else {
              acc[codeSystem] = [code];
            }

            return acc;
          }, {})
        : {},
    [codes]
  );

  if (Object.keys(groupedCodes).length === 0) {
    return (
      <ListNoDataRow testId={noCodeTestId} noDataMessage={noCodesMessage} />
    );
  }

  return (
    <>
      {makeUnknownCodesLast(Object.keys(groupedCodes)).map(
        (codeSystem, index) => (
          <React.Fragment key={codeSystem}>
            {index !== 0 ? (
              <>
                <dt />
                <dd />
              </>
            ) : null}

            <ListItemKey testId={titleTestId}>
              {codeLabels[codeSystem]}
            </ListItemKey>
            <ListItemValue>&nbsp;</ListItemValue>
            {groupedCodes[codeSystem].map(({code, display}) => (
              <React.Fragment key={code}>
                <ListItemKey testId={keyTestId} classNames={classes.key}>
                  {code ?? 'Code Unavailable'}
                </ListItemKey>
                <ListItemValue testId={valueTestId}>{display}</ListItemValue>
              </React.Fragment>
            ))}
          </React.Fragment>
        )
      )}
    </>
  );
};
