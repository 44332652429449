import * as yup from 'yup';

export const addUserValidationSchema = yup.object({
  firstName: yup
    .string("Enter owner's first name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('First name is required'),
  lastName: yup
    .string("Enter owner's last name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('Last name is required'),
  phoneNumber: yup
    .string("Enter owner's phone number")
    .trim()
    .matches(
      /^[1-9]\d{9}$/,
      'Phone number must be 10 digits and cannot start with 0'
    )
    .required('Phone number is required'),
  role: yup.array().min(1, 'Role is required'),
  email: yup
    .string("Enter owner's email")
    .trim()
    .max(200, 'Must be 200 characters or less')
    .email('Invalid email')
    .required('Email is required'),
});

export const editUserValidationSchema = yup.object({
  firstName: yup
    .string("Enter owner's first name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('First name is required'),
  lastName: yup
    .string("Enter owner's last name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('Last name is required'),
  role: yup.array().min(1, 'Role is required'),
});
