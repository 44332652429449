import React from 'react';
import styled from 'styled-components';

import {Button} from '../../../../shared/components/button';
import {Description} from '../../../../shared/components/description';
import {InfoIcon} from '../../../../shared/icons/infoIcon';
import {colors} from '../../../../shared/styles/theme';

import {ViewFileWrapper} from './viewerWrapper';

type Props = {
  title?: string;
  description?: string;
  onRetry?: () => void;
};

export const ViewerScreenError: React.FC<Props> = ({
  title,
  description,
  onRetry,
}) => (
  <ViewFileWrapper>
    <InfoIconWrapper>
      <InfoIcon style={{width: '30px', height: '30px'}} />
    </InfoIconWrapper>
    <Description isError title={title} description={description} />
    {onRetry ? (
      <Button
        onClick={e => {
          e.stopPropagation();
          onRetry();
        }}
        position="section"
        size="small"
        variant="outlined"
      >
        Retry
      </Button>
    ) : null}
  </ViewFileWrapper>
);

export const InfoIconWrapper = styled.div`
  background-color: ${colors.grey1200};
  color: ${colors.primary};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
