import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

import {Button} from '../../../shared/components/button';
import {CustomizedButton} from '../../../shared/components/customizedButton';
import {CloseIcon} from '../../../shared/icons/closeIcon';
import {DownloadIcon} from '../../../shared/icons/downloadIcon';
import {colors} from '../../../shared/styles/theme';
import {usePreviewForSavedFile} from '../hooks/usePreviewForSavedFile';

import {FileViewer} from './fileViewer/fileViewer';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    boxSizing: 'border-box',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 700,
    margin: 0,
    lineHeight: '24px',
  },
  modalCloseBtn: {
    minWidth: 'auto',
    padding: '7px',

    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  modalCloseBtnIcon: {
    margin: 0,
  },
  modalFileName: {
    margin: '0 0 8px 0',
    fontWeight: 600,
  },
  modalBodyWrapper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '100%',
    height: '100%',

    '&:focus': {
      outline: 'none',
    },
  },
}));

type Props = {
  gcpFileName: string;
  fileContentType: string;
  fileName: string;
  onDownloadFile?: () => void;
  onClose?: VoidFunction;
};

export const PreviewFileModalWindow: React.FC<Props> = ({
  gcpFileName,
  fileContentType,
  fileName,
  onDownloadFile,
  onClose,
}) => {
  const classes = useStyles();
  const {
    isFetching: filePreviewIsLoading,
    isError: filePreviewHasError,
    data: filePreviewData,
  } = usePreviewForSavedFile(gcpFileName, fileContentType);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalWindowWrapper className={classes.modalBodyWrapper}>
          <Header>
            <h2 className={classes.modalTitle}>File Preview</h2>
            <CustomizedButton
              className={classes.modalCloseBtn}
              color="primary"
              variant="outlined"
              testID="add-mrn-btn"
              onClick={onClose}
              startIcon={<CloseIcon fontSize="inherit" viewBox="-3 -3 20 20" />}
            />
          </Header>
          <Body>
            <>
              <p data-testid="file-name" className={classes.modalFileName}>
                {fileName}
              </p>
              <FileViewer
                filePreviewIsLoading={filePreviewIsLoading}
                filePreviewHasError={filePreviewHasError}
                filePreviewType={fileContentType}
                filePreviewData={filePreviewData ?? ''}
              />
            </>
          </Body>
          <Footer>
            <Button
              testID="download-button-preview-modal-window"
              variant="outlined"
              aria-haspopup="true"
              disabled={!onDownloadFile}
              onClick={onDownloadFile}
              startIcon={<DownloadIcon />}
            >
              Download
            </Button>
            <Button onClick={onClose}>Close</Button>
          </Footer>
        </ModalWindowWrapper>
      </Fade>
    </Modal>
  );
};

const ModalWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 24px 35px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Body = styled.div`
  border-top: 1px solid ${colors.grey800};
  border-bottom: 1px solid ${colors.grey800};
  padding: 16px 35px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  border-top: 1px solid ${colors.grey800};
  padding: 24px 35px;
  display: flex;
  justify-content: space-between;
`;
