import React from 'react';
import {isNil} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

import OverflowTip from './overFlowTip';

export const Subheader = ({title, subtitle, children}) => {
  const hasSubtitle = !isNil(subtitle);
  return (
    <Container>
      <Wrapper style={{display: 'flex', height: '65px'}}>
        <Title value={title} tipValue={title} moveup={hasSubtitle ? 1 : 0} />
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ChildContainer>{children}</ChildContainer>
      </Wrapper>
    </Container>
  );
};

Subheader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

const Container = styled.div`
  position: relative;
  padding-left: 40px;
  padding-right: 60px;
  border-bottom: 1px solid ${colors.grey50};
`;

const Wrapper = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(OverflowTip)`
  color: ${colors.black};
  font-weight: 700;
  font-size: 24px;
  transform: ${({moveup}) => (moveup ? 'translateY(-9px)' : 0)};
`;

const Subtitle = styled.p`
  position: absolute;
  margin: 0;
  top: 40px;
  color: ${colors.grey801};
  font-size: 12px;
  font-style: italic;
`;

const ChildContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
`;
