import React, {ChangeEvent, FC, useCallback} from 'react';
import {Pagination as MUIPagination, PaginationProps} from '@material-ui/lab';

type Props = {
  setSelectedPage: (val: number) => void;
};

export const Pagination: FC<PaginationProps & Props> = ({
  setSelectedPage,
  ...props
}) => {
  const handlePagination = useCallback(
    (event: ChangeEvent<unknown>, value: number) => {
      setSelectedPage(value - 1);
    },
    [setSelectedPage]
  );
  return (
    <MUIPagination
      {...props}
      onChange={handlePagination}
      data-testid="pagination"
    />
  );
};
