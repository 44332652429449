import React, {FC} from 'react';

import {Message as MessageType} from '../../../../shared/interfaces/aIChat';

import ChatMessage from './chatMessage';

interface MessageProps {
  message: MessageType;
}

const Message: FC<MessageProps> = ({message}) =>
  message.isUser ? (
    <ChatMessage headerText="You" message={message} />
  ) : (
    <ChatMessage headerText="Care Guide" message={message} />
  );

export default Message;
