import {snakeCase} from 'lodash';

export const convertRequestBody = object => {
  const requestBody = {};
  Object.keys(object).forEach(key => {
    if (key !== 'role') {
      requestBody[snakeCase(key)] = object[key];
    } else {
      requestBody.roles = object[key].join(',');
    }
    return null;
  });
  return requestBody;
};
