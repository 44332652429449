import React, {useState} from 'react';
import {Popper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../../shared/styles/theme';
import {getColor} from '../utils/getColor';

const useStyles = makeStyles(() => ({
  paper: {
    width: '194px',
    backgroundColor: 'white',
    paddingLeft: '24px',
    marginLeft: '20px',
    borderRadius: '4px',
    border: `1px solid ${colors.grey50}`,
    paddingTop: '24px',
    paddingBottom: '24px',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.160784)',
  },
  arrow: {
    left: 0,
    marginLeft: '-1em',
    position: 'absolute',
    fontSize: 6,
    borderWidth: '0.5px',
    '&::before': {
      borderWidth: '0.7em 1em 0.7em 0',
      borderColor: 'transparent white transparent transparent',
      content: '""',
      display: 'block',
      borderStyle: 'solid',
    },
  },
}));

export const PasswordHelper = ({
  prompt,
  requirements,
  anchorEl,
  placement = 'right',
  errors = [],
  touched,
  open = false,
  value,
}) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      anchorEl={anchorEl}
      placement={placement}
      open={open}
      className={classes.paper}
      modifiers={{
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      <Prompt>{prompt}</Prompt>
      {requirements?.map(requirement => {
        const hasError = errors.includes(requirement.key);
        return (
          <Restrictions
            color={getColor(hasError, value, touched)}
            key={requirement.key}
          >{`${requirement.description}\n`}</Restrictions>
        );
      })}
    </Popper>
  );
};

PasswordHelper.propTypes = {
  prompt: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.object),
  anchorEl: PropTypes.objectOf(PropTypes.any),
  placement: PropTypes.string,
  value: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  touched: PropTypes.bool,
  open: PropTypes.bool,
};

const Prompt = styled.p`
  margin-block-start: 0;
  line-height: 16px;
  font-size: 12px;
  color: ${colors.grey801};
  font-style: italic;
`;

const Restrictions = styled.p`
  margin-block-end: 0;
  line-height: 12px;
  font-size: 12px;
  color: ${props => props?.color};
  font-weight: 600;
  &:before {
    content: ' ';
    display: inline-block;
    margin-right: 14px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${props => props.color};
  }
`;
