import React, {useCallback, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import PropTypes from 'prop-types';
import {useRecoilState, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {Button, CancelButton} from '../../../shared/components/button';
import SearchNPI from '../../../shared/components/searchNPI';
import {NPI_STATUSES, NPI_TYPES} from '../../../shared/constants/npi';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';
import {
  fetchNpiData,
  fetchNpiDataAndValidate,
} from '../../../shared/utils/fetchers';
import {checkAddFormButtonDisability} from '../../../shared/utils/formikUtils';
import {STEPS, STEPS_TITLE} from '../constants/stepper';
import {relyingPartyFormValidationSchema} from '../constants/validationSchema';
import useClearOutInvitation from '../hooks/useClearOutInvitation';
import {relyingPartyInitialState, relyingPartyState, stepState} from '../store';
import {resetOrganizationForm, resetOwnerForm} from '../utils/formik';
import {getOrganizationNpiState, getOwnerNpiState} from '../utils/npiStates';

import {OrganizationInformationForm} from './setRelyingParty/organizationInformationForm';
import {OwnerForm} from './setRelyingParty/ownerForm';
import {PrimaryLocationForm} from './setRelyingParty/primaryLocationForm';
import {ProviderTypeForm} from './setRelyingParty/providerTypeForm';
import ConfirmModal from './confirmModal';
import InvitationLayout from './invitationLayout';

export const StepSetRelyingParty = ({
  ownerNpi,
  organizationNpi,
  locationAddresses,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const {clearOutInvitation} = useClearOutInvitation();
  const setCurStep = useSetRecoilState(stepState);
  const [relyingParty, setRelyingParty] = useRecoilState(relyingPartyState);
  const {
    isLoading: ownerNpiDataIsLoading,
    onSearchNPI: onSearchOwnerNpi,
    onClearDataNPI: onClearOwnerNpi,
    npiStatus: ownerNpiStatus,
    setNpiEditedStatus: setOwnerNpiEditedStatus,
  } = ownerNpi;

  const {
    isLoading: organizationNpiDataIsLoading,
    onSearchNPI: onSearchOrganizationNpi,
    onClearDataNPI: onClearOrganizationNpi,
    npiStatus: organizationNpiStatus,
    setNpiEditedStatus: organizationNpiEditedStatus,
  } = organizationNpi;

  const {
    data: addresses,
    hasError: addressesHaveError,
    isLoading: addressesIsLoading,
    fetchLocationAddresses,
    onClearLocationAddresses,
    lastSearchedZipCode,
  } = locationAddresses;

  const clearOwnerDataNpi = formik => () => {
    onClearOwnerNpi();
    onClearLocationAddresses();

    resetOwnerForm(formik);
  };

  const clearOrganizationDataNpi = formik => () => {
    onClearOrganizationNpi();
    onClearLocationAddresses();

    resetOrganizationForm(formik);
  };

  const getOwnerDataNpi = formik => npi => {
    const npiFetcher =
      formik.values.providerType === PROVIDER_TYPES.INDIVIDUAL
        ? fetchNpiDataAndValidate(npi, NPI_TYPES.USER_RELYING_PARTY)
        : fetchNpiData(npi, NPI_TYPES.USER_RELYING_PARTY);

    onSearchOwnerNpi(npiFetcher, data => {
      formik.resetForm({
        values: {
          ...formik.values,
          ownerNpi: npi,
          firstName: data?.first_name || '',
          lastName: data?.last_name || '',
          email: '',
          phoneNumber: '',
        },
        errors: {
          ...formik.errors,
          ownerNpi: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        },
        touched: {
          ...formik.touched,
          ownerNpi: false,
          firstName: false,
          lastName: false,
          email: false,
          phoneNumber: false,
        },
      });
    });
  };

  const getOrganizationDataNpi = formik => npi => {
    const npiFetcher = fetchNpiDataAndValidate(
      npi,
      NPI_TYPES.ISSUER_RELYING_PARTY
    );

    onSearchOrganizationNpi(npiFetcher, data => {
      formik.resetForm({
        values: {
          ...formik.values,
          organizationNpi: npi,
          name: data?.name || '',
          domain: '',
        },
        errors: {
          ...formik.errors,
          organizationNpi: '',
          name: '',
          domain: '',
        },
        touched: {
          ...formik.touched,
          organizationNpi: false,
          name: false,
          domain: false,
        },
      });
    });
  };

  const ownerNpiState = useMemo(
    () => getOwnerNpiState(ownerNpiStatus),
    [ownerNpiStatus]
  );

  const organizationNpiState = useMemo(
    () => getOrganizationNpiState(organizationNpiStatus),
    [organizationNpiStatus]
  );

  const setProviderType = providerType => {
    onClearOwnerNpi();
    onClearOrganizationNpi();
    setRelyingParty({
      ...relyingPartyInitialState,
      providerType,
    });
  };

  const getPrefilledAddress = values => {
    const {
      address_1: addressOne,
      address_2: addressTwo,
      postal_code: zipCode,
      city,
      state,
    } = addresses[Number(values.primaryLocationAddress)];

    return {
      addressOne,
      addressTwo,
      city,
      state,
      zipCode,
    };
  };

  const onSubmit = values => {
    setRelyingParty({
      ...values,
      ...(values.primaryLocationManually ? {} : getPrefilledAddress(values)),
    });

    setCurStep(STEPS.SECOND);
  };

  const isAddFormButtonDisabled = formik =>
    checkAddFormButtonDisability(formik) ||
    ownerNpiState.status === NPI_STATUSES.ERROR ||
    organizationNpiState.status === NPI_STATUSES.ERROR;

  const isOwnerFormFieldsDisabled = formik =>
    ownerNpiState?.status === NPI_STATUSES.ERROR || !formik.values.ownerNpi;

  const isOrganizationFormFieldsDisabled = formik =>
    organizationNpiState?.status === NPI_STATUSES.ERROR ||
    !formik.values.organizationNpi;

  const isNpiCorrect = useCallback(
    formik =>
      (formik.values.providerType === PROVIDER_TYPES.INDIVIDUAL &&
        ownerNpiState.status === NPI_STATUSES.SUCCESS) ||
      (formik.values.providerType === PROVIDER_TYPES.ORGANIZATION &&
        organizationNpiState.status === NPI_STATUSES.SUCCESS),
    [organizationNpiState.status, ownerNpiState.status]
  );

  return (
    <InvitationLayout
      title={STEPS_TITLE.SET_NPI.TITLE}
      description={STEPS_TITLE.SET_NPI.DESCRIPTION}
    >
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={relyingPartyFormValidationSchema}
        initialValues={relyingParty}
      >
        {formik => (
          <>
            <ProviderTypeForm setProviderType={setProviderType} />
            <OwnerForm disabledFrom={isOwnerFormFieldsDisabled(formik)}>
              <SearchNPI
                label="Owner NPI"
                placeholder="Should contain 10 digits"
                npiState={ownerNpiState}
                isLoading={ownerNpiDataIsLoading}
                onSearch={getOwnerDataNpi(formik)}
                onClear={clearOwnerDataNpi(formik)}
                onChange={setOwnerNpiEditedStatus}
                defaultValue={formik.values.ownerNpi}
                testIdPrefix="owner"
              />
            </OwnerForm>
            {formik.values.providerType === PROVIDER_TYPES.ORGANIZATION ? (
              <OrganizationInformationForm
                disabledFrom={isOrganizationFormFieldsDisabled(formik)}
              >
                <SearchNPI
                  label="Organization NPI"
                  placeholder="Should contain 10 digits"
                  npiState={organizationNpiState}
                  isLoading={organizationNpiDataIsLoading}
                  onSearch={getOrganizationDataNpi(formik)}
                  onClear={clearOrganizationDataNpi(formik)}
                  onChange={organizationNpiEditedStatus}
                  defaultValue={formik.values.organizationNpi}
                  testIdPrefix="organization"
                />
              </OrganizationInformationForm>
            ) : null}
            <PrimaryLocationForm
              isNpiCorrect={isNpiCorrect(formik)}
              addresses={addresses}
              addressesIsLoading={addressesIsLoading}
              addressesHaveError={addressesHaveError}
              onClearLocationAddresses={onClearLocationAddresses}
              fetchLocationAddresses={fetchLocationAddresses}
              lastSearchedZipCode={lastSearchedZipCode}
            />
            <ButtonGroup>
              <CancelButton onClick={() => setOpen(true)}>Cancel</CancelButton>
              <Button
                data-testid="next-button"
                type="submit"
                onClick={formik.submitForm}
                disabled={isAddFormButtonDisabled(formik)}
              >
                Next
              </Button>
            </ButtonGroup>
          </>
        )}
      </Formik>
      <ConfirmModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          clearOutInvitation();
          setOpen(false);
          history.push('/relying-parties');
        }}
        description="Are you sure you want to return to the relying party list?"
      />
    </InvitationLayout>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 40px;
`;

StepSetRelyingParty.propTypes = {
  ownerNpi: PropTypes.shape({
    isLoading: PropTypes.bool,
    onSearchNPI: PropTypes.func,
    onClearDataNPI: PropTypes.func,
    npiStatus: PropTypes.string,
    setNpiEditedStatus: PropTypes.func,
  }),
  organizationNpi: PropTypes.shape({
    isLoading: PropTypes.bool,
    onSearchNPI: PropTypes.func,
    onClearDataNPI: PropTypes.func,
    npiStatus: PropTypes.string,
    setNpiEditedStatus: PropTypes.func,
  }),
  locationAddresses: PropTypes.shape({
    data: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          address_1: PropTypes.string,
          address_2: PropTypes.string,
          city: PropTypes.string,
          country: PropTypes.string,
          district: PropTypes.string,
          postal_code: PropTypes.string,
          state: PropTypes.string,
        })
      ),
    ]),
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    fetchLocationAddresses: PropTypes.func,
    onClearLocationAddresses: PropTypes.func,
    lastSearchedZipCode: PropTypes.string,
  }),
};
