import React, {useCallback, useEffect, useState} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {includes} from 'lodash';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import {SWRConfig} from 'swr';

import loadingImg from '../assets/icons/loading.svg';

import {useAuth} from './entry/hooks/useAuth';
import {ChangePasswordPage} from './entry/pages/changePasswordPage';
import {ResetPasswordPage} from './entry/pages/resetPasswordPage';
import {SetPasswordSuccessPage} from './entry/pages/setPasswordSuccessPage';
import {SignInPage} from './entry/pages/signInPage';
import {SignUpPage} from './entry/pages/signUpPage';
import {currentTenantIdState} from './shared/atoms/authAtom';
import {LoadingBar} from './shared/components/loadingBar';
import {useCustomizedSnackbar} from './shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from './shared/hooks/useErrorHandling';
import {NoPropsComponent} from './shared/interfaces/common';
import {colors} from './shared/styles/theme';
import {fetcher} from './shared/utils/fetcher';
import {wrapAsyncFunction} from './shared/utils/wrapAsyncFunction';
import {Main} from './main';

const LoadingPage = () => (
  <>
    <LoadingBar loading masking={false} />
    <LoadingWrapper>
      <LoadingImg src={loadingImg} alt="loading" />
      <LoadingTitle>Loading...</LoadingTitle>
    </LoadingWrapper>
  </>
);

const notOnEntryPages = (pathname: string) =>
  !/\/[A-Za-z0-9-]{4,20}-[a-z0-9]{5}\/sign-in$/.exec(pathname) &&
  pathname !== '/mobile-sign-in' &&
  pathname !== '/reset-password' &&
  pathname !== '/health-connect' &&
  pathname !== '/change-password' &&
  !/\/[A-Za-z0-9-]{4,20}-[a-z0-9]{5}\/sign-up\/(\d+)$/.exec(pathname);

const notOnEntryOrSuccessPages = (pathname: string) =>
  notOnEntryPages(pathname) && pathname !== '/set-password-successful';

let pageRefreshed = false;

export const App: NoPropsComponent = () => {
  const history = useHistory();
  const {pathname} = useLocation();

  const tenantId = useRecoilValue(currentTenantIdState);

  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();
  const {loadFirebaseUser, signOut} = useAuth();

  const [loading, setLoading] = useState(true);

  const redirectOnTokenUpdate = useCallback(
    async currentUser => {
      if (notOnEntryOrSuccessPages(pathname)) {
        const hasSignedOut = !currentUser;
        const tokenReplacedDuringSignUp =
          currentUser && !currentUser.mfaEnabled;

        if (hasSignedOut) {
          history.replace(`/${tenantId}/sign-in`);
          showMessage('Session expired, please log in again.', 'error');
        } else if (tokenReplacedDuringSignUp) {
          history.replace(`/${tenantId}/sign-in`);
        }
      }
    },
    [history, pathname, tenantId, showMessage]
  );

  const redirectOnRefresh = useCallback(
    async currentUser => {
      if (currentUser) {
        const mfaNotEnabled = !currentUser.mfaEnabled;

        const entryPaths = [
          `/${tenantId}/sign-in`,
          '/set-password-successful',
          '/mobile-sign-in',
        ];
        const goToEntryPagesWithToken = includes(entryPaths, pathname);

        if (mfaNotEnabled) {
          await signOut();
          history.replace(`/${tenantId}/sign-in`);
        } else if (goToEntryPagesWithToken) {
          history.replace('/');
        }
      } else if (notOnEntryPages(pathname)) {
        history.replace(`/${tenantId}/sign-in`);
      }
    },
    [history, pathname, tenantId, signOut]
  );

  useEffect(
    () =>
      onAuthStateChanged(getAuth(), user => {
        (async () => {
          let currentUser;

          if (user) {
            currentUser = await loadFirebaseUser(user);
          }

          if (pageRefreshed) {
            await redirectOnTokenUpdate(currentUser);
            return;
          }

          await redirectOnRefresh(currentUser);
          pageRefreshed = true;

          setLoading(false);
        })();
      }),
    [loadFirebaseUser, redirectOnTokenUpdate, redirectOnRefresh]
  );

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <FullScreenLayout>
      <SWRConfig
        value={{
          fetcher,
          onError: wrapAsyncFunction(handleError),
          revalidateOnFocus: false,
        }}
      >
        <Switch>
          <Route path="/:tenantId/sign-up/:invitationId">
            <SignUpPage />
          </Route>
          <Route path="/:tenantId/sign-in">
            <SignInPage />
          </Route>
          <Route exact path="/reset-password">
            <ResetPasswordPage />
          </Route>
          <Route exact path="/change-password">
            <ChangePasswordPage />
          </Route>
          <Route exact path="/set-password-successful">
            <SetPasswordSuccessPage />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </SWRConfig>
    </FullScreenLayout>
  );
};

const FullScreenLayout = styled.div`
  height: 100%;
`;

const LoadingTitle = styled.h4`
  font-size: 22px;
  font-style: italic;
  color: ${colors.grey801};
  font-weight: 400;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const LoadingImg = styled.img`
  margin-top: 250px;
`;
