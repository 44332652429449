/* eslint-disable react/prop-types */
import React from 'react';
import {Menu, Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {isArray, snakeCase} from 'lodash';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {usePatientNameClick} from '../../main/activityLog/hooks/usePatientNameClick';
import {
  addMRN,
  updateMRN,
} from '../../main/activityLog/utils/activityFetchHelper';
import {currentUserState} from '../atoms/authAtom';
import {useErrorHandling} from '../hooks/useErrorHandling';
import {colors} from '../styles/theme';

import {CloseButton, ConfirmButton} from './button';
import {InputField} from './form';
import OverflowTip from './overFlowTip';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '41px',
    marginRight: '60px',

    '& .MuiTableRow-root': {
      '& td:first-child': {
        fontWeight: 600,
      },
    },
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}));

const ColumnText = React.memo(({text, column}) => (
  <OverflowTip
    value={isArray(text) ? text.join('; ') : text}
    tipValue={isArray(text) ? text.join('; ') : text}
    maxWidth={column.maxWidth}
  />
));

ColumnText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.any),
  ]),
  column: PropTypes.shape({maxWidth: PropTypes.number}),
};

const HyperlinkColumnText = React.memo(
  ({text, column, style, redirectTo, ...props}) => (
    <a
      href={redirectTo ?? 'https://demoehr.allclearid.com/clinic'}
      style={{textDecoration: 'underline', color: colors.primary, ...style}}
      {...props}
    >
      <OverflowTip value={text} tipValue={text} maxWidth={column.maxWidth} />
    </a>
  )
);

HyperlinkColumnText.propTypes = {
  text: PropTypes.string,
  redirectTo: PropTypes.string,
  style: PropTypes.shape({}),
  column: PropTypes.shape({maxWidth: PropTypes.number}),
};

const IconCell = React.memo(({icons, methods, item, style}) => {
  const currentUser = useRecoilValue(currentUserState);
  return (
    <TableCell style={style}>
      {icons.map(icon => {
        const IconComponent = icon.component;
        return (
          (!icon.prerequisite || icon.prerequisite({item, currentUser})) && (
            <Tooltip title={icon.title} key={icon.title}>
              <IconContainer key={icon.title}>
                <IconComponent
                  onClick={() => methods[icon.title](item)}
                  data-testid={`${icon.title}-icon`}
                  style={{
                    cursor: 'pointer',
                    color: colors.grey801,
                    width: 14,
                    height: 14,
                  }}
                />
              </IconContainer>
            </Tooltip>
          )
        );
      })}
    </TableCell>
  );
});

IconCell.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({})),
  methods: PropTypes.shape({}),
  item: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

const IconWithTextCell = ({
  icons,
  item,
  style,
  children,
  iconColor,
  iconStyle,
  iconPosition = 'right',
  iconTextCellElement,
  iconClick,
  menuAnchor,
  isMenuOpen,
  closeMenu,
}) => {
  const currentUser = useRecoilValue(currentUserState);

  return (
    <div style={style}>
      {iconPosition === 'right' && icons ? children : null}
      {icons?.map(icon => {
        const IconComponent = icon.component;
        return (
          (!icon.prerequisite || icon.prerequisite({item, currentUser})) && (
            <Tooltip
              title={icon.title}
              key={icon.title}
              interactive
              disableHoverListener
              disableFocusListener
            >
              <IconContainer key={icon.title}>
                <IconComponent
                  onClick={iconClick}
                  data-testid={`${icon.title}-icon`}
                  style={{
                    cursor: 'pointer',
                    color: iconColor ?? colors.grey801,
                    width: 14,
                    height: 14,
                    ...iconStyle,
                  }}
                />
                <Menu
                  anchorEl={menuAnchor}
                  open={isMenuOpen}
                  onClose={closeMenu}
                  id="basic-menu"
                >
                  {iconTextCellElement() ?? null}
                </Menu>
              </IconContainer>
            </Tooltip>
          )
        );
      })}
      {iconPosition === 'left' && icons ? children : null}
    </div>
  );
};

IconWithTextCell.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({})),
  methods: PropTypes.shape({}),
  item: PropTypes.shape({}),
  children: PropTypes.element,
  style: PropTypes.shape({}),
  iconColor: PropTypes.string,
  iconStyle: PropTypes.shape({}),
  iconTextCellElement: PropTypes.element,
  iconPosition: PropTypes.string,
};

const showStyles = (value, column) => {
  if (column.getStyle) {
    return column.getStyle(value);
  }
  return null;
};

const customHeaderStyles = {
  action: {
    position: 'sticky',
    right: 0,
    borderLeft: `1px solid ${colors.grey800}`,
    boxShadow: '-3px 0px 8px rgba(176, 176, 176, 0.25)',
  },
  name: {
    borderRight: `1px solid ${colors.grey800}`,
    fontWeight: 700,
    position: 'sticky',
    left: 0,
  },
  consumerName: {
    borderRight: `1px solid ${colors.grey800}`,
    fontWeight: 700,
    position: 'sticky',
    left: 0,
  },
};

const Cell = React.memo(({column, item, methods, style}) => {
  const [mrn, setMrn] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onConsumerNameClick = usePatientNameClick();
  const open = Boolean(anchorEl);
  const handleError = useErrorHandling('Unable to save changes.');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfirmClick = React.useCallback(async () => {
    try {
      if (!item?.mrn?.patient_mrn) {
        await addMRN(
          item.consumer_uuid,
          item.ehr.id,
          mrn,
          item.ehr.link,
          item.ehr.relying_party_id
        );
      } else {
        await updateMRN(
          item.consumer_uuid,
          item.mrn.id,
          item.ehr.id,
          mrn,
          item.ehr.link,
          item.ehr.relying_party_id
        );
      }
      handleClose();
      setMrn('');
    } catch (error) {
      handleError(error);
    }
  }, [
    handleError,
    item?.consumer_uuid,
    item?.ehr?.id,
    item?.ehr?.link,
    item?.ehr?.relying_party_id,
    item?.mrn?.id,
    item?.mrn?.patient_mrn,
    mrn,
  ]);
  const editMrnMenu = () => (
    <div
      style={{
        border: `1.5px solid ${colors.grey800}`,
        backgroundColor: colors.white,
        boxShadow: '0px 4px 10px #8b8b8b40',
        width: 255,
        height: 140,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p
        style={{
          alignSelf: 'flex-start',
          marginTop: 8,
          marginLeft: 18,
          marginBottom: 8,
        }}
      >
        Add MRN
      </p>
      <InputField
        data-testid="editMRN-input"
        width="small"
        onChange={e => setMrn(e.target.value)}
        value={mrn}
        inputProps={{maxLength: 10}}
      />
      <div style={{alignSelf: 'flex-end', marginRight: 18}}>
        <CloseButton
          variant="outlined"
          testID="cancelEditMRN"
          style={{
            width: 36,
            height: 36,
            minWidth: 36,
            marginTop: 15,
          }}
          onClick={handleClose}
        />
        <ConfirmButton
          disabled={!mrn.length > 0}
          onClick={handleConfirmClick}
          testID="confirmEditMRN"
          variant="outlined"
          style={{
            backgroundColor: !mrn.length > 0 ? colors.grey900 : colors.primary,
            color: colors.white,
            width: 36,
            height: 36,
            minWidth: 36,
            marginTop: 15,
          }}
        />
      </div>
    </div>
  );
  const fieldValue = item[snakeCase(column.key)];
  const columnCells = {
    action: (
      <IconCell
        icons={column.icons}
        item={item}
        methods={methods}
        style={{
          ...style,
          ...customHeaderStyles.action,
        }}
      />
    ),
    mrn: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
        }}
      >
        {item?.ehr ? (
          <IconWithTextCell
            icons={column.icons}
            item={item}
            methods={methods}
            style={{
              background: colors.white,
              display: 'flex',
              alignItems: 'center',
              maxWidth: 120,
              justifyContent: 'space-between',
            }}
            iconColor={colors.black}
            iconTextCellElement={editMrnMenu}
            iconClick={handleClick}
            menuAnchor={anchorEl}
            isMenuOpen={open}
            closeMenu={handleClose}
          >
            <HyperlinkColumnText
              text={fieldValue?.patient_mrn ?? 'Add MRN'}
              column={column}
              style={{
                color: !fieldValue?.patient_mrn ? colors.red : colors.primary,
              }}
              redirectTo={fieldValue?.link}
            />
          </IconWithTextCell>
        ) : (
          <span style={showStyles(fieldValue, column)}>
            <ColumnText text="--" column={column} />
          </span>
        )}
      </TableCell>
    ),
    consumerName: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
          ...customHeaderStyles.consumerName,
        }}
      >
        <HyperlinkColumnText
          onClick={e => {
            e.preventDefault();
            onConsumerNameClick(item.consumer_uuid, item.consumer_name);
          }}
          text={column.formatter ? column.formatter(fieldValue) : fieldValue}
          column={column}
        />
      </TableCell>
    ),
    name: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
          ...customHeaderStyles.name,
        }}
      >
        <span style={showStyles(fieldValue, column)}>
          <ColumnText
            text={column.formatter ? column.formatter(fieldValue) : fieldValue}
            column={column}
          />
        </span>
      </TableCell>
    ),
    ehr: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
        }}
      >
        <span style={showStyles(fieldValue, column)}>
          <ColumnText text={fieldValue?.name} column={column} />
        </span>
      </TableCell>
    ),
    insurance: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
        }}
      >
        <span style={showStyles(fieldValue, column)}>
          <ColumnText
            text={fieldValue?.health_insurance_provider}
            column={column}
          />
        </span>
      </TableCell>
    ),
    memberIdNumber: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
        }}
      >
        <span style={showStyles(fieldValue, column)}>
          <ColumnText
            text={item?.insurance?.member_id_number}
            column={column}
          />
        </span>
      </TableCell>
    ),
    groupNumber: (
      <TableCell
        key={column.key}
        style={{
          ...style,
          fontWeight: 'normal',
        }}
      >
        <span style={showStyles(fieldValue, column)}>
          <ColumnText text={item?.insurance?.group_number} column={column} />
        </span>
      </TableCell>
    ),
  };
  return column.key in columnCells ? (
    columnCells[column.key]
  ) : (
    <TableCell key={column.key} style={style}>
      <span style={showStyles(fieldValue, column)}>
        <ColumnText
          text={column.formatter ? column.formatter(fieldValue) : fieldValue}
          column={column}
        />
      </span>
    </TableCell>
  );
});

Cell.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.shape({})),
    formatter: PropTypes.func,
  }),
  item: PropTypes.shape({}),
  methods: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

/**
 * @deprecated CustomizedTable table will be deleted. Use styled table instead.
 */
export const CustomizedTable = React.memo(
  ({
    data = [],
    columns = [],
    iconMethodMap,
    iconTextCellElement,
    keyExtractor = 'id',
    isStickyHeader,
    ...props
  }) => {
    const classes = useStyles();
    const currentUser = useRecoilValue(currentUserState);
    return (
      <TableWrapper {...props}>
        <TableContainer className={classes.container}>
          <Table
            aria-label="sticky table"
            style={{width: '100%', borderCollapse: 'separate'}}
          >
            <TableHead className={isStickyHeader ? classes.stickyHeader : ''}>
              <TableRow>
                {columns.map(
                  column =>
                    (!column.prerequisiteRoles ||
                      column.prerequisiteRoles(currentUser.roles)) && (
                      <TableCell
                        key={column.key}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          width: column.width,
                          ...customHeaderStyles[column.key],
                          ...column.style,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => (
                <TableRow hover key={item[keyExtractor]}>
                  {columns.map(
                    column =>
                      (!column.prerequisiteRoles ||
                        column.prerequisiteRoles(currentUser.roles)) && (
                        <Cell
                          item={item}
                          column={column}
                          methods={iconMethodMap}
                          key={column.key}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth,
                            background: column.background,
                            ...column.style,
                          }}
                          iconTextCellElement={iconTextCellElement}
                        />
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    );
  }
);

CustomizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.number,
      style: PropTypes.shape({}),
    })
  ),
  iconTextCellElement: PropTypes.func,
  keyExtractor: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  iconMethodMap: PropTypes.shape({}),
  isStickyHeader: PropTypes.bool,
};

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.span`
  height: 14px;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
