import React, {useCallback, useMemo} from 'react';
import {Grid} from '@material-ui/core';
import {pick} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PenIcon from '../../../../assets/icons/edit.svg';
import {Button} from '../../../shared/components/button';
import {Subheader} from '../../../shared/components/subheader';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';
import {isUserIssuerAdminOrOwner} from '../../../shared/utils/user';

import {InformationContainer} from './informationContainer';
import {InvitationButton} from './invitationButton';
import {OwnerInformationField} from './ownerInformationField';

export const ProfileDisplayField = ({roles, data, onEditModeChanged}) => {
  const {relying_party: profile, relying_party_owner: ownerData} = data;
  const isCurrentUserNonIndividual =
    profile?.provider_type !== PROVIDER_TYPES.INDIVIDUAL;

  const informationContainerFields = useMemo(
    () =>
      isCurrentUserNonIndividual
        ? pick(profile, ['npi', 'name', 'domain'])
        : pick(profile, ['npi', 'name']),
    [isCurrentUserNonIndividual, profile]
  );
  const onClick = useCallback(
    () => onEditModeChanged(true),
    [onEditModeChanged]
  );

  return (
    <>
      <Subheader title={profile?.name}>
        {isUserIssuerAdminOrOwner(roles) &&
          (ownerData.status === 'CONFIRMED' ? (
            <Button
              position="section"
              startIcon={<img src={PenIcon} alt="editIcon" />}
              testID="editButton"
              onClick={onClick}
            >
              Edit
            </Button>
          ) : (
            ownerData.status === 'INVITED' && (
              <InvitationButton email={ownerData.email} />
            )
          ))}
      </Subheader>
      <ContentWrapper>
        <Grid container>
          <Grid item xs={9}>
            <InformationContainer
              title="Profile"
              data={informationContainerFields}
            />
            <OwnerInformationFieldWrapper>
              <OwnerInformationField ownerInfo={ownerData} />
            </OwnerInformationFieldWrapper>
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  );
};

ProfileDisplayField.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any),
  onEditModeChanged: PropTypes.func,
};

const ContentWrapper = styled.div`
  margin: 24px 60px 0 40px;
  padding-bottom: 14px;
`;

const OwnerInformationFieldWrapper = styled.div`
  margin-top: 37px;
`;
