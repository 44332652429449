import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import useSWR, {mutate} from 'swr';

import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {currentPolicyState} from '../atoms/currentPolicyState';
import {DeleteModal} from '../components/deleteModal';
import {PolicyDetailTable} from '../components/policyDetailTable';
import {PolicyEditForm} from '../components/policyEditForm';

export const PolicyDetailPage = ({crumbs, title}) => {
  const {policyId} = useParams();
  const policyUrl = urls.policy(policyId);
  const {data: policiesData} = useSWR(policyUrl);
  const {state} = useLocation();
  const history = useHistory();
  const [editMode, setEditMode] = useState(state?.startEditing);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const setCurrentPolicy = useSetRecoilState(currentPolicyState);

  useEffect(() => {
    if (policiesData) {
      setCurrentPolicy(policiesData.name);
    }
  }, [policiesData, setCurrentPolicy]);

  const handleError = useErrorHandling('Unable to save changes.');
  const showMessage = useCustomizedSnackbar();

  const handleSubmit = useCallback(
    async newPolicyData => {
      try {
        const policyData = await fetcher(policyUrl, 'PUT', {
          ...policiesData,
          ...newPolicyData,
        });

        await mutate(policyUrl, policyData);

        showMessage('Changes saved successfully.', 'success');
        if (state?.from) {
          history.push({
            pathname: state.from,
            state: {policyTypeId: policiesData?.policy_type_id},
          });
        } else {
          setEditMode(false);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, history, policiesData, policyUrl, showMessage, state]
  );

  const handleCancel = useCallback(() => {
    if (state?.from) {
      history.push({
        pathname: state.from,
        state: {policyTypeId: policiesData?.policy_type_id},
      });
    } else {
      setEditMode(false);
    }
  }, [history, state, policiesData]);

  if (!policiesData) {
    return null;
  }

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      {editMode ? (
        <PolicyEditForm
          onCancel={handleCancel}
          policies={policiesData}
          onSubmit={handleSubmit}
          type="edit"
        />
      ) : (
        <PolicyDetailTable
          policies={policiesData}
          startEditing={() => setEditMode(true)}
          startDeleting={() => setOpenDeleteModal(true)}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          data={policiesData}
          open={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          disabled={!isEmpty(policiesData.locations)}
          type="policy"
        />
      )}
    </Container>
  );
};

PolicyDetailPage.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
