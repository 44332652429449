import {PenIcon} from '../../../shared/icons/penIcon';
import {colors} from '../../../shared/styles/theme';
import {formatDate} from '../../../shared/utils/formatDate';
import {POLICY_TYPES_FORMATTER_STATUS_FIELD} from '../utils/formatter';

export const activityLogColumns = policyType => [
  {
    key: 'consumerName',
    label: 'Patient',
    minWidth: 100,
    maxWidth: 100,
    style: {fontWeight: 700, paddingLeft: 50},
  },
  {
    key: 'mrn',
    label: 'MRN',
    minWidth: 100,
    maxWidth: 100,
    icons: [
      {
        component: PenIcon,
        title: 'Edit',
      },
    ],
  },
  {
    key: 'ehr',
    label: 'EHR',
    minWidth: 100,
    maxWidth: 100,
    style: {fontWeight: 700},
  },
  {
    key: 'insurance',
    label: 'Insurance',
    minWidth: 100,
    maxWidth: 100,
    style: {fontWeight: 700},
  },
  {
    key: 'memberIdNumber',
    label: 'Member #',
    minWidth: 100,
    maxWidth: 100,
    style: {fontWeight: 700},
  },
  {
    key: 'groupNumber',
    label: 'Group #',
    minWidth: 100,
    maxWidth: 100,
    style: {fontWeight: 700},
  },
  {
    key: 'locationName',
    label: 'Location',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: 'createdAt',
    label: 'Arrival Time',
    minWidth: 200,
    maxWidth: 200,
    formatter: formatDate,
  },
  {
    key: 'admitted',
    label: 'Status',
    minWidth: 80,
    maxWidth: 80,
    formatter: POLICY_TYPES_FORMATTER_STATUS_FIELD[policyType],
    style: {
      position: 'sticky',
      right: 0,
      borderLeft: `1px solid ${colors.grey800}`,
      boxShadow: '-3px 0px 8px rgba(176, 176, 176, 0.25)',
    },
    getStyle: status => ({
      fontWeight: 700,
      color: status ? colors.greenText : colors.red,
    }),
  },
];
