import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {v4 as uuidv4} from 'uuid';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {User} from '../../../shared/interfaces/user';
import {
  fetchFileForPreview,
  fetchFileUpload,
  fetchFileUploadLink,
  fetchFileUploadSave,
} from '../utils/fetchers';
import {FileContentType, getContentType} from '../utils/fileViewer';

export type FileForPreview = {
  id: string;
  file: File;
  contentType: FileContentType;
  hasError: boolean;
  isUploaded: boolean;
  isUploading: boolean;
  isSelected: boolean;
  gcpFileName: string;
};

export type FilePreview = {
  type: string;
  data: string;
};

type FileUploadLinkResponse = {
  upload_url: string;
  file_name: string;
};

const filePreviewInitialState = {
  type: '',
  data: '',
};

export const usePatientsFiles = (): {
  onLoadFilesForPreview: (files: File[]) => Promise<void>;
  onSelectFileForPreview: (data: FileForPreview) => Promise<void>;
  onRetryUpload: (data: FileForPreview) => Promise<void>;
  onDeleteFile: (id: string) => void;
  onSaveFiles: () => Promise<void>;
  filesForPreview: FileForPreview[];
  selectedFileForPreview: FileForPreview | null;
  filePreviewIsLoading: boolean;
  filePreviewHasError: boolean;
  filePreviewData: FilePreview;
  filesIsSaving: boolean;
} => {
  const currentUser = useRecoilValue(currentUserState);
  const {patientUuid} = useParams<{patientUuid: string}>();
  const [filesForPreview, setFilesForPreview] = useState<FileForPreview[]>([]);
  const [filePreviewIsLoading, setFilePreviewIsLoading] =
    useState<boolean>(false);
  const [filePreviewHasError, setFilePreviewHasError] =
    useState<boolean>(false);
  const [filePreviewData, setFilePreviewData] = useState<FilePreview>(
    filePreviewInitialState
  );
  const [filesIsSaving, setFilesIsSaving] = useState<boolean>(false);

  const showMessage = useCustomizedSnackbar();

  const setFileIsUpload = (
    fileForPreview: FileForPreview,
    gcpFileName: string
  ) => {
    const fileChanges = {
      isUploading: false,
      isUploaded: true,
      gcpFileName,
    };

    setFilesForPreview(prevState =>
      prevState.map(file => {
        if (fileForPreview.id === file.id) {
          return {
            ...file,
            ...fileChanges,
          };
        }

        return file;
      })
    );
  };

  const setFileHasError = (fileForPreview: FileForPreview) => {
    const fileChanges = {
      isUploading: false,
      hasError: true,
      gcpFileName: '',
    };

    setFilesForPreview(prevState =>
      prevState.map(file =>
        fileForPreview.id === file.id ? {...file, ...fileChanges} : file
      )
    );
  };

  const setFileIsUploading = (fileForPreview: FileForPreview) => {
    const fileChanges = {
      isUploading: true,
      isUploaded: false,
      hasError: false,
      gcpFileName: '',
    };

    setFilesForPreview(prevState =>
      prevState.map(file =>
        fileForPreview.id === file.id ? {...file, ...fileChanges} : file
      )
    );
  };

  const uploadFile = async (
    fileForPreview: FileForPreview,
    currentUser: User
  ): Promise<void> => {
    try {
      const {
        upload_url: uploadUrl,
        file_name: gcpFileName,
      }: FileUploadLinkResponse = await fetchFileUploadLink({
        file_name: fileForPreview.file.name,
        content_type: fileForPreview.contentType.payloadContentType,
        consumer_uuid: patientUuid,
        client_id: currentUser.id,
        relying_party_id: currentUser.relyingParty.id,
        provider_name: currentUser.relyingParty.name,
        uploaded_by: currentUser.name,
      });

      await fetchFileUpload(uploadUrl, fileForPreview);

      setFileIsUpload(fileForPreview, gcpFileName);
    } catch (e) {
      setFileHasError(fileForPreview);
    }
  };

  const uploadFiles = async (files: FileForPreview[]): Promise<void> => {
    if (currentUser && files.length !== 0) {
      await Promise.allSettled(
        files.map(file => uploadFile(file, currentUser))
      );
    }
  };

  const onLoadFilesForPreview = async (files: File[]): Promise<void> => {
    const filesForPreview = files.reduce<FileForPreview[]>(
      (acc, curr): FileForPreview[] => {
        const contentType = getContentType(curr.name);

        if (contentType) {
          acc.push({
            id: uuidv4(),
            file: curr,
            contentType,
            hasError: false,
            isUploading: true,
            isSelected: false,
            isUploaded: false,
            gcpFileName: '',
          });
        } else {
          showMessage(
            `File "${curr.name}" has not supported file extension.`,
            'error'
          );
        }

        return acc;
      },
      []
    );

    setFilesForPreview(prevState => [...prevState, ...filesForPreview]);

    await uploadFiles(filesForPreview);
  };

  const getFileForPreview = async (fileForPreview: FileForPreview) => {
    try {
      setFilePreviewHasError(false);
      setFilePreviewIsLoading(true);

      const fileData = await fetchFileForPreview({
        file_name: fileForPreview.gcpFileName,
        content_type: fileForPreview.contentType.payloadContentType,
      });

      setFilePreviewData({
        type: fileForPreview.contentType.payloadContentType,
        data: fileData,
      });
    } catch (e) {
      setFilePreviewHasError(true);
    } finally {
      setFilePreviewIsLoading(false);
    }
  };

  const onSelectFileForPreview = async (
    selectedFile: FileForPreview
  ): Promise<void> => {
    setFilesForPreview(prevState =>
      prevState.map(file => {
        if (file.id === selectedFile.id) {
          return {
            ...file,
            isSelected: true,
          };
        }
        return {
          ...file,
          isSelected: false,
        };
      })
    );
  };

  const onDeleteFile = (id: string) => {
    setFilesForPreview(filesForPreview.filter(file => file.id !== id));
  };

  const onRetryUpload = async (file: FileForPreview) => {
    if (currentUser) {
      setFileIsUploading(file);

      await uploadFile(file, currentUser);
    }
  };

  const selectedFileForPreview = useMemo(
    () => filesForPreview.find(file => file.isSelected) ?? null,
    [filesForPreview]
  );

  const onSaveFiles = async () => {
    try {
      setFilesIsSaving(true);

      if (currentUser) {
        await fetchFileUploadSave(
          filesForPreview.map(file => ({
            file_name: file.gcpFileName,
            content_type: file.contentType.payloadContentType,
            consumer_uuid: patientUuid,
            client_id: currentUser.id,
            relying_party_id: currentUser.relyingParty.id,
            provider_name: currentUser.relyingParty.name,
            uploaded_by: currentUser.name,
          }))
        );
      }

      showMessage('Uploaded files saved successfully', 'success');
    } catch (e) {
      showMessage('Saving files failed. Please try again.', 'error');
    } finally {
      setFilesIsSaving(false);
    }
  };

  useEffect(() => {
    if (selectedFileForPreview?.isUploaded) {
      getFileForPreview(selectedFileForPreview);
    }
  }, [selectedFileForPreview]);

  return {
    onLoadFilesForPreview,
    onSelectFileForPreview,
    onDeleteFile,
    onRetryUpload,
    onSaveFiles,
    filesForPreview,
    selectedFileForPreview,
    filePreviewIsLoading,
    filePreviewHasError,
    filePreviewData,
    filesIsSaving,
  };
};
