import {isEmpty} from 'lodash';

export const shouldDisplayConfirmationPopper = (
  errors,
  values,
  touched,
  passwordFocus,
  confirmPasswordFocus
) => {
  const passwordFocusedOrInvalid = passwordFocus || errors.password;
  if (!values.password && !touched.password) {
    return false;
  }
  if (passwordFocusedOrInvalid) {
    return false;
  }
  if (confirmPasswordFocus) {
    return true;
  }
  return !!(
    values.confirmPassword &&
    touched.confirmPassword &&
    !isEmpty(errors.confirmPassword)
  );
};

export const shouldDisplayPasswordPopper = (
  errors,
  passwordFocus,
  passwordValue,
  passwordTouched
) => {
  if (passwordFocus) {
    return true;
  }
  return !!(passwordValue && passwordTouched && !isEmpty(errors.password));
};
