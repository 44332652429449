import useSWR from 'swr';

import {Ehr} from '../../../shared/interfaces/ehr';
import {urls} from '../../../shared/utils/urls';

type RequestEhrsResult = [Ehr] | [];

export const useEhrs: (
  relyingPartyId: number
) => RequestEhrsResult = relyingPartyId =>
  useSWR<RequestEhrsResult>(urls.relyingPartyEhrs(relyingPartyId)).data ?? [];
