import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {ActionIcon} from '../../../shared/icons/actionIcon';

interface DetailProps {
  path: string;
}

export const DetailIcon: React.FC<DetailProps> = ({path}) => {
  const history = useHistory();
  const goTo = useCallback(() => history.push(path), [history, path]);

  return (
    <ActionTableButton title="Detail" testId="detailIcon" onClick={goTo}>
      <ActionIcon />
    </ActionTableButton>
  );
};
