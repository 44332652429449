import React from 'react';

import {List} from '../../../../shared/components/list';

export const ListWithFixedLabelsColumn: React.FC<{
  labelsColumnWidth: string;
  children: React.ReactNode;
}> = ({labelsColumnWidth, children}) => (
  <List labelsColumnWidth={labelsColumnWidth}>{children}</List>
);
