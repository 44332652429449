import React from 'react';
import {Form, Formik} from 'formik';
import {pick} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button, CancelButton} from '../../../shared/components/button';
import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {Subheader} from '../../../shared/components/subheader';
import {checkEditFormButtonDisability} from '../../../shared/utils/formikUtils';
import {POLICY_TYPES} from '../constants/defaultPolicy';
import {policyTitleMap} from '../utils/policyFormatter';
import {policyFormValidateSchema} from '../utils/policyFormValidate';

import {PolicyInputs} from './policyInputs';

export const PolicyEditForm = ({policies, onCancel, onSubmit}) => {
  const mapKey = POLICY_TYPES[policies.policy_type_id];
  const titleMap = policyTitleMap[mapKey];
  const validationSchema = policyFormValidateSchema[mapKey];
  return (
    <Formik
      enableReinitialize
      onSubmit={values => onSubmit({...policies, ...values})}
      initialValues={pick(policies, [...Object.keys(titleMap), 'name'])}
      validationSchema={validationSchema}
    >
      {({...props}) => (
        <Form>
          <Subheader title={policies.name}>
            <Button
              type="submit"
              testID="saveButton"
              position="section"
              disabled={checkEditFormButtonDisability(props)}
            >
              Save
            </Button>
            <CancelButton position="section" onClick={onCancel} />
          </Subheader>
          <MainContainer>
            <PolicyInfoSection>
              <Subtitle>Policy type</Subtitle>
              <DataDisplayField
                label="Policy type"
                value={POLICY_TYPES[policies.policy_type_id]}
                title={POLICY_TYPES[policies.policy_type_id]}
              />
            </PolicyInfoSection>
            <PolicyInfoSection>
              <Subtitle>Policy name</Subtitle>
              <DataDisplayField
                label="Policy name"
                value={policies.name}
                title={policies.name}
              />
            </PolicyInfoSection>
            <Subtitle>Select policy requirements</Subtitle>
            <PolicyInputs policies={policies} type="edit" />
          </MainContainer>
        </Form>
      )}
    </Formik>
  );
};

PolicyEditForm.propTypes = {
  policies: PropTypes.objectOf(PropTypes.any),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

const Subtitle = styled.h6`
  padding-bottom: 12px;
  margin: 0;
`;

const MainContainer = styled.div`
  padding: 16px 40px;
`;

const PolicyInfoSection = styled.div`
  margin-bottom: 30px;
`;
