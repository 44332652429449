import {getAuth} from 'firebase/auth';

import {envConfig} from '../../../env';

export function autoLogout(showMessage, signOut) {
  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  const sessionExpiryMinutes =
    Number(envConfig.REACT_APP_SESSION_EXPIRY_MINUTES) || 15;

  const warningTime = 1000 * 60 * (sessionExpiryMinutes - 1);
  const logoutTime = 1000 * 60 * sessionExpiryMinutes;

  const warn = () => {
    if (getAuth().currentUser !== null)
      showMessage('Session will expire in 1 minute', 'info');
  };

  const logout = async () => {
    if (getAuth().currentUser !== null) {
      showMessage('Session expired, please log in again', 'error');
      await signOut();
    }
  };

  let timeoutWarningTime = setTimeout(warn, warningTime);
  let timeoutSignoutTime = setTimeout(logout, logoutTime);

  const setTimeouts = () => {
    timeoutWarningTime = setTimeout(warn, warningTime);
    timeoutSignoutTime = setTimeout(logout, logoutTime);
  };

  const clearTimeoutFunc = () => {
    clearTimeout(timeoutWarningTime);
    clearTimeout(timeoutSignoutTime);
  };

  const resetTimeout = () => {
    clearTimeoutFunc();
    setTimeouts();
  };

  const addWindowCloseEvent = () => {
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('closeWindowTimestamp', Date.now().toString());
    });
  };

  const logoutAfterCloseWindowTimeout = async () => {
    const closeWindowTimestamp = Number(
      localStorage.getItem('closeWindowTimestamp')
    );
    const timeSinceWindowClose = Date.now() - closeWindowTimestamp;
    const millisecondsUntilExpiration = logoutTime - timeSinceWindowClose;

    if (closeWindowTimestamp !== 0 && millisecondsUntilExpiration <= 0) {
      await logout();
    }
  };

  const init = async () => {
    events.forEach(event => window.addEventListener(event, resetTimeout));
    addWindowCloseEvent();
    logoutAfterCloseWindowTimeout();
  };

  init();
}
