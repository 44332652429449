import {fetcher, HttpMethod} from './fetcher';
import {urls} from './urls';

export const fetchNpiData = (npi, npiType) =>
  fetcher(urls.searchDataForNPI(npi, npiType));

export const fetchNpiDataAndValidate = (npi, npiType) =>
  fetcher(urls.searchAndValidateDataForNPI(npi, npiType));

export const fetchConnectPatient = data =>
  fetcher(urls.clientShareRequests(), HttpMethod.POST, data);

export const fetchShareMedicalRecordsWithProvider = data =>
  fetcher(urls.shareMedicalRecordsWithProvider(), HttpMethod.POST, data);
