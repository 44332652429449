import React, {FC, Fragment, useCallback, useRef, useState} from 'react';
import {IconButton, Link} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

import {CustomizedButton} from '../../../shared/components/customizedButton';
import {FormattedMRNsDetailsType} from '../../../shared/constants/formattedMRNsDetailsType';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {CopyIcon} from '../../../shared/icons/copyIcon';
import {PenIcon} from '../../../shared/icons/penIcon';
import {colors} from '../../../shared/styles/theme';
import {PATIENT_DETAILS_LABELS} from '../constants/PatientDetailsLabels';

import {ModalWindowManageMRN} from './modalWindowManageMRN';

type Props = {
  consumerUuid: string;
  items: FormattedMRNsDetailsType[];
  patientName: string;
};

type ItemProps = {
  item: FormattedMRNsDetailsType;
  onOpenModalWindow: (item: FormattedMRNsDetailsType | null) => void;
};

export const PatientEhrsList: FC<Props> = ({
  items,
  patientName,
  consumerUuid,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [modalWindowManageMRN, setModalWindowManageMRN] = useState<{
    isOpen: boolean;
    data: FormattedMRNsDetailsType | null;
  }>({
    isOpen: false,
    data: null,
  });

  const onCloseModalWindow = useCallback(
    () =>
      setModalWindowManageMRN(prevState => ({
        ...prevState,
        isOpen: false,
      })),
    []
  );

  const onOpenModalWindow = (item: FormattedMRNsDetailsType | null) => {
    setModalWindowManageMRN(prevState => ({
      ...prevState,
      isOpen: true,
      data: item,
    }));
  };

  return (
    <PatientEhrsListWrapper ref={ref}>
      {items.length !== 0 ? (
        <ListWrapper>
          <HeaderItemWrapper>{PATIENT_DETAILS_LABELS.EHR}</HeaderItemWrapper>
          <HeaderItemWrapper>{PATIENT_DETAILS_LABELS.MRN}</HeaderItemWrapper>

          {items.map(item => (
            <FragmentItem
              key={item.id}
              item={item}
              onOpenModalWindow={onOpenModalWindow}
            />
          ))}
        </ListWrapper>
      ) : (
        <NoAvailableMRNs>No available MRN</NoAvailableMRNs>
      )}

      <div>
        <CustomizedButton
          color="primary"
          size="small"
          variant="outlined"
          testID="add-mrn-btn"
          onClick={() => onOpenModalWindow(null)}
          startIcon={<AddIcon />}
        >
          Add MRN
        </CustomizedButton>
      </div>

      <ModalWindowManageMRN
        isOpen={modalWindowManageMRN.isOpen}
        data={modalWindowManageMRN.data}
        patientName={patientName}
        consumerUuid={consumerUuid}
        onClose={onCloseModalWindow}
        container={ref.current}
      />
    </PatientEhrsListWrapper>
  );
};

const FragmentItem: FC<ItemProps> = ({item, onOpenModalWindow}) => {
  const showMessage = useCustomizedSnackbar();

  const onClickCopyIcon = useCallback(async () => {
    await navigator.clipboard.writeText(item.ehrLink);
    showMessage(<span>Copied!</span>, 'success');
  }, [item.ehrLink, showMessage]);

  const onClickPenIcon = useCallback(
    () => onOpenModalWindow(item),
    [item, onOpenModalWindow]
  );

  return (
    <Fragment key={item.id}>
      <EhrItemWrapper>{item.ehrName}</EhrItemWrapper>
      <MrnItemWrapper>
        <StyledLink target="_blank" href={item.ehrLink} underline="always">
          {item.mrn}
        </StyledLink>
        <div>
          <IconButton
            data-testid={`copy-mrn-btn-${item.id}`}
            size="small"
            onClick={onClickCopyIcon}
          >
            <CopyIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            size="small"
            data-testid={`edit-mrn-btn-${item.id}`}
            onClick={onClickPenIcon}
          >
            <PenIcon fontSize="inherit" />
          </IconButton>
        </div>
      </MrnItemWrapper>
    </Fragment>
  );
};

const ListWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: max-content auto;
  grid-gap: 8px;
  margin-bottom: 10px;
`;

const HeaderItemWrapper = styled.div`
  color: ${colors.grey801};
  line-height: 24px;
`;

const EhrItemWrapper = styled.div`
  color: ${colors.black};
  font-weight: 600;
  max-width: 200px;
  line-height: 24px;
`;

const MrnItemWrapper = styled.div`
  line-height: 24px;
  display: grid;
  grid-template-columns: max-content 50px;
  column-gap: 10px;
  max-width: 300px;
  word-break: break-all;
`;

const NoAvailableMRNs = styled.span`
  font-style: italic;
  margin-bottom: 10px;
  color: ${colors.grey801};
`;

const PatientEhrsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  max-width: 200px;
`;
