import React, {FC} from 'react';
import {Divider, Link, Typography} from '@material-ui/core';
import styled from 'styled-components';

import {Card} from '../../../shared/components/card';
import {InfoTooltip} from '../../../shared/components/infoTooltip';
import {dateFormat} from '../../../shared/constants/dateFormat';
import {FormattedMRNsDetailsType} from '../../../shared/constants/formattedMRNsDetailsType';
import {colors} from '../../../shared/styles/theme';
import {formatUTCDate} from '../../../shared/utils/formatDate';
import {getFullName} from '../../../shared/utils/getFullName';
import {ProviderInboxEventsType} from '../../inbox/constatnts/types';
import {PATIENT_DETAILS_LABELS} from '../constants/PatientDetailsLabels';
import {
  PatientsProfileLimitedDetailsFormattedType,
  PersonalAndAddressDetailsType,
} from '../constants/types';

import {PatientPageHeaderTitle} from './patientPageHeaderTitle';

interface Props {
  patientsProfileDetails: PatientsProfileLimitedDetailsFormattedType;
  eventDetails: ProviderInboxEventsType['event_details'] | null;
}

export const UploadRecordsHeader: FC<Props> = ({
  patientsProfileDetails,
  eventDetails,
}) => {
  const {publicDetails, personalDetails, mrnDetails} = patientsProfileDetails;

  const getPersonalDetails = (
    personalDetails: PersonalAndAddressDetailsType[]
  ): PersonalAndAddressDetailsType[] =>
    personalDetails.filter(
      (item: PersonalAndAddressDetailsType) =>
        item.title === PATIENT_DETAILS_LABELS.DATE_OF_BIRTH ||
        item.title === PATIENT_DETAILS_LABELS.GENDER
    );

  const isEventDataRangeExist =
    eventDetails?.date_from && eventDetails?.date_to;
  const isEventPatientCommentExist = eventDetails?.patient_comment;
  const isEventFirst = eventDetails?.is_maximum_date_range;

  return (
    <>
      <Card display="flex" flexWrap="nowrap" gridGap="40px">
        <PersonalDetailsWrapper>
          <PatientPageHeaderTitle
            variant="h6"
            status={patientsProfileDetails?.connection.status}
            title={getFullName(
              publicDetails.firstName,
              publicDetails.lastName,
              publicDetails.middleName
            )}
          />
          <DataWrapper>
            {getPersonalDetails(personalDetails).map(
              (item: PersonalAndAddressDetailsType) => (
                <div key={item.title}>
                  <TitleWrapper>{item.title}</TitleWrapper>
                  <TextWrapper>{item.value}</TextWrapper>
                </div>
              )
            )}
          </DataWrapper>
        </PersonalDetailsWrapper>
        <Divider orientation="vertical" flexItem />
        <MRNDetailsWrapper>
          <TextWrapper>{PATIENT_DETAILS_LABELS.MRN}</TextWrapper>
          <DataWrapper>
            {mrnDetails.length !== 0 ? (
              mrnDetails.map((item: FormattedMRNsDetailsType) => (
                <div key={item.id}>
                  <TitleWrapper>{item.ehrName}</TitleWrapper>
                  <Link target="_blank" href={item.ehrLink} underline="always">
                    <TextLink>{item.mrn}</TextLink>
                  </Link>
                </div>
              ))
            ) : (
              <NoAvailableMRNs>No available MRN</NoAvailableMRNs>
            )}
          </DataWrapper>
        </MRNDetailsWrapper>
      </Card>
      {isEventDataRangeExist || isEventPatientCommentExist ? (
        <Card display="flex" flexWrap="nowrap" gridGap="40px">
          <PatientNodeDataWrapper>
            {isEventDataRangeExist ? (
              <div>
                <TitleWrapper>Records requested</TitleWrapper>
                <TextWrapperWithInfoTooltip>
                  <TextWrapper>
                    From {formatUTCDate(eventDetails?.date_from, dateFormat)} to{' '}
                    {formatUTCDate(eventDetails?.date_to, dateFormat)}{' '}
                  </TextWrapper>
                  {isEventFirst ? (
                    <InfoTooltip description="If you have records older than 5 years please include them." />
                  ) : null}
                </TextWrapperWithInfoTooltip>
              </div>
            ) : null}

            {isEventPatientCommentExist ? (
              <div>
                <TitleWrapper>Patient&apos;s Note</TitleWrapper>
                <TextWrapper>{eventDetails?.patient_comment}</TextWrapper>
              </div>
            ) : null}
          </PatientNodeDataWrapper>
        </Card>
      ) : null}
    </>
  );
};

const PersonalDetailsWrapper = styled.div`
  flex-grow: 2;
  max-width: 40%;
`;

const MRNDetailsWrapper = styled.div`
  flex-grow: 5;
`;

const NoAvailableMRNs = styled.span`
  font-style: italic;
  margin-bottom: 10px;
  color: ${colors.grey801};
`;

const TextWrapper = styled(Typography)`
  font-weight: 600;
  color: ${colors.black};
  font-size: 14px;
  vertical-align: bottom;
  line-height: 18px;
`;

const TextWrapperWithInfoTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TextLink = styled(Typography)`
  font-weight: 600;
`;

const TitleWrapper = styled(Typography)`
  color: ${colors.grey801};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const DataWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fit, 150px);
  word-break: break-all;
`;

const PatientNodeDataWrapper = styled.div`
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 10px;
  grid-template-columns: 280px minmax(200px, auto);
  word-break: break-all;
`;
