/* eslint-disable indent */
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {
  ListItemKey,
  ListItemValue,
  ListTitleRow,
} from '../../../../../shared/components/list';
import {urls} from '../../../../../shared/utils/urls';
import {useGeneralListStyles} from '../constants/listTemplate';
import {ElectronicRecordAccordion} from '../electronicRecordAccordion';
import {ElectronicRecordAccordionWrapper} from '../electronicRecordAccordionWrapper';
import {ElectronicRecordCodesList} from '../electronicRecordCodesList';
import {ElectronicRecordGroupTitle} from '../electronicRecordGroupTitle';
import {ListWithFixedLabelsColumn} from '../electronicRecordListWithFixedLabelsColumn';
import {ElectronicRecordSectionWrapper} from '../electronicRecordSectionWrapper';
import {useSectionData} from '../hooks/useSectionData';
import {MedicationDispenseComponent} from '../medicationDispense';
import {MedicationStatementComponent} from '../medicationStatement';
import {StyledDivider} from '../styledDivider';
import {FetchedMedication} from '../types/medicationTypes';
import {CommonSectionProps} from '../types/types';
import {getCodeDescriptionBySystem} from '../utils/getDescriptionFromCodesBySystem';
import {formatDate} from '../utils/mappersUtils';
import {getMedications} from '../utils/medicationsMapper';

const LABELS_COLUMN_WIDTH = '150px';

const useStyles = makeStyles(() => ({
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    width: '100%',
    boxSizing: 'border-box',
  },
  contentBlockItemGrid: {
    gridTemplateColumns: '1fr 1px 1fr',
    padding: '24px',
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'red',
  },
}));

export const Medications: React.FC<CommonSectionProps> = ({
  consumerUuid,
  sectionLabel,
}) => {
  const classes = useStyles();
  const listTemplateClasses = useGeneralListStyles();

  const {data, error, isFetching, refetch} = useSectionData<
    FetchedMedication[]
  >(urls.getMedicationsOfConsolidatedMedicalRecord(consumerUuid));

  const medications = useMemo(() => getMedications(data), [data]);

  const hasNoData = medications.length === 0 && !isFetching && !error;

  const listsWrapperClasses = cx(
    listTemplateClasses.wrapper,
    classes.contentBlockItemGrid
  );

  return (
    <ElectronicRecordSectionWrapper
      sectionLabel={sectionLabel}
      isLoading={isFetching}
      hasNoData={hasNoData}
      hasError={!!error}
      refetch={refetch}
      title="Medications"
    >
      <ElectronicRecordAccordionWrapper>
        {medications?.map(medication => (
          <ElectronicRecordAccordion
            key={medication.id}
            title={getCodeDescriptionBySystem(medication?.codings)}
            content={
              <div className={classes.contentBlock}>
                {medication.requests?.map((request, index, requests) => (
                  <>
                    <div className={listsWrapperClasses}>
                      <div>
                        <ElectronicRecordGroupTitle title="Medication Prescribed" />
                        <ListWithFixedLabelsColumn
                          labelsColumnWidth={LABELS_COLUMN_WIDTH}
                        >
                          <ListItemKey>Status</ListItemKey>
                          <ListItemValue testId="request_status">
                            {request.status}
                          </ListItemValue>
                          <ListItemKey>Intent</ListItemKey>
                          <ListItemValue testId="request_intent">
                            {request.intent}
                          </ListItemValue>
                          <ListItemKey>Priority</ListItemKey>
                          <ListItemValue testId="request_priority">
                            {request.priority}
                          </ListItemValue>
                          <ListItemKey>Dosage Instruction</ListItemKey>
                          <ListItemValue testId="request_dosage_instruction">
                            {request.dosage_instruction}
                          </ListItemValue>
                          <ListItemKey>Requester</ListItemKey>
                          <ListItemValue testId="request_requester">
                            {request.requester}
                          </ListItemValue>
                          <ListItemKey>Course of Therapy</ListItemKey>
                          <div
                            className="listWrapper"
                            data-testid="request_course_of_therapy"
                          >
                            {request.course_of_therapy_codings ? (
                              request.course_of_therapy_codings.map(code => (
                                <ListItemValue key={code.code}>
                                  {code.display}
                                </ListItemValue>
                              ))
                            ) : (
                              <ListItemValue />
                            )}
                          </div>
                          <ListItemKey>Note</ListItemKey>
                          <div
                            className="listWrapper"
                            data-testid="request_note"
                          >
                            {request.notes ? (
                              request.notes.map(note => (
                                <ListItemValue key={note.recordedDate}>
                                  {note.text}
                                </ListItemValue>
                              ))
                            ) : (
                              <ListItemValue />
                            )}
                          </div>
                          <ListItemKey>Prescribed Date</ListItemKey>
                          <ListItemValue testId="request_prescribed_date">
                            {formatDate(request.authoring_date)}
                          </ListItemValue>
                          <ListItemKey>Data Last Updated</ListItemKey>
                          <ListItemValue testId="request_data_last_updated">
                            {formatDate(request.last_updated_at)}
                          </ListItemValue>
                        </ListWithFixedLabelsColumn>
                      </div>
                      <StyledDivider orientation="vertical" />
                      <div data-testid="request_codes_block">
                        <ElectronicRecordGroupTitle title="Codes" />
                        <ListWithFixedLabelsColumn
                          labelsColumnWidth={LABELS_COLUMN_WIDTH}
                        >
                          <ListTitleRow title="Medications" />
                          <ElectronicRecordCodesList
                            noCodeTestId="request_no_code_medication_codings"
                            codes={medication.codings ?? []}
                          />
                          <dt />
                          <dd />
                          <ListTitleRow title="Dose Form" />
                          <ElectronicRecordCodesList
                            noCodeTestId="request_no_code_medication_dose_form"
                            codes={medication.dose_form ?? []}
                          />
                          <ListItemKey>Amount</ListItemKey>
                          <ListItemValue testId="request_amount">
                            {medication?.amount}
                          </ListItemValue>
                          <ListTitleRow title="Reason" />
                          {request.reasons_for_request?.codes ? (
                            request.reasons_for_request?.codes.map(code => (
                              <ElectronicRecordCodesList
                                key={code.code}
                                noCodeTestId="request_no_code_medication_reason"
                                codes={[code]}
                              />
                            ))
                          ) : (
                            <ElectronicRecordCodesList
                              noCodeTestId="request_no_code_medication_reason"
                              codes={[]}
                            />
                          )}
                        </ListWithFixedLabelsColumn>
                      </div>
                    </div>
                    <StyledDivider />
                    <MedicationDispenseComponent
                      dispenses={request.dispenses ?? []}
                    />
                    <StyledDivider />
                    <MedicationStatementComponent
                      statements={request.statements ?? []}
                    />
                    {index !== requests.length - 1 && <StyledDivider />}
                  </>
                ))}
              </div>
            }
          />
        ))}
      </ElectronicRecordAccordionWrapper>
    </ElectronicRecordSectionWrapper>
  );
};
