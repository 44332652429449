import React, {FC} from 'react';
import {Box, BoxProps} from '@material-ui/core';
import styled from 'styled-components';

export const StyledCell: FC<BoxProps & {info: string | null}> = ({
  info,
  ...otherProps
}) => <StyledBox {...otherProps}>{info}</StyledBox>;

const StyledBox = styled(Box)`
  word-wrap: break-word;
`;
