import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function TrashIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-2 0 15 15" width="15" height="15">
      <path
        className="a"
        d="M7.328,11.375h.656a.328.328,0,0,0,.328-.328V5.141a.328.328,0,0,0-.328-.328H7.328A.328.328,0,0,0,7,5.141v5.906A.328.328,0,0,0,7.328,11.375Zm4.484-9.187H9.559L8.629.637A1.312,1.312,0,0,0,7.5,0H4.747A1.313,1.313,0,0,0,3.621.637l-.93,1.55H.438A.437.437,0,0,0,0,2.625v.438A.437.437,0,0,0,.437,3.5H.875v9.187A1.312,1.312,0,0,0,2.187,14h7.875a1.313,1.313,0,0,0,1.313-1.312V3.5h.438a.437.437,0,0,0,.438-.437V2.625A.437.437,0,0,0,11.813,2.187ZM4.7,1.392a.164.164,0,0,1,.141-.08H7.41a.164.164,0,0,1,.141.08l.477.8H4.222Zm5.364,11.3H2.188V3.5h7.875Zm-5.8-1.312h.656a.328.328,0,0,0,.328-.328V5.141a.328.328,0,0,0-.328-.328H4.266a.328.328,0,0,0-.328.328v5.906A.328.328,0,0,0,4.266,11.375Z"
      />
    </SvgIcon>
  );
}
