import React from 'react';
import {SvgIcon} from '@material-ui/core';

import {colors} from '../styles/theme';

export const StarOutlinedIcon: React.FC<any> = props => (
  <SvgIcon {...props} fill="none" width={20} height={20} viewBox="0 0 20 20">
    <path
      fill={colors.grey801}
      fillRule="evenodd"
      d="M11.627 8.134 10 4.111 8.373 8.134l-4.329.304 3.324 2.79-1.049 4.212L10 13.142l3.681 2.298-1.048-4.211 3.323-2.79-4.329-.305ZM7.542 6.99l-4.939.347a.65.65 0 0 0-.372 1.146l3.791 3.183-1.196 4.804a.65.65 0 0 0 .975.708l4.2-2.622 4.198 2.622a.65.65 0 0 0 .975-.708l-1.196-4.804 3.791-3.183a.65.65 0 0 0-.372-1.146l-4.938-.347-1.856-4.59a.65.65 0 0 0-1.205 0L7.542 6.99Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
