import {useCallback, useState} from 'react';

import {useAuth} from './useAuth';

export const useRecaptcha = () => {
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  const {initializeRecaptcha} = useAuth();

  return useCallback(
    containerId => {
      const verifier = recaptchaVerifier ?? initializeRecaptcha(containerId);
      setRecaptchaVerifier(verifier);
      return verifier;
    },
    [initializeRecaptcha, recaptchaVerifier]
  );
};
