import React, {FC} from 'react';
import styled from 'styled-components';

import AICareGuideProfileIcon from '../../../../../assets/icons/aICareGuideProfileIcon.svg';
import LoadingChat from '../../../../../assets/icons/loadingChat.svg';

export const CareGuideLoadingMessage: FC = () => (
  <ChatMessage>
    <ChatMessageHeader>
      <ChatMessageIcon src={AICareGuideProfileIcon} />
      Care Guide
    </ChatMessageHeader>
    <ChatMessageContent>
      <ChatMessageLoadingChat>
        <ChatMessageIcon
          src={LoadingChat}
          alt="loading-chat"
          width={70}
          height={10}
          data-testid="loading-message"
        />
      </ChatMessageLoadingChat>
    </ChatMessageContent>
  </ChatMessage>
);

const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const ChatMessageLoadingChat = styled.div`
  display: flex;
  width: 70px;
  height: 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
`;
const ChatMessageHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: -4px;
  gap: 16px;
`;

const ChatMessageIcon = styled.img<{
  width?: number;
  height?: number;
}>`
  width: ${props => `${props.width ?? 32}px`};
  height: ${props => `${props.height ?? 32}px`};
`;

const ChatMessageContent = styled.div`
  display: flex;
  height: 24px;
  padding-left: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin-left: 47px;
`;
