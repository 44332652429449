import React from 'react';
import PropTypes from 'prop-types';

import {Description} from '../../../shared/components/description';
import {InformationForm} from '../components/informationForm';

export const InviteUser = ({
  information,
  setInformation,
  setStepper,
  nextStep,
  prevStep,
}) => (
  <>
    <Description
      title="Invite admin portal user"
      description="Submit the following information to invite a new user to this admin portal"
    />
    <InformationForm
      setCurStep={setStepper}
      setInformationState={setInformation}
      informationState={information}
      nextStep={nextStep}
      prevStep={prevStep}
    />
  </>
);
InviteUser.propTypes = {
  information: PropTypes.objectOf(PropTypes.any),
  setInformation: PropTypes.func,
  setStepper: PropTypes.func,
  nextStep: PropTypes.number,
  prevStep: PropTypes.number,
};
