import React, {FC} from 'react';
import styled from 'styled-components';

import {months} from '../constants/months';
import {getFullYear} from '../utils/getFullYear';
import {getMonth} from '../utils/getMonth';
import {getYearsRange} from '../utils/getYearsRange';

import {NextButton, PreviousButton} from './buttons';
import {DateSelect} from './dateSelect';

type Props = {
  date: Date;
  handleChangeYear: (val: number) => void;
  handleChangeMonth: (val: number) => void;
  handleDecreaseMonth: VoidFunction;
  handleIncreaseMonth: VoidFunction;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  startYear: number;
};

const STEP = 1;

export const PickerHeader: FC<Props> = ({
  date,
  handleChangeYear,
  handleChangeMonth,
  handleDecreaseMonth,
  handleIncreaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  startYear,
}) => {
  const years = getYearsRange(getFullYear(new Date()), startYear, STEP);

  return (
    <PickerContainer>
      <PreviousButton
        onClick={handleDecreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <MonthSelectContainer>
        <DateSelect
          selectedValue={months[getMonth(date)]}
          handleChange={handleChangeMonth}
          items={months}
        />
      </MonthSelectContainer>
      <YearSelectContainer>
        <DateSelect
          selectedValue={years[getFullYear(date)]}
          handleChange={handleChangeYear}
          items={years}
        />
      </YearSelectContainer>
      <NextButton
        onClick={handleIncreaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </PickerContainer>
  );
};

const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const MonthSelectContainer = styled.div`
  width: 124px;
`;

const YearSelectContainer = styled.div`
  width: 80px;
`;
