import {atom} from 'recoil';

import {CareGuide, Message} from '../../../shared/interfaces/aIChat';

const defaultMessage: Message = {
  isUser: false,
  isFile: false,
  fileType: '',
  fileName: '',
  text: '',
};

export const chatState = atom<CareGuide>({
  key: 'chatState',
  default: {
    messages: [defaultMessage],
    chatId: null,
  },
});

export const postMessageisLoadingState = atom<boolean>({
  key: 'postMessageisLoadingState',
  default: false,
});
