import React, {FC, useCallback} from 'react';
import {CircularProgress, Divider, Typography} from '@material-ui/core';
import styled from 'styled-components';

import {NoDataPlaceholderDrawer} from '../../../shared/components/noDataPlaceholderDrawer';
import {CustomizedCard} from '../../../shared/customizedCard';
import {
  CardsTitles,
  OnDownloadDocumentFnType,
  ReturnDocumentType,
} from '../constants/types';
import {usePatientDocuments} from '../hooks/usePatientDocuments';
import {isSmthWentWrong} from '../utils/isSmthWentWrong';
import {noDocuments} from '../utils/noDocuments';

import {InsuranceCardsDrawer} from './insuranceCardsDrawer';
import {PatientDocumentContentSmallSizeDrawer} from './patientDocumentContentSmallSizeDrawer';

const CIRCULAR_PROGRESS_PROPS = {
  size: 40,
  thickness: 2,
};

type Props = {
  primaryInsuranceId: number | null;
  secondaryInsuranceId: number | null;
  consumerUuid: string | null;
  patientFirstName: string;
  patientLastName: string;
  handleToggleSetDocument: (
    documents: ReturnDocumentType[],
    title: CardsTitles
  ) => void;
  onDownloadDocuments: OnDownloadDocumentFnType;
  horizontal?: boolean;
};

export const PatientDocumentsContent: FC<Props> = ({
  primaryInsuranceId,
  secondaryInsuranceId,
  consumerUuid,
  patientFirstName,
  patientLastName,
  handleToggleSetDocument,
  onDownloadDocuments,
  horizontal = false,
}) => {
  const {
    data: documents,
    isLoading: isLoadingDocuments,
    isError: errorFetchPatientsDetails,
    refetch: refetchDocuments,
  } = usePatientDocuments(
    consumerUuid,
    primaryInsuranceId,
    secondaryInsuranceId,
    patientFirstName,
    patientLastName
  );

  const isError =
    errorFetchPatientsDetails ||
    (documents &&
      isSmthWentWrong([
        ...documents.driverLicense,
        ...documents.insurancePrimary,
        ...documents.insuranceSecondary,
      ]));

  const handleSetDriverLicense = useCallback(
    () =>
      documents &&
      handleToggleSetDocument(
        documents.driverLicense,
        CardsTitles.DRIVER_LICENSE_TITLE
      ),
    [documents, handleToggleSetDocument]
  );

  const handleRefetchDocuments = useCallback(
    async () => refetchDocuments(),
    [refetchDocuments]
  );

  const handleClickDriversLicense = useCallback(() => {
    if (documents && noDocuments(documents.driverLicense)) {
      return null;
    }
    if (isError) {
      handleRefetchDocuments();
    } else {
      handleSetDriverLicense();
    }
  }, [documents, handleRefetchDocuments, handleSetDriverLicense, isError]);

  return (
    <>
      {isLoadingDocuments ? (
        <>
          <Title>
            <strong>ID Card</strong>
          </Title>
          <CenteringWrapper>
            <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
          </CenteringWrapper>
          <Divider style={{marginTop: 20}} />
          <Title>
            <strong>Primary Insurance</strong>
          </Title>
          <CenteringWrapper>
            <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
          </CenteringWrapper>
          <Title>
            <strong>Secondary Insurance</strong>
          </Title>
          <CenteringWrapper>
            <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
          </CenteringWrapper>
        </>
      ) : null}
      {!isLoadingDocuments && documents ? (
        <>
          <Title>
            <strong>ID Card</strong>
          </Title>
          <CustomizedCard testId="driver_license_card" fixedSize={horizontal}>
            {!noDocuments(documents.driverLicense) ? (
              <PatientDocumentContentSmallSizeDrawer
                title={CardsTitles.DRIVER_LICENSE_TITLE}
                patientDocumentsInfo={documents.driverLicense}
                handleClick={handleClickDriversLicense}
              />
            ) : (
              <div>
                <NoDataPlaceholderDrawer noDataText="ID Card is not available" />
              </div>
            )}
          </CustomizedCard>
          <Divider style={{marginTop: 20}} />
          <InsuranceCardsDrawer
            primaryInsurance={documents?.insurancePrimary}
            secondaryInsurance={documents?.insuranceSecondary}
            setPatientDocument={handleToggleSetDocument}
            refetchDocuments={handleRefetchDocuments}
            onDownloadDocuments={onDownloadDocuments}
            horizontal={horizontal}
          />
        </>
      ) : null}
    </>
  );
};

const CenteringWrapper = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Typography)`
  font-weight: 700;
  margin-bottom: 12px;
  font-size: 14px;
`;
