import {locationInitialFieldsState} from '../../../shared/components/manuallyTypedAddressForm';
import {searchZipCodeFieldsInitialState} from '../../../shared/components/prefilledByZipCodeAddressForm';
import {LocationPreview} from '../../../shared/interfaces/location';

export const STATUS_PRIVATE = 'Private';
export const STATUS_PUBLIC = 'Public';
export const STATUS_OPTIONS = [STATUS_PRIVATE, STATUS_PUBLIC];
export const SELECT_EHR = 'Select EHR for this location';
export const SELECT_EHR_ID = 1;

export const defaultLocation: LocationPreview = {
  name: '',
  ehr_id: 1,
  ehr_link: '',
  ehr_name: '',
  havePhysicalAddress: true,
  primaryLocationManually: false,
  ...locationInitialFieldsState,
  ...searchZipCodeFieldsInitialState,
};

export const NEW_POLICY_STEPS = {
  CHOOSING_POLICY_TYPE: 0,
  FILLING_POLICY_RULES: 1,
};

export const STEPS = {
  CREATE_LOCATION: 'Create Location',
  ASSIGN_HEALTH_POLICY: 'Assign/Create Policy',
  CONFIRM_CREATION: 'Confirm Setup',
};

export const CREATE_LOCATION_STEPS = [
  STEPS.CREATE_LOCATION,
  STEPS.ASSIGN_HEALTH_POLICY,
  STEPS.CONFIRM_CREATION,
];

export const LOCATION_TOOLTIP_DESCRIPTION =
  'EHR link is used to connect a patients MRN with the associated EHR.';
