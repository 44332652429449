import React, {FC} from 'react';
import styled from 'styled-components';

import {FormattedMRNsDetailsType} from '../constants/formattedMRNsDetailsType';

import {CustomizedButton} from './customizedButton';
import {MedicalRecordNumber} from './medicalRecordNumber';
import {NoDataDash} from './noDataDash';
import {StyledPopover} from './styledPopover';

type Props = {
  MRNData: FormattedMRNsDetailsType[];
  id: string;
};

export const ContentMRN: FC<Props> = ({MRNData, id}) => {
  const MRNQuantity = MRNData.length;

  if (MRNQuantity === 0) {
    return (
      <ContentWrapper>
        <NoDataDash testId={`no-mrn-dash-${id}`} />
      </ContentWrapper>
    );
  }

  const firstElement = MRNData[0];

  return (
    <Container>
      <ContentWrapper>
        <div>
          <MedicalRecordNumber
            ehr={firstElement.ehrName}
            mrn={firstElement.mrn}
            link={firstElement.ehrLink}
          />
        </div>
        {MRNQuantity > 1 && (
          <StyledPopover
            id={id}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            content={
              <>
                {MRNData.slice(1).map((el: FormattedMRNsDetailsType) => (
                  <MedicalRecordNumber
                    key={el.id}
                    ehr={el.ehrName}
                    mrn={el.mrn}
                    link={el.ehrLink}
                  />
                ))}
              </>
            }
          >
            <CustomizedButton
              variant="outlined"
              color="primary"
              size="small"
              aria-describedby={id}
              testID={`showMRNDetails-${id}`}
            >
              {`+${MRNQuantity - 1} MRN`}
            </CustomizedButton>
          </StyledPopover>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
