import React from 'react';
import {Box, Link} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../styles/theme';

type Props = {
  ehr: string;
  mrn: string;
  link: string;
};

export const MedicalRecordNumber = ({ehr, mrn, link}: Props): JSX.Element => (
  <>
    <Box color={colors.grey801} fontSize="12px" lineHeight="16px">
      {ehr}
    </Box>
    <StyledLink href={link} underline="always" target="_blank" style={{}}>
      {mrn}
    </StyledLink>
  </>
);

const StyledLink = styled(Link)`
  word-break: break-all;
  width: 110px;
  display: inline-block;
`;
