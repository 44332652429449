export const formatReasons = (reasons: string[] | string): string => {
  if (reasons.length === 1 && reasons[0].startsWith('You meet')) {
    return '-';
  }

  return Array.isArray(reasons) ? reasons.join('; ') : reasons;
};

export const formatScreener = (screenerName: string): string =>
  screenerName || '-';

const formatAdmitted = (admitted: boolean): 'APPROVED' | 'DENIED' =>
  admitted ? 'APPROVED' : 'DENIED';

const formatCheckIn = (status: boolean): 'ARRIVED' | 'DENIED' =>
  status ? 'ARRIVED' : 'DENIED';

export const POLICY_TYPES_FORMATTER_STATUS_FIELD = {
  REGISTRATION: formatCheckIn,
  ENTRY: formatAdmitted,
};
