/* eslint-disable indent */
import {v4 as uuid} from 'uuid';

import {FetchedMedication, Medication} from '../types/medicationTypes';

import {formatDate} from './mappersUtils';

export const getMedications = (
  medications?: FetchedMedication[]
): Medication[] => {
  if (!medications) {
    return [];
  }

  return medications.map(({last_updated_at, ...medication}) => ({
    ...medication,
    last_updated_at: formatDate(last_updated_at),
    id: uuid(),
  }));
};
