import React, {FC, useRef, useState} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {issuerLogoState} from '../../../shared/atoms/issuerLogoState';
import {ButtonText} from '../../../shared/buttonText';
import {Button} from '../../../shared/components/button';
import {ConfirmModal} from '../../../shared/components/confirmModal';
import {LogoPreview} from '../../../shared/components/logoPreview';
import {RedirectToFirstAvailableRoute} from '../../../shared/components/redirectToFirstAvailableRoute';
import {LOGO_SIZE_LIMIT} from '../../../shared/constants/logoSizeLimit';
import {ROLES} from '../../../shared/constants/roles';
import {useConfirmNavigation} from '../../../shared/hooks/useConfirmNavigation';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {colors} from '../../../shared/styles/theme';
import {isRoleMatched} from '../../../shared/utils/user';
import {IssuerSettingsWrapper} from '../components/issuerSettingsWrapper';
import {useUploadIssuerLogo} from '../hooks/useUploadIssuerLogo';

const useStyles = makeStyles({
  descriptionBlock: {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '16px',
    color: `${colors.grey801}`,
  },
  listBlock: {
    paddingLeft: '16px',
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
});

type Props = {
  title: string;
};

export const UploadLogoPage: FC<Props> = ({title}) => {
  const classes = useStyles();

  const [previewUrlIssuerLogo, setPreviewUrlIssuerLogo] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {handleConfirmNavigation, routeIsBlocked, setRouteIsBlocked} =
    useConfirmNavigation(Boolean(previewUrlIssuerLogo));
  const {uploadIssuerLogoLoading, uploadIssuerLogo} = useUploadIssuerLogo();
  const showMessage = useCustomizedSnackbar();

  const issuerLogoUrl = useRecoilValue(issuerLogoState);
  const userState = useRecoilValue(currentUserState);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target as HTMLInputElement;
    const selectedFiles = fileInput.files;

    if (selectedFiles != null) {
      const currentFile = selectedFiles[0];

      if (currentFile.size > LOGO_SIZE_LIMIT) {
        showMessage(
          'Please upload a PNG file that is less than 2 Mb.',
          'error'
        );
        return;
      }

      if (currentFile.type !== 'image/png') {
        showMessage(
          'Wrong file format. Please upload your logo in PNG format.',
          'error'
        );
        return;
      }

      setSelectedFile(currentFile);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrlIssuerLogo(reader.result as string);
      };
      reader.readAsDataURL(currentFile);
    }
  };

  const handleSelectFileClick: React.MouseEventHandler<HTMLInputElement> = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const target = e.target as HTMLInputElement;
    if (target != null) {
      target.value = '';
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleOnClose = () => setRouteIsBlocked(false);

  const isNotAvailableUploadIssuerLogo = !isRoleMatched(userState?.roles, [
    ROLES.ISSUER_OWNER,
    ROLES.ISSUER_ADMIN,
    ROLES.ISSUER_SUPPORT_AGENT,
  ]);

  if (isNotAvailableUploadIssuerLogo) {
    return <RedirectToFirstAvailableRoute />;
  }

  return (
    <IssuerSettingsWrapper
      title={title}
      onSave={uploadIssuerLogo}
      selectedFile={selectedFile}
      isLoading={uploadIssuerLogoLoading}
      setPreviewUrlIssuerLogo={setPreviewUrlIssuerLogo}
      setSelectedFile={setSelectedFile}
    >
      <Box fontWeight={700} component="p">
        Upload Logo
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <Box marginBottom={2}>
            <LogoPreview
              imgSrc={previewUrlIssuerLogo || issuerLogoUrl}
              placeholder="Issuer Logo"
            />
          </Box>
          <label htmlFor="contained-button-file">
            <input
              accept="image/png"
              id="contained-button-file"
              type="file"
              hidden
              onChange={handleSelectFiles}
              onClick={handleSelectFileClick}
              ref={inputRef}
            />
            <Button
              variant="outlined"
              position="section"
              className={classes.button}
              onClick={handleButtonClick}
              disabled={uploadIssuerLogoLoading}
              testID="upload-or-replace-logo-btn"
            >
              <ButtonText issuerLogoUrl={issuerLogoUrl ?? selectedFile} />
            </Button>
          </label>
        </Grid>
        <Grid item className={classes.descriptionBlock}>
          <span>
            Please be sure that your logo meets the following recommendations:
          </span>
          <ul className={classes.listBlock}>
            <li>White icon with transparent background </li>
            <li>No larger than 2 Mb</li>
            <li>PNG file format</li>
            <li>The logo is square or has a horizontal orientation</li>
          </ul>
        </Grid>
      </Grid>
      <ConfirmModal
        title="Unsaved changes"
        isOpen={routeIsBlocked}
        variant="h6"
        onClose={handleOnClose}
        onConfirm={handleConfirmNavigation}
        confirmBtnText="Leave without saving"
        confirmBtnVariant="primary"
      >
        <ConfirmModalContentWrapper>
          <Typography component="p">
            Are you sure you want to leave the page without saving your changes?
          </Typography>
        </ConfirmModalContentWrapper>
      </ConfirmModal>
    </IssuerSettingsWrapper>
  );
};

const ConfirmModalContentWrapper = styled.div`
  margin: 16px 0 40px;
`;
