import {every, isEqual} from 'lodash';

import {ROLES} from '../../../shared/constants/roles';

export const editPermission = {
  [ROLES.ISSUER_OWNER]: [
    ROLES.ISSUER_OWNER,
    ROLES.ISSUER_ADMIN,
    ROLES.ISSUER_SUPPORT_AGENT,
  ],
  [ROLES.ISSUER_ADMIN]: [ROLES.ISSUER_ADMIN, ROLES.ISSUER_SUPPORT_AGENT],
  [ROLES.ISSUER_SUPPORT_AGENT]: [],
  [ROLES.RELYING_PARTY_OWNER]: [
    ROLES.RELYING_PARTY_OWNER,
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ROLES.SCREENER,
  ],
  [ROLES.RELYING_PARTY_ADMIN]: [
    ROLES.RELYING_PARTY_ADMIN,
    ROLES.RELYING_PARTY_RECORDS_REQUESTER,
    ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
    ROLES.SCREENER,
  ],
  [ROLES.RELYING_PARTY_RECORDS_REQUESTER]: [],
  [ROLES.RELYING_PARTY_RECORDS_VIEWER]: [],
  [ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER]: [],
  [ROLES.SCREENER]: [],
};

const supportAgentPermission = (currentUser, targetUser) =>
  isEqual(currentUser.roles, [ROLES.ISSUER_SUPPORT_AGENT]) &&
  currentUser.email === targetUser.email;

const getFullPermission = currentUser => {
  const fullPermissions = new Set();
  currentUser.roles.forEach(role =>
    editPermission[role].forEach(permittedRole =>
      fullPermissions.add(permittedRole)
    )
  );
  return fullPermissions;
};

export const hasEditPermission = (currentUser, targetUser) => {
  const fullPermissions = getFullPermission(currentUser);

  return (
    supportAgentPermission(currentUser, targetUser) ||
    every(targetUser.roles, role => fullPermissions.has(role))
  );
};

export const hasRoleEditPermission = (currentUser, targetUser) =>
  hasEditPermission(currentUser, targetUser) &&
  currentUser.email !== targetUser.email;
