import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';
import useSWR from 'swr';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {BreadCrumbNavigator} from '../../../shared/components/breadCrumbNavigator';
import {PARTY} from '../../../shared/constants/roles';
import {urls} from '../../../shared/utils/urls';
import {currentPortalUserState} from '../atoms/currentPortalUserState';
import {UserForm} from '../components/userForm';
import {formatNames} from '../utils/formatter';

const getUrl = (currentUser, portalUserId) =>
  currentUser.party === PARTY.ISSUER
    ? urls.issuerPortalUserDetail(currentUser.relyingParty.id, portalUserId)
    : urls.relyingPartyPortalUserDetail(
        currentUser.relyingParty.id,
        portalUserId
      );

export const PortalUserProfilePage = ({crumbs, title}) => {
  const {portalUserId} = useParams();
  const {state} = useLocation();
  const [editMode, setEditMode] = useState(state?.startEditing);

  const currentUser = useRecoilValue(currentUserState);
  const setCurrentPortalUser = useSetRecoilState(currentPortalUserState);
  const {data: portalUserDetails, revalidate} = useSWR(
    getUrl(currentUser, portalUserId)
  );
  const cancelEdit = useCallback(() => setEditMode(false), []);
  const startEditing = useCallback(() => setEditMode(true), []);

  useEffect(() => {
    if (portalUserDetails) {
      setCurrentPortalUser(formatNames(portalUserDetails));
    }
  }, [portalUserDetails, setCurrentPortalUser]);

  if (!portalUserDetails) {
    return null;
  }

  return (
    <Container>
      <BreadCrumbNavigator crumbs={crumbs} title={title} />
      <UserForm
        editedUser={portalUserDetails}
        revalidateEditedUser={revalidate}
        currentUser={currentUser}
        cancelEdit={cancelEdit}
        fromList={state?.startEditing}
        isEditMode={editMode}
        startEditing={startEditing}
      />
    </Container>
  );
};

PortalUserProfilePage.propTypes = {
  title: PropTypes.string,
  crumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

const Container = styled.div`
  width: 100%;
`;
