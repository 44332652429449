import {useCallback, useState} from 'react';

import {CONNECTION_STATUSES} from '../constants/patientStatuses';

type HookReturnType = {
  selectedConnectionStatuses: CONNECTION_STATUSES[];
  handleToggleConnectionStatuses: (val: CONNECTION_STATUSES) => void;
};

export const useSelectConnectionStatus = (): HookReturnType => {
  const [selectedConnectionStatuses, setSelectedConnectionStatuses] = useState<
    CONNECTION_STATUSES[]
  >([]);

  const handleToggleConnectionStatuses = useCallback(
    (selectedStatus: CONNECTION_STATUSES) => {
      if (selectedConnectionStatuses.includes(selectedStatus)) {
        setSelectedConnectionStatuses((prevState: CONNECTION_STATUSES[]) =>
          prevState.filter(
            (status: CONNECTION_STATUSES) => status !== selectedStatus
          )
        );
      } else {
        setSelectedConnectionStatuses((prevState: CONNECTION_STATUSES[]) => [
          ...prevState,
          selectedStatus,
        ]);
      }
    },
    [selectedConnectionStatuses]
  );

  return {
    selectedConnectionStatuses,
    handleToggleConnectionStatuses,
  };
};
