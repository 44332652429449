import React, {FC, useMemo, useState} from 'react';
import {CircularProgress} from '@material-ui/core';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';
import useSWR, {mutate} from 'swr';

import {
  ChatHistoryConversation,
  ChatHistoryConversationResponse,
} from '../../../../shared/interfaces/aIChat';
import {urls} from '../../../../shared/utils/urls';
import {selectedPatientState} from '../../atoms/selectedPatientState';

import useMessages from './hooks/useMessages';
import {transformHistory} from './utils/dateUtils';
import {ChatHistoryDeleteModal} from './chatHistoryDeleteModal';
import {ChatHistoryItem} from './chatHistoryItem';

const CIRCULAR_PROGRESS_PROPS = {
  size: 120,
  thickness: 2,
};

interface Props {
  selectChatFromHistory: (conversationId: string) => Promise<void>;
  setOpenHistory: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItem: string | null;
}

export const ChatHistory: FC<Props> = ({
  selectChatFromHistory,
  setOpenHistory,
  selectedItem,
}) => {
  const patient = useRecoilValue(selectedPatientState);
  const {onDeleteConversation, handleClickNewChat} = useMessages();
  const [deleteModalConversationId, setDeleteModalConversationId] =
    useState('');
  const [menuOpen, setMenuOpen] = useState<{[key: string]: boolean}>({});

  const {
    data: chatHistory,
    error,
    isValidating: isLoading,
  } = useSWR<ChatHistoryConversationResponse[]>(
    urls.getAIChatHistory(patient.patientUuid),
    {revalidateOnFocus: true, revalidateOnReconnect: true}
  );

  const transformedHistory = useMemo(
    () => (chatHistory ? transformHistory(chatHistory) : {}),
    [chatHistory]
  );

  const onDelete = async (id: string) => {
    await onDeleteConversation(id);
    mutate(urls.getAIChatHistory(patient.patientUuid));
    setDeleteModalConversationId('');
    if (id === selectedItem) {
      handleClickNewChat();
    }
  };

  const handleItemClick = (id: string) => {
    selectChatFromHistory(id);
    setOpenHistory(false);
  };

  const handleToggleMenu = (id: string) => {
    setMenuOpen(prevMenuOpen => ({[id]: !prevMenuOpen[id]}));
  };

  if (isLoading) {
    return (
      <CenteringWrapper data-testid="ai-chat-loading">
        <CircularProgress {...CIRCULAR_PROGRESS_PROPS} />
      </CenteringWrapper>
    );
  }

  if (error) {
    return (
      <HistoryWrapper data-testid="ai-chat-history">
        Error loading chat history
      </HistoryWrapper>
    );
  }

  return (
    <HistoryWrapper data-testid="ai-chat-history">
      {Object.keys(transformedHistory).map(date => (
        <HistoryItem key={date}>
          <DateTitle>{date}</DateTitle>
          {(transformedHistory[date] || []).map(
            (conversation: ChatHistoryConversation) => (
              <ChatHistoryItem
                key={conversation.conversationId}
                conversation={conversation}
                isSelectedItem={selectedItem === conversation.conversationId}
                isMenuOpen={menuOpen[conversation.conversationId]}
                handleToggleMenu={handleToggleMenu}
                handleItemClick={handleItemClick}
                setDeleteModalConversationId={setDeleteModalConversationId}
              />
            )
          )}
        </HistoryItem>
      ))}
      <ChatHistoryDeleteModal
        isOpen={deleteModalConversationId !== ''}
        onClose={() => setDeleteModalConversationId('')}
        onConfirm={() => onDelete(deleteModalConversationId)}
      />
    </HistoryWrapper>
  );
};

const HistoryWrapper = styled.div`
  display: flex;
  padding: 93px 40px 20px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 380px;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
`;

const HistoryItem = styled.div`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--text-third, #c5c5c5);
`;

const DateTitle = styled.div`
  display: flex;
  padding: 0px 8px 8px 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--text-secondary, #7a7a7a);
  font-weight: 400;
  flex: 1 0 0;
`;

const CenteringWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex: 1;
  height: -webkit-fill-available;
  width: inherit;
`;
