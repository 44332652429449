/* eslint-disable indent */
import {v4 as uuid} from 'uuid';

import {FetchedProcedure, Procedure} from '../types/procedureTypes';

import {formatDate} from './mappersUtils';

export const getProcedures = (procedures?: FetchedProcedure[]): Procedure[] => {
  if (!procedures) {
    return [];
  }

  return procedures.map(
    ({
      last_updated_at,
      body_site,
      notes,
      complications,
      reasons,
      performed,
      ...procedure
    }) => ({
      ...procedure,
      id: uuid(),
      performed: performed
        ? {
            ...performed,
            value_date: formatDate(performed?.value_date),
            value_period: {
              ...performed?.value_period,
              start: formatDate(performed?.value_period?.start),
              end: formatDate(performed?.value_period?.end),
            },
          }
        : null,
      reasons: reasons
        ? reasons.map(reason => ({
            id: uuid(),
            ...reason,
          }))
        : [],
      body_site: body_site
        ? body_site.map(body_site => ({
            id: uuid(),
            ...body_site,
          }))
        : [],
      complications: complications
        ? complications.map(complication => ({
            id: uuid(),
            ...complication,
          }))
        : [],
      last_updated_at: formatDate(last_updated_at),
      notes: notes
        ? notes.reduce<Procedure['notes']>((acc, curr) => {
            if (curr.text) {
              acc.push({
                ...curr,
                id: uuid(),
                recorded_date: formatDate(curr.recorded_date),
              });
            }

            return acc;
          }, [])
        : [],
    })
  );
};
