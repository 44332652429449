import React from 'react';
import {ButtonBase, ButtonBaseProps, withStyles} from '@material-ui/core';
import {CloseOutlined} from '@material-ui/icons';

import {colors} from '../styles/theme';

export const StyledCloseButton = withStyles(() => ({
  root: {
    height: '100%',
    border: `1px solid ${colors.backgroundPrimary}`,
    borderRadius: '4px',
    padding: '6px',
  },
}))(ButtonBase);

interface Props extends ButtonBaseProps {
  onClick: () => void;
}

export const CloseButton: React.FC<Props> = ({onClick, ...restProps}) => (
  <StyledCloseButton {...restProps} onClick={onClick}>
    <CloseOutlined color="primary" />
  </StyledCloseButton>
);
