import {useQueryClient} from '@tanstack/react-query';
import {useSetRecoilState} from 'recoil';

import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {
  fetchConnectPatient,
  fetchShareMedicalRecordsWithProvider,
} from '../../../shared/utils/fetchers';
import {isPatientConnectingState} from '../atoms/isPatientConnectingState';
import {isSharingMedicalRecordsWithProviderState} from '../atoms/isSharingMedicalRecordsWithProviderState';
import {isViewingRecordNotAvailableState} from '../atoms/isViewingRecordNotAvailableState';
import {patientDataForRequestState} from '../atoms/patientDataForRequestState';
import {
  CONNECTION_STATUSES,
  MEDICAL_RECORDS_INITIATION_TYPES,
} from '../constants/patientStatuses';
import {QueryKeyOnRevalidateType} from '../constants/types';

type Props = {
  patientName: string | null;
  patientUuid: string | null;
  firstName: string;
  invalidateKey: QueryKeyOnRevalidateType;
  relyingPartyId?: number;
  connectionStatus?: CONNECTION_STATUSES;
  isSharingMedicalRecords?: boolean;
  isUpdateRequested?: boolean;
  clientId?: number;
  connectionId?: number;
};

export const useSendRequest = ({
  patientName,
  patientUuid,
  firstName,
  invalidateKey,
  relyingPartyId,
  connectionStatus,
  isSharingMedicalRecords,
  isUpdateRequested,
  clientId,
  connectionId,
}: Props): VoidFunction => {
  const showMessage = useCustomizedSnackbar();
  const queryClient = useQueryClient();
  const setConnectPatientIsLoading = useSetRecoilState(
    isPatientConnectingState
  );
  const setPatientDataForRequest = useSetRecoilState(
    patientDataForRequestState
  );
  const setIsViewingRecordNotAvailableState = useSetRecoilState(
    isViewingRecordNotAvailableState
  );
  const setShareMedicalRecordsWithProviderIsLoading = useSetRecoilState(
    isSharingMedicalRecordsWithProviderState
  );

  const connectPatient = async () => {
    setConnectPatientIsLoading(true);

    try {
      await fetchConnectPatient({
        relying_party_id: relyingPartyId,
        client_id: clientId,
        consumer_uuid: patientUuid,
        patient_first_name: firstName,
      });
      await queryClient.invalidateQueries({
        queryKey: [invalidateKey],
      });
    } catch (error) {
      if (error instanceof Error) {
        showMessage(error?.message, 'error');
      } else {
        showMessage('Patient connection error.', 'error');
      }
    } finally {
      setConnectPatientIsLoading(false);
    }
  };

  const shareMedicalRecordsWithProvider = async () => {
    setShareMedicalRecordsWithProviderIsLoading(true);

    try {
      await fetchShareMedicalRecordsWithProvider({
        relying_party_id: relyingPartyId,
        client_id: clientId,
        consumer_uuid: patientUuid,
        connection_id: connectionId,
        type: MEDICAL_RECORDS_INITIATION_TYPES.CHANGE_CONNECTION,
      });
      await queryClient.invalidateQueries({
        queryKey: [invalidateKey],
      });
    } catch (error) {
      if (error instanceof Error) {
        showMessage(error?.message, 'error');
      } else {
        showMessage('Sharing medical records with provider error.', 'error');
      }
    } finally {
      setShareMedicalRecordsWithProviderIsLoading(false);
    }
  };

  const getCallBackFunc = () => {
    switch (connectionStatus) {
      case CONNECTION_STATUSES.PENDING:
        return null;
      case CONNECTION_STATUSES.CONNECTED:
        return !isSharingMedicalRecords
          ? () => shareMedicalRecordsWithProvider()
          : null;
      default:
        return () => connectPatient();
    }
  };

  return () => {
    if (connectionStatus === CONNECTION_STATUSES.PENDING || isUpdateRequested) {
      setIsViewingRecordNotAvailableState(true);
    } else {
      setPatientDataForRequest({
        isOpenModalWindow: true,
        data: {
          patientName,
          connectionStatus,
          isUpdateRequested,
          requestCallBack: getCallBackFunc(),
        },
      });
    }
  };
};
