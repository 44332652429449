import React from 'react';
import {Route, Switch, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';

import {NotFoundPage} from '../../../notFound';
import {PORTAL_PATIENTS_ROUTES} from '../../constants/routes';

import {ELECTRONIC_RECORD_SECTIONS as sections} from './constants/electronicRecordSections';
import {Allergies} from './sections/allergies';
import {Conditions} from './sections/conditions';
import {ElectronicRecordNoData} from './sections/electronicRecordNoData';
import {FamilyHistory} from './sections/familyHistory';
import {ImagingStudy} from './sections/imagingStudy';
import {Immunizations} from './sections/immunizations';
import {Medications} from './sections/medications';
import {Procedures} from './sections/procedures';
import {Providers} from './sections/providers';
import {ElectronicRecordMenu} from './electronicRecordMenu';

const useTabStyles = makeStyles(() => ({
  wrapper: {
    padding: '24px 0 12px 0',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '24px',
  },
  nav: {
    flexShrink: 0,
    minWidth: '200px',
  },
  content: {
    flexGrow: 1,
    minHeight: '50vh',
  },
}));

type Props = {
  testId: string;
  consumerUuid: string;
};

export const ElectronicRecordTab: React.FC<Props> = ({
  testId,
  consumerUuid,
}) => {
  const tabStyles = useTabStyles();
  const {section} = useParams<{
    section: string;
  }>();
  const getRouteBySection = (section: string): string =>
    PORTAL_PATIENTS_ROUTES.PATIENT_TAB.concat(`/electronic-record/${section}`);

  const {
    providers,
    medications,
    allergies,
    procedures,
    conditions,
    immunizations,
    images,
    familyHistory,
    clinicalNotes,
    clinicalVitals,
    labResults,
  } = sections;

  return (
    <div data-testid={testId} className={tabStyles.wrapper}>
      <div className={tabStyles.nav}>
        <ElectronicRecordMenu selectedSectionPath={section} />
      </div>
      <div data-testid={`${testId}-body`} className={tabStyles.content}>
        <Switch>
          <Route path={getRouteBySection(providers.path)}>
            <Providers
              sectionLabel={providers.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(medications.path)}>
            <Medications
              sectionLabel={medications.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(allergies.path)}>
            <Allergies
              sectionLabel={allergies.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(procedures.path)}>
            <Procedures
              sectionLabel={procedures.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(conditions.path)}>
            <Conditions
              sectionLabel={conditions.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(immunizations.path)}>
            <Immunizations
              sectionLabel={immunizations.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(images.path)}>
            <ImagingStudy
              sectionLabel={images.label}
              consumerUuid={consumerUuid}
            />
          </Route>

          <Route path={getRouteBySection(familyHistory.path)}>
            <FamilyHistory
              sectionLabel={familyHistory.label}
              consumerUuid={consumerUuid}
            />
          </Route>
          <Route path={getRouteBySection(clinicalNotes.path)}>
            <ElectronicRecordNoData
              sectionName={clinicalNotes.label}
              isInProgress
            />
          </Route>
          <Route path={getRouteBySection(clinicalVitals.path)}>
            <ElectronicRecordNoData
              sectionName={clinicalVitals.label}
              isInProgress
            />
          </Route>
          <Route path={getRouteBySection(labResults.path)}>
            <ElectronicRecordNoData
              sectionName={labResults.label}
              isInProgress
            />
          </Route>
          <Route path={getRouteBySection('*')}>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
