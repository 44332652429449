import {useCallback, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {fetchPhysicians} from '../utils/fetchers';

export const usePhysicians = (isRequestAllowed = true) => {
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [physiciansIsLoading, setPhysiciansIsLoading] = useState(false);
  const [physiciansData, setPhysiciansData] = useState([]);
  const [error, setError] = useState(false);
  const {relyingParty} = useRecoilValue(currentUserState);

  const getPhysicians = useCallback(
    async relyingPartyId => {
      setPhysiciansIsLoading(true);
      try {
        const physiciansList = isRequestAllowed
          ? await fetchPhysicians(relyingPartyId)
          : Promise.resolve([]);
        setPhysiciansData(physiciansList);
      } catch {
        setError(true);
      } finally {
        setPhysiciansIsLoading(false);
      }
    },
    [isRequestAllowed]
  );

  const handleSelectedPhysicians = physicians => {
    setSelectedPhysicians(physicians);
  };

  const removeSelectedPhysician = id => {
    setSelectedPhysicians(
      selectedPhysicians.filter(physician => physician.id !== id)
    );
  };

  const remappedPhysicians = useMemo(
    () =>
      physiciansData.length > 0
        ? physiciansData.map(physician => ({
            label: `${physician.firstName || ''} ${physician.lastName || ''}`,
            id: physician.id,
          }))
        : [],
    [physiciansData]
  );

  useEffect(() => {
    getPhysicians(relyingParty.id);
  }, [getPhysicians, relyingParty.id]);

  return {
    physicians: remappedPhysicians,
    physiciansIsLoading,
    selectedPhysicians,
    error,
    handleSelectedPhysicians,
    removeSelectedPhysician,
  };
};
