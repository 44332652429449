import React, {createRef, FC, forwardRef, useCallback} from 'react';
import ReactDatePicker from 'react-datepicker';
import {IconButton, Theme} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/styles';

import {CalendarIcon} from '../../icons/calendarIcon';
import {convertYearNumberToDate} from '../../utils/formatDate';
import {InputField} from '../form';

import {PickerHeader} from './components/pickerHeader';
import {DateRangeType} from './constants/types';

import 'react-datepicker/dist/react-datepicker.css';

const DEFAULT_YEAR_START = 2017;

const ClearSearchInputBtn = withStyles(() => ({
  root: {
    marginRight: '10px',
  },
}))(IconButton);

const CalendarBtn = withStyles(() => ({
  root: {
    marginRight: '10px',
  },
}))(CalendarIcon);

const useStyles = makeStyles((theme: Theme) => ({
  datePickerWrapper: {
    width: '100%',

    '& .react-datepicker-popper': {
      zIndex: 16,
    },

    '& .react-datepicker': {
      padding: '24px',
      boxShadow: '0 4px 10px rgba(139, 139, 139, 0.25)',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      borderColor: theme.palette.grey[400],
    },

    '& .react-datepicker__header': {
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: 'none',
      padding: '0 0 8px 0',
      '& .react-datepicker__day-name': {
        color: theme.palette.grey[800],
        height: '37px',
        width: '37px',
      },
    },

    '& .react-datepicker__input-container': {
      '& .MuiInputBase-root': {
        width: 'inherit',
      },
      '& .MuiInputAdornment-root .MuiIconButton-root': {
        display: 'none',
      },
    },

    '& .react-datepicker__input-container:hover': {
      '& .MuiInputAdornment-root .MuiIconButton-root': {
        display: 'flex',
      },
    },

    '& .react-datepicker__month': {
      marginRight: 0,
      marginLeft: 0,
      '& .react-datepicker__day': {
        height: '37px',
        width: '37px',
        lineHeight: '36.5px',
      },
      '& .react-datepicker__day--selected, & .react-datepicker__day:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        lineHeight: '36.5px',
      },
      '& .react-datepicker__day--disabled:hover, .react-datepicker__day--disabled':
        {
          backgroundColor: 'unset',
          color: theme.palette.grey[200],
          fontWeight: 'unset',
        },
    },

    '& .react-datepicker__day-names .react-datepicker__day-name:first-child, & .react-datepicker__week .react-datepicker__day:first-child':
      {
        marginLeft: 0,
      },

    '& .react-datepicker__day-names .react-datepicker__day-name:last-child, & .react-datepicker__week .react-datepicker__day:last-child':
      {
        marginRight: 0,
      },

    '& .react-datepicker__day--today, & .react-datepicker__day--keyboard-selected':
      {
        backgroundColor: theme.palette.primary.contrastText,
        fontWeight: 400,
        color: theme.palette.text.primary,
      },

    '& .react-datepicker__day--in-range, & .react-datepicker__day--today .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range':
      {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.main,
        fontWeight: 600,
      },

    '& .react-datepicker__day--selecting-range-start, & .react-datepicker__day--range-start, & .react-datepicker__day--selecting-range-end, & .react-datepicker__day--range-end':
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        lineHeight: '37px',
      },
  },
}));

type PropsType = {
  dateRange: DateRangeType;
  setDateRange: (val: DateRangeType) => void;
  isSelectRange?: boolean;
  placeholderText: string;
  handleOnBlur?: () => void;
  startYear?: number;
};

type RefType = HTMLInputElement;

const CustomInput = forwardRef<RefType, PropsType>(
  (
    {dateRange, setDateRange, placeholderText, handleOnBlur, ...props},
    _ref
  ) => {
    const onClear = useCallback(
      e => {
        e.stopPropagation();
        setDateRange([null, null]);
        if (handleOnBlur) {
          handleOnBlur();
        }
      },
      [setDateRange, handleOnBlur]
    );

    return (
      <InputField
        size="large"
        inputProps={{
          placeholder: placeholderText,
          readOnly: true,
          ref: _ref,
          'data-testid': 'date-range-picker-input',
        }}
        endAdornment={
          <InputAdornment position="end">
            {dateRange.at(0) || dateRange.at(1) ? (
              <ClearSearchInputBtn
                onClick={onClear}
                size="small"
                data-testid="picker-clear-date-btn"
              >
                <CancelIcon fontSize="small" />
              </ClearSearchInputBtn>
            ) : null}
            <CalendarBtn />
          </InputAdornment>
        }
        {...props}
      />
    );
  }
);

CustomInput.displayName = 'CustomInput';

export const DatePicker: FC<PropsType> = ({
  dateRange,
  setDateRange,
  isSelectRange = false,
  placeholderText,
  handleOnBlur,
  startYear = DEFAULT_YEAR_START,
}) => {
  const [startDate, endDate] = dateRange;
  const ref = createRef<HTMLInputElement>();
  const classes = useStyles();

  const minDate = convertYearNumberToDate(startYear);

  const customHeader = useCallback(
    ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }) => (
      <PickerHeader
        date={date}
        startYear={startYear}
        handleChangeYear={changeYear}
        handleChangeMonth={changeMonth}
        handleDecreaseMonth={decreaseMonth}
        handleIncreaseMonth={increaseMonth}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
      />
    ),
    [startYear]
  );

  const onChange = useCallback(
    (date: DateRangeType) =>
      Array.isArray(date) ? setDateRange(date) : setDateRange([date, null]),
    [setDateRange]
  );

  const handleOnCalendarClose = useCallback(() => {
    if (!startDate && handleOnBlur) {
      return handleOnBlur();
    }
  }, [handleOnBlur, startDate]);

  return (
    <div className={classes.datePickerWrapper}>
      <ReactDatePicker
        renderCustomHeader={customHeader}
        selectsRange={isSelectRange}
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        maxDate={new Date()}
        minDate={minDate}
        showDisabledMonthNavigation
        onChange={onChange}
        onCalendarClose={handleOnCalendarClose}
        customInput={
          <CustomInput
            dateRange={dateRange}
            setDateRange={setDateRange}
            placeholderText={placeholderText}
            handleOnBlur={handleOnBlur}
            ref={ref}
          />
        }
        fixedHeight
        showPopperArrow={false}
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
};
