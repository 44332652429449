import {useState} from 'react';

const DEFAULT_SEARCH_VALUE = '';
const DEFAULT_SEARCH_ERROR = null;
const MIN_SEARCH_CHARACTERS = 3;

type ReturnHookType = [
  searchValue: string,
  searchError: string | null,
  onClearSearch: () => void,
  onSearch: (val: string) => void,
];

export const useSearch = (): ReturnHookType => {
  const [searchValue, setSearchValue] = useState<string>(DEFAULT_SEARCH_VALUE);
  const [searchError, setSearchError] = useState<string | null>(
    DEFAULT_SEARCH_ERROR
  );

  const onClearSearch = () => {
    setSearchError(DEFAULT_SEARCH_ERROR);
    setSearchValue(DEFAULT_SEARCH_VALUE);
  };

  const onSearch = (value: string) => {
    const trimmedSearchValue = value.trim();
    setSearchError(DEFAULT_SEARCH_ERROR);

    if (
      trimmedSearchValue.length >= MIN_SEARCH_CHARACTERS ||
      trimmedSearchValue.length === 0
    ) {
      setSearchValue(trimmedSearchValue);
    } else {
      setSearchError('Please type three characters or more to search.');
    }
  };

  return [searchValue, searchError, onClearSearch, onSearch];
};
