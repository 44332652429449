import React from 'react';
import {makeStyles} from '@material-ui/styles';

import {colors} from '../../../../shared/styles/theme';

type Props = {
  title: string;
};

const useStyles = makeStyles(() => ({
  accordionTitle: {
    color: colors.grey600,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    margin: '0 0 20px 0',
  },
}));
export const ElectronicRecordGroupTitle: React.FC<Props> = ({title}) => {
  const classes = useStyles();

  return <p className={classes.accordionTitle}>{title}</p>;
};
