import React, {useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {isEmpty, isFunction} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSWR from 'swr';

import BackIcon from '../../../assets/icons/back.svg';
import {Button} from '../../shared/components/button';
import CustomizedInput from '../../shared/components/customizedInput';
import {useCustomizedSnackbar} from '../../shared/hooks/useCustomizedSnackbar';
import {colors} from '../../shared/styles/theme';
import {urls} from '../../shared/utils/urls';
import {successMessage} from '../constants/constants';
import {useHideRecaptcha} from '../hooks/useHideRecaptcha';
import {useMFAErrorHandling} from '../hooks/useMFAErrorHandling';
import {useRecaptcha} from '../hooks/useRecaptcha';

import {ResendPrompt} from './resendPrompt';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: 36,
      width: 380,
    },
    '& > .MuiButton-root': {
      width: 256,
      height: 32,
      fontSize: 12,
    },
  },
}));

const containerId = 'resend-button';

export const VerifySMS = ({
  error,
  onBack,
  loading,
  onSubmit,
  setLoading,
  resendCode,
  phoneNumber,
}) => {
  const classes = useStyles();
  const getRecaptcha = useRecaptcha();
  const showMessage = useCustomizedSnackbar();
  const handleMFAError = useMFAErrorHandling(error);
  const recaptchaVisible = useHideRecaptcha(setLoading);
  const {data: selfData} = useSWR(!phoneNumber ? urls.self : null);

  const [helperText, setHelperText] = useState('');
  const [resending, setResending] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const checkVerification = useCallback(() => {
    if (verificationCode.length === 0) {
      setHelperText('Please enter your verification code');
    } else if (verificationCode.length < 6) {
      setHelperText('Please enter a 6-digit verification code');
    }
  }, [verificationCode]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await onSubmit(verificationCode);
    } catch (e) {
      handleMFAError(e);
      setLoading(false);
    }
  }, [handleMFAError, onSubmit, setLoading, verificationCode]);

  const handleResendCode = useCallback(async () => {
    setResending(true);
    const verifier = getRecaptcha(containerId);
    try {
      await resendCode(verifier);
      showMessage(successMessage.RESEND_CODE, 'success');
    } catch (e) {
      handleMFAError(e);
    }
    setResending(false);
  }, [getRecaptcha, handleMFAError, resendCode, showMessage]);

  const onChangeVerificationCode = useCallback(e => {
    setVerificationCode(e.target.value);
    setHelperText('');
  }, []);

  const phone = (
    isEmpty(phoneNumber) ? selfData?.phone_number : phoneNumber
  )?.slice(-4);

  return (
    <>
      {isFunction(onBack) && (
        <BackButton src={BackIcon} onClick={onBack} data-testid="backIcon" />
      )}
      <Content>
        A text message with a one time verification code has been sent to your
        phone number ending in <strong>{phone}</strong>.
      </Content>
      <form className={classes.root}>
        <CodeInputField
          error={!!helperText}
          label="Verification code"
          variant="outlined"
          errorMessage={helperText}
          onBlur={checkVerification}
          placeholder="Verification code"
          onChange={onChangeVerificationCode}
          inputProps={{maxLength: 6, 'data-testid': 'verificationInput'}}
        />
        <Button
          type="submit"
          testID="verificationSubmitButton"
          onClick={handleSubmit}
          disabled={verificationCode.length < 6 || loading || resending}
        >
          SUBMIT
        </Button>
        <ResendPrompt
          disabled={resending && recaptchaVisible}
          onClick={handleResendCode}
          containerId={containerId}
        />
      </form>
    </>
  );
};

VerifySMS.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  phoneNumber: PropTypes.string,
  resendCode: PropTypes.func,
};

const CodeInputField = styled(CustomizedInput)`
  width: 100%;
  margin-bottom: 20px;
`;

const BackButton = styled.img`
  top: 24px;
  left: 24px;
  position: absolute;
  cursor: pointer;
`;

const Content = styled.p`
  font-size: 14px;
  color: ${colors.black};
  width: 380px;
  margin: 0 auto 15px;
`;
