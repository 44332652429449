import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

import Logo from '../../../../assets/icons/allClearIdLogoFilled.svg';
import QRLogo from '../../../../assets/icons/healthIDLocationQR.svg';
import {LockIcon} from '../../../shared/icons/lockIcon';
import {colors} from '../../../shared/styles/theme';
import {POLICY_TYPES_QR_CODE_SCAN_MESSAGE} from '../constants/defaultPolicy';

export const QRCodeDisplay = ({
  componentRef,
  location,
  relyingPartyName,
  testId = 'locationQRCode',
  ...props
}) => {
  const welcomeMessage =
    POLICY_TYPES_QR_CODE_SCAN_MESSAGE[location.policy.policy_type_id];
  return (
    <svg
      ref={componentRef}
      viewBox="0 0 250 330"
      data-testid={testId}
      {...props}
    >
      <foreignObject width="250" height="330">
        <Container>
          <MainContainer>
            <WelcomeMessage length={welcomeMessage.length}>
              {welcomeMessage}
            </WelcomeMessage>
            <QRCodeBlock
              value={location.dynamic_link}
              renderAs="svg"
              size={116}
              level="H"
              imageSettings={{
                src: QRLogo,
                height: 41,
                width: 41,
                excavate: false,
              }}
            />
            <ScanMessage>
              {relyingPartyName || `${location.relying_party_name} `}
              {location.name}
            </ScanMessage>
          </MainContainer>
          <FooterContainer>
            <FooterMessageContainer>
              <LockIconQRCode />
              <FooterMessage>Your Security is Guaranteed</FooterMessage>
            </FooterMessageContainer>
            <FooterLogo src={Logo} />
          </FooterContainer>
        </Container>
      </foreignObject>
    </svg>
  );
};

QRCodeDisplay.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    relying_party_name: PropTypes.string,
    policy: PropTypes.shape({
      policy_type_id: PropTypes.string,
    }),
    dynamic_link: PropTypes.string,
  }),
  testId: PropTypes.string,
  relyingPartyName: PropTypes.string,
  componentRef: PropTypes.objectOf(PropTypes.any),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 324px;
  width: 251px;
`;

const MainContainer = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const QRCodeBlock = styled(QRCode)`
  margin: 20px 0;
`;

const WelcomeMessage = styled.h1`
  max-width: 190px;
  max-height: 70px;
  color: ${colors.primary};
  font-size: ${({length}) =>
    length < 55 ? 19 : Math.sqrt((190 * 70) / length)}px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  text-align: center;
  max-width: 222px;
  word-wrap: break-word;
  white-space: pre-line;
`;

const ScanMessage = styled.p`
  fill: ${colors.black};
  text-anchor: middle;
  text-align: center;
  font-size: 10px;
  margin: 0;
  white-space: pre-line;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  flex-direction: column;
`;

const FooterMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const LockIconQRCode = styled(LockIcon)`
  fill: ${colors.black};
  width: 4px;
  height: 5px;
  margin-right: 1px;
`;

const FooterMessage = styled.p`
  color: ${colors.black};
  font-size: 6px;
  margin: 0;
`;

const FooterLogo = styled.img`
  width: 46px;
  height: 10px;
  margin-top: 2px;
`;
