import React, {useCallback, useMemo} from 'react';
import {
  Box,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  RadioGroup,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CancelIcon from '@material-ui/icons/Cancel';
import {useFormikContext} from 'formik';
import {isEmpty} from 'lodash';

import {RelyingPartyMainFields} from '../../main/relyingPartyInvitation/store';
import {
  onResetPrimaryLocationAddressFiled,
  onResetPrimaryLocationSubFormWithManuallyTypedAddress,
  onResetPrimaryLocationSubFormWithPrefilledByZipCodeAndNpiAddress,
} from '../../main/relyingPartyInvitation/utils/formik';
import {onlyNumbersPattern} from '../constants/patterns';
import {LocationAddress} from '../hooks/useLocationAddresses';
import {colors} from '../styles/theme';

import {Button} from './button';
import {
  AdornmentWrapper,
  INPUT_WIDTH_SIZES,
  InputFieldWithFormik,
  InputHelperText,
  InputLabel,
  InputRadioButton,
} from './form';
import {LocationFields} from './manuallyTypedAddressForm';
import {SearchFormTemplate} from './searchFormTemplate';

export type SearchZipCodeFields = {
  zipCodeAsync: string;
  primaryLocationAddress: string;
};

export const searchZipCodeFieldsInitialState: SearchZipCodeFields = {
  zipCodeAsync: '',
  primaryLocationAddress: '',
};

type Props = {
  addressNotFoundMessage: string;
  addresses: LocationAddress[] | null;
  addressesIsLoading: boolean;
  addressesHaveError: boolean;
  lastSearchedZipCode: string;
  enterLocationManuallyLinkMessage: string;
  onSubmitZipCode: () => Promise<LocationAddress[]>;
  onClearLocationAddresses: () => void;
  disabled?: boolean;
};

export const PrefilledByZipCodeAddressForm: React.FC<Props> = ({
  addresses,
  addressNotFoundMessage,
  addressesIsLoading,
  addressesHaveError,
  lastSearchedZipCode,
  enterLocationManuallyLinkMessage,
  onSubmitZipCode,
  onClearLocationAddresses,
  disabled,
}) => {
  const formik = useFormikContext<
    RelyingPartyMainFields & SearchZipCodeFields & LocationFields
  >();

  const onResetPrimaryLocationSubForm = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();

      onClearLocationAddresses();
      onResetPrimaryLocationSubFormWithManuallyTypedAddress(formik);
    },
    [formik, onClearLocationAddresses]
  );

  const setFirstAddressFromListAsDefaultValue = (
    addresses: LocationAddress[] = []
  ) => {
    if (addresses?.length !== 0) {
      formik.setFieldValue('primaryLocationAddress', '0', true);
    }
  };

  const zipCodeWasEdited =
    Boolean(formik.values.zipCodeAsync) &&
    Boolean(lastSearchedZipCode) &&
    formik.values.zipCodeAsync !== lastSearchedZipCode;

  const zipCodeInputHelperMessage = useMemo(() => {
    if (zipCodeWasEdited) {
      return 'Zip was edited. Search it again.';
    }

    if (addressesHaveError) {
      return 'Zip code is not found';
    }

    if (formik.touched.zipCodeAsync && formik.errors.zipCodeAsync) {
      return formik.errors.zipCodeAsync;
    }

    if (addresses) {
      return (
        <Box component="span" color={colors.green}>
          Zip code is valid
        </Box>
      );
    }

    return 'Type Zip code and press Search';
  }, [
    addresses,
    addressesHaveError,
    formik.errors.zipCodeAsync,
    formik.touched.zipCodeAsync,
    zipCodeWasEdited,
  ]);

  const isSearchedFormDisabled = disabled ?? addressesIsLoading;
  const isSearchedFormHasError =
    (formik.touched.zipCodeAsync && Boolean(formik.errors.zipCodeAsync)) ||
    addressesHaveError ||
    zipCodeWasEdited;

  return (
    <div>
      <FormControl variant="standard" error={isSearchedFormHasError}>
        <SearchFormTemplate
          label={
            <InputLabel
              htmlFor="zip-code-label"
              disabled={isSearchedFormDisabled}
              required
              shrink
            >
              Zip code
            </InputLabel>
          }
          input={
            <InputFieldWithFormik
              width="small"
              disabled={isSearchedFormDisabled}
              name="zipCodeAsync"
              inputProps={{
                'data-testid': 'async-zip-code-input',
              }}
              endAdornment={
                formik.values.zipCodeAsync.length > 0 ? (
                  <InputAdornment position="end">
                    <AdornmentWrapper>
                      <IconButton
                        data-testid="zip-clear-button"
                        disabled={isSearchedFormDisabled}
                        size="small"
                        onClick={() => {
                          onClearLocationAddresses();
                          onResetPrimaryLocationSubFormWithPrefilledByZipCodeAndNpiAddress(
                            formik
                          );
                        }}
                        edge="end"
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </AdornmentWrapper>
                  </InputAdornment>
                ) : null
              }
              onChange={(e: any) => {
                e.preventDefault();
                const {value} = e.target;

                if (
                  isEmpty(value) ||
                  (value.match(onlyNumbersPattern) &&
                    value.length >= 0 &&
                    value.length <= 9)
                ) {
                  formik.setFieldValue('zipCodeAsync', value);
                }
              }}
            />
          }
          button={
            <Button
              type="submit"
              data-testid="async-zip-code-search-button"
              onClick={async e => {
                e.preventDefault();
                onResetPrimaryLocationAddressFiled(formik);

                const addresses = await onSubmitZipCode();

                setFirstAddressFromListAsDefaultValue(addresses);
              }}
              disabled={
                !formik.values.zipCodeAsync ||
                Boolean(formik.errors.zipCodeAsync) ||
                isSearchedFormDisabled
              }
            >
              {addressesIsLoading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                'Search'
              )}
            </Button>
          }
          inputHelper={
            <InputHelperText
              shrink
              data-testid="async-zip-code-search-input-helper"
              disabled={isSearchedFormDisabled}
            >
              {zipCodeInputHelperMessage}
            </InputHelperText>
          }
        />
      </FormControl>

      {Array.isArray(addresses) && addresses.length === 0 ? (
        <Box
          maxWidth={INPUT_WIDTH_SIZES.default}
          border={1}
          borderRadius={8}
          padding={2}
          marginTop="10px"
          borderColor={colors.grey800}
          boxSizing="border-box"
        >
          <Box
            component="p"
            fontStyle="italic"
            marginTop={0}
            marginBottom="8px"
            color={colors.grey801}
            lineHeight="24px"
          >
            {addressNotFoundMessage}&nbsp;
          </Box>
          <Link
            href="/#"
            onClick={onResetPrimaryLocationSubForm}
            underline="always"
            data-testid="enter-primary-location-link-manualy-and-do-not-have-adresses"
          >
            {enterLocationManuallyLinkMessage}
          </Link>
        </Box>
      ) : (
        <Box marginTop="10px">
          <PrimaryLocationAddressesList
            addressLabel="Address"
            addresses={addresses}
          />
        </Box>
      )}

      {Array.isArray(addresses) && addresses.length !== 0 ? (
        <p>
          Can&apos;t find the correct address?{' '}
          <Link
            href="/#"
            onClick={onResetPrimaryLocationSubForm}
            underline="always"
            data-testid="enter-primary-location-link-manualy-and-have-adresses"
          >
            {enterLocationManuallyLinkMessage}
          </Link>
        </p>
      ) : null}
    </div>
  );
};

const PrimaryLocationAddressesList: React.FC<{
  addresses: LocationAddress[] | null;
  addressLabel: string;
}> = ({addresses, addressLabel}) => {
  const formik = useFormikContext<SearchZipCodeFields>();

  const setPrimaryLocationAddress = useCallback(
    (value: string) => {
      formik.setFieldValue('primaryLocationAddress', value, true);
    },
    [formik]
  );

  const getAddressString = useCallback((address: LocationAddress) => {
    const addressLine = address.address_1 ? `${address.address_1},` : '';
    const address2Line = address.address_2 ? `${address.address_2},` : '';

    return (
      `${addressLine}` +
      `${address.address_1 && address.address_2 ? ' ' : ''}` +
      `${address2Line}` +
      `${address.address_2 && address.city ? ' ' : ''}` +
      `${address.city}, ${address.state} ${address.postal_code}`
    );
  }, []);

  return addresses ? (
    <Fade in>
      <FormControl
        error={
          formik.touched.primaryLocationAddress &&
          !!formik.errors.primaryLocationAddress
        }
        required
      >
        <InputLabel shrink>{addressLabel}</InputLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={(e, value) => setPrimaryLocationAddress(value)}
        >
          {addresses.map((address, index) => (
            <FormControlLabel
              key={getAddressString(address)}
              value={index.toString()}
              control={<InputRadioButton />}
              checked={
                formik.values.primaryLocationAddress === index.toString()
              }
              label={getAddressString(address)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Fade>
  ) : null;
};
