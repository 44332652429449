import React, {FC, Fragment} from 'react';
import {Divider as MuiDivider} from '@material-ui/core';
import styled from 'styled-components';

import {
  List,
  ListItemKey,
  ListItemValue,
} from '../../../shared/components/list';
import {getFullName} from '../../../shared/utils/getFullName';
import {PatientsProfileDetailsFormattedType} from '../constants/types';

import {PatientAvatar} from './patientAvatar';
import {PatientEhrsList} from './patientEhrList';
import {PatientPageHeaderTitle} from './patientPageHeaderTitle';

type Props = {
  patientsProfileDetails: PatientsProfileDetailsFormattedType;
};

export const PatientPageHeader: FC<Props> = React.memo(
  ({patientsProfileDetails}) => {
    const {publicDetails, personalDetails, addressDetails, mrnDetails} =
      patientsProfileDetails;

    return (
      <ComponentWrapper>
        <div>
          <PatientAvatar
            firstName={publicDetails.firstName}
            lastName={publicDetails.lastName}
            src={publicDetails.src}
          />
        </div>
        <HeaderBodyWrapper>
          <PatientPageHeaderTitle
            status={patientsProfileDetails?.connection.status}
            title={getFullName(
              publicDetails.firstName,
              publicDetails.lastName,
              publicDetails.middleName
            )}
          />
          <DataWrapper>
            <PatientDetailsWrapper>
              <List>
                {personalDetails.map(({title, value}) => (
                  <Fragment key={title}>
                    <ListItemKey key={title}>{title}</ListItemKey>
                    <ListItemValue key={value}>{value}</ListItemValue>
                  </Fragment>
                ))}
              </List>
              <List>
                {addressDetails.map(({title, value}) => (
                  <Fragment key={title}>
                    <ListItemKey key={title}>{title}</ListItemKey>
                    <ListItemValue key={value}>{value}</ListItemValue>
                  </Fragment>
                ))}
              </List>
            </PatientDetailsWrapper>

            <StyledDivider orientation="vertical" flexItem />

            <PatientEhrsList
              consumerUuid={patientsProfileDetails?.publicDetails.consumerUuid}
              items={mrnDetails}
              patientName={getFullName(
                publicDetails.firstName,
                publicDetails.lastName,
                publicDetails.middleName
              )}
            />
          </DataWrapper>
        </HeaderBodyWrapper>
      </ComponentWrapper>
    );
  }
);

PatientPageHeader.displayName = 'PatientPageHeader';

const ComponentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  width: 100%;
`;

const DataWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 64px;

  @media (max-width: 1260px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
    width: 100%;
  }
`;

const PatientDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 64px;

  @media (max-width: 1570px) {
    grid-template-columns: auto;
    grid-row-gap: 10px;

    dt {
      width: 86px;
    }
  }
`;

const HeaderBodyWrapper = styled.div`
  width: 100%;
`;

const StyledDivider = styled(MuiDivider)`
  @media (max-width: 1260px) {
    height: 1px;
    width: 100%;
  }
`;
