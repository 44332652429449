import React, {useCallback} from 'react';
import {
  FormControlLabel,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {InputLabel, InputRadioButton} from '../../../shared/components/form';
import OverlayTableMessage from '../../../shared/components/overlayTableMessage';
import SearchNPI from '../../../shared/components/searchNPI';
import {NPI_STATUSES, NPI_TYPES} from '../../../shared/constants/npi';
import {
  USER_TYPES,
  USER_TYPES_LABELS,
} from '../../../shared/constants/userTypes';
import {colors} from '../../../shared/styles/theme';
import {fetchNpiDataAndValidate} from '../../../shared/utils/fetchers';
import {INITIAL_USER_PORTAL} from '../constants/userPortal';

const STATUS_TABLE_CELL_COLORS = {
  [NPI_STATUSES.ERROR]: colors.red,
  [NPI_STATUSES.SUCCESS]: colors.green,
  [NPI_STATUSES.DEFAULT]: colors.grey600,
};

export const SelectUserTypeForm = ({
  information,
  setInformation,
  dataNPI,
  isLoadingNPI,
  onSearchNPI,
  onClearDataNPI,
  npiState,
}) => {
  const onSearch = useCallback(
    npi => {
      onSearchNPI(fetchNpiDataAndValidate(npi, NPI_TYPES.USER_RELYING_PARTY));
    },
    [onSearchNPI]
  );
  const onClickNonPhysician = useCallback(() => {
    setInformation({
      ...INITIAL_USER_PORTAL,
      userType: USER_TYPES.NON_PHYSICIAN,
    });
    onClearDataNPI();
  }, [onClearDataNPI, setInformation]);

  const onClickPhysician = useCallback(() => {
    setInformation({
      ...INITIAL_USER_PORTAL,
      userType: USER_TYPES.PHYSICIAN,
    });
  }, [setInformation]);

  const onClickPhysicianSearch = useCallback(() => {
    setInformation({
      ...INITIAL_USER_PORTAL,
      userType: USER_TYPES.PHYSICIAN,
    });
    onClearDataNPI();
  }, [onClearDataNPI, setInformation]);

  return (
    <>
      <StyledFormControl>
        <InputLabel shrink>User type</InputLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            control={<InputRadioButton />}
            checked={information.userType === USER_TYPES.NON_PHYSICIAN}
            onClick={onClickNonPhysician}
            label={USER_TYPES_LABELS[USER_TYPES.NON_PHYSICIAN]}
          />
          <FormControlLabel
            control={<InputRadioButton />}
            checked={information.userType === USER_TYPES.PHYSICIAN}
            onClick={onClickPhysician}
            label={USER_TYPES_LABELS[USER_TYPES.PHYSICIAN]}
          />
        </RadioGroup>
      </StyledFormControl>
      {information.userType === USER_TYPES.PHYSICIAN && (
        <>
          <WrapperSearchNPI>
            <SearchNPI
              defaultValue={information.npi}
              npiState={{
                message: 'NPI should contain 10 digits',
                status: NPI_STATUSES.DEFAULT,
              }}
              onClear={onClickPhysicianSearch}
              isLoading={isLoadingNPI}
              onSearch={onSearch}
              placeholder='e.g. "XXXXXXXXXX"'
              testIdPrefix="portal-user"
            />
          </WrapperSearchNPI>
          <WrapperTable>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>NPI</TableCell>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataNPI?.npi && dataNPI?.first_name && dataNPI?.last_name ? (
                    <TableRow>
                      <TableCell>{dataNPI.npi}</TableCell>
                      <TableCell>{dataNPI.first_name}</TableCell>
                      <TableCell>{dataNPI.last_name}</TableCell>
                      <StatusTableCell status={npiState.status}>
                        {npiState.message}
                      </StatusTableCell>
                    </TableRow>
                  ) : (
                    <OverlayTableMessage
                      numberColumns={4}
                      overlayMessage={npiState.message}
                      isError={npiState.status === NPI_STATUSES.ERROR}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </WrapperTable>
        </>
      )}
    </>
  );
};

const StatusTableCell = styled(TableCell)`
  font-weight: bold;
  color: ${({status}) => STATUS_TABLE_CELL_COLORS[status]};
`;

const WrapperSearchNPI = styled.div`
  margin: 30px 0;
`;

const WrapperTable = styled.div`
  min-width: 50%;
  max-width: 70%;
  margin-bottom: 40px;
`;

const StyledFormControl = styled(FormControl)`
  display: block;
  margin-bottom: 40px;
`;

SelectUserTypeForm.propTypes = {
  information: PropTypes.objectOf(PropTypes.any),
  setInformation: PropTypes.func,
  dataNPI: PropTypes.objectOf(PropTypes.any),
  isLoadingNPI: PropTypes.bool,
  onSearchNPI: PropTypes.func,
  onClearDataNPI: PropTypes.func,
  npiState: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    status: PropTypes.oneOf([
      NPI_STATUSES.DEFAULT,
      NPI_STATUSES.SUCCESS,
      NPI_STATUSES.ERROR,
    ]),
  }),
};
