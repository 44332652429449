import React from 'react';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';

import {colors} from '../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: '12px',
    border: `1px solid ${colors.grey801}`,
    boxSizing: 'border-box',
    verticalAlign: 'middle',
  },
}));

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string;
}

export const NoDataDash: React.FC<Props> = ({className, testId, ...props}) => {
  const {root} = useStyles();

  return (
    <div
      {...props}
      className={cx(root, className)}
      data-testid={testId ?? 'no-data-dash'}
    />
  );
};
