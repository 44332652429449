import {makeStyles} from '@material-ui/styles';

export const useGeneralListStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'grid',
    gap: '24px',
    maxWidth: '1200px',
    boxSizing: 'border-box',
  },
}));
