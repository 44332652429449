import React from 'react';

import {ListItemKey, ListItemValue} from '../../../../shared/components/list';

import {ElectronicRecordDate} from './types/types';

export const ElectronicRecordDateByType: React.FC<{
  date: ElectronicRecordDate | null;
}> = ({date}) => {
  switch (date?.type) {
    case 'datetime':
      return (
        <>
          <ListItemKey>Date</ListItemKey>
          <ListItemValue testId="date_performed">
            {date?.value_date}
          </ListItemValue>
        </>
      );
    case 'period':
      return (
        <>
          <ListItemKey>Start Date</ListItemKey>
          <ListItemValue testId="start_performed_date">
            {date?.value_period?.start}
          </ListItemValue>
          <ListItemKey>End Date</ListItemKey>
          <ListItemValue testId="end_performed_date">
            {date?.value_period?.end}
          </ListItemValue>
        </>
      );
    case 'string':
      return (
        <>
          <ListItemKey>String</ListItemKey>
          <ListItemValue testId="performed_string">
            {date?.value_string}
          </ListItemValue>
        </>
      );
    case 'age':
      return (
        <>
          <ListItemKey>Age</ListItemKey>
          <ListItemValue testId="performed_age">
            {date?.value_age}
          </ListItemValue>
        </>
      );
    case 'range':
      return (
        <>
          <ListItemKey>Start</ListItemKey>
          <ListItemValue testId="performed_start">
            {date?.value_range?.start}
          </ListItemValue>
          <ListItemKey>End</ListItemKey>
          <ListItemValue testId="performed_end">
            {date?.value_range?.end}
          </ListItemValue>
          <ListItemKey>Unit</ListItemKey>
          <ListItemValue testId="performed_unit">
            {date?.value_range?.unit}
          </ListItemValue>
        </>
      );
    default:
      return (
        <>
          <ListItemKey>Date</ListItemKey>
          <ListItemValue testId="date_performed" />
        </>
      );
  }
};
