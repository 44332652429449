import {User} from '../../../shared/interfaces/user';
import {getDefaultProvider} from '../utils/getDefaultProvider';

import {
  EVENTS_CATEGORY_DROPDOWN,
  filterValuesType,
  TABLE_TYPE_ENUM,
} from './types';

export const notExpiredEventsCategory: EVENTS_CATEGORY_DROPDOWN[] = [
  EVENTS_CATEGORY_DROPDOWN.CONNECTION_DENIED,
  EVENTS_CATEGORY_DROPDOWN.DISCONNECTED,
  EVENTS_CATEGORY_DROPDOWN.SHARING_DENIED,
  EVENTS_CATEGORY_DROPDOWN.UPLOAD_IMAGES,
  EVENTS_CATEGORY_DROPDOWN.UPLOAD_TEXT_RECORDS,
];

export const getDefaultToDoFiltersValues = (
  currentUser: User | null
): filterValuesType => ({
  status: TABLE_TYPE_ENUM.TO_DO,
  eventCategories: [],
  provider: getDefaultProvider(currentUser),
  patientNames: [],
  dateRange: [null, null],
});

export const getDefaultCompletedFiltersValues = (
  currentUser: User | null
): filterValuesType => ({
  status: TABLE_TYPE_ENUM.COMPLETED,
  eventCategories: [],
  provider: getDefaultProvider(currentUser),
  patientNames: [],
  dateRange: [null, null],
});
