import React from 'react';
import {Box} from '@material-ui/core';
import {useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {InputFormControlWithFormik} from '../../../../shared/components/form';

export const OrganizationInformationForm = ({children, disabledFrom}) => {
  const formik = useFormikContext();

  return (
    <OrganizationInformationFormWrapper>
      <Box fontWeight={700} component="p">
        Set Organization Information
      </Box>
      {children}
      <FormWrapper>
        <InputsWrapper>
          <InputFormControlWithFormik
            disabled
            required
            id="name"
            name="name"
            label="Relying party name"
            testIdPrefix="name"
          />
          <InputFormControlWithFormik
            disabled={disabledFrom}
            required
            id="domain"
            name="domain"
            label="Domain"
            error={(formik.touched.domain && formik.errors.domain) || ''}
            description="Organization web domain. ( www.example.com )"
            testIdPrefix="domain"
          />
        </InputsWrapper>
      </FormWrapper>
    </OrganizationInformationFormWrapper>
  );
};

const OrganizationInformationFormWrapper = styled.div`
  margin-top: 40px;
`;

const InputsWrapper = styled.div`
  display: grid;
  row-gap: 4px;
`;

const FormWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`;

OrganizationInformationForm.propTypes = {
  children: PropTypes.node,
  disabledFrom: PropTypes.bool,
};
