import React from 'react';
import {FormLabel as MuiInputLabel} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = ({shrink, ...props}) => <MuiInputLabel {...props} />;

export const InputLabel = styled(Label)`
  ${props => {
    if (props.shrink) {
      return `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      `;
    }

    return '';
  }};
`;

Label.propTypes = {
  shrink: PropTypes.bool,
};
