import React from 'react';

interface AIChatProfileIconProps {
  initials: string;
}
export const AIChatProfileIcon: React.FC<AIChatProfileIconProps> = ({
  initials,
}) => (
  <svg
    data-testid="profile-icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#7A7A7A"
    />
    <text
      x="50%"
      y="55%"
      fill="#FAFAFA"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize="16"
    >
      {initials}
    </text>
  </svg>
);
