import {useCallback, useState} from 'react';

import {PARTY} from '../../../shared/constants/roles';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {
  fetchIssuerPortalUsersSorted,
  fetchRelyingPartyPortalUsersSorted,
} from '../utils/fetchers';

export const usePortalUsers = () => {
  const handleError = useErrorHandling();

  const [portalUsers, setPortalUsers] = useState([]);
  const [portalUsersIsLoading, setPortalUsersIsLoading] = useState(false);

  const dataMapper = data =>
    data.map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone_number: user.phone_number,
      roles: user.roles,
      npi: user.npi,
      status: user.status,
    }));

  const getPortalUsers = useCallback(
    async (organisationID, organisationType, searchValue) => {
      setPortalUsersIsLoading(true);

      try {
        const data =
          organisationType === PARTY.ISSUER
            ? await fetchIssuerPortalUsersSorted(organisationID)
            : await fetchRelyingPartyPortalUsersSorted(
                organisationID,
                searchValue
              );

        setPortalUsers(dataMapper(data));
      } catch (error) {
        await handleError(error);
      } finally {
        setPortalUsersIsLoading(false);
      }
    },
    [handleError]
  );

  return {portalUsers, portalUsersIsLoading, getPortalUsers};
};
