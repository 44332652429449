import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CustomizedTable} from '../../../shared/components/customizedTable';
import {Tab} from '../../../shared/components/tab';
import {colors} from '../../../shared/styles/theme';
import {policiesLocationsColumns} from '../utils/policiesLocationsColumns';
import {portalUserListColumns} from '../utils/portalUserListColumns';

import {NoItem} from './noItem';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTabs-root': {
      minHeight: '40px',
      textAlign: 'center',
    },
    '& .MuiTabs-fixed, .MuiTabs-flexContainer': {
      height: '40px',
    },
    '& .MuiTab-root': {
      minHeight: '40px',
      padding: '8px 15px 8px 16px',
    },
    '& .MuiTableContainer-root': {
      border: `1px solid ${colors.grey40}`,
      borderBottom: 0,
    },
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${colors.grey50}`,
    },
    '& .MuiTableCell-head': {
      paddingTop: '7px',
      paddingBottom: '6px',
    },
    '& .MuiTableCell-body': {
      paddingTop: '5px',
      paddingBottom: '3px',
      height: '24px',
    },
  },
}));

const Content = ({activeIndex, policiesAndLocations, url, portalUsers}) => {
  const history = useHistory();

  const iconMethodMap = {
    Detail: item => history.push(`${url}/${item.id}`),
  };

  if (activeIndex === 0) {
    if (policiesAndLocations?.length <= 0) {
      return <NoItem />;
    }
    return (
      <CustomizedTable
        columns={policiesLocationsColumns}
        data={policiesAndLocations}
        iconMethodMap={iconMethodMap}
      />
    );
  }
  if (portalUsers?.length <= 0) {
    return <NoItem />;
  }
  return <CustomizedTable columns={portalUserListColumns} data={portalUsers} />;
};

Content.propTypes = {
  activeIndex: PropTypes.number,
  policiesAndLocations: PropTypes.arrayOf(PropTypes.shape({})),
  url: PropTypes.string,
  portalUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

export const TabTable = ({url, policiesAndLocations, portalUsers}) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const handleTabChange = useCallback((_event, curActiveIndex) => {
    setActiveIndex(curActiveIndex);
  }, []);

  return (
    <TabTableContainer className={classes.root}>
      <TabContainer>
        <Tab
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
          tabs={['Policies & Locations', 'Portal user list']}
        />
      </TabContainer>
      <Content
        activeIndex={activeIndex}
        policiesAndLocations={policiesAndLocations}
        url={url}
        portalUsers={portalUsers}
      />
    </TabTableContainer>
  );
};

TabTable.propTypes = {
  url: PropTypes.string,
  policiesAndLocations: PropTypes.arrayOf(PropTypes.shape({})),
  portalUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

const TabTableContainer = styled.div`
  margin-top: 62px;
`;

const TabContainer = styled.div`
  margin: 0 60px 0 41px;
`;
