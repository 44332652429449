import {atom} from 'recoil';

import {
  LocationFields,
  locationInitialFieldsState,
} from '../../../shared/components/manuallyTypedAddressForm';
import {
  SearchZipCodeFields,
  searchZipCodeFieldsInitialState,
} from '../../../shared/components/prefilledByZipCodeAddressForm';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';

export type RelyingPartyMainFields = {
  providerType: PROVIDER_TYPES;
  ownerNpi: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  organizationNpi: string;
  name: string;
  domain: string;
  primaryLocation: string;
  primaryLocationManually: boolean;
};

export type RelyingParty = RelyingPartyMainFields &
  LocationFields &
  SearchZipCodeFields;

const blockPrefix = 'relyingPartyInvitation';
export const relyingPartyInitialState: RelyingParty = {
  providerType: PROVIDER_TYPES.INDIVIDUAL,
  ownerNpi: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  organizationNpi: '',
  name: '',
  domain: '',
  primaryLocation: '',
  primaryLocationManually: false,
  ...locationInitialFieldsState,
  ...searchZipCodeFieldsInitialState,
};

export const relyingPartyState = atom({
  key: `${blockPrefix}relyingPartyState`,
  default: relyingPartyInitialState,
});

export const stepState = atom({
  key: `${blockPrefix}stepState`,
  default: 0,
});
