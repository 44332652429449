import React, {FC, useCallback, useState} from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import styled from 'styled-components';

import {ChevronUpDownIcon} from '../../../icons/chevronUpDownIcon';
import {DateListType} from '../constants/types';

const StyledButton = withStyles((theme: Theme) => ({
  root: {
    height: '36px',
    width: '100%',
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.primary.main,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    marginBottom: '5px',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    fontSize: '14px',
  },
}))(Button);

type Props = {
  selectedValue: string;
  handleChange: (val: number) => void;
  items: DateListType;
};

export const DateSelect: FC<Props> = ({selectedValue, items, handleChange}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClick = useCallback(() => setIsOpen(prevState => !prevState), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box style={{width: 'inherit'}}>
        <StyledButton endIcon={<ChevronUpDownIcon />} onClick={onClick}>
          {selectedValue}
        </StyledButton>
        <Fade in={isOpen}>
          <MenuListContainer>
            <Paper>
              <StyledList>
                {Object.keys(items).map((item: string) => (
                  <ListItemFun
                    key={item}
                    item={item}
                    items={items}
                    selectedValue={selectedValue}
                    setIsOpen={setIsOpen}
                    handleChange={handleChange}
                  />
                ))}
              </StyledList>
            </Paper>
          </MenuListContainer>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};

const MenuListContainer = styled.div`
  position: absolute;
  width: inherit;
`;

const StyledListItem = styled(ListItem)`
  padding-bottom: 0;
  padding-top: 0;
`;

const StyledList = styled(List)`
  max-height: 350px;
  overflow-y: auto;
`;

interface ListItemFunProps {
  item: string;
  items: DateListType;
  selectedValue: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (val: number) => void;
}

function ListItemFun({
  item,
  items,
  selectedValue,
  setIsOpen,
  handleChange,
}: Readonly<ListItemFunProps>): JSX.Element {
  const onClick = useCallback(() => {
    setIsOpen(false);
    handleChange(Number(item));
  }, [handleChange, item, setIsOpen]);

  return (
    <StyledListItem
      button
      key={item}
      selected={items[Number(item)] === selectedValue}
      onClick={onClick}
    >
      <ListItemText primary={items[Number(item)]} />
    </StyledListItem>
  );
}
