import React from 'react';
import {Link} from 'react-router-dom';
import {List, ListItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {colors} from '../../../../shared/styles/theme';

import {ELECTRONIC_RECORD_SECTIONS} from './constants/electronicRecordSections';

const useListItemStyles = makeStyles(() => ({
  root: {
    padding: '8px 24px',
    borderRadius: '4px',
    backgroundColor: colors.grey1000,
    color: colors.grey600,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    marginBottom: '4px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  selected: {
    backgroundColor: colors.grey250,
    fontWeight: 600,
  },
  dividerText: {
    fontSize: '12px',
    fontWeight: 600,
    marginLeft: 24,
    marginTop: 24,
    marginBottom: 24,
    color: colors.primary,
  },
}));

type Props = {
  selectedSectionPath: string;
};
export const ElectronicRecordMenu: React.FC<Props> = ({
  selectedSectionPath,
}) => {
  const listItemStyles = useListItemStyles();

  return (
    <List component="nav" disablePadding>
      {[
        ELECTRONIC_RECORD_SECTIONS.allergies,
        ELECTRONIC_RECORD_SECTIONS.conditions,
        ELECTRONIC_RECORD_SECTIONS.familyHistory,
        ELECTRONIC_RECORD_SECTIONS.images,
        ELECTRONIC_RECORD_SECTIONS.immunizations,
        ELECTRONIC_RECORD_SECTIONS.medications,
        ELECTRONIC_RECORD_SECTIONS.procedures,
        ELECTRONIC_RECORD_SECTIONS.providers,
        ELECTRONIC_RECORD_SECTIONS.clinicalNotes,
        ELECTRONIC_RECORD_SECTIONS.clinicalVitals,
        ELECTRONIC_RECORD_SECTIONS.labResults,
      ].map(({id, label, path}) => (
        <>
          {label === 'Clinical Notes' && (
            <div className={listItemStyles.dividerText}>IN PROGRESS</div>
          )}
          <ListItem
            component={Link}
            to={path}
            key={id}
            data-testid={`electronic-record-menu-item-${path}`}
            selected={selectedSectionPath === path}
            button
            classes={{
              root: listItemStyles.root,
              selected: listItemStyles.selected,
            }}
          >
            {label}
          </ListItem>
        </>
      ))}
    </List>
  );
};
