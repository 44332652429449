import React from 'react';
import {IconButton, IconButton as MuiIconButton} from '@material-ui/core';
import styled from 'styled-components';

import CloseIconChat from '../../../../assets/icons/closeIconChat.svg';
import Error from '../../../../assets/icons/error.svg';
import FileIcon from '../../../../assets/icons/fileIcon.svg';
import LoadFileChatIcon from '../../../../assets/icons/loadFileChatIcon.svg';
import {CloseIcon} from '../../../shared/icons/closeIcon';
import {ALLOWED_FILE_TYPE} from '../../../shared/interfaces/aIChat';
import {colors} from '../../../shared/styles/theme';

import {SelectedFileMedicalRecords} from './aIChat/chatFooter';

type Props = {
  selectedFile: SelectedFileMedicalRecords | File;
  fileLoading: boolean;
  errorInFile?: string | null;
  handleRemoveFile: () => void;
  fileContent?: string | ArrayBuffer | null;
  medicalFileSelected?: boolean;
};
export const getExtension = (value: string): string => {
  const exactMatch = (
    Object.keys(ALLOWED_FILE_TYPE) as Array<keyof typeof ALLOWED_FILE_TYPE>
  ).find(key => ALLOWED_FILE_TYPE[key] === value);
  if (exactMatch) {
    return exactMatch;
  }
  return value;
};

interface RenderPreviewProps {
  selectedFile: SelectedFileMedicalRecords | File;
  errorInFile?: string | null;
  handleRemoveFile: () => void;
  fileContent?: string | ArrayBuffer | null;
  medicalFileSelected?: boolean;
}

const renderPreview = ({
  selectedFile,
  errorInFile,
  handleRemoveFile,
  fileContent,
  medicalFileSelected = false,
}: RenderPreviewProps) => {
  if (errorInFile) {
    return (
      <ErrorWrapper>
        <AlertImageIcon width="20" height="20" src={Error} />
        <FilePreviewWrapper>
          <FileErrorText>{errorInFile}</FileErrorText>
        </FilePreviewWrapper>
        <CloseButtonWrapper>
          <IconButtonForError
            style={{
              width: '20px',
              height: '20px',
              right: '10px',
            }}
            onClick={handleRemoveFile}
          >
            <CloseIcon fontSize="20px" viewBox="0 0 28 28" color={colors.red} />
          </IconButtonForError>
        </CloseButtonWrapper>
      </ErrorWrapper>
    );
  }
  if (!medicalFileSelected && selectedFile.type?.startsWith('image/')) {
    return (
      <ImagePreview>
        <ImgPreview
          data-testid="image-preview"
          src={fileContent as string}
          alt="file preview"
        />
        <IconButton
          style={{
            position: 'relative',
            right: '32px',
            bottom: '9px',
          }}
          onClick={handleRemoveFile}
        >
          <CloseImageIcon src={CloseIconChat} />
        </IconButton>
      </ImagePreview>
    );
  }
  return (
    <>
      <FilePreviewWrapper>
        <FileIconContainer data-testid="file-chat">
          <ChatMessageIcon
            marginRight={0}
            width={32}
            height={32}
            src={FileIcon}
          />
          <FilePreviewIcon>{getExtension(selectedFile.type)}</FilePreviewIcon>
        </FileIconContainer>

        <FilePreviewText>{selectedFile.name}</FilePreviewText>
      </FilePreviewWrapper>
      <IconButton
        style={{
          position: 'relative',
          bottom: '14px',
          right: '0px',
          paddingRight: '4px',
        }}
        onClick={handleRemoveFile}
      >
        <CloseImageIcon src={CloseIconChat} />
      </IconButton>
    </>
  );
};
export const ChatFooterPreviewFiles: React.FC<Props> = ({
  selectedFile,
  fileLoading,
  errorInFile,
  handleRemoveFile,
  fileContent,
  medicalFileSelected = false,
}) => (
  <FilePreviewContainer
    id="file-preview-container"
    style={{
      opacity: fileLoading ? '0.6' : '1',
      maxHeight: '100px',
    }}
  >
    <FilePreview
      style={{
        width: '100%',
      }}
    >
      {fileLoading && (
        <LoadingFileIcon
          data-testid="loading-file-icon"
          src={LoadFileChatIcon}
        />
      )}
      {renderPreview({
        selectedFile,
        errorInFile,
        handleRemoveFile,
        fileContent,
        medicalFileSelected,
      })}
    </FilePreview>
  </FilePreviewContainer>
);

const LoadingFileIcon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 126px;
  top: 27px;
`;

const CloseImageIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const AlertImageIcon = styled.img`
  width: 20px;
  height: 20px;
  padding: 2px 0px 0px 0px;
  gap: 0px;
  opacity: 0px;
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 30px;
`;

const FilePreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

const FilePreviewText = styled.div`
  width: 191px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
`;

const FileErrorText = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 208px;
  height: 20px;
  color: ${colors.red};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  height: Fill (28px) px;
  padding: 10px 8px 10px 8px;
  gap: 8px;
  border-radius: var(--radiusradius-xs);
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: ${colors.red12};
  border: 1px solid ${colors.red26};
`;
const ImgPreview = styled.img`
  max-width: 252px;
  max-height: 100px;
  border-radius: 12px;
`;

const FilePreviewContainer = styled.div`
  width: 100%;
`;

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

const ChatMessageIcon = styled.img<{
  width?: number;
  height?: number;
  marginRight?: number;
}>`
  width: ${props => `${props.width ?? 32}px`};
  height: ${props => `${props.height ?? 32}px`};
  flex-shrink: 0;
  margin-right: ${props => `${props.marginRight ?? 16}px`};
`;

const FileIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const FilePreviewIcon = styled.div`
  position: absolute;
  bottom: 4px;
  color: ${colors.white};
  font-family: 'Open Sans';
  font-size: 8px;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconButtonForError = styled(MuiIconButton)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const CloseButtonWrapper = styled.div`
  top: 5px;
  position: relative;
`;
