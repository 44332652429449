import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {INPUT_WIDTH_SIZES, INPUT_WIDTH_TYPES} from './InputField';
import {InputFieldWithFormik} from './InputFieldWithFormik';
import {InputHelperText} from './InputHelperText';
import {InputLabel} from './InputLabel';

export const InputFormControlWithFormik = ({
  id,
  error,
  name,
  label,
  width,
  disabled,
  required,
  placeholder,
  description,
  shrink = true,
  testIdPrefix = 'default',
  fullWidth = false,
  onKeyPress,
}) => (
  <FormControl
    disabled={disabled}
    required={required}
    error={Boolean(error)}
    fullWidth={fullWidth}
  >
    <InputLabel data-testid={`${testIdPrefix}-label`} htmlFor={id}>
      {label}
    </InputLabel>
    <InputContainer width={width}>
      <InputFieldWithFormik
        id={id}
        name={name}
        width={width}
        placeholder={placeholder}
        inputProps={{
          'data-testid': `${testIdPrefix}-input`,
        }}
        onKeyPress={onKeyPress}
      />
      <InputHelperText
        data-testid={`${testIdPrefix}-input-helper`}
        shrink={shrink}
      >
        {error || description}
      </InputHelperText>
    </InputContainer>
  </FormControl>
);

const InputContainer = styled.div.attrs(props => ({width: props.width}))`
  width: ${props => {
    switch (props.width) {
      case INPUT_WIDTH_TYPES.AUTO:
        return INPUT_WIDTH_SIZES.auto;
      case INPUT_WIDTH_TYPES.SMALL:
        return INPUT_WIDTH_SIZES.small;
      default:
        return INPUT_WIDTH_SIZES.default;
    }
  }};
`;

InputFormControlWithFormik.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.string,
  shrink: PropTypes.bool,
  testIdPrefix: PropTypes.string,
  fullWidth: PropTypes.bool,
  onKeyPress: PropTypes.func,
};
