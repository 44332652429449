import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {FormControl} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {
  InputField,
  InputHelperText,
  InputLabel,
} from '../../../shared/components/form';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {fetcher} from '../../../shared/utils/fetcher';
import {urls} from '../../../shared/utils/urls';
import {PolicyDetail} from '../../healthPolicies/components/policyDetail';
import {PLACEHOLDER_ENTRY_POLICY} from '../../healthPolicies/constants/defaultPolicy';
import {MainHeader} from '../../layout/components/mainHeader';

export const ConfirmCreation = ({
  onBack,
  addingPolicy,
  location,
  associatedPolicies,
}) => {
  const handleError = useErrorHandling();
  const showMessage = useCustomizedSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const history = useHistory();
  const user = useRecoilValue(currentUserState);

  const createLocationWithoutPhysicalAddress = useCallback(
    async policy => {
      if (location.ehr_id === 0) {
        await fetcher(urls.locations, 'POST', {
          name: location.name,
          policy_id: policy.id,
        });
      } else {
        await fetcher(urls.locations, 'POST', {
          name: location.name,
          ehr_id: location.ehr_id,
          policy_id: policy.id,
        });
      }
    },
    [location.ehr_id, location.name]
  );

  const createLocationWithPhysicalAddress = useCallback(
    async ({id}, {addressOne, addressTwo, city, state, zipCode}) => {
      const addressData = {
        address_line_1: addressOne,
        address_line_2: addressTwo,
        postal_code: zipCode,
        city,
        state,
      };

      if (location.ehr_id === 0) {
        await fetcher(urls.physicalLocations, 'POST', {
          name: location.name,
          policy_id: id,
          relying_party_id: user.relyingParty.id,
          ...addressData,
        });
      } else {
        await fetcher(urls.physicalLocations, 'POST', {
          name: location.name,
          ehr_id: location.ehr_id,
          policy_id: id,
          relying_party_id: user.relyingParty.id,
          ...addressData,
        });
      }
    },
    [location.ehr_id, location.name, user.relyingParty.id]
  );

  const handleCreate = useCallback(async () => {
    setButtonDisabled(true);
    try {
      let policy = associatedPolicies.selected;
      const policyPlaceholder =
        associatedPolicies?.toAdd?.policy_type_id === 'REGISTRATION'
          ? PLACEHOLDER_ENTRY_POLICY
          : {};

      if (addingPolicy) {
        policy = await fetcher(urls.policies, 'POST', {
          ...associatedPolicies.toAdd,
          ...policyPlaceholder,
          relying_party_id: user.relyingParty.id,
        });
      }

      if (location.havePhysicalAddress) {
        await createLocationWithPhysicalAddress(policy, location);
      } else {
        await createLocationWithoutPhysicalAddress(policy);
      }

      showMessage(
        `You successfully created a location named ${location.name} with a policy of ${policy.name}.`,
        'success'
      );

      history.push('/locations');
    } catch (err) {
      handleError(err);
      setButtonDisabled(false);
    }
  }, [
    addingPolicy,
    associatedPolicies.selected,
    associatedPolicies.toAdd,
    createLocationWithPhysicalAddress,
    createLocationWithoutPhysicalAddress,
    handleError,
    history,
    location,
    showMessage,
    user.relyingParty.id,
  ]);

  return (
    <>
      <HeaderContainer>
        <MainHeader
          title="Confirm Setup"
          description="Upon clicking confirm, the following location will be created with the corresponding policy as designated below."
        />
      </HeaderContainer>

      <Content>
        <LocationDetail>
          <LocationTitle>Location Information</LocationTitle>
          <DataDisplayField
            label="Location Name"
            value={`${user.relyingParty.name} ${location.name}`}
            size="large"
          />
          <LocationContainer>
            <DataDisplayField
              label="Location EHR"
              value={location.ehr_name}
              size="small"
            />
            {location.ehr_link !== '' && (
              <DataDisplayField
                label="EHR Link for Patient Search"
                value={location.ehr_link}
                size="large"
              />
            )}
          </LocationContainer>
        </LocationDetail>
        {location.havePhysicalAddress ? (
          <OwnerInformationWrapper>
            <LocationTitle>Location Address</LocationTitle>
            <FieldsWrapper>
              <FormControl disabled variant="standard">
                <InputLabel htmlFor="addressOne">Address Line 1</InputLabel>
                <InputField
                  id="addressOne"
                  width="default"
                  value={location.addressOne}
                />
                <InputHelperText />
              </FormControl>

              <FormControl disabled variant="standard">
                <InputLabel htmlFor="addressTwo">Address Line 2</InputLabel>
                <InputField
                  id="addressTwo"
                  width="default"
                  value={location.addressTwo}
                />
                <InputHelperText />
              </FormControl>
            </FieldsWrapper>

            <FieldsWrapper>
              <FormControl disabled variant="standard">
                <InputLabel htmlFor="city">City</InputLabel>
                <InputField id="city" width="small" value={location.city} />
                <InputHelperText />
              </FormControl>

              <FormControl disabled variant="standard">
                <InputLabel htmlFor="state">State</InputLabel>
                <InputField id="state" width="small" value={location.state} />
                <InputHelperText />
              </FormControl>

              <FormControl disabled variant="standard">
                <InputLabel htmlFor="zipCode">Zip Code</InputLabel>
                <InputField
                  id="zipCode"
                  width="small"
                  value={location.zipCode}
                />
                <InputHelperText />
              </FormControl>
            </FieldsWrapper>
          </OwnerInformationWrapper>
        ) : null}
        <PolicyDetail
          policies={
            addingPolicy
              ? associatedPolicies.toAdd
              : associatedPolicies.selected
          }
        />
      </Content>
      <ButtonContainer>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button onClick={handleCreate} disabled={buttonDisabled}>
          Confirm
        </Button>
      </ButtonContainer>
    </>
  );
};

ConfirmCreation.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    ehr_name: PropTypes.string,
    ehr_link: PropTypes.string,
    ehr_id: PropTypes.number,
    havePhysicalAddress: PropTypes.bool,
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  onBack: PropTypes.func.isRequired,
  addingPolicy: PropTypes.bool,
  associatedPolicies: PropTypes.shape({
    selected: PropTypes.shape({id: PropTypes.number, name: PropTypes.string}),
    toAdd: PropTypes.shape({
      name: PropTypes.string,
      policy_type_id: PropTypes.string,
    }),
  }),
};

const Content = styled.div``;

const OwnerInformationWrapper = styled.div`
  margin: 38px 0;
`;

const HeaderContainer = styled.div`
  width: 410px;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const LocationTitle = styled.p`
  font-weight: bold;
`;

const LocationDetail = styled.div`
  margin-bottom: 36px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
  align-items: center;
`;
