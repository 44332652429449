import React, {FC} from 'react';
import {SvgIcon} from '@material-ui/core';

export const RetryChatIcon: FC<any> = props => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M10.0423 17.0833C9.05621 17.0833 8.1326 16.8958 7.27148 16.5208C6.41037 16.1458 5.66037 15.6388 5.02148 15C4.3826 14.3611 3.87926 13.6144 3.51148 12.76C3.14315 11.9061 2.95898 10.9861 2.95898 9.99996C2.95898 9.01385 3.14315 8.09385 3.51148 7.23996C3.87926 6.38551 4.3826 5.63885 5.02148 4.99996C5.66037 4.36107 6.41037 3.85413 7.27148 3.47913C8.1326 3.10413 9.05621 2.91663 10.0423 2.91663C11.084 2.91663 12.077 3.13524 13.0215 3.57246C13.9659 4.01024 14.7784 4.6319 15.459 5.43746V4.08329C15.459 3.90274 15.5182 3.75329 15.6365 3.63496C15.7543 3.51718 15.9034 3.45829 16.084 3.45829C16.2507 3.45829 16.3965 3.51718 16.5215 3.63496C16.6465 3.75329 16.709 3.90274 16.709 4.08329V6.93746C16.709 7.14579 16.6362 7.32274 16.4907 7.46829C16.3445 7.6144 16.1604 7.68746 15.9382 7.68746H13.084C12.9173 7.68746 12.7715 7.62829 12.6465 7.50996C12.5215 7.39218 12.459 7.24302 12.459 7.06246C12.459 6.8819 12.5215 6.73246 12.6465 6.61413C12.7715 6.49635 12.9173 6.43746 13.084 6.43746H14.6465C14.077 5.72913 13.3932 5.17357 12.5948 4.77079C11.7959 4.36801 10.9451 4.16663 10.0423 4.16663C8.41732 4.16663 7.03898 4.73246 5.90732 5.86413C4.7751 6.99635 4.20898 8.37496 4.20898 9.99996C4.20898 11.625 4.7751 13.0033 5.90732 14.135C7.03898 15.2672 8.41732 15.8333 10.0423 15.8333C11.3618 15.8333 12.5423 15.4338 13.584 14.635C14.6257 13.8366 15.3132 12.7986 15.6465 11.5208C15.702 11.3402 15.7993 11.1944 15.9382 11.0833C16.077 10.9722 16.2298 10.9375 16.3965 10.9791C16.5909 11.0208 16.7334 11.125 16.824 11.2916C16.914 11.4583 16.9312 11.6319 16.8757 11.8125C16.4729 13.368 15.6362 14.6355 14.3657 15.615C13.0945 16.5938 11.6534 17.0833 10.0423 17.0833Z"
      fill="black"
    />
  </SvgIcon>
);
