import React from 'react';
import styled from 'styled-components';

import {LoadingBar} from '../../shared/components/loadingBar';
import {AllClearIDLogo} from '../../shared/icons/allClearIDIcon';
import {colors} from '../../shared/styles/theme';

interface SignPageTemplateProps {
  children: React.ReactElement;
  title: string;
  isLogoLeft?: boolean;
  loading?: boolean;
}

export const SignPageTemplate: React.FC<SignPageTemplateProps> = ({
  children,
  title,
  isLogoLeft = false,
  loading,
}) => (
  <Background>
    <Container>
      <LoadingBar loading={loading} masking />
      {isLogoLeft && (
        <LogoContainer>
          <Logo />
        </LogoContainer>
      )}
      <ContainerLayout>
        <Title>{title}</Title>
        {children}
      </ContainerLayout>
      {!isLogoLeft && (
        <LogoContainer>
          <Logo />
        </LogoContainer>
      )}
    </Container>
  </Background>
);

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey250};
  min-height: 100vh;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.16));
`;

const ContainerLayout = styled.div`
  height: 700px;
  width: 500px;
  padding: 0 58px;
  background-color: ${colors.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const LogoContainer = styled(ContainerLayout)`
  background-color: ${colors.darkPrimary};
  display: flex;
  align-items: center;
  position: relative;
`;

const Logo = styled(AllClearIDLogo)`
  width: 210px;
  height: 57.282px;
  color: ${colors.white};
`;

const Title = styled.h3`
  color: ${colors.primary};
  margin: 0 auto 20px;
`;
