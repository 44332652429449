import React from 'react';

import {List, ListNoDataRow} from '../../../../shared/components/list';

export const ListWithNoDataValue: React.FC<{
  noDataMessage?: string | null;
}> = ({noDataMessage = 'Not Available'}) => (
  <List>
    <ListNoDataRow noDataMessage={noDataMessage} />
  </List>
);
