import useSWR from 'swr';

import {urls} from '../../../shared/utils/urls';

export const usePoliciesAndLocations = relyingPartyId => {
  const {data: policiesAndLocations} = useSWR(
    urls.relyingPartyPolicies(relyingPartyId)
  );

  return policiesAndLocations?.map(policy => ({
    id: policy.id,
    policy_name: policy.name,
    number_of_locations: policy.locations.length,
    assigned_locations: policy.locations.map(location => location.name),
  }));
};
