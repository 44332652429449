import * as yup from 'yup';

import {domainPattern, phonePattern} from '../../../shared/constants/patterns';
import {PROVIDER_TYPES} from '../../../shared/constants/userTypes';

export const relyingPartyFormValidationSchema = yup.object({
  providerType: yup
    .string("Enter provider's type")
    .required('Provider type is required'),
  firstName: yup
    .string("Enter owner's first name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('First name is required'),
  lastName: yup
    .string("Enter owner's last name")
    .trim()
    .max(50, 'Must be 50 characters or less')
    .required('Last name is required'),
  email: yup
    .string("Enter owner's email")
    .trim()
    .max(200, 'Must be 200 characters or less')
    .email('Invalid email')
    .required('Email is required'),
  phoneNumber: yup
    .string("Enter owner's phone number")
    .trim()
    .matches(
      phonePattern,
      'Phone number must be 10 digits and cannot start with 0'
    )
    .required('Phone number is required'),
  name: yup.string().when('providerType', {
    is: providerType => providerType === PROVIDER_TYPES.ORGANIZATION,
    then: yup
      .string('Enter your relying party name')
      .trim()
      .max(100, 'Must be 100 characters or less')
      .required('Relying party name is required'),
  }),
  domain: yup.string().when('providerType', {
    is: providerType => providerType === PROVIDER_TYPES.ORGANIZATION,
    then: yup
      .string()
      .trim()
      .max(200, 'Must be 200 characters or less')
      .matches(domainPattern, 'Please enter a valid domain')
      .required('Domain is required'),
  }),
  primaryLocation: yup
    .string('Enter Primary Location Name')
    .trim()
    .max(256, 'Must be 256 characters or less')
    .required('Primary Location Name is Required'),
  addressOne: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => primaryLocationManually,
    then: yup
      .string()
      .trim()
      .max(256, 'Must be 256 characters or less')
      .required('Address Line 1 is required'),
  }),
  addressTwo: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => primaryLocationManually,
    then: yup.string().trim().max(256, 'Must be 256 characters or less'),
  }),
  city: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => primaryLocationManually,
    then: yup
      .string()
      .trim()
      .max(256, 'Must be 256 characters or less')
      .required('City is required'),
  }),
  state: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => primaryLocationManually,
    then: yup.string().trim().required('State is required'),
  }),
  zipCode: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => primaryLocationManually,
    then: yup
      .string()
      .trim()
      .required('Zip Code is required')
      .test(
        'zipCode',
        'Zip Code is not valid',
        zipCode => zipCode?.length === 5 || zipCode?.length === 9
      ),
  }),
  zipCodeAsync: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => !primaryLocationManually,
    then: yup
      .string()
      .trim()
      .required('Zip Code is required')
      .test(
        'zipCode',
        'Zip should contain 5 or 9 digits',
        zipCode => zipCode?.length === 5 || zipCode?.length === 9
      ),
  }),
  primaryLocationAddress: yup.string().when('primaryLocationManually', {
    is: primaryLocationManually => !primaryLocationManually,
    then: yup.string().trim().required('Primary Location Address is required'),
  }),
});
