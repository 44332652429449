import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import noDataIcon from '../../../assets/icons/noData.svg';
import {colors} from '../styles/theme';

export function NoDataPlaceholder({noDataText}) {
  return (
    <Container>
      <img src={noDataIcon} alt="noDataIcon" />
      <NoDataText>{noDataText}</NoDataText>
    </Container>
  );
}
const Container = styled.div`
  text-align: center;
  margin-top: 80px;
`;

const NoDataText = styled.div`
  margin-top: 20px;
  font-style: italic;
  color: ${colors.grey801};
`;
NoDataPlaceholder.propTypes = {
  noDataText: PropTypes.string,
};
