import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';

import {CustomizedCard} from '../../../shared/customizedCard';
import {CloseIcon} from '../../../shared/icons/closeIcon';
import {colors} from '../../../shared/styles/theme';
import {formatBytes} from '../../../shared/utils/formatBites';
import {FileForPreview} from '../hooks/usePatientsFiles';

type Props = {
  file: FileForPreview['file'];
  onSelect: () => void;
  onDelete: () => void;
  onRetry: () => void;
  isSelected: boolean;
  hasError: boolean;
  isUploaded: boolean;
  isLoading: boolean;
  testId: string;
};

export const FileItem: React.FC<Props> = ({
  file,
  isSelected,
  onSelect,
  onDelete,
  onRetry,
  hasError,
  isUploaded,
  isLoading,
  testId,
}) => (
  <CustomizedCard
    handleClick={onSelect}
    isSelected={isSelected}
    testId={testId}
  >
    <FileItemHeaderWrapper>
      <FileNameWrapper className="fileName">{file.name}</FileNameWrapper>
      <Tooltip title="Remove file">
        <IconButton
          size="small"
          onClick={e => {
            e.stopPropagation();
            onDelete();
          }}
          data-testid={`remove-file-${file.name}`}
        >
          <CloseIcon fontSize="inherit" viewBox="-3 -3 20 20" />
        </IconButton>
      </Tooltip>
    </FileItemHeaderWrapper>
    <FooterFileWrapper>
      <FileSizeWrapper>{formatBytes(file.size)}</FileSizeWrapper>

      {isLoading ? (
        <>
          <Divider>&bull;</Divider>
          <LoadingIndicatorWrapper data-testid={`loading-${file.name}`}>
            Loading <CircularProgress size={18} />
          </LoadingIndicatorWrapper>
        </>
      ) : null}

      {hasError ? (
        <>
          <Divider>&bull;</Divider>
          <Box component="span" fontWeight={600} color={colors.red}>
            Loading failed
          </Box>
          <Divider>&bull;</Divider>
          <RetryLinkWrapper
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRetry();
            }}
            href="/#"
            underline="always"
            data-testid={`retry-${file.name}`}
          >
            Retry
          </RetryLinkWrapper>
        </>
      ) : null}

      {isUploaded ? (
        <>
          <Divider>&bull;</Divider>
          <Box component="span" fontWeight={600} color={colors.green}>
            Uploaded
          </Box>
        </>
      ) : null}
    </FooterFileWrapper>
  </CustomizedCard>
);

const FileItemHeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const FileNameWrapper = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  word-break: break-word;
`;

const FileSizeWrapper = styled.span`
  font-size: 14px;
  color: ${colors.grey801};
`;

const FooterFileWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const LoadingIndicatorWrapper = styled.div`
  color: ${colors.primary};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RetryLinkWrapper = styled(Link)`
  font-weight: 600;
`;

const Divider = styled.span`
  color: ${colors.grey801};
`;
