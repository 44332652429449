/* eslint-disable indent */
import {useQuery, UseQueryResult} from '@tanstack/react-query';

import {fetchElectronicRecordSectionData} from '../../../utils/fetchers';

const getSectionData = async <T>(apiUrl: string): Promise<T> => {
  try {
    const data = await fetchElectronicRecordSectionData(apiUrl);

    return data;
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};
export const useSectionData = <T>(apiUrl: string): UseQueryResult<T, Error> =>
  useQuery({
    queryKey: ['section-data', apiUrl],
    queryFn: () => getSectionData(apiUrl),
    keepPreviousData: true,
  });
