import React from 'react';

import {PreviewDocumentModalWindow} from '../../../shared/components/previewDocumentsModalWindow';
import {OnDownloadDocumentFnType, ReturnDocumentType} from '../constants/types';

import {PatientDocumentContentFullSize} from './patientDocumentContentFullSize';
import {PatientDocumentDetails} from './PatientDocumentDetails';

type Props = {
  title: string | null;
  onDownloadDocuments: OnDownloadDocumentFnType;
  onClose: VoidFunction;
  open: boolean;
  container?: HTMLElement | null;
  patientDocumentsInfo: ReturnDocumentType[] | null;
};

export const PatientDocumentDetailsPopup: React.FC<Props> = ({
  title,
  onDownloadDocuments,
  onClose,
  open,
  container,
  patientDocumentsInfo,
}) => (
  <PreviewDocumentModalWindow
    title={title}
    open={open}
    onClose={onClose}
    container={container}
    onDownloadDocuments={onDownloadDocuments}
    patientDocumentsInfo={patientDocumentsInfo}
  >
    {patientDocumentsInfo ? (
      <>
        <PatientDocumentDetails
          patientDocumentInfo={patientDocumentsInfo[0].data}
        />
        <PatientDocumentContentFullSize
          patientDocumentsInfo={patientDocumentsInfo}
        />
      </>
    ) : null}
  </PreviewDocumentModalWindow>
);
