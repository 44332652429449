import {colors} from '../../shared/styles/theme';

export const getColor = (hasError, value, touched) => {
  if (touched && hasError) {
    return colors.red;
  }
  if (!value) {
    return colors.black;
  }
  if (!hasError) {
    return colors.green;
  }
  return touched ? colors.red : colors.black;
};
