import React, {FC, useCallback, useMemo} from 'react';
import {Box, Chip} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {compose} from 'lodash/fp';
import styled from 'styled-components';

import {ChipsWrapper} from '../../../shared/components/chip';
import {FilterWithCheckboxes} from '../../../shared/components/filterWithCheckboxes';
import {NoDataPlaceholder} from '../../../shared/components/noDataPlaceholder';
import {SearchInput} from '../../../shared/components/searchInput';
import {StyledTable} from '../../../shared/components/table/styledTable';
import {Crumb} from '../../../shared/interfaces/crumb';
import {colors} from '../../../shared/styles/theme';
import {PatientsPageWrapper} from '../components/patientsPageWrapper';
import {INVITED_PATIENTS_TABLE_COLUMNS} from '../constants/invitedPatientsTableConfig';
import {RemappedInvitedPatientsType} from '../constants/types';
import {useFilterWithCheckboxes} from '../hooks/useFilterWithCheckboxes';
import {useInvitedPatients} from '../hooks/useInvitedPatients';
import {useSearch} from '../hooks/useSearch';
import {getSearchedTableData} from '../utils/tableFilters';

const STATUSES_FILTER: {[key: string]: string} = {
  PENDING: 'Invited',
  EXPIRED: 'Expired',
  MISMATCH: 'Mismatch',
  OPTED_OUT: 'Opted Out',
};

type Props = {
  title: string;
  crumbs: Crumb[];
  activeTabIndex: number;
};

export const InvitedPatientsPage: FC<Props> = ({
  title,
  crumbs,
  activeTabIndex,
}) => {
  const [searchValue, searchError, onClearSearch, onSearch] = useSearch();
  const [selectedItems, toggleItem] = useFilterWithCheckboxes();
  const [invitedPatients, invitedPatientsIsLoading] = useInvitedPatients();

  const getFilteredTableData =
    (filterValues: string[]) => (tableData: RemappedInvitedPatientsType[]) => {
      if (filterValues.length === 0) {
        return tableData;
      }

      return tableData.filter(item => {
        const isOptedOutPatient =
          item.is_number_opted_out && filterValues.includes('OPTED_OUT');

        const isMatchBySelectedStatus =
          filterValues.includes(item.status) && !item.is_number_opted_out;

        return isOptedOutPatient || isMatchBySelectedStatus;
      });
    };

  const tableData = useMemo(
    () =>
      compose(
        getSearchedTableData(INVITED_PATIENTS_TABLE_COLUMNS, searchValue),
        getFilteredTableData(selectedItems)
      )(invitedPatients),
    [searchValue, selectedItems, invitedPatients]
  );

  const isFirstLoadingCase =
    invitedPatientsIsLoading &&
    invitedPatients.length === 0 &&
    selectedItems.length === 0 &&
    searchValue?.length === 0;

  const isNoDataCase =
    invitedPatients.length === 0 &&
    selectedItems.length === 0 &&
    searchValue?.length === 0;

  const isNotFoundDataCase =
    !invitedPatientsIsLoading &&
    tableData.length === 0 &&
    (selectedItems.length !== 0 || searchValue?.length !== 0);

  const placeholderComponent = useMemo(() => {
    if (searchError) {
      return (
        <Box
          component="div"
          fontStyle="italic"
          textAlign="center"
          color={colors.red}
        >
          {searchError}
        </Box>
      );
    }
    if (isNotFoundDataCase) {
      return (
        <Box
          component="div"
          fontStyle="italic"
          textAlign="center"
          color={colors.red}
        >
          No results found. Please try again.
        </Box>
      );
    }

    return null;
  }, [searchError, isNotFoundDataCase]);

  const table = useReactTable({
    data: tableData,
    columns: INVITED_PATIENTS_TABLE_COLUMNS,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PatientsPageWrapper
      title={title}
      crumbs={crumbs}
      activeTabIndex={activeTabIndex}
      isLoading={invitedPatientsIsLoading}
    >
      {!isFirstLoadingCase && isNoDataCase ? (
        <NoDataPlaceholder noDataText="No Invitations yet" />
      ) : null}

      {!isFirstLoadingCase && !isNoDataCase ? (
        <>
          <FiltersWrapper>
            <div>
              <SearchInput
                testId="invited-patients"
                placeholder="Search by Name or Phone"
                focusedPlaceholder="Type at least 3 characters to search"
                onSearch={onSearch}
                onClearSearch={onClearSearch}
              />
            </div>
            <FilterWithCheckboxes
              testId="invited-patients"
              id="enrolled-patients-statues-filter"
              items={STATUSES_FILTER}
              selectedItems={selectedItems}
              toggleItem={toggleItem}
              label="Status"
            />
            <ChipsWrapper>
              {selectedItems.map(selectedStatus => (
                <ChipItem
                  key={selectedStatus}
                  selectedStatus={selectedStatus}
                  toggleItem={toggleItem}
                />
              ))}
            </ChipsWrapper>
          </FiltersWrapper>
          <StyledTable
            table={table}
            testId="invited-patients"
            placeholderComponent={placeholderComponent}
            isHeaderSticky
          />
        </>
      ) : null}
    </PatientsPageWrapper>
  );
};

const FiltersWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

interface ChipItemProps {
  selectedStatus: string;
  toggleItem: (item: string) => void;
}

const ChipItem = ({selectedStatus, toggleItem}: ChipItemProps): JSX.Element => {
  const onDelete = useCallback(
    () => toggleItem(selectedStatus),
    [selectedStatus, toggleItem]
  );
  const onMouseDown = useCallback(event => event.stopPropagation(), []);
  return (
    <Chip
      key={selectedStatus}
      label={STATUSES_FILTER[selectedStatus]}
      deleteIcon={<CancelIcon onMouseDown={onMouseDown} />}
      onDelete={onDelete}
    />
  );
};
