import React from 'react';
import styled from 'styled-components';

import noDataIcon from '../../../../assets/icons/noData.svg';
import {colors} from '../../../shared/styles/theme';

export const NoItem = () => (
  <Container>
    <img src={noDataIcon} alt="noItemIcon" />
    <NoItemText>No item found</NoItemText>
  </Container>
);

const Container = styled.div`
  text-align: center;
  padding-top: 60px;
  padding-bottom: 211px;
  margin-left: 40px;
  margin-right: 60px;
  border: 1px solid ${colors.grey40};
`;

const NoItemText = styled.div`
  margin-top: 20px;
  font-style: italic;
  color: ${colors.grey801};
`;
