import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {withStyles} from '@material-ui/core/styles';
import {camelCase} from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

import {Label} from './label';
import OverflowTip from './overFlowTip';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: colors.white,
    fontSize: '14px',
    padding: '5px 16px',
    height: 34,
  },
}))(OutlinedInput);

const handleEndAdornment = loading => {
  if (!loading) return null;
  return (
    <InputAdornment position="end">
      <CircularProgress size={20} />
    </InputAdornment>
  );
};

const PrefixText = ({prefix, prefixMaxWidth}) => (
  <PrefixContainer>
    <Prefix
      maxWidth={prefixMaxWidth}
      value={prefix}
      tipValue={prefix}
      overflow="hidden"
    />
    <Dash>-</Dash>
  </PrefixContainer>
);

PrefixText.propTypes = {
  prefix: PropTypes.string,
  prefixMaxWidth: PropTypes.number,
};

const PrefixContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
`;

const Prefix = styled(OverflowTip)`
  color: ${colors.grey400};
  margin-right: 5px;
`;

const Dash = styled.p`
  color: ${colors.grey400};
  margin-right: 5px;
`;

const getTestId = (testId, label) => testId || `${camelCase(label)}Input`;

export default function CustomizedInput(props) {
  const {
    label,
    error,
    prefix,
    required,
    suffix,
    testID,
    loading,
    errorMessage,
    className,
    helperText,
    prefixMaxWidth,
    inputProps,
    ...rest
  } = props;
  return (
    <FormControl className={className} required={required} error={error}>
      <Label label={label} error={error} />
      <Input
        inputProps={{...inputProps, 'data-testid': getTestId(testID, label)}}
        startAdornment={
          prefix && (
            <PrefixText prefix={prefix} prefixMaxWidth={prefixMaxWidth} />
          )
        }
        endAdornment={
          handleEndAdornment(loading) || (suffix && <Suffix>{suffix}</Suffix>)
        }
        {...rest}
      />
      <ErrorMessage>{error ? errorMessage : ' '}</ErrorMessage>
    </FormControl>
  );
}

const Suffix = styled.p`
  color: ${colors.black};
  width: 60px;
  padding: 14px 16px;
  margin: 0;
  border-left: 1px solid ${colors.grey1100};
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${colors.red};
  display: flex;
  justify-content: start;
  height: 10px;
`;

const Input = styled(BootstrapInput)`
  input {
    padding-left: ${props => props.startAdornment && 0};
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

CustomizedInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  testID: PropTypes.string,
  prefixMaxWidth: PropTypes.number,
  inputProps: PropTypes.objectOf(PropTypes.any),
};
