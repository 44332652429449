import React, {useMemo} from 'react';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {SmthWentWrongPlaceholder} from '../../../../shared/components/smthWentWrongPlaceholder';

import {ElectronicRecordNoData} from './sections/electronicRecordNoData';
import {ElectronicRecordAccordionHeader} from './electronicRecordAccordionHeader';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  somethingWentWrongParagraph: {
    margin: 0,
    lineHeight: '24px',
  },
}));

const PROGRESS_SIZE = 55;
const PROGRESS_THICKNESS = 1.5;

interface Props {
  isLoading: boolean;
  hasNoData: boolean;
  children: React.ReactNode;
  hasError: boolean;
  refetch: () => void;
  sectionLabel: string;
  title: string;
}

export const ElectronicRecordSectionWrapper: React.FC<Props> = ({
  sectionLabel,
  children,
  isLoading,
  hasError,
  hasNoData,
  refetch,
  title,
}) => {
  const {wrapper, somethingWentWrongParagraph} = useStyles();

  const pageState = useMemo(() => {
    if (isLoading) {
      return (
        <div className={wrapper}>
          <CircularProgress
            size={PROGRESS_SIZE}
            thickness={PROGRESS_THICKNESS}
          />
        </div>
      );
    }

    if (hasError) {
      return (
        <div className={wrapper}>
          <SmthWentWrongPlaceholder
            labelText={
              <>
                <span className={somethingWentWrongParagraph}>
                  Something went wrong during request processing.
                </span>
                <br />
                <span className={somethingWentWrongParagraph}>
                  Please retry.
                </span>
              </>
            }
            onClick={refetch}
          />
        </div>
      );
    }

    if (hasNoData) {
      return (
        <ElectronicRecordNoData sectionName={sectionLabel.toLowerCase()} />
      );
    }

    return children;
  }, [
    children,
    hasError,
    hasNoData,
    isLoading,
    refetch,
    sectionLabel,
    somethingWentWrongParagraph,
    wrapper,
  ]);

  return (
    <>
      <ElectronicRecordAccordionHeader title={title} />
      {pageState}
    </>
  );
};
