import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../../shared/atoms/authAtom';
import {
  MedicalFileObject,
  selectedMedicalFileAtom,
} from '../../../../shared/atoms/medicalFileInAiChat';
import {BaseModalWindow} from '../../../../shared/components/baseModalWindow';
import {Button} from '../../../../shared/components/button';
import {Tab} from '../../../../shared/components/tab';
import {colors} from '../../../../shared/styles/theme';
import {usePatientDetails} from '../../hooks/usePatientDetails';
import {getTabs, PossibleTab} from '../../utils/getTabs';
import {MedicalDocumentsTab} from '../medicalDocumentsTab';
import {PatientPageHeader} from '../patientPageHeader';

import {SelectedFileMedicalRecords} from './chatFooter';

interface ModalWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
  onFileSelect: (
    file: SelectedFileMedicalRecords | null,
    selectFileFromMedicalRecord: MedicalFileObject
  ) => void;
}
const ChatFileMedicalRecordsWindow: React.FC<ModalWrapperProps> = ({
  isOpen,
  handleClose,
  onFileSelect,
}) => {
  const currentUser = useRecoilValue(currentUserState);
  const setSelectedMedicalFile = useSetRecoilState<MedicalFileObject>(
    selectedMedicalFileAtom
  );
  const [selectMedicalFile, setSelectMedicalFile] = useState<any>(null);

  const {patientUuid} = useParams<{
    patientUuid: string;
  }>();
  const {data: patientDetails} = usePatientDetails(
    patientUuid,
    currentUser?.relyingParty.id ?? null
  );

  const handleContinue = () => {
    const data = {
      original_file_name: selectMedicalFile.file_name,
      content_type: selectMedicalFile.file_type,
      health_record: true,
      file_path: selectMedicalFile.uploaded_file_name,
    };
    const medicalRecord: SelectedFileMedicalRecords = {
      type: selectMedicalFile.file_type,
      name: selectMedicalFile.file_name,
    };
    onFileSelect(medicalRecord, data);
    setSelectedMedicalFile(data);
    handleClose();
  };

  const handleModalClose = () => {
    handleClose();
  };

  const handleOnSelect = useCallback((selectedRow: any) => {
    if (selectedRow.length > 0) {
      setSelectMedicalFile(selectedRow[0]);
    }
  }, []);

  const getAllPossibleTabs = (): PossibleTab[] => [
    {
      key: 'medical-files',
      title: 'Medical Files',
      isHidden: false,
      path: '',
    },
  ];

  const TABS = getTabs(getAllPossibleTabs());

  return (
    <BaseModalWindow
      minWidth={1620}
      maxWidth={797}
      open={isOpen}
      onClose={handleModalClose}
    >
      <ModalWrapper>
        <HeaderWrapper data-testid="patient-details-header">
          <PatientPageHeader patientsProfileDetails={patientDetails} />
        </HeaderWrapper>
        <ContentWrapper>
          <TabsWrapper>
            <Tab
              activeIndex={0}
              tabs={Object.values(TABS).map(el => el.title)}
            />
          </TabsWrapper>
          <ListWrapper>
            <MedicalDocumentsTab
              onSelect={handleOnSelect}
              testId="medical-documents-chat"
              isAIChat
            />
          </ListWrapper>
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            className="cancelButton"
            variant="outlined"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button data-testid="select-file" onClick={handleContinue}>
            Continue
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </BaseModalWindow>
  );
};

export default ChatFileMedicalRecordsWindow;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const ModalWrapper = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

const HeaderWrapper = styled.div`
  margin: 24px 60px 40px 40px;
`;

const TabsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.primary};
`;

const ListWrapper = styled.div`
  max-height: 440px;
  overflow-y: auto;
`;
