import {useQuery} from '@tanstack/react-query';
import {UseQueryResult} from '@tanstack/react-query/src/types';

import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {wrapAsyncFunction} from '../../../shared/utils/wrapAsyncFunction';
import {fetchFileForPreview} from '../utils/fetchers';

const getPreviewForFile = async (
  fileName: string,
  contentType: string,
  preview_saved = true
) => {
  try {
    const data = await fetchFileForPreview({
      file_name: fileName,
      content_type: contentType,
      preview_saved,
    });
    return data;
  } catch (e) {
    throw new Error('Something went wrong.');
  }
};

export const usePreviewForSavedFile = (
  fileName: string,
  contentType: string,
  preview_saved = true
): UseQueryResult<string> => {
  const handleError = useErrorHandling();

  return useQuery({
    queryKey: ['previewForSavedFile', fileName, contentType],
    queryFn: () => getPreviewForFile(fileName, contentType, preview_saved),
    onError: wrapAsyncFunction(async e => {
      await handleError(e);
    }),
    refetchOnMount: true,
    keepPreviousData: true,
  });
};
