import React, {FC, useCallback} from 'react';
import {Typography} from '@material-ui/core';
import {useRecoilState} from 'recoil';
import styled from 'styled-components';

import {BaseModalWindow} from '../../../shared/components/baseModalWindow';
import {Button} from '../../../shared/components/button';
import {notValidTaskModalWindowState} from '../atoms/notValidTaskModalWindowState';

export const NotValidTaskModalWindow: FC = () => {
  const [notValidTaskState, setNotValidTaskState] = useRecoilState(
    notValidTaskModalWindowState
  );

  const handleClose = useCallback(
    () => setNotValidTaskState(null),
    [setNotValidTaskState]
  );

  const handleDismiss = useCallback(() => {
    handleClose();
    if (notValidTaskState) {
      notValidTaskState.callBackFunc();
    }
  }, [handleClose, notValidTaskState]);

  return (
    <BaseModalWindow open={!!notValidTaskState} onClose={handleClose}>
      <Title>Task is not valid anymore</Title>
      <ContentWrapper>
        For some reason this task can&apos;t be performed. Click on the Dismiss
        button will move task to Completed.
      </ContentWrapper>
      <ButtonWrapper>
        <Button
          variant="contained"
          testID="modal-dismiss-btn"
          onClick={handleDismiss}
        >
          Dismiss
        </Button>
      </ButtonWrapper>
    </BaseModalWindow>
  );
};

const ContentWrapper = styled.div`
  margin: 16px 0 40px;
`;

const Title = styled(Typography)`
  margin: 0;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
