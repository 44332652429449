import React, {useCallback, useState} from 'react';
import {Checkbox} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';

import {colors} from '../styles/theme';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{color: colors.lightPrimary}} />
);
const selectAllValue = 'select-all';
const useStyles = makeStyles(() => ({
  popper: {
    height: '200px',
    zIndex: 2,
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: colors.white,
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: colors.white,
    },
    '& .MuiAutocomplete-option': {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  input: {
    '& > .MuiInput-root': {
      marginTop: 0,
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      minHeight: '42px',
    },
    '& > .MuiInputLabel-root': {
      color: colors.grey801,
      fontSize: '12px',
      transition: 'none',
      transform: 'none',
    },
    '& > .Mui-focused.MuiInputLabel-root': {
      color: colors.darkPrimary,
      transition: 'color .15s',
    },
    '& > .MuiInputBase-root': {
      padding: '0px 15px',
      overflow: 'hidden',
      border: `1px solid ${colors.backgroundPrimary}`,
      borderRadius: '4px',

      '& > input::placeholder': {
        color: colors.black,
        opacity: 1,
        fontSize: '14px',
      },

      '&::before': {
        display: 'none',
      },
      '&:hover': {
        border: `1px solid ${colors.darkPrimary}`,
      },
      '& > .MuiAutocomplete-endAdornment': {
        marginRight: '9px',
      },
      '& > .MuiChip-root': {
        height: '30px',

        '& > .MuiChip-deleteIcon': {
          transform: 'scale(.8)',
        },
      },
    },
    '& > .Mui-focused.MuiInputBase-root, .Mui-focused:hover.MuiInputBase-root:hover':
      {
        border: `2px solid ${colors.darkPrimary}`,
      },
  },
  container: {
    display: 'contents',
    '& > .MuiFormControl-fullWidth': {
      width: 'auto',
      minWidth: '383px',
      maxWidth: '100%',
      marginTop: '8px',

      '& > div::after': {
        border: 'none',
      },
    },
  },
  clearIndicator: {
    display: 'none',
  },
  popupIndicator: {
    transition: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      color: 'black',
    },
    '& > .MuiTouchRipple-root': {
      display: 'none',
    },
  },
}));

export const MultiSelect = ({
  items,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  onToggleOption,
  onClearOptions,
  onSelectAll,
}) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);

  const allSelected = items?.length === selectedValues.length;
  const getOptionLabel = useCallback(option => `${option.label}`, []);
  const handleToggleSelectAll = useCallback(() => {
    if (onSelectAll) {
      onSelectAll(!allSelected);
    }
  }, [allSelected, onSelectAll]);

  const handleChange = useCallback(
    (_event, selectedOptions, reason) => {
      if (reason === 'select-option' || reason === 'remove-option') {
        if (selectedOptions.find(option => option.value === selectAllValue)) {
          handleToggleSelectAll();
        } else if (onToggleOption) {
          onToggleOption(selectedOptions);
        }
      } else if (reason === 'clear') {
        if (onClearOptions) {
          onClearOptions();
        }
      }
    },
    [handleToggleSelectAll, onClearOptions, onToggleOption]
  );
  const optionRenderer = useCallback(
    (option, {selected}) => {
      const selectAllProps =
        option.value === selectAllValue ? {checked: allSelected} : {};
      return (
        <>
          <Checkbox
            color="primary"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected}
            {...selectAllProps}
          />
          {option.label}
        </>
      );
    },
    [allSelected]
  );
  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);
  const inputRenderer = useCallback(
    params => (
      <TextField
        {...params}
        onFocus={onFocus}
        onBlur={onBlur}
        label={(selectedValues.length > 0 || focused) && label}
        placeholder={placeholder}
        className={classes.input}
      />
    ),
    [
      classes.input,
      focused,
      label,
      onBlur,
      onFocus,
      placeholder,
      selectedValues.length,
    ]
  );
  const getOptionSelected = useCallback(
    (option, anotherOption) => option.value === anotherOption.value,
    []
  );
  const filter = createFilterOptions();

  const MultiPopper = useCallback(
    props => (
      <Popper
        {...props}
        placement="bottom-start"
        className={classes.popper}
        style={{width: '383px'}}
      />
    ),
    [classes.popper]
  );
  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      const {inputValue} = params;
      return inputValue
        ? filtered
        : [{label: selectAllLabel, value: selectAllValue}, ...filtered];
    },
    [filter, selectAllLabel]
  );

  const renderTags = useCallback(
    (value, getTagProps) => {
      const defaultTagProps = {
        variant: 'default',
        style: {backgroundColor: colors.grey50, fontSize: '12px'},
      };
      if (allSelected) {
        return [
          <Chip
            label={selectAllLabel}
            {...defaultTagProps}
            onDelete={onClearOptions}
          />,
        ];
      }
      if (value.length > 7) {
        return [
          <Chip
            label={`${value.length} locations selected`}
            {...defaultTagProps}
            onDelete={onClearOptions}
          />,
        ];
      }
      return value.map((option, index) => (
        <Chip
          label={option.label}
          {...getTagProps({index})}
          {...defaultTagProps}
        />
      ));
    },
    [allSelected, onClearOptions, selectAllLabel]
  );

  return (
    <Autocomplete
      multiple
      size="small"
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      className={classes.container}
      popupIcon={<ExpandMoreIcon />}
      classes={{
        popupIndicator: classes.popupIndicator,
        clearIndicatorDirty: classes.clearIndicator,
      }}
      filterOptions={filterOptions}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={renderTags}
      PopperComponent={MultiPopper}
      ListboxProps={{style: {maxHeight: '500px'}}}
    />
  );
};

MultiSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  selectedValues: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.node,
  placeholder: PropTypes.string,
  selectAllLabel: PropTypes.string,
  onToggleOption: PropTypes.func,
  onClearOptions: PropTypes.func,
  onSelectAll: PropTypes.func,
};

MultiSelect.defaultProps = {
  items: [],
  selectedValues: [],
};
