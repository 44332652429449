import React, {useCallback} from 'react';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import {isEmpty, isString} from 'lodash';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
} from 'notistack';

import {colors} from '../styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1rem',
    color: colors.white,
    padding: '24px 115px',
    minWidth: '250px',
    overflow: 'hidden',
    fontWeight: 700,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      color: colors.white,
    },
    '& .MuiSvgIcon-root': {
      color: colors.white,
      width: 24,
      height: 24,
    },
    '& .MuiAlert-icon': {
      padding: 0,
      marginRight: '16px',
    },
    '& .MuiAlert-message': {
      padding: 0,
      width: '100%',
    },
  },
  error: {
    backgroundColor: colors.red,
  },
  success: {
    backgroundColor: colors.green,
  },
  loading: {
    backgroundColor: colors.grey801,
  },
  info: {
    backgroundColor: colors.grey801,
    padding: '16px',
  },
}));

export type SnackbarType = 'success' | 'loading' | 'error' | 'info';

export const useCloseCustomizedSnackbar = (): ((
  snackbarKey: SnackbarKey
) => void) => {
  const {closeSnackbar} = useSnackbar();

  return useCallback(
    snackbarKey => {
      closeSnackbar(snackbarKey);
    },
    [closeSnackbar]
  );
};

export const useCustomizedSnackbar = (): ((
  children: SnackbarMessage,
  type: SnackbarType,
  icon?: React.ReactNode | false
) => SnackbarKey) => {
  const classes = useStyles();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const getOptions = useCallback(
    (type: SnackbarType, icon?: React.ReactNode): OptionsObject => ({
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      // eslint-disable-next-line react/display-name
      content: (_key: SnackbarKey, children: SnackbarMessage) => {
        const options = {
          onClose: () => closeSnackbar(_key),
        };
        return (
          <Alert
            className={`${classes[type]} ${classes.root}`}
            severity={type === 'loading' ? undefined : type}
            icon={icon}
          >
            {!isEmpty(children) && isString(children)
              ? children
              : React.cloneElement(children as React.ReactElement, options)}
          </Alert>
        );
      },
    }),
    [classes, closeSnackbar]
  );

  return useCallback(
    (
      children: SnackbarMessage,
      type: SnackbarType,
      icon?: React.ReactNode | false
    ) => enqueueSnackbar(children, getOptions(type, icon)),
    [enqueueSnackbar, getOptions]
  );
};
