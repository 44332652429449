import {useCallback, useState} from 'react';

import {
  PROVIDER_TYPES,
  PROVIDER_TYPES_SEARCH_VALUES,
} from '../constants/userTypes';
import {fetcher} from '../utils/fetcher';
import {urls} from '../utils/urls';

export type LocationAddress = {
  address_1: string;
  address_2: string;
  city: string;
  country: string;
  district: string;
  postal_code: string;
  state: string;
};

type Return = {
  data: LocationAddress[] | null;
  isLoading: boolean;
  hasError: boolean;
  lastSearchedZipCode: string;
  onClearLocationAddresses: () => void;
  fetchLocationAddresses: (
    providerType: PROVIDER_TYPES,
    npi: string,
    zipCode: string
  ) => Promise<LocationAddress[]>;
};

export const useLocationAddresses = (
  removeInnerPhysicalLocations: string
): Return => {
  const [data, setData] = useState<LocationAddress[] | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastSearchedZipCode, setLastSearchedZipCode] = useState<string>('');

  const fetchLocationAddresses = async (
    providerType: PROVIDER_TYPES,
    npi: string,
    zipCode: string
  ): Promise<LocationAddress[]> => {
    try {
      setData(null);
      setIsLoading(true);
      setHasError(false);

      const data: LocationAddress[] = await fetcher(
        urls.getLocationsAddresses(
          PROVIDER_TYPES_SEARCH_VALUES[providerType],
          npi,
          zipCode,
          removeInnerPhysicalLocations
        )
      );

      setData(data);
      setLastSearchedZipCode(zipCode);

      return data;
    } catch (e) {
      setHasError(true);
      setLastSearchedZipCode('');
    } finally {
      setIsLoading(false);
    }

    return [];
  };

  const onClearLocationAddresses = useCallback((): void => {
    setIsLoading(false);
    setHasError(false);
    setData(null);
    setLastSearchedZipCode('');
  }, []);

  return {
    data,
    isLoading,
    hasError,
    lastSearchedZipCode,
    fetchLocationAddresses,
    onClearLocationAddresses,
  };
};
