import React, {useCallback, useState} from 'react';
import {
  ButtonBase,
  Divider,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import {InputField} from './form';

const SearchInputBtn = withStyles(() => ({
  root: {
    height: '100%',
    padding: '0 10px',
    borderRadius: '0 4px 4px 0',
  },
}))(ButtonBase);

const ClearSearchInputBtn = withStyles(() => ({
  root: {
    marginRight: '10px',
  },
}))(IconButton);

const DEFAULT_SEARCH_VALUE = '';

export const SearchInput = ({
  placeholder,
  focusedPlaceholder,
  onSearch,
  onClearSearch,
  testId,
}) => {
  const [value, setValue] = useState(DEFAULT_SEARCH_VALUE);
  const onFocus = useCallback(
    e => {
      e.target.placeholder = focusedPlaceholder;
    },
    [focusedPlaceholder]
  );

  const onBlur = useCallback(
    e => {
      e.target.placeholder = placeholder;
    },
    [placeholder]
  );
  const onChange = useCallback(e => {
    setValue(e.target.value);
  }, []);
  const onClear = useCallback(() => {
    setValue(DEFAULT_SEARCH_VALUE);
    onClearSearch();
  }, [onClearSearch]);

  const setSearchValue = useCallback(
    e => {
      e.preventDefault();
      onSearch(value);
    },
    [onSearch, value]
  );

  return (
    <form onSubmit={setSearchValue}>
      <InputField
        inputProps={{'data-testid': testId ? `${testId}-search-input` : null}}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            {value.length !== 0 ? (
              <ClearSearchInputBtn
                data-testid={testId ? `${testId}-search-clear-btn` : null}
                onClick={onClear}
                size="small"
              >
                <CancelIcon fontSize="small" />
              </ClearSearchInputBtn>
            ) : null}
            <Divider orientation="vertical" />
            <SearchInputBtn
              data-testid={testId ? `${testId}-search-submit-btn` : null}
              type="submit"
              onClick={setSearchValue}
            >
              <SearchIcon color="primary" />
            </SearchInputBtn>
          </InputAdornment>
        }
      />
    </form>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  focusedPlaceholder: PropTypes.string,
  testId: PropTypes.string,
};
