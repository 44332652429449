import {atom} from 'recoil';

type PatientWasNotConnectedState = {
  isOpen: boolean;
  data: {
    patientName: string;
  };
};

export const patientWasNotConnectedState = atom<PatientWasNotConnectedState>({
  key: 'patientWasNotConnected',
  default: {
    isOpen: false,
    data: {
      patientName: '',
    },
  },
});
