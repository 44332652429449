import React from 'react';
import {Box} from '@material-ui/core';

import {colors} from '../../../../shared/styles/theme';
import {
  CONNECTION_STATUSES,
  CONNECTION_STATUSES_LABELS,
} from '../../constants/patientStatuses';

type Props = {
  status: CONNECTION_STATUSES;
  consumerUuid: string;
};

export const ConnectionStatus = ({
  status,
  consumerUuid,
}: Props): JSX.Element => (
  <Box
    data-testid={`connection-status-${consumerUuid}`}
    color={
      status === CONNECTION_STATUSES.PENDING
        ? colors.statusBlue
        : colors.grey600
    }
  >
    {CONNECTION_STATUSES_LABELS[status]}
  </Box>
);
