import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/styles';

import {NoDataDash} from '../../../../shared/components/noDataDash';
import {colors} from '../../../../shared/styles/theme';

const useAccordionStyles = makeStyles(() => ({
  root: {
    transition: 'none',
    border: `1px solid ${colors.grey800}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    margin: 0,
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
}));

const useAccordionSummaryStyles = makeStyles(() => ({
  root: {
    color: colors.black,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    padding: '0 24px',
    transition: 'none',
    backgroundColor: colors.grey1000,
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  content: {
    margin: '16px 0',
    transition: 'none',
    '&.Mui-expanded': {
      margin: '16px 0',
    },
  },
}));

const useAccordionDetailsStyles = makeStyles(() => ({
  root: {
    borderTop: `1px solid ${colors.grey800}`,
    padding: 0,
    width: '100%',
  },
}));

type Props = {
  title?: string | null | JSX.Element;
  content: string | JSX.Element;
};
export const ElectronicRecordAccordion: React.FC<Props> = ({
  title,
  content,
}) => {
  const accordionClasses = useAccordionStyles();
  const accordionSummaryClasses = useAccordionSummaryStyles();
  const accordionDetailsClasses = useAccordionDetailsStyles();

  return (
    <Accordion
      classes={{root: accordionClasses.root}}
      elevation={0}
      defaultExpanded
    >
      <AccordionSummary
        data-testid="accordion-summary"
        classes={{
          root: accordionSummaryClasses.root,
          content: accordionSummaryClasses.content,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {title ?? <NoDataDash />}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: accordionDetailsClasses.root,
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
};
