import {useState} from 'react';

export const useFilterWithCheckboxes = (): [
  string[],
  (item: string) => void,
] => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const toggleItem = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return [selectedItems, toggleItem];
};
