import React from 'react';
import {makeStyles} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colors} from '../styles/theme';

const useStyles = makeStyles({
  arrowBack: {
    color: `${colors.primary}`,
    cursor: 'pointer',
    marginLeft: '25px',
    marginRight: '15px',
  },
});

export const ExitBar = ({onClick, title, testID}) => {
  const classes = useStyles();

  return (
    <Title>
      <ArrowBackIcon
        color="primary"
        className={classes.arrowBack}
        onClick={onClick}
        data-testid={testID ? `${testID}` : null}
      />
      <Label>{title}</Label>
    </Title>
  );
};

ExitBar.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  testID: PropTypes.string,
};

const Title = styled.div`
  display: flex;
  max-height: 46px;
  align-items: center;
  border-bottom: 1px solid ${colors.grey50};
`;

const Label = styled.h5`
  font-weight: 500;
  line-height: 21px;
`;
