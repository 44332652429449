import React from 'react';
import {Color, PropTypes, Theme} from '@material-ui/core';
import MuiButton, {ButtonProps} from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import styled from 'styled-components';

import {AddIcon} from '../icons/addIcon';
import {CloseIcon} from '../icons/closeIcon';
import {ConfirmIcon} from '../icons/confirmIcon';
import {TrashIcon} from '../icons/trashIcon';
import {UploadIcon} from '../icons/uploadIcon';
import {colors} from '../styles/theme';

type PossiblePositions = 'mainArea' | 'section';

export interface CustomButtonProps extends Omit<ButtonProps, 'color'> {
  position?: PossiblePositions;
  testID?: string;
  color?: Color | PropTypes.Color | 'dangerous';
  target?: string;
}

const areaStyles: any = {
  mainArea: {height: 44},
  section: {height: 36, fontWeight: 500},
};

const useDefaultBtnStyles = makeStyles(() => ({
  root: ({position}: {position: PossiblePositions}) => ({
    '&.MuiButton-root': {
      minWidth: 110,
      boxShadow: 'none',
      textTransform: 'none',
      lineHeight: 'normal',
      fontSize: 14,
      ...areaStyles[position],
      '&:not(:first-of-type)': {
        marginLeft: 10,
      },
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(36, 71, 130, 0.16)',
      },
    },
  }),
}));

const useDangerousBtnStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.MuiButton-contained': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    '&.MuiButton-outlined': {
      backgroundColor: theme.palette.error.contrastText,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      '&.Mui-disabled': {
        color: theme.palette.action.disabledBackground,
        borderColor: theme.palette.action.disabledBackground,
      },
    },
  },
}));

export const Button: React.FC<CustomButtonProps> = ({
  className,
  position = 'mainArea',
  color,
  variant = 'contained',
  testID,
  ...props
}) => {
  const dangerous = color === 'dangerous';
  const defaultBtnClasses = useDefaultBtnStyles({position});
  const defaultDangerousBtnClasses = useDangerousBtnStyles({position});

  return (
    <MuiButton
      className={classNames(defaultBtnClasses.root, className, {
        [defaultDangerousBtnClasses.root]: dangerous,
      })}
      color="primary"
      variant={variant}
      data-testid={testID}
      {...props}
    />
  );
};

export const DeleteButton: React.FC<CustomButtonProps> = props => {
  const deleteButtonProperties = {
    ...props,
    color: 'dangerous',
    position: 'section',
    startIcon: <DeleteIcon />,
  } as CustomButtonProps;
  return (
    <Button {...deleteButtonProperties}>
      <div data-testid="deleteButton">Delete</div>
    </Button>
  );
};

export const CancelButton: React.FC<CustomButtonProps> = props => (
  <Button variant="outlined" {...props} data-testid="cancelButton">
    <div>Cancel</div>
  </Button>
);

export const AddButton: React.FC<CustomButtonProps & {text: string}> = ({
  text,
  testID,
  ...props
}) => {
  const addButtonProps = {
    ...props,
    position: 'section',
    startIcon: <AddIcon style={{width: 14}} />,
  } as CustomButtonProps;
  return (
    <Button {...addButtonProps}>
      <div data-testid={testID}>{text}</div>
    </Button>
  );
};

export const DownloadButton: React.FC<CustomButtonProps & {text: string}> = ({
  text,
  testID,
  ...props
}) => {
  const uploadButtonProps = {
    ...props,
    position: 'section',
    startIcon: <UploadIcon style={{width: 14}} />,
  } as CustomButtonProps;
  return (
    <Button {...uploadButtonProps}>
      <div data-testid={testID}>{text}</div>
    </Button>
  );
};

export const CloseButton: React.FC<CustomButtonProps> = ({
  testID,
  ...props
}) => {
  const uploadButtonProps = {
    ...props,
  } as CustomButtonProps;
  return (
    <Button {...uploadButtonProps} testID={testID}>
      <CloseIcon style={{width: 16}} />
    </Button>
  );
};

export const ConfirmButton: React.FC<CustomButtonProps> = ({
  testID,
  ...props
}) => {
  const uploadButtonProps = {
    ...props,
  } as CustomButtonProps;
  return (
    <Button {...uploadButtonProps} testID={testID}>
      <ConfirmIcon style={{width: 16}} />
    </Button>
  );
};

const DeleteIcon = styled(TrashIcon)`
  width: 14px;
  color: ${colors.white};
`;
